import { createSlice } from '@reduxjs/toolkit'
import { dictionaryToObject, contentArrayToObj } from '../../utils/utils'

const normalizeContent = (obj) => {
    let content:any = {}
    obj.forEach(flow => {
        let flowKey = Object.keys(flow)[0]
        let flowContentArray = flow[flowKey]
        content[flowKey] = {}
        flowContentArray.forEach(page => {
            let pageKey = Object.keys(page)[0]
            let pageContent = page[pageKey]
            content[flowKey][pageKey] = pageContent
        })
    })
    return content
}

interface ContentState {
    // dictionaryContent: any
    // fetchDictionaryContent: boolean

    englishAllContent: any
    fetchedEnglishAllContent: boolean
    fetchingEnglishAllContent: boolean
    fetchEnglishAllContentFailed: boolean

    spanishAllContent: any
    fetchedSpanishAllContent: boolean
    fetchingSpanishAllContent: boolean
    fetchSpanishAllContentFailed: boolean

    englishDictionaryContent: any
    fetchedEnglishDictionaryContent: boolean
    fetchingEnglishDictionaryContent: boolean
    fetchEnglishDictionaryContentFailed: boolean

    spanishDictionaryContent: any
    fetchedSpanishDictionaryContent: boolean
    fetchingSpanishDictionaryContent: boolean
    fetchSpanishDictionaryContentFailed: boolean


    // activationContent: any
    // fetchedActivationContent: boolean

    englishActivationContent: any
    fetchingEnglishActivationContent: boolean
    fetchEnglishActivationContentFailed: boolean
    fetchedEnglishActivationContent: boolean

    spanishActivationContent: any
    fetchingSpanishActivationContent: boolean
    fetchSpanishActivationContentFailed: boolean
    fetchedSpanishActivationContent: boolean


    // dashboardContent: any
    // fetchedDashboardContent: boolean

    englishDashboardContent: any
    fetchingEnglishDashboardContent: boolean
    fetchEnglishDashboardContentFailed: boolean
    fetchedEnglishDashboardContent: boolean

    spanishDashboardContent: any
    fetchingSpanishDashboardContent: boolean
    fetchSpanishDashboardContentFailed: boolean
    fetchedSpanishDashboardContent: boolean

    // cartContent: any
    // fetchedCartContent: boolean

    englishCartContent: any
    fetchingEnglishCartContent: boolean
    fetchEnglishCartContentFailed: boolean
    fetchedEnglishCartContent: boolean

    spanishCartContent: any
    fetchingSpanishCartContent: boolean
    fetchSpanishCartContentFailed: boolean
    fetchedSpanishCartContent: boolean

    // buyflowContent: any
    // fetchedBuyflowContent: boolean
    // checkoutContent: any
    // headerContent: any
    // footerContent: any

    englishBuyflowContent: any
    fetchingEnglishBuyflowContent: boolean
    fetchEnglishBuyflowContentFailed: boolean
    fetchedEnglishBuyflowContent: boolean

    spanishBuyflowContent: any
    fetchingSpanishBuyflowContent: boolean
    fetchSpanishBuyflowContentFailed: boolean
    fetchedSpanishBuyflowContent: boolean

    englishRefillContent: any
    fetchingEnglishRefillContent: boolean
    fetchEnglishRefillContentFailed: boolean
    fetchedEnglishRefillContent: boolean

    spanishRefillContent: any
    fetchingSpanishRefillContent: boolean
    fetchSpanishRefillContentFailed: boolean
    fetchedSpanishRefillContent: boolean

    englishMerchantContent: any
    fetchingEnglishMerchantContent: boolean
    fetchEnglishMerchantContentFailed: boolean
    fetchedEnglishMerchantContent: boolean

    spanishMerchantContent: any
    fetchingSpanishMerchantContent: boolean
    fetchSpanishMerchantContentFailed: boolean
    fetchedSpanishMerchantContent: boolean

    englishMoveContent: any
    fetchingEnglishMoveContent: boolean
    fetchEnglishMoveContentFailed: boolean
    fetchedEnglishMoveContent: boolean

    spanishMoveContent: any
    fetchingSpanishMoveContent: boolean
    fetchSpanishMoveContentFailed: boolean
    fetchedSpanishMoveContent: boolean

    englishReconnectContent: any
    fetchingEnglishReconnectContent: boolean
    fetchEnglishReconnectContentFailed: boolean
    fetchedEnglishReconnectContent: boolean

    spanishReconnectContent: any
    fetchingSpanishReconnectContent: boolean
    fetchSpanishReconnectContentFailed: boolean
    fetchedSpanishReconnectContent: boolean

}

// const updateReadyStatus = (state) => {
//     let ready = true
//     ready = ready && state.fetchedEnglishContent
//     ready = ready && state.fetchedSpanishContent
//     ready = ready && state.fetchedIdentity
//     ready = ready && state.fetchedDashboard
//     state.ready = ready
//     state.fetchedContent = state.fetchedEnglishContent && state.fetchedSpanishContent
// }

const initialState = {
    // dictionaryContent: null,
    // fetchDictionaryContent: false,

    englishAllContent: null,
    fetchedEnglishAllContent: false,
    fetchingEnglishAllContent: false,
    fetchEnglishAllContentFailed: false,

    spanishAllContent: null,
    fetchedSpanishAllContent: false,
    fetchingSpanishAllContent: false,
    fetchSpanishAllContentFailed: false,

    englishDictionaryContent: null,
    fetchedEnglishDictionaryContent: false,
    fetchingEnglishDictionaryContent: false,
    fetchEnglishDictionaryContentFailed: false,

    spanishDictionaryContent: null,
    fetchedSpanishDictionaryContent: false,
    fetchingSpanishDictionaryContent: false,
    fetchSpanishDictionaryContentFailed: false,


    // activationContent: null,
    // fetchedActivationContent: false,

    englishActivationContent: null,
    fetchingEnglishActivationContent: false,
    fetchEnglishActivationContentFailed: false,
    fetchedEnglishActivationContent: false,

    spanishActivationContent: null,
    fetchingSpanishActivationContent: false,
    fetchSpanishActivationContentFailed: false,
    fetchedSpanishActivationContent: false,


    // dashboardContent: null,
    // fetchedDashboardContent: false,

    englishDashboardContent: null,
    fetchingEnglishDashboardContent: false,
    fetchEnglishDashboardContentFailed: false,
    fetchedEnglishDashboardContent: false,

    spanishDashboardContent: null,
    fetchingSpanishDashboardContent: false,
    fetchSpanishDashboardContentFailed: false,
    fetchedSpanishDashboardContent: false,


    // cartContent: null,
    // fetchedCartContent: false,

    englishCartContent: null,
    fetchingEnglishCartContent: false,
    fetchEnglishCartContentFailed: false,
    fetchedEnglishCartContent: false,

    spanishCartContent: null,
    fetchingSpanishCartContent: false,
    fetchSpanishCartContentFailed: false,
    fetchedSpanishCartContent: false,

    // buyflowContent: null,
    // fetchedBuyflowContent: false,
    // checkoutContent: null,
    // headerContent: null,
    // footerContent: null,

    englishBuyflowContent: null,
    fetchingEnglishBuyflowContent: false,
    fetchEnglishBuyflowContentFailed: false,
    fetchedEnglishBuyflowContent: false,

    spanishBuyflowContent: null,
    fetchingSpanishBuyflowContent: false,
    fetchSpanishBuyflowContentFailed: false,
    fetchedSpanishBuyflowContent: false,


    englishRefillContent: null,
    fetchingEnglishRefillContent: false,
    fetchEnglishRefillContentFailed: false,
    fetchedEnglishRefillContent: false,

    spanishRefillContent: null,
    fetchingSpanishRefillContent: false,
    fetchSpanishRefillContentFailed: false,
    fetchedSpanishRefillContent: false,

    englishMerchantContent: null,
    fetchingEnglishMerchantContent: false,
    fetchEnglishMerchantContentFailed: false,
    fetchedEnglishMerchantContent: false,

    spanishMerchantContent: null,
    fetchingSpanishMerchantContent: false,
    fetchSpanishMerchantContentFailed: false,
    fetchedSpanishMerchantContent: false,

    englishMoveContent: null,
    fetchingEnglishMoveContent: false,
    fetchEnglishMoveContentFailed: false,
    fetchedEnglishMoveContent: false,

    spanishMoveContent: null,
    fetchingSpanishMoveContent: false,
    fetchSpanishMoveContentFailed: false,
    fetchedSpanishMoveContent: false,

    englishReconnectContent: null,
    fetchingEnglishReconnectContent: false,
    fetchEnglishReconnectContentFailed: false,
    fetchedEnglishReconnectContent: false,

    spanishReconnectContent: null,
    fetchingSpanishReconnectContent: false,
    fetchSpanishReconnectContentFailed: false,
    fetchedSpanishReconnectContent: false

} as ContentState

const content = createSlice({
    name: 'content',
    initialState,
    reducers: {
        fetchEnglishAllContent: (state) => {
            state.fetchingEnglishAllContent = true
        },
        fetchEnglishAllContentSuccess: (state, {payload}) => {
            let content:any = payload.sharedContent
            console.log('🇺🇸 Updating English All content...', content)
            state.englishAllContent = content
            state.fetchingEnglishAllContent = false
            state.fetchEnglishAllContentFailed = false
            state.fetchedEnglishAllContent = true

            // set dictionary content
            let dictionaryContent: any = dictionaryToObject(content.find(p => p.dictionary).dictionary)
            state.englishDictionaryContent = dictionaryContent
            state.fetchedEnglishDictionaryContent = true
            
            // set activation content
            let activationContent: any = normalizeContent(content.find(p => p.activation).activation)
            state.englishActivationContent = activationContent
            state.fetchedEnglishActivationContent = true
            
            // set dashboard content
            let dashboardContent: any = normalizeContent(content.find(p => p.accountDashboard).accountDashboard)
            state.englishDashboardContent = dashboardContent
            state.fetchedEnglishDashboardContent = true
            
            // set buyflow content
            let buyflowContent: any = contentArrayToObj(content.find(p => p.buyflow).buyflow)
            state.englishBuyflowContent = buyflowContent
            state.fetchedEnglishBuyflowContent = true
            
            // set refill content
            let refillContent: any = normalizeContent(content.find(p => p.refill).refill)
            state.englishRefillContent = refillContent
            state.fetchedEnglishRefillContent = true

            // set merchant content
            let merchantContent: any = contentArrayToObj(content.find(p => p.merchantFlow).merchantFlow)
            state.englishMerchantContent = merchantContent
            state.fetchedEnglishMerchantContent = true

            // set move content
            let moveContent: any = contentArrayToObj(content.find(p => p.moving).moving)
            state.englishMoveContent = moveContent
            state.fetchedEnglishMoveContent = true

            // set reconnect content
            let reconnectContent: any = contentArrayToObj(content.find(p => p.reconnect).reconnect)
            state.englishReconnectContent = reconnectContent
            state.fetchedEnglishReconnectContent = true
        },
        fetchEnglishAllContentFailure: (state, {payload}) => {
            console.log('fetchEnglishAllContentFailure payload', payload)
            state.fetchingEnglishAllContent = false
            state.fetchEnglishAllContentFailed = true
        },
        fetchSpanishAllContent: (state) => {
            state.fetchingSpanishAllContent = true
        },
        fetchSpanishAllContentSuccess: (state, {payload}) => {
            let content:any = payload.sharedContent
            console.log('🇲🇽Updating Spanish All content...', content)
            state.spanishAllContent = content
            state.fetchingSpanishAllContent = false
            state.fetchSpanishAllContentFailed = false
            state.fetchedSpanishAllContent = true

            // set dictionary content
            let dictionaryContent: any = dictionaryToObject(content.find(p => p.dictionary).dictionary)
            state.spanishDictionaryContent = dictionaryContent
            state.fetchedSpanishDictionaryContent = true

            // set activation content
            let activationContent: any = normalizeContent(content.find(p => p.activation).activation)
            state.spanishActivationContent = activationContent
            state.fetchedSpanishActivationContent = true

            // set dashboard content
            let dashboardContent: any = normalizeContent(content.find(p => p.accountDashboard).accountDashboard)
            state.spanishDashboardContent = dashboardContent
            state.fetchedSpanishDashboardContent = true

            // set buyflow content
            let buyflowContent: any = contentArrayToObj(content.find(p => p.buyflow).buyflow)
            state.spanishBuyflowContent = buyflowContent
            state.fetchedSpanishBuyflowContent = true

            // set refill content
            let refillContent: any = normalizeContent(content.find(p => p.refill).refill)
            state.spanishRefillContent = refillContent
            state.fetchedSpanishRefillContent = true

            // set merchant content
            let merchantContent: any = contentArrayToObj(content.find(p => p.merchantFlow).merchantFlow)
            state.spanishMerchantContent = merchantContent
            state.fetchedSpanishMerchantContent = true

            // set move content
            let moveContent: any = contentArrayToObj(content.find(p => p.moving).moving)
            state.spanishMoveContent = moveContent
            state.fetchedSpanishMoveContent = true

            // set reconnect content
            let reconnectContent: any = contentArrayToObj(content.find(p => p.reconnect).reconnect)
            state.spanishReconnectContent = reconnectContent
            state.fetchedSpanishReconnectContent = true
        },
        fetchSpanishAllContentFailure: (state, {payload}) => {
            console.log('fetchSpanishAllContentFailure payload', payload)
            state.fetchingSpanishAllContent = false
            state.fetchSpanishAllContentFailed = true
        },
        fetchEnglishDictionaryContent: (state) => {
            state.fetchingEnglishDictionaryContent = true
        },
        fetchEnglishDictionaryContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishDictionaryContentSuccess payload', payload)

            let content:any = dictionaryToObject(payload.dictionary)

            console.log('🇺🇸 Updating English Dictionary content...', content)
            state.englishDictionaryContent = content
            // if (state.locale === 'en') {
            //     state.dictionaryContent = {...content}
            // }
            state.fetchingEnglishDictionaryContent = false
            state.fetchEnglishDictionaryContentFailed = false
            state.fetchedEnglishDictionaryContent = true
        },
        fetchEnglishDictionaryContentFailure: (state, {payload}) => {
            console.log('fetchEnglishDictionaryContentFailure payload', payload)
            state.fetchingEnglishDictionaryContent = false
            state.fetchEnglishDictionaryContentFailed = true
        },

        fetchSpanishDictionaryContent: (state) => {
            state.fetchingSpanishDictionaryContent = true
        },
        fetchSpanishDictionaryContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchSpanishDictionaryContentSuccess payload', payload)

            let content:any = dictionaryToObject(payload.dictionary)

            console.log('🇺🇸 Updating Spanish Dictionary content...', content)
            state.spanishDictionaryContent = content
            // if (state.locale === 'en') {
            //     state.dictionaryContent = {...content}
            // }
            state.fetchingSpanishDictionaryContent = false
            state.fetchSpanishDictionaryContentFailed = false
            state.fetchedSpanishDictionaryContent = true
        },
        fetchSpanishDictionaryContentFailure: (state, {payload}) => {
            console.log('fetchSpanishDictionaryContentFailure payload', payload)
            state.fetchingSpanishDictionaryContent = false
            state.fetchSpanishDictionaryContentFailed = true
        },

        fetchEnglishActivationContent: (state) => {
            state.fetchingEnglishActivationContent = true
        },
        fetchEnglishActivationContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishActivationContentSuccess payload', payload)

            let content:any = normalizeContent(payload.activation)

            console.log('🇺🇸 Updating English Activation content...', content)
            state.englishActivationContent = content
            // if (state.locale === 'en') {
            //     state.content = {...content}
            // }
            state.fetchingEnglishActivationContent = false
            state.fetchEnglishActivationContentFailed = false
            state.fetchedEnglishActivationContent = true
        },
        fetchEnglishActivationContentFailure: (state, {payload}) => {
            console.log('fetchEnglishActivationContentFailure payload', payload)
            state.fetchingEnglishActivationContent = false
            state.fetchEnglishActivationContentFailed = true
        },

        fetchSpanishActivationContent: (state) => {
            state.fetchingSpanishActivationContent = true
        },
        fetchSpanishActivationContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishActivationContentSuccess payload', payload)

            let content:any = normalizeContent(payload.activation)

            console.log('🇲🇽 Updating Spanish Activation content...', content)
            state.spanishActivationContent = content
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishActivationContent = false
            state.fetchSpanishActivationContentFailed = false
            state.fetchedSpanishActivationContent = true
        },
        fetchSpanishActivationContentFailure: (state, {payload}) => {
            console.log('fetchSpanishActivationContentFailure payload', payload)
            state.fetchingSpanishActivationContent = false
            state.fetchSpanishActivationContentFailed = true
        },

        fetchEnglishDashboardContent: (state) => {
            state.fetchingEnglishDashboardContent = true
        },
        fetchEnglishDashboardContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishDashboardContentSuccess payload', payload)

            let content:any = normalizeContent(payload.accountDashboard)

            console.log('🇺🇸 Updating English Dashboard content...', content)
            state.englishDashboardContent = content
            state.fetchingEnglishDashboardContent = false
            state.fetchEnglishDashboardContentFailed = false
            state.fetchedEnglishDashboardContent = true
        },
        fetchEnglishDashboardContentFailure: (state, {payload}) => {
            console.log('fetchEnglishDashboardContentFailure payload', payload)
            state.fetchingEnglishDashboardContent = false
            state.fetchEnglishDashboardContentFailed = true
        },

        fetchSpanishDashboardContent: (state) => {
            state.fetchingSpanishDashboardContent = true
        },
        fetchSpanishDashboardContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishDashboardContentSuccess payload', payload)

            let content:any = normalizeContent(payload.accountDashboard)

            console.log('🇲🇽 Updating Spanish Dashboard content...', content)
            state.spanishDashboardContent = content
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishDashboardContent = false
            state.fetchSpanishDashboardContentFailed = false
            state.fetchedSpanishDashboardContent = true
        },
        fetchSpanishDashboardContentFailure: (state, {payload}) => {
            console.log('fetchSpanishDashboardContentFailure payload', payload)
            state.fetchingSpanishDashboardContent = false
            state.fetchSpanishDashboardContentFailed = true
        },

        fetchEnglishCartContent: (state) => {
            state.fetchingEnglishCartContent = true
        },
        fetchEnglishCartContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishCartContentSuccess payload', payload)

            let products:any = payload.products
            let cartPages:any = payload.cartPages
            let cartContent:any = payload.cartContent

            console.log('🇺🇸 Updating English Cart content...', {products, cartPages, cartContent})
            state.englishCartContent = {products, cartPages, cartContent}
            state.fetchingEnglishCartContent = false
            state.fetchEnglishCartContentFailed = false
            state.fetchedEnglishCartContent = true
        },
        fetchEnglishCartContentFailure: (state, {payload}) => {
            console.log('fetchEnglishCartContentFailure payload', payload)
            state.fetchingEnglishCartContent = false
            state.fetchEnglishCartContentFailed = true
        },

        fetchSpanishCartContent: (state) => {
            state.fetchingSpanishCartContent = true
        },
        fetchSpanishCartContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishCartContentSuccess payload', payload)

            let products:any = payload.products
            let cartPages:any = payload.cartPages
            let cartContent:any = payload.cartContent

            console.log('🇲🇽 Updating Spanish Cart content...', {products, cartPages, cartContent})
            state.spanishCartContent = {products, cartPages, cartContent}
            state.fetchingSpanishCartContent = false
            state.fetchSpanishCartContentFailed = false
            state.fetchedSpanishCartContent = true
        },
        fetchSpanishCartContentFailure: (state, {payload}) => {
            console.log('fetchSpanishCartContentFailure payload', payload)
            state.fetchingSpanishCartContent = false
            state.fetchSpanishCartContentFailed = true
        },

        fetchEnglishBuyflowContent: (state) => {
            state.fetchingEnglishBuyflowContent = true
        },
        fetchEnglishBuyflowContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishBuyflowContentSuccess payload', payload)
            let buyflowContent
            buyflowContent = {}
            payload.buyflow.forEach((obj) => {
                const keys = Object.keys(obj)
                keys.forEach((key) => {
                    buyflowContent[key] = obj[key]
                })
            })

            console.log('🇺🇸 Updating English Buyflow content...', buyflowContent)
            state.englishBuyflowContent = buyflowContent
            state.fetchingEnglishBuyflowContent = false
            state.fetchEnglishBuyflowContentFailed = false
            state.fetchedEnglishBuyflowContent = true
        },
        fetchEnglishBuyflowContentFailure: (state, {payload}) => {
            console.log('fetchEnglishBuyflowContentFailure payload', payload)
            state.fetchingEnglishBuyflowContent = false
            state.fetchEnglishBuyflowContentFailed = true
        },

        fetchSpanishBuyflowContent: (state) => {
            state.fetchingSpanishBuyflowContent = true
        },
        fetchSpanishBuyflowContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishBuyflowContentSuccess payload', payload)

            let buyflowContent
            buyflowContent = {}
            payload.buyflow.forEach((obj) => {
                const keys = Object.keys(obj)
                keys.forEach((key) => {
                    buyflowContent[key] = obj[key]
                })
            })

            console.log('🇲🇽 Updating Spanish Buyflow content...', buyflowContent)
            state.spanishBuyflowContent = buyflowContent
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishBuyflowContent = false
            state.fetchSpanishBuyflowContentFailed = false
            state.fetchedSpanishBuyflowContent = true
        },
        fetchSpanishBuyflowContentFailure: (state, {payload}) => {
            console.log('fetchSpanishBuyflowContentFailure payload', payload)
            state.fetchingSpanishBuyflowContent = false
            state.fetchSpanishBuyflowContentFailed = true
        },

        fetchEnglishRefillContent: (state) => {
            // console.log('fetchEnglishRefillContent')
            state.fetchingEnglishRefillContent = true
        },
        fetchEnglishRefillContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishRefillContentSuccess payload', payload)

            let content:any = normalizeContent(payload.refill)

            console.log('🇺🇸 Updating English Refill content...', content)
            state.englishRefillContent = content
            state.fetchingEnglishRefillContent = false
            state.fetchEnglishRefillContentFailed = false
            state.fetchedEnglishRefillContent = true
        },
        fetchEnglishRefillContentFailure: (state, {payload}) => {
            console.log('fetchEnglishRefillContentFailure payload', payload)
            state.fetchingEnglishRefillContent = false
            state.fetchEnglishRefillContentFailed = true
        },

        fetchSpanishRefillContent: (state) => {
            state.fetchingSpanishRefillContent = true
        },
        fetchSpanishRefillContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishRefillContentSuccess payload', payload)

            let content:any = normalizeContent(payload.refill)

            console.log('🇲🇽 Updating Spanish Refill content...', content)
            state.spanishRefillContent = content
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishRefillContent = false
            state.fetchSpanishRefillContentFailed = false
            state.fetchedSpanishRefillContent = true
        },
        fetchSpanishRefillContentFailure: (state, {payload}) => {
            console.log('fetchSpanishRefillContentFailure payload', payload)
            state.fetchingSpanishRefillContent = false
            state.fetchSpanishRefillContentFailed = true
        },


        fetchEnglishMerchantContent: (state) => {
            // console.log('fetchEnglishMerchantContent')
            state.fetchingEnglishMerchantContent = true
        },
        fetchEnglishMerchantContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishMerchantContentSuccess payload', payload)

            let content:any = contentArrayToObj(payload.merchantFlow)

            console.log('🇺🇸 Updating English Merchant content...', content)
            state.englishMerchantContent = content
            state.fetchingEnglishMerchantContent = false
            state.fetchEnglishMerchantContentFailed = false
            state.fetchedEnglishMerchantContent = true
        },
        fetchEnglishMerchantContentFailure: (state, {payload}) => {
            console.log('fetchEnglishMerchantContentFailure payload', payload)
            state.fetchingEnglishMerchantContent = false
            state.fetchEnglishMerchantContentFailed = true
        },

        fetchSpanishMerchantContent: (state) => {
            state.fetchingSpanishMerchantContent = true
        },
        fetchSpanishMerchantContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishMerchantContentSuccess payload', payload)

            let content:any = contentArrayToObj(payload.merchantFlow)

            console.log('🇲🇽 Updating Spanish Merchant content...', content)
            state.spanishMerchantContent = content
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishMerchantContent = false
            state.fetchSpanishMerchantContentFailed = false
            state.fetchedSpanishMerchantContent = true
        },
        fetchSpanishMerchantContentFailure: (state, {payload}) => {
            console.log('fetchSpanishMerchantContentFailure payload', payload)
            state.fetchingSpanishMerchantContent = false
            state.fetchSpanishMerchantContentFailed = true
        },



        fetchEnglishMoveContent: (state) => {
            // console.log('fetchEnglishMoveContent')
            state.fetchingEnglishMoveContent = true
        },
        fetchEnglishMoveContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishMoveContentSuccess payload', payload)

            let content:any = contentArrayToObj(payload.moving)

            console.log('🇺🇸 Updating English Move content...', content)
            state.englishMoveContent = content
            state.fetchingEnglishMoveContent = false
            state.fetchEnglishMoveContentFailed = false
            state.fetchedEnglishMoveContent = true
        },
        fetchEnglishMoveContentFailure: (state, {payload}) => {
            console.log('fetchEnglishMoveContentFailure payload', payload)
            state.fetchingEnglishMoveContent = false
            state.fetchEnglishMoveContentFailed = true
        },

        fetchSpanishMoveContent: (state) => {
            state.fetchingSpanishMoveContent = true
        },
        fetchSpanishMoveContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishMoveContentSuccess payload', payload)

            let content:any = contentArrayToObj(payload.moving)

            console.log('🇲🇽 Updating Spanish Move content...', content)
            state.spanishMoveContent = content
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishMoveContent = false
            state.fetchSpanishMoveContentFailed = false
            state.fetchedSpanishMoveContent = true
        },
        fetchSpanishMoveContentFailure: (state, {payload}) => {
            console.log('fetchSpanishMoveContentFailure payload', payload)
            state.fetchingSpanishMoveContent = false
            state.fetchSpanishMoveContentFailed = true
        },


        fetchEnglishReconnectContent: (state) => {
            // console.log('fetchEnglishReconnectContent')
            state.fetchingEnglishReconnectContent = true
        },
        fetchEnglishReconnectContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishReconnectContentSuccess payload', payload)

            let content:any = contentArrayToObj(payload.reconnect)

            console.log('🇺🇸 Updating English Reconnect content...', content)
            state.englishReconnectContent = content
            state.fetchingEnglishReconnectContent = false
            state.fetchEnglishReconnectContentFailed = false
            state.fetchedEnglishReconnectContent = true
        },
        fetchEnglishReconnectContentFailure: (state, {payload}) => {
            console.log('fetchEnglishReconnectContentFailure payload', payload)
            state.fetchingEnglishReconnectContent = false
            state.fetchEnglishReconnectContentFailed = true
        },

        fetchSpanishReconnectContent: (state) => {
            state.fetchingSpanishReconnectContent = true
        },
        fetchSpanishReconnectContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishReconnectContentSuccess payload', payload)

            let content:any = contentArrayToObj(payload.reconnect)

            console.log('🇲🇽 Updating Spanish Reconnect content...', content)
            state.spanishReconnectContent = content
            // if (state.locale === 'es') {
            //     state.content = {...content}
            // }
            state.fetchingSpanishReconnectContent = false
            state.fetchSpanishReconnectContentFailed = false
            state.fetchedSpanishReconnectContent = true
        },
        fetchSpanishReconnectContentFailure: (state, {payload}) => {
            console.log('fetchSpanishReconnectContentFailure payload', payload)
            state.fetchingSpanishReconnectContent = false
            state.fetchSpanishReconnectContentFailed = true
        },

    },
})

export {content}
