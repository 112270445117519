import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ProgressSpinner from "../ProgressSpinner";
import NotificationBanner from "../../components/NotificationBanner";
import SuccessBanner from "../SuccessBanner";
import RemoveCCModal from "../dashboard/RemoveCCModal";
import withDashboard from "../../redux/connectors/dashboard";
import { JSEncrypt } from "jsencrypt";
import { errorForCode } from "../../lib/errors";
import { trackEvent } from "../../utils/utils";
import Payment from "../Payment";
import PaymentComponent from "../PaymentComponent";
import {
  digitalDataButtonClick,
  digitalDataValidationError,
} from "../../utils/adobe-analytics";

(function (prototype) {
  if (typeof prototype.requestSubmit == "function") return;

  prototype.requestSubmit = function (submitter) {
    if (submitter) {
      validateSubmitter(submitter, this);
      submitter.click();
    } else {
      submitter = document.createElement("input");
      submitter.type = "submit";
      submitter.hidden = true;
      this.appendChild(submitter);
      submitter.click();
      this.removeChild(submitter);
    }
  };

  function validateSubmitter(submitter, form) {
    submitter instanceof HTMLElement ||
      raise(TypeError, "parameter 1 is not of type 'HTMLElement'", "Error");
    submitter.type === "submit" ||
      raise(TypeError, "The specified element is not a submit button", "Error");
    submitter.form === form ||
      raise(
        DOMException,
        "The specified element is not owned by this form element",
        "NotFoundError"
      );
  }

  function raise(errorConstructor, message, name) {
    throw new errorConstructor(
      "Failed to execute 'requestSubmit' on 'HTMLFormElement': " +
        message +
        ".",
      name
    );
  }
})(HTMLFormElement.prototype);

const PREPAID_PUBLIC_KEY =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCL0uzNpAvff4QkZCbyF3N1U5e54pnWSi7E514ETZ+4/hybGJ5XoneG2twZzSb1YTa/KE7wPNyl4y4w0tUC9eMuXG9c6U8CELHDwmcr84VrO0B6Na0ckvMaE7cWxKx7dEMoxT+LMG/MdAKhInvq95xxSxq/SpMeQr1WjB2whqI06QIDAQAB";

const encrypt = (content) => {
  const crypto = new JSEncrypt({});

  crypto.setPublicKey(PREPAID_PUBLIC_KEY);
  return crypto.encrypt(content);
};

let validationExpirationMonth = "";
let validationExpirationYear = "";
let validationCVV = "";

const uglyToNiceCardTypeMap = {
  MASTER_CARD: "mastercard",
  Visa: "visa",
  Discover: "discover",
  AMERICAN_EXPRESS: "american-express",
};

const PaymentMethod = (props) => {
  const {
    // component props
    minimalView, // Doesn't show the address
    refillView, // Shows "Enroll in Auto-Refill now" checkbox and blurb, and Save this payment method
    refillMoveView, //Different tracking events
    showNameOnCard,
    showAutoRefill,
    hideButton,
    ignoreSaved,
    onContinue,
    onEdit,
    onUpdate,
    refillMoveReview,
    refillCPCEdit,
    refillMovePayment,
    moveView,

    // redux props
    currentLocale,
    accountId,
    dashboard,
    paymentInfo,
    fetchPaymentInfo,
    fetchingPaymentInfo,
    fetchedPaymentInfo,
    dashboardContent,
    dictionaryContent,
    refillContent,
    updateBilling,
    resetBilling,
    removePaymentInstrument,
    removedPaymentInstrument,
    addPaymentInstrument,
    addedPaymentInstrument,
    updatePaymentInstrument,
    updatedPaymentInstrument,
    addPaymentInstrumentFailed,
    removePaymentInstrumentFailed,
    updatePaymentInstrumentFailed,
    paymentInstrumentErrorCode,
    autoRefillEnabled,
    billing,
    cardTypeMap,
    startCPCEvents,
    setFormValid,
    trackingId,
    updateCpcResponse,
    cpcResponse,
    enableAutoRefillFailed,
    fetchBroadbandFactsContent,
  } = props;

  const [readyToRender, setReadyToRender] = useState(false);
  const [editing, setEditing] = useState(false);
  const [cancellable, setCancellable] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [hasSavedCard, setHasSavedCard] = useState(false);
  const [savedCardType, setSavedCardType] = useState("");
  const [cardDisplayNumber, setCardDisplayNumber] = useState("");
  const [showSuccessBanner, setShowSuccesBanner] = useState(false);
  const [localChangesOnly, setLocalChangesOnly] = useState(false);
  const [apiError, setApiError] = useState("");
  const [enrollInAutoRefill, setEnrollInAutoRefill] = useState(false);
  const [value, setValue] = useState("");
  const [isCPCLoaded, setCPCLoader] = useState(true);
  const { handleSubmit, register, trigger, errors } = useForm();
  const [cpcTermsAndConditions, setcpcTermsAndConditions] = useState(false);
  const [isapiError, setErrorApi] = useState(true);

  // let cardType = ''
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const now = new Date().getUTCFullYear();
  const years = Array(now + 15 - now)
    .fill("")
    .map((v, idx) => now + idx) as Array<number>;
  const states = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const history = useHistory();

  useEffect(() => {
    sessionStorage.removeItem("alreadyLoadedRefill");
    sessionStorage.removeItem("alreadyLoadedMove");
    if (refillMovePayment || !paymentInfo) {
      setHasSavedCard(false);
    }
  }, []);

  useEffect(() => {
    if (editing) {
      if (onEdit) {
        onEdit();
      }
    }
    return function cleanup() {
      if (billing?.isValid) {
        if (
          !billing.cardNumberLastFour ||
          !billing.firstName ||
          !billing.lastName ||
          !billing.cardType ||
          !billing.expirationDate
        ) {
          if (!refillMoveReview && !addedPaymentInstrument) {
            updateBilling({ isValid: false });
          }
        }
      }
    };
  }, [editing, onEdit]); // eslint-disable-line

  useEffect(() => {
    if (billing?.cvv && billing?.cvv !== validationCVV) {
      validationCVV = billing.cvv;
    }

    if (
      billing?.expirationMonth &&
      billing?.expirationMonth !== validationExpirationMonth
    ) {
      validationExpirationMonth = billing.expirationMonth;
    }

    if (
      billing?.expirationYear &&
      billing?.expirationYear !== validationExpirationYear
    ) {
      validationExpirationYear = billing.expirationYear;
    }
  }, [billing]);

  useEffect(() => {
    if (billing?.isValid) {
      setLocalChangesOnly(true);
      setCardDisplayNumber(billing.cardDisplayNumber);
      setEditing(false);
      setUpdating(false);
      setHasSavedCard(true);
    }
  }, [billing?.isValid]); // eslint-disable-line

  useEffect(() => {
    if (billing?.submitAndContinue) {
      updateBilling({ submitAndContinue: false });
      if (editing) {
        const paymentForm = document.forms["payment-form"];
        if (paymentForm) {
          setProcessNext(true);
        }
      } else {
        if (onContinue) {
          onContinue();
        }
      }
    }
  }, [billing?.submitAndContinue]); // eslint-disable-line

  useEffect(() => {
    let refillstepNumber, movestepNumber;
    let refillstepName, movestepName;

    if (updatedPaymentInstrument) {
      if (minimalView) {
        if (refillMoveView) {
          movestepNumber = refillMoveReview ? "step 3" : "step 1";
          movestepName = refillMoveReview ? "order review" : "start refill";
          trackEvent({
            event: "move refill info",
            moveStep: movestepNumber,
            moveStepName: movestepName,
            moveEvent: "payment method component",
            moveDetail: "update success",
          });
        } else if (refillView) {
          refillstepNumber = refillMoveReview ? "step 3" : "step 1";
          refillstepName = refillMoveReview ? "order review" : "start refill";
          trackEvent({
            event: "refill info",
            refillStep: refillstepNumber,
            refillStepName: refillstepName,
            refillEvent: "payment method component",
            refillDetail: "update success",
          });
        } else {
          trackEvent({
            event: "dashboard",
            dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
            dashboardEvent: "payment method component",
            dashboardDetail: "update success",
          });
        }
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: "update success",
        });
      }
    } else if (addedPaymentInstrument) {
      if (minimalView) {
        if (!refillMoveReview) {
          trackEvent({
            event: "dashboard",
            dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
            dashboardEvent: "payment method component",
            dashboardDetail: "add success",
          });
        }
      } else if (refillMovePayment) {
        if (!moveView) {
          trackEvent({
            event: "refill info",
            refillStep: "step 2",
            refillStepName: "payment",
            refillEvent: "payment method component",
            refillDetail: "add success",
          });
        } else {
          trackEvent({
            event: "move refill info",
            moveStep: "step 2",
            moveStepName: "payment",
            moveEvent: "payment method component",
            moveDetail: "add success",
          });
        }
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: "add success",
        });
      }
    } else if (removedPaymentInstrument) {
      if (minimalView) {
        if (refillMoveView) {
          trackEvent({
            event: "move refill info",
            moveStep: "step 1",
            moveStepName: "start refill",
            moveEvent: "payment method component",
            moveDetail: "delete success",
          });
        } else {
          trackEvent({
            event: "dashboard",
            dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
            dashboardEvent: "payment method component",
            dashboardDetail: "delete success",
          });
        }
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: "delete success",
        });
      }
    }

    let shouldUpdate =
      removedPaymentInstrument ||
      addedPaymentInstrument ||
      updatedPaymentInstrument;
    shouldUpdate = shouldUpdate && !fetchingPaymentInfo;
    if (shouldUpdate) {
      if (updating && !fetchingPaymentInfo) {
        console.log("fetching payment info...");
        fetchPaymentInfo({
          accountId,
          phoneNumber: dashboard.customerInfo.phoneNumber,
        });
      }

      if (!refillMoveReview && !refillMoveView) {
        sessionStorage.removeItem("cpcTermsAndConditions");
        sessionStorage.removeItem("enrollInAutoRefill");
        sessionStorage.removeItem("errorReloadCount");
        window.location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    removedPaymentInstrument,
    addedPaymentInstrument,
    updatedPaymentInstrument,
  ]); // eslint-disable-line

  useEffect(() => {
    if (
      addPaymentInstrumentFailed ||
      removePaymentInstrumentFailed ||
      updatePaymentInstrumentFailed ||
      enableAutoRefillFailed
    ) {
      const error = errorForCode(
        paymentInstrumentErrorCode || "",
        currentLocale
      );
      setUpdating(false);
      if (isapiError) {
        setApiError(error);
      }
      digitalDataValidationError(
        "payment method",
        [
          "addPaymentInstrumentFailed",
          "removePaymentInstrumentFailed",
          "updatePaymentInstrumentFailed",
        ],
        "payment instrument invalid",
        ".payment-method"
      );
    }
  }, [
    addPaymentInstrumentFailed,
    currentLocale,
    paymentInstrumentErrorCode,
    removePaymentInstrumentFailed,
    updatePaymentInstrumentFailed,
    enableAutoRefillFailed,
    isapiError,
    updating,
  ]); // eslint-disable-line

  useEffect(() => {
    if (!localChangesOnly && !billing?.isValid) {
      if (fetchedPaymentInfo) {
        setCancellable(!!paymentInfo);
        setHasSavedCard(!!paymentInfo);
        if (updating) {
          console.log("finalizing update...");
          setEditing(false);
          setUpdating(false);
          console.log("resetBilling 1");
          resetBilling();
          setCardDisplayNumber("");
          setShowSuccesBanner(true);
          if (onUpdate) {
            onUpdate();
          }
        }
        window.scrollTo(0, 0);
      }

      if (!paymentInfo || ignoreSaved) {
        if (!billing?.isValid) {
          setEditing(true);
        }
        if (sessionStorage.getItem("cpc_card_type")) {
          const cpcCardType = sessionStorage.getItem("cpc_card_type");
          const sessionCPCCardType = cpcCardType ? cpcCardType : "";
          setSavedCardType(sessionCPCCardType);
        }
      } else if (fetchedPaymentInfo) {
        let name = "";
        if (
          paymentInfo?.custInfo?.firstName &&
          paymentInfo?.custInfo?.lastName
        ) {
          name = `${paymentInfo.custInfo.firstName} ${paymentInfo.custInfo.lastName}`;
        }
        updateBilling({
          name: name,
          firstName: paymentInfo?.custInfo.firstName,
          lastName: paymentInfo?.custInfo.lastName,
          cardNumberLastFour: paymentInfo?.cardNumber,
          cardNiceType: uglyToNiceCardTypeMap[paymentInfo?.cardType],
          expirationMonth: paymentInfo?.expirationDate.slice(0, 2),
          expirationYear: "20" + paymentInfo?.expirationDate.slice(2, 4),
          addressLine1: paymentInfo?.custInfo?.addressLine1,
          addressLine2: paymentInfo?.custInfo?.addressLine2,
          city: paymentInfo?.custInfo?.city,
          state: paymentInfo?.custInfo?.state,
          zip: paymentInfo?.custInfo?.zip,
        });

        setCardDisplayNumber(
          paymentInfo?.cardNumber
            ? `xxxx-xxxx-xxxx-${paymentInfo?.cardNumber}`
            : ""
        );
        setHasSavedCard(true);
        if (paymentInfo?.cardType) {
          setSavedCardType(paymentInfo?.cardType);
        } else if (sessionStorage.getItem("cpc_card_type")) {
          const cpcCardType = sessionStorage.getItem("cpc_card_type");
          const sessionCPCCardType = cpcCardType ? cpcCardType : "";
          setSavedCardType(sessionCPCCardType);
        }
      }
    }
    setReadyToRender(true);
  }, [fetchedPaymentInfo, paymentInfo]); // eslint-disable-line

  useEffect(() => {
    if (
      refillMoveReview &&
      (addedPaymentInstrument || updatedPaymentInstrument)
    ) {
      setLocalChangesOnly(true);
      setCardDisplayNumber(billing?.cardDisplayNumber);
      setEditing(false);
      setUpdating(false);
      setHasSavedCard(true);
      updateBilling({ submitAndContinue: false, isValid: true });
      if (updatedPaymentInstrument) {
        onUpdate();
      }
    }
  }, [refillMoveReview, addedPaymentInstrument, updatedPaymentInstrument]); // eslint-disable-line

  useEffect(() => {
    const savePaymentVal =
      sessionStorage.getItem("setcpcTermsAndConditions") === "true"
        ? true
        : false;
    setcpcTermsAndConditions(savePaymentVal);
  }, []);

  useEffect(() => {
    const enableAutoRefillVal =
      sessionStorage.getItem("enrollInAutoRefill") === "true" ? true : false;
    setEnrollInAutoRefill(enableAutoRefillVal);
  }, []);

  /* CPC */
  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [cpcProcessing, setCpcProcessing] = useState(false);
  const [loadCPCForm, setLoadCPCForm] = useState(false);
  const [cpcToken, setCpcToken] = useState();

  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);

  useEffect(() => {
    updateFormStatus(!cpcProcessing);
    setFormValid(!cpcProcessing);
  }, [cpcProcessing]); // eslint-disable-line

  useEffect(() => {
    if (!updating && editing && apiError !== "") {
      let errorReloadCount: any;
      errorReloadCount = sessionStorage.getItem("errorReloadCount");

      if (errorReloadCount == null) {
        errorReloadCount = 1;
      } else {
        errorReloadCount = Number(errorReloadCount) + 1;
      }
      sessionStorage.setItem("errorReloadCount", errorReloadCount);
      sessionStorage.setItem("currentURL", window.location.pathname);
      setTimeout(function () {
        setUpdating(false);
        setCpcProcessing(false);
        setCPCLoader(false);
        setApiError("");
        setErrorApi(false);
      }, 30000);
      if (errorReloadCount >= 3) {
        sessionStorage.removeItem("errorReloadCount");
        history?.push(`/${props.currentLocale}/dashboard`);
      }
    } else {
      setErrorApi(true);
    }
  }, [updating, editing, hasSavedCard, apiError]);

  const paymentProcessing = (value) => {
    setCpcProcessing(value);
  };

  const paymentProcessed = (response) => {
    setCpcToken(response?.cpcData?.cardDetails?.token);
    onUpdateField(response);
    updateCpcResponse(response);
    setProcessNext(false);
    console.log("processNext value", processNext);
    goToOrderReview(response);
  };

  const updateFormValid = (valid) => {
    setFormValid(valid);
    updateFormStatus(valid);
    if (valid) {
      setProcessNext(false);
    }
  };

  const toggleEditMode = () => {
    let refillstepNumber, movestepNumber;
    let refillstepName, movestepName;
    if (!editing) {
      if (minimalView) {
        if (refillMoveView) {
          movestepNumber = refillMoveReview ? "step 3" : "step 1";
          movestepName = refillMoveReview ? "order review" : "start refill";
          trackEvent({
            event: "move refill info",
            moveStep: movestepNumber,
            moveStepName: movestepName,
            moveEvent: "payment method component",
            moveDetail: "edit",
          });
        } else if (refillView) {
          refillstepNumber = refillMoveReview ? "step 3" : "step 1";
          refillstepName = refillMoveReview ? "order review" : "start refill";
          trackEvent({
            event: "refill info",
            refillStep: refillstepNumber,
            refillStepName: refillstepName,
            refillEvent: "payment method component",
            refillDetail: "edit",
          });
        } else {
          trackEvent({
            event: "dashboard",
            dashboardPage: "auto refill",
            dashboardEvent: "payment method component",
            dashboardDetail: "edit",
          });
        }
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: "edit",
        });
      }
    }
    setEditing(!editing);
  };

  const removeCard = () => {
    console.log("removeCard");
    if (minimalView) {
      if (refillMoveView) {
        trackEvent({
          event: "move refill info",
          moveStep: "step 1",
          moveStepName: "start refill",
          moveEvent: "payment method component",
          moveDetail: "delete submit",
        });
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: "delete submit",
        });
      }
    } else {
      trackEvent({
        event: "dashboard",
        dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
        dashboardEvent: "payment method component",
        dashboardDetail: "delete submit",
      });
    }

    setUpdating(true);
    removePaymentInstrument({
      custInfo: { accountId },
      paymentInstToken: paymentInfo.paymentInstToken,
    });
    window.scrollTo(0, 0);
  };

  const onBlurField = (e) => {
    trigger(e.target.name.toString());
    if (e.target.name === "cardDisplayNumber") {
      const tempCardNumber = e.target.value.split("-").join("");
      const tempLastFour = tempCardNumber.substr(tempCardNumber.length - 4);
      updateBilling({
        cardNumber: tempCardNumber,
        cardNumberLastFour: tempLastFour,
        [e.target.name]: e.target.value,
      });
      // in case of a browser auto-fill, we need to force updated the month, year and cvv
      const expMonth = (
        document.getElementById("expirationMonth") as HTMLInputElement
      ).value;
      const expYear = (
        document.getElementById("expirationYear") as HTMLInputElement
      ).value;
      const cvv = (document.getElementById("cvv") as HTMLInputElement).value;
      const billingName = (document.getElementById("name") as HTMLInputElement)
        .value;
      updateBilling({
        expirationMonth: expMonth,
        expirationYear: expYear,
        cvv: cvv,
        name: billingName,
      });
    } else if (e.target.name === "name") {
      updateBilling({ [e.target.name]: e.target.value });
      const cc = (
        document.getElementById("cardDisplayNumber") as HTMLInputElement
      ).value;
      if (cc) {
        const tempCardNumber = cc.split("-").join("");
        const tempLastFour = tempCardNumber.substr(tempCardNumber.length - 4);
        updateBilling({
          cardNumber: tempCardNumber,
          cardNumberLastFour: tempLastFour,
          cardDisplayNumber: cc,
        });
        // in case of a browser auto-fill, we need to force updated the month, year and cvv
        const expMonth = (
          document.getElementById("expirationMonth") as HTMLInputElement
        ).value;
        const expYear = (
          document.getElementById("expirationYear") as HTMLInputElement
        ).value;
        const cvv = (document.getElementById("cvv") as HTMLInputElement).value;
        updateBilling({
          expirationMonth: expMonth,
          expirationYear: expYear,
          cvv: cvv,
        });
      }
    } else if (e.target.name === "savePaymentMethod") {
      updateBilling({ [e.target.name]: e.target.checked });
    } else if (e.target.name === "enrollInAutoRefill") {
      updateBilling({ [e.target.name]: e.target.checked });
      sessionStorage.setItem("enrollInAutoRefill", e.target.checked);
      trackEvent({
        event: "dashboard",
        dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
        dashboardEvent: "auto refill set up",
        dashboardDetail: "enroll submit",
      });
      sessionStorage.setItem("enrollInAutoRefill", e.target.checked);
    } else if (e.target.name === "useServiceAddressForBilling") {
      updateBilling({ [e.target.name]: e.target.checked });
      if (e.target.checked) {
        updateBilling({
          addressLine1: dashboard?.serviceAddress?.addressLine1,
          // addressLine2: dashboard?.serviceAddress?.addressLine2,
          city: dashboard?.serviceAddress?.city,
          zip: dashboard?.serviceAddress?.zip,
          state: dashboard?.serviceAddress?.state,
          useServiceAddressForBilling: true,
        });
      } else {
        updateBilling({ useServiceAddressForBilling: false });
      }
    } else if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "zip"
    ) {
      // getting address values and updating state for all fields in case of browser autofill
      const street1 = (
        document.getElementById("addressLine1") as HTMLInputElement
      ).value;
      const street2 = (
        document.getElementById("addressLine2") as HTMLInputElement
      ).value;
      const cityField = (document.getElementById("city") as HTMLInputElement)
        .value;
      const stateField = (document.getElementById("state") as HTMLInputElement)
        .value;
      const zipField = (document.getElementById("zip") as HTMLInputElement)
        .value;
      updateBilling({
        addressLine1: street1,
        addressLine2: street2,
        city: cityField,
        zip: zipField,
        state: stateField,
        useServiceAddressForBilling: false,
      });
      trigger(["state", "city", "zip", "addressLine1"]);
    } else {
      updateBilling({ [e.target.name]: e.target.value });
    }
  };

  const onUpdateField = (e) => {
    console.log(e);
    if (
      refillMovePayment &&
      e.action === "CPC_INFO_EVENT" &&
      e.dataLayerKey === "CpcCardTermsAndConditionSelected"
    ) {
      const termsAndConditons =
        e.data?.event[0]?.eventInfo?.CpcCardTermsAndConditionSelected;
      if (termsAndConditons) {
        sessionStorage.setItem(
          "cpcTermsAndConditions",
          termsAndConditons.currentValue
        );
      }
    }
  };
  const handleNext = async (e) => {
    let refillstepNumber, movestepNumber;
    let refillstepName, movestepName;
    e.preventDefault();
    e.stopPropagation();
    if (minimalView) {
      if (refillMoveView) {
        movestepNumber = refillMoveReview ? "step 3" : "step 1";
        movestepName = refillMoveReview ? "order review" : "start refill";
        trackEvent({
          event: "move refill info",
          moveStep: movestepNumber,
          moveStepName: movestepName,
          moveEvent: "payment method component",
          moveDetail: hasSavedCard ? "update submit" : "add submit",
        });
      } else if (refillView) {
        refillstepNumber = refillMoveReview ? "step 3" : "step 1";
        refillstepName = refillMoveReview ? "order review" : "start refill";
        trackEvent({
          event: "refill info",
          refillStep: refillstepNumber,
          refillStepName: refillstepName,
          refillEvent: "payment method component",
          refillDetail: hasSavedCard ? "update submit" : "add submit",
        });
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: hasSavedCard ? "update submit" : "add submit",
        });
      }
    } else {
      if (!refillMoveReview) {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: "payment method component",
          dashboardDetail: hasSavedCard ? "update submit" : "add submit",
        });
      }
    }
    setProcessNext(true);
    console.log("handleNext");
  };

  const goToOrderReview = (response) => {
    setApiError("");

    if (billing?.submitAndContinue) {
      updateBilling({ submitAndContinue: false, isValid: true });
      onContinue();
      return;
    }
    const savePaymentval = sessionStorage.getItem("cpcTermsAndConditions");
    const savePaymentSession = savePaymentval === "true" ? true : false;

    if (localChangesOnly && !savePaymentval) {
      updateBilling({ submitAndContinue: false, isValid: true });
      setEditing(false);
      if (onUpdate) {
        if (!refillMoveView) {
          trackEvent({
            event: "refill info",
            refillStep: "step 3",
            refillStepName: "order review",
            refillEvent: "payment method component",
            refillDetail: "update success",
          });
        } else {
          trackEvent({
            event: "move refill info",
            MoveStep: "step 3",
            MoveStepName: "order review",
            MoveEvent: "payment method component",
            MoveDetail: "update success",
          });
        }
        if (response.cpcData) {
          updateBilling({
            addressLine1: response?.cpcData?.customerDetails?.address
              ? response?.cpcData?.customerDetails?.address?.line1
              : response?.paymentInfo?.address,
            addressLine2: response?.cpcData?.customerDetails?.address
              ? response?.cpcData?.customerDetails?.address?.line2
              : response?.paymentInfo?.addressLine2,
            city: response?.cpcData?.customerDetails?.address
              ? response?.cpcData?.customerDetails?.address?.city
              : response?.paymentInfo?.city,
            zip: response?.cpcData?.customerDetails?.address
              ? response?.cpcData?.customerDetails?.address?.zip
              : response?.paymentInfo?.zipCode,
            state: response?.cpcData?.customerDetails?.address
              ? response?.cpcData?.customerDetails?.address?.state
              : response?.paymentInfo?.state,
          });
        }
        onUpdate();
      }
      return;
    }

    setUpdating(true);
    if (response.cpcData) {
      console.log("CPC response", response.cpcData);
      updateBilling({
        addressLine1: response?.cpcData?.customerDetails?.address
          ? response?.cpcData?.customerDetails?.address?.line1
          : response?.paymentInfo?.address,
        addressLine2: response?.cpcData?.customerDetails?.address
          ? response?.cpcData?.customerDetails?.address?.line2
          : response?.paymentInfo?.addressLine2,
        city: response?.cpcData?.customerDetails?.address
          ? response?.cpcData?.customerDetails?.address?.city
          : response?.paymentInfo?.city,
        zip: response?.cpcData?.customerDetails?.address
          ? response?.cpcData?.customerDetails?.address?.zipCode
          : response?.paymentInfo?.zipCode,
        state: response?.cpcData?.customerDetails?.address
          ? response?.cpcData?.customerDetails?.address?.state
          : response?.paymentInfo?.state,
        useServiceAddressForBilling: false,
        firstName: response?.cpcData?.customerDetails?.name
          ? response?.cpcData?.customerDetails?.name?.firstName
          : response?.paymentInfo?.firstName,
        lastName: response?.cpcData?.customerDetails?.name
          ? response?.cpcData?.customerDetails?.name?.lastName
          : response?.paymentInfo?.lastName,
        cardNumberLastFour: response?.cpcData?.cardDetails?.cardLast4Digits,
        cardType: response?.cpcData?.cardDetails?.cardType,
        expirationMonth: parseInt(
          response?.cpcData?.cardDetails?.expirationDate?.substring(0, 2)
        ),
        expirationYear: parseInt(
          response?.cpcData?.cardDetails?.expirationDate?.substring(2, 4)
        ),
        expirationDate: response?.cpcData?.cardDetails?.expirationDate,
        paymentInstToken: response?.cpcData?.cardDetails?.token,
      });
    }

    const enrollInAutoRefillSession =
      sessionStorage.getItem("enrollInAutoRefill");
    const enrollInAutoRefill =
      enrollInAutoRefillSession === "true" || autoRefillEnabled ? true : false;
    const broadbandPayload = {
      nameExEmployee: "123434524",
    };
    if (!hasSavedCard) {
      const payload = {
        custInfo: {
          accountId,
          firstName: response?.paymentInfo?.firstName,
          lastName: response?.paymentInfo?.lastName,
          addressLine1: response?.paymentInfo?.address,
          addressLine2: response?.paymentInfo?.addressLine2,
          city: response?.paymentInfo?.city,
          state: response?.paymentInfo?.state,
          zip: response?.paymentInfo?.zipCode,
        },
        enrollAutoPayment: enrollInAutoRefill,
        processingNetwork: "CREDIT",
        addInstrumentToWallet: "true",
        paymentInstToken: response?.cpcData?.cardDetails?.token,
        isCPC: true,
        expirationDate: response?.cpcData?.cardDetails?.expirationDate,
        cardType: response?.cpcData?.cardDetails?.cardType,
        cardNumber: response?.cpcData?.cardDetails?.cardLast4Digits,
        savePayment: true,
      };

      if (refillMoveReview && !savePaymentSession) {
        payload["savePayment"] = false;
      }
      fetchBroadbandFactsContent(broadbandPayload);

      addPaymentInstrument(payload);
    } else {
      const payload = {
        custInfo: {
          accountId,
          firstName: response?.cpcData?.customerDetails?.name
            ? response?.cpcData?.customerDetails?.name?.firstName
            : billing.firstName,
          lastName: response?.cpcData?.customerDetails?.name
            ? response?.cpcData?.customerDetails?.name?.lastName
            : billing.lastName,
          addressLine1: response?.cpcData?.customerDetails?.address
            ? response?.cpcData?.customerDetails?.address?.line1
            : billing.addressLine1,
          addressLine2: response?.cpcData?.customerDetails?.address
            ? response?.cpcData?.customerDetails?.address?.line2
            : billing.addressLine2,
          city: response?.cpcData?.customerDetails?.address
            ? response?.cpcData?.customerDetails?.address?.city
            : billing.city,
          state: response?.cpcData?.customerDetails?.address
            ? response?.cpcData?.customerDetails?.address?.state
            : billing.state,
          zip: response?.cpcData?.customerDetails?.address
            ? response?.cpcData?.customerDetails?.address?.zip
            : billing.zip,
        },
        enrollAutoPayment: enrollInAutoRefill,
        processingNetwork: "CREDIT",
        paymentInstToken: response?.cpcData?.cardDetails?.token
          ? response?.cpcData?.cardDetails?.token
          : paymentInfo.paymentInstToken,
        expirationDate: response?.cpcData?.cardDetails
          ? response?.cpcData?.cardDetails?.expirationDate
          : `${billing.expirationMonth}${billing.expirationYear.slice(2, 4)}`,
        cardType: response?.cpcData?.cardDetails
          ? response?.cpcData?.cardDetails?.cardType
          : paymentInfo.cardType,
        cardNumber: response?.cpcData?.cardDetails
          ? response?.cpcData?.cardDetails?.cardLast4Digits
          : billing.cardNumberLastFour,
        isCPC: true,
      };
      if (
        response.cpcData.submissionDetails.actionTaken !== "update" &&
        response.cpcData.submissionDetails.cpcStatus !== "SUCCESS"
      ) {
        payload.cardType = response?.cpcData?.cardDetails
          ? encrypt(response?.cpcData?.cardDetails?.cardType)
          : encrypt(paymentInfo.cardType);
        payload.cardNumber = response?.cpcData?.cardDetails
          ? encrypt(response?.cpcData?.cardDetails?.cardLast4Digits)
          : encrypt(billing.cardNumberLastFour);
        payload.expirationDate = response?.cpcData?.cardDetails
          ? encrypt(response?.cpcData?.cardDetails?.expirationDate)
          : encrypt(
              `${billing.expirationMonth}${billing.expirationYear.slice(2, 4)}`
            );
        payload.isCPC = false;
      }
      updatePaymentInstrument(payload);
    }
  };
  if (!readyToRender) {
    return <div className={`payment-method card`}></div>;
  }
  const paymentError = (response) => {
    let errorType;
    let errorDetail;
    if (response.type === "form") {
      errorType = "error validation";
      errorDetail = response.message;
    } else {
      errorType = "error";
      errorDetail = response.messageDetail;
    }
    let refillstepNumber, movestepNumber;
    let refillstepName, movestepName;

    digitalDataValidationError(
      "payment method",
      ["payment-error"],
      errorDetail,
      ".payment-method"
    );

    if (response.type !== "form") {
      if (minimalView) {
        if (refillMoveView) {
          movestepNumber = refillMoveReview ? "step 3" : "step 1";
          movestepName = refillMoveReview ? "order review" : "start refill";
          trackEvent({
            event: "move refill info",
            moveStep: movestepNumber,
            moveStepName: movestepName,
            moveEvent: errorType,
            moveDetail: errorDetail,
          });
        } else if (refillView) {
          refillstepNumber = refillMoveReview ? "step 3" : "step 1";
          refillstepName = refillMoveReview ? "order review" : "start refill";
          trackEvent({
            event: "refill info",
            refillStep: refillstepNumber,
            refillStepName: refillstepName,
            refillEvent: errorType,
            refillDetail: errorDetail,
          });
        } else {
          trackEvent({
            event: "dashboard",
            dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
            dashboardEvent: errorType,
            dashboardDetail: errorDetail,
          });
        }
      } else if (refillMovePayment) {
        if (!moveView) {
          trackEvent({
            event: "refill info",
            refillStep: "step 2",
            refillStepName: "payment",
            refillEvent: errorType,
            refillDetail: errorDetail,
          });
        } else {
          trackEvent({
            event: "move refill info",
            moveStep: "step 1",
            moveStepName: "start refill ",
            moveEvent: errorType,
            moveDetail: errorDetail,
          });
        }
      } else {
        trackEvent({
          event: "dashboard",
          dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
          dashboardEvent: errorType,
          dashboardDetail: errorDetail,
        });
      }
    }
  };

  const paymentLoaded = (value) => {
    setCPCLoader(value);
    let errorReloadCount: any = sessionStorage.getItem("errorReloadCount");
    if (errorReloadCount >= 2) {
      setTimeout(function () {
        setApiError("");
      }, 10000);
    }
  };

  return (
    <div
      className={`payment-method ${editing ? "card padding-bottom" : "card"}`}
    >
      {apiError ? (
        <NotificationBanner text={apiError} displayStyle="alert" modal="true" />
      ) : null}
      {cpcProcessing && (
        <SuccessBanner
          text={
            dashboardContent?.dashboardArea?.autoRefill
              ?.successfullyModifiedYourPaymentMethod?.message
          }
          displayModal={showSuccessBanner}
          onClose={() => setShowSuccesBanner(false)}
        />
      )}
      <div className="card__header">
        <h2 className="card__header-title">
          {hasSavedCard && !ignoreSaved
            ? dashboardContent?.dashboardArea?.autoRefill
                ?.yourCurrentPaymentMethod?.text
            : dashboardContent?.dashboardArea?.autoRefill
                ?.enterYourPaymentMethod}
        </h2>
        {hasSavedCard || (editing && cancellable) ? (
          <button
            type="button"
            data-testid="btn-toggle-edit"
            className="totals__edit link-callout"
            aria-labelledby="edit-xitv"
            onClick={toggleEditMode}
            data-tracking={digitalDataButtonClick(
              "totals-edit",
              editing
                ? dictionaryContent?.global?.cancel
                : dictionaryContent?.global?.edit
            )}
          >
            {editing
              ? dictionaryContent?.global?.cancel
              : dictionaryContent?.global?.edit}
          </button>
        ) : null}
      </div>
      {updating && (
        <ProgressSpinner
          loadingText={dictionaryContent?.global?.updating + "…"}
        />
      )}
      {!updating && (
        <div>
          {!editing && hasSavedCard ? (
            <div>
              {showNameOnCard && billing?.firstName && (
                <div>
                  <h4 className="payment-method-header">
                    {dashboardContent?.dashboardArea?.autoRefill?.nameOnCard}
                  </h4>
                  <div className="payment-method-detail">
                    {billing?.firstName} {billing?.lastName}
                  </div>
                </div>
              )}

              {billing?.cardNumberLastFour && (
                <h4 className="payment-method-header">
                  {dashboardContent?.dashboardArea?.autoRefill?.cardNumber}
                </h4>
              )}

              {billing?.cardNumberLastFour && (
                <div className="payment-method-detail">
                  xxxx-xxxx-xxxx-{billing?.cardNumberLastFour}
                  <img
                    className="payment-method-icon"
                    alt={savedCardType}
                    src={`/site-assets/img/${savedCardType.toLocaleLowerCase()}.svg`}
                  />
                </div>
              )}
              {!minimalView && billing?.addressLine1 ? (
                <div>
                  <h4 className="payment-method-header">
                    {dictionaryContent?.formLabels?.expiration}
                  </h4>
                  <div className="payment-method-detail">
                    {billing?.expirationMonth}/{billing?.expirationYear}
                  </div>

                  <h4 className="payment-method-header">
                    {dictionaryContent?.formLabels?.billingAddress}
                  </h4>
                  <div className="payment-method-detail">
                    {billing?.addressLine1}
                  </div>
                  <div className="payment-method-detail">
                    {billing?.city}, {billing?.state} {billing?.zip}
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="card ">
              <form id="payment-form">
                <div className="main__width-constraint">
                  <div className="card">
                    <div className={cpcProcessing || isCPCLoaded ? "" : "vh"}>
                      <ProgressSpinner
                        loadingText={
                          isCPCLoaded ? "Loading..." : "Processing..."
                        }
                      />
                    </div>

                    {refillMoveReview ? (
                      <div className={cpcProcessing ? "vh" : ""}>
                        {loadCPCForm && (
                          <PaymentComponent
                            cpcPageType={
                              refillCPCEdit ? "CardOnlyWithEdit" : "CardOnly"
                            }
                          />
                        )}

                        <Payment
                          addressLine1={
                            billing?.addressLine1
                              ? billing.addressLine1
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.addressLine1
                              : dashboard?.serviceAddress?.addressLine1
                          }
                          addressLine2={
                            billing?.addressLine2
                              ? billing.addressLine2
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.addressLine2
                              : dashboard?.serviceAddress?.addressLine2
                          }
                          city={
                            billing?.city
                              ? billing.city
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.city
                              : dashboard?.serviceAddress?.city
                          }
                          zip={
                            billing?.zip
                              ? billing.zip
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.zip
                              : dashboard?.serviceAddress?.zip
                          }
                          UsState={
                            billing?.state
                              ? billing.state
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.state
                              : dashboard?.serviceAddress?.state
                          }
                          trackingId={trackingId}
                          custGuid={dashboard?.customerInfo?.prepaidPaymentID}
                          firstName={
                            billing?.firstName
                              ? billing.firstName
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.firstName
                              : dashboard?.serviceAddress?.firstName
                          }
                          lastName={
                            billing?.lastName
                              ? billing.lastName
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.lastName
                              : dashboard?.serviceAddress?.lastName
                          }
                          processNext={processNext}
                          paymentProcessing={paymentProcessing}
                          paymentProcessed={paymentProcessed}
                          updateFormValid={updateFormValid}
                          showAddress={false}
                          loadCPCForm={loadCPCForm}
                          paymentInstToken={cpcToken}
                          onUpdateField={onUpdateField}
                          channelName={"PREPAID_TKN"}
                          sourceSystemId={"PREPAID_TKN_CPC"}
                          enableFraudManager={false}
                          cpcMessageDisplayLocation={"top"}
                          displayAddressOverride={true}
                          displayAutoPayEnroll={false}
                          displayStoredPaymentOption={
                            refillMovePayment ? true : false
                          }
                          customerClass={"residential"}
                          paymentToken={
                            cpcResponse?.cpcData?.cardDetails
                              ? cpcResponse.cpcData?.cardDetails?.token
                              : billing?.paymentInstToken ||
                                paymentInfo?.paymentInstToken
                          }
                          storePaymentInstrumentLongTerm={
                            sessionStorage.getItem("cpcTermsAndConditions") ===
                            "true"
                              ? true
                              : false
                          }
                          cpcPageTypeVal={
                            refillCPCEdit ? "CardOnlyWithEdit" : "CardOnly"
                          }
                          paymentError={paymentError}
                          paymentLoaded={paymentLoaded}
                        />
                      </div>
                    ) : (
                      <div className={cpcProcessing ? "vh" : ""}>
                        {hasSavedCard
                          ? loadCPCForm && (
                              <PaymentComponent cpcPageType="CardOnlyWithEdit" />
                            )
                          : loadCPCForm && (
                              <PaymentComponent cpcPageType="CardOnly" />
                            )}
                        <Payment
                          addressLine1={
                            billing?.addressLine1
                              ? billing.addressLine1
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.addressLine1
                              : dashboard?.serviceAddress?.addressLine1
                          }
                          addressLine2={
                            billing?.addressLine2
                              ? billing.addressLine2
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.addressLine2
                              : dashboard?.serviceAddress?.addressLine2
                          }
                          city={
                            billing?.city
                              ? billing.city
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.city
                              : dashboard?.serviceAddress?.city
                          }
                          zip={
                            billing?.zip
                              ? billing.zip
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.zip
                              : dashboard?.serviceAddress?.zip
                          }
                          UsState={
                            billing?.state
                              ? billing.state
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.state
                              : dashboard?.serviceAddress?.state
                          }
                          trackingId={trackingId}
                          custGuid={dashboard?.customerInfo?.prepaidPaymentID}
                          firstName={
                            billing?.firstName
                              ? billing.firstName
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.firstName
                              : dashboard?.customerInfo?.firstName
                          }
                          lastName={
                            billing?.lastName
                              ? billing.lastName
                              : hasSavedCard
                              ? paymentInfo?.custInfo?.lastName
                              : dashboard?.customerInfo?.lastName
                          }
                          processNext={processNext}
                          paymentProcessing={paymentProcessing}
                          paymentProcessed={paymentProcessed}
                          updateFormValid={updateFormValid}
                          showAddress={false}
                          loadCPCForm={loadCPCForm}
                          paymentInstToken={cpcToken}
                          onUpdateField={onUpdateField}
                          channelName={"PREPAID_TKN"}
                          sourceSystemId={"PREPAID_TKN_CPC"}
                          enableFraudManager={false}
                          cpcMessageDisplayLocation={"top"}
                          displayAddressOverride={true}
                          displayAutoPayEnroll={false}
                          displayStoredPaymentOption={false}
                          customerClass={"residential"}
                          paymentToken={
                            paymentInfo
                              ? paymentInfo.paymentInstToken
                              : cpcToken
                          }
                          storePaymentInstrumentLongTerm={true}
                          cpcPageTypeVal={
                            hasSavedCard ? "CardOnlyWithEdit" : "CardOnly"
                          }
                          paymentError={paymentError}
                          paymentLoaded={paymentLoaded}
                        />
                      </div>
                    )}
                  </div>
                  {showAutoRefill && !autoRefillEnabled ? (
                    <div className="auto-refill-selection">
                      <div className="card__header">
                        <h2 className="card__header-title">
                          {
                            refillContent?.refillFlow?.refillHsdXitv
                              ?.enrollInAutoRefillNow
                          }
                        </h2>
                      </div>
                      <div className="checkbox">
                        <input
                          id="enrollInAutoRefill"
                          data-testid="enroll-auto"
                          type="checkbox"
                          className="checkbox__input"
                          name="enrollInAutoRefill"
                          onChange={(e) => onBlurField(e)}
                        />
                        <label
                          className="checkbox__label"
                          htmlFor="enrollInAutoRefill"
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                refillContent?.refillFlow?.refillHsdXitv
                                  ?.keepYourServicesActive,
                            }}
                          ></p>
                        </label>
                      </div>
                    </div>
                  ) : null}

                  {(editing || !hasSavedCard) && (
                    <div
                      className={
                        `form-buttons` +
                        (hasSavedCard ? " has-delete-card" : "")
                      }
                    >
                      {hasSavedCard &&
                        !ignoreSaved &&
                        !billing.isValid &&
                        !refillView && (
                          <RemoveCCModal
                            paymentMethodContent={
                              dashboardContent?.accountInfoArea?.paymentMethod
                            }
                            buttonClasses={"button--link"}
                            onRemoveCard={removeCard}
                            autoRefill={autoRefillEnabled}
                          />
                        )}
                      {!hideButton && (hasSavedCard || editing) && (
                        <button
                          data-testid="submit-btn"
                          type="submit"
                          className="button"
                          onClick={(e) => handleNext(e)}
                          disabled={!formValid}
                          data-tracking={digitalDataButtonClick(
                            "save-and-continue",
                            hasSavedCard
                              ? dictionaryContent?.global?.update
                              : dictionaryContent?.global?.saveAndContinue
                          )}
                        >
                          {hasSavedCard
                            ? dictionaryContent?.global?.update
                            : dictionaryContent?.global?.saveAndContinue}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withDashboard(PaymentMethod);
