import DashboardLayout from '../../layouts/DashboardLayout'
import LinkList from '../../components/dashboard/LinkList'
import PaymentHistoryAccordion from '../../components/dashboard/PaymentHistoryAccordion'
import withDashboard from '../../redux/connectors/dashboard'
import PromoBanner from '../../components/dashboard/PromoBanner'
import WinbackBanner from '../../components/refill/WinbackBanner'
import { useEffect } from 'react'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

export const PaymentHistory = (props) => {
    const {
        accountId,
        dashboard,
        dashboardContent,
        deactivatedAccount,
        fetchPrepaidTransactions,
        fetchPrepaidTransactionsFailed,
        fetchedPrepaidTransactions,
        locale,
        haveScheduledMove,
        history,
        haveValidPromo,
        isWinbackPromo,
        prepaidTransactionsByDate,
        updatePageClass,
        isTestFile
    } = props

    useEffect(() => {
        updatePageClass('payment-history')
    }, []) // eslint-disable-line

    useEffect(() => {
        if (accountId && dashboard && dashboard.customerInfo.phoneNumber) {
            fetchPrepaidTransactions({accountId, phoneNumbmer: dashboard.customerInfo.phoneNumber})
        }
    }, [accountId, dashboard, fetchPrepaidTransactions])

    useEffect(() => {
        if (fetchPrepaidTransactionsFailed) {
            console.error('Failed to load payment history')
            history.push(`/${locale}/error`)
        }

    }, [fetchPrepaidTransactionsFailed]) // eslint-disable-line

    const links = dashboardContent?.accountInfoArea?.paymentHistory?.additionalItems

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Payment History'}>
            <TrackUserData data={dashboard} />
            {haveValidPromo && !deactivatedAccount && !isWinbackPromo &&
                <PromoBanner title={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.title} body={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.body} link={`/${locale}/refill`} />
            }
            {haveValidPromo && !deactivatedAccount && isWinbackPromo &&
                <WinbackBanner />
            }
            {fetchedPrepaidTransactions && <div  className="main__width-constraint">
                <h1 className="page__title">{dashboardContent?.accountInfoArea?.paymentHistory?.title}</h1>
                <PaymentHistoryAccordion title={''} items={prepaidTransactionsByDate} isTestFile={isTestFile} />
                {links && links.icons && <div className="card">
                    <LinkList content={dashboardContent?.accountInfoArea?.paymentHistory?.additionalItems} locale={locale} haveScheduledMove={haveScheduledMove} />
                </div>}
            </div>}
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(PaymentHistory);