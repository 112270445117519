import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import { formatPhoneNumber } from '../utils/utils'
import { trackEvent } from '../utils/utils'
interface ComponentProps {
    title?: String
    modalContent?: any
    dashboard?: any
    identity?: any
    modalCloseButton?: String
    defaultOpen?: Boolean
    onSubmit?: Function
}

const ConflictModal = ({title, modalContent, dashboard, identity, modalCloseButton, defaultOpen, onSubmit}:ComponentProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(defaultOpen)
    const [smsOptIn, setSmsOptIn] = useState(false)
    const [language, setLanguage] = useState('ENGLISH')

    const closeModal = () => setModalIsOpen(false)

    const node = useRef<HTMLDivElement | null>(null)

    const toggleOptIn = () => {
        setSmsOptIn(!smsOptIn)
    }

    const resolveConflict = (e) => {
        e.preventDefault()

        trackEvent({
            event: 'login',
            loginPage: 'sync up modal',
            loginEvent: 'sms marketing',
            loginDetail: smsOptIn ? 'opt in' : 'opt out'
        })
        trackEvent({
            event: 'login',
            loginPage: 'sync up modal',
            loginEvent: 'com language',
            loginDetail: language
        })
        trackEvent({
            event: 'login',
            loginPage: 'sync up modal',
            loginEvent: 'main cta',
            loginDetail: 'submit'
        })
        closeModal()
        document.body.classList.remove('modal-open')

        // Should ultimately call updateIDCMTCustomerInfo
        if (onSubmit) {
            onSubmit({smsOptIn, language})
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (defaultOpen) {
            trackEvent({
                event: 'login',
                loginPage: 'sync up modal',
                loginEvent: 'impression',
                loginDetail: ''
            })
        }

        // XXX disble dismissing the modal
        // document.addEventListener("mousedown", handleClick)

        // return () => {
        //     // console.log('removing onmousdown event listener')
        //     document.removeEventListener("mousedown", handleClick)
        // }
    }, []) // eslint-disable-line

    // XXX disble dismissing the modal
    // const handleClick = e => {
    //     if (null !== node.current && node.current.contains(e.target)) {
    //       // inside click
    //         return
    //     }
    //     // outside click
    //     setModalIsOpen(false)
    // }

    let xfinityId
    if (identity?.idcmtMobileVerified) {
        xfinityId = formatPhoneNumber(identity.idcmtMobile)
    } else if (identity.idcmtUid) {
        xfinityId = identity.idcmtUid
    } else if (identity.idcmtEmailVerified) {
        xfinityId = identity.idcmtEmail
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                    <div ref={node} className="modal__body">
                        <h3 id="modal__title" tabIndex={0} className="modal__title">{ modalContent?.title }</h3>
                        <p>{ modalContent?.description }</p>

                        <div className='account-block'>
                            <b>Name:</b><br />
                            {dashboard?.customerInfo?.firstName} {dashboard?.customerInfo?.lastName}
                        </div>

                        <div data-testid="id-01" className='account-block'>
                            <b className="information-label">Xfinity ID:</b><br />
                            {xfinityId}
                        </div>

                        <div className='account-block'>
                            <b className="information-label">Mobile Number:</b><br />
                            {formatPhoneNumber(dashboard?.customerInfo?.phoneNumber)}
                        </div>

                        <div className='account-block'>
                            <b className="information-label">Email Address:</b><br />
                            {dashboard?.customerInfo?.emailAddress}
                        </div>
                        <form data-testid="conflict-resolution" onSubmit={resolveConflict}>
                            <div className="checkbox">
                                <input id="communication" type="checkbox" name="smsOptIn" onChange={toggleOptIn} checked={smsOptIn} className="checkbox__input" />
                                <label htmlFor="communication" className="checkbox__label">
                                    {modalContent?.yesIWouldLikeToReceiveAutomated}
                                </label>
                            </div>
                            <fieldset form="my-info" name="communication-language-preference">
                                <legend className="radio__main-label">
                                    <span>{modalContent?.languagePreference}</span>
                                </legend>
                                <div className="flex">
                                    <div className=" flex-item radio radio--inline">
                                        <input data-testid="language_en" checked={language === 'ENGLISH'} onChange={() => setLanguage('ENGLISH')} type="radio" name="langPref" value="ENGLISH" className="radio__input" />
                                        <label htmlFor="language_en" className="radio__label">English</label>
                                    </div>
                                    <div className="flex-item radio radio--inline">
                                        <input data-testid="language_es" type="radio" checked={language === 'SPANISH'} onChange={() => setLanguage('SPANISH')} name="langPref" value="SPANISH" className="radio__input" />
                                        <label htmlFor="language_es" className="radio__label">Español</label>
                                    </div>
                                </div>
                            </fieldset>
                            <input type="submit" value={modalContent?.buttonText} className="button form-submit-button"/>
                        </form>

                        {/* disable ability to dismiss this modal
                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal}>
                            { modalCloseButton }
                            <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                        </button>*/}
                    </div>
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default ConflictModal