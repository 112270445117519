import { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'
import Calendar from '../../components/move/Calendar'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { sprintf } from 'sprintf-js'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const MoveSchedule = (props) => {
    const history = useHistory();
    const [moveDate, setMoveDate] = useState("Select your move date")
    const [validMoveDay, setValidMoveDay] = useState(0)
    const [dateObject, setDateObject] = useState({date: '', day: 1, month: 1, year: 1, available: false, inRange: false, class: ''})

    const {
        moveContent,
        auxAccount,
        updateField,
        hsdService,
        dashboard,
        isTestFile 
    } = props
    useEffect(() => {
        props.updatePageClass('dashboard refill')
        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility?.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 2a',
            moveStepName: 'schedule move',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
    }, []) // eslint-disable-line

      
    useEffect(() => {
        isTestFile && setValidMoveDay(1);
        const alreadyLoaded = sessionStorage.getItem("alreadyLoadedMove");
        if (!alreadyLoaded) {
          sessionStorage.setItem("alreadyLoadedMove", JSON.stringify(true));
          sessionStorage.removeItem("errorReloadCount");
          window.location.reload();
        }
      }, []);

    // Render the Calendar
    var today = new Date()

    var timeLimit = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30)
    //const CalendarWithRange = withRange(Calendar)

    const onSelectFunc = (e) => {
        //console.log(e);
        const date = new Date(e.date)
        const expirationDate = new Date(hsdService?.expirationDate)
        let expirationGap = 0
        const differenceInTime = date.getTime() - expirationDate.getTime()
        expirationGap = (differenceInTime / (1000 * 3600 * 24) + 1)
        expirationGap = Math.floor(expirationGap)

        //console.log(date.getFullYear())
        setMoveDate(date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear());

        const dateString = sprintf('%04d-%02d-%02d', date.getFullYear(), date.getMonth() + 1, date.getDate())
        updateField({field: 'scheduledMoveDate', value: dateString})
        updateField({field: 'moveDateGap', value: expirationGap})
        setDateObject(e)
        if (timeLimit >= date) {
            // The selected time is less than 30 days from now, schedule it
            setValidMoveDay(1);
        } else if (timeLimit < date) {
            // The selected time is more than 30 days from now, need to purchase more time
            setValidMoveDay(1)
        }
        return false
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const todayDate = new Date()

    const routeToCorrectPage = (e) => {
        e.preventDefault()
        const todayDate = new Date()
        const todayDateString = todayDate.getMonth() + 1+"/" + todayDate.getDate()+"/" + todayDate.getFullYear()
        // console.log(todayDateString, moveDate)
        trackEvent({
            event: 'move info',
            moveStep: 'step 2a',
            moveStepName: 'schedule move',
            moveEvent: 'next step cta',
            moveDetail: dateObject.available ? moveContent?.moveScheduled?.next?.text : moveContent?.moveScheduled?.refill?.text
        })
        trackEvent({
            event: 'move info',
            moveStep: 'step 2a',
            moveStepName: 'schedule move',
            moveEvent: 'schedule move date',
            moveDetail: moveDate
        })
        if (dateObject.available) {
            trackEvent({
                event: 'move info',
                moveStep: 'step 2a',
                moveStepName: 'schedule move',
                moveEvent: 'redirected to refill',
                moveDetail: false
            })
            if (todayDateString === moveDate) {
                history.push("/en/move/schedule-now")
            } else {
                history.push("/en/move/schedule-finalize")
            }
        } else {
            trackEvent({
                event: 'move info',
                moveStep: 'step 2a',
                moveStepName: 'schedule move',
                moveEvent: 'redirected to refill',
                moveDetail: true
            })
            history?.push("/en/move/refill-required")
        }
    }

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move Schedule'}>
            <TrackUserData data={dashboard} />
            <div>
                <div className="main__width-constraint move">

                    <h1 className="move__title">
                        {moveContent?.moveScheduled?.selectTheDate}
                    </h1>
                    <div className="main__desktop-body">
                        <div className="move__refill-label">{moveContent?.moveScheduled?.activeRefill}</div>
                        <div className="move__month">{months[todayDate.getMonth()]}<span className="move__year">{todayDate.getFullYear()}</span></div>
                        <Calendar onSelectFunc={onSelectFunc} isTestFile={isTestFile} />
                    </div>
                </div>
                <div className="cart__display-large main__desktop-sidebar-right">
                <div className="status">
                    <div className="status--title">{moveContent?.moveScheduled?.yourMoveDate}:</div>
                        <div className="status--date">{moveDate}</div>
                        { validMoveDay ? <button className="button" data-testid="button1-valid-move" onClick={(e) => routeToCorrectPage(e)} data-tracking={digitalDataButtonClick('move-scheduled', dateObject.available ? moveContent?.moveScheduled?.next?.text : moveContent?.moveScheduled?.refill?.text)}>{dateObject.available ? moveContent?.moveScheduled?.next?.text : moveContent?.moveScheduled?.refill?.text}</button>:<button className="button" disabled data-tracking={digitalDataButtonClick('move-scheduled-next', moveContent?.moveScheduled?.next?.text)}>{moveContent?.moveScheduled?.next?.text}</button>}
                    </div>
                </div>
                <div className="cart__display-small-medium">
                    <div className="move__move-schedule--footer">
                        <div className="status">
                            <div className="status--title">{moveContent?.moveScheduled?.yourMoveDate}:</div>
                            <div className="status--date">{moveDate}</div>
                        </div>
                        { validMoveDay ? <button className="button" data-testid="button2-valid-move" onClick={(e) => routeToCorrectPage(e)} data-tracking={digitalDataButtonClick('move-scheduled', dateObject.available ? moveContent?.moveScheduled?.next?.text : moveContent?.moveScheduled?.refill?.text)}>{dateObject.available ? moveContent?.moveScheduled?.next?.text : moveContent?.moveScheduled?.refill?.text}</button>:<button className="button" disabled data-tracking={digitalDataButtonClick('move-scheduled-next', moveContent?.moveScheduled?.next?.text)}>{moveContent?.moveScheduled?.next?.text}</button>}
                    </div>
                </div>
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveSchedule)
