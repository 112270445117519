import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Header from '../components/dashboard/Header'
import ReconnectHeader from '../components/reconnect/Header'
import Footer from '../components/dashboard/Footer'
import ProgressSpinner from '../components/ProgressSpinner'
import TimeoutModal from '../components/TimeoutModal'
import withDashboard from '../redux/connectors/dashboard'

let redirectUri = String(process.env.REACT_APP_CIMA_REDIRECT_URI)
if (window.location.hostname === 'localhost') {
    redirectUri = "http://localhost:3000/"
}
let clientId = String(process.env.REACT_APP_LOGOUT_CLIENT_ID)
const loginUrl = `https://oauth.xfinity.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid urn:cpc:scope:method-of-payment-service:all`

// 60s in a min x 12.5 mins x 1000 (to convert to milliseconds)
const sessionTimeout = 60 * 12.5 * 1000;
let sessionTimer;

const DashboardLayout = (props) => {
    const {
        pageTitle, // component prop to set page title, not in Redux
        reconnectHeader, // component prop to set header type
        locale, // passed down from router, different from currentLocale which is in redux
        currentLocale,
        updateCurrentLocale,
        ready,
        checksCompleted,
        accountId,
        custGuid,
        pageClass,
        updateAccountId,
        updateCustGuid,
        sessionId,
        updateSessionId,
        inApp,
        updateInAppStatus,
        behindTheModem,
        updateBehindTheModemStatus,
        generateTrackingId,
        fetchToken,
        fetchTokenFailed,
        fetchedToken,
        getAccount,
        gotAccount,
        fetchEnglishAllContent,
        fetchingEnglishAllContent,
        fetchedEnglishAllContent,
        fetchSpanishAllContent,
        fetchingSpanishAllContent,
        fetchedSpanishAllContent,
        fetchEnglishCartContent,
        fetchSpanishCartContent,
        fetchingEnglishCartContent,
        fetchingSpanishCartContent,
        fetchedEnglishCartContent,
        fetchedSpanishCartContent,
        fetchEnglishAllContentFailed,
        fetchSpanishAllContentFailed,
        fetchEnglishCartContentFailed,
        fetchSpanishCartContentFailed,

        identityAccounts,
        fetchIdentity,
        fetchIdentityFailed,
        fetchingIdentity,
        fetchedIdentity,

        dashboard,
        fetchDashboard,
        fetchDashboardFailed,
        fetchingDashboard,
        fetchedDashboard,

        fetchPaymentInfo,
        // fetchPaymentInfoFailed,
        fetchingPaymentInfo,
        fetchedPaymentInfo,

        fetchPromos,
        fetchingPromos,
        fetchedPromos,

        auxAccount,
        fetchAuxAccount,
        fetchingAuxAccount,
        fetchedAuxAccount,
        fetchAuxAccountFailed,

        dictionaryContent,
        dashboardContent,
        refillContent,
        cartContent,
        buyflowContent,
        updateField,
        hsdService,

        promos,
        haveValidPromo,
        addPromo,
        isWinbackPromo,

        lastActionAt,
        sessionError,
        pingSession,
        pingingSession
    } = props

    // set up page title
    const [pageTitleTag, setPageTitleTag] = useState('Xfinity Prepaid')
    const [showTimeoutModal, setShowTimeoutModal] = useState(false)
    const [haveSavedSessionData, setHaveSavedSessionData] = useState(false)

    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (!pingingSession) {
            pingSession()
        }
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log('lastActionAt update', lastActionAt)
        if (sessionTimer) {
            // console.log('Resetting session timer...')
            clearTimeout(sessionTimer)
            sessionTimer = null
            setShowTimeoutModal(false)
        }
        sessionTimer = setTimeout(() => {
            setShowTimeoutModal(true)
        }, sessionTimeout)
    }, [lastActionAt]) // eslint-disable-line

    useEffect(() => {
        if (sessionError) {
            console.error('A session error has occurred', sessionError)
            if (sessionError.expired) {
                // XXX Ideally we should have a separate error page for expired sessions
                // It's a different scenario than a session that has timed out due to inactivity.
                // Or maybe redirect to login instead?
                history.replace(`/${locale}/timeout-error`)
            } else {
                if (sessionId) {
                    history.push(`/${locale}/error`)
                } else {
                    console.log('No session id found, redirecting to login')
                    window.location.href = loginUrl
                }
            }
        }
    }, [sessionError]) // eslint-disable-line

    useEffect(() => {
        if (pageTitle) {
            setPageTitleTag(pageTitle + ' - Xfinity Prepaid')
        }
    }, [pageTitle])

    if (locale !== currentLocale) {
        console.log('updating current locale', currentLocale, '->', locale)
        updateCurrentLocale(locale)
    }

    useEffect(() => {
        if (fetchedDashboard && fetchedIdentity && fetchedPaymentInfo && fetchedAuxAccount) {
            updateField({field: 'ready', value: true})
        }
    }, [fetchedDashboard, fetchedIdentity, fetchedPaymentInfo, fetchedAuxAccount, updateField]) // eslint-disable-line

    useEffect(() => {
        setTimeout(
            function () {
                if (fetchedIdentity) {
                    let currentAccount;
                    if (identityAccounts) {
                        //console.log("identityAccounts",identityAccounts);
                        currentAccount = identityAccounts.find(acct => {
                            return accountId === acct.accountNumber
                        })
                        //console.log('currentAccount', currentAccount) 
                    }
        
                    if (!currentAccount) {
                        console.error("No matching identity account found for account ID",currentAccount)
                        history.push(`/${currentLocale}/error`)
                    }
        
                    if (currentAccount && currentAccount.role !== 'ACCOUNT_OWNER') {
                        history.push(`/${currentLocale}/permission-error`)
                    }
                } 
            },
            30000
          );
       
    }, [fetchedIdentity]) // eslint-disable-line

    useEffect(() => {
        if (!accountId || !custGuid) { // TODO: a better way to check whether data is already loaded?
            let accountIdParam, custGuidParam, sessionIdParam, bhr, mobile ;

            // If there are URL parameters, use those...
            let urlParams = new URLSearchParams(window.location.search);

            bhr = urlParams.get('bhr')
            mobile = urlParams.get('mobile')

            accountIdParam = urlParams.get('accountId')
            if (accountIdParam) {
                accountIdParam = atob(accountIdParam).replace(/["]/g, '')
            }

            custGuidParam = urlParams.get('guid')

            if (custGuidParam) {
                custGuidParam = atob(custGuidParam)
            }

            if (accountIdParam && custGuidParam) {
                // If have values from params, save them to sessionStorage
                sessionStorage.setItem('xpp_acct', JSON.stringify({
                    accountId: accountIdParam,
                    custGuid: custGuidParam,
                    bhr: bhr && bhr === 'true',
                    mobile: mobile && mobile === 'true',
                    sessionId: sessionIdParam,
                }))
                setHaveSavedSessionData(true)
            } else {
                // If no values in URL parameters, look for values in sessionStorage
                let ssAccountData = sessionStorage.getItem('xpp_acct')

                if (ssAccountData) {
                    console.log('found ssAccountData in sessionStorage', ssAccountData)
                    let data = JSON.parse(ssAccountData)
                    accountIdParam = data.accountId
                    custGuidParam = data.custGuid
                    bhr = data.bhr
                    mobile = data.mobile
                    sessionIdParam = data.sessionId
                    setHaveSavedSessionData(true)
                }
            }

            //console.log('accountId', accountIdParam, 'custGuid', custGuidParam, 'bhr', bhr, 'mobile', mobile)

            if (accountIdParam && custGuidParam) {
                updateBehindTheModemStatus(bhr && bhr === 'true')
                updateInAppStatus(mobile && mobile === 'true')
                updateAccountId(accountIdParam)
                updateCustGuid(custGuidParam)
                updateSessionId(sessionIdParam)
            } else {
                // XXX TODO New error page for dashboard? Or common error page? (ie, /en/error)
                console.log('Missing required parameters, redirecting to login page')
                window.location.href = loginUrl
            }

            generateTrackingId()

            fetchToken()
        } else {
            setHaveSavedSessionData(true)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fetchedToken) {
            if (!dictionaryContent.loaded) {
                if (!(fetchedEnglishAllContent || fetchingEnglishAllContent)) {
                    fetchEnglishAllContent()
                }
                if (!(fetchedSpanishAllContent || fetchingSpanishAllContent)) {
                    fetchSpanishAllContent()
                }
            }

            if (!cartContent.loaded) {
                if (!(fetchedEnglishCartContent || fetchingEnglishCartContent)) {
                    fetchEnglishCartContent()
                }
                if (!(fetchedSpanishCartContent || fetchingSpanishCartContent)) {
                    fetchSpanishCartContent()
                }
            }

            if (!gotAccount) {
                getAccount()
            }

            if (!fetchedIdentity && !fetchingIdentity) {
                fetchIdentity({custGuid})
            }

            if (!fetchedDashboard && !fetchingDashboard) {
                fetchDashboard({accountId})
            }

            if (!fetchedAuxAccount && !fetchingAuxAccount) {
                fetchAuxAccount({
                    accountId,
                    auxConfNo: '',
                    checkMoveEligibility: true,
                    checkReconnectEligibility: true
                })
            }
        }
    }, [fetchedToken]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log('haveSavedSessionData: ', haveSavedSessionData, 'fetched dashboard: ', fetchedDashboard, 'fetched aux account: ', fetchAuxAccount, 'checksCompleted: ', checksCompleted)
        if (haveSavedSessionData && fetchedDashboard && fetchedAuxAccount && !checksCompleted) {
            updateField({field: 'checksCompleted', value: true})

            let accountClosed = dashboard.customerInfo.prepaidAccountStatus === 'Closed'

            if (!accountClosed && !dashboard.availablePlans) {
                console.warn('Active account with no availablePlans. Redirecting to activation.')
                let activationUrl = `/${locale}/activation?accountId=${btoa(accountId)}`
                activationUrl += `&guid=${btoa(custGuid)}`
                activationUrl += `&bhr=${behindTheModem ? 'true' : 'false'}`
                activationUrl += `&mobile=${inApp ? 'true' : 'false'}`
                console.log('activationUrl', activationUrl)

                setTimeout(() => {
                    history.push(activationUrl)
                }, 1500)
                return
            } else if ((accountClosed || !dashboard.availablePlans) && auxAccount.auxDetails && auxAccount.auxDetails.length) {
                let preactivatedAuxAccount = auxAccount.auxDetails.find(acct => acct.auxAcctStatus === 'Preactivated')
                let activeAuxAccount = auxAccount.auxDetails.find(acct => acct.auxAcctStatus === 'Activated')
                if (preactivatedAuxAccount) {
                    console.log('preactivatedAuxAccount', preactivatedAuxAccount)
                    console.warn('Account is closed but found a preactivated aux account. Redirecting to activation')

                    let activationUrl = `/${locale}/activation?accountId=${btoa(preactivatedAuxAccount.auxAccountId)}`
                    activationUrl += `&guid=${btoa(custGuid)}`
                    activationUrl += `&bhr=${behindTheModem ? 'true' : 'false'}`
                    activationUrl += `&mobile=${inApp ? 'true' : 'false'}`
                    console.log('activationUrl', activationUrl)

                    setTimeout(() => {
                        history.push(activationUrl)
                    }, 1500)
                    return
                } else if (activeAuxAccount) {
                    console.log('activeAuxAccount', preactivatedAuxAccount)
                    console.warn('Account is closed but found an Active aux account. Redirecting to new account.')

                    let activeUrl = `/${locale}/dashboard?accountId=${btoa(activeAuxAccount.auxAccountId)}`
                    activeUrl += `&guid=${btoa(custGuid)}`
                    activeUrl += `&bhr=${behindTheModem ? 'true' : 'false'}`
                    activeUrl += `&mobile=${inApp ? 'true' : 'false'}`

                    setTimeout(() => {
                        history.push(activeUrl)
                    }, 1500)
                    return
                } else {
                    console.error('Account is closed. There are auxAccounts, but none in Preactivated or Active state.')
                    // history.replace(`/${locale}/error`)
                    // return
                }
            }

            if (dashboard.availablePlans) {
                if (accountClosed) {
                    console.error('This account is closed, but there is no aux account to redirect to.')
                    history.replace(`/${locale}/error`)
                    return
                }

                const legacyTvServices = dashboard.availablePlans.filter(p => p.productCode.includes('VDO_V0'))
                if (!hsdService || legacyTvServices.length) { // Like TV200 / TV450
                    console.error('No HSD, or account has legacy TV')
                    history.replace(`/${locale}/error`)
                    return
                }
            } else {
                if (dashboard.customerInfo.prepaidAccountStatus === 'Closed') {
                    console.log('Account is deactivated and there are no available plans.')
                    if (!auxAccount?.reconnectEligibility?.reconnectEligibleAcct) {
                        console.error('But account is not eligible for reconnect (auxAccount.reconnectEligibility.reconnectEligibleAcct is false)')
                        history.replace(`/${locale}/error`)
                    } else {
                        updateField({field: 'deactivatedAccount', value: true})
                    }
                } else {
                    console.error('The account is has no availablePlans, but is not Closed.')
                    history.replace(`/${locale}/error`)
                    return
                }
            }

            if (!fetchedPaymentInfo && !fetchingPaymentInfo) {
                console.log('Fetching payment info...')
                fetchPaymentInfo({accountId, phoneNumber: dashboard.customerInfo.phoneNumber})
            }

            if (!fetchedPromos && !fetchingPromos) {
                fetchPromos({
                    accountId,
                    locationId: dashboard.customerInfo.locationId,
                })
            }
        }
    }, [fetchedDashboard, fetchedAuxAccount]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fetchedPromos) {
            if (haveValidPromo && !isWinbackPromo) {
                addPromo(promos.discounts[0].discId)
            }
        }
    }, [fetchedPromos]) // eslint-disable-line

    useEffect(() => {
        // console.log('failures', fetchingEnglishContentFailed, fetchingSpanishContentFailed, fetchingIdentityFailed, fetchingDashboardFailed, fetchingDeviceStatusFailed)
        let failed = fetchTokenFailed
        failed = failed || fetchEnglishAllContentFailed || fetchSpanishAllContentFailed
        failed = failed || fetchEnglishCartContentFailed || fetchSpanishCartContentFailed
        failed = failed || fetchDashboardFailed
        failed = failed || fetchIdentityFailed
        failed = failed || fetchAuxAccountFailed

        if (failed) {
            setTimeout(() => {
                console.error('Error making a required API call')
                history?.replace(`/${locale}/error`)
            }, 1000);
        }
    }, [
        fetchTokenFailed,
        fetchEnglishAllContentFailed,
        fetchSpanishAllContentFailed,
        fetchEnglishCartContentFailed,
        fetchSpanishCartContentFailed,
        fetchIdentityFailed,
        fetchDashboardFailed,
        fetchAuxAccountFailed,
        locale,
        history
    ])
    if (dashboardContent.loaded && refillContent.loaded && cartContent.loaded && buyflowContent.loaded && ready) {
        return (
            <div className={`prepaid-wrapper ${pageClass}`}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{pageTitleTag}</title>
                    <link rel="icon" href="/favicon.ico" />
                </Helmet>
                {reconnectHeader ?
                <ReconnectHeader /> : <Header />}

                <main>
                    <div id="main" role="main" className="main">
                        {props.children}
                        {<TimeoutModal isOpen={showTimeoutModal} />}
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
    else return (
        <div id="main" className="main" role="main">
            <div className="main__width-constraint">
                <ProgressSpinner loadingText="Loading..." />
            </div>
        </div>
    )
}

export default withDashboard(DashboardLayout)