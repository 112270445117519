import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import ProgressSpinner from '../../components/ProgressSpinner'
import { digitalDataLinkClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const TimeoutError = (props) => {
    const {
        pageClass,
        dashboardContent,

        fetchEnglishDashboardContent,
        fetchedEnglishDashboardContent,
        fetchingEnglishDashboardContent,
        fetchSpanishDashboardContent,
        fetchedSpanishDashboardContent,
        fetchingSpanishDashboardContent,
        dashboard
    } = props;

    let redirectUri = String(process.env.REACT_APP_CIMA_REDIRECT_URI)
    if (window.location.hostname === 'localhost') {
        redirectUri = "http://localhost:3000/"
    }
    const clientId=String(process.env.REACT_APP_LOGOUT_CLIENT_ID);
    const loginUrl = `https://oauth.xfinity.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid urn:cpc:scope:method-of-payment-service:all`

    useEffect(() => {
        props.updatePageClass('dashboard')
        // console.log(props)
        if (!dashboardContent.loaded) {
            if (!(fetchedEnglishDashboardContent || fetchingEnglishDashboardContent)) {
                fetchEnglishDashboardContent()
            }
            if (!(fetchedSpanishDashboardContent || fetchingSpanishDashboardContent)) {
                fetchSpanishDashboardContent()
            }
        }

    }, []) // eslint-disable-line

    if (dashboardContent.loaded) {
        return (
            <div className={`prepaid-wrapper ${pageClass}`}>
                <TrackUserData data={dashboard} />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Error - Xfinity Prepaid</title>
                    <link rel="icon" href="/favicon.ico" />
                </Helmet>

                <main>
                    <div id="main" role="main" className="main">
                        <div className='error-content'>
                            <img alt="" src="/site-assets/img/icon-fail.svg"></img>
                            <h1 className="page__title">
                                {dashboardContent?.dashboardArea?.timeoutError?.title}
                            </h1>
                            <p>
                                {dashboardContent?.dashboardArea?.timeoutError?.yourSessionHasBeenInactive}
                            </p>
                            <a href={loginUrl} className="link-button" data-tracking={digitalDataLinkClick('login', loginUrl, dashboardContent?.dashboardArea?.timeoutError?.refreshAndLoginAgain?.text)}>{dashboardContent?.dashboardArea?.timeoutError?.refreshAndLoginAgain?.text}</a>
                        </div>
                    </div>
                </main>
                <TrackPageComplete />
            </div>
        )
    }
    else return (
        <div id="main" className="main" role="main">
            <div className="main__width-constraint">
                <ProgressSpinner loadingText="Loading..." />
            </div>
        </div>
    )
}

export default withDashboard(TimeoutError)