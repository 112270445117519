import {put, select, call, takeEvery} from 'redux-saga/effects';

import {
    fetchToken,
    fetchEnglishActivationContent,
    fetchSpanishActivationContent,
    fetchIdentityInfo,
    fetchCustomerDashboard,
    loadPrepaidAccount,
    fetchDeviceStatus,
    fetchMacStatus,
    activatePrepaidAccount,
    restartDevice,
} from './apis';

import {activation} from './store';
import { digitalDataAPIError } from '../../utils/adobe-analytics';

const getTrackingId = (state) => state?.activation.trackingId

function* fetchTokenSaga({payload}) {
    console.log('fetchTokenSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchToken, {trackingId});
        yield put(activation.actions.fetchTokenSuccess(response));
    } catch (error: any) {
        console.log('token catch error', error)
        digitalDataAPIError('fetchTokenSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchTokenFailure({}));
    }
}

function* fetchTokenSagaConfig() {
    yield takeEvery(activation.actions.fetchToken, fetchTokenSaga);
}

function* fetchEnglishActivationContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishActivationContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishActivationContent, {trackingId});
        yield put(activation.actions.fetchEnglishActivationContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response?.data.errorCode
        const errorMessage = error.response?.data.errorMessage
        digitalDataAPIError('fetchEnglishActivationContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchEnglishActivationContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishActivationContentSagaConfig() {
    yield takeEvery(activation.actions.fetchEnglishActivationContent, fetchEnglishActivationContentSaga);
}

function* fetchSpanishActivationContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishActivationContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishActivationContent, {trackingId});
        yield put(activation.actions.fetchSpanishActivationContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response?.data.errorCode
        const errorMessage = error.response?.data.errorMessage
        digitalDataAPIError('fetchSpanishActivationContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchSpanishActivationContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishActivationContentSagaConfig() {
    yield takeEvery(activation.actions.fetchSpanishActivationContent, fetchSpanishActivationContentSaga);
}

function* fetchIdentityInfoSaga({payload}) {
    console.log('fetchIdentityInfoSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchIdentityInfo, {...payload, trackingId});
        yield put(activation.actions.fetchIdentityInfoSuccess(response.response));
    } catch (error: any) {
        console.log('Error : ', error);
        let errorCode = null, errorMessage = null;
        if (error.response && error.response.data) {
            errorCode = error.response.data.errorCode
            errorMessage = error.response.data.errorMessage
        }
        digitalDataAPIError('fetchIdentityInfoSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchIdentityInfoFailure({errorCode, errorMessage}));
    }
}

function* fetchIdentityInfoSagaConfig() {
    yield takeEvery(activation.actions.fetchIdentityInfo, fetchIdentityInfoSaga);
}


function* fetchCustomerDashboardSaga({payload}) {
    console.log('fetchCustomerDashboardSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchCustomerDashboard, {...payload, trackingId});
        yield put(activation.actions.fetchCustomerDashboardSuccess(response.response));
    } catch (error: any) {
        console.log('Error : ', error);
        let errorCode = null, errorMessage = null;
        if (error.response && error.response.data) {
            errorCode = error.response.data.errorCode
            errorMessage = error.response.data.errorMessage
        }
        digitalDataAPIError('fetchCustomerDashboardSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchCustomerDashboardFailure({errorCode, errorMessage}));
    }
}

function* fetchCustomerDashboardSagaConfig() {
    yield takeEvery(activation.actions.fetchCustomerDashboard, fetchCustomerDashboardSaga);
}


function* loadPrepaidAccountSaga({payload}) {
    console.log('loadPrepaidAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(loadPrepaidAccount, {...payload, trackingId});
        yield put(activation.actions.loadPrepaidAccountSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        // eslint-disable-next-line
        const errorCode = error.response?.data.errorCode
        // eslint-disable-next-line
        const errorMessage = error.response?.data.errorMessage
        // yield put(activation.actions.loadPrepaidAccountFailure({errorCode, errorMessage}));
        digitalDataAPIError('loadPrepaidAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.loadPrepaidAccountSuccess(error.response?.data)); // XXX
    }
}

function* loadPrepaidAccountSagaConfig() {
    yield takeEvery(activation.actions.loadPrepaidAccount, loadPrepaidAccountSaga);
}


function* fetchDeviceStatusSaga({payload}) {
    console.log('fetchDeviceStatusSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchDeviceStatus, {...payload, trackingId});
        yield put(activation.actions.fetchDeviceStatusSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        // const errorCode = error.response.data.errorCode
        // const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchDeviceStatusSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchDeviceStatusFailure(error.response));
    }
}

function* fetchDeviceStatusSagaConfig() {
    yield takeEvery(activation.actions.fetchDeviceStatus, fetchDeviceStatusSaga);
}


function* fetchMacStatusSaga({payload}) {
    console.log('fetchMacStatusSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchMacStatus, {...payload, trackingId});
        yield put(activation.actions.fetchMacStatusSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response?.data.errorCode
        const errorMessage = error.response?.data.errorMessage
        digitalDataAPIError('fetchMacStatusSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.fetchMacStatusFailure({errorCode, errorMessage}));
    }
}

function* fetchMacStatusSagaConfig() {
    yield takeEvery(activation.actions.fetchMacStatus, fetchMacStatusSaga);
}


function* activatePrepaidAccountSaga({payload}) {
    console.log('activatePrepaidAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(activatePrepaidAccount, {...payload, trackingId});
        yield put(activation.actions.activatePrepaidAccountSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response?.data.errorCode
        const errorMessage = error.response?.data.errorMessage
        digitalDataAPIError('activatePrepaidAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.activatePrepaidAccountFailure({errorCode, errorMessage}));
    }
}

function* activatePrepaidAccountSagaConfig() {
    yield takeEvery(activation.actions.activatePrepaidAccount, activatePrepaidAccountSaga);
}

function* restartDeviceSaga({payload}) {
    console.log('restartDeviceSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(restartDevice, {...payload, trackingId});
        yield put(activation.actions.restartDeviceSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response?.data.errorCode
        const errorMessage = error.response?.data.errorMessage
        digitalDataAPIError('restartDeviceSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(activation.actions.restartDeviceFailure({errorCode, errorMessage}));
    }
}

function* restartDeviceSagaConfig() {
    yield takeEvery(activation.actions.restartDevice, restartDeviceSaga);
}

export {
    fetchTokenSaga,
    fetchTokenSagaConfig,
    fetchEnglishActivationContentSaga,
    fetchEnglishActivationContentSagaConfig,
    fetchSpanishActivationContentSaga,
    fetchSpanishActivationContentSagaConfig,
    fetchIdentityInfoSaga,
    fetchIdentityInfoSagaConfig,
    fetchCustomerDashboardSaga,
    fetchCustomerDashboardSagaConfig,
    loadPrepaidAccountSaga,
    loadPrepaidAccountSagaConfig,
    fetchDeviceStatusSaga,
    fetchDeviceStatusSagaConfig,
    fetchMacStatusSaga,
    fetchMacStatusSagaConfig,
    activatePrepaidAccountSaga,
    activatePrepaidAccountSagaConfig,
    restartDeviceSaga,
    restartDeviceSagaConfig
}

