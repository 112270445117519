import { connect } from "react-redux";
import { dashboard } from "../dashboard/store";
import { common } from "../common/store";
import { content } from "../content/store";
import contentSelectors from "../content/selectors";
import dashboardSelectors from "../dashboard/selectors";

const mapStateToProps = (state) => ({
  sessionExpired: state.dashboard.sessionExpired,
  sessionError: state.dashboard.sessionError,
  lastActionAt: state.dashboard.lastActionAt,

  ready: state.dashboard.ready,
  checksCompleted: state.dashboard.checksCompleted,

  pingedSession: state.dashboard.pingedSession,
  pingingSession: state.dashboard.pingingSession,
  pingSessionFailed: state.dashboard.pingSessionFailed,

  inApp: state.common.inApp,
  behindTheModem: state.common.behindTheModem,
  accountId: state.common.accountId,
  custGuid: state.common.custGuid,
  sessionId: state.common.sessionId,
  trackingId: state.common.trackingId,
  currentLocale: state.common.currentLocale,
  pageClass: state.common.pageClass,
  notificationMessage: state.common.notificationMessage,
  confirmationMessage: state.common.confirmationMessage,

  macAddress: state.dashboard.macAddress,
  firstName: state.dashboard.firstName,
  lastName: state.dashboard.lastName,
  isVanAccount: state.dashboard.isVanAccount,

  deactivatedAccount: state.dashboard.deactivatedAccount,

  xsrfToken: state.common.xsrfToken,
  fetchedToken: state.common.fetchedToken,
  fetchingToken: state.common.fetchingToken,
  fetchTokenFailed: state.common.fetchTokenFailed,

  accountData: state.common.accountData,
  gotAccount: state.common.gotAccount,
  gettingAccount: state.common.gettingAccount,
  getAccountFailed: state.common.getAccountFailed,

  fetchedAllContent: state.content.fetchedAllContent,
  fetchedEnglishAllContent: state.content.fetchedEnglishAllContent,
  fetchedSpanishAllContent: state.content.fetchedSpanishAllContent,
  fetchingEnglishAllContent: state.content.fetchingEnglishAllContent,
  fetchingSpanishAllContent: state.content.fetchingSpanishAllContent,
  fetchEnglishAllContentFailed: state.content.fetchEnglishAllContentFailed,
  fetchSpanishAllContentFailed: state.content.fetchSpanishAllContentFailed,

  fetchedDictionaryContent: state.content.fetchedDictionaryContent,
  fetchedEnglishDictionaryContent:
    state.content.fetchedEnglishDictionaryContent,
  fetchingEnglishDictionaryContent:
    state.content.fetchingEnglishDictionaryContent,
  fetchEnglishDictionaryContentFailed:
    state.content.fetchEnglishDictionaryContentFailed,
  fetchedSpanishDictionaryContent:
    state.content.fetchedSpanishDictionaryContent,
  fetchingSpanishDictionaryContent:
    state.content.fetchingSpanishDictionaryContent,
  fetchSpanishDictionaryContentFailed:
    state.content.fetchSpanishDictionaryContentFailed,

  fetchedDashboardContent: state.content.fetchedDashboardContent,
  fetchedEnglishDashboardContent: state.content.fetchedEnglishDashboardContent,
  fetchingEnglishDashboardContent:
    state.content.fetchingEnglishDashboardContent,
  fetchEnglishDashboardContentFailed:
    state.content.fetchEnglishDashboardContentFailed,
  fetchedSpanishDashboardContent: state.content.fetchedSpanishDashboardContent,
  fetchingSpanishDashboardContent:
    state.content.fetchingSpanishDashboardContent,
  fetchSpanishDashboardContentFailed:
    state.content.fetchSpanishDashboardContentFailed,

  englishActivationContent: state.content.englishActivationContent,
  fetchingEnglishActivationContent:
    state.content.fetchingEnglishActivationContent,
  fetchEnglishActivationContentFailed:
    state.content.fetchEnglishActivationContentFailed,
  fetchedEnglishActivationContent:
    state.content.fetchedEnglishActivationContent,

  spanishActivationContent: state.content.spanishActivationContent,
  fetchingSpanishActivationContent:
    state.content.fetchingSpanishActivationContent,
  fetchSpanishActivationContentFailed:
    state.content.fetchSpanishActivationContentFailed,
  fetchedSpanishActivationContent:
    state.content.fetchedSpanishActivationContent,

  identity: state.dashboard.identity,
  identityAccounts: state.dashboard.identityAccounts,
  fetchedIdentity: state.dashboard.fetchedIdentity,
  fetchingIdentity: state.dashboard.fetchingIdentity,
  fetchIdentityFailed: state.dashboard.fetchIdentityFailed,
  updatingIdentity: state.dashboard.updatingIdentity,
  updatedIdentity: state.dashboard.updatedIdentity,
  updateIdentityFailed: state.dashboard.updateIdentityFailed,
  pronoun: state.dashboard.pronoun,
  chosenName: state.dashboard.chosenName,

  updatingIDCMTCustomerInfo: state.dashboard.updatingIDCMTCustomerInfo,
  updatedIDCMTCustomerInfo: state.dashboard.updatedIDCMTCustomerInfo,
  updateIDCMTCustomerInfoFailed: state.dashboard.updateIDCMTCustomerInfoFailed,

  dashboard: state.dashboard.dashboard,
  xitvProducts: state.dashboard.xitvProducts,
  xitvBasic: state.dashboard.xitvBasic,
  hsdService: state.dashboard.hsdService,
  tv200Service: state.dashboard.tv200Service,
  availableHSDServices: state.dashboard.availableHSDServices,
  hsd30Service: state.dashboard.hsd30Service,
  hsd7Service: state.dashboard.hsd7Service,
  tvServiceAvailableForRefill: state.dashboard.tvServiceAvailableForRefill,
  availableXitvBasic: state.dashboard.availableXitvBasic,
  availableXitvPackages: state.dashboard.availableXitvPackages,
  availableXitvAddOns: state.dashboard.availableXitvAddOns,
  availableXitvSpanishAddOns: state.dashboard.availableXitvSpanishAddOns,
  fetchedDashboard: state.dashboard.fetchedDashboard,
  fetchingDashboard: state.dashboard.fetchingDashboard,
  fetchDashboardFailed: state.dashboard.fetchDashboardFailed,

  restartDeviceStatus: state.dashboard.restartDeviceStatus,
  restartedDevice: state.dashboard.restartedDevice,
  restartingDevice: state.dashboard.restartingDevice,
  restartDeviceFailed: state.dashboard.restartDeviceFailed,

  prepaidTransactions: state.dashboard.prepaidTransactions,
  prepaidTransactionsByDate: state.dashboard.prepaidTransactionsByDate,
  fetchedPrepaidTransactions: state.dashboard.fetchedPrepaidTransactions,
  fetchingPrepaidTransactions: state.dashboard.fetchingPrepaidTransactions,
  fetchPrepaidTransactionsFailed:
    state.dashboard.fetchPrepaidTransactionsFailed,

  autoRefillEnabled: state.dashboard.autoRefillEnabled,
  enablingAutoRefill: state.dashboard.enablingAutoRefill,
  enabledAutoRefill: state.dashboard.enabledAutoRefill,
  enableAutoRefillFailed: state.dashboard.enableAutoRefillFailed,
  disablingAutoRefill: state.dashboard.disablingAutoRefill,
  disabledAutoRefill: state.dashboard.disabledAutoRefill,
  disableAutoRefillFailed: state.dashboard.disableAutoRefillFailed,

  paymentInfo: state.dashboard.paymentInfo,
  fetchedPaymentInfo: state.dashboard.fetchedPaymentInfo,
  fetchingPaymentInfo: state.dashboard.fetchingPaymentInfo,
  fetchPaymentInfoFailed: state.dashboard.fetchPaymentInfoFailed,

  addingPaymentInstrument: state.dashboard.addingPaymentInstrument,
  addedPaymentInstrument: state.dashboard.addedPaymentInstrument,
  addPaymentInstrumentFailed: state.dashboard.addPaymentInstrumentFailed,

  removingPaymentInstrument: state.dashboard.removingPaymentInstrument,
  removedPaymentInstrument: state.dashboard.removedPaymentInstrument,
  removePaymentInstrumentFailed: state.dashboard.removePaymentInstrumentFailed,

  updatingPaymentInstrument: state.dashboard.updatingPaymentInstrument,
  updatedPaymentInstrument: state.dashboard.updatedPaymentInstrument,
  updatePaymentInstrumentFailed: state.dashboard.updatePaymentInstrumentFailed,

  paymentInstrumentErrorCode: state.dashboard.paymentInstrumentErrorCode,

  activationCode: state.dashboard.activationCode,
  validatingActivationCode: state.dashboard.validatingActivationCode,
  validatedActivationCode: state.dashboard.validatedActivationCode,
  validateActivationCodeFailed: state.dashboard.validateActivationCodeFailed,

  promos: state.dashboard.promos,
  fetchingPromos: state.dashboard.fetchingPromos,
  fetchedPromos: state.dashboard.fetchedPromos,
  fetchPromosFailed: state.dashboard.fetchPromosFailed,

  taxData: state.dashboard.taxData,
  fetchingTaxData: state.dashboard.fetchingTaxData,
  fetchedTaxData: state.dashboard.fetchedTaxData,
  fetchTaxDataFailed: state.dashboard.fetchTaxDataFailed,

  refill: state.dashboard.refill,
  refillingPrepaidAccount: state.dashboard.refillingPrepaidAccount,
  refilledPrepaidAccount: state.dashboard.refilledPrepaidAccount,
  refillPrepaidAccountFailed: state.dashboard.refillPrepaidAccountFailed,

  pinRefill: state.dashboard.pinRefill,
  redeemingRefill: state.dashboard.redeemingRefill,
  redeemedRefill: state.dashboard.redeemedRefill,
  redeemRefillFailed: state.dashboard.redeemRefillFailed,

  refillErrorCode: state.dashboard.refillErrorCode,

  billing: state.dashboard.billing,

  cardTypeMap: state.dashboard.cardTypeMap,

  cart: state.dashboard.cart,
  cartCount: state.dashboard.cartCount,
  cartSubtotal: state.dashboard.cartSubtotal,
  cartTaxes: state.dashboard.cartTaxes,
  cartTotal: state.dashboard.cartTotal,

  auxAccount: state.dashboard.auxAccount,
  fetchingAuxAccount: state.dashboard.fetchingAuxAccount,
  fetchedAuxAccount: state.dashboard.fetchedAuxAccount,
  fetchAuxAccountFailed: state.dashboard.fetchAuxAccountFailed,

  newAuxAccount: state.dashboard.newAuxAccount,
  creatingAuxAccount: state.dashboard.creatingAuxAccount,
  createdAuxAccount: state.dashboard.createdAuxAccount,
  createAuxAccountFailed: state.dashboard.createAuxAccountFailed,

  scheduledMoveDate: state.dashboard.scheduledMoveDate,
  moveDateGap: state.dashboard.moveDateGap,
  haveScheduledMove: state.dashboard.haveScheduledMove,
  cancelledScheduledMove: state.dashboard.cancelledScheduledMove,
  scheduledMove: state.dashboard.scheduledMove,
  updatingScheduledMove: state.dashboard.updatingScheduledMove,
  updatedScheduledMove: state.dashboard.updatedScheduledMove,
  updateScheduledMoveFailed: state.dashboard.updateScheduledMoveFailed,

  acpDetail: state.dashboard.acpDetail,

  englishRefillContent: state.content.englishRefillContent,
  fetchingEnglishRefillContent: state.content.fetchingEnglishRefillContent,
  fetchEnglishRefillContentFailed:
    state.content.fetchEnglishRefillContentFailed,
  fetchedEnglishRefillContent: state.content.fetchedEnglishRefillContent,

  spanishRefillContent: state.content.spanishRefillContent,
  fetchingSpanishRefillContent: state.content.fetchingSpanishRefillContent,
  fetchSpanishRefillContentFailed:
    state.content.fetchSpanishRefillContentFailed,
  fetchedSpanishRefillContent: state.content.fetchedSpanishRefillContent,

  englishMerchantContent: state.content.englishMerchantContent,
  fetchingEnglishMerchantContent: state.content.fetchingEnglishMerchantContent,
  fetchEnglishMerchantContentFailed:
    state.content.fetchEnglishMerchantContentFailed,
  fetchedEnglishMerchantContent: state.content.fetchedEnglishMerchantContent,

  spanishMerchantContent: state.content.spanishMerchantContent,
  fetchingSpanishMerchantContent: state.content.fetchingSpanishMerchantContent,
  fetchSpanishMerchantContentFailed:
    state.content.fetchSpanishMerchantContentFailed,
  fetchedSpanishMerchantContent: state.content.fetchedSpanishMerchantContent,

  englishMoveContent: state.content.englishMoveContent,
  fetchingEnglishMoveContent: state.content.fetchingEnglishMoveContent,
  fetchEnglishMoveContentFailed: state.content.fetchEnglishMoveContentFailed,
  fetchedEnglishMoveContent: state.content.fetchedEnglishMoveContent,

  spanishMoveContent: state.content.spanishMoveContent,
  fetchingSpanishMoveContent: state.content.fetchingSpanishMoveContent,
  fetchSpanishMoveContentFailed: state.content.fetchSpanishMoveContentFailed,
  fetchedSpanishMoveContent: state.content.fetchedSpanishMoveContent,

  englishReconnectContent: state.content.englishReconnectContent,
  fetchingEnglishReconnectContent:
    state.content.fetchingEnglishReconnectContent,
  fetchEnglishReconnectContentFailed:
    state.content.fetchEnglishReconnectContentFailed,
  fetchedEnglishReconnectContent: state.content.fetchedEnglishReconnectContent,

  spanishReconnectContent: state.content.spanishReconnectContent,
  fetchingSpanishReconnectContent:
    state.content.fetchingSpanishReconnectContent,
  fetchSpanishReconnectContentFailed:
    state.content.fetchSpanishReconnectContentFailed,
  fetchedSpanishReconnectContent: state.content.fetchedSpanishReconnectContent,

  englishCartContent: state.content.englishCartContent,
  fetchingEnglishCartContent: state.content.fetchingEnglishCartContent,
  fetchEnglishCartContentFailed: state.content.fetchEnglishCartContentFailed,
  fetchedEnglishCartContent: state.content.fetchedEnglishCartContent,

  spanishCartContent: state.content.spanishCartContent,
  fetchingSpanishCartContent: state.content.fetchingSpanishCartContent,
  fetchSpanishCartContentFailed: state.content.fetchSpanishCartContentFailed,
  fetchedSpanishCartContent: state.content.fetchedSpanishCartContent,

  cpcResponse: state.dashboard.cpcResponse,
  isTestFile:state.dashboard.isTestFile,

  // CONTENT SELECTORS
  dictionaryContent: contentSelectors.getDictionaryContent(state),
  dashboardContent: contentSelectors.getDashboardContent(state),
  refillContent: contentSelectors.getRefillContent(state),
  merchantContent: contentSelectors.getMerchantContent(state),
  moveContent: contentSelectors.getMoveContent(state),
  reconnectContent: contentSelectors.getReconnectContent(state),
  cartContent: contentSelectors.getCartContent(state),
  buyflowContent: contentSelectors.getBuyflowContent(state),
  activationContent: contentSelectors.getActivationContent(state),

  // OTHER SELECTORS
  haveValidPromo: dashboardSelectors.getHaveValidPromo(state),
  isWinbackPromo: dashboardSelectors.getIsWinbackPromo(state),
  havePromoInCart: dashboardSelectors.getHavePromoInCart(state),
  isRefillConfirmationPage:
    dashboardSelectors.getIsRefillConfirmationPage(state),
  haveValidACP: dashboardSelectors.getHaveValidACP(state),
  isTribalACP: dashboardSelectors.getIsTribalACP(state),

  fetchBroadbandFactsContent: dashboard.actions.fetchBroadbandFactsContent,

});

const mapDispatchToProps = {
  generateTrackingId: common.actions.generateTrackingId,
  updateCurrentLocale: common.actions.updateCurrentLocale,
  updatePageClass: common.actions.updatePageClass,
  updateInAppStatus: common.actions.updateInAppStatus,
  updateBehindTheModemStatus: common.actions.updateBehindTheModemStatus,
  updateAccountId: common.actions.updateAccountId,
  updateCustGuid: common.actions.updateCustGuid,
  updateSessionId: common.actions.updateSessionId,
  pingSession: dashboard.actions.pingSession,
  updateFirstName: dashboard.actions.updateFirstName,
  updateLastName: dashboard.actions.updateLastName,
  updateField: dashboard.actions.updateField,
  toggleField: dashboard.actions.toggleField,
  togglePackage: dashboard.actions.togglePackage,
  toggleAddOn: dashboard.actions.toggleAddOn,
  removeAllTvProducts: dashboard.actions.removeAllTvProducts,
  updateInternet: dashboard.actions.updateInternet,
  toggleInternet: dashboard.actions.toggleInternet,
  addPromo: dashboard.actions.addPromo,
  removePromo: dashboard.actions.removePromo,
  addWinbackPromo: dashboard.actions.addWinbackPromo,
  clearCart: dashboard.actions.clearCart,
  updateBilling: dashboard.actions.updateBilling,
  resetBilling: dashboard.actions.resetBilling,
  fetchToken: common.actions.fetchToken,
  getAccount: common.actions.getAccount,
  fetchEnglishAllContent: content.actions.fetchEnglishAllContent,
  fetchSpanishAllContent: content.actions.fetchSpanishAllContent,
  fetchEnglishDictionaryContent: content.actions.fetchEnglishDictionaryContent,
  fetchSpanishDictionaryContent: content.actions.fetchSpanishDictionaryContent,
  fetchEnglishDashboardContent: content.actions.fetchEnglishDashboardContent,
  fetchSpanishDashboardContent: content.actions.fetchSpanishDashboardContent,
  fetchEnglishActivationContent: content.actions.fetchEnglishActivationContent,
  fetchSpanishActivationContent: content.actions.fetchSpanishActivationContent,
  fetchIdentity: dashboard.actions.fetchIdentityInfo,
  fetchIdentityInfoSuccess: dashboard.actions.fetchIdentityInfoSuccess,
  fetchIdentityInfoFailure: dashboard.actions.fetchIdentityInfoFailure,
  updateCustomer: dashboard.actions.updateCustomer,
  updateCustomerInfo: dashboard.actions.updateCustomerInfo,
  updateIDCMTCustomerInfo: dashboard.actions.updateIDCMTCustomerInfo,
  fetchDashboard: dashboard.actions.fetchCustomerDashboard,
  restartDevice: dashboard.actions.restartDevice,
  fetchAuxAccount: dashboard.actions.fetchAuxAccount,
  createAuxAccount: dashboard.actions.createAuxAccount,
  updateScheduledMove: dashboard.actions.updateScheduledMove,
  fetchPrepaidTransactions: dashboard.actions.fetchPrepaidTransactions,
  fetchPaymentInfo: dashboard.actions.fetchPaymentInfo,
  enableAutoRefill: dashboard.actions.enableAutoRefill,
  disableAutoRefill: dashboard.actions.disableAutoRefill,
  fetchPromos: dashboard.actions.fetchPromos,
  fetchTaxData: dashboard.actions.fetchTaxData,
  refillPrepaidAccount: dashboard.actions.refillPrepaidAccount,
  redeemRefill: dashboard.actions.redeemRefill,
  addPaymentInstrument: dashboard.actions.addPaymentInstrument,
  removePaymentInstrument: dashboard.actions.removePaymentInstrument,
  updatePaymentInstrument: dashboard.actions.updatePaymentInstrument,
  validateActivationCode: dashboard.actions.validateActivationCode,
  fetchSpanishRefillContent: content.actions.fetchSpanishRefillContent,
  fetchEnglishRefillContent: content.actions.fetchEnglishRefillContent,
  fetchSpanishMerchantContent: content.actions.fetchSpanishMerchantContent,
  fetchEnglishMerchantContent: content.actions.fetchEnglishMerchantContent,
  fetchSpanishMoveContent: content.actions.fetchSpanishMoveContent,
  fetchEnglishMoveContent: content.actions.fetchEnglishMoveContent,
  fetchSpanishReconnectContent: content.actions.fetchSpanishReconnectContent,
  fetchEnglishReconnectContent: content.actions.fetchEnglishReconnectContent,
  fetchSpanishCartContent: content.actions.fetchSpanishCartContent,
  fetchEnglishCartContent: content.actions.fetchEnglishCartContent,
  setNotificationMessage: common.actions.setNotificationMessage,
  clearNotificationMessage: common.actions.clearNotificationMessage,
  setConfirmationMessage: common.actions.setConfirmationMessage,
  clearConfirmationMessage: common.actions.clearConfirmationMessage,
  updateCpcResponse: dashboard.actions.updateCpcResponse,
  fetchBroadbandFactsContent: dashboard.actions.fetchBroadbandFactsContent,
};

export default connect(mapStateToProps, mapDispatchToProps);