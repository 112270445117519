import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import withBuyflow from '../../redux/connectors/buyflow'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const BuyFlowError = (props) => {
    const {
        locale, 
        cartPages,
        updateProgress,
        dashboard
    } = props
    const [pageTitle, setPageTitle] = useState('Xfinity Prepaid')

    useEffect(() => {
        setPageTitle('Error - Xfinity Prepaid')
        updateProgress({ title: '', progress: 100 })
    }, [cartPages]) // eslint-disable-line react-hooks/exhaustive-deps

    const clickLink = () => {
        const shopURL = process.env.REACT_APP_SHOPBASE_URL + ''
        window.location.replace(shopURL)
    }
    return (
        <BuyFlowLayout locale={locale}>
            <TrackUserData data={dashboard} />
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="main__width-constraint error-page">
                <h1>
                    <strong>Something's not right. </strong>
                </h1>
                <p>
                    We're having trouble completing your request.
                </p>
                <p>
                    <button className="link-button" data-testid="click-link" onClick={clickLink} data-tracking={digitalDataButtonClick('back', 'Back to xfinityprepaid.com')}>Back to xfinityprepaid.com</button>
                </p>
            </div>
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}

export default withBuyflow(BuyFlowError)
