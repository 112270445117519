import { Link } from 'react-router-dom'
// import { useEffect, useState } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

const StickyCta = ({content, currentLocale, haveValidPromo, dashboardContent, deactivatedAccount, reconnectContent, isWinbackPromo}) => {

    // determine if we have a promotion available to show
    // const [displayPromo, setDisplayPromo] = useState(false)
    // useEffect(() => {
    //     if (haveValidPromo) setDisplayPromo(true)
    // }, [haveValidPromo])
    const trackReconnect = () => {
        trackEvent({
            event: 'dashboard',
            dashboardPage: 'reconnect',
            dashboardEvent: 'reactivate',
            dashboardDetail: ''
        })
    }
    return (
        <div className="sticky-cta">
            {haveValidPromo && !deactivatedAccount && !isWinbackPromo &&
                <div className="sticky-cta__promo-banner">
                    {dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.stickyTabText}
                </div>
            }
            <div className="sticky-cta__main">
            { deactivatedAccount ? 
                <Link to={`/${currentLocale}/reconnect`} onClick={trackReconnect} className="button button--inline" data-tracking={digitalDataLinkClick('reconnect', `/${currentLocale}/reconnect`, reconnectContent?.step1?.reactivateYourAccount)}>
                    { reconnectContent?.step1?.reactivateYourAccount }
                </Link> :

                <Link
                    to={`/${currentLocale}/refill`}
                    className="button button--inline"
                    onClick={() => {
                        trackEvent({
                            event: 'dashboard',
                            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                            dashboardEvent: 'refill now',
                            dashboardDetail: 'internet'
                        })
                    }}
                    data-tracking={digitalDataLinkClick('refill-now', `/${currentLocale}/refill`, content?.linkText)}
                >
                    {content?.linkText}
                </Link>
            }
            </div>
        </div>
    )
}

export default withDashboard(StickyCta)