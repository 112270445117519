import { FunctionComponent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { dictionaryToObject } from '../utils/utils'
import withBuyflow from '../redux/connectors/buyflow'
import Header from '../components/Header'
import Footer from '../components/buyflow/Footer'
import { NotificationBanner } from '../components/buyflow/NotificationBanner'
import { ProgressBar } from '../components/buyflow/ProgressBar'

const BuyFlowLayout: FunctionComponent<any> = props => {
    const {
        updateCartContent,
        updateCheckoutContent,
        updateDictionaryContent,
        checkoutContent,
        loadScreen,
        showNotification,
        notificationMessage,
        progress,
        progressTitle,
        fetchProductDetails,
        fetchingProductDetails,
        fetchedProductDetails,
        productDetailsLoaded,
        generateTrackingId,
        trackingId,
        locationId,
        validateServiceability,
        validatingServiceability,
        validatedServiceability,
        updateMarketId,
        marketId,
        getPromos,
        locale,
        pageClass
    } = props
    const [contentLoaded, setContentLoaded] = useState(false)
    

    // language switching logic
    const [language, setLanguage] = useState('en')
    useEffect(() => {
        if (contentLoaded && language !== locale) checkForContent()
    }, [locale]) // eslint-disable-line react-hooks/exhaustive-deps
    
    // function for retrieving content, either from local storage or via API
    const checkForContent = () => {
        // this is the function that will be called if no content has been brought into local storage yet
        async function fetchData() {
            // set props for fetching content
            const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER
            const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD
            let BUYFLOW_CONTENT_API:string = process.env.REACT_APP_BUYFLOW_CONTENT_API!
            let CART_CONTENT_API:string = process.env.REACT_APP_CART_CONTENT_API!
            let DICTIONARY_API:string = process.env.REACT_APP_DICTIONARY_API!
            if (props.locale !== 'en') {
                BUYFLOW_CONTENT_API = BUYFLOW_CONTENT_API.replace('/api/', '/api/' + props.locale + '/')
                CART_CONTENT_API = CART_CONTENT_API.replace('/api/', '/api/' + props.locale + '/')
                DICTIONARY_API = DICTIONARY_API.replace('/api/', '/api/' + props.locale + '/')
            }
            // check for authorization credentials
            let params = {}
            if (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) {
                params = {
                    headers: new Headers({
                        'Authorization': 'Basic ' + Buffer.from(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD).toString('base64')
                    })
                }
            }
            // now handle separate API calls for dictionary and for content
            const [sourceContent, cartContent, sourceDictionary] = await Promise.all([
                fetch(BUYFLOW_CONTENT_API, params).then(r => r.json()),
                fetch(CART_CONTENT_API, params).then(r => r.json()),
                fetch(DICTIONARY_API, params).then(r => r.json())
            ])
            //let fetchedContent: {[k: string]: any} = contentArrayToObj(sourceContent.buyflow)
            updateCartContent(cartContent)
            updateCheckoutContent(sourceContent)
            updateDictionaryContent(dictionaryToObject(sourceDictionary.dictionary))
            setContentLoaded(true)
            setLanguage(locale)
        }


        // only fetch data if it is not in the store
        if ((checkoutContent && Object.keys(checkoutContent).length === 0) || language !== locale) { 
            fetchData()
            setContentLoaded(false)
        }
        else setContentLoaded(true)
    }

    // get product details
    useEffect(() => {
        if (!productDetailsLoaded) {
            if (marketId && trackingId && locationId && !fetchedProductDetails && !fetchingProductDetails) {
                fetchProductDetails(marketId, trackingId)
                getPromos(trackingId)
            }
        }
        if (locationId && !validatedServiceability && !validatingServiceability) {
            validateServiceability()
        }
    }, [marketId, trackingId, locationId]) // eslint-disable-line react-hooks/exhaustive-deps


    // initial load
    useEffect(() => {
        if (!trackingId) {
            generateTrackingId()
        }
        if (!marketId) {
            const sessionId = sessionStorage.getItem('marketId')
            if (sessionId) {
                updateMarketId(sessionId)
            }
        }
        checkForContent()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (contentLoaded) {
        return (
            <div className={`prepaid-wrapper buyflow-prepaid ${pageClass}`}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Xfinity Prepaid</title>
                    <link rel="icon" href="/favicon.ico" />
                </Helmet>

                {!loadScreen && showNotification && <NotificationBanner notificationMessage={notificationMessage} /> }
                <Header locale={locale} disableLogout="true" />
                {!loadScreen && <ProgressBar title={progressTitle} progress={progress} /> }

                    <main>
                        <div id="main" role="main" className="main">
                            <div className="main__width-constraint">
                                {props.children}
                            </div>
                        </div>
                    </main>

                <Footer />

            </div>
        )
    }
    else return null
}

export default withBuyflow(BuyFlowLayout)