import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BuyFlowLayout from "../../layouts/BuyFlowLayout";
import { isObject } from "../../utils/utils";
import withBuyflow from "../../redux/connectors/buyflow";
import Cart from "../../components/buyflow/Cart";
import { useForm } from "react-hook-form";
import { GlobalModal as Modal } from "../../components/Modal";
import { Link } from "react-router-dom";
import ProgressSpinner from "../../components/ProgressSpinner";
import ModalPromoCTA from "../../components/buyflow/ModalPromoCTA";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../utils/utils";
import { digitalDataButtonClick, digitalDataLinkClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const BuyflowOrderReview = (props) => {
  const {
    locale,
    locationId,
    trackingId,
    cartPages,
    checkoutContent,
    updateProgress,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    showFreeShipping,
    shippingAddressLine1,
    shippingAddressLine2,
    shippingCity,
    shippingState,
    shippingZip,
    firstName,
    lastName,
    digitalSession,
    prepaidInternetProduct,
    email,
    displayTn,
    tn,
    updateField,
    langPref,
    submitOrder,
    submitOrderFailed,
    submitingOrder,
    productsByName,
    productTaxDetailsByName,
    cartTotal,
    activationSource,
    smsOptIn,
    geoCode,
    houseKey,
    serviceabilityBusinessErrorCode,
    bundleDetails,
    shippingCode,
    shippingRate,
    shippingTaxDetails,
    cart,
    showNotification,
    orderPlaced,
    haveValidPromos,
    havePromoInCart,
    updatePageClass,
    cpcResponse,
    paymentId,
    resetPaymentId,
    errorCode,
    serviceabilityData
  } = props;

  const [pageTitle, setPageTitle] = useState("Xfinity Prepaid");
  const { handleSubmit } = useForm();
  const [consent, setConsent] = useState(false);
  const [cardTypeValue, getCardTypeValue] = useState("");
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => { 
    sessionStorage.removeItem("cpcTermsAndConditions");
    sessionStorage.removeItem("enrollInAutoRefill");
    getCardTypeValue(
      (cpcResponse?.cpcData?.cardDetails?.cardType)?.replace(
        /([a-z0-9])([A-Z])/g,
        "$1 $2"
      )
    );
  }, [cpcResponse]);
  useEffect(() => {
    if (isObject(cartPages)) setPageTitle("Order Review - Xfinity Prepaid");
    updatePageClass("direct-promo");
    updateProgress({
      title: checkoutContent?.orderReview?.statusLabel,
      progress: 80,
    });
    let products: any;
    products = [];
    for (let i = 0; i < prepaidInternetProduct?.productData.plans.length; i++) {
      let product: any;
      product = {};
      product.name =
        prepaidInternetProduct.productData.plans[i].planDisplayText;
      product.id = prepaidInternetProduct.productData.plans[i].plan;
      product.sku = prepaidInternetProduct?.productData?.plans[i].sku
      product.price = prepaidInternetProduct.productData.plans[i].rate;
      product.dimension1 = "buy";
      product.dimension4 = "website";
      product.quantity = 1;
      products.push(product);
    }
    setProducts(products);
    trackEvent({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 7 },
          products,
        },
      },
    });
    console.log("here is the data I need: ", cpcResponse);
  }, [cartPages]); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();

  useEffect(() => {
    if (orderPlaced) {
      trackEvent({
        event: "buyflow info",
        buyStep: "step 5",
        buyStepName: "order complete",
        buyEvent: "sms marketing",
        buyDetail: smsOptIn ? "opt in" : "opt out",
      });

      trackEvent({
        event: "buyflow info",
        buyStep: "step 5",
        buyStepName: "order complete",
        buyEvent: "comm language",
        buyDetail: langPref,
      });

      if (haveValidPromos && havePromoInCart) {
        trackEvent({
          event: "buyflow info",
          buyStep: "step 5",
          buyStepName: "order complete",
          buyEvent: "offer acquisition",
          buyDetail: "purchase",
        });
      }

      history.push("/" + locale + "/buy/order-confirmation");
    }
  }, [orderPlaced]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (submitOrderFailed) {
      trackEvent({
        event: "buyflow info",
        buyStep: "step 4",
        buyStepName: "order review",
        buyEvent: "error",
        buyDetail: "system error",
      });
      history.push("/" + locale + "/buy/error");
    }
  }, [submitOrderFailed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showNotification) {
      setConsent(false);
      trackEvent({
        event: "buyflow info",
        buyStep: "step 4",
        buyStepName: "order review",
        buyEvent: "error",
        buyDetail: errorCode,
      });
    }
  }, [showNotification]);

  useEffect(() => {
    if (haveValidPromos && !havePromoInCart) {
      trackEvent({
        event: "offer shown",
        offer: "acquisition 10 for first refill",
        offerLocation: "buy flow offer",
      });
    }
  }, [haveValidPromos, havePromoInCart]);

  const onSubmit = (e) => {
    trackEvent({
      event: "buyflow info",
      buyStep: "step 4",
      buyStepName: "order review",
      buyEvent: "next step cta",
      buyDetail: checkoutContent?.orderReview?.completePurchaseButtonLabel,
    });
    window.scrollTo(0, 0);
    const customerInfo = {
      address: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        zip: zip,
      },
      locationId: locationId,
    };
    // All lowercase!
    const paymentInfo = {
      custInfo: {
        firstName: cpcResponse?.cpcData?.customerDetails?.firstName,
        lastName: cpcResponse?.cpcData?.customerDetails?.lastName,
        addressLine1: cpcResponse?.paymentInfo?.address,
        addressLine2: cpcResponse?.paymentInfo?.addressLine2,
        city: cpcResponse?.paymentInfo?.city,
        state: cpcResponse?.paymentInfo?.state,
        zip: cpcResponse?.paymentInfo?.zipCode,
        country: "US",
      },
      amount: cartTotal.toFixed(2),
      paymentInstToken: cpcResponse?.cpcData?.cardDetails?.token,
      expirationDate: cpcResponse?.cpcData?.cardDetails?.expirationDate,
      cardType: cpcResponse?.cpcData?.cardDetails?.cardType.toUpperCase(),
      cardNumber: cpcResponse?.cpcData?.cardDetails?.cardLast4Digits,
    };
    const shippingAddress = {
      firstName: cpcResponse?.cpcData?.customerDetails?.firstName,
      lastName: cpcResponse?.cpcData?.customerDetails?.lastName,
      addressLine1: shippingAddressLine1,
      addressLine2: shippingAddressLine2,
      city: shippingCity,
      state: shippingState,
      zip: shippingZip,
    };

    let cartItems: any;
    cartItems = [];
    if (Object.keys(productsByName).includes("ECO-HSD-XB3")) {
      cartItems.push("ECO-HSD-XB3");
    } else {
      cartItems.push("ECO-HSD-WIFI");
    }

    console.log("cartItems", cartItems);
    let deviceDetails: any;
    deviceDetails = [];

    cartItems.forEach((itemName) => {
      console.log("itemName", itemName);
      const product = productsByName[itemName];
      let rate = 0;
      console.log("product", product);
      product.plans.forEach((plan) => {
        rate += parseFloat(plan.rate);
      });

      let lob = product.lob;
      if (lob === "VDO") {
        lob = "VIDEO";
      }
      let details: any;
      details = {
        prepaidProduct: {
          prepaidProductCode: product.name,
          prepaidProductName: product.plans[0].planDesc,
          lob,
          planCode: product.plans[0].plan,
          rate,
          planStatus: "",
          chargeCode: [],
        },
      };
      product.plans.forEach((plan) => {
        const taxDetails = productTaxDetailsByName[product.name];
        if (taxDetails) {
          details.prepaidProduct.chargeCode.push({
            chargeCode: plan.chargeCode,
            prodTaxDetails: taxDetails[plan.chargeCode],
          });
        } else {
          console.log("ERROR: tax details are missing");
        }
      });
      deviceDetails.push(details);
    });

    cart.promos.forEach((promo) => {
      const details = {
        prepaidProduct: {
          prepaidProductCode: promo.discountDetails[0].product,
          prepaidProductName: "",
          lob: promo.discountDetails[0].lob,
          planCode: promo.discountDetails[0].plan,
          rate: promo.discountDetails[0].effectivePrice,
          planStatus: "",
          chargeCode: [
            {
              chargeCode: promo.discountDetails[0].plan,
              prodTaxDetails: null,
            },
          ],
        },
      };
      deviceDetails.push(details);
    });
    let payload: any;
    payload = {
      customerInfo,
      paymentinfo: paymentInfo, // different case
      prepaidPaymentID:paymentId,
      shippingAddress,
      trackingId: trackingId,
      activationSource: activationSource,
      name: {
        firstName: firstName,
        lastName: lastName,
      },
      emailOptin: smsOptIn ? "Y" : "N",
      geoCode: geoCode,
      email: email,
      tn: tn,
      langPref: langPref,
      housekey: houseKey,
      isCPC: true,
      serviceabilityBusinessErrorCode: serviceabilityBusinessErrorCode,
      bundleDetails: bundleDetails,
      shippingDetails: {
        shippingCode: shippingCode,
        shippingRate: shippingRate,
        shipTaxDetails: shippingTaxDetails,
      },
      deviceDetails,
      // XXX TODO
      custGuid: digitalSession.identity.custGuid,
    };

    if (digitalSession.identity.errorCode) {
      payload.errorCode = digitalSession.identity.errorCode;
    }

    if (cart.offerInCart) {
      const promo = cart.promos[0];
      const discountDetails = {
        discountId: promo.discId,
        offerType: promo.offerType,
        productQuantity: 1,
        discountPlanDet: [
          {
            planCode: promo.discountDetails[0].plan,
            productCode: promo.discountDetails[0].product,
            originalPrice: promo.discountDetails[0].originPricePerUnit,
            discountPrice: promo.discountDetails[0].discountedPricePerUnit,
            planQty: 1,
          },
        ],
      };
      payload.discountDetails = [discountDetails];
    }
    // console.log('order payload', payload)
    updateField({ field: showNotification, value: false });
    submitOrder(payload);
  };
  const onToggleField = (e) => {
    updateField({ field: [e.target.name], value: e.target.checked });
  };
  const onUpdateField = (e) => {
    updateField({ field: [e.target.name], value: e.target.value });
  };

  const xfinityId = () => {
    if (digitalSession.contact.verificationStatus.mobile) {
      return displayTn;
    }
    if (digitalSession.contact.verificationStatus.email) {
      return email;
    }
    return displayTn;
  };

  const updatePaymentInformation = () => {
    trackEvent({
      event: "buyflow info",
      buyStep: "step 4",
      buyStepName: "order review",
      buyEvent: "payment method component",
      buyDetail: "new card details",
    });
    resetPaymentId();
  };
  return (
    <BuyFlowLayout locale={locale}>
      <TrackUserData data={serviceabilityData} />
      <TrackPurchaseData offerIncluded={false} price={cartTotal} productID={products.length > 0 ? products[0].sku : ''} productType={'prepaid-kit'} quantity={1} transactionID={""} screenName="review"/>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {submitingOrder ? (
        <ProgressSpinner
          loadingText={
            checkoutContent?.checkoutLoadingScreen?.loadingScreenText + "…"
          }
        />
      ) : (
        <div className="main_width-constraint clear">
          <div className="page__header">
            <h1 className="page__title">
              {checkoutContent?.orderReview?.orderReviewPageTitle}
            </h1>
          </div>

          <div className="cart__display-small-medium">
            <Cart showDetails={true} />
          </div>
          <div className="main__desktop-body">
            {haveValidPromos && !havePromoInCart ? (
              <ModalPromoCTA
                ctaText="test"
                modalContent={checkoutContent?.orderReview?.promoModal}
              />
            ) : null}
            <form id="my-info" data-testid="my-Info" onSubmit={() => handleSubmit(onSubmit)}>
              <div className="card card--clear-zero">
                <div className="card__header">
                  <div className="card__header-title">
                    {checkoutContent?.orderReview?.paymentSectionTitle}
                  </div>
                </div>
                <div className="card__summary">
                  <div className="vertical-margin-block">
                    <label htmlFor="summary-cardtype">
                      {checkoutContent?.orderReview?.cardTypeLabel}
                    </label>
                    <input
                      id="summary-cardtype"
                      value={cardTypeValue}
                      type="text"
                      readOnly
                    />
                  </div>

                  <div className="vertical-margin-block">
                    <label htmlFor="summary-cardnumber">
                      {checkoutContent?.orderReview?.cardNumberLabel}
                    </label>
                    <input
                      id="summary-cardnumber"
                      value={`XXXX XXXX XXXX ${cpcResponse?.cpcData?.cardDetails?.cardLast4Digits}`}
                      type="text"
                      readOnly
                    />
                  </div>

                  <div className="vertical-margin-block">
                    <label htmlFor="summary-expirationdate">
                      {checkoutContent?.orderReview?.expirationDateLabel}
                    </label>
                    <input
                      id="summary-expirationdate"
                      value={
                        cpcResponse?.cpcData?.cardDetails?.expirationDate?.substring(
                          0,
                          2
                        ) +
                        "/" +
                        cpcResponse?.cpcData?.cardDetails?.expirationDate?.substring(
                          2,
                          4
                        )
                      }
                      type="text"
                      readOnly
                    />
                  </div>

                  <div className="vertical-margin-block">
                    <div className="review-label">
                      {checkoutContent?.orderReview?.billingAddressLabel}
                    </div>
                    <div>
                      {cpcResponse?.paymentInfo?.address}
                      <br />
                      {cpcResponse?.paymentInfo?.addressLine2 &&
                        cpcResponse?.paymentInfo?.addressLine2}
                      {cpcResponse?.paymentInfo?.addressLine2 && <br />}
                      {cpcResponse?.paymentInfo?.city} ,{" "}
                      {cpcResponse?.paymentInfo?.state}{" "}
                      {cpcResponse?.paymentInfo?.zipCode}
                    </div>
                  </div>
                </div>
                <Link
                  to={`/${locale}/buy/order-details`}
                  onClick={() => updatePaymentInformation()}
                  className="link-callout"
                  data-testid="Link-callout"
                  data-tracking={digitalDataLinkClick('update-payment-information', `/${locale}/buy/order-details`, checkoutContent?.orderReview?.updatePaymentInformationLinkLabel)}
                >
                  {
                    checkoutContent?.orderReview
                      ?.updatePaymentInformationLinkLabel
                  }
                </Link>
              </div>

              <div className="card card--clear-zero">
                <div className="card__header">
                  <div className="card__header-title">
                    {checkoutContent?.orderReview?.shippingSectionTitle}
                  </div>
                </div>
                <div className="card__summary">
                  <div className="vertical-margin-block">
                    <label htmlFor="summary-shippingmethod">
                      {checkoutContent?.orderReview?.shippingMethodLabel}
                    </label>
                    <input
                      id="summary-shippingmethod"
                      type="text"
                      readOnly
                      value={
                        showFreeShipping
                          ? checkoutContent?.orderReview
                              ?.freeShippingChargeStatement
                          : checkoutContent?.orderReview
                              ?.shippingChargeStatement
                      }
                    />
                  </div>

                  <div className="vertical-margin-block">
                    <div className="review-label">
                      {checkoutContent?.orderReview?.shippingAddressLabel}
                    </div>
                    <div>
                      {shippingAddressLine1}
                      <br />
                      {shippingAddressLine2 && shippingAddressLine2}{" "}
                      {shippingAddressLine2 && <br />}
                      {shippingCity} , {shippingState} {shippingZip}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card--clear-zero">
                <div className="card__header">
                  <div className="card__header-title">
                    {checkoutContent?.orderReview?.yourInformationSectionTitle}
                  </div>
                </div>

                <div className="card__summary">
                  <div className="vertical-margin-block">
                    <div className="account-block">
                      <b>{checkoutContent?.orderReview?.nameLabel}</b>
                      <br />
                      {firstName} {lastName}
                    </div>
                  </div>
                  {digitalSession && digitalSession.identity?.custGuid ? (
                    <div className="vertical-margin-block">
                      <div className="account-block">
                        <div className="modal-button-container">
                          <b className="information-label">
                            {checkoutContent?.orderReview?.xfinityIdLabel}
                          </b>
                          <br />
                          {xfinityId()}
                          {prepaidInternetProduct ? (
                            <Modal
                              buttonClasses="link-callout modal-button"
                              body={
                                checkoutContent?.orderReview
                                  ?.aboutYourIdModalText
                              }
                              title=""
                              ctaText={
                                checkoutContent?.orderReview
                                  ?.aboutYourIdLinkLabel
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="vertical-margin-block">
                    <div className="account-block">
                      <div className="modal-button-container">
                        <b className="information-label">
                          {checkoutContent?.orderReview?.emailLabel}
                        </b>
                        <br />
                        {email}

                        {digitalSession && digitalSession.identity?.custGuid ? (
                          <div>
                            {digitalSession.contact.verificationStatus.email ? (
                              <div className="cima-label cima-verified">
                                {
                                  checkoutContent?.orderReview
                                    ?.emailVerifiedLabel
                                }
                              </div>
                            ) : (
                              <Modal
                                buttonClasses="cima-label cima-unverified"
                                body={
                                  checkoutContent?.orderReview
                                    ?.emailUnverifiedModalText
                                }
                                title=""
                                ctaText={
                                  checkoutContent?.orderReview
                                    ?.emilUnverifiedLabel
                                }
                                isVerificationModal="true"
                              />
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="vertical-margin-block">
                    <div className="account-block">
                      <div className="modal-button-container">
                        <b className="information-label">
                          {checkoutContent?.orderReview?.mobileNumberLabel}
                        </b>
                        <br />
                        {displayTn || tn}

                        {digitalSession && digitalSession.identity?.custGuid ? (
                          <div>
                            {digitalSession.contact.verificationStatus
                              .mobile ? (
                              <div className="cima-label cima-verified">
                                {
                                  checkoutContent?.orderReview
                                    ?.mobileNumberVerifiedLabel
                                }
                              </div>
                            ) : (
                              <Modal
                                buttonClasses="cima-label cima-unverified"
                                body={
                                  checkoutContent?.orderReview
                                    ?.mobileNumberUnverifiedModalText
                                }
                                title=""
                                ctaText={
                                  checkoutContent?.orderReview
                                    ?.mobileNumberUnverifiedLabel
                                }
                                isVerificationModal="true"
                              />
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="checkbox">
                    <input
                      id="communication"
                      data-testid="communication-checkbox"
                      type="checkbox"
                      className="checkbox__input"
                      name="smsOptIn"
                      onChange={onToggleField}
                    />
                    <label className="checkbox__label" htmlFor="communication">
                      {checkoutContent?.orderReview?.smsOptInFieldLabel}
                    </label>
                  </div>

                  <fieldset
                    form="review-order"
                    name="communication-language-preference"
                  >
                    <legend className="radio__main-label">
                      <span>
                        {
                          checkoutContent?.orderReview
                            ?.languagePreferenceFieldLabel
                        }
                      </span>
                    </legend>

                    <div className="flex">
                      <div className=" flex-item radio radio--inline">
                        <input
                          id="language_en"
                          data-testid="language-en"
                          defaultChecked={langPref === "ENGLISH"}
                          type="radio"
                          className="radio__input"
                          name="langPref"
                          value="ENGLISH"
                          onChange={onUpdateField}
                        />
                        <label htmlFor="language_en" className="radio__label">
                          {checkoutContent?.orderReview?.englishLabel}
                        </label>
                      </div>
                      <div className="flex-item radio radio--inline">
                        <input
                          id="language_es"
                          defaultChecked={langPref === "SPANISH"}
                          type="radio"
                          className="radio__input"
                          name="langPref"
                          value="SPANISH"
                          onChange={onUpdateField}
                        />
                        <label htmlFor="language_es" className="radio__label">
                          {checkoutContent?.orderReview?.spanishLabel}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="card ">
                <div className="checkbox">
                  <input
                    id="consent"
                    type="checkbox"
                    className="checkbox__input"
                    name="consent"
                    onChange={() => setConsent(!consent)}
                  />
                  <label className="checkbox__label" htmlFor="consent">
                    <b
                      dangerouslySetInnerHTML={{
                        __html: checkoutContent?.orderReview?.consentStatement,
                      }}
                    />
                  </label>
                </div>
                <Modal
                  buttonClasses="button--link--underline"
                  body={
                    checkoutContent?.orderReview?.termsAndConditionsModalContent
                  }
                  title={
                    checkoutContent?.orderReview
                      ?.termsAndConditionsModalLinkLabel
                  }
                  id="cvvModal"
                  ctaText={
                    checkoutContent?.orderReview
                      ?.termsAndConditionsModalLinkLabel
                  }
                />
              </div>
              <input
                disabled={!consent}
                value={
                  checkoutContent?.orderReview?.completePurchaseButtonLabel
                }
                type="button"
                onClick={(e) => onSubmit(e)}
                className="button form-submit-button"
                data-tracking={digitalDataButtonClick('submit', checkoutContent?.orderReview?.completePurchaseButtonLabel)}
              />
            </form>
          </div>
          <div className="cart__display-large main__desktop-sidebar-right">
            <Cart showDetails={true} />
          </div>
        </div>
      )}
      <TrackPageComplete />
    </BuyFlowLayout>
  );
};

export default withBuyflow(BuyflowOrderReview);
