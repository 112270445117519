import DashboardLayout from '../../layouts/DashboardLayout'
//import ProgressSpinner from '../../components/ProgressSpinner'
import ConfirmationButton from '../../components/move/ConfirmationButton';
import { useEffect } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const MoveNow = props => {
    const {
        history,
        currentLocale,
        accountId,
        custGuid,
        dashboard,
        auxAccount,
        createAuxAccount,
        creatingAuxAccount,
        createdAuxAccount,
        createAuxAccountFailed,
        isTestFile
        // updateField
    } = props

    //Dynamic Variables
    const animTime = 2000;

    function performMove() {
        if (!creatingAuxAccount) {
            trackEvent({
                event: 'move info',
                moveStep: 'step 3',
                moveStepName: 'ready to move',
                moveEvent: 'next step cta',
                moveDetail: 'press and hold to move'
            })
    
            //Trigger API
            let addressData:any = sessionStorage.getItem('xppdata')
            if (!addressData) {
                console.log('Service availability data not found')
                return
            }
            addressData = JSON.parse(addressData)
    
            let payload = {
                channel: 'ONLINE',
                flow: 'MOVE',
                accountID: accountId,
                custGuid,
                updateCustomer:false,
                customerInfo: {
                    email: dashboard.customerInfo.emailAddress,
                    emailOptin: dashboard.customerInfo.emailOptIn,
                    smsOptin: dashboard.customerInfo.smsOptIn,
                    firstName: dashboard.customerInfo.firstName,
                    lastName: dashboard.customerInfo.lastName,
                    langPref: dashboard.customerInfo.language,
                    phone: dashboard.customerInfo.phoneNumber,
                    serviceAddress: {
                        addressLine1: addressData.address,
                        addressLine2: addressData.unit,
                        city: addressData.city,
                        houseKey: addressData.houseKey,
                        locationID: addressData.locationId,
                        state: addressData.state,
                        zip: addressData.zip
                    },
                },
                operatorUserName: null
            }
    
            console.log('createAuxAccount payload', payload)
    
            createAuxAccount(payload)
        }
    }

    useEffect(() => {
        if (createdAuxAccount) {
            history?.push(`/${currentLocale}/move/now-confirmation`)
        }
    }, [createdAuxAccount]) // eslint-disable-line

    useEffect(() => {
        if (createAuxAccountFailed) {
            console.error('Failed to create aux account, an API error occurred')
            history?.push(`/${currentLocale}/error`)
            // history.push(`/${currentLocale}/move/now-confirmation`)
        }
    }, [createAuxAccountFailed]) // eslint-disable-line

    useEffect(() => {
        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility?.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 3',
            moveStepName: 'ready to move',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
    }, [auxAccount])

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move Now'}>
            <TrackUserData data={dashboard} />
            { props.moveContent.loaded &&
            <div className="main__width-constraint move">
                <div className="card move--now">
                    <button className="back-button" onClick={() => history?.goBack()} data-tracking={digitalDataButtonClick('back', 'Back')}>
                        Back
                    </button>
                    <div>
                        <h1 className="page__title">
                            {props.moveContent.moveNow.readyToMoveNow}
                        </h1>
                        <div className="alert-section">
                            {props.moveContent.moveNow.yourServiceWillBeDisconnected &&
                                <div className="alert-section__row"><span className="alert-section__warning-sign"></span> <b>{props.moveContent.moveNow.yourServiceWillBeDisconnected}</b></div>
                            }
                            {props.moveContent?.acP?.youWillNeedToReEnrollInAcP &&
                                <div className="alert-section__row">
                                  <span className="alert-section__warning-sign"></span>
                                  <b dangerouslySetInnerHTML={{__html:props?.moveContent?.acP?.youWillNeedToReEnrollInAcP}} />
                                </div>
                            }
                        </div>
                        <p>{props.moveContent.moveNow.onceYouCompleteThisStep}</p>
                        <b className="">{props.moveContent.moveNow.youWillNotHaveAnInternetConnection}</b>
                        <div className="button--container confirm-button--container">
                            <ConfirmationButton title={props.moveContent.moveNow.presSHolDTOMovE.text} animTime={animTime} CB={performMove} isTestFile={isTestFile}></ConfirmationButton>
                        </div>
                    </div>
                </div>
            </div>
            }
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveNow)