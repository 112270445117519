import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import withDashboard from '../../redux/connectors/dashboard'
import { CSSTransition } from 'react-transition-group'
import Cart from '../refill/Cart'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '../../utils/utils'
import AccordionText from '../AccordionText'
import { digitalDataButtonClick, digitalDataAccordionClick, digitalDataAddToCart } from '../../utils/adobe-analytics'

const WinbackPromoModal = (props) => {
    const {
        addWinbackPromo,
        currentLocale,
        displayPromoModal,
        fetchedPaymentInfo,
        modalCloseButton,
        paymentInfo,
        promos,
        setDisplayPromoModal,
        refillContent,
        cart,
        havePromoInCart
    } = props
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)


    const history = useHistory()

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false)
            document.removeEventListener("mousedown", handleClick)
            document.body.classList.remove('modal-open')
            setDisplayPromoModal(false)
            routeToCorrectPage()
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener("mousedown", handleClick)
        } else {
            document.removeEventListener("mousedown", handleClick)
        }
        // eslint-disable-next-line
    }, [modalIsOpen])
    useEffect(() => {
        if (displayPromoModal) {
        setModalIsOpen(true)
        document.body.classList.add('modal-open')
        trackEvent({
            event: 'refill info',
            refillStep: 'step 1',
            refillStepName: 'start refill',
            refillEvent: 'offer winback modal',
            refillDetail: 'impression'
        })
        }
    }, [displayPromoModal])

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }
    
    const handlePurchaseClick = () => {
        setModalIsOpen(!modalIsOpen)
        document.removeEventListener("mousedown", handleClick)
        addWinbackPromo(promos.discounts[0].discId)
        trackEvent({
            event: 'refill info',
            refillStep: 'step 1',
            refillStepName: 'start refill',
            refillEvent: 'offer winback modal',
            refillDetail: 'click'
        })
        trackEvent({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'USD',
                add: {
                    products:
                    [
                        {
                            name: "30 days plan for customer",
                            id: "HSD_030",
                            price: 90,
                            dimension1: "refill",
                            dimension4: "website",
                            quantity: 3
                        }
                    ]
                }
            }
        })
        digitalDataAddToCart('standard refill flow', 'addToCart', '.direct-promo',havePromoInCart,cart?.internet);
        routeToCorrectPage()
    }
    const routeToCorrectPage = () => {
        if (fetchedPaymentInfo && paymentInfo) {
            console.log('redirecitng to review page...')
            trackEvent({
                event: 'refill flow',
                refillStep: 'step 2',
                refillStepName: 'payment',
                refillStepDetail: 'saved debit credit'
            })
            history.push('/' + currentLocale + '/refill/review')
        } else {
            console.log('redirecitng to payment page...')
            history.push('/' + currentLocale + '/refill/payment')
        }
    }

    const toggleAccordion = () => {
        setExpanded(!expanded)
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                <div ref={node} className="modal__body direct-promo winback">
                            <h3>{refillContent?.refillFlow?.winback?.buy230Day}</h3>
                            <div className="direct-promo__hero-banner">
                                <img className="display-large" src={refillContent?.refillFlow?.winback?.modalImage?.desktopImage?.url} alt={refillContent?.refillFlow?.winback?.modalImage?.desktopImage?.alt} />
                                <img className="display-small-medium" src={refillContent?.refillFlow?.winback?.modalImage?.mobileImage?.url} alt={refillContent?.refillFlow?.winback?.modalImage?.mobileImage?.alt} />
                                <div className="direct-promo__hero-banner-container">
                                    <div className="main__width-constraint clear">
                                        <div className="banner-title">
                                        {refillContent?.refillFlow?.winback?._30DaysFree}
                                        </div>
                                        {refillContent?.refillFlow?.winback?.save45Today}
                                    </div>
                                </div>
                            </div>

                            <div id="main-content">
                                <div className="main__width-constraint">
                                    <div className="card card-promo">
                                        <div className="card__summary">
                                            <div v-if="version == 'version1'" className="accordion direct-promo-accordion" role="tablist" multiselectable="true">
                                                <div className="accordion direct-promo-accordion" role="tablist" multiselectable="true">
                                                    <div className="accordion__item">
                                                        <div
                                                            id="'01-tab'"
                                                            className={`accordion__tab direct-promo-accordion__tab ${expanded ? "" : "active"}`}
                                                            role="tab"
                                                            aria-expanded={expanded}
                                                            aria-controls="01-panel"
                                                            onClick={() => {
                                                                toggleAccordion();
                                                                digitalDataAccordionClick('promo', '$90 For up to 90 days', '.accordion-tab');
                                                            }}
                                                        >
                                                            <table v-if="cartPages.step1Internet.promoModal" className="price-wrap">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="price-wrap__amount">
                                                                            <span>$</span>90
                                                                        </td>
                                                                        <td className="price-wrap__label">
                                                                            <strong>For up to 90 days</strong>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="accordion__icon">
                                                                <span className="line" /><span className="line" />
                                                            </div>
                                                        </div>

                                                        <CSSTransition
                                                            in={expanded}
                                                            timeout={{ enter: 500, exit: 0 }}
                                                            classNames="expand"
                                                        >
                                                            <div
                                                                id="'01-panel'"
                                                                className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
                                                                tabIndex={0}
                                                                role="tabpanel"
                                                            >
                                                                <Cart showLineOffer={true}/>
                                                            </div>
                                                        </CSSTransition>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                            <div className="button--container">
                                                <button className="button" onClick={() => handlePurchaseClick()} data-tracking={digitalDataButtonClick('refill-winback-purchase-offer', refillContent?.refillFlow?.winback?.purchaseOffer)}>{refillContent?.refillFlow?.winback?.purchaseOffer}</button>
                                                <br />
                                                <br />
                                                <AccordionText title={refillContent?.refillFlow?.winback?.pricingAndOtherInfo?.primaryButtonText} body={refillContent?.refillFlow?.winback?.pricingAndOtherInfo?.text} />
                                                <button className="button--link" onClick={() => closeModal()} data-tracking={digitalDataButtonClick('close-modal', refillContent?.refillFlow?.winback?.pricingAndOtherInfo?.secondaryButtonText, true)}>{refillContent?.refillFlow?.winback?.pricingAndOtherInfo?.secondaryButtonText}</button>
                                            </div>
                                    </div>

                                </div>
                            </div>
                            <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', modalCloseButton, true)}>
                                {modalCloseButton}
                                <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                            </button>
                        </div >
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withDashboard(WinbackPromoModal)
