import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import withDashboard from '../../redux/connectors/dashboard'
import { getProductName, getProductPrice, trackEvent } from '../../utils/utils'
import { digitalDataAddToCart, digitalDataButtonClick, digitalDataModalOpen } from '../../utils/adobe-analytics'

const ModalCheckout = (props) => {
    const {
        title,
        bodyText,
        modalCloseButton,
        buttonClasses,
        ctaText,
        content,
        modalType,
        packageName,
        packageDisplayName,
        cart, togglePackage,
        toggleAddOn,
        removeAllTvProducts,
        updateEditCart,
        dashboard,
        fetchTaxData,
        havePromoInCart
    } = props
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)


    const openModal = () => {
        setModalIsOpen(true)
        // document.body.className += ' modal-open'
        document.body.classList.add('modal-open')
    }

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false)
            // document.body.className = document.body.className.replace(new RegExp(/modal-open/g), '')
            document.body.classList.remove('modal-open')
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener("mousedown", handleClick)
        } else {
            document.removeEventListener("mousedown", handleClick)
        }
        // eslint-disable-next-line
    }, [modalIsOpen])

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }

    const toggleProduct = () => {
        const index = cart.packages.indexOf(packageName)
        let products: any
        products = []
        let basicTV: any
        basicTV = {}
        basicTV.name = 'Prepaid Instant TV Basic'
        basicTV.id = 'VDO_XITV_30'
        basicTV.price = 22
        basicTV.dimension1 = 'refill'
        basicTV.dimension4 = 'website'
        basicTV.quantity = 1
        if (index === -1 && cart.packages.length + cart.addOns.length === 0) {
            products.push(basicTV)
        }
        let toggleProduct: any
        toggleProduct = {}
        toggleProduct.name = getProductName(packageName, dashboard?.availablePlans)
        toggleProduct.id = packageName
        toggleProduct.price = getProductPrice(packageName, dashboard?.availablePlans)
        toggleProduct.dimension1 = 'refill'
        toggleProduct.dimension4 = 'website'
        toggleProduct.quantity = 1
        products.push(toggleProduct)
        if (index === -1) {
            trackEvent({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'USD',
                add: {
                products
                }
            }
            })
            digitalDataAddToCart('standard refill flow', 'addToCart', '.activation-conflict-modal',havePromoInCart,cart?.internet);
        } else {
            trackEvent({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'USD',
                remove: {
                products
                }
            }
            })
        }
        togglePackage(packageName)
        fetchTaxData()
        closeModal()
    }
    const toggleChannel = () => {

        const index = cart.addOns.indexOf(packageName)
        let products: any
        products = []
        let basicTV: any
        basicTV = {}
        basicTV.name = 'Prepaid Instant TV Basic'
        basicTV.id = 'VDO_XITV_30'
        basicTV.price = 22
        basicTV.dimension1 = 'refill'
        basicTV.dimension4 = 'website'
        basicTV.quantity = 1
        if (index === -1 && cart.packages.length + cart.addOns.length === 0) {
            products.push(basicTV)
        }
        let toggleProduct: any
        toggleProduct = {}
        toggleProduct.name = getProductName(packageName, dashboard?.availablePlans)
        toggleProduct.id = packageName
        toggleProduct.price = getProductPrice(packageName, dashboard?.availablePlans)
        toggleProduct.dimension1 = 'refill'
        toggleProduct.dimension4 = 'website'
        toggleProduct.quantity = 1
        products.push(toggleProduct)
        if (index === -1) {
            trackEvent({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'USD',
                add: {
                products
                }
            }
            })
            digitalDataAddToCart('standard refill flow', 'addToCart', '.activation-conflict-modal',havePromoInCart,cart?.internet);
        } else {
            trackEvent({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'USD',
                remove: {
                products
                }
            }
            })
        }
        toggleAddOn(packageName)
        fetchTaxData()
        closeModal()
    }
    const toggleAllTvPackages = () => {
        let products: any
        products = []
        if (cart.packages.length > 0) {
            for (let i=0; cart.packages.length > i; i++) {
                let toggleProduct: any
                toggleProduct = {}
                toggleProduct.name = getProductName(cart.packages[i], dashboard?.availablePlans)
                toggleProduct.id = cart.packages[i]
                toggleProduct.price = getProductPrice(cart.packages[i], dashboard?.availablePlans)
                toggleProduct.dimension1 = 'refill'
                toggleProduct.dimension4 = 'website'
                toggleProduct.quantity = 1
                products.push(toggleProduct)
            }
        }
        if (cart.addOns.length > 0) {
            for (let i=0; cart.addOns.length > i; i++) {
                let toggleProduct: any
                toggleProduct = {}
                toggleProduct.name = getProductName(cart.addOns[i], dashboard?.availablePlans)
                toggleProduct.id = cart.addOns[i]
                toggleProduct.price = getProductPrice(cart.addOns[i], dashboard?.availablePlans)
                toggleProduct.dimension1 = 'refill'
                toggleProduct.dimension4 = 'website'
                toggleProduct.quantity = 1
                products.push(toggleProduct)
            }
        }
        trackEvent({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'USD',
                remove: {
                products
                }
            }
        })
        removeAllTvProducts()
        fetchTaxData()
        updateEditCart(false)
        closeModal()
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <button type="button" className={buttonClasses} onClick={openModal} data-testid="modal-open-button" data-tracking={digitalDataModalOpen('activation-conflict')}>{ ctaText }</button>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                    <div ref={node} className="modal__body">
                        <h3 id="modal__title" tabIndex={0} className="modal__title">{ title }</h3>
                        <div dangerouslySetInnerHTML={{__html: bodyText}} />
                        <br />
                        <br />
                        {/* <!-- remove tv basic --> */}
                        { modalType === 'tvBasic' ?
                            <div className="button--container" data-testid="toggle-tv-packages-container">
                                <button className="button" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', content?.cartRemovalBasic?.buttonText, true)}>{ content?.cartRemovalBasic?.buttonText }</button>
                                <br />
                                <br />
                                { content?.cartRemovalBasic?.keepLeadIn } <button className="button--link" onClick={toggleAllTvPackages} data-testid="button-toggle-tv-packages">{ content?.cartRemovalBasic?.keepText }</button>
                            </div>
                        : null }

                        {/* <!-- remove package --> */}
                        { modalType === 'tvPackage' ?
                            <div className="button--container">
                                <button className="button" onClick={toggleProduct} data-testid="button-toggle-product" data-tracking={digitalDataButtonClick('remove-package', content?.cartRemovalPacks?.buttonText)}> { content?.cartRemovalPacks?.buttonText }  { packageDisplayName }</button>
                                <br />
                                <br />
                                <br />
                                { content?.cartRemovalPacks?.keepLeadIn } <button className="button--link" onClick={closeModal} data-tracking={digitalDataButtonClick('keep-package', content?.cartRemovalPacks?.keepText)}>{ content?.cartRemovalPacks?.keepText }</button>
                            </div>
                        : null }
                        {/* <!-- remove add on--> */}
                        { modalType === 'tvBundle' ?
                            <div className="button--container">
                                <button className="button" data-tracking={digitalDataButtonClick('remove-package', packageDisplayName)}> { content?.cartRemovalPremium?.buttonText }  { packageDisplayName }</button>
                                <br />
                                <br />
                                { content?.cartRemovalPacks?.keepLeadIn } <button className="button--link" onClick={closeModal}>{ content?.cartRemovalPremium?.keepText }</button>
                            </div>
                        : null }
                        {/* <!-- remove bundled package and tv basic --> */}
                        { modalType === 'addOn' ?
                            <div className="button--container">
                                <button className="button" onClick={toggleChannel} data-testid="button-toggle-channel" data-tracking={digitalDataButtonClick('remove-package', content?.cartRemovalPacks?.buttonText + ' ' + packageDisplayName)}> { content?.cartRemovalPacks?.buttonText }  { packageDisplayName }</button>
                                <br />
                                <br />
                                <br />
                                <button aria-labelledby="removeAll" className="button--link" onClick={toggleAllTvPackages} data-tracking={digitalDataButtonClick('remove-all-packages', content?.cartRemovalPacks?.removeText)}>{ content?.cartRemovalPacks?.removeText }</button>
                                <br />
                                <strong id="removeAll">{ content?.cartRemovalPacks?.removeDisclaimer }</strong>
                                <br />
                                <br />
                                <br />
                                { content?.cartRemovalPacks?.keepLeadIn } <button className="button--link" onClick={closeModal} data-tracking={digitalDataButtonClick('keep-package', content?.cartRemovalPremium?.keepText)}>{ content?.cartRemovalPremium?.keepText }</button>
                            </div>
                        : null }

                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-testid="modal-close-button" data-tracking={digitalDataButtonClick('close-modal', modalCloseButton, true)}>
                            { modalCloseButton }
                            <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withDashboard(ModalCheckout)