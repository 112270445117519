// import Head from 'next/head'
import { Helmet } from 'react-helmet'
import { digitalDataLinkClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const Error = (props) => {
    const { dashboard } = props;
    
    return (
        <div className="prepaid-wrapper dashboard">
            <TrackUserData data={dashboard} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Activation Error - Xfinity Prepaid</title>
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <main>
                <div className='error-content'>
                    <img alt="" src="/site-assets/img/icon-fail.svg"></img>
                    <h1 className="page__title">
                        Oh no! Something did not work...
                    </h1>
                    <p>
                        It seems like something did not work on our end.<br />
                        Please try again.
                    </p>
                    <a href="https://www.xfinityprepaid.com/" className="link-button" data-tracking={digitalDataLinkClick('back-to-xfinity-prepaid', 'https://www.xfinityprepaid.com/', 'Back to xfinityprepaid.com')}>Back to xfinityprepaid.com</a>
                </div>
            </main>
            <TrackPageComplete />
        </div>
    )
}

export default Error