import DashboardLayout from '../../layouts/DashboardLayout'
import withDashboard from '../../redux/connectors/dashboard'
import ReconnectAccordion from '../../components/reconnect/ReconnectStepsAccordion'
import ProgressSpinner from '../../components/ProgressSpinner'
import { useEffect, useState } from 'react'
import { trackEvent } from '../../utils/utils'
import axios from 'axios';
import { digitalDataAPIError } from '../../utils/adobe-analytics';
import { useHistory } from "react-router-dom";
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const Reconnect = props => {
    const {
        locale,
        dashboardContent,
        dashboard,
        fetchedDashboard,
        trackingId,
        clearCart
    } = props

    const history= useHistory()
    const [processing, setProcessing] = useState(true)
    const [serviceabilityData, setServiceabilityData] = useState<any>(null)

    const renderPageComponents = () => {
        return (
            <div>

            </div>
        )
    }

    useEffect(() => {
        clearCart()
        props.updatePageClass('reconnect')
        trackEvent({
            event: 'reconnect flow',
            reconnectStep: 'step 1',
            reconnectStepName: 'start reconnect'
        })

    }, []) // eslint-disable-line

    useEffect(() => {
        if (fetchedDashboard) {
            const fetchData = async () => {
                const url = `${apiBaseUrl}/secure/api/v1/serviceability/address/validate-customer-serviceability?source=DIGITAL2.0&trackingId=${trackingId}&locationId=${dashboard.customerInfo.locationId}`
                try {
                    const response = await axios.get(url)
                    console.log('serviceability response', response)
                    let data = response.data

                    setServiceabilityData(data)

                    const haveErrorCode = data.businessErrorCode !== null

                    // XXX Not sure about BUS0139 – their docs say this is an email address error
                    // var existingServiceErrorCodes = ['BUS0077', 'BUS0078', 'BUS0139'];
                    // var haveExistingServiceErrorCode = existingServiceErrorCodes.indexOf(data.businessErrorCode) !== -1;
                    const haveExistingPrepaidService = data.businessErrorCode === 'BUS0078'
                    const haveExistingPostpaidService = data.businessErrorCode === 'BUS0077'
                    const coldTapCodes = ['BUS0003', 'BUS011', 'BUS0021', 'BUS0027']
                    const haveColdTapAddress = coldTapCodes.indexOf(data.businessErrorCode) !== -1 && data?.serviceablityStatus?.value === 'YELLOW'
                    const serviceAvailableErrorCodes = ['BUS0003', 'BUS0027', 'BUS0111', 'BUS0138', 'BUS0143, BUS0144']
                    const haveServiceAvailableErrorCode = serviceAvailableErrorCodes.indexOf(data.businessErrorCode) !== -1

                    // NOTE: `serviceablityStatus` is a typo in the API response!
                    if (haveExistingPrepaidService) {
                        console.error('There is an existing prepaid account at this address.')
                        history.push(`/${locale}/error`)
                        return
                    } else if (haveExistingPostpaidService) {
                        console.error('There is an existing postpaid account at this address.')
                        history.push(`/${locale}/error`)
                        return
                    } else if ((haveErrorCode && !haveServiceAvailableErrorCode && !haveColdTapAddress) || !data.serviceabilityDetails || data.serviceabilityDetails.length === 0) {
                        console.error('This address is not serviceable')
                        history.push(`/${locale}/error`)
                        return
                    } else {
                        console.warn('This is a cold-tap address')
                    }
                    setProcessing(false)
                } catch(error: any) {
                    console.error('An error occurred while checking serviceability')
                    digitalDataAPIError('checkServiceability', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.reconnect');
                    history.push(`/${locale}/error`)
                    return
                }
            }
            fetchData()
        }
    }, [fetchedDashboard]) // eslint-disable-line

    return (
        <div>
        <DashboardLayout locale={props.locale} reconnectHeader={true}>
            <TrackUserData data={dashboard} />
            { processing &&
            <ProgressSpinner loadingText={'Checking serviceability...'} />}
            { dashboardContent.loaded && !processing && serviceabilityData &&
            <div className="main__width-constraint reconnect">
                <h1 className="page__title">
                    {props?.reconnectContent?.step1?.reactivateYourAccount}
                </h1>
                <p>{props?.reconnectContent?.step1?.toReactivatePleaseCompleteTheFollowing}</p>
                <ReconnectAccordion serviceabilityData={serviceabilityData}></ReconnectAccordion>
                {renderPageComponents()}
            </div>}
            <TrackPageComplete />
        </DashboardLayout>
        </div>
    )
}

export default withDashboard(Reconnect)
