import withDashboard from '../../redux/connectors/dashboard'
import { useState } from 'react'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const PurchaseAgreementForm = ({
  refillContent,
  billingIsValid,
  handleNext,
  isTestFile,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="card">
      <form id="agree" action="" method="get">
        <div className="agreement-selection">
          <div className="card__header">
            <h2 className="card__header-title">
              {refillContent.refillFlow.orderConfirmation.purchaseAgreement}
            </h2>
          </div>
          <div className="checkbox">
            <input
              id="purchaseAgreement"
              data-testid="01-purchaseAgreement"
              type="checkbox"
              className="checkbox__input"
              name="purchaseAgreement"
              onChange={() => setChecked(!checked)}
            />
            <label className="checkbox__label" htmlFor="purchaseAgreement">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    refillContent.refillFlow.orderConfirmation.byClickingTheBox,
                }}
              ></p>
            </label>
          </div>
        </div>
        <div className="button--container">
            { !isTestFile ? 
              <button className="button" disabled={!checked || !billingIsValid} onClick={(e) => {handleNext(e)}} data-tracking={digitalDataButtonClick('refill-order-confirmation', refillContent.refillFlow.orderConfirmation.makeRefillPayment.text)}>
                {refillContent.refillFlow.orderConfirmation.makeRefillPayment.text}
              </button>
            :
              <button className="button" disabled={false} onClick={(e) => {handleNext(e)}} data-testid="01-button">
                  {refillContent.refillFlow.orderConfirmation.makeRefillPayment.text}
              </button>
            }
        </div>
      </form>
    </div>
  );
};
export default withDashboard(PurchaseAgreementForm);