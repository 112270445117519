import axios from 'axios';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const fetchProductDetails = async ({marketId, trackingId}) => {
    console.log('api.fetchProductDetails')
    const url = `${apiBaseUrl}/secure/api/v1/util-service/product-detail?source=123&trackingId=${trackingId}&marketId=${marketId}`
    const response = await axios.get(url)

    console.log('fetchProductDetails response', response.data)

    return response.data
}
