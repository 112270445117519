import withDashboard from '../../redux/connectors/dashboard'
import {useState, useEffect} from 'react'
import { getProductContent, getProductPrice, formatMoney } from '../../utils/utils'

const CartWinbackPromo = (props) => {
    const {
        refill,
        cart,
        cartContent,
        dashboard
    } = props


    const [internetProduct, updateInternetProduct] = useState<any>({})

    const showingRefillConfirmation = refill && refill.cart && window.location.href.includes('confirmation')
    const activeCart = showingRefillConfirmation ? refill.cart : cart

    useEffect(() => {
        if (activeCart.internet !== null && activeCart.internet !== '') {
            let internetItem: any
            internetItem = getProductContent(activeCart.internet, cartContent.products)
            updateInternetProduct(internetItem)
        }
    }, [cart, cartContent]) // eslint-disable-line


    return (
        <>
            <tr className="line-item">
                <th className="line-item__label">{activeCart.internetRefills + "x "}{ internetProduct?.productData?.plans[0]?.planDisplayText}</th>
                <td className="line-item__price">
                    <span className="price-full"><span>$</span>{ formatMoney(getProductPrice(activeCart.internet, dashboard?.availablePlans) * (activeCart.internetRefills)) }</span>
                </td>
            </tr>
            <tr className="line-item">
                <th className="line-item__label">{internetProduct?.productData?.plans[0]?.planDisplayText}</th>
                <td className="line-item__price">
                    <span className="price-full price-old"><span>$</span>{ formatMoney(getProductPrice(activeCart.internet, dashboard?.availablePlans)) }</span>
                </td>
            </tr>
            <tr className="line-item">
                <td className="line-item__price">
                    <span className="price-full">FREE</span>
                </td>
            </tr>
        </>
    )
}

export default withDashboard(CartWinbackPromo)