import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LitepayLayout from "../../layouts/LitepayLayout";
import withLitepay from "../../redux/connectors/litepay";
import Accordion from "../../components/Accordion";
import Cart from "../../components/litepay/Cart";
import { formatMoney, trackEvent } from "../../utils/utils";
import {
  digitalDataButtonClick,
  digitalDataAccordionClick
} from "../../utils/adobe-analytics";
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const LitepayOrderConfirmation = (props) => {
  const {
    litepayPages,
    checkoutContent,
    cartTotal,
    orderNumber,
    billingPhoneNumber,
    billingZipLookup,
    dictionaryContent,
    currentLocale,
    refilledPrepaidAccount,
    haveValidACP,
    dashboard,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!refilledPrepaidAccount) {
      history.push("/" + currentLocale + "/litepay");
      return;
    }
    trackEvent({
      event: "litepay flow",
      litepayStep: "step 4",
      litepayStepName: "order complete",
    });

    const products: any = [];
    const refillProduct: any = {};
    refillProduct.name = "30 days plan for customer";
    refillProduct.id = "HSD_030";
    refillProduct.price = 45;
    refillProduct.dimension1 = "litepay refill";
    refillProduct.dimension4 = "website";
    refillProduct.quantity = 1;
    products.push(refillProduct);

    trackEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: orderNumber,
            revenue: 45,
          },
          products,
        },
      },
    });
    if (!haveValidACP && dashboard?.acpDetail?.acpStatus !== "Denied") {
      trackEvent({
        event: "litepay info",
        litepayStep: "step 4",
        litepayStepName: "order complete",
        refillEvent: "acp learn tile",
        refillDetail: "impression",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = () => {
    setExpanded(!expanded);
  };

  const savePrint = () => {
    window.print();
  };

  return (
    <LitepayLayout locale={props.locale}>
      <TrackUserData data={dashboard} />
      <TrackPurchaseData offerIncluded={false} price={'45'} productID={'HSD_030'} productType={'prepaid-refill'} quantity={1} transactionID={orderNumber} screenName="confirmation"/>
      <div className="main__width-constraint litepay-confirmation">
        <h1 className="page__title fullbleed-offsets">
          {litepayPages?.confirmation?.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: litepayPages?.confirmation?.body }}
        />
        <div
          className="accordion order-confirm-accordion"
          role="tablist"
          multiselectable="true"
        >
          <div className="accordion__item">
            <div
              id="'01-tab'"
              className={
                expanded
                  ? "accordion__tab order-confirm-accordion__tab expanded"
                  : "accordion__tab order-confirm-accordion__tab"
              }
              role="tab"
              data-testid="toggle-click"
              onClick={() => {
                toggle();
                digitalDataAccordionClick(
                  "order-confirmation",
                  checkoutContent?.orderConfirmation?.orderTotalLabel +
                    " $" +
                    formatMoney(cartTotal),
                  ".accordion-tab"
                );
              }}
            >
              <h2 className="order-confirm-accordion__title">
                {checkoutContent?.orderConfirmation?.orderTotalLabel}
                <b>
                  <sup>$</sup>
                  {formatMoney(cartTotal)}
                </b>
              </h2>

              {expanded ? (
                <button
                  className="button button--link"
                  data-tracking={digitalDataButtonClick(
                    "close-details",
                    checkoutContent?.orderConfirmation?.closeDetailsLinkLabel
                  )}
                >
                  {checkoutContent?.orderConfirmation?.closeDetailsLinkLabel}
                </button>
              ) : (
                <button
                  className="button button--link"
                  data-tracking={digitalDataButtonClick(
                    "view-details",
                    checkoutContent?.orderConfirmation?.viewDetailsLinkLabel
                  )}
                >
                  {checkoutContent?.orderConfirmation?.viewDetailsLinkLabel}
                </button>
              )}

              <div className="accordion__icon">
                <span className="line" />
                <span className="line" />
              </div>
            </div>

            <div
              id="'01-panel'"
              className={`accordion__panel ${
                expanded ? "" : "expand-exit-done"
              }`}
              tabIndex={0}
              role="tabpanel"
            >
              <button
                type="button"
                data-testid="savePrintBtn"
                className="totals__edit link-callout print-save"
                aria-labelledby="edit-xitv"
                onClick={savePrint}
                data-tracking={digitalDataButtonClick(
                  "print-save",
                  litepayPages?.confirmation?.printSave
                )}
              >
                {litepayPages?.confirmation?.printSave}
              </button>
              <Cart showDetails={true} />
              <div className="card cc-form-recap">
                <div className="cc-form-recap-card">
                  <div className="cc-form-recap-header">
                    {litepayPages?.confirmation?.orderNumberLabel}
                  </div>
                  {orderNumber}
                </div>
                <div className="cc-form-recap-telephone">
                  <div className="cc-form-recap-header">
                    {dictionaryContent?.formLabels?.phoneNumber}
                  </div>
                  {billingPhoneNumber}
                </div>
                <div className="cc-form-recap-zip">
                  <div className="cc-form-recap-header">
                    {dictionaryContent?.formLabels?.ziPCode}
                  </div>
                  {billingZipLookup}
                </div>
              </div>
            </div>
          </div>
        </div>
        {litepayPages?.confirmation?.support?.faQs.length && (
          <div className="accordion__faq-group">
            <Accordion
              title={litepayPages?.confirmation?.support?.title}
              items={litepayPages?.confirmation?.support?.faQs}
              eventObj={{
                event: "accordion",
                accordionPage: "litepay flow step 4 order complete",
                accordionType: "faq",
                faqSubcategory: litepayPages?.confirmation?.support?.title,
              }}
              eventDetailCategory="accordionText"
            />
          </div>
        )}
      </div>
      <TrackPageComplete />
    </LitepayLayout>
  );
};

export default withLitepay(LitepayOrderConfirmation);
