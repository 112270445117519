import DashboardLayout from "../../layouts/DashboardLayout";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import Cart from "../../components/refill/Cart";
import StickyCart from "../../components/refill/StickyCart";
import { useEffect, useState } from "react";
import withDashboard from "../../redux/connectors/dashboard";
import { trackEvent } from "../../utils/utils";
import PaymentMethod from "../../components/dashboard/PaymentMethod";
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const RefillPayment = (props) => {
  const {
    cartContent,
    locale,
    paymentInfo,
    fetchedPaymentInfo,
    dashboard,
    updateBilling,
    cartCount,
    addedPaymentInstrument,
    havePromoInCart,
    cart,
    cartTotal
  } = props;
  const cartComponentContent = { cartContent };

  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [cpcProcessing, setCpcProcessing] = useState(false); // eslint-disable-line
  const [loadCPCForm, setLoadCPCForm] = useState(false);
  const [startCPCEvents, setStartCPCEvents] = useState(false);
  const [cpcToken, setCpcToken] = useState();

  const onUpdateField = (e) => {
    console.log(e);
  };

  useEffect(() => {
    props.updatePageClass("dashboard refill");
    console.log("DASHBOARD REFILLL!!! <><><>");
    trackEvent({
      event: "refill flow",
      refillStep: "step 2",
      refillStepName: "payment",
      refillStepDetail: paymentInfo ? "saved debit credit" : "debit credit",
    });
  }, []); // eslint-disable-line

  const history = useHistory();
  useEffect(() => {
    sessionStorage.removeItem("alreadyLoaded");
    sessionStorage.removeItem("savePaymentMethod");
    setStartCPCEvents(true);
  }, []);
  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);

  const updateFormValid = (valid) => {
    updateFormStatus(valid);
    if (valid) {
      setProcessNext(false);
    }
  };

  const goToOrderReview = (e) => {
    console.log("goToOrderReview");
  };
  const setFormValid = (status) => {
    handleFormStatus(status);
  };
  const handleFormStatus = (formStatus) => {
    updateFormStatus(formStatus);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    digitalDataButtonClick('handle-next', "Next", false, undefined, true, '.step1-next');
    updateBilling({ submitAndContinue: true });
      trackEvent({
        event: "refill info",
        refillStep: "step 2",
        refillStepName: "payment",
        refillEvent: "payment method component",
        refillDetail: "add submit",
    });
  };

  useEffect(() => {
    if (paymentInfo || addedPaymentInstrument) {
      history.push("/" + locale + "/refill/review");
    }
    // eslint-disable-next-line
  }, [fetchedPaymentInfo]);

  const onContinue = (e) => {
    trackEvent({
      event: "refill info",
      refillStep: "step 2",
      refillStepName: "payment",
      refillEvent: "next step cta",
      refillDetail: "Next",
    });
    history.push("/" + locale + "/refill/review");
  };

  return (
    <DashboardLayout locale={props.locale} pageTitle={"Refill Payment"}>
      <TrackUserData data={dashboard} />
      <TrackPurchaseData offerIncluded={havePromoInCart} price={cartTotal} productID={cart?.internet} productType={'prepaid-refill'} quantity={cartCount} transactionID={""} screenName="payment"/>
      {props.refillContent &&
        props.refillContent.loaded &&
        fetchedPaymentInfo && (
          <div>
            <ProgressBar title={"Customize your refill"} percent={66} />
            <div className="main__width-constraint edit-payment">
              <br />
              <h1 className="page__title">
                {
                  props.refillContent.refillFlow.orderConfirmation
                    .enterYourPaymentInformation
                }
              </h1>
              <br />
              <div className="main__desktop-body">
                <PaymentMethod
                  onUpdateField={onUpdateField}
                  goToOrderReview={goToOrderReview}
                  setFormValid={setFormValid}
                  nextClicked={processNext}
                  startCPCEvents={startCPCEvents}
                  minimalView={false}
                  refillView={true}
                  hideButton={true}
                  ignoreSaved={true}
                  refillMoveReview={true}
                  refillMovePayment={true}
                  onContinue={onContinue}
                />

                <div className="cart__display-large">
                  <hr />
                  <div className="card card--clear card--small card--right">
                    <input
                      type="button"
                      alt="Next"
                      className="button button--inline step1-next"
                      value={"Next"}
                      onClick={(e) => handleNext(e)}
                      // disabled={!formValid}
                      disabled={cartTotal === 0}
                    />
                  </div>
                </div>
              </div>
              <div className="cart__display-large main__desktop-sidebar-right">
                <Cart content={cartComponentContent} />
              </div>
              <div className="cart__display-small-medium">
                <StickyCart
                  // nextDisabled={!formValid}
                  nextDisabled={cartTotal === 0}
                  handleNext={handleNext}
                  cartComponentContent={cartComponentContent}
                />
              </div>
            </div>
          </div>
        )}
        <TrackPageComplete />
    </DashboardLayout>
  );
};

export default withDashboard(RefillPayment);
