import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import Internet from '../../components/buyflow/Internet'
import { isObject } from '../../utils/utils'
import withBuyflow from '../../redux/connectors/buyflow'
import { trackEvent } from '../../utils/utils'
import { digitalDataAddToCart } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const BuyFlowInternet = (props) => {
    const {
        locale, 
        cartPages,
        prepaidInternetProduct,
        productDetailsLoaded,
        serviceabilityData
    } = props
    const [pageTitle, setPageTitle] = useState('Xfinity Prepaid')

    useEffect(() => {
        if (isObject(cartPages)) setPageTitle(cartPages.step1Internet?.pageTitle)
    }, [cartPages]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (productDetailsLoaded) {
            let products: any
            products = []
            for (let i = 0; i < prepaidInternetProduct?.productData?.plans?.length; i++) {
                let product: any
                product = {}
                product.name = prepaidInternetProduct.productData.plans[i].planDisplayText
                product.id = prepaidInternetProduct.productData.plans[i].plan
                product.sku = prepaidInternetProduct?.productData?.plans[i].sku
                product.price = prepaidInternetProduct.productData.plans[i].rate
                product.dimension1 = 'buy'
                product.dimension4 = 'website'
                product.quantity = 1
                products.push(product)
            }

            trackEvent({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step: 2 },
                        products
                    }
                }
            })
            
            if (products.length > 0) {
                digitalDataAddToCart(
                  "new customer buy flow",
                  "next-step",
                  ".buyflow-prepaid",
                  false,
                  products.length > 0 ? products[0].sku : ""
                );
            }
            
            // Add internet package GTM code on page load
            trackEvent({
                event: 'addToCart',
                ecommerce: {
                currencyCode: 'USD',
                add: {
                    products
                }
                }
            })
        }
    }, [productDetailsLoaded]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <BuyFlowLayout locale={locale}>
            <TrackUserData data={serviceabilityData} />
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Internet cartPages={cartPages} />
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}

export default withBuyflow(BuyFlowInternet)
