import {GlobalModal as Modal } from '../Modal'
import withDashboard from '../../redux/connectors/dashboard'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const CancelMoveModal = (props) => {
    const history = useHistory()

    const {
        currentLocale,
        moveContent,
        scheduledMove,
        updateScheduledMove,
        updatedScheduledMove,
        updateScheduledMoveFailed,
        accountId,
        fetchAuxAccount
    } = props

    const cancelMove = () => {
        updateScheduledMove({
            action: "C", 
            confirmationId: scheduledMove.auxAcctConfNo
        })

    }

    useEffect(() => {
        if (updatedScheduledMove) {
            console.log('Cancelled move...')
            fetchAuxAccount({
                accountId,
                auxConfNo: '',
                checkMoveEligibility: true,
                checkReconnectEligibility: true
            })
            history?.push(`/${currentLocale}/dashboard`)
        }
    }, [updatedScheduledMove]) // eslint-disable-line

    useEffect(() => {
        if (updateScheduledMoveFailed) {
            console.log('Error occurred cancelling scheduled move')
            history?.push(`/${currentLocale}/error`)
        }
    }, [updateScheduledMoveFailed]) // eslint-disable-line

    return (
        <div className="support-modal-wrapper">
            <Modal
                buttonClasses={props.buttonClass}
                body={moveContent?.cancelEdit?.cancelThisMove?.text}
                title={moveContent?.cancelEdit?.cancelThisMove?.title}
                ctaText={props.buttonText}
                isMoveCancel={'true'}
                cancelMove={cancelMove}
                type={props.modalType}
            />
        </div>
    )
}

export default withDashboard(CancelMoveModal)
