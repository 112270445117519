import { useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import CheckAvailability from '../../components/move/MoveCheckAvailability'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const Move = (props) => {
    const {
        dictionaryContent,
        moveContent,
        hsdService,
        clearCart,
        dashboard,
        isTestFile
    } = props

    useEffect(() => {
        clearCart()
        let accountExpired = true
        if (hsdService && hsdService.daysRemaining > 0) {
            accountExpired = false
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 1',
            moveStepName: 'start move',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
        trackEvent({
            event: 'move info',
            moveStep: 'step 1',
            moveStepName: 'start move',
            moveEvent: 'check availability',
            moveDetail: 'impression'
        })

    }, [hsdService]) // eslint-disable-line
    let content: any
    content = moveContent
    content.dictionary = dictionaryContent

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move'}>
            <TrackUserData data={dashboard} />
            <div className="main__width-constraint merchant-prepaid">
                <CheckAvailability isMove={true} content={content} isTestFile={isTestFile}/>
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(Move)