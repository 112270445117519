import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import { isObject } from '../../utils/utils'
import withBuyflow from '../../redux/connectors/buyflow'
import {GlobalModal as Modal } from '../../components/Modal'
import Cart from '../../components/buyflow/Cart'
import Accordion from '../../components/buyflow/Accordion'
import { formatMoney, trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataAccordionClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const BuyFlowOrderConfirmation = (props) => {
    const {
        locale, 
        buyflowContent,
        cartPages,
        updateProgress,
        checkoutContent,
        email,
        digitalSession,
        displayTn,
        cartTotal,
        serviceabilityBusinessErrorCode,
        prepaidInternetProduct,
        orderId,
        havePromoInCart,
        serviceabilityData
    } = props
    const [pageTitle, setPageTitle] = useState('Order Confirmation - Xfinity Prepaid')
    const [expanded, setExpanded] = useState(false)
    const [products, setProducts] = useState<any[]>([]);
    
    useEffect(() => {
        if (isObject(cartPages)) setPageTitle('Order Review - Xfinity Prepaid')
            setProducts([]);
            updateProgress({ title: checkoutContent?.orderConfirmation?.statusLabel, progress: 100 })
            const coldTapCodes = ['BUS0003', 'BUS011', 'BUS0021', 'BUS0027']
            const haveColdTapAddress = coldTapCodes.indexOf(serviceabilityBusinessErrorCode) !== -1
            const serviceAvailableErrorCodes = ['BUS0003', 'BUS0027', 'BUS0111', 'BUS0138', 'BUS0143, BUS0144']
            const haveServiceAvailableErrorCode = serviceAvailableErrorCodes.indexOf(serviceabilityBusinessErrorCode) !== -1
            let tapStatus = 'Never'
            if (haveColdTapAddress) {
                tapStatus = 'Cold'
            } else if (haveServiceAvailableErrorCode) {
                tapStatus = 'Hot'
            }

            trackEvent({
                event:'buyflow info',
                buyStep:'step 5',
                buyStepName:'order complete',
                buyEvent:'tap status',
                buyDetail: tapStatus
            })
            let products: any
            products = []
            for (let i = 0; i < prepaidInternetProduct?.productData?.plans?.length; i++) {
                let product: any
                product = {}
                product.name = prepaidInternetProduct?.productData?.plans[i].planDisplayText
                product.id = prepaidInternetProduct?.productData?.plans[i].plan
                product.sku = prepaidInternetProduct?.productData?.plans[i].sku
                product.price = prepaidInternetProduct?.productData?.plans[i].rate
                product.dimension1 = 'buy'
                product.dimension4 = 'website'
                product.quantity = 1
                products.push(product)
            }

            setProducts(products);
            
            trackEvent({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step: 8 },
                        products
                    }
                }
            })
            trackEvent({
                event: 'purchase',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: orderId,
                            revenue: cartTotal
                        },
                        products
                    }
                }
            })
            if (havePromoInCart) {
                trackEvent({
                    event: 'offer purchased',
                    offer: 'acquisition 10 for first refill',
                    offerLocation: 'buy flow'
                })
            }
        sessionStorage.removeItem("isNewisNewCustomer");
        sessionStorage.removeItem("errorReloadCount");

    }, [cartPages]) // eslint-disable-line react-hooks/exhaustive-deps

    const newConfirmationMessage = () => {
        if (checkoutContent?.orderConfirmation) {
            return checkoutContent?.orderConfirmation?.confirmationPageSuccessMessage?.replace('{email-address}', email)
        } else {
        return checkoutContent?.orderConfirmation?.confirmationPageSuccessMessage
        }
    }
    const xfinityId = () => {
        if (digitalSession?.contact?.verificationStatus?.mobile) {
            return displayTn
        }
        if (digitalSession?.contact?.verificationStatus?.email) {
            return email
        }
        return displayTn
    }

    const toggle = () => {
        setExpanded(!expanded)
        console.log(expanded)
    }
    return (
        <BuyFlowLayout locale={locale}>
            <TrackUserData data={serviceabilityData} />
            <TrackPurchaseData offerIncluded={false} price={cartTotal} productID={products.length > 0 ? products[0].sku : ''} productType={'prepaid-kit'} quantity={1} transactionID={orderId} screenName="confirmation"/>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {checkoutContent.orderConfirmation && (
                <div className="main__width-constraint buyflow">
                    <div className="page__header">
                        <h1 className="page__title">{ checkoutContent?.orderConfirmation?.confirmationPageTitle}</h1>
                        <div className="page__sub-text" dangerouslySetInnerHTML={{__html: newConfirmationMessage()}} />
                        {digitalSession?.identity?.custGuid && 
                            <div className="account-block card confirmation-card">
                                <div className="modal-button-container">
                                    <b className="information-label">{ checkoutContent?.orderReview?.xfinityIdLabel }</b><br />
                                    { xfinityId() }
                                    <Modal
                                        id="about"
                                        buttonClasses="link-callout modal-button"
                                        body={checkoutContent?.orderReview?.aboutYourIdModalText}
                                        title=""
                                        ctaText={checkoutContent?.orderReview?.aboutYourIdLinkLabel}
                                    />
                                </div>
                            </div>
                        }

                        <div className="confirmation-notification">
                            { checkoutContent?.orderConfirmation?.xfinityIdLabel }
                        </div>
                    </div>
                    <div className="accordion order-confirm-accordion" role="tablist" multiselectable="true">
                        <div className="accordion__item">
                            <div
                                id="'01-tab'"
                                className={expanded ? "accordion__tab order-confirm-accordion__tab expanded" : "accordion__tab order-confirm-accordion__tab"}
                                role="tab"
                                onClick={() => {
                                    toggle();
                                    digitalDataAccordionClick('order-confirmation', checkoutContent?.orderConfirmation?.orderTotalLabel + ' $' + formatMoney(cartTotal), '.accordion-tab');
                                }}
                            >
                                <h2 className="order-confirm-accordion__title">
                                    { checkoutContent?.orderConfirmation?.orderTotalLabel } <span className="order-confirm-accordion__total"><span>$</span>{formatMoney(cartTotal)}</span>
                                </h2>

                                {!expanded ? <button className="button button--link" data-tracking={digitalDataButtonClick('view-details', 'checkoutContent?.orderConfirmation?.viewDetailsLinkLabel')}>{ checkoutContent?.orderConfirmation?.viewDetailsLinkLabel }</button>  : <button className="button button--link" data-tracking={digitalDataButtonClick('close-details', checkoutContent?.orderConfirmation?.closeDetailsLinkLabel)}>{ checkoutContent?.orderConfirmation?.closeDetailsLinkLabel }</button> }

                                <div className="accordion__icon">
                                    <span className="line" /><span className="line" />
                                </div>
                            </div>
                            <div
                                id="'01-panel'"
                                className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
                                tabIndex={0}
                                role="tabpanel"
                            >
                                <Cart showDetails={true} />
                            </div>
                        </div>
                    </div>
                    <div className="accordion__faq-group">
                        <Accordion items={checkoutContent?.orderConfirmation?.productSupport?.[0].prepaidInternet.supportItems } title={"What's Next"} eventObj={{event: 'accordion', accordionPage: 'buy flow step 5 order complete', accordionType: "faq", faqSubcategory: "What's Next"}} eventDetailCategory='accordionText' />
                    </div>
                </div>
            )}
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}

export default withBuyflow(BuyFlowOrderConfirmation)
