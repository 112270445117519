import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import ProgressSpinner from '../ProgressSpinner'
import { digitalDataAPIError } from '../../utils/adobe-analytics'

const ValidateAddress = (props) => {
    const {
        setStage,
        content,
        addressData,
        setAddressData,
        searchAddress,
        setAddressList,
        setErrorType,
        selectedLocation,
        isTestFile
    } = props
    const [locationsById, setLocationsById] = useState<Array<any>>([])
    const history = useHistory()

    let validatedLocation
    let marketId

    // create a random tracking ID
    const CA_uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-mixed-operators
            return v.toString(16);
        });
    }
    const trackingId = CA_uuidv4()

    // build the URL for the validate API
    const CA_API_BASE_URL = process.env.REACT_APP_CA_API_BASE_URL
    const validateAddressUrl = dropUnit => {
        console.log(dropUnit)
        let query = 'addressLine1=' + encodeURI(addressData.serviceAddress)
        query += '&addressLine2=' + (dropUnit ? '' : encodeURI(addressData.serviceApt))
        query += '&city=&state='
        query += '&zip=' + encodeURI(addressData.serviceZipCode)
        query += '&trackingId=' + trackingId
        return CA_API_BASE_URL + 'secure/api/v1/serviceability/address/validate-customer-address?' + query
    }

    const validateServiceabilityUrl = () => {
        let url = CA_API_BASE_URL + 'secure/api/v1/serviceability/address/validate-customer-serviceability?trackingId=' + trackingId
        url += '&locationId=' + validatedLocation.locationId
        url += '&source=CP_VAN'
        return url
    }

    const sortList = list => {
        list.sort((a, b) => {
            if (a && b && a.address && b.address && a.address.addressLine2 && b.address.addressLine2) {
                if (a.address.addressLine2.toString().toUpperCase() < b.address.addressLine2.toString().toUpperCase()) return -1
                if (a.address.addressLine2.toString().toUpperCase() < b.address.addressLine2.toString().toUpperCase()) return 1
            }
            return 0
        })
        return list
    }

    const confirmLocation = loc => {
        let location = typeof loc === 'string' ? locationsById[loc] : loc
        let addr = location.address
        setAddressData({
            address: addr?.addressLine1,
            unit: addr?.addressLine2,
            city: addr?.city,
            state: addr?.state,
            zip: addr?.zip,
            locationId: location?.locationId,
            trackingId
        })
        validatedLocation = location
        validateServiceability()
    }

    const contentError = () => {
        setErrorType('content error')
        setStage('addressCheck')
    }

    const connectionError = err => {
        setErrorType('connection error')
        setStage('addressCheck')
    }

    const addressError = businessErrorCode => {
        setErrorType('invalid address')
        setStage('addressCheck')
    }

    // cold tap
    const [coldTapFlag, setColdTapFlag] = useState(false)
    useEffect(() => { setAddressData({...addressData,  coldTapFlag }) }, [coldTapFlag]) // eslint-disable-line react-hooks/exhaustive-deps

    // test address for multiunit: 753 N LABURNUM AVE, 23223
    // test address for long list of multi unit (lowercase, don't select from dropdown): 1701 jfk blvd, 19103
    const multiUnit = (list, mode) => {
        !isTestFile && setAddressList(list.slice())
        let thisLocationsById = {}
        let unitMatch = false
        let index = 0 
        !isTestFile && list.forEach(location => {
            if (mode === 'primary' && searchAddress.unit !== '' && searchAddress.unit.toLowerCase() === location.address.addressLine2.toLowerCase()) {
                unitMatch = true
                confirmLocation(location)
            }
            thisLocationsById[location.locationId] = location
            index++
            if (index === list.length) {
                setMultiUnit(thisLocationsById, mode, unitMatch)
            }
        })
        isTestFile && setMultiUnit(thisLocationsById, 'secondary', unitMatch)
        isTestFile && confirmLocation(list)
    }
    const setMultiUnit = (thisLocationsById, mode, unitMatch) => {
        setLocationsById(thisLocationsById)
        if (mode === 'primary' && !unitMatch) setStage('addressMultiUnit')
        else if (mode === 'secondary') setStage('addressMultiUnit')
    }

    // run the validate API
    const checkAddress = () => {
        axios.get(validateAddressUrl(false), { timeout: 60000 }).then(response => {
            let list =  !isTestFile ? sortList(response.data.customerInfos) : response.data.customerInfos    
                // no list, show error
            if (!list) contentError()
            // if no results try again if we were using a unit in address
            else if (list.length === 0 && addressData.serviceApt) {
                axios.get(validateAddressUrl(true), { timeout: 60000 } ).then(newresponse => {
                    let newlist = sortList(newresponse.data.customerInfos)
                    if (!newlist) contentError()
                    else if (newlist.length === 0) addressError(newresponse.data.businessErrorCode)
                    else if (newlist.length === 1) confirmLocation(newlist[0])
                    else multiUnit(newlist, 'secondary')
                }).catch(error => {
                    connectionError(error)
                    digitalDataAPIError('validateAddress', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.merchant-prepaid__check');
                })
            }
            // no results
            else if (list.length === 0) addressError(response.data.businessErrorCode)
            // one result, perfect
            else if (list.length === 1) confirmLocation(list[0])
            // multiple results
            else multiUnit(list, 'primary')
        }).catch(error => {
            connectionError(error)
            digitalDataAPIError('validateAddress', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.merchant-prepaid__check');
        })
    }

    // now check serviceability
    const validateServiceability = () => {
        sessionStorage.removeItem('xppdata')

        // POSSIBLE ERROR CODES:

        // BUS0001--Offer is not available in market
        // BUS0002--Tap status = never
        // BUS0003--Tap status = cold
        // BUS0004--Active account exists at address (#6)

        // BUS0105--Fraud Indicator is true ,need proofs to be shown
        // BUS0106--fiber condition || both HSD and VIDEO not in capability or
        // // Exceptions blocks||non serviceable
        // BUS0107--VIDEO not present in either Capability or Exceptions block
        // BUS0108--HSD not present in either Capability or Exceptions block

        // BUS0013--location is commercial
        // // these will supercede some of the above
        // BUS0018--account not serviceable and cold tap (#2)
        // BUS0019--account not serviceable and hot tap (#3)
        // BUS0020--account not serviceable and no tap (#4)
        // BUS0021--account is serviceable and no tap (#5)
        // // correspond to the # versions above but for activate path
        // BUS0022--account not serviceable and cold tap (#2) (activate)
        // BUS0023--account not serviceable and hot tap (#3) (activate)
        // BUS0024--account not serviceable and no tap (#4) (activate)
        // BUS0025--account is serviceable and no tap (#5) (activate)
        // BUS0026--active account exists at address (#6) (activate)
        // BUS0027--acct is serviceable and cold tap (serviceability)
        // BUS0028--acct is serviceable and cold tap (activation)


        // BUS0077--post-paid active account exists at address(activate)
        // BUS0078--prepaid-paid active account exists at address(activate)

        // BUS0094--Address is no more in Comcast footprint.
        // BUS0137--LocationId is mandatory and it should be valid number
        // BUS0138--location is fraud and cold tap.
        // BUS0139--Email address format is wrong.

        // SYS0001-- Failure interacting with Location Service
        // SYS0002--System error occured while processing address.

        // SYS0006-- System error occured while processing serviceability.
        // SYS0013-- modify location call failed.

        axios.get(validateServiceabilityUrl()).then(response => {
            const data = response.data
            marketId = data.ftaCode
            let sessionData = {
                address: searchAddress.address || '',
                unit: searchAddress.unit || '',
                city: addressData.city || data.address?.city || '',
                state: addressData.state || data.address?.state || '',
                zip: searchAddress.zip || '',
                locationId: validatedLocation.locationId,
                marketId: marketId,
                houseKey: data.houseKey,
                existingPrepaid: false,
                existingPostpaid: false,
                isColdTap: false
            }

            if (data.status !== 'SUCCESS') {
                history?.push(`/en/error`)
            }

            // XXX This is just a guess at the logic.
            // We have a 'status' field which contains "SUCCESS" and a serviceabilityStatus.value which is "GREEN"
            // but we don't know yet what the values would be when service is unavailable.
            // XXX We're assuming that the presence of any error code means service is not available
            const haveErrorCode = data.businessErrorCode !== null

            // XXX Not sure about BUS0139 – their docs say this is an email address error
            // var existingServiceErrorCodes = ['BUS0077', 'BUS0078', 'BUS0139'];
            // var haveExistingServiceErrorCode = existingServiceErrorCodes.indexOf(data.businessErrorCode) !== -1;
            const haveExistingPrepaidService = data.businessErrorCode === 'BUS0078'
            const haveExistingPostpaidService = data.businessErrorCode === 'BUS0077'
            const coldTapCodes = ['BUS0003', 'BUS011', 'BUS0021', 'BUS0027']
            const haveColdTapAddress = coldTapCodes.indexOf(data.businessErrorCode) !== -1 && data?.serviceablityStatus?.value === 'YELLOW'
            const serviceAvailableErrorCodes = ['BUS0003', 'BUS0027', 'BUS0111', 'BUS0138', 'BUS0143, BUS0144']
            const haveServiceAvailableErrorCode = serviceAvailableErrorCodes.indexOf(data.businessErrorCode) !== -1

            // NOTE: `serviceablityStatus` is a typo in the API response!

            if (haveExistingPrepaidService) {
                sessionData.existingPrepaid = true
                sessionStorage.setItem('xppdata', JSON.stringify(sessionData))
                setStage('existingPrepaidAccount')
            }
            else if (haveExistingPostpaidService) {
                sessionData.existingPostpaid = true
                sessionStorage.setItem('xppdata', JSON.stringify(sessionData))
                setStage('existingPostpaidAccount')
            }
            else if ((haveErrorCode && !haveServiceAvailableErrorCode && !haveColdTapAddress) || !data.serviceabilityDetails || data.serviceabilityDetails.length === 0) {
                setStage('unserviceable')
            }
            else {
                if (haveColdTapAddress) {
                    setColdTapFlag(true)
                    sessionData.isColdTap = true
                } 
                sessionStorage.setItem('xppdata', JSON.stringify(sessionData))
                setErrorType(false)
                setStage('serviceable')
            }
        }).catch(error => {
            digitalDataAPIError('validateServiceability', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.merchant-prepaid__check');
        })
    }
    const isEmpty = (obj) => {
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    // this is what fires on component load to kick everything off
    useEffect(() => {
        if (!isEmpty(selectedLocation)) confirmLocation(selectedLocation)
        else checkAddress()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="merchant-prepaid__check">
            <ProgressSpinner loadingText={content?.servicibilityCheck.processingLoadingScreen.checkingAvailability} />
            <p className="merchant-prepaid__check--content">{content?.servicibilityCheck.processingLoadingScreen.loadingScreenDescription}</p>

        </div>
    )
}

export default ValidateAddress