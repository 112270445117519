import TrackingData from './TrackingData';

const TrackPurchaseData = (data) => {
  if (!data.transactionID && data.screenName==="confirmation") {
    return null;
  }
  let offerIncluded = data.offerIncluded;
  let price = data.price;
  let productID = data.productID;
  let productType = data.productType;
  let quantity = data.quantity;
  let transactionID = data.transactionID;
  let screenName = data.screenName
  return <TrackingData trackingInfo={ screenName === "confirmation"?
    {
      page:{
        attributes:{
          pageLoadEvent:"Purchase"
        }
      },
    product: [
      {
        productInfo: {
          offerIncluded,
          price: typeof price === 'string' && price.includes('$') ? Number(price.replace('$', '')) : Number(price),
          productID,
          productType,
          quantity
        },
      },
    ],
    transaction: {
        transactionID
    },
  } :  {
    product: [
      {
        productInfo: {
          offerIncluded,
          price: typeof price === 'string' && price.includes('$') ? Number(price.replace('$', '')) : Number(price),
          productID,
          productType,
          quantity
        },
      },
    ],
  }
}></TrackingData>
};

export default TrackPurchaseData;
