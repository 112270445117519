import { createSelector } from 'reselect'

const getLocale = state => state.common.currentLocale
const getContent = state => state.content

const selectors = {

    getDictionaryContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishDictionaryContent && content?.fetchedSpanishDictionaryContent) {
                return locale === 'es' ? {...content.spanishDictionaryContent, loaded: true} : {...content.englishDictionaryContent, loaded: true}
            }
            return {loaded: false}
        }
    ),

    getActivationContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishActivationContent && content?.fetchedSpanishActivationContent) {
                return locale === 'es' ? {...content.spanishActivationContent, loaded: true} : {...content.englishActivationContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getDashboardContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishDashboardContent && content?.fetchedSpanishDashboardContent) {
                return locale === 'es' ? {...content.spanishDashboardContent, loaded: true} : {...content.englishDashboardContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getRefillContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishRefillContent && content?.fetchedSpanishRefillContent) {
                return locale === 'es' ? {...content.spanishRefillContent, loaded: true} : {...content.englishRefillContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getMerchantContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishMerchantContent && content?.fetchedSpanishMerchantContent) {
                return locale === 'es' ? {...content.spanishMerchantContent, loaded: true} : {...content.englishMerchantContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getMoveContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishMoveContent && content?.fetchedSpanishMoveContent) {
                return locale === 'es' ? {...content.spanishMoveContent, loaded: true} : {...content.englishMoveContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getReconnectContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishReconnectContent && content?.fetchedSpanishReconnectContent) {
                return locale === 'es' ? {...content.spanishReconnectContent, loaded: true} : {...content.englishReconnectContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getCartContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishCartContent && content?.fetchedSpanishCartContent) {
                return locale === 'es' ? {...content.spanishCartContent, loaded: true} : {...content.englishCartContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
    getBuyflowContent: createSelector(
        [getLocale, getContent],
        (locale, content) => {
            if (content?.fetchedEnglishBuyflowContent && content?.fetchedSpanishBuyflowContent) {
                return locale === 'es' ? {...content.spanishBuyflowContent, loaded: true} : {...content.englishBuyflowContent, loaded: true}
            }
            return {loaded: false}
        }
    ),
}

export default selectors
