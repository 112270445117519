import { useEffect, useState } from 'react'
import withActivation from '../../redux/connectors/activation'
//
const ActivationAccountName = ({item, completeAccountNameStep, updateFirstName, updateLastName}) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [nextEnabled, setNextEnabled] = useState(false)

    useEffect(() => {
        const enabled = firstName.length > 0 && lastName.length > 0
        setNextEnabled(enabled)
    }, [firstName, lastName])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (firstName.length > 0 && lastName.length > 0) {
            updateFirstName(firstName)
            updateLastName(lastName)
            completeAccountNameStep()
        }
    }

    return (
        <div className="card">
            <form  id="mac-search" data-testid="mac-search-01" onSubmit={handleSubmit}>
                <p style={{marginTop: 0}}>{item.content?.beforeWeActivate}</p>
                <div>
                    <label htmlFor="firstName">{item.content?.firstName}</label>
                    <input
                        id="firstName"
                        placeholder=""
                        type="text"
                        onChange={e => setFirstName(e.target.value)}
                        data-testid="firstName-01"
                    />
                </div>
                <div>
                    <label htmlFor="lastName">{item.content?.lastName}</label>
                    <input
                        id="lastName"
                        placeholder=""
                        type="text"
                        onChange={e => setLastName(e.target.value)}
                        data-testid="lastName-01"
                    />
                </div>

                <div>
                    <input type="submit" value={item.content?.nextStep || 'Next Step'} disabled={!nextEnabled} className="button form-submit-button"/>
                </div>
            </form>
        </div>
    )
}

export default withActivation(ActivationAccountName)
