import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Accordion from '../../components/Accordion'
// import SupportModal from '../../components/support/SupportModal'
import ProgressSpinner from '../../components/ProgressSpinner'
// import SupportVideoModal from '../../components/support/SupportVideoModal'
import withDashboard from '../../redux/connectors/dashboard'
import { useEffect, useState } from 'react'
import { trackEvent } from '../../utils/utils'
import axios from 'axios'
import { digitalDataAPIError, digitalDataLinkClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

let redirectUri = String(process.env.REACT_APP_CIMA_REDIRECT_URI)
if (window.location.hostname === 'localhost') {
    redirectUri = "http://localhost:3000/"
}
const clientId=String(process.env.REACT_APP_LOGOUT_CLIENT_ID);
const loginUrl = `https://oauth.xfinity.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid urn:cpc:scope:method-of-payment-service:all`
const newAccountUrl = `https://idm.xfinity.com/myaccount/create-uid?execution=e1s1&continue=${encodeURIComponent(redirectUri)}`

const urlParams = new URLSearchParams(window.location.search);
let code = urlParams.get('code')
const continueUrl = urlParams.get('continue')
let state = urlParams.get('state')
let sloFs = urlParams.get('slo_fs')
const skipRedirect = urlParams.get('s')
let bhr = urlParams.get('bhr')
let mobile = urlParams.get('mobile')
const sd = sessionStorage.getItem('xpp_acct')
let sessionData = sd ? JSON.parse(sd) : {}


const HandoffPage = (props) => {
    const {
        pageClass,
        updatePageClass,
        locale,
        updateCurrentLocale,
        activationContent,
        updateCustGuid,
        updateAccountId,
        updateSessionId,
        updateBehindTheModemStatus,
        updateInAppStatus,
        updateField,
        fetchDashboard,
        fetchedToken,
        fetchToken,
        generateTrackingId,
        trackingId,
        fetchIdentityInfoSuccess,
        fetchIdentityInfoFailure,
        fetchEnglishActivationContent,
        fetchingEnglishActivationContent,
        fetchEnglishActivationContentFailed,
        fetchedEnglishActivationContent,

        fetchSpanishActivationContent,
        fetchingSpanishActivationContent,
        fetchSpanishActivationContentFailed,
        fetchedSpanishActivationContent,
        dashboard,
        isTestFile
    } = props;

    const [ready, setReady] = useState(false)
    const [checksCompleted, setChecksCompleted] = useState(false)
    const [verified, setVerified] = useState(false)
    const [verifying, setVerifying] = useState(false)

    const history = useHistory();

    useEffect(() => {
        updatePageClass('handoff')
        updateCurrentLocale(locale)
        if(isTestFile){
            sessionData={
                bhr:"test bhr",
                mobile:"test mobile"
            };

        }
        if (continueUrl) {
            // First step of logout
            // TODO: Need to destroy session on server
            if (sessionData.sessionId) {
                axios.post(`${apiBaseUrl}/secure2/logout`, {}, {
                    headers: {
                        'x-session-id': sessionData.sessionId
                    }
                }).then(response => {
                    console.log(response.data)
                }).catch(error => {
                    digitalDataAPIError('logout', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.handoff__intro');
                    console.log('logout error', error)
                }).finally(() => {
                    sessionStorage.removeItem('xpp_acct')
                    window.location.href = continueUrl
                })
            } else {
                sessionStorage.removeItem('xpp_acct')
                window.location.href = continueUrl
            }
        } else if (sloFs) {
            // Second step of logout
            if (state && state === 'expired') {
                history.replace(`/${locale}/timeout-error`)
            } else {
                window.location.href = loginUrl
            }
        } else {
            // const hasSeenWarmTransferScreen = sessionStorage.getItem('xpp_wt')
            const hasSeenWarmTransferScreen = localStorage.getItem('xpp_wt')

            if (sessionData.bhr || sessionData.mobile) {
                console.log('bhr', bhr, 'mobile', mobile)
                updateBehindTheModemStatus(sessionData.bhr)
                updateInAppStatus(sessionData.mobile)
            } else {
                if (bhr) {
                    sessionData.bhr = bhr === 'true'
                    updateBehindTheModemStatus(sessionData.bhr)
                }

                if (mobile) {
                    sessionData.mobile = mobile === 'true'
                    updateInAppStatus(sessionData.mobile)
                }

                if (Object.keys(sessionData).length) {
                    console.log('updating sessionData', sessionData, Object.keys(sessionData), Object.keys(sessionData).length)
                    sessionStorage.setItem('xpp_acct', JSON.stringify(sessionData))
                }
            }

            if (!code && !skipRedirect && hasSeenWarmTransferScreen) {
                window.location.href = loginUrl
            } else {
                setChecksCompleted(true)
            }

        }
    }, [updatePageClass, locale]) // eslint-disable-line

    useEffect(() => {
        if (checksCompleted && !fetchedToken) {
            generateTrackingId()
            fetchToken()
        }
    }, [checksCompleted]) // eslint-disable-line

    useEffect(() => {
        if (fetchedToken) {
            if (!fetchedEnglishActivationContent && !fetchingEnglishActivationContent) {
                fetchEnglishActivationContent()
            }
            if (!fetchedSpanishActivationContent && !fetchingSpanishActivationContent) {
                fetchSpanishActivationContent()
            }
        }
    }, [fetchedToken]) //eslint-disable-line


    useEffect(() => {
        if (fetchEnglishActivationContentFailed || fetchSpanishActivationContentFailed) {
            // XXX TODO Redirect to error page
        }
    }, [fetchEnglishActivationContentFailed, fetchSpanishActivationContentFailed])


    useEffect(() => {
        if (fetchedToken && !verified && !verifying) {

            if(isTestFile){
                code='testCode';
            }
            setVerifying(true)

            if (code) {
                let data = {code, redirectUri, trackingId }

                axios.post(`${apiBaseUrl}/secure2/decoder`, data).then(response => {
                    console.log(response.data)
                    const data = response.data

                    if (data.custGuid) {
                        setVerified(true)
                        setVerifying(false)
                        updateCustGuid(data.custGuid)
                        updateSessionId(data.sessionId)
                        console.log('custGuid', data.custGuid)

                        let foundAccount:any = null

                        if (data.identityResponse) {
                            if (data.identityResponse.errorCode) {
                                fetchIdentityInfoFailure({
                                    errorCode: data.identityResponse.errorCode,
                                    errorMessage: data.identityResponse.errorMessage,
                                })
                            } else {
                                fetchIdentityInfoSuccess({
                                    identity: data.identityResponse.response.identity,
                                    identityAccounts: data.identityResponse.response.identityAccounts,
                                    pronoun: data.identityResponse.response.pronoun,
                                    chosenName: data.identityResponse.response.chosenName
                                })

                                let validAccounts:Array<any> = []
                                const accounts = data.identityResponse.response.identityAccounts
                                accounts.forEach(account => {
                                    if (account.presentInPrepaidDB) {
                                        validAccounts.push(account)
                                    }
                                })

                                if (validAccounts.length === 1) {
                                    foundAccount = validAccounts[0]
                                    updateAccountId(foundAccount.accountNumber)
                                    updateField({field: 'ready', value: false})
                                    fetchDashboard({accountId: foundAccount.accountNumber})
                                }
                            }
                        } else {
                            console.error('Missing identityResponse')
                            setVerified(false)
                            setVerifying(false)
                            history.replace(`/${locale}/error`)
                            return
                        }

                        localStorage.setItem('xpp_wt', '1')
                        // sessionStorage.setItem('xpp_wt', '1')

                        if (foundAccount) {
                            sessionStorage.setItem('xpp_acct', JSON.stringify({
                                ...sessionData,
                                accountId: foundAccount.accountNumber,
                                custGuid: data.custGuid,
                                sessionId: data.sessionId,
                            }))
                            let dashboardUrl = `/${locale}/dashboard`
                            const params:string[] = []
                            if (sessionData.bhr) {
                                params.push('bhr=true')
                            }
                            if (sessionData.mobile) {
                                params.push('mobile=true')
                            }
                            if (params.length) {
                                dashboardUrl += `?${params.join('&')}`
                            }
                            history.push(dashboardUrl)
                        } else {
                            sessionStorage.setItem('xpp_acct', JSON.stringify({
                                ...sessionData,
                                custGuid: data.custGuid,
                                sessionId: data.sessionId,
                            }))
                            history.push(`/${locale}/account-picker`)
                        }
                    } else {
                        setVerified(false)
                        setVerifying(false)
                        console.error('Unxpected response, no custGuid in response.')
                        history.replace(`/${locale}/error`)
                        return
                    }
                }).catch(error => {
                    console.log('error', error)
                    console.log(error.response)
                    console.error('An exception occured while verifying CIMA code')
                    digitalDataAPIError('decoder', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.handoff__intro');
                    history.replace(`/${locale}/error`)
                })
            } else {
                setReady(true)
            }
        }
    }, [fetchedToken]) // eslint-disable-line

    const trackClick = (type) => {
        trackEvent({
            event: 'login',
            loginPage: 'warm transfer',
            loginEvent: 'main cta',
            loginDetail: type
        })
    }

    const renderHandoffContent = (content) => {
        return (
            <div id="main" role="main" className="main">
                <div className="main__width-constraint">
                    <div className="handoff__intro">
                        <h1 className="page__title">{content.transfer.warmTransferPage.title}</h1>
                        <p dangerouslySetInnerHTML={{__html: content.transfer.warmTransferPage.description}} />
                    </div>
                    <div className="handoff__links">
                        <a href={newAccountUrl} className="create-id button" onClick={() => {trackClick('create id')}} data-tracking={digitalDataLinkClick('create-id', newAccountUrl, content.transfer.warmTransferPage.primaryCtAButton.text)}>
                            {content.transfer.warmTransferPage.primaryCtAButton.text}
                        </a>
                        {/* was: content.transfer.warmTransferPage.primaryCtAButton.link */}

                        <a href={loginUrl} className="log-in" onClick={() => {trackClick('login')}} data-tracking={digitalDataLinkClick('login', loginUrl, content.transfer.warmTransferPage.secondaryCtA.text)}>
                                {content.transfer.warmTransferPage.secondaryCtA.text}
                        </a>
                        {/* was: content.transfer.warmTransferPage.secondaryCtA.link */}
                    </div>
                    <Accordion items={content?.transfer?.warmTransferPage?.support} eventObj={{event: 'accordion', accordionPage: 'warm transfer', accordionType: "faq", faqSubcategory: 'warm transfer'}} eventDetailCategory='accordionText' />
                </div>
            </div>
        )
    }

    if (!checksCompleted || !ready || !activationContent.loaded) {
        return (
            <div id="main" className="main" role="main">
                <div className="main__width-constraint">
                    <ProgressSpinner loadingText="Loading..." />
                </div>
            </div>
        )
    }

    return (
        <div className={`prepaid-wrapper ${pageClass}`}>
            <TrackUserData data={dashboard} />
            <main>

                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="robots" content="noindex,nofollow" />
                    <title>Log In To Your Xfinity Prepaid Account | Xfinity Prepaid</title>
                    <link rel="icon" href="/favicon.ico" />
                </Helmet>

                {ready && !code && activationContent.loaded &&
                <div className="handoff">
                    <div className="handoff__header">
                        {process.env.REACT_APP_SHOPBASE_URL && (
                            <a href={process.env.REACT_APP_SHOPBASE_URL} className="xfinity-logo" data-tracking={digitalDataLinkClick('xfinity-logo', process.env.REACT_APP_SHOPBASE_URL, 'xfinity')}>
                                <span className="vh">xfinity</span>
                            </a>
                        )}

                        {/* <nav aria-label="Utility Navigation">
                            <a href={process.env.REACT_APP_SHOPBASE_URL}>Back</a>
                        </nav> */}
                    </div>

                    {renderHandoffContent(activationContent)}
                </div>}
            </main>
            <TrackPageComplete />
        </div>
    )
}
const Handoff = withDashboard(HandoffPage)
export { Handoff }