import { useState, useEffect } from "react";
import LitepayLayout from "../../layouts/LitepayLayout";
import withLitepay from "../../redux/connectors/litepay";
import { GlobalModal as Modal } from "../../components/Modal";
import RefillAccordions from "../../components/litepay/RefillAccordions";
import StickyCart from "../../components/litepay/StickyCart";
import { trackEvent } from "../../utils/utils";
import TrackPageComplete from "../../utils/TrackPageComplete";
import TrackUserData from "../../utils/TrackUserData";
import BroadbandFacts from "../../components/refill/BroadbandFacts";
import SpanishBroadbandFacts from "../../components/Spanish_BroadbandFacts";

const Refill = (props) => {
  const { litepayPages, showStickyCart, dashboard, locale } = props;

  const [nextClicked, updateNexClicked] = useState(false);
  const [formValid, updateFromStatus] = useState(false);

  useEffect(() => {
    trackEvent({
      event: "litepay flow",
      litepayStep: "step 1",
      litepayStepName: "find your account",
    });
  }, []);

  const handleNext = () => {
    updateNexClicked(true);
    return false;
  };
  const handleFormStatus = (formStatus) => {
    updateFromStatus(formStatus);
  };

  return (
    <LitepayLayout locale={props.locale}>
      <TrackUserData data={dashboard} />
      <div data-testid="refillTab" className="main__width-constraint">
        <h1 className="page__title fullbleed-offsets">
          {litepayPages?.litepay?.title}
        </h1>
        <p>{litepayPages?.litepay?.description}</p>
        <Modal
          ctaText={litepayPages?.litepay?.whatIsLitepayModal?.primaryButtonText}
          buttonClasses="button--link--large"
          title={litepayPages?.litepay?.whatIsLitepayModal?.title}
          body={litepayPages?.litepay?.whatIsLitepayModal?.text}
          type=""
        />
        <br />
        <div className="card">
          <h4>{litepayPages?.litepay?.refillOtherServices?.title}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: litepayPages?.litepay?.refillOtherServices?.text,
            }}
          />
        </div>
        <div className="flex-container">
          <RefillAccordions
            handleNext={handleNext}
            nextClicked={nextClicked}
            handleFormStatus={handleFormStatus}
          />
          {process.env.REACT_APP_FCC_DISPLAYED === "true" &&
            (locale === "es" ? (
              <SpanishBroadbandFacts tierId="HSD_030"></SpanishBroadbandFacts>
            ) : (
              <BroadbandFacts
                tierId="HSD_030"
                servicePlanType="PREPAID"
                lineOfBusiness="INTERNET"
                sourceServerId={window.location.hostname}
                sourceSystemId="PREPAID"
              ></BroadbandFacts>
            ))}
        </div>
        {showStickyCart && (
          <StickyCart handleNext={handleNext} nextDisabled={!formValid} />
        )}
      </div>
      <TrackPageComplete />
    </LitepayLayout>
  );
};

export default withLitepay(Refill);
