import { useEffect } from 'react'
import withLitepay from '../redux/connectors/litepay'
import { Helmet } from 'react-helmet'
import Header from '../components/litepay/Header'
import Footer from '../components/litepay/Footer'


const LitepayLayout = props => {
    const {fetchedContent} = props

    const {
        fetchEnglishLitepayContent,
        fetchSpanishLitepayContent,
        fetchEnglishCartContent,
        fetchSpanishCartContent,
        fetchEnglishDictionaryContent,
        fetchSpanishDictionaryContent,
        fetchEnglishBuyflowContent,
        fetchSpanishBuyflowContent,
        fetchToken,
        fetchedToken,
        generateTrackingId,
        locale, // passed down from router, different from currentLocale which is in redux
        currentLocale,
        updateCurrentLocale,
        updateCheckoutContent,
        buyflowContent
    } = props

    useEffect(() => {
        if (locale !== currentLocale) {
            // console.log('updating current locale', currentLocale, '->', locale)
            updateCurrentLocale(locale)
        }
    }, [locale]) // eslint-disable-line

    useEffect(() => {
        generateTrackingId()
        fetchToken()
    }, []) // eslint-disable-line

    useEffect(() => {
        if (fetchedToken) {
            if (!fetchedContent) {
                fetchEnglishLitepayContent()
                fetchSpanishLitepayContent()
                fetchEnglishCartContent()
                fetchSpanishCartContent()
                fetchEnglishDictionaryContent()
                fetchSpanishDictionaryContent()
                fetchEnglishBuyflowContent()
                fetchSpanishBuyflowContent()
            }
        }
    }, [fetchedToken]) // eslint-disable-line

    useEffect(() => {
        if (buyflowContent.loaded) {
            updateCheckoutContent(buyflowContent)
        }
    }, [buyflowContent]) // eslint-disable-line



    // Always show error page even if content isn't loaded
    const errorPage = window.location.pathname.includes('/error')

    return !fetchedContent && !errorPage ? <div /> :
        <div className={`litepay-page ${props.wrapperClass ? props.wrapperClass : ''}`}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Litepay - Xfinity Prepaid</title>
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Header />
            <main>
                <div id="main" role="main" className="main">
                    <div className="main__width-constraint">
                        {props.children}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
}

export default withLitepay(LitepayLayout);
