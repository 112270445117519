import { createSlice, current } from "@reduxjs/toolkit";
import { errorForCode } from "../../lib/errors";

interface BuyFlowState {
  digitalSessionId: string | null;
  digitalSession: any | null;
  shouldRestoreSession: boolean;

  shopBaseUrl: string;
  redirectToShop: boolean;
  locales: ["en", "es"];
  locale: "en" | "es";
  unrecoverableActivationErrors: ["PAYMENT-0001", "SYS_CUSTACT_01"];
  successActivationErrors: ["BUS_CUSTACT_05", "SYS0010"];

  mediaBaseUrl: string;
  progress: number;
  progressTitle: string;

  showNotification: boolean;
  notificationMessage: string;
  errorCode:string;

  showErrorPage: boolean;
  error: string;

  paymentProcessing: boolean;
  orderPlaced: boolean;
  orderFailed: boolean;
  orderId: string;
  accountId: string;

  eligibilityConfirmed: boolean;
  productDetailsLoaded: boolean;

  haveComcastApiError: boolean;
  comcastApiErrors: string[];

  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  locationId: string;
  marketId: string;

  billingName: string; // XXX API actually breaks these out and first and last name...
  billingFirstName: string;
  billingLastName: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry: string; // 'US',
  billingCardNumber: string;
  billingCardDisplayNumber: string;
  billingCardNumberLength: 0;
  billingCardNumberLastFour: string;
  billingCardType: string;
  billingCardNiceType: string;
  billingExpirationMonth: string;
  billingExpirationYear: string;
  billingCvv: string;
  billingAddress: boolean;

  shippingSameAsBilling: boolean;
  shippingFirstName: string; // XXX Shipping first and last name are not in the new design
  shippingLastName: string;
  shippingAddressLine1: string;
  shippingAddressLine2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;

  shippingCode: string; // 'STD',
  shippingRate: number; // '9.95',
  freeShippingCartText: string;
  freeShippingPromoText: string;
  freeShippingChargeStatement: string;
  showFreeShipping: boolean;
  shippingDescription: string; // 'Standard',
  shippingTaxDetails: object[];
  shippingTaxAmount: number; // tax amount related to shipping, to deduct when shipping is free

  trackingId: string;
  activationSource: "COESALES";

  emailOptIn: boolean; // XXX This is an old field but no longer in the form, always default to true?
  smsOptIn: boolean; // XXX This is a new field, not sure how to send it!

  geoCode: string;
  email: string;
  confirmEmail: string;
  tn: string;
  displayTn: string;
  langPref: "ENGLISH" | "SPANISH";
  houseKey: string; // '08096271505026',
  serviceabilityBusinessErrorCode: string | null;

  bundleDetails: object[];

  deviceDetails: object[];

  // From Sitecore
  cartContentLoaded: boolean;
  cartContent: any;
  dictionaryContentLoaded: boolean;
  dictionaryContent: any;
  cartPages: any;
  productContent: any;

  checkoutContentLoaded: boolean;
  checkoutContent: any;
  buyflowContent: any;
  headerContent: any;
  footerContent: any;

  // From Comcast APIs
  // serviceability: {},
  serviceabilityData: any;
  productDetails?: any;
  prepaidPromotions: any;
  validPromos: any;
  haveValidPromos: boolean;
  hsdPromoId: string;

  taxData: any;
  taxDataLoaded: boolean;

  cart: {
    packages: object[];
    addOns: object[];
    promos: any | object[];
    haveStandaloneInstantTV: boolean;
    directCartFlow: boolean;
    offerInCart: boolean;
    cartCount: number;
  };
  cartSubtotal: number;
  cartTaxes: number;
  cartTotal: number;

  xsrfToken: string | null;
  fetchedToken: boolean;
  fetchingToken: boolean;
  fetchTokenFailed: boolean;

  validatedServiceability: boolean;
  validatingServiceability: boolean;
  validateServiceabilityFailed: boolean;

  fetchedProductDetails?: boolean;
  fetchingProductDetails?: boolean;
  fetchProductDetailsFailed: boolean;

  createdSession: boolean;
  creatingSession: boolean;
  createSessionFailed: boolean;

  restoredSession: boolean;
  restoringSession: boolean;
  restoreSessionFailed: boolean;

  fetchedPromos: boolean;
  fetchingPromos: boolean;
  fetchPromosFailed: boolean;

  fetchedTaxData: boolean;
  fetchingTaxData: boolean;
  fetchTaxDataFailed: boolean;

  submitedOrder: boolean;
  submitingOrder: boolean;
  submitOrderFailed: boolean;

  fetchedPaymentId?: boolean;
  fetchingPaymentId?: boolean;
  fetchPaymentIdFailed?: boolean;

  paymentId?: string;

  asrHandoffComplete: boolean;

  cpcResponse: any;

  addingPaymentInstrument: boolean;
  addedPaymentInstrument: boolean;
  addPaymentInstrumentFailed: boolean;

  paymentInstrumentErrorCode: string | null;
  lastActionAt: number;
  backToOrder: boolean;
  
}

// const updateReadyStatus = (state) => {
//     let ready = true
//     ready = ready && state.fetchedEnglishContent
//     ready = ready && state.fetchedSpanishContent
//     ready = ready && state.fetchedIdentity
//     ready = ready && state.fetchedDashboard
//     // ready = ready && state.fetchedAccount
//     ready = ready && state.fetchedDeviceStatus
//     state.ready = ready
//     state.fetchedContent = state.fetchedEnglishContent && state.fetchedSpanishContent
// }

const initialState = {
  digitalSessionId: null,
  digitalSession: null,
  shouldRestoreSession: false,
  errorCode:"",
  shopBaseUrl: "",
  redirectToShop: false,
  locales: ["en", "es"],
  locale: "en",
  unrecoverableActivationErrors: ["PAYMENT-0001", "SYS_CUSTACT_01"],
  successActivationErrors: ["BUS_CUSTACT_05", "SYS0010"],

  mediaBaseUrl: "",
  progress: 0,
  progressTitle: "",

  showNotification: false,
  notificationMessage: "",

  showErrorPage: false,
  error: "",

  paymentProcessing: false,
  orderPlaced: false,
  orderFailed: false,
  orderId: "",
  accountId: "",

  eligibilityConfirmed: false,
  productDetailsLoaded: false,

  haveComcastApiError: false,
  comcastApiErrors: [],

  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  locationId: "",
  marketId: "",

  billingName: "", // XXX API actually breaks these out and first and last name...
  billingFirstName: "",
  billingLastName: "",
  billingAddressLine1: "",
  billingAddressLine2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  billingCountry: "", // 'US',
  billingCardNumber: "",
  billingCardDisplayNumber: "",
  billingCardNumberLength: 0,
  billingCardNumberLastFour: "",
  billingCardType: "",
  billingCardNiceType: "",
  billingExpirationMonth: "",
  billingExpirationYear: "",
  billingCvv: "",
  billingAddress: true,

  cardTypeMap: {
    mastercard: "MASTER_CARD",
    visa: "Visa",
    discover: "Discover",
    "american-express": "AMERICAN_EXPRESS",
  },

  shippingSameAsBilling: true,
  shippingFirstName: "", // XXX Shipping first and last name are not in the new design
  shippingLastName: "",
  shippingAddressLine1: "",
  shippingAddressLine2: "",
  shippingCity: "",
  shippingState: "",
  shippingZip: "",

  shippingCode: "STD", // setting this as the default since it is required for the Tax API and is not consistently being passed to us from product API
  shippingRate: 0, // '9.95',
  freeShippingCartText: "",
  freeShippingPromoText: "",
  freeShippingChargeStatement: "",
  showFreeShipping: false,
  shippingDescription: "", // 'Standard',
  shippingTaxDetails: [],
  shippingTaxAmount: 0.0, // tax amount related to shipping, to deduct when shipping is free

  trackingId: "",
  activationSource: "COESALES",

  emailOptIn: false, // XXX This is an old field but no longer in the form, always default to true?
  smsOptIn: false, // XXX This is a new field, not sure how to send it!

  geoCode: "",
  email: "",
  confirmEmail: "",
  tn: "",
  displayTn: "",
  langPref: "ENGLISH",
  houseKey: "", // '08096271505026',
  serviceabilityBusinessErrorCode: null,

  bundleDetails: [],

  deviceDetails: [
    // {
    //   prepaidProduct: {
    //     prepaidProductCode: 'ECO-HSD-WIFI',
    //     prepaidProductName: 'And $35* one-time equipment and start-up fee',
    //     lob: 'HSD',
    //     planCode: 'KIT_WIFI',
    //     rate: 80,
    //     chargeCode: [
    //       {
    //         chargeCode: 'HW',
    //         prodTaxDetails: [
    //           {
    //             taxCode: '199',
    //             taxAmount: 3.39
    //           }
    //         ]
    //       },
    //       {
    //         chargeCode: 'SC',
    //         prodTaxDetails: []
    //       }
    //     ],
    //     planStatus: ''
    //   }
    // }
  ],

  // From Sitecore
  cartContentLoaded: false,
  cartContent: {},
  dictionaryContentLoaded: false,
  dictionaryContent: {},
  cartPages: {},
  productContent: {},

  checkoutContentLoaded: false,
  checkoutContent: {},
  buyflowContent: {},
  headerContent: {},
  footerContent: {},

  // From Comcast APIs
  // serviceability: {},
  serviceabilityData: {},
  productDetails: {},
  prepaidPromotions: {},
  validPromos: [],
  haveValidPromos: false,
  hsdPromoId: "",

  taxData: {},
  taxDataLoaded: false,

  cart: {
    packages: [],
    addOns: [],
    promos: [],
    haveStandaloneInstantTV: false,
    directCartFlow: false,
    offerInCart: false,
    cartCount: 1,
  },
  cartSubtotal: 0,
  cartTaxes: 0,
  cartTotal: 0,

  xsrfToken: null, // XXX not populated, not needed?
  fetchedToken: false,
  fetchingToken: false,
  fetchTokenFailed: false,

  validatedServiceability: false,
  validatingServiceability: false,
  validateServiceabilityFailed: false,

  fetchedProductDetails: false,
  fetchingProductDetails: false,
  fetchProductDetailsFailed: false,

  createdSession: false,
  creatingSession: false,
  createSessionFailed: false,

  restoredSession: false,
  restoringSession: false,
  restoreSessionFailed: false,

  fetchedPromos: false,
  fetchingPromos: false,
  fetchPromosFailed: false,

  fetchedTaxData: false,
  fetchingTaxData: false,
  fetchTaxDataFailed: false,

  submitedOrder: false,
  submitingOrder: false,
  submitOrderFailed: false,

  paymentId: "",

  fetchedPaymentId: false,
  fetchingPaymentId: false,
  fetchPaymentIdFailed: false,

  asrHandoffComplete: false,

  cpcResponse: {},

  addingPaymentInstrument: false,
  addedPaymentInstrument: false,
  addPaymentInstrumentFailed: false,

  paymentInstrumentErrorCode: null,
  lastActionAt: Date.now(),
  backToOrder: false

} as BuyFlowState;

const getCartSubtotal = (productDetails, cart) => {
  let subtotal = 0;

  if (productDetails?.products.length) {
    const internetProduct = productDetails?.products.find(
      (item) => item.product.lob === "HSD"
    );
    if (internetProduct) {
      internetProduct.product.plans.forEach((plan) => {
        subtotal += parseInt(plan.rate);
      });
    }
  }

  cart.promos.forEach((promo) => {
    // XXX not clear why discountDetails is an array
    subtotal += promo.discountDetails[0].effectivePrice;
  });
  return subtotal;
};

const resetPaymentInstrumentState = (state) => {
  state.addedPaymentInstrument = false;
  state.addingPaymentInstrument = false;
  state.addPaymentFailed = false;
  state.updatedPaymentInstrument = false;
  state.updatingPaymentInstrument = false;
  state.updatePaymentInstrumentFailed = false;
  state.removedPaymentInstrument = false;
  state.removingPaymentInstrument = false;
  state.removePaymentInstrumentFailed = false;
};
const buyflow = createSlice({
  name: "buyflow",
  initialState,
  reducers: {
    generateTrackingId: (state, { payload }) => {
      if (!state.trackingId) {
        state.trackingId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0;
            var v = c === "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line no-mixed-operators
            return v.toString(16);
          }
        );
      }
    },
    updateData: (state, { payload }) => {},
    updateLocale: (state, { payload }) => {
      state.locale = payload;
      state.langPref = payload === "en" ? "ENGLISH" : "SPANISH";
      /* if (state.locale === 'es') {
                state.content = {...state.spanishContent}
            } else {
                state.content = {...state.englishContent}
            } */
    },
    updateProgress: (state, { payload }) => {
      state.progressTitle = payload.title;
      state.progress = payload.progress;
    },
    updateField: (state, { payload }) => {
      state[payload.field] = payload.value;
      if (payload.field === "firstName") {
        state.billingFirstName = payload.value;
        state.shippingFirstName = payload.value;
        if (state.billingFirstName && state.billingLastName) {
          state.billingName = `${state.billingFirstName} ${state.billingLastName}`;
        }
      }
      if (payload.field === "lastName") {
        state.billingLastName = payload.value;
        state.shippingLastName = payload.value;
        if (state.billingFirstName && state.billingLastName) {
          state.billingName = `${state.billingFirstName} ${state.billingLastName}`;
        }
      }

      if (payload.field === "billingName") {
        const parts = payload.value.replace(/[ ]+/g, " ").split(" ");
        if (parts.length >= 2) {
          state.billingFirstName = parts[0];
          state.billingLastName = parts[parts.length - 1];
        }
      }
    },

    togglePromo(state, { payload }) {
      // Check to see if the particular promo is already in the cart
      const discId = payload;
      const promoInCart = current(state).cart.promos.find(
        (d) => d.discId === discId
      );
      if (promoInCart) {
        // If so, remove it
        state.cart.promos = current(state).cart.promos.filter(
          (d) => d.discId !== discId
        );
        state.cart.cartCount = 1;
        state.cart.offerInCart = false;
      } else {
        // Otherwise, find the discount object and add it
        if (
          current(state).prepaidPromotions.discounts &&
          current(state).prepaidPromotions.discounts.length
        ) {
          let promos: any;
          promos = [];
          const promo = current(state).prepaidPromotions.discounts.find(
            (d) => d.discId === discId
          );

          if (promo) {
            promos.push(promo);
            state.cart.promos = promos;
          }
          state.cart.cartCount = 2;
          state.cart.offerInCart = true;
        }
      }
      if (current(state).productDetails?.products && current(state).cart) {
        state.cartSubtotal = getCartSubtotal(
          current(state)?.productDetails,
          current(state).cart
        );
      }
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    updateLocationId: (state, { payload }) => {
      state.locationId = payload;
    },
    updateMarketId: (state, { payload }) => {
      state.marketId = payload;
    },
    updateDigitalSessionId: (state, { payload }) => {
      state.digitalSessionId = payload;
    },
    updateShouldRestoreSession: (state, { payload }) => {
      state.shouldRestoreSession = payload;
    },
    setDirectCartFlow: (state, { payload }) => {
      state.cart.directCartFlow = payload;
      state.cart.offerInCart = payload;
    },
    updateCartContent: (state, { payload }) => {
      state.cartContent = payload;
      state.cartContentLoaded = true;
      state.cartPages = payload.cartPages;
      state.cartContent = payload.cartContent;
      state.productContent = payload.products;

      state.productContent.forEach((product) => {
        product.actualPrice = 0;
        product.productData.plans.forEach((plan) => {
          product.actualPrice += plan.rate;
        });
      });
    },
    updateCheckoutContent: (state, { payload }) => {
      state.checkoutContent = {};
      // Normalize Sitecore content (flatten arrays of objects)
      let buyflowContent;
      buyflowContent = {};
      payload.buyflow.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          buyflowContent[key] = obj[key];
        });
      });
      state.buyflowContent = buyflowContent;
      // console.log('buyflow content', buyflowContent)
      let checkoutContent: any;
      checkoutContent = {};
      buyflowContent.checkout.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          checkoutContent[key] = obj[key];
        });
      });

      // console.log('buyflow checkout content', checkoutContent)

      const errors = {};
      checkoutContent.errors.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          errors[key] = obj[key];
        });
      });

      // console.log('buyflow checkout content errors', errors)

      checkoutContent.errors = errors;

      state.checkoutContent = checkoutContent;
      state.checkoutContentLoaded = true;
    },
    updateDictionaryContent: (state, { payload }) => {
      state.dictionaryContent = payload;
      state.dictionaryContentLoaded = true;
    },
    fetchToken: (state) => {
      state.fetchingToken = true;
    },
    fetchTokenSuccess: (state, { payload }) => {
      console.log("fetchTokenSuccess payload", payload);
      state.xsrfToken = payload.xsrfToken;
      state.fetchingToken = false;
      state.fetchTokenFailed = false;
      state.fetchedToken = true;
    },
    fetchTokenFailure: (state, { payload }) => {
      console.log("fetchTokenFailure payload", payload);
      state.fetchedToken = false;
      state.fetchingToken = false;
      state.fetchTokenFailed = true;
    },

    validateServiceability: (state) => {
      state.validatingServiceability = true;
    },
    validateServiceabilitySuccess: (state, { payload }) => {
      console.log("validateServiceabilty success", payload);
      const data = payload;

      state.serviceabilityData = data;

      // state.locationId = data.locationId
      state.geoCode = data.geocode;
      state.houseKey = data.houseKey;

      state.addressLine1 = data.address.addressLine1;
      state.addressLine2 = data.address.addressLine2;
      state.city = data.address.city;
      state.state = data.address.state;
      state.zip = data.address.zip;

      state.billingAddressLine1 = data.address.addressLine1;
      state.billingAddressLine2 = data.address.addressLine2;
      state.billingCity = data.address.city;
      state.billingState = data.address.state;
      state.billingZip = data.address.zip;

      state.shippingAddressLine1 = data.address.addressLine1;
      state.shippingAddressLine2 = data.address.addressLine2;
      state.shippingCity = data.address.city;
      state.shippingState = data.address.state;
      state.shippingZip = data.address.zip;

      state.serviceabilityBusinessErrorCode = data.businessErrorCode;

      state.eligibilityConfirmed = true;

      state.validatingServiceability = false;
      state.validateServiceabilityFailed = false;
      state.validatedServiceability = true;
    },
    validateServiceabilityFailure: (state, { payload }) => {
      console.log("validateServiceabilty failure", payload);
      state.validatedServiceability = false;
      state.validatingServiceability = false;
      state.validateServiceabilityFailed = true;
    },

    fetchProductDetails: (state) => {
      state.fetchingProductDetails = true;
    },
    fetchProductDetailsSuccess: (state, { payload }) => {
      // console.log('fetchProductDetails success', payload)
      const data = payload;

      state.productDetails = data;
      state.productDetailsLoaded = true;

      state.shippingCode = data.shipping.name;
      state.shippingRate = parseFloat(data.shipping.rate);
      if (state.shippingRate === 0.0) {
        state.showFreeShipping = true;
      }
      state.shippingDescription = data.shipping.desc;

      state.fetchingProductDetails = false;
      state.fetchProductDetailsFailed = false;
      state.fetchedProductDetails = true;
      state.cartSubtotal = getCartSubtotal(state?.productDetails, state.cart);
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    fetchProductDetailsFailure: (state, { payload }) => {
      console.log("fetchProductDetails failure", payload);
      state.fetchedProductDetails = false;
      state.fetchingProductDetails = false;
      state.fetchProductDetailsFailed = true;
    },
    createSession: (state) => {
      state.creatingSession = true;
    },
    createSessionSuccess: (state, { payload }) => {
      const sessionId = { ...payload };
      state.digitalSessionId = sessionId.digitalSessionId;
      console.log("digitalSessionId: ", state.digitalSessionId);
      state.creatingSession = false;
      state.createSessionFailed = false;
      state.createdSession = true;
    },
    createSessionFailure: (state, { payload }) => {
      console.log("createSession failure", payload);
      state.createdSession = false;
      state.creatingSession = false;
      state.createSessionFailed = true;
    },
    restoreDigitalSession: (state) => {
      state.restoringSession = true;
    },
    restoreDigitalSessionSuccess: (state, { payload }) => {
      const session = { ...payload };

      const stripPhoneNumber = sessionStorage.getItem("stripPhoneNumber");
      if (stripPhoneNumber === "true") {
        session.contact.phoneNumber = null;
      }
      const stripEmail = sessionStorage.getItem("stripEmail");
      if (stripEmail === "true") {
        session.contact.email = null;
      }

      state.digitalSession = session;

      state.firstName = session.name.firstName;
      state.lastName = session.name.lastName;

      state.tn = session.contact.phoneNumber;
      if (state.tn) {
        const first = session.contact.phoneNumber.substr(0, 3);
        const second = session.contact.phoneNumber.substr(3, 3);
        const third = session.contact.phoneNumber.substr(6, 4);
        state.displayTn = `${first}-${second}-${third}`;
      }

      state.email = session.contact.email || "";
      state.confirmEmail = session.contact.email || "";

      const cart = sessionStorage.getItem("cart");
      if (cart) {
        try {
          state.cart = JSON.parse(cart);
        } catch (e) {
          console.log("cart restoration error", e);
        }
      }

      state.restoringSession = false;
      state.restoreSessionFailed = false;
      state.restoredSession = true;
      state.asrHandoffComplete =
        state.digitalSession &&
        state.eligibilityConfirmed &&
        state.fetchedProductDetails;

      if (current(state)?.productDetails.products && current(state).cart) {
        state.cartSubtotal = getCartSubtotal(
          current(state)?.productDetails,
          current(state).cart
        );
      }
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    restoreDigitalSessionFailure: (state, { payload }) => {
      console.log("createSession failure", payload);
      state.restoredSession = false;
      state.restoringSession = false;
      state.restoreSessionFailed = true;
    },
    getPromos: (state) => {
      state.fetchingPromos = true;
    },
    getPromosSuccess: (state, { payload }) => {
      const prepaidPromotions = { ...payload.prepaidPromotions };

      // add `effectivePrice` field that's actually a number, for convenience
      if (prepaidPromotions.discounts) {
        prepaidPromotions.discounts.forEach((promo) => {
          const originalPrice = parseFloat(
            promo.discountDetails[0].originPricePerUnit
          );
          const discount = parseFloat(
            promo.discountDetails[0].discountedPricePerUnit
          );
          promo.discountDetails[0].effectivePrice = originalPrice + discount; // adding because discount is a negative number
        });
      }
      state.prepaidPromotions = prepaidPromotions;
      let tempPromotions = prepaidPromotions;
      if (
        tempPromotions &&
        tempPromotions.discounts &&
        tempPromotions.discounts.length
      ) {
        state.validPromos = tempPromotions.discounts.filter((discount) => {
          let valid = false;
          const now = Date.now();
          if (discount.discStartDate) {
            const s = discount.discStartDate
              .split(/[^0-9]/)
              .map((str) => parseInt(str));
            // apparently months are 0-11 in javascript, not 1-12
            const startDate = Date.UTC(s[0], s[1] - 1, s[2], s[3], s[4], s[5]);
            if (startDate && startDate <= now) {
              if (discount.discEndDate) {
                const e = discount.discEndDate.split(/[^0-9]/);
                const endDate = Date.UTC(
                  e[0],
                  e[1] - 1,
                  e[2],
                  e[3],
                  e[4],
                  e[5]
                );
                // valid if we have not reached the endDate yet
                // XXX need to make sure endDate timestamp is end of the day/start of next day (midnight)
                valid = now <= endDate;
              } else {
                // no end date, assuming it's not expired
                valid = true;
              }
            }
            valid =
              valid &&
              discount.discountDetails &&
              discount.discountDetails.length === 1;
            valid =
              valid && discount.discountDetails[0].product === "ECO-HSD-030";
          }
          return valid;
        });
      }
      if (state.validPromos.length) {
        state.haveValidPromos = true;
        state.hsdPromoId = state.validPromos[0].discId;
      }
      state.fetchingPromos = false;
      state.fetchPromosFailed = false;
      state.fetchedPromos = true;
    },
    getPromosFailure: (state, { payload }) => {
      console.log("getPromos failure", payload);
      state.fetchedPromos = false;
      state.fetchingPromos = false;
      state.fetchPromosFailed = true;
    },

    fetchTaxData: (state) => {
      state.fetchingTaxData = true;
    },
    fetchTaxDataSuccess: (state, { payload }) => {
      const taxData = { ...payload };
      state.taxData = taxData;
      state.cartTaxes = taxData.totalTaxAmt;
      state.fetchingTaxData = false;
      state.fetchTaxDataFailed = false;
      state.fetchedTaxData = true;
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
      if (
        state.digitalSession &&
        state.eligibilityConfirmed &&
        state.fetchedProductDetails &&
        state.fetchedTaxData
      ) {
        state.asrHandoffComplete = true;
      }
    },
    fetchTaxDataFailure: (state, { payload }) => {
      console.log("getTaxData failure", payload);
      state.fetchedTaxData = false;
      state.fetchingTaxData = false;
      state.fetchTaxDataFailed = true;
    },

    submitOrder: (state) => {
      state.submitingOrder = true;
    },
    submitOrderSuccess: (state, { payload }) => {
      const errorCode = payload.businessErrorCode || payload.systemErrorCode;

      if (errorCode) {
        if (state.successActivationErrors.includes(errorCode)) {
          state.orderId = payload.orderDetails.orderNumber;
          state.accountId = payload.accountId;
          state.orderPlaced = true;
        } else if (state.unrecoverableActivationErrors.includes(errorCode)) {
          console.log(
            "Showing error page",
            "Unrecoverable activation error",
            payload
          );
          state.submitingOrder = false;
          state.submitOrderFailed = true;
          state.submitedOrder = false;
        } else {
          const errorCode =
            payload.businessErrorCode || payload.systemErrorCode || "GENERIC";
          state.notificationMessage = errorForCode(errorCode, state.locale);
          state.errorCode=errorCode;
          state.showNotification = true;
          state.orderFailed = true;
        }
      } else {
        state.orderId = payload.orderDetails.orderNumber;
        state.accountId = payload.accountId;
        state.orderPlaced = true;
      }
      state.submitingOrder = false;
      state.submitOrderFailed = false;
      state.submitedOrder = true;
    },
    submitOrderFailure: (state, { payload }) => {
      console.log("submitOrder failure", payload);
      state.submitingOrder = false;
      state.submitOrderFailed = true;
      state.submitedOrder = false;
    },
    updateCpcResponse: (state, { payload }) => {
      state.cpcResponse = payload;
    },
    getPaymentId: (state) => {
      state.fetchingPaymentId = true;
    },
    getPaymentIdSuccess: (state, { payload }) => {
      console.log("getPaymentIdSuccess", payload);
      state.paymentId = payload.response.prepaidPaymentID;
      console.log("paymentId", state.paymentId);
      state.fetchingPaymentId = false;
      state.fetchedPaymentId = true;
      state.fetchPaymentIdFailed = false;
      state.backToOrder = false;
    },
    getPaymentIdFailure: (state, { payload }) => {
      console.log("getPaymentIdFailure failure", payload);
      state.fetchingPaymentId = false;
      state.fetchedPaymentId = false;
      state.fetchPaymentIdFailed = true;
      state.backToOrder = false;
    },
    resetPaymentId: (state) => {
      state.paymentId = "";
      state.backToOrder = true;
  },
  addPaymentInstrument: (state) => {
    resetPaymentInstrumentState(state);
    state.addingPaymentInstrument = true;
  },
  addPaymentInstrumentSuccess: (state, { payload }) => {
    console.log("addPaymentInstrumentSuccess payload", payload);
    state.addedPaymentInstrument = true;
    state.addingPaymentInstrument = false;
    state.addPaymentInstrumentFailed = false;
    state.lastActionAt = Date.now();
  },
  addPaymentInstrumentFailure: (state, { payload }) => {
    console.log("addPaymentInstrumentFailure payload", payload);
    state.addedPaymentInstrument = false;
    state.addingPaymentInstrument = false;
    state.addPaymentInstrumentFailed = true;
    state.paymentInstrumentErrorCode = payload.businessErrorCode;
  },
  },
});

export { buyflow };
