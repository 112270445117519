import DashboardLayout from '../../layouts/DashboardLayout'
import Accordion from '../../components/Accordion'
import ProgressSpinner from '../../components/ProgressSpinner'
import { useState, useEffect } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import { weekdays, months, trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataLinkClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const MoveNowConfirmation = (props) => {
    const {
        history,
        locale,
        accountId,
        custGuid,
        dashboard,
        newAuxAccount,
        behindTheModem,
        auxAccount,
        fetchAuxAccount,
        fetchedAuxAccount,
        fetchAuxAccountFailed,
        moveContent,
        isTestFile,
    } = props

    const [address, setAddress] = useState('')
    const [unit, setUnit] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [state, setState] = useState('')
    const [isColdTap, setIsColdTap] = useState(false)
    const [loading, setLoading] = useState(false)

    const currentDate = new Date()

    const dayOfWeek = weekdays[currentDate.getDay() - 1]
    const day = currentDate.getDate()
    const month = months[currentDate.getMonth()]
    const year = currentDate.getFullYear()

    useEffect(() => {
        let dataSrc = sessionStorage.getItem('xppdata')
        if (dataSrc) {
            const data = JSON.parse(dataSrc);
            if (data.address) {
                setAddress(data.address.toUpperCase())
                setUnit(data.unit.toUpperCase())
                setCity(data.city.toUpperCase())
                setZip(data.zip.toUpperCase())
                setState(data.state.toUpperCase())
                setIsColdTap(data.isColdTap)
            }
        }
        console.log('behind modem: ', behindTheModem)

        trackEvent({
            event: 'move info',
            moveStep: 'step 4',
            moveStepName: 'move complete',
            moveEvent: 'move type',
            moveDetail: 'move now'
        })

        setLoading(true)

        if (newAuxAccount) {
            setTimeout(() => {
                fetchAuxAccount({
                    accountId,
                    auxConfNo: newAuxAccount.auxAccountConfNo,
                    checkMoveEligibility: true,
                    checkReconnectEligibility: true
                })
            }, 10000)
        }
        else {
            console.log('A new aux account does not exist')
            history?.push(`/${locale}/error`)
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fetchedAuxAccount) {
            if (loading) {
                setLoading(false)
            }
        }
    }, [fetchedAuxAccount]) // eslint-disable-line

    useEffect(() => {
        if (fetchAuxAccountFailed) {
            console.error('Failed to fetch aux account details')
            history?.push(`/${locale}/error`)
        }
    }, [fetchAuxAccountFailed]) // eslint-disable-line

    useEffect(() => {
        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility?.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 4',
            moveStepName: 'move complete',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
    }, [auxAccount])

    const goToActivation = (e) => {
        trackClick(moveContent.moveNow.beginActivation.text);
        let activationUrl = `/${locale}/activation?accountId=${btoa(auxAccount?.auxDetails[0]?.auxAccountId)}&guid=${btoa(custGuid)}&bhr=false`
        console.log('activationUrl', activationUrl)
        window.location.href = activationUrl
    }

    const trackClick = (label) => {
        trackEvent({
            event: 'move info',
            moveStep: 'step 4',
            moveStepName: 'move complete',
            moveEvent: 'next step cta',
            moveDetail: label
        })
    }


    const FAQs = [
        {
          "videoText": "",
          "videoImage": {
            "url": "",
            "alt": ""
          },
          "question": "How do I update my account settings?",
          "answer": "<h3>Creating, logging in, and updating your online account</h3>\nNeed to update your account information or check out the details of your service? Head over to my.xfinityprepaid.com to log in.<br />\nIf you haven&rsquo;t created an online account yet, you&rsquo;ll want to do that now&mdash;go to my.xfinityprepaid.com and click &ldquo;CREATE AN ONLINE ACCOUNT NOW&rdquo; to get started.",
          "videoName": "",
          "videoEmbedID": ""
        },
        {
          "videoText": "",
          "videoImage": {
            "url": "",
            "alt": ""
          },
          "question": "I want to refill my Prepaid Instant TV service.",
          "answer": "<h3>With convenient refill options, topping off your Instant TV is easy.</h3>\nHead over to my.xfinityprepaid.com to log in to your account. From there, you&rsquo;ll be able to refill your Instant TV service online with a credit or debit card. Or, if you&rsquo;d like to buy with cash, you can always <a href=\"https://www.xfinityprepaid.com/find-a-retailer\">visit your local retailer</a>.",
          "videoName": "",
          "videoEmbedID": ""
        },
        {
          "videoText": "",
          "videoImage": {
            "url": "",
            "alt": ""
          },
          "question": "What’s the Xfinity Prepaid App?",
          "answer": "<h3>Your Prepaid account, all in one place&nbsp;</h3>\nThe Xfinity Prepaid app makes it easy to buy refills and manage your account on the go.&nbsp;<br />\n[<a href=\"https://apps.apple.com/us/app/xfinity-prepaid/id1436535939\" target=\"_blank\">iOS</a>]&nbsp; &nbsp;[<a href=\"https://play.google.com/store/apps/details?id=com.xfinity.xfinityprepaid&amp;hl=en_US\" target=\"_blank\">Android</a>]",
          "videoName": "",
          "videoEmbedID": ""
        },
        {
          "videoText": "",
          "videoImage": {
            "url": "",
            "alt": ""
          },
          "question": "How do I fix my connection?",
          "answer": "<h3>Tips and tricks for a slow or weak connection</h3>\r\nIf you can&rsquo;t connect to the Internet, the Getting Started Guide included with your Modem Kit has some troubleshooting tips for you to try.<br />\r\nYou can also reset your device through the link on <a href=\"https://my.xfinityprepaid.com?langPref=EN\">your Prepaid dashboard</a> or through the Xfinity Prepaid mobile app.",
          "videoName": "",
          "videoEmbedID": ""
        }
    ]


    let ready = moveContent.loaded && !loading
    const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
    return (
        <DashboardLayout locale={props.locale} reconnectHeader={true} pageTitle={'Move Now Confirmation'}>
            <TrackUserData data={dashboard} />
            {!ready && <ProgressSpinner loadingText={'Loading...'} />}
            {ready &&
            <div>
                { behindTheModem
                ? <div className="main__width-constraint move move-flow move--finalize">
                    <div className="move--now">
                        <div className="check-mark"></div>
                        <h1 className="page__title fullbleed-offsets">
                            {moveContent.moveNow.yourMoveRequestHasBeenReceived}
                        </h1>
                        { isColdTap &&
                        <div>
                            <hr></hr>
                            <b>{ moveContent?.moveScheduled?.onTheScheduledDate }</b>
                        </div>}
                        <div className="service-section">
                            <div className="fullbleed-offsets">
                                <b> {moveContent.moveNow.serviceTransfer}</b>
                                <hr></hr>
                                <div className="service-section__row">
                                    <div className="address">
                                        <b> {moveContent.moveNow.toDestinationAddress}</b><br></br>
                                        <div>{address}</div>
                                        {unit !== '' ? <div>{unit}</div> : null }
                                        {city}, {state} {zip}
                                        <p></p>
                                        <b>{moveContent.moveNow.fromPreviousAddress}</b><br></br>
                                        {dashboard?.serviceAddress?.addressLine1}
                                        {dashboard?.serviceAddress?.addressLine2 !== '' ? <div>{dashboard?.serviceAddress?.addressLine2}</div> : null }
                                        {dashboard?.serviceAddress?.city}, {dashboard?.serviceAddress?.state} {dashboard?.serviceAddress?.zip}
                                    </div>
                                    {/* <button className="button--link" onClick={() => history.push("/en/dollargeneral")}>Edit Address</button> */}
                                </div>
                                <p></p>
                                <div className="service-section__row">
                                    <div className="address">
                                        <b>{moveContent.moveNow.moveDate}</b><br></br>
                                        <span>{dayOfWeek}, {month} {day}, {year}</span>
                                    </div>
                                    {/* <button className="button--link" onClick={() => history.push("/en/move/schedule")}>Edit Date</button> */}
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                        <div className="what-next" dangerouslySetInnerHTML={{__html: moveContent.moveNow.whatComesNext}}></div>
                        <Accordion title={moveContent.moveNow.moveSupport} items={FAQs} eventObj={{event: 'accordion', accordionType: 'faq', accordionPage: 'dollargeneral', faqSubcategory: "content.success.supportTitle"}} eventDetailCategory='accordionText' />
                    </div>
                </div>
                : <div className="main__width-constraint move move-flow move--finalize">
                    <div className="card move--now">
                        <div className="check-mark"></div>
                        <h1 className="page__title fullbleed-offsets">
                            {moveContent.moveNow.yourMoveRequestHasBeenReceived}
                        </h1>
                        { isColdTap ?
                            <div>
                                <hr></hr>
                                <b>{ moveContent?.moveScheduled?.onTheScheduledDate }</b>
                            </div>
                            :
                            null
                        }
                        <p> {moveContent.moveNow.weHaveConfirmedYourMoveRequest}</p>

                        <div className="button--container">
                            <button className="button" onClick={goToActivation} data-tracking={digitalDataButtonClick('begin-activation', moveContent.moveNow.beginActivation.text)}>{moveContent.moveNow.beginActivation.text}</button>
                        </div>
                        <hr></hr>

                        <div className="button--container">
                            <p>{moveContent.moveNow.imNotReadyToActivateYet}</p>
                            <a href={logoutURL} className="button button--hollow" onClick={() => { trackClick('Logout')}} data-tracking={digitalDataLinkClick('logout', 'https://oauth.xfinity.com/oauth/sp-logout?client_id=xfinity_prepaid_digital', 'Logout')}>Logout</a>
                        </div>
                    </div>
                </div>}
            </div>}
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveNowConfirmation)