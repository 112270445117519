import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { sprintf } from 'sprintf-js'
import { digitalDataButtonClick, digitalDataAccordionClick } from '../../utils/adobe-analytics';
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
      // extends React's HTMLAttributes
        multiselectable?: string;
    }
}

interface ComponentProps {
    items?: any
    title?: any
    eventObj?: any
    eventDetailCategory?: any
    eventDetailCategoryPretext?: any
    autoRefill?: boolean
    isTestFile:boolean
}

const PaymentHistoryAccordion = ({items, title, eventObj, eventDetailCategory, eventDetailCategoryPretext, autoRefill,isTestFile}: ComponentProps) => {
    const [activeIndex, setActiveIndex] = useState(null)

    const [currentPaginationPage, setCurrentPaginationPage] = useState(1)

    let pages = 1
    const perPage = 12

    const calculatePages = () => {
        let list = items
        if (list?.length <= perPage) {
            pages = 1;
        } else {
            pages = Math.ceil(list?.length / perPage)
        }
    }

    const paginationList = () => {
        let list = items,
            currentPage = currentPaginationPage
        calculatePages()

        var start = 0;

        if (list?.length < perPage) {
            currentPage = 1;
        } else if (currentPage > 1) {
            start = (currentPage - 1) * perPage;
        }
        let paginationList = []
        paginationList = list?.slice(start, start + perPage)
        if (paginationList !== null) {
            return paginationList
        } else return items
    }

    const centerPages = () => {
        calculatePages()
        if (pages <= 7) {
            let pgs : any[] = []
            for (var i = 2; i < pages; i++) {
                pgs.push(i)
            }
            return pgs
        } else {
            if (currentPaginationPage < 5) {
                return [2, 3, 4, 5]
            }

            if (currentPaginationPage > (pages - 3)) {
                return [pages - 3, pages - 2, pages - 1];
            }

            return [currentPaginationPage -1, currentPaginationPage, currentPaginationPage + 1];
        }
    }
    const nextPage = () => {
        if (currentPaginationPage < pages) {
            setCurrentPaginationPage(currentPaginationPage + 1)
        }
    }
    const previousPage = () => {
        if (currentPaginationPage > 1) {
            setCurrentPaginationPage(currentPaginationPage - 1)
        }
    }
    const updatePage = (newPage) => {
        setCurrentPaginationPage(newPage)
    }
    const renderPagination = () => {
        if (items?.length > perPage) {
            return (
                <div className="pagination-pag">
                    <ul className="pagination-pag__list">
                        <li className="pagination-pag__item pagination-pag__item--prev">{
                        !isTestFile ? (
                            <button disabled={currentPaginationPage === 1} className="pagination-pag__btn pagination-pag__btn--prev" onClick={previousPage}>{'Previous'}</button>
                            ) : (
                                <button data-testid="01-list" disabled={currentPaginationPage === 1} className="pagination-pag__btn pagination-pag__btn--prev" onClick={previousPage}>{'Previous'}</button>
                            )
        }
                        </li>
                        <li className="pagination-pag__item">
                            <button
                                data-testid="01-pagination"
                                className="pagination-pag__link"
                                aria-label={'page'}
                                aria-current={1 === currentPaginationPage ? 'true' : 'false'}
                                onClick={() => {updatePage(1)}}
                                data-tracking={digitalDataButtonClick('pagination-current', '1')}
                            > 1</button>
					    </li>
                        {renderPreviousElipsis()}
                        {rendereCenterPages}
                        {renderNextElipsis()}
                        <li className="pagination-pag__item">
                            <button
                                data-testid="01-pagination-pag"
                                className="pagination-pag__link"
                                aria-label={'Pages'}
                                aria-current={pages === currentPaginationPage ? 'true' : 'false'}
                                onClick={() => {updatePage(pages)}}
                                data-tracking={digitalDataButtonClick('pagination-pages', pages.toString())}
                            > {pages}</button>
					    </li>
                        <li className="pagination-pag__item pagination-pag__item--next">{
                        !isTestFile ? (
                            <button disabled={currentPaginationPage === pages} className="pagination-pag__btn pagination-pag__btn--next" onClick={nextPage}>{'Next'}</button>
                            ) : (
                                <button data-testid="01-next" disabled={currentPaginationPage === pages} className="pagination-pag__btn pagination-pag__btn--next" onClick={nextPage}>{'Next'}</button>
                            )
        }
                        </li>
                    </ul>
                </div>
            )
        } else {
            return null
        }
    }
    const rendereCenterPages = centerPages()?.map((item, index) => {
        return (
            <li className="pagination-pag__item" key={index}>
                <button
                    className="pagination-pag__link"
                    aria-label={'Page Number'}
                    aria-current={item === currentPaginationPage ? 'true' : 'false'}
                    onClick={() => updatePage(item)}
                    data-tracking={digitalDataButtonClick('pagination-link', item)}
                >
                    {item}
                </button>
            </li>
        )
    })
    const renderPreviousElipsis = () => {
        if (pages > 7 && currentPaginationPage > 4) {
            return (
                <li className="pagination-pag__item">... </li>
            )
        } else return null
    }
    const renderNextElipsis = () => {
        if (pages > 7 && currentPaginationPage < (pages - 2)) {
            return (
                <li className="pagination-pag__item">... </li>
            )
        } else return null
    }

    const renderedItems = paginationList()?.map((item, index) => {
        const onTitleClick = (index) => {
            if (activeIndex === index) {
                setActiveIndex(null)
            } else {
                setActiveIndex(index)
            }
        }
        const captureEnterKey = (event, index) => {
            if (event.keyCode === 13 || event.keyCode === 32) {
                onTitleClick(index)
            }
        }

        return (
            <React.Fragment key={index}>
                <div className="accordion__item">
                    <div
                        id="01-tab"
                        tabIndex={0}
                        role="tab"
                        aria-expanded={activeIndex === index}
                        aria-controls="01-panel"
                        className={`accordion__tab ${activeIndex === index ? "" : "active"}`}
                        onClick={() => {
                            onTitleClick(index);
                            digitalDataAccordionClick('payment-history-active', 'Date: ' + item.formattedDate + ' Amount: ' + item.formattedTotal, '.accordion-tab')
                        }}
                        onKeyDown={(e) => captureEnterKey(e, index)}
                    >
                        <div className="accordion__icon">
                            <span className="line" /><span className="line" />
                        </div>
                        <div className="accordion-date">
                            Date:
                            <span className="accordion-date-detail">
                                {item.formattedDate}
                            </span>
                        </div>
                        <div className="accordion-amount">
                            Amount:
                            <span className="accordion-amount-detail">
                                {item.formattedTotal}
                            </span>
                        </div>
                    </div>
                    <CSSTransition
                        in={activeIndex === index}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames="expand"
                    >
                    <div
                        className={`accordion__panel ${activeIndex === index ? "" : "expand-exit-done"}`}
                        id="01-panel"
                        aria-hidden={activeIndex !== index}
                        aria-labelledby="01-tab"
                        tabIndex={0}
                        role="tabpanel"
                    >
                        <div className="accordion__content">
                            <div className="card ">
                                <table className="totals">
                                    <caption className="totals__caption">
                                        <div>
                                            <h5>
                                                Service:
                                            </h5>
                                        </div>
                                    </caption>
                                    <tbody>
                                    { item.items?.map((lineItem, lineIndex) => {
                                        return <tr className="line-item" key={lineIndex}>
                                        <th className="line-item__label">
                                            {lineItem.duration
                                            ? `${lineItem.duration} Days of ${lineItem.product}`
                                            : lineItem.product}
                                            {lineItem.expirationDate && <br/>}
                                            {lineItem.expirationDate && <span className="line-item__label expiration">Expires {lineItem.formattedExpirationDate}</span>}
                                        </th>
                                        <td className="line-item__price">
                                            {lineItem.amount !== null && <span className="price-full">
                                                <span>$</span>
                                                {sprintf("%2.2f", lineItem.amount)}
                                            </span>}
                                        </td>
                                    </tr>

                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </CSSTransition>
                </div>
            </React.Fragment>
        )
    })
    return (
        <div className="payment-history-accordion">
            <fieldset>
                <div className="vh" aria-live="polite" />

                <div className="accordion accordion__faq-group" role="tablist" multiselectable="true">
                    {title ? <div className="accordion__title">{title}</div> : ''}
                        {renderedItems}
                        {renderPagination()}
                </div>
            </fieldset>
        </div>
    )
}

export default PaymentHistoryAccordion
