import withBuyflow from '../../redux/connectors/buyflow'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

function Footer(props) {
    const {
        buyflowContent
    } = props

        return (
            <div className="footer buyflow">
                <div className="footer__width-constraint">
                    <div className="footer__flex">
                        <nav aria-label="Footer Utility Links">
                        <ul className="footer__utils">
                            <li dangerouslySetInnerHTML={{__html: buyflowContent?.buyflowFooter?.copyrightStatement!}} />
                            <li>
                                <a href={buyflowContent?.buyflowFooter?.link1Destination?.url} target={buyflowContent?.buyflowFooter?.link1Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', buyflowContent?.buyflowFooter?.link1Destination?.url, buyflowContent?.buyflowFooter?.link1Label)}>{buyflowContent?.buyflowFooter?.link1Label}</a>
                            </li>
                            <li>
                                <a href={buyflowContent?.buyflowFooter?.link2Destination?.url} target={buyflowContent?.buyflowFooter?.link2Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', buyflowContent?.buyflowFooter?.link2Destination?.url, buyflowContent?.buyflowFooter?.link2Label)}>{buyflowContent?.buyflowFooter?.link2Label}</a>
                            </li>
                            <li>
                                <a href={buyflowContent?.buyflowFooter?.link3Destination?.url} target={buyflowContent?.buyflowFooter?.link3Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', buyflowContent?.buyflowFooter?.link3Destination?.url, buyflowContent?.buyflowFooter?.link3Label)}>{buyflowContent?.buyflowFooter?.link3Label}</a>
                            </li>
                            <li>
                                <a href={buyflowContent?.buyflowFooter?.link4Destination?.url} target={buyflowContent?.buyflowFooter?.link4Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', buyflowContent?.buyflowFooter?.link4Destination?.url, buyflowContent?.buyflowFooter?.link4Label)}>{buyflowContent?.buyflowFooter?.link4Label}</a>
                            </li>
                            <li>
                                <a href={buyflowContent?.buyflowFooter?.link5Destination?.url} target={buyflowContent?.buyflowFooter?.link5Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', buyflowContent?.buyflowFooter?.link5Destination?.url, buyflowContent?.buyflowFooter?.link5Label)}>{buyflowContent?.buyflowFooter?.link5Label}</a>
                            </li>
                        </ul>
                        </nav>
                        <div className="showLogo footer__logo">
                            <div className="comcast-logo" aria-label="Comcast" />
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default withBuyflow(Footer)
