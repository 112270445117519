import { useEffect, useState } from 'react'
import NotificationBanner from '../../components/NotificationBanner'
import ProgressSpinner from '../../components/ProgressSpinner'
import { useHistory } from 'react-router-dom'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataValidationError } from '../../utils/adobe-analytics'

const PinSubmissionForm = (props) => {
    const {
        accountId,
        refillContent,
        currentLocale,
        activationCode,
        validateActivationCode,
        validatedActivationCode,
        validateActivationCodeFailed,
        redeemRefill,
        redeemedRefill,
        redeemRefillFailed,
        hsdService,
        updateField,
    } = props

    const [showForm, setShowForm] = useState(true)
    const [pinError, setPinError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [validated, setValidated] = useState(false)
    const [pin, setPin] = useState('')
    const history = useHistory();

    const submitPIN = () => {
        console.log('submitPIN')
        setSubmitting(true)
        setPinError(false)
        setValidated(false)

        redeemRefill({accountId, code: pin, hsdService})
    }

    useEffect(() => {
        if (redeemedRefill) {
            trackEvent({
                event: 'pin refill info',
                refillStep: 'step 3',
                refillStepName: 'order review',
                refillEvent: 'next step cta',
                refillDetail: refillContent?.refillFlow?.cart?.redeemRefillPiNCode?.text
            })
            history.push(`/${currentLocale}/refill/confirmation`)
        } else if (redeemRefillFailed) {
            setSubmitting(false)
            setPinError(true)
            setShowForm(true)
            trackEvent({
                event: 'pin refill info',
                refillStep: 'step 1',
                refillStepName: 'start refill',
                refillEvent: 'error',
                refillDetail: 'redeem pin identity error'
            })
        }
    }, [redeemedRefill, redeemRefillFailed]) // eslint-disable-line


    const validatePIN = (e) => {
        e.preventDefault()
        e.stopPropagation()

        trackEvent({
            event: 'pin refill info',
            refillStep: 'step 1',
            refillStepName: 'start refill',
            refillEvent: 'next step cta',
            refillDetail: refillContent?.refillFlow?.cart?.verifyRefillPiNCode?.text
        })

        trackEvent({
            event: 'pin refill flow',
            refillStep: 'step 2',
            refillStepName: 'payment',
            refillStepDetail: 'redeem code'
        })

        setPinError(false)
        setSubmitting(true)
        validateActivationCode({accountId, pin})
    }

    useEffect(() => {
        if (validatedActivationCode) {
            if (activationCode?.redemptionDate) {
                console.log('This code has already been redeemed on', activationCode?.redemptionDate)
                setShowForm(true)
                setPinError(true)
                setSubmitting(false)
            } else {
                trackEvent({
                    event: 'pin refill flow',
                    refillStep: 'step 3',
                    refillStepName: 'order review',
                    refillStepDetail: 'redeem code'
                })
                setShowForm(false)
                setSubmitting(false)
                updateField({field: 'validatingActivationCode', value: false})
                updateField({field: 'validatedActivationCode', value: false})
                updateField({field: 'validateActivationCodeFailed', value: false})
                setValidated(true)
            }
        }

    }, [validatedActivationCode]) // eslint-disable-line

    useEffect(() => {
        if (validateActivationCodeFailed) {
            setSubmitting(false)
            setPinError(true)
            digitalDataValidationError('pin submission', ['pin'], 'redeem pin input invalid', '.pin-refill');
            trackEvent({
                event: 'pin refill info',
                refillStep: 'step 1',
                refillStepName: 'start refill',
                refillEvent: 'error',
                refillDetail: 'redeem pin input error'
            })
        }

    }, [validateActivationCodeFailed]) // eslint-disable-line

    return (
        <div className="pin-refill">
            {pinError && <NotificationBanner text={refillContent?.refillFlow?.cart?.ourSystemDoesNotRecognizeTheRefillPiNEntered?.message} displayStyle="alert" modal="true" data-testid="pin-error" />}

            <div className="card">
                {submitting ? <ProgressSpinner loadingText="Verifying your Prepaid code..." data-testid="progress-spinner" /> :
                <div>
                    <div className="card__header">
                        <h2 className="card__header-title">
                            {showForm ? refillContent?.refillFlow?.cart?.enterYourPrepaidRefillPiN?.text : 'Refill PIN Verified'}
                        </h2>
                    </div>
                    { showForm &&
                    <div className="pin-refill-form">
                        <p>
                            {refillContent?.refillFlow?.cart?.yourRefillPiNCodedCanBeFound}
                        </p>
                        <img src="../../site-assets/placeholder/PINillustration_2x.png" alt="refill graphic" />
                        <form
                            id="pin"
                            action=""
                            method="get"
                        >
                            <label htmlFor="pinInput" className="form-field__title form-field-cvv">
                                {refillContent?.refillFlow?.cart?.enterYourPrepaidRefillPiN?.text}
                            </label>
                            <input
                                id="pinInput"
                                className={pinError ? 'validation-error' : ''}
                                name="pinInput"
                                type="text"
                                value={pin}
                                onChange={e => setPin(e.target.value)}
                                maxLength={16}
                                data-testid="pin-input"
                            />
                            {pinError &&
                            <label id="pinInput-error" htmlFor="pinInput" className="validation-error">{refillContent?.refillFlow?.cart?.enterCorrectRefillPiNCode}</label>}

                            <div className="button--container">
                                <button disabled={pin === ''} className="button" onClick={validatePIN} data-testid="validate-pin-button" data-tracking={digitalDataButtonClick('verify-refill-pin', refillContent.refillFlow?.cart.verifyRefillPiNCode?.text)}>
                                    {refillContent?.refillFlow?.cart?.verifyRefillPiNCode?.text}
                                </button>
                            </div>
                        </form>
                    </div>}
                    {validated && !activationCode?.redemptionDate &&
                    <div className="pin-refill-success">
                        <h2 className="card__header-title">
                            Prepaid Internet {activationCode?.planQty} day refill
                        </h2>
                        <h4 className="pin-refill-header">{refillContent?.refillFlow?.cart?._16DigitRefillPiNCode}:</h4>
                        <div className="pin-refill-detail">{activationCode?.pin}</div>
                        <h4 className="pin-refill-header">{refillContent?.refillFlow?.cart?.expirationDate}</h4>
                        <div className="pin-refill-detail">{activationCode?.formattedExpirationDate}</div>

                    </div>}
                </div>}
            </div>
            { showForm ? null :
            <div className="button--container">
                <button className="button" onClick={() => {submitPIN()}} data-testid="submit-pin-button" data-tracking={digitalDataButtonClick('submit-pin', refillContent.refillFlow?.cart.redeemRefillPiNCode?.text)}>
                    {refillContent?.refillFlow?.cart?.redeemRefillPiNCode?.text}
                </button>
            </div>}
        </div>
    )
}

export default withDashboard(PinSubmissionForm)