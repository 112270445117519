import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BuyFlowLayout from "../../layouts/BuyFlowLayout";
import { isObject } from "../../utils/utils";
import withBuyflow from "../../redux/connectors/buyflow";
import Cart from "../../components/buyflow/Cart";
import StickyCart from "../../components/buyflow/StickyCart";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../utils/utils";
import { digitalDataButtonClick, digitalDataValidationError } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const BuyflowCustomerInfo = (props) => {
  const {
    locale,
    cartPages,
    checkoutContent,
    updateProgress,
    asrHandoffComplete,
    digitalSession,
    cartContent,
    firstName,
    lastName,
    displayTn,
    email,
    confirmEmail,
    dictionaryContent,
    updateField,
    prepaidInternetProduct,
    serviceabilityData,
    cartTotal
  } = props;

  const [pageTitle, setPageTitle] = useState("Xfinity Prepaid");
  const { handleSubmit, register, trigger, errors } = useForm();
  const [products, setProducts] = useState<any[]>([]);

  const history = useHistory();

  // forcing state to rerender to show validation
  useEffect(() => {
    if (errors?.firstName || errors?.lastName || errors?.email || errors?.confirmEmail || errors?.displayTn) {
      digitalDataValidationError('customer info', [errors?.firstName ? 'firstName' : '', errors?.lastName ? 'lastName' : '', errors?.email ? 'email' : '', errors?.confirmEmail ? 'confirmEmail' : '', errors?.displayTn ? 'displayTn' : ''], 'customer info invalid', '.main__desktop-body');
  }
  }, [errors]);

  useEffect(() => {
    if (isObject(cartPages))
    setProducts([]);
      setPageTitle("Customer Information - Xfinity Prepaid");
    updateProgress({
      title: checkoutContent?.customerInformation?.statusLabel,
      progress: 60,
    });
    let products: any;
    products = [];
    for (
      let i = 0;
      i < prepaidInternetProduct?.productData?.plans?.length;
      i++
    ) {
      let product: any;
      product = {};
      product.name =
        prepaidInternetProduct?.productData?.plans[i]?.planDisplayText;
      product.id = prepaidInternetProduct?.productData?.plans[i]?.plan;
      product.sku = prepaidInternetProduct?.productData?.plans[i].sku
      product.price = prepaidInternetProduct?.productData?.plans[i]?.rate;
      product.dimension1 = "buy";
      product.dimension4 = "website";
      product.quantity = 1;
      products.push(product);
    }
    setProducts(products);
    trackEvent({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 5 },
          products,
        },
      },
    });
  }, [cartPages]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onError = (errors, e) => {
    e.preventDefault();
    console.log("onError", errors);
  };
  const handleNext = async () => {
    const validated = await trigger();
    if (validated && Object.keys(errors).length === 0) {
      history.push("/" + locale + "/buy/order-details");
    } else {
      console.log("onError", errors);
    }
  };
  const onUpdateField = (e) => {
    if (errors[e.target.name]) {
      trigger(e.target.name.toString());
    }
    if (e.target.name === "displayTn") {
      const target = e.target;
      const input = target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
      const zip = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        target.value = `(${zip}) ${middle} - ${last}`;
      } else if (input.length > 3) {
        target.value = `(${zip}) ${middle}`;
      } else if (input.length > 0) {
        target.value = `(${zip}`;
      } else if (input.length === 0) {
        target.value = "";
      }
      const tn = target.value.replace(/\D/g, "").substring(0, 10);
      updateField({ field: "tn", value: tn });
      updateField({ field: e.target.name, value: e.target.value });
    } else if (e.target.name === "firstName" || e.target.name === "lastName") {
      e.target.value = e.target.value.replace(
        /[^a-zA-Z\s-'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ:]/g,
        ""
      );
      updateField({ field: e.target.name, value: e.target.value });
    }
  };
  const onBlurField = (e) => {
    trigger(e.target.name.toString());
    updateField({ field: e.target.name, value: e.target.value });
  };
  const sameEmail = (value) => {
    return email === value;
  };

  return (
    <BuyFlowLayout locale={locale}>
      <TrackUserData data={serviceabilityData} />
      <TrackPurchaseData offerIncluded={false} price={cartTotal} productID={products.length > 0 ? products[0].sku : ''} productType={'prepaid-kit'} quantity={1} transactionID={""} screenName="customer-info"/>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="main_width-constraint clear">
        <div className="page__header">
          <h1 className="page__title">
            {checkoutContent?.customerInformation?.customerInformationPageTitle}
          </h1>
        </div>
        <div className="main__desktop-body">
          <form id="my-info" data-testid="handle-submit" onSubmit={() => handleSubmit(onSubmit, onError)}>
            <div className="main__width-constraint">
              <div className="card">
                <div className="flex flex--50-50" data-flex-start="medium">
                  <div className="flex-item form-field">
                    <label className="form-field__title" htmlFor="firstName">
                      {
                        checkoutContent?.customerInformation
                          ?.firstNameFieldLabel
                      }
                    </label>
                    <input
                      id="firstName"
                      data-testid="First-Name"
                      defaultValue={firstName}
                      className={`${
                        errors.firstName ? "validation-error" : ""
                      }`}
                      name="firstName"
                      type="text"
                      maxLength={70}
                      onInput={onUpdateField}
                      onBlur={onBlurField}
                      ref={register({ required: true })}
                      readOnly={
                        asrHandoffComplete && digitalSession?.name.firstName
                      }
                    />
                    {errors.firstName && (
                      <label
                        id="first-name-error"
                        htmlFor="firstName"
                        className="validation-error"
                      >
                        {
                          checkoutContent?.customerInformation
                            ?.firstNameNotEnteredErrorMessage
                        }
                      </label>
                    )}
                  </div>

                  <div className="flex-item form-field">
                    <label className="form-field__title" htmlFor="lastName">
                      {checkoutContent?.customerInformation?.lastNameFieldLabel}
                    </label>
                    <input
                      id="lastName"
                      data-testid="Last-Name"
                      defaultValue={lastName}
                      className={`${errors.lastName ? "validation-error" : ""}`}
                      name="lastName"
                      type="text"
                      maxLength={40}
                      onInput={onUpdateField}
                      onBlur={onBlurField}
                      ref={register({ required: true })}
                      readOnly={
                        asrHandoffComplete && digitalSession?.name.lastName
                      }
                    />
                    {errors.lastName && (
                      <label
                        id="last-name-error"
                        htmlFor="lastName"
                        className="validation-error"
                      >
                        {
                          checkoutContent?.customerInformation
                            ?.lastNameNotEnteredErrorMessage
                        }
                      </label>
                    )}
                  </div>

                  <div className="flex-item form-field">
                    <label className="form-field__title" htmlFor="email">
                      {
                        checkoutContent?.customerInformation
                          ?.emailAddressFieldLabel
                      }
                    </label>
                    <input
                      id="email"
                      defaultValue={email}
                      className={`${errors.email ? "validation-error" : ""}`}
                      name="email"
                      type="email"
                      maxLength={300}
                      onInput={onUpdateField}
                      onBlur={onBlurField}
                      readOnly={
                        asrHandoffComplete && digitalSession?.contact.email
                      }
                      ref={register({
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message:
                            dictionaryContent?.formLabels?.fieldIsRequired,
                        },
                      })}
                    />
                    {errors.email && (
                      <label
                        id="email-error"
                        htmlFor="email"
                        className="validation-error"
                      >
                        {
                          checkoutContent?.customerInformation
                            ?.emailAddressNotEnteredErrorMessage
                        }
                      </label>
                    )}
                  </div>

                  <div className="flex-item form-field">
                    <div v-if="showEmailConfirmation">
                      <label
                        className="form-field__title"
                        htmlFor="confirmEmail"
                      >
                        {
                          checkoutContent?.customerInformation
                            ?.confirmEmailAddressFieldLabel
                        }
                      </label>
                      <input
                        id="confirmEmail"
                        defaultValue={confirmEmail}
                        className={`${
                          errors.confirmEmail ? "validation-error" : ""
                        }`}
                        name="confirmEmail"
                        type="email"
                        maxLength={300}
                        onInput={onUpdateField}
                        onBlur={onBlurField}
                        ref={register({
                          required: true,
                          validate: sameEmail,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              dictionaryContent?.formLabels?.fieldIsRequired,
                          },
                        })}
                      />
                      {errors.confirmEmail &&
                        errors.confirmEmail.type !== "validate" && (
                          <label
                            id="confirm-email-error"
                            htmlFor="confirmEmail"
                            className="validation-error"
                          >
                            {
                              checkoutContent?.customerInformation
                                ?.confirmedEmailAddressNotEnteredErrorMessage
                            }
                          </label>
                        )}
                      {errors.confirmEmail &&
                        errors.confirmEmail.type === "validate" && (
                          <label
                            id="confirm-email-error"
                            htmlFor="confirmEmail"
                            className="validation-error"
                          >
                            {
                              checkoutContent?.customerInformation
                                ?.emailAddressFieldsDoNotMatchErrorMessage
                            }
                          </label>
                        )}
                    </div>
                  </div>
                  <div className="flex-item form-field">
                    <label className="form-field__title" htmlFor="displayTn">
                      {
                        checkoutContent?.customerInformation
                          ?.mobileNumberFieldLabel
                      }
                    </label>
                    <input
                      id="displayTn"
                      data-testid="display-Tn"
                      defaultValue={displayTn}
                      name="displayTn"
                      type="tel"
                      maxLength={16}
                      onInput={onUpdateField}
                      onBlur={onBlurField}
                      ref={register({
                        required: true,
                      })}
                      readOnly={
                        asrHandoffComplete && digitalSession?.contact.phoneNumber
                      }
                    />
                    {errors.displayTn && (
                      <label
                        id="phone-error"
                        htmlFor="displayTn"
                        className="validation-error"
                      >
                        {
                          checkoutContent?.customerInformation
                            ?.mobilePhoneNumberIncorrectFormatErrorMessage
                        }
                      </label>
                    )}
                  </div>
                </div>
                <div className="cart__display-large">
                  <hr />
                  <div className="card card--clear card--small card--right">
                    <input
                      value={cartContent?.cartModule?.nextButtonLabel}
                      type="button"
                      data-testid="handle-Next"
                      className="button button--inline"
                      onClick={() => handleNext()}
                      data-tracking={digitalDataButtonClick('handle-next', cartContent?.cartModule?.nextButtonLabel)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="cart__display-large main__desktop-sidebar-right">
          <Cart />
        </div>
      </div>
      <div className="cart__display-small-medium">
        <StickyCart handleNext={handleNext} />
      </div>
      <TrackPageComplete />
    </BuyFlowLayout>
  );
};

export default withBuyflow(BuyflowCustomerInfo);
