import { digitalDataLinkClick } from "../../utils/adobe-analytics"

interface ComponentProps {
    locale?: String
    disableLogout?: any
}

function ReconnectHeader({locale, disableLogout}: ComponentProps) {
    const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
    return (
        <div className="header">
            <div className="header__width-constraint">
                <div className="header__left">
                    <div className="xfinity-logo">
                        <span className="vh">xfinity</span>
                    </div>
                </div>
                <nav className="header__right" aria-label="Utility Navigation">
                    <div className="header-language-switch">
                        {!disableLogout &&
                            <a href={logoutURL} data-tracking={digitalDataLinkClick('logout', 'https://oauth.xfinity.com/oauth/sp-logout?client_id=xfinity_prepaid_digital', 'Log out')}>Log out</a>
                        }
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default ReconnectHeader
