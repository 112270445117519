import React, { useEffect, useState } from 'react'
import ProgressSpinner from '../../components/activation/ActivateProgressSpinner'
import Accordion from '../../components/Accordion'
import ActivationSuccessBanner from '../../components/activation/ActivationSuccessBanner'
import withActivation from '../../redux/connectors/activation'
import axios from 'axios';
import { Helmet } from 'react-helmet'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'
import { digitalDataAPIError } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const Activate = (props) => {
    const {
        locale,
        accountId,
        // custGuid,
        sitecoreContent,
        fetchedContent,
        dashboard,
        activationStatus,
        activatePrepaidAccount,
        activatedPrepaidAccount,
        activatingPrepaidAccount,
        activatingPrepaidAccountFailed,
        restartDevice,
        restartedDevice,
        restartingDevice,
        macAddress,
        ready,
        behindTheModem,
        history,
        isVanAccount,
        firstName,
        lastName,
    } = props;


    // const [inApp, setInApp] = useState(false)
    // const router = useRouter()
    const [showSuccessBanner, updateShowSuccessBanner] = useState(false)
    const [displayPromoModal, setDisplayPromoModal] = useState(false)
    // const clientSide = (typeof window !== "undefined")

    const goToDashboard = () => {
        const flow = sessionStorage.getItem('activationFlow')
        trackEvent({
            event: 'activation flow',
            activateStep: 'step 3',
            activateStepName: 'activation complete',
            activateStepDetail: flow
        })

        // let dashboardUrl = `/${locale}/dashboard`

        // URL params not needed
        // dashboardUrl += `?accountId=${btoa(accountId)}&guid=${btoa(custGuid)}`
        // if (inApp) {
        //     dashboardUrl += '&mobile=true'
        // }

        // ACP Intercept Modal trigger
        setDisplayPromoModal(true)
        // history.replace(dashboardUrl)
    }

    const closeModal = () => {
       history.push('/' + locale + '/dashboard')
    }

    useEffect(() => {
        // if (clientSide) {

        //     let urlParams = new URLSearchParams(clientSide ? window.location.search : '');

        //     const m = urlParams.get('mobile')
        //     if (m) {
        //         setInApp(true)
        //     }
        // }

        // redirect back to /activation
        if (!ready) {
            history?.replace(`/${locale}/activation`)
        }

        if (ready) {

            let data;

            if (dashboard?.auxSubsPlans) {
                data = {
                    accountId: accountId,
                    channel: "Online",
                    devices: dashboard.auxSubsPlans.map(subscription => {
                        let deviceSerial = ''
                        if (subscription.planLob === 'HSD') {
                            let hsdSub = dashboard.subscriptionDetails.find(sub => sub.deviceLob === 'HSD')
                            if (hsdSub) {
                                deviceSerial = hsdSub.deviceSerialNumber || macAddress
                            }
                        }
                        return {
                            deviceSerial, // not required for XITV products
                            prepaidProducts: [
                                {
                                    lob: subscription.planLob,
                                    productCode: subscription.productCode,
                                }
                            ]
                        }
                    })
                }
            } else {
                data = {
                    accountId: accountId,
                    channel: "Online",
                    devices: dashboard.subscriptionDetails?.map(subscription => {
                        return {
                            deviceSerial: subscription.deviceSerialNumber || macAddress, // not required for XITV products
                            prepaidProducts: subscription.planDetails.map(item => {
                                return {
                                    // activationCode: "string", // not required
                                    // serviceCredit: 0, // not required
                                    lob: item.planLob,
                                    productCode: item.productCode,
                                }
                            })
                        }
                    })
                }
            }

            if (isVanAccount) {
                let vanData = {
                    ...data,
                    updateCustomerInfo: true,
                    customerInfo: {
                        firstName: firstName,
                        lastName: lastName,
                        langPref: locale === 'es' ? 'SPANISH' : 'ENGLISH'
                    }
                }
                console.log('Activation request payload', vanData)
                activatePrepaidAccount(vanData)
            } else {
                console.log('Activation request payload', data)
                activatePrepaidAccount(data)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (activatingPrepaidAccountFailed) {
            console.error('Activation failed')
            history?.replace(`/${locale}/activation/error`)
        }

        if (activatedPrepaidAccount) {

            if (restartedDevice) {
                if (behindTheModem) {
                    console.log('Should start pinging for internet connection...')
                    let pinger = setInterval(() => {
                        axios.get('https://www.xfinityprepaid.com/ping.json') // https://json.link/y38uvgiy7a.json
                            .then(response => {
                                console.log('Ping response', response)
                                if (response.status === 200) {
                                    clearInterval(pinger)
                                    goToDashboard();
                                }
                            })
                            .catch(error => {
                                digitalDataAPIError('ping', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.alert-banner');
                                console.warn('Ping request failed', error)
                            })
                    }, 10000)
                } else {
                    updateShowSuccessBanner(true)
                    // we should delay sending the user to dashboard by a second or two so they have time
                    // to see the success banner.  The success banner was not implemented on .net.
                    setTimeout(() => {
                        updateShowSuccessBanner(false)
                        goToDashboard();
                    }, 1000)
                }
            } else {
                console.log('Restarting device...')
                restartDevice({accountId, macAddress})
            }
        }
    }, [activationStatus, activatedPrepaidAccount, activatingPrepaidAccountFailed, restartedDevice]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderSpinnerContent = (screen) => {
        return (
            '<b>' + screen?.loadingScreenText + '</b> <br />' + screen?.loadingScreenWarning
        )
    }
    const renderAlertBanner = (activateProgressConnect) => {
        if (behindTheModem) {
            return (
                <div className="alert-banner alert-banner__bottom">
                    <div className="main__width-constraint">
                        <img alt="" src='/site-assets/img/icon-alert.svg'></img>
                        <div className="alert-banner-title">
                            {activateProgressConnect?.alert?.message}
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
    const renderSuccessBanner = (activateProgressConnect) => {
        if (showSuccessBanner) {
            return (
                <ActivationSuccessBanner text={activateProgressConnect?.isSuccess} />
            )
        } else {
            return null
        }
    }


    const content = fetchedContent ? sitecoreContent?.activation.activationFlow.activateProgressConnect : {}

    let spinnerLoadingText = ''

    if (fetchedContent) {
        if (activatingPrepaidAccount) {
            spinnerLoadingText = renderSpinnerContent(content?.loadingScreenConnecting)

        } else if (restartingDevice) {
            spinnerLoadingText = renderSpinnerContent(content?.loadingScreenRestarting)
        } else if (behindTheModem && activatedPrepaidAccount && restartedDevice) {
            spinnerLoadingText = renderSpinnerContent(content?.loadingScreenActivating)
        }
    }

    return (
        !fetchedContent ? <div /> :
        <div>
            <TrackUserData data={dashboard} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Activation - Xfinity Prepaid</title>
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <main>
                {renderSuccessBanner(content)}
                <div id="main" role="main" className="main activating-page">
                    <div className="main__width-constraint">
                       {spinnerLoadingText !=="" ? <><ProgressSpinner dark={false} loadingText={spinnerLoadingText} /><div className="text-center" dangerouslySetInnerHTML={{ __html: content?.loadingScreenActivating?.loadingScreenDescription }} /></>
                       :
                       <div className='align-center'>
                         <button
                        data-testid="btn-dashboard"
                        className="button"
                        onClick={closeModal}
                        data-tracking={digitalDataButtonClick('continue-to-dashboard', 'Return to Dashboard')}
      >
        Return to Dashboard
      </button>
                   </div>
                   }
                       
                        <Accordion items={content?.activationSupport?.faQs} title={content?.activationSupport?.title} eventObj={{event: 'accordion', accordionPage: 'activation flow step 2 activate service', accordionType: "faq", faqSubcategory: content?.activationSupport?.title}} eventDetailCategory='accordionText' />
                    </div>
                    {renderAlertBanner(content)}
                </div>
            </main>
            <TrackPageComplete />
        </div>

    )
}

export default withActivation(Activate);