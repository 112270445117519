import * as React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
// import { Home } from './components/Home'
import AccountPicker from './pages/account-picker'
import Dashboard from './pages/dashboard'
import { DollarGeneral } from './pages/dollargeneral'
import { BuyFlowRouteCheck } from './pages/buyflow'
import BuyFlowInternet from './pages/buyflow/internet'
import Session from './pages/buyflow/session'
import BuyflowCustomerInfo from './pages/buyflow/customer-info'
import BuyflowOrderDetails from './pages/buyflow/order-details'
import BuyflowOrderReview from './pages/buyflow/order-review'
import BuyflowOrderConfirmation from './pages/buyflow/order-confirmation'
import BuyflowError from './pages/buyflow/error'
import BuyflowNotFound from './pages/buyflow/404'
import DirectPromo from './pages/buyflow/direct-promo'
import Move from './pages/move'
import MoveNow from './pages/move/move-now'
import MoveNowConfirmation from './pages/move/now-confirmation'
import AddressConfirmation from './pages/move/address-confirmation'
import MoveSchedule from './pages/move/move-schedule'
import MoveScheduleNow from './pages/move/move-schedule-now'
import MoveScheduleFinalize from './pages/move/move-schedule-finalize'
import MoveScheduleConfirmation from './pages/move/move-schedule-confirmation'
import MoveRefillConfirmation from './pages/move/move-refill-confirmation'
import MoveRefillRequired from './pages/move/move-refill-required'
import MoveRefillPayment from './pages/move/move-refill-payment'
import MoveRefillReview from './pages/move/move-refill-review'
// import MoveCancel from './pages/move/move-cancel'
import AccountInformation from './pages/dashboard/account-information'
import ActivationHome from './pages/activation'
import ActivationActivate from './pages/activation/activate'
import ActivationError from './pages/activation/error'
import ActivationPermissionError from './pages/activation/permission-error'
import AutoRefill from './pages/dashboard/auto-refill'
import DetailHsd from './pages/dashboard/detail-hsd'
import Notifications from './pages/dashboard/notifications'
import PaymentHistory from './pages/dashboard/payment-history'
// import ServicesPaused from './pages/dashboard/services-paused'
import EditPayment from './pages/dashboard/edit-payment'
import Refill from './pages/refill'
import RefillPayment from './pages/refill/payment'
import RefillReview from './pages/refill/review'
import RefillConfirmation from './pages/refill/confirmation'
import { Handoff } from './pages/handoff'
import PermissionError from './pages/permission-error'
import TimeoutError from './pages/timeout-error'
import NotFoundError from './pages/404'
import GenericError from './pages/error'
import withBuyflow from './redux/connectors/buyflow'
import Reconnect from './pages/reconnect'
import Litepay from './pages/litepay'
import LitepayRefill from './pages/litepay/refill'
import LitepayOrderConfirmation from './pages/litepay/order-confirmation'
import TrackingData from './utils/TrackingData';
import { getLanguage, getScreenName, getReleaseVersion, getSubCategories } from "./utils/adobe-analytics";
import TrackPageStart from './utils/TrackPageStart';
const BuyFlowRouteCheckRedux = withBuyflow(BuyFlowRouteCheck)

export const AppRouter: React.FC = (props) => {
  const getTrackingInfo = (buyflowType?, buyflowStep?) => {
    return {
      schemaVersion: "0.2",
      page: {
        affiliate: {
          channel: "web",
          name: "comcast",
        },
        category: {
          businessType: "resi",
          designType: "responsive",
          primaryCategory: "prepaid",
          siteType: "selfservice",
          subCategory1: getSubCategories()[0] || "",
          subCategory2: getSubCategories()[1] || "",
          workFlowIdentifier: "prepaid",
        },
        codebase: {
          name: "prepaid",
          releaseVersion: getReleaseVersion(),
        },
        pageInfo: {
          language: getLanguage(),
          screenName: getScreenName(),
        },
      },
      siteInfo: {
        server: window.location.href,
        visitorId: "", // TBD
        sessionId: "", // TBD
      },
      transaction: {
        attributes: {
          buyflowType,
          buyflowStep,
        },
      }
    };
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
            <Route exact path="/" render={(props) =>
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Handoff locale="en" {...props} />
              </>
            } />
            <Route exact path="/en/handoff" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Handoff locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/handoff" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Handoff locale="es" {...props} />
              </>
            } />

            <Route exact path="/en/account-picker" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AccountPicker locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/account-picker" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AccountPicker locale="es" {...props} />
              </>
            } />
            {/* ACTIVATION */}
            <Route exact path="/en/activation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationHome locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/activation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationHome locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/activation/activate" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationActivate locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/activation/activate" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationActivate locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/activation/permission-error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationPermissionError locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/activation/permission-error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationPermissionError locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/activation/error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationError locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/activation/error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ActivationError locale="es" {...props} />
              </>
            } />
            {/* DASHBOARD */}
            <Route exact path="/en/dashboard" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Dashboard locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Dashboard locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/account-information" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AccountInformation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/account-information" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AccountInformation locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/auto-refill" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AutoRefill locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/auto-refill" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AutoRefill locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/detail-hsd" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <DetailHsd locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/detail-hsd" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <DetailHsd locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/edit-payment" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <EditPayment locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/edit-payment" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <EditPayment locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/payment-history" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <PaymentHistory locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/payment-history" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <PaymentHistory locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/notifications" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Notifications locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/notifications" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Notifications locale="es" {...props} />
              </>
            } />

            {/* DASHBOARD MOVE PAGES */}
            {/* <Route exact path="/en/dashboard/services-paused" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ServicesPaused locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/services-paused" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <ServicesPaused locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/dashboard/cancel-move" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveCancel locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dashboard/cancel-move" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveCancel locale="es" {...props} />
              </>
            } /> */}

            {/* MOVE FLOW */}
            <Route exact path="/en/move/" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'cart')}
                />
                <Move locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'cart')}
                />
                <Move locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/address-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AddressConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/address-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <AddressConfirmation locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/now" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveNow locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/now" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveNow locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/now-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveNowConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/now-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveNowConfirmation locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/schedule" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveSchedule locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/schedule" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveSchedule locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/schedule-now" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveScheduleNow locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/schedule-now" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveScheduleNow locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/schedule-finalize" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveScheduleFinalize locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/schedule-finalize" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveScheduleFinalize locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/schedule-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveScheduleConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/schedule-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <MoveScheduleConfirmation locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/refill-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'order-confirmation')}
                />
                <MoveRefillConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/refill-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'order-confirmation')}
                />
                <MoveRefillConfirmation locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/refill-payment" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'payment')}
                />
                <MoveRefillPayment locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/refill-payment" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'payment')}
                />
                <MoveRefillPayment locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/refill-required" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid','cart')}
                />
                <MoveRefillRequired locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/refill-required" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid','cart')}
                />
                <MoveRefillRequired locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/move/refill-review" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'order-review')}
                />
                <MoveRefillReview locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/move/refill-review" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillmoveflow|prepaid', 'order-review')}
                />
                <MoveRefillReview locale="es" {...props} />
              </>
            } />

            {/* REFILL */}
            <Route exact path="/en/refill" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'cart')}
                />
                <Refill locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/refill" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'cart')}
                />
                <Refill locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/refill/payment" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'payment')}
                />
                <RefillPayment locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/refill/payment" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'payment')}
                />
                <RefillPayment locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/refill/review" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'order-review')}
                />
                <RefillReview locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/refill/review" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'order-review')}
                />
                <RefillReview locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/refill/confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'order-confirmation')}
                />
                <RefillConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/refill/confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('refillflow|prepaid', 'order-confirmation')}
                />
                <RefillConfirmation locale="es" {...props} />
              </>
            } />


            {/* DOLLAR GENERAL */}
            <Route exact path="/en/dollargeneral" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <DollarGeneral locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/dollargeneral" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <DollarGeneral locale="es" {...props} />
              </>
            } />

            {/* BUY FLOW */}
            <Route exact path="/en/buy" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyFlowRouteCheckRedux locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyFlowRouteCheckRedux locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/internet" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'internet')}
                />
                <BuyFlowInternet locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/internet" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'internet')}
                />
                <BuyFlowInternet locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/session" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Session locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/session" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Session locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/customer-info" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyflowCustomerInfo locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/customer-info" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyflowCustomerInfo locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/order-details" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'payment-shipping')}
                />
                <BuyflowOrderDetails locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/order-details" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'payment-shipping')}
                />
                <BuyflowOrderDetails locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/order-review" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'order-review')}
                />
                <BuyflowOrderReview locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/order-review" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'order-review')}
                />
                <BuyflowOrderReview locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/order-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'order-confirmation')}
                />
                <BuyflowOrderConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/order-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('buyflow|prepaid', 'order-confirmation')}
                />
                <BuyflowOrderConfirmation locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyflowError locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyflowError locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/404" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyflowNotFound locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/404" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <BuyflowNotFound locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/buy/direct-promo" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <DirectPromo locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/buy/direct-promo" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <DirectPromo locale="es" {...props} />
              </>
            } />

            {/* RECONNECT */}
            <Route exact path="/en/reconnect" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('reconnectflow|prepaid')}
                />
                <Reconnect locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/reconnect" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('reconnectflow|prepaid')}
                />
                <Reconnect locale="es" {...props} />
              </>
            } />

            {/* LITEPAY */}
            <Route exact path="/en/litepay" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Litepay locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/litepay" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <Litepay locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/litepay/refill" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('litepayrefillflow|prepaid', 'find-your-account')}
                />
                <LitepayRefill locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/litepay/refill" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('litepayrefillflow|prepaid', 'find-your-account')}
                />
                <LitepayRefill locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/litepay/order-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('litepayrefillflow|prepaid', 'order-confirmation')}
                />
                <LitepayOrderConfirmation locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/litepay/order-confirmation" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo('litepayrefillflow|prepaid', 'order-confirmation')}
                />
                <LitepayOrderConfirmation locale="es" {...props} />
              </>
            } />

            {/* ERROR PAGES */}
            <Route exact path="/en/permission-error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <PermissionError locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/permission-error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <PermissionError locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/timeout-error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <TimeoutError locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/timeout-error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <TimeoutError locale="es" {...props} />
              </>
            } />
            <Route exact path="/en/error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <GenericError locale="en" {...props} />
              </>
            } />
            <Route exact path="/es/error" render={(props) => 
              <>
                <TrackPageStart />
                <TrackingData
                  trackingInfo={getTrackingInfo()}
                />
                <GenericError locale="es" {...props} />
              </>
            } />
            <Route path="*" component={NotFoundError} />
        </Switch>
      </BrowserRouter>
    </>
  )
}