import withDashboard from '../../redux/connectors/dashboard'

const InfoStripe = (props) => {

    let {
        Title,
        Text,
    } = props

    return (
        <div className="info-stripe">
            <div className="main__width-constraint">
                <span className="info-stripe__title">{Title}</span>
                <div className="info-stripe__text" dangerouslySetInnerHTML={{__html: Text }} />
            </div>
        </div>
    )
}

export default withDashboard(InfoStripe)