import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import { trackEvent } from '../utils/utils'
import { digitalDataButtonClick, digitalDataModalOpen } from '../utils/adobe-analytics'
interface ComponentProps {
    id?: string
    ctaText?: any
    title?: string
    body?: string
    buttonClasses?: string
    productName?: string
    modalType?: string
    toolTip?: string
    formToolTip?: string
    formToolTipText?: string
    isVerificationModal?: string
    verificationModalText?: string
    modalCloseButton?: string
    component?: any
    modalWrapClasses?: string
    modalClasses?: string
    type?: string
    isMoveCancel?: string,
    cancelMove?: Function,
    isMoveSchedule?: string,
    onOpen?: Function,
    showTooltip?: Boolean

}

const GlobalModal = (props: ComponentProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const defaults = {
        id: 'Modal Title',
        ctaText: 'Open',
        title: '',
        body: '',
        buttonClasses: 'link-callout',
        productName: '',
        modalType: '',
        toolTip: '',
        isVerificationModal: false,
        verificationModalText: '',
        modalCloseButton: 'Close',
        modalWrapClasses: '',
        modalClasses: '',
        type: '',
        isMoveCancel: 'false',
        isMoveSchedule: 'false',
        onOpen: null,
        showTooltip: false
    }

    const {
        id,
        ctaText,
        title,
        body,
        buttonClasses,
        isVerificationModal,
        verificationModalText,
        modalCloseButton,
        component,
        modalWrapClasses,
        modalClasses,
        type,
        isMoveCancel,
        isMoveSchedule,
        onOpen,
        showTooltip,
        toolTip

    } = {...defaults, ...props}

    const node = useRef<HTMLDivElement | null>(null)

    const openModal = () => {
        setModalIsOpen(true)
        if (onOpen !== null) {
            onOpen()
        }
        // document.body.className += ' modal-open'
        document.body.classList.add('modal-open')
        if (type === 'move') {
            trackEvent({
                event: 'move info',
                moveStep: 'step 2',
                moveStepName: 'move review',
                moveEvent: 'services component',
                moveDetail: 'what does this mean'
            })
        }
        if (type === 'reconnect-dashboard') {
            trackEvent({
                event: 'dashboard',
                dashboardPage: 'reconnect',
                dashboardEvent: 'product component',
                dashboardDetail: 'what does this mean'
            })
        }
        if (type === 'reconnect-pricing-30') {
            trackEvent({
                event: 'reconnect info',
                reconnectStep: 'step 1',
                reconnectStepName: 'start reconnect',
                reconnectEvent: 'pricing & other info',
                reconnectDetail: '30 days internet'
            })
        }
        if (type === 'reconnect-pricing-7') {
            trackEvent({
                event: 'reconnect info',
                reconnectStep: 'step 1',
                reconnectStepName: 'start reconnect',
                reconnectEvent: 'pricing & other info',
                reconnectDetail: '7 days internet'
            })
        }
        if (type === 'moveConfirmationCancel') {
            trackEvent({
                event: 'move info',
                moveStep: 'step 4',
                moveStepName: 'move complete',
                moveEvent: 'request details component',
                moveDetail: 'cancel move request'
            })
        }
        if (type === 'moveNotificationCancel') {
            trackEvent({
                event: 'dashboard',
                dashboardPage: 'scheduled move',
                dashboardEvent: 'request details component',
                dashboardDetail: 'cancel move request'
            })
        }
        if (type === 'expired') {
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'services component',
                dashboardDetail: 'what does this mean'
            })
        }
        if (type.includes('packagePricing')) {
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'pricing & other info',
                dashboardDetail: type.replace('packagePricing', '')
            })
        }
        if (type === 'macAddress') {
            trackEvent({
                event: 'activation info',
                activateStep: 'step 1',
                activateStepName: 'start activation',
                activateEvent: 'locate modem',
                activateDetail: 'where can I find my mac address'
            })
        }
        if (type === 'activationFurtherSupport') {
            trackEvent({
                event: 'activation info',
                activateStep: 'step 1',
                activateStepName: 'start activation',
                activateEvent: 'locate modem',
                activateDetail: "further suport & information"
            })
        }
        if (type === 'litepayAccountError') {
            trackEvent({
                event: 'litepay info',
                litepayStep: 'step 1',
                litepayStepName: 'find your account',
                litepayEvent: 'trouble locating account',
                litepayDetail: ctaText
            })
        }
    }

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false);
            // document.body.className = document.body.className.replace(new RegExp(/modal-open/g), '')
            document.body.classList.remove('modal-open')
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, []) // eslint-disable-line

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }
    const cancelMove = () => {
        // cancel move logic
        console.log('cancel move')
        if (type === 'moveConfirmationCancel') {
            trackEvent({
                event: 'move info',
                moveStep: 'step 4',
                moveStepName: 'move complete',
                moveEvent: 'request details component',
                moveDetail: 'cancel success'
            })
        }
        if (type === 'moveNotificationCancel') {
            trackEvent({
                event: 'dashboard',
                dashboardPage: 'scheduled move',
                dashboardEvent: 'request details component',
                dashboardDetail: 'cancel move success'
            })
        }
        if (props.cancelMove) {
            props.cancelMove()
        }
        closeModal()
    }
    
    const onButtonClick = () => {
        digitalDataButtonClick(
          ctaText,
          ctaText ,
          true,
          undefined, 
          true, 
          "." + buttonClasses)
    }

    const renderButton = () => {
        if (isVerificationModal) {
            return(
                <button data-testid="open-01" data-a11y-dialog-show={id} type="button" className="cima-label cima-unverified" onClick={openModal} data-tracking={digitalDataModalOpen('cima-verification')}>
                    <span className="vh" dangerouslySetInnerHTML={{__html: verificationModalText}} />
                    { ctaText }
                    <img src="/site-assets/img/icon-info.svg" alt="" title="tooltip" />
                </button>
            )
        } else {
            return (
                <button data-a11y-dialog-show={id} type="button" className={buttonClasses} onClick={()=>{onButtonClick(); openModal()}} data-tracking={digitalDataModalOpen('cima-verification')}>{ ctaText }</button>
            )
        }
    }
    return (
        <div
            className={`modal-item ${showTooltip  ? "tooltip-parent" : ""} ${modalWrapClasses}`}
            onClick={stopPropagation}
        >
            { renderButton() }
            {
                toolTip ? 
                <div className="tooltip" dangerouslySetInnerHTML={{__html: toolTip}} />
                : null
            }
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className={modalClasses ? 'modal modal--visible ' + modalClasses : 'modal modal--visible'}
            overlayClassName="modal__overlay"
            >
                <div className="modal__content">
                    <div ref={node} className="modal__body">
                        {title &&
                            <h3 id="modal__title" tabIndex={0} className="modal__title">{ title }</h3>
                        }

                        {body &&
                            <div className="modal__body__content" dangerouslySetInnerHTML={{__html: body}} />

                        }

                        {isMoveSchedule === 'true' &&
                            <div className="move-modal">
                                <h1 className="move-modal--title">Welcome back Vanessa</h1>
                                <hr></hr>
                                <div className="move-modal--subtitle">Move Scheduled</div>
                                    <div className="move-modal--addresses">
                                        <b>To Address</b><br></br>
                                        <span>7014 A Mill Creek Road</span><br></br>
                                        <span>Levittown, </span><span>PA</span> <span>19057</span>
                                        <p></p>
                                        <b>From Address</b><br></br>
                                        <span>7014 A Mill Creek Road</span><br></br>
                                        <span>Levittown, </span><span>PA</span> <span>19057</span>
                                    </div>
                                    <p></p>



                                    <div className="section">
                                        <div className="address">
                                            <div><b>Move Date</b> <button className="btn-date button--link" data-tracking={digitalDataButtonClick('edit-date', 'Edit Date')}>Edit Date</button></div>
                                            <span>Wednesday, May 12, 2021</span>
                                        </div>

                                    </div>

                                <hr></hr>
                                <div className="button--container">
                                    <button onClick={closeModal} className="button--link" data-tracking={digitalDataButtonClick('close-modal', 'CANCEL THIS MOVE', true)}>CANCEL THIS MOVE</button>
                                </div>
                            </div>
                        }

                        {isMoveCancel === 'true' &&
                            <div>

                                <div className="button--container">
                                    <button className="button" onClick={cancelMove} data-tracking={digitalDataButtonClick('move-cancel-yes', 'Yes, Cancel')}>Yes, Cancel</button>
                                </div>
                                <div className="button--container">
                                    <button onClick={closeModal} className="button--link" data-tracking={digitalDataButtonClick('move-cancel-yes', 'No, keep Move Request')}>No, keep Move Request</button>
                                </div>
                            </div>
                        }

                        {component &&
                            <div className="modal__component__wrapper">
                                {component}
                            </div>
                        }

                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-dialog', 'Close this dialog window')}>
                            { modalCloseButton }
                            <img title="close" alt="" className="icon" src='/site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')
export {
    GlobalModal
}