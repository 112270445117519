import { useState, useEffect } from 'react'
import AddressCheck from '../merchant/AddressCheck'
import ValidateAddress from '../merchant/ValidateAddress'
import { useHistory } from 'react-router-dom'
import MultiAddressList from '../merchant/MultiAddressList'
import ExistingAccount from '../merchant/ExistingAccount'
import Unserviceable from '../merchant/Unserviceable'
import withDashboard from '../../redux/connectors/dashboard'

const CheckAvailability = (props) => {
    const {
        isMove,
        content,
        isTestFile,
    } = props

    const [stage, setStage] = useState('addressCheck')
    const [addressData, setAddressData] = useState({})
    const [addressList, setAddressList] = useState([])
    const [searchAddress, setSearchAddress] = useState({})
    const [selectedLocation, setSelectedLocation] = useState({})
    const [errorType, setErrorType] = useState(false)

    const history = useHistory()
    const locale = 'en'

    // for any general updates that need to happen when the stage is changed
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [stage, props])

    useEffect(() => {
        if (!errorType && stage === 'serviceable') {
            history?.push('/' + locale + '/move/address-confirmation')
        }
        // eslint-disable-next-line
    }, [errorType, stage])

    //TODO: determine the needed differences between the dollar general UX / messaging & the move flow, apply the changes with the isMove flag

    switch (stage) {

        case 'addressCheck':
            return <AddressCheck isTestFile={isTestFile} isMove={isMove} setStage={setStage} content={content} addressData={addressData} searchAddress={searchAddress} setSearchAddress={setSearchAddress} setAddressData={setAddressData} setSelectedLocation={setSelectedLocation} errorType={errorType} setErrorType={setErrorType} />

        case 'validateAddress':
            return <ValidateAddress isTestFile={isTestFile} isMove={isMove} setStage={setStage} setAddressList={setAddressList} content={content} searchAddress={searchAddress} addressData={addressData} setAddressData={setAddressData} selectedLocation={selectedLocation} setErrorType={setErrorType} />

        case 'addressMultiUnit':
            return <MultiAddressList setStage={setStage} content={content} setSearchAddress={setSearchAddress} setAddressData={setAddressData} setSelectedLocation={setSelectedLocation} addressList={addressList} />

        case 'serviceable': 
            break

        case 'unserviceable':
            return <Unserviceable isMove={isMove} setStage={setStage} content={content} addressData={addressData} />

        case 'existingPrepaidAccount':
            return <ExistingAccount isMove={isMove} setStage={setStage} content={content} accountType="prepaid" addressData={addressData} />

        case 'existingPostpaidAccount':
            return <ExistingAccount isMove={isMove} setStage={setStage} content={content} accountType="postpaid" addressData={addressData} />

        default:
            return null

    }
    return (
        null
    )
}

export default withDashboard(CheckAvailability)