import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Accordion from '../Accordion'
import MerchantModal from './MerchantModal'
import parse, { attributesToProps, domToReact } from 'html-react-parser'
import { Element } from 'domhandler/lib/node'
import axios from 'axios'
import { trackEvent } from '../../utils/utils'
import { digitalDataAPIError, digitalDataButtonClick, digitalDataLinkClick, digitalDataValidationError } from '../../utils/adobe-analytics'

const AddressCheck = ({isMove, setStage, content, addressData, setAddressData, searchAddress, setSearchAddress, setSelectedLocation, errorType, setErrorType, isTestFile}) => {

    // initalize react-hook-form
    const { handleSubmit, register, errors, setValue, trigger } = useForm()

    // set up FAQs
    const [helpText, setHelpText] = useState(<div/>)
    const [faqModalContent, setFaqModalContent] = useState(<div/>)
    const faqSetup = () => {
        // convert FAQ content
        const convertToAccordionItemArray = arr => {
            const itemArr : any[] = []
            for (let item of arr) {
                // in order to attach analytics events to pre-fetched content need to use html-react-parser
                let parsedAnswer = parse(item.answer, {
                    replace: domNode => {
                        if (domNode instanceof Element && domNode?.attribs && domNode?.attribs?.href) {
                            const props = attributesToProps(domNode?.attribs)
                            // check for links that need analytics
                            if (domNode?.attribs?.href.includes('xfinityassistant')) {
                                return <a {...props} onClick={() => trackEvent({
                                    event: 'blind sale serviceability check',
                                    blindSaleEvent: 'serviceability flow',
                                    blindSaleDetail: 'Chat'
                                })} data-tracking={digitalDataLinkClick('blind-sale-serviceability-chat', domNode.attribs.href, 'Chat')}>{domToReact(domNode.children)}</a>
                            }
                            else if (domNode?.attribs?.href.includes('tel')) {
                                return <a {...props} onClick={() => trackEvent({
                                    event: 'blind sale serviceability check',
                                    blindSaleEvent: 'serviceability flow',
                                    blindSaleDetail: 'Call'
                                })} data-tracking={digitalDataLinkClick('blind-sale-serviceability-call', domNode.attribs.href, 'Call')}>{domToReact(domNode.children)}</a>
                            }
                        }
                    }
                })
                // create the Q & A array for the accordion component
                itemArr.push({
                    question: item?.question,
                    renderedContent: parsedAnswer
                })
            }
            return itemArr
        }
        const FAQs = !isTestFile && convertToAccordionItemArray(content?.servicibilityCheck?.faqModal?.faqs)

        // content for FAQ modal
        const modalContent = (
            <>
                <h2>{content?.servicibilityCheck?.faqModal?.title}</h2>
                <div dangerouslySetInnerHTML={{__html: content?.servicibilityCheck?.faqModal?.text}} />
                <Accordion items={FAQs} eventObj={{event: 'accordion', accordionType: 'faq', accordionPage: 'dollargeneral', faqSubcategory: content?.servicibilityCheck?.faqModal?.title}} eventDetailCategory='accordionText' />
            </>
        )
        setFaqModalContent(modalContent)

        // logic for swapping FAQ modal link into content
        const processHelpText = text => {
            return (<div className="merchant-prepaid__helptext">{
                !isTestFile && parse(text, {
                    replace: domNode => {
                        if (domNode instanceof Element && domNode?.attribs && domNode?.attribs?.href === '#faq-modal') return (
                            <MerchantModal content={modalContent} ctaLabel={domNode?.children[0]['data']}
                                closeBtnTxt={content?.dictionary?.global?.close} ctaClass="merchant-prepaid__bodylink"
                                eventObj={{event: 'blind sale serviceability check', blindSaleEvent: 'serviceability flow'}} eventDetailCategory='blindSaleDetail' />
                        )
                }})
            }</div>)
        }
        setHelpText(processHelpText(content?.servicibilityCheck?.introSubText))
    }

    // action when Check Availability form is submitted
    //TODO MOVE: append isMove to account for new flow
    const submitAddress = values => {
        setSearchAddress({
            address: values?.serviceAddress,
            unit: values?.serviceApt,
            zip: values?.serviceZipCode
        })
        setAddressData(values)
        setErrorType(false)
        setStage('validateAddress')
    }

    // search logic
    const [searchTerm, setSearchTerm] = useState('')
    const [zip, setZip] = useState('')
    const [matches, setMatches] = useState<Array<any>>([])
    const [expanded, setExpanded] = useState(false)
    const [addressIndex, setAddressIndex] = useState(-1)

    useEffect(() => {
        const ADDRESS_SEARCH_API = process.env.REACT_APP_ADDRESS_SEARCH_API
        // initiate search if we have a value in search input above 1 character
        if (searchTerm && searchTerm.replace(/\s+/g, '').length > 1) {
            const delayDebounceFn = setTimeout(() => {
                // empty out results
                setMatches([])
                // api request
                axios.get(ADDRESS_SEARCH_API + '?q=' + searchTerm)
                .then((response) => {
                    // skip first two items – they are the original address and a separator
                    let addresses = response.data.slice(2);
                    // filter out items that are just "N" which appears to be a separator
                    addresses = addresses.filter(addr => { return addr !== 'N' })
                    // only use unique addresses
                    addresses = addresses.filter((value, index, self) => { return self.indexOf(value) === index })
                    // process matches if we have them
                    let splitMatches : any[] = []
                    if (addresses.length > 0) {
                        const topMatches = addresses.slice(0,5)
                        for (let match of topMatches) {
                            let parts = match.split(',')
                            let obj = { fullAddress: match, streetAddress: match, cityStateZip: null }
                            if (parts.length > 2) {
                                obj.streetAddress = parts.slice(0, parts.length - 2).join(', ')
                                obj.cityStateZip = parts.slice(parts.length - 2).join(', ')
                            }
                            splitMatches.push(obj)
                        }
                    }
                    // add matches globally
                    setMatches(splitMatches)
                }).catch(error => {
                    digitalDataAPIError('addressSearch', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.merchant-prepaid__start');
                })
            }, 300)
            return () => clearTimeout(delayDebounceFn)
        }
        // search input is empty so clear matches
        else setMatches([])
    }, [searchTerm])

    const addressSelected = index => {
        // take address and break it down
        let fullAddress = matches[index]?.fullAddress
        let parts = fullAddress.trim().split(',')
        setValue('serviceAddress', parts[0] ? parts[0].trim() : '')
        setValue('serviceApt', parts.length > 3 ? parts[1].trim() : '')
        let zips = fullAddress.match(/[0-9]{5}(-[0-9]{4})?$/)
        let thisZip = zips && zips.length > 0 ? zips[0].trim() : ''
        setValue('serviceZipCode', thisZip)
        setZip(thisZip)
        // trigger form validation
        trigger()
        // reset globals
        setAddressIndex(-1)
        setMatches([])
        setExpanded(false)
    }

    const matchList = matches.map((match, i) => {
        const selected = i === addressIndex ? 'true' : 'false'
        return (
            <li key={i} role="option"
                className={`${i === addressIndex ? 'active ' : ''} merchant-prepaid__address-autofill--option`}
                aria-selected={selected}
                onClick={() => addressSelected(i)} data-tracking={digitalDataButtonClick('merchant-prepaid-address-autofill', match.streetAddress + ' ' + match.cityStateZip)}>
                <span className="merchant-prepaid__address-autofill--street">{match.streetAddress}</span><span className="merchant-prepaid__address-autofill--city">{match.cityStateZip}</span>
            </li>
        )
    })

    const handleKeyDown = event => {
        if (event.key === 'ArrowDown') {
            if (addressIndex < matches.length) setAddressIndex(addressIndex + 1)
        }
        else if (event.key === 'ArrowUp') {
            if (addressIndex >= 0) setAddressIndex(addressIndex - 1)
        }
        else if (event.key === 'Enter') {
            event.preventDefault()
            if (addressIndex >= 0) addressSelected(addressIndex)
        }
        else if (event.key === 'Escape') {
            setAddressIndex(-1)
            setMatches([])
            setExpanded(false)
        }
    }

    // moving this check to a function instead of directly on the button so that it runs with every render
    const isDisabled = () => {
        if (!searchTerm || !zip || errors?.serviceAddress || errors?.serviceZipCode) return true
    }

    // errors
    const [errorTitle, setErrorTitle] = useState('')
    const [errorDescription, setErrorDescription] = useState('')
    const [errorImageUrl, setErrorImageUrl] = useState('')
    const [errorImageAlt, setErrorImageAlt] = useState('')
    const checkError = () => {
        const errorsObj = {
            'invalid address': 'unfoundAddress',
            'content error': 'connectionIssue',
            'connection error': 'connectionIssue',
        }
        const errorDetails = errorsObj[errorType]
        if (errorDetails) {
            setErrorTitle(content?.servicibilityCheck?.errors[errorDetails + 'Title'])
            setErrorDescription(content?.servicibilityCheck?.errors[errorDetails + 'Description'])
            setErrorImageUrl(content?.servicibilityCheck?.errors[errorDetails + 'Image']?.mobileImage?.url)
            setErrorImageAlt(content?.servicibilityCheck?.errors[errorDetails + 'Image']?.mobileImage?.alt)
        }
    }

    // initial load
    useEffect(() => {
        let dataSrc = sessionStorage.getItem('xppdata')
        if (dataSrc) {
            const data = JSON.parse(dataSrc);
            if (data.address) {
                setValue('serviceAddress', data.address)
                setSearchTerm(data.address)
                setValue('serviceApt', data.unit)
                setValue('serviceZipCode', data.zip)
                setZip(data.zip)
                const thisAddressData = {
                    serviceAddress: data.address,
                    serviceApt: data.unit,
                    serviceZipCode: data.zip
                }
                setAddressData(thisAddressData)
                // setErrorType(false)
                // setStage('validateAddress')
            }
        } else if (addressData.address) {
            setValue('serviceAddress', addressData.address)
            setSearchTerm(addressData.address)
            setValue('serviceApt', addressData.unit)
            setValue('serviceZipCode', addressData.zip)
            setZip(addressData.zip)
        } else if (searchAddress.address) {
            setValue('serviceAddress', searchAddress.address)
            setSearchTerm(searchAddress.address)
            setValue('serviceApt', searchAddress.unit)
            setValue('serviceZipCode', searchAddress.zip)
            setZip(searchAddress.zip)
        }
        checkError()
        setSelectedLocation({})
        faqSetup()
        // analytics
        if (isMove) {
            
        } else {
            trackEvent({
                event: 'blind sale serviceability check',
                blindSaleEvent: 'flow',
                blindSaleDetail: 'step 1 check availability'
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (errors?.serviceAddress || errors?.serviceZipCode) {
            digitalDataValidationError('address check', [errors?.serviceAddress ? 'serviceAddress' : '', errors?.serviceZipCode ? 'serviceZipCode' : ''], 'address check validation invalid', '.merchant-prepaid__start');
        }
    }, [errors]);

    return (
        <div className="merchant-prepaid__start">
            {isMove ?
                <section className="merchant-prepaid__banner">

                    <h1 className='merchant-prepaid__title'>{content?.checkAvailability?.firstLetsCheckYourAddress}</h1>
                    <p className="merchant-prepaid__subtitle">{content?.checkAvailability?.toMakeSureThatPrepaidServiceIsAvailable}</p>
                </section>
            : 
                <section className="merchant-prepaid__banner">
                    <h1 className='merchant-prepaid__title'>{content?.servicibilityCheck?.title}</h1>
                    <p className="merchant-prepaid__subtitle">{content?.servicibilityCheck?.introText}</p>
                    {helpText}
                </section>
            }

            {errorType &&
                <div className="merchant-prepaid__error">
                    <div className="merchant-prepaid__error--content">
                        <img src="/site-assets/img/icon-alert.svg" role="presentation" alt=""/>
                        <div className="merchant-prepaid__error--body-content">
                            <h2 className="merchant-prepaid__error--headline">{errorTitle}</h2>
                            <p>{errorDescription}</p>
                        </div>
                    </div>
                    {errorImageUrl &&
                        <div className="merchant-prepaid__error--example">
                            <img src={errorImageUrl} role="presentation" alt={errorImageAlt} />
                        </div>
                    }
                </div>
            }

            <form data-testid="stage" onSubmit={handleSubmit(submitAddress)} className="form merchant-prepaid__form">
                <div className="merchant-prepaid__white-bg">
                    <div className="merchant-prepaid__form-row">
                        <div className="merchant-prepaid__form-row--full">
                            <label htmlFor="serviceAddress">{content?.dictionary?.formLabels?.address}</label>
                            <input id="serviceAddress" name="serviceAddress" type="text" autoComplete="nope"
                                onChange={e => setSearchTerm(e.target.value)}
                                onFocus={() => setExpanded(true)}
                                onKeyDown={handleKeyDown}
                                ref={register({ required: content?.dictionary?.formLabels?.aValidAddressIsRequired })}
                            />  
                            {expanded &&
                                <ul className="merchant-prepaid__address-autofill autocomplete__list">
                                    {matchList}
                                    <li> 
                                        <MerchantModal content={faqModalContent} ctaLabel={content?.servicibilityCheck?.faqModal?.secondaryButtonText} ctaClass="merchant-prepaid__address-autofill--button"
                                            eventObj={{event: 'blind sale serviceability check', blindSaleEvent: 'serviceability flow'}} eventDetailCategory='blindSaleDetail' />
                                    </li>
                                </ul>
                            }
                            {errors?.serviceAddress && <p className="validation-error">{errors.serviceAddress?.message}</p>}
                        </div>
                    </div>
                    <div className="merchant-prepaid__form-row">
                        <div className="merchant-prepaid__form-row--half">
                            <label htmlFor="serviceApt">{content?.dictionary?.formLabels?.unit}</label>
                            <input id="serviceApt" name="serviceApt" type="text" autoComplete="nope"
                                onFocus={() => setExpanded(false)}
                                /* ref={register({ serviceApt: 'value' })} */
                                ref={register}
                            />
                        </div>
                        
                        <div className="merchant-prepaid__form-row--half">
                            <label htmlFor="serviceZipCode">{content?.dictionary?.formLabels?.ziPCode}</label>
                            <input id="serviceZipCode" name="serviceZipCode" type="text" maxLength={5} autoComplete="nope"
                                onChange={e => setZip(e.target.value)}
                                onFocus={() => setExpanded(false)}
                                ref={register({
                                    required: content?.dictionary?.formLabels?.fieldIsRequired,
                                    pattern: {
                                        value: /^[0-9]{5}?$/,
                                        message: content?.dictionary?.formLabels?.pleaseProvideAValidZipCode
                                    }
                                    })}
                            />
                            {errors.serviceZipCode && <p className="validation-error">{errors.serviceZipCode?.message}</p>}
                        </div>
                    </div>
                </div>
                <div className="merchant-prepaid__button-wrap">
                    <button className="button" type="submit" disabled={isDisabled()} data-tracking={digitalDataButtonClick('check-availability', content?.servicibilityCheck?.checkAvailabilityButtonText)}>{content?.servicibilityCheck?.checkAvailabilityButtonText}</button>
                </div>
                
            </form>
            
            {isMove ?  
                <div></div>
            :
                <a href={content?.servicibilityCheck.chatLink.link.url} target={content?.servicibilityCheck.chatLink.link.target || '_top'} className="merchant-prepaid__chat"
                onClick={() => trackEvent({ event: 'blind sale serviceability check', blindSaleEvent:'serviceability flow', blindSaleDetail:'Chat'})} data-tracking={digitalDataLinkClick('merchant-prepaid-chat', content?.servicibilityCheck.chatLink.link.url, 'blind sale serviceability check')}>
                {content?.servicibilityCheck.chatText}
                <img src="/site-assets/img/icon-chat.svg" alt="" role="presentation" className="merchant-prepaid__chat--icon"/>
            </a>
            }

        </div>
    )
}

export default AddressCheck