import axios from 'axios';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER
const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD

const headers = (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) ? {
    Authorization: `Basic ${btoa(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD)}`
} : {}

export const fetchEnglishAllContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishAllContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishDictionaryContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/dictionary`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishDictionaryContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/dictionary`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishActivationContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/activation`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishActivationContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/activation`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishDashboardContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/account-dashboard`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishDashboardContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/account-dashboard`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishCartContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/cart`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishCartContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/cart`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishBuyflowContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/buyflow`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishBuyflowContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/buyflow`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishRefillContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/refill`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishRefillContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/refill`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishMerchantContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/merchant-flow`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishMerchantContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/merchant-flow`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishMoveContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/moving`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishMoveContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/moving`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchEnglishReconnectContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/reconnect`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishReconnectContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/reconnect`
    const response = await axios.get(url, { headers })
    // console.log('content response', response.data)
    return response.data
}
