import { put, select, call, takeEvery } from 'redux-saga/effects'

import {
    fetchEnglishAllContent,
    fetchSpanishAllContent,
    fetchEnglishDictionaryContent,
    fetchSpanishDictionaryContent,
    fetchEnglishActivationContent,
    fetchSpanishActivationContent,
    fetchEnglishDashboardContent,
    fetchSpanishDashboardContent,
    fetchEnglishCartContent,
    fetchSpanishCartContent,
    fetchEnglishBuyflowContent,
    fetchSpanishBuyflowContent,
    fetchEnglishRefillContent,
    fetchSpanishRefillContent,
    fetchEnglishMerchantContent,
    fetchSpanishMerchantContent,
    fetchEnglishMoveContent,
    fetchSpanishMoveContent,
    fetchEnglishReconnectContent,
    fetchSpanishReconnectContent,
} from './apis'

import { content } from './store'
import { digitalDataAPIError } from '../../utils/adobe-analytics'

const getTrackingId = (state) => state.common.trackingId

function* fetchEnglishAllContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishAllContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishAllContent, {trackingId});
        yield put(content.actions.fetchEnglishAllContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishAllContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishAllContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishAllContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishAllContent, fetchEnglishAllContentSaga);
}

function* fetchSpanishAllContentSaga({payload}) {
    console.log('🇲🇽  fetchSpanishAllContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishAllContent, {trackingId});
        yield put(content.actions.fetchSpanishAllContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishAllContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishAllContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishAllContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishAllContent, fetchSpanishAllContentSaga);
}
function* fetchEnglishDictionaryContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishDictionaryContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishDictionaryContent, {trackingId});
        yield put(content.actions.fetchEnglishDictionaryContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishDictionaryContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishDictionaryContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishDictionaryContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishDictionaryContent, fetchEnglishDictionaryContentSaga);
}

function* fetchSpanishDictionaryContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishDictionaryContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishDictionaryContent, {trackingId});
        yield put(content.actions.fetchSpanishDictionaryContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishDictionaryContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishDictionaryContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishDictionaryContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishDictionaryContent, fetchSpanishDictionaryContentSaga);
}

function* fetchEnglishActivationContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishActivationContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishActivationContent, {trackingId});
        yield put(content.actions.fetchEnglishActivationContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishActivationContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishActivationContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishActivationContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishActivationContent, fetchEnglishActivationContentSaga);
}

function* fetchSpanishActivationContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishActivationContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishActivationContent, {trackingId});
        yield put(content.actions.fetchSpanishActivationContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishActivationContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishActivationContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishActivationContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishActivationContent, fetchSpanishActivationContentSaga);
}

function* fetchEnglishDashboardContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishDashboardContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishDashboardContent, {trackingId});
        yield put(content.actions.fetchEnglishDashboardContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishDashboardContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishDashboardContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishDashboardContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishDashboardContent, fetchEnglishDashboardContentSaga);
}

function* fetchSpanishDashboardContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishDashboardContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishDashboardContent, {trackingId});
        yield put(content.actions.fetchSpanishDashboardContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishDashboardContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishDashboardContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishDashboardContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishDashboardContent, fetchSpanishDashboardContentSaga);
}

function* fetchEnglishCartContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishCartContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishCartContent, {trackingId});
        yield put(content.actions.fetchEnglishCartContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishCartContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishCartContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishCartContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishCartContent, fetchEnglishCartContentSaga);
}

function* fetchSpanishCartContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishCartContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishCartContent, {trackingId});
        yield put(content.actions.fetchSpanishCartContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishCartContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishCartContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishCartContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishCartContent, fetchSpanishCartContentSaga);
}


function* fetchEnglishBuyflowContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishBuyflowContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishBuyflowContent, {trackingId});
        yield put(content.actions.fetchEnglishBuyflowContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishBuyflowContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishBuyflowContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishBuyflowContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishBuyflowContent, fetchEnglishBuyflowContentSaga);
}

function* fetchSpanishBuyflowContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishBuyflowContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishBuyflowContent, {trackingId});
        yield put(content.actions.fetchSpanishBuyflowContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishBuyflowContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishBuyflowContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishBuyflowContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishBuyflowContent, fetchSpanishBuyflowContentSaga);
}




function* fetchEnglishRefillContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishRefillContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishRefillContent, {trackingId});
        yield put(content.actions.fetchEnglishRefillContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchEnglishRefillContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishRefillContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishRefillContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishRefillContent, fetchEnglishRefillContentSaga);
}

function* fetchSpanishRefillContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishRefillContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishRefillContent, {trackingId});
        yield put(content.actions.fetchSpanishRefillContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error.response.data.errorCode
        const errorMessage = error.response.data.errorMessage
        digitalDataAPIError('fetchSpanishRefillContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishRefillContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishRefillContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishRefillContent, fetchSpanishRefillContentSaga);
}




function* fetchEnglishMerchantContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishMerchantContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishMerchantContent, {trackingId});
        yield put(content.actions.fetchEnglishMerchantContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        digitalDataAPIError('fetchEnglishMerchantContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishMerchantContentFailure(error.response.data));
    }
}

function* fetchEnglishMerchantContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishMerchantContent, fetchEnglishMerchantContentSaga);
}

function* fetchSpanishMerchantContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishMerchantContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishMerchantContent, {trackingId});
        yield put(content.actions.fetchSpanishMerchantContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        digitalDataAPIError('fetchSpanishMerchantContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishMerchantContentFailure(error.response.data));
    }
}

function* fetchSpanishMerchantContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishMerchantContent, fetchSpanishMerchantContentSaga);
}


function* fetchEnglishMoveContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishMoveContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishMoveContent, {trackingId});
        yield put(content.actions.fetchEnglishMoveContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        digitalDataAPIError('fetchEnglishMoveContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishMoveContentFailure(error.response.data));
    }
}

function* fetchEnglishMoveContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishMoveContent, fetchEnglishMoveContentSaga);
}

function* fetchSpanishMoveContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishMoveContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishMoveContent, {trackingId});
        yield put(content.actions.fetchSpanishMoveContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        digitalDataAPIError('fetchSpanishMoveContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishMoveContentFailure(error.response.data));
    }
}

function* fetchSpanishMoveContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishMoveContent, fetchSpanishMoveContentSaga);
}


function* fetchEnglishReconnectContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishReconnectContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishReconnectContent, {trackingId});
        yield put(content.actions.fetchEnglishReconnectContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        digitalDataAPIError('fetchEnglishReconnectContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchEnglishReconnectContentFailure(error.response.data));
    }
}

function* fetchEnglishReconnectContentSagaConfig() {
    yield takeEvery(content.actions.fetchEnglishReconnectContent, fetchEnglishReconnectContentSaga);
}

function* fetchSpanishReconnectContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishReconnectContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishReconnectContent, {trackingId});
        yield put(content.actions.fetchSpanishReconnectContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        digitalDataAPIError('fetchSpanishReconnectContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(content.actions.fetchSpanishReconnectContentFailure(error.response.data));
    }
}

function* fetchSpanishReconnectContentSagaConfig() {
    yield takeEvery(content.actions.fetchSpanishReconnectContent, fetchSpanishReconnectContentSaga);
}

export {
    fetchEnglishAllContentSagaConfig,
    fetchSpanishAllContentSagaConfig,
    fetchEnglishDictionaryContentSagaConfig,
    fetchSpanishDictionaryContentSagaConfig,
    fetchEnglishActivationContentSagaConfig,
    fetchSpanishActivationContentSagaConfig,
    fetchEnglishDashboardContentSagaConfig,
    fetchSpanishDashboardContentSagaConfig,
    fetchEnglishCartContentSagaConfig,
    fetchSpanishCartContentSagaConfig,
    fetchEnglishBuyflowContentSagaConfig,
    fetchSpanishBuyflowContentSagaConfig,
    fetchEnglishRefillContentSagaConfig,
    fetchSpanishRefillContentSagaConfig,
    fetchEnglishMerchantContentSagaConfig,
    fetchSpanishMerchantContentSagaConfig,
    fetchEnglishMoveContentSagaConfig,
    fetchSpanishMoveContentSagaConfig,
    fetchEnglishReconnectContentSagaConfig,
    fetchSpanishReconnectContentSagaConfig,

}

