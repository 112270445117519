import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/utils'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

const StatusRow = (props) => {

const [address, setAddress] = useState('')
const [unit, setUnit] = useState('')
const [city, setCity] = useState('')
const [zip, setZip] = useState('')
const [state, setState] = useState('')
const currentLocale = 'en'
    // initial load
    useEffect(() => {
        let dataSrc = sessionStorage.getItem('xppdata')
        if (dataSrc) {
            const data = JSON.parse(dataSrc);
            if (data.address) {
                setAddress(data.address.toUpperCase())
                setUnit(data.unit.toUpperCase())
                setCity(data.city.toUpperCase())
                setZip(data.zip.toUpperCase())
                setState(data.state.toUpperCase())
            }
        } 
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const trackClick = () => {
        trackEvent({
            event: 'move info',
            moveStep: 'step 2',
            moveStepName: 'move review',
            moveEvent: 'request address component',
            moveDetail: 'change address'
        })
}

    return (
        <div className="status-row status-row--success">
            <div className="status">
                <div className="round">
                    <span className="check"></span>
                </div>
            </div>
            <div className="text">
                <div className="title">
                    Destination address for service transfer request
                </div>
                <div className="description">
                <div>{address}</div>
                {unit !== '' ? <div>{unit}</div> : null }
                {city}, {state} {zip}
                </div>
                <Link
                    to={`/${currentLocale}/move`}
                    onClick={() => trackClick()}
                    className="button button--link button--link--left"
                    title="Check Address Availability"
                    data-tracking={digitalDataLinkClick('check-address-availability', `/${currentLocale}/move`, 'Change Address')}
                >
                    Change Address
                </Link>
            </div>
        </div>
    )
}

export default StatusRow