import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";

import { activation } from "./activation/store";
import {
  fetchTokenSagaConfig,
  fetchEnglishActivationContentSagaConfig,
  fetchSpanishActivationContentSagaConfig,
  fetchIdentityInfoSagaConfig,
  fetchCustomerDashboardSagaConfig,
  loadPrepaidAccountSagaConfig,
  fetchDeviceStatusSagaConfig,
  fetchMacStatusSagaConfig,
  activatePrepaidAccountSagaConfig,
  restartDeviceSagaConfig,
} from "./activation/sagas";

import { litepay } from "./litepay/store";
import {
  fetchTokenSagaConfig as litepayFetchTokenSagaConfig,
  fetchEnglishLitepayContentSagaConfig,
  fetchSpanishLitepayContentSagaConfig,
  fetchCustomerDashboardSagaConfig as litepayFetchCustomerDashboardSagaConfig,
  fetchTaxDataSagaConfig as litepayFetchTaxDataSagaConfig,
  refillPrepaidAccountSagaConfig as litepayReillPrepaidAccountSagaConfig,
  addPaymentInstrumentSagaConfig as litepayAddPaymentInstrumentSagaConfig,
} from "./litepay/sagas";

import { buyflow } from "./buyflow/store";
import {
  fetchTokenSagaConfig as buyflowFetchTokenSagaConfig,
  validateServiceabilitySagaConfig,
  fetchProductDetailsSagaConfig,
  createSessionSagaConfig,
  restoreDigitalSessionSagaConfig,
  getPromosSagaConfig,
  getTaxDataSagaConfig,
  submitOrderSagaConfig,
  getPaymentIdSagaConfig,
  addPaymentInstrumentSagaConfig as buyflowAddPaymentInstrumentSagaConfig
} from "./buyflow/sagas";

import { dashboard } from "./dashboard/store";
import {
  fetchIdentityInfoSagaConfig as dashboardFetchIdentityInfoSagaConfig,
  updateCustomerSagaConfig,
  updateIDCMTCustomerInfoSagaConfig,
  fetchCustomerDashboardSagaConfig as dashboardFetchCustomerDashboardSagaConfig,
  restartDeviceSagaConfig as dashboardRestartDeviceSagaConfig,
  fetchPrepaidTransactionsSagaConfig,
  fetchPaymentInfoSagaConfig,
  enableAutoRefillSagaConfig,
  disableAutoRefillSagaConfig,
  addPaymentInstrumentSagaConfig,
  removePaymentInstrumentSagaConfig,
  updatePaymentInstrumentSagaConfig,
  validateActivationCodeSagaConfig,
  fetchPromosSagaConfig,
  fetchTaxDataSagaConfig,
  refillPrepaidAccountSagaConfig,
  redeemRefillSagaConfig,
  fetchAuxAccountSagaConfig,
  createAuxAccountSagaConfig,
  updateScheduledMoveSagaConfig,
  pingSessionSagaConfig,
} from "./dashboard/sagas";

import { content } from "./content/store";
import {
  fetchEnglishAllContentSagaConfig,
  fetchSpanishAllContentSagaConfig,
  fetchEnglishDictionaryContentSagaConfig,
  fetchSpanishDictionaryContentSagaConfig,
  fetchEnglishDashboardContentSagaConfig,
  fetchSpanishDashboardContentSagaConfig,
  fetchEnglishRefillContentSagaConfig,
  fetchSpanishRefillContentSagaConfig,
  fetchEnglishMerchantContentSagaConfig,
  fetchSpanishMerchantContentSagaConfig,
  fetchEnglishMoveContentSagaConfig,
  fetchSpanishMoveContentSagaConfig,
  fetchEnglishReconnectContentSagaConfig,
  fetchSpanishReconnectContentSagaConfig,
  fetchEnglishCartContentSagaConfig,
  fetchSpanishCartContentSagaConfig,
  fetchEnglishBuyflowContentSagaConfig,
  fetchSpanishBuyflowContentSagaConfig,
  fetchEnglishActivationContentSagaConfig as contentFetchEnglishActivationContentSagaConfig,
  fetchSpanishActivationContentSagaConfig as contentFetchSpanishActivationContentSagaConfig,
} from "./content/sagas";

import { common } from "./common/store";
import {
  fetchTokenSagaConfig as commonFetchTokenSagaConfig,
  getAccountSagaConfig,
} from "./common/sagas";

import { cart } from "./cart/store";
import { fetchProductDetailsSagaConfig as cartFetchProductDetailsSagaConfig } from "./cart/sagas";

const rootReducer = combineReducers({
  activation: activation.reducer,
  buyflow: buyflow.reducer,
  dashboard: dashboard.reducer,
  content: content.reducer,
  common: common.reducer,
  cart: cart.reducer,
  litepay: litepay.reducer,
});

const sagaMiddleware = createSagaMiddleware();

export const configurePrepaidStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });

  // activation
  sagaMiddleware.run(fetchTokenSagaConfig);
  sagaMiddleware.run(fetchEnglishActivationContentSagaConfig);
  sagaMiddleware.run(fetchSpanishActivationContentSagaConfig);
  sagaMiddleware.run(fetchIdentityInfoSagaConfig);
  sagaMiddleware.run(fetchCustomerDashboardSagaConfig);
  sagaMiddleware.run(loadPrepaidAccountSagaConfig);
  sagaMiddleware.run(fetchDeviceStatusSagaConfig);
  sagaMiddleware.run(fetchMacStatusSagaConfig);
  sagaMiddleware.run(activatePrepaidAccountSagaConfig);
  sagaMiddleware.run(restartDeviceSagaConfig);

  // litepay
  sagaMiddleware.run(litepayFetchTokenSagaConfig);
  sagaMiddleware.run(fetchEnglishLitepayContentSagaConfig);
  sagaMiddleware.run(fetchSpanishLitepayContentSagaConfig);
  sagaMiddleware.run(litepayFetchCustomerDashboardSagaConfig);
  sagaMiddleware.run(litepayFetchTaxDataSagaConfig);
  sagaMiddleware.run(litepayReillPrepaidAccountSagaConfig);
  sagaMiddleware.run(litepayAddPaymentInstrumentSagaConfig);

  // buyflow
  sagaMiddleware.run(buyflowFetchTokenSagaConfig);
  sagaMiddleware.run(validateServiceabilitySagaConfig);
  sagaMiddleware.run(fetchProductDetailsSagaConfig);
  sagaMiddleware.run(createSessionSagaConfig);
  sagaMiddleware.run(restoreDigitalSessionSagaConfig);
  sagaMiddleware.run(getPromosSagaConfig);
  sagaMiddleware.run(getTaxDataSagaConfig);
  sagaMiddleware.run(submitOrderSagaConfig);
  sagaMiddleware.run(getPaymentIdSagaConfig);
  sagaMiddleware.run(buyflowAddPaymentInstrumentSagaConfig);

  // dashboard
  sagaMiddleware.run(dashboardFetchIdentityInfoSagaConfig);
  sagaMiddleware.run(updateCustomerSagaConfig);
  sagaMiddleware.run(updateIDCMTCustomerInfoSagaConfig);
  sagaMiddleware.run(dashboardFetchCustomerDashboardSagaConfig);
  sagaMiddleware.run(dashboardRestartDeviceSagaConfig);
  sagaMiddleware.run(fetchPrepaidTransactionsSagaConfig);
  sagaMiddleware.run(fetchPaymentInfoSagaConfig);
  sagaMiddleware.run(enableAutoRefillSagaConfig);
  sagaMiddleware.run(disableAutoRefillSagaConfig);
  sagaMiddleware.run(addPaymentInstrumentSagaConfig);
  sagaMiddleware.run(removePaymentInstrumentSagaConfig);
  sagaMiddleware.run(updatePaymentInstrumentSagaConfig);
  sagaMiddleware.run(validateActivationCodeSagaConfig);
  sagaMiddleware.run(fetchPromosSagaConfig);
  sagaMiddleware.run(fetchTaxDataSagaConfig);
  sagaMiddleware.run(refillPrepaidAccountSagaConfig);
  sagaMiddleware.run(redeemRefillSagaConfig);
  sagaMiddleware.run(fetchAuxAccountSagaConfig);
  sagaMiddleware.run(createAuxAccountSagaConfig);
  sagaMiddleware.run(updateScheduledMoveSagaConfig);
  sagaMiddleware.run(pingSessionSagaConfig);

  // common
  sagaMiddleware.run(commonFetchTokenSagaConfig);
  sagaMiddleware.run(getAccountSagaConfig);

  // content
  sagaMiddleware.run(contentFetchEnglishActivationContentSagaConfig);
  sagaMiddleware.run(contentFetchSpanishActivationContentSagaConfig);
  sagaMiddleware.run(fetchEnglishAllContentSagaConfig);
  sagaMiddleware.run(fetchSpanishAllContentSagaConfig);
  sagaMiddleware.run(fetchEnglishDictionaryContentSagaConfig);
  sagaMiddleware.run(fetchSpanishDictionaryContentSagaConfig);
  sagaMiddleware.run(fetchEnglishDashboardContentSagaConfig);
  sagaMiddleware.run(fetchSpanishDashboardContentSagaConfig);
  sagaMiddleware.run(fetchEnglishRefillContentSagaConfig);
  sagaMiddleware.run(fetchSpanishRefillContentSagaConfig);
  sagaMiddleware.run(fetchEnglishMerchantContentSagaConfig);
  sagaMiddleware.run(fetchSpanishMerchantContentSagaConfig);
  sagaMiddleware.run(fetchEnglishMoveContentSagaConfig);
  sagaMiddleware.run(fetchSpanishMoveContentSagaConfig);
  sagaMiddleware.run(fetchEnglishReconnectContentSagaConfig);
  sagaMiddleware.run(fetchSpanishReconnectContentSagaConfig);
  sagaMiddleware.run(fetchEnglishCartContentSagaConfig);
  sagaMiddleware.run(fetchSpanishCartContentSagaConfig);
  sagaMiddleware.run(fetchEnglishBuyflowContentSagaConfig);
  sagaMiddleware.run(fetchSpanishBuyflowContentSagaConfig);

  // cart
  sagaMiddleware.run(cartFetchProductDetailsSagaConfig);

  return { Provider, store };
};
