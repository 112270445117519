import { createSlice } from "@reduxjs/toolkit";
import { sprintf } from "sprintf-js";
import { promoList } from "./promos/valid-promos.json";
import dashboardSelectors from "../dashboard/selectors";

const resetPaymentInstrumentState = (state) => {
  state.addedPaymentInstrument = false;
  state.addingPaymentInstrument = false;
  state.addPaymentFailed = false;
  state.updatedPaymentInstrument = false;
  state.updatingPaymentInstrument = false;
  state.updatePaymentInstrumentFailed = false;
  state.removedPaymentInstrument = false;
  state.removingPaymentInstrument = false;
  state.removePaymentInstrumentFailed = false;
};
interface DashboardState {
  sessionExpired: boolean;
  sessionError: any | null;
  lastActionAt: number;

  pingedSession: boolean;
  pingingSession: boolean;
  pingSessionFailed: boolean;

  ready: boolean;
  checksCompleted: boolean;

  firstName: string | null;
  lastName: string | null;
  isVanAccount: boolean;

  deactivatedAccount: boolean;

  identity: any | null;
  identityAccounts: any | null;
  fetchedIdentity: boolean;
  fetchingIdentity: boolean;
  fetchIdentityFailed: boolean;
  updatingIdentity: boolean;
  updatedIdentity: boolean;
  updateIdentityFailed: boolean;
  identityErrorCode: string | null;
  identityErrorMessage: string | null;
  pronoun: string | null;
  chosenName: any | null;

  updatingIDCMTCustomerInfo: boolean;
  updatedIDCMTCustomerInfo: boolean;
  updateIDCMTCustomerInfoFailed: boolean;

  dashboard: any | null;
  xitvProducts: any | null;
  xitvBasic: any | null;
  hsdService: any | null;
  tv200Service: any | null;

  availableHSDServices: any | null;
  hsd30Service: any | null;
  hsd7Service: any | null;
  tvServiceAvailableForRefill: boolean;

  availableXitvBasic: any | null;
  availableXitvPackages: any | null;
  availableXitvAddOns: any | null;
  availableXitvSpanishAddOns: any | null;

  fetchedDashboard: boolean;
  fetchingDashboard: boolean;
  fetchDashboardFailed: boolean;
  dashboardErrorCode: string | null;
  dashboardErrorMessage: string | null;

  restartDeviceStatus: any | null;
  restartedDevice: boolean;
  restartingDevice: boolean;
  restartingDeviceFailed: boolean;

  prepaidTransactions: any | null;
  prepaidTransactionsByDate: any | null;
  fetchedPrepaidTransactions: boolean;
  fetchingPrepaidTransactions: boolean;
  fetchPrepaidTransactionsFailed: boolean;

  autoRefillEnabled: boolean;
  enablingAutoRefill: boolean;
  enabledAutoRefill: boolean;
  enableAutoRefillFailed: boolean;
  disablingAutoRefill: boolean;
  disabledAutoRefill: boolean;
  disableAutoRefillFailed: boolean;

  paymentInfo: any | null;
  fetchedPaymentInfo: boolean;
  fetchingPaymentInfo: boolean;
  fetchPaymentInfoFailed: boolean;

  addingPaymentInstrument: boolean;
  addedPaymentInstrument: boolean;
  addPaymentInstrumentFailed: boolean;

  removingPaymentInstrument: boolean;
  removedPaymentInstrument: boolean;
  removePaymentInstrumentFailed: boolean;

  updatingPaymentInstrument: boolean;
  updatedPaymentInstrument: boolean;
  updatePaymentInstrumentFailed: boolean;

  paymentInstrumentErrorCode: string | null;

  activationCode: any | null;
  validatingActivationCode: boolean;
  validatedActivationCode: boolean;
  validateActivationCodeFailed: boolean;

  promos: any | null;
  fetchingPromos: boolean;
  fetchedPromos: boolean;
  fetchPromosFailed: boolean;

  taxData: any | null;
  fetchingTaxData: boolean;
  fetchedTaxData: boolean;
  fetchTaxDataFailed: boolean;

  refill: any | null;
  refillingPrepaidAccount: boolean;
  refilledPrepaidAccount: boolean;
  refillPrepaidAccountFailed: boolean;

  pinRefill: any | null;
  redeemingRefill: boolean;
  redeemedRefill: boolean;
  redeemRefillFailed: boolean;

  refillErrorCode: string | null;

  billing: {
    name: string; // XXX API actually breaks these out and first and last name...
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    country: string; // 'US',
    cardNumber: string;
    cardDisplayNumber: string;
    cardNumberLength: number;
    cardNumberLastFour: string;
    cardType: string;
    cardNiceType: string;
    expirationMonth: string;
    expirationYear: string;
    cvv: string;
    useServiceAddressForBilling: boolean;
    savePaymentMethod: boolean;
    enrollInAutoRefill: boolean;
  };
  // billingName: string // XXX API actually breaks these out and first and last name...
  // billingFirstName: string
  // billingLastName: string
  // billingAddressLine1: string
  // billingAddressLine2: string
  // billingCity: string
  // billingState: string
  // billingZip: string
  // billingCountry: string // 'US',
  // billingCardNumber: string
  // billingCardDisplayNumber: string
  // billingCardNumberLength: 0,
  // billingCardNumberLastFour: string
  // billingCardType: string
  // billingCardNiceType: string
  // billingExpirationMonth: string
  // billingExpirationYear: string
  // billingCvv: string
  // useServiceAddressForBilling: boolean
  // savePaymentMethod: boolean,
  // enrollInAutoRefill: boolean,

  cardTypeMap: any;

  cart: {
    internet: string | null;
    internetRefills: number;
    packages: string[];
    addOns: string[];
    promos: string[];
  };
  cartCount: number;
  cartSubtotal: number;
  cartTaxes: number;
  cartTotal: number;

  auxAccount: any | null;
  fetchingAuxAccount: boolean;
  fetchedAuxAccount: boolean;
  fetchAuxAccountFailed: boolean;

  newAuxAccount: any | null;
  creatingAuxAccount: boolean;
  createdAuxAccount: boolean;
  createAuxAccountFailed: boolean;

  scheduledMoveDate: string | null;
  moveDateGap: number;
  haveScheduledMove: boolean;
  cancelledScheduledMove: boolean;
  scheduledMove: any | null;
  updatingScheduledMove: boolean;
  updatedScheduledMove: boolean;
  updateScheduledMoveFailed: boolean;
  acpDetail: {
    customerType: string | null;
    acpStatus: string | null;
    deniedReasonCode: string | null;
    deniedReasonDesc: string | null;
    acpBenefits: [
      {
        benefitPlan: string | null;
        benefitPeriod: string | null;
        benefitCode: string | null;
        benefitAmount: number;
      }
    ];
  };
  cpcResponse: any;
  isTestFile:boolean;
  broadbandFactsResponse: any;
}

// const updateReadyStatus = (state) => {
//     let ready = true
//     // ready = ready && state.fetchedEnglishContent
//     // ready = ready && state.fetchedSpanishContent
//     ready = ready && state.fetchedIdentity
//     ready = ready && state.fetchedDashboard
//     state.ready = ready
//     state.fetchedContent = state.fetchedEnglishContent && state.fetchedSpanishContent
// }

const initialState = {
  sessionExpired: false,
  sessionError: null,
  lastActionAt: Date.now(),

  pingedSession: false,
  pingingSession: false,
  pingSessionFailed: false,

  ready: false,
  checksCompleted: false,

  macAddress: null,

  accountId: null,
  custGuid: null,
  behindTheModem: false,

  firstName: null,
  lastName: null,
  isVanAccount: false,

  deactivatedAccount: false,

  trackingId: null,

  locale: "en",
  inApp: false,

  xsrfToken: null, // XXX not populated, not needed?
  fetchedToken: false,
  fetchingToken: false,
  fetchingTokenFailed: false,

  identity: null,
  identityAccounts: null,
  fetchedIdentity: false,
  fetchingIdentity: false,
  fetchIdentityFailed: false,
  updatingIdentity: false,
  updatedIdentity: false,
  updateIdentityFailed: false,
  identityErrorCode: "",
  identityErrorMessage: "",
  pronoun: "",
  chosenName: {},

  updatingIDCMTCustomerInfo: false,
  updatedIDCMTCustomerInfo: false,
  updateIDCMTCustomerInfoFailed: false,

  dashboard: null,
  xitvProducts: null,
  xitvBasic: null,
  hsdService: null,
  tv200Service: null,

  availableHSDServices: null,
  hsd30Service: null,
  hsd7Service: null,
  tvServiceAvailableForRefill: false,

  availableXitvBasic: null,
  availableXitvPackages: null,
  availableXitvAddOns: null,
  availableXitvSpanishAddOns: null,

  fetchedDashboard: false,
  fetchingDashboard: false,
  fetchDashboardFailed: false,
  dashboardErrorCode: "",
  dashboardErrorMessage: "",

  restartDeviceStatus: null,
  restartedDevice: false,
  restartingDevice: false,
  restartingDeviceFailed: false,

  prepaidTransactions: [],
  prepaidTransactionsByDate: [],
  fetchedPrepaidTransactions: false,
  fetchingPrepaidTransactions: false,
  fetchPrepaidTransactionsFailed: false,

  autoRefillEnabled: false,
  enablingAutoRefill: false,
  enabledAutoRefill: false,
  enableAutoRefillFailed: false,
  disablingAutoRefill: false,
  disabledAutoRefill: false,
  disableAutoRefillFailed: false,

  paymentInfo: null,
  fetchedPaymentInfo: false,
  fetchingPaymentInfo: false,
  fetchPaymentInfoFailed: false,

  addingPaymentInstrument: false,
  addedPaymentInstrument: false,
  addPaymentInstrumentFailed: false,

  removingPaymentInstrument: false,
  removedPaymentInstrument: false,
  removePaymentInstrumentFailed: false,

  updatingPaymentInstrument: false,
  updatedPaymentInstrument: false,
  updatePaymentInstrumentFailed: false,

  paymentInstrumentErrorCode: null,

  activationCode: null,
  validatingActivationCode: false,
  validatedActivationCode: false,
  validateActivationCodeFailed: false,

  promos: null,
  fetchingPromos: false,
  fetchedPromos: false,
  fetchPromosFailed: false,

  taxData: null,
  fetchingTaxData: false,
  fetchedTaxData: false,
  fetchTaxDataFailed: false,

  refill: null,
  refillingPrepaidAccount: false,
  refilledPrepaidAccount: false,
  refillPrepaidAccountFailed: false,

  pinRefill: null,
  redeemingRefill: false,
  redeemedRefill: false,
  redeemRefillFailed: false,

  refillErrorCode: null,

  billing: {
    name: "", // XXX API actually breaks these out and first and last name...
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    cardNumber: "",
    cardDisplayNumber: "",
    cardNumberLength: 0,
    cardNumberLastFour: "",
    cardType: "",
    cardNiceType: "",
    expirationMonth: "",
    expirationYear: "",
    expirationDate: "",
    cvv: "",
    useServiceAddressForBilling: true,
    savePaymentMethod: false,
    enrollInAutoRefill: false,
    submitAndContinue: false,
    isValid: false,
    paymentInstToken: "",
  },

  cardTypeMap: {
    mastercard: "MASTER_CARD",
    visa: "Visa",
    discover: "Discover",
    "american-express": "AMERICAN_EXPRESS",
  },

  cart: {
    internet: "HSD_030",
    internetRefills: 1,
    packages: [],
    addOns: [],
    promos: [],
  },
  cartCount: 0,
  cartSubtotal: 0,
  cartTaxes: 0,
  cartTotal: 0,

  auxAccount: null,
  fetchingAuxAccount: false,
  fetchedAuxAccount: false,
  fetchAuxAccountFailed: false,

  newAuxAccount: null,
  creatingAuxAccount: false,
  createdAuxAccount: false,
  createAuxAccountFailed: false,

  scheduledMoveDate: null,
  moveDateGap: 0,
  haveScheduledMove: false,
  cancelledScheduledMove: false,
  scheduledMove: null,
  updatingScheduledMove: false,
  updatedScheduledMove: false,
  updateScheduledMoveFailed: false,
  acpDetail: {
    customerType: "",
    acpStatus: "",
    deniedReasonCode: "",
    deniedReasonDesc: "",
    acpBenefits: [
      {
        benefitPlan: "",
        benefitPeriod: "",
        benefitCode: "",
        benefitAmount: 0,
      },
    ],
  },
  cpcResponse: {},
  isTestFile:false,
  broadbandFactsResponse:{},
} as DashboardState;

const blankBillingData = {
  name: "",
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  country: "US",
  cardNumber: "",
  cardDisplayNumber: "",
  cardNumberLength: 0,
  cardNumberLastFour: "",
  cardType: "",
  cardNiceType: "",
  expirationMonth: "",
  expirationYear: "",
  expirationDate: "",
  cvv: "",
  useServiceAddressForBilling: true,
  savePaymentMethod: true,
  enrollInAutoRefill: false,
  submitAndContinue: false,
  isValid: false,
  paymentInstToken: "",
};

const calculateDaysRemaining = (date) => {
  const today = new Date();
  const dateToCheck = new Date(date);
  const timeinmilisec = dateToCheck.getTime() - today.getTime();
  return Math.floor(timeinmilisec / (1000 * 60 * 60 * 24)) + 1;
};

const getHSDService = ({ subscriptionDetails, planStatusDetails }) => {
  console.log("planStatusDetails", planStatusDetails);
  let hsdService: any;
  const index = subscriptionDetails?.findIndex((p) => p.deviceLob === "HSD");
  if (index >= 0 && planStatusDetails) {
    hsdService = subscriptionDetails[index];
    // XXX TODO Localize and pull from Sitecore Content
    hsdService.name = "Prepaid Internet";
    hsdService.deviceLob = subscriptionDetails[index].deviceLob;
    let expiryDate = "";
    // const numberOfPlans = subscriptionDetails[index].planDetails.length - 1
    // expiryDate = subscriptionDetails[index].planDetails[numberOfPlans].planExpiryDate
    expiryDate = planStatusDetails.find((d) => d.lob === "HSD").expiryDate;

    let expDate = new Date(expiryDate);
    hsdService.formattedExpirationDate = sprintf(
      `%d/%d/%d`,
      expDate.getUTCMonth() + 1,
      expDate.getUTCDate(),
      expDate.getUTCFullYear()
    );

    hsdService.daysRemaining = calculateDaysRemaining(expiryDate);
    hsdService.expirationDate = expiryDate;
    hsdService.deviceId = subscriptionDetails[index].macId;
    hsdService.deviceSerialNumber =
      subscriptionDetails[index].deviceSerialNumber;
    return hsdService;
  } else return;
};
const getTv200Service = ({ subscriptionDetails, planStatusDetails }) => {
  let tv200Service: any;
  const index = subscriptionDetails?.findIndex((p) => p.deviceLob === "TV200");
  if (index >= 0) {
    tv200Service = subscriptionDetails[index];
    tv200Service.name = "TV200";
    tv200Service.deviceLob = subscriptionDetails[index].deviceLob;
    let expiryDate = "";
    // expiryDate = subscriptionDetails[index].planDetails[0].planExpiryDate
    expiryDate = planStatusDetails.find((d) => d.lob === "TV200").expiryDate;
    tv200Service.daysRemaining = calculateDaysRemaining(expiryDate);
    tv200Service.expirationDate = expiryDate;
    tv200Service.deviceId = subscriptionDetails[index].macId;
    tv200Service.deviceSerialNumber =
      subscriptionDetails[index].deviceSerialNumber;
    return tv200Service;
  } else return;
};
const getXITVServices = ({ subscriptionDetails, planStatusDetails }) => {
  let xitvServices: any;
  xitvServices = [];
  const index = subscriptionDetails?.findIndex((p) => p.deviceLob === "VIDEO");

  if (index < 0 || !planStatusDetails) {
    return xitvServices;
  }

  let pd = subscriptionDetails[index].planDetails;
  const xitvServiceArray = pd && pd.length ? pd : [];

  const codes: string[] = [];
  for (let i = 0; i < xitvServiceArray.length; i++) {
    let service: any;
    let expiryDate = "";
    // expiryDate = xitvServiceArray[i].planExpiryDate
    const plan = planStatusDetails.find(
      (d) => d.lob === xitvServiceArray[i].planCode
    );
    if (plan) {
      expiryDate = plan.expiryDate;
      service = xitvServiceArray[i];
      // XXX TODO Localize and pull from Sitecore content
      service.name = xitvServiceArray[i].planDesc.replace(
        /[ ]?-?[ ]?30 Day[s]?/,
        ""
      );
      service.deviceLob = xitvServiceArray[i].deviceLob;
      service.daysRemaining = calculateDaysRemaining(expiryDate);
      service.expirationDate = expiryDate;
      service.productCode = xitvServiceArray[i].productCode;

      // filter out duplicates with different expiration dates
      if (!codes.includes(service.productCode)) {
        codes.push(service.productCode);
        xitvServices.push(service);
      }
    }
  }
  return xitvServices;
};

const getAvailableHSDServices = (availablePlans) => {
  if (!availablePlans) {
    return [];
  }

  return availablePlans.filter((p) => p.planLob === "HSD");
};

const getXITVBasic = (state) => {
  let index = state.xitvProducts?.findIndex(
    (p) => p.productCode === "VDO_XITV_30"
  );
  if (index >= 0) {
    return state.xitvProducts[index];
  }
  return null;
};
const getAvailableXITVBasic = (availablePlans) => {
  if (!availablePlans) {
    return [];
  }

  const index = availablePlans?.findIndex(
    (p) => p.productCode === "VDO_XITV_30"
  );
  return availablePlans[index];
};

const getAvailableXitvPackages = (availablePlans) => {
  if (!availablePlans) {
    return [];
  }

  let xitvPackages = [{}];
  for (let i = 0; i < availablePlans.length; i++) {
    if (
      availablePlans[i].productCode === "VDO_XITV_ENTRTMNT_30" ||
      availablePlans[i].productCode === "VDO_XITV_SPORTS_NEWS_30" ||
      availablePlans[i].productCode === "VDO_XITV_KID_FAMILY_30"
    ) {
      xitvPackages.push(availablePlans[i]);
    }
  }
  xitvPackages.shift();
  return xitvPackages;
};
const getAvailableXitvAddOns = (availablePlans) => {
  if (!availablePlans) {
    return [];
  }

  let xitvAddOns = [{}];
  for (let i = 0; i < availablePlans.length; i++) {
    if (
      availablePlans[i].productCode === "VDO_XITV_HBO_30" ||
      availablePlans[i].productCode === "VDO_XITV_STREAMPIX_30" ||
      availablePlans[i].productCode === "VDO_XITV_CINEMAX_30" ||
      availablePlans[i].productCode === "VDO_XITV_SHOWTIME_30"
    ) {
      xitvAddOns.push(availablePlans[i]);
    }
  }
  xitvAddOns.shift();
  return xitvAddOns;
};
const getAvailableXitvSpanishAddOns = (availablePlans) => {
  if (!availablePlans) {
    return [];
  }

  let xitvSpanishAddOns = [{}];
  for (let i = 0; i < availablePlans.length; i++) {
    if (
      availablePlans[i].productCode === "VDO_XITV_DEPORTES_30" ||
      availablePlans[i].productCode === "VDO_XITV_LATINO_30"
    ) {
      xitvSpanishAddOns.push(availablePlans[i]);
    }
  }
  xitvSpanishAddOns.shift();
  return xitvSpanishAddOns;
};

const isWinbackPromo = (promos) => {
  let isWinbackPromo = false;
  if (promos?.discounts?.length) {
    promoList?.winback.forEach(function (item) {
      const validDiscountID = item;
      // for now we only use the first promo
      const promo = promos.discounts[0];
      if (promo.discId === validDiscountID) {
        isWinbackPromo = true;
      }
    });
  }
  return isWinbackPromo;
};

const getCartCount = (cart,state) => {
  let cartCount = state;
  cartCount = cartCount + cart.packages.length + cart.addOns.length;
  return cartCount;
};

const getCartSubtotal = (cart, dashboard, promos, validNonTribalACP) => {
  let total = 0;
  if (!dashboard?.availablePlans) {
    return total;
  }

  if (cart?.internet !== null && cart?.internet !== "") {
    let hsdProduct: any;
    hsdProduct = dashboard.availablePlans.find(
      ({ productCode }) => productCode === cart.internet
    );
    if (hsdProduct) {
      total = hsdProduct.planPrice * cart.internetRefills;
    }
    if (
      cart.internet === "HSD_030" &&
      promos !== null &&
      promos.discounts &&
      promos.discounts.length > 0
    ) {
      if (isWinbackPromo(promos)) {
        total = hsdProduct.planPrice * (cart.internetRefills + 1);
      }
      // Refill Promo
      total = total - Math.abs(parseInt(promos.discounts[0].discPrice));
    }
    if (validNonTribalACP && cart.internet === "HSD_030") {
      total = total - dashboard.acpDetail.acpBenefits[0].benefitAmount;
    }
  }
  for (let i = 0; cart.packages.length > i; i++) {
    let product: any;
    product = dashboard.availablePlans.find(
      ({ productCode }) => productCode === cart.packages[i]
    );
    if (product) {
      total = total + product.planPrice;
    } else {
      console.error("package not in availablePlans", cart.packages[i]);
    }
  }
  for (let i = 0; cart.addOns.length > i; i++) {
    let product: any;
    product = dashboard.availablePlans.find(
      ({ productCode }) => productCode === cart.addOns[i]
    );
    if (product) {
      total = total + product.planPrice;
    } else {
      console.error("addOn not in availablePlans", cart.addOns[i]);
    }
  }

  return total;
};

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    pingSession: (state, { payload }) => {
      state.pingingSession = true;
      state.pingedSession = false;
      state.pingSessionFailed = false;
    },
    pingSessionSuccess: (state, { payload }) => {
      state.pingingSession = false;
      state.pingedSession = true;
      state.pingSessionFailed = false;

      if (payload.pinged) {
        // Session was pinged on server, expiration time extended
        state.lastActionAt = Date.now();
      }
    },
    pingSessionFailure: (state, { payload }) => {
      console.log("pingSessionFailure payload", payload);
      state.pingingSession = false;
      state.pingedSession = false;
      state.pingSessionFailed = true;
    },
    expireSession: (state) => {
      state.sessionExpired = true;
    },
    setSessionError: (state, { payload }) => {
      state.sessionError = payload.response?.data;
      if (payload.expired) {
        state.sessionExpired = true;
      }
    },
    updateFirstName: (state, { payload }) => {
      state.firstName = payload;
    },

    updateLastName: (state, { payload }) => {
      state.lastName = payload;
    },
    updateField: (state, { payload }) => {
      state[payload.field] = payload.value;
    },
    toggleField: (state, { payload }) => {
      state[payload.field] = !payload.field;
    },
    togglePackage: (state, { payload }) => {
      const index = state.cart.packages.indexOf(payload);

      if (index === -1) {
        // Add the package
        state.cart.packages = [...state.cart.packages, payload];
      } else {
        // Remove the package
        state.cart.packages.splice(index, 1);
      }

      if (
        state.cart.packages.length &&
        !state.cart.packages.includes("VDO_XITV_30")
      ) {
        state.cart.packages = [...state.cart.packages, "VDO_XITV_30"];
      }
      if (!state.cart.packages.length && state.cart.addOns.length) {
        state.cart.addOns = [];
      }
      if (state.dashboard?.availablePlans) {
        state.cartCount = getCartCount(state.cart,state.cartCount);
        state.cartSubtotal = getCartSubtotal(
          state.cart,
          state.dashboard,
          state.promos,
          dashboardSelectors.getHaveValidACP(state) &&
            !dashboardSelectors.getIsTribalACP(state)
        );
        state.cartTotal = state.cartSubtotal + state.cartTaxes;
      }
    },
    toggleAddOn: (state, { payload }) => {
      const index = state.cart.addOns.indexOf(payload);

      if (index === -1) {
        // Add the package
        if (state.cart.packages.length === 0) {
        }
        state.cart.addOns = [...state.cart.addOns, payload];
      } else {
        // Remove the package
        state.cart.addOns.splice(index, 1);
        if (payload.keepBasic && state.cart.packages.length === 0) {
        }
      }
      if (
        state.cart.addOns.length &&
        !state.cart.packages.includes("VDO_XITV_30")
      ) {
        state.cart.packages = [...state.cart.packages, "VDO_XITV_30"];
      }
      state.cartCount = getCartCount(state.cart,state.cartCount);
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    removeAllTvProducts: (state, { payload }) => {
      state.cart.addOns = [];
      state.cart.packages = [];
      state.cartCount = getCartCount(state.cart,state.cartCount);
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    toggleInternet: (state, { payload }) => {
      if (state.cart.internet === null || state.cart.internet === "") {
        // Add the package
        state.cart.internet = payload;
      } else if (
        state.cart.internet !== null &&
        state.cart.internet !== "" &&
        state.cart.internet !== payload
      ) {
        state.cart.internet = payload;
      } else {
        // Remove the package
        state.cart.internet = "";
      }
      state.cartCount = getCartCount(state.cart,state.cartCount);
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    updateInternet: (state, { payload }) => {
      state.cart.internet = payload.name;
      state.cart.internetRefills = payload.refills;
      state.cartCount = payload.refills;
      if (state.cart.promos.length > 0) {
        if (isWinbackPromo(state.promos)) {
          state.cartCount++;
        }
      }
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },

    addPromo: (state, { payload }) => {
      if (!state.cart.promos.includes(payload)) {
        state.cart.promos.push(payload);
      }
    },

    removePromo: (state, { payload }) => {
      state.cart.promos = state.cart.promos.filter((p) => p !== payload);
    },

    addWinbackPromo: (state, { payload }) => {
      if (!state.cart.promos.includes(payload)) {
        state.cart.promos.push(payload);
        state.cartCount = 3;
        state.cart.internetRefills = 2;
        state.cartSubtotal = getCartSubtotal(
          state.cart,
          state.dashboard,
          state.promos,
          dashboardSelectors.getHaveValidACP(state) &&
            !dashboardSelectors.getIsTribalACP(state)
        );
        state.cartTotal = state.cartSubtotal + state.cartTaxes;
      }
    },
    clearCart: (state, { payload }) => {
      state.cart.promos = [];
      state.cartCount = 1;
      state.cart.internetRefills = 1;
    },
    updateBilling: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        // state.billing = {...state.billing, [key]: payload[key]}
        state.billing[key] = payload[key];
      });
    },

    resetBilling: (state, { payload }) => {
      state.billing = { ...blankBillingData };
    },

    fetchPrepaidTransactions: (state) => {
      state.fetchingPrepaidTransactions = true;
    },
    fetchPrepaidTransactionsSuccess: (state, { payload }) => {
      console.log("fetchPrepaidTransactionsSuccess payload", payload);
      // state.prepaidTransactions = payload.transactions[0].transactionDetail
      payload?.transactions?.forEach((transaction) => {
        transaction.transactionDetail.forEach((item) => {
          state.prepaidTransactions.push(item);
        });
      });
      let transactionsByDate = {};
      const adder = (acc, item) => {
        return acc + item.amount;
      };
      state.prepaidTransactions.forEach((tx) => {
        let txDate = new Date(tx.transactionDate);
        let key = sprintf(
          `%d-%02d-%02d`,
          txDate.getUTCFullYear(),
          txDate.getUTCMonth() + 1,
          txDate.getUTCDate()
        );
        tx.formattedDate = sprintf(
          `%d/%d/%d`,
          txDate.getUTCMonth() + 1,
          txDate.getUTCDate(),
          txDate.getUTCFullYear()
        );
        if (tx.expirationDate) {
          let justDate = tx.expirationDate.split(" ")[0];
          let expDate = new Date(Date.parse(justDate)); // parse just the date, not the time port
          tx.formattedExpirationDate = sprintf(
            `%d/%d/%d`,
            expDate.getUTCMonth() + 1,
            expDate.getUTCDate(),
            expDate.getUTCFullYear()
          );
        }
        transactionsByDate[key] = transactionsByDate[key] || [];
        transactionsByDate[key].push(tx);
      });

      // console.log('transactionsByDate', transactionsByDate)
      let dates = Object.keys(transactionsByDate).sort().reverse();
      // console.log('dates', dates)
      let dateGroups: any = [];
      dates.forEach((date) => {
        let items = transactionsByDate[date];
        let total = items.reduce(adder, 0);
        // console.log(total)
        let formattedTotal = sprintf("$%2.2f", total);
        dateGroups.push({
          formattedDate: items[0].formattedDate,
          formattedTotal,
          items,
        });
      });
      // console.log('dateGroups', dateGroups)
      state.prepaidTransactionsByDate = dateGroups;

      state.fetchingPrepaidTransactions = false;
      state.fetchPrepaidTransactionsFailed = false;
      state.fetchedPrepaidTransactions = true;
      state.lastActionAt = Date.now();
    },
    fetchPrepaidTransactionsFailure: (state, { payload }) => {
      console.log("fetchPrepaidTransactionsFailure payload", payload);
      state.fetchingPrepaidTransactions = false;
      state.fetchPrepaidTransactionsFailed = true;
      state.fetchedPrepaidTransactions = false;
    },
    
    enableAutoRefill: (state) => {
      state.enablingAutoRefill = true;
      state.enabledAutoRefill = false;
      state.enableAutoRefillFailed = false;
      state.disabledAutoRefill = false;
    },
    enableAutoRefillSuccess: (state, { payload }) => {
      console.log("enableAutoRefillSuccess payload", payload);
      if (
        payload.businessErrorCode &&
        payload.businessErrorCode === "BUS_PMT_006"
      ) {
        //  "message": "No payment instrument details found for this customer",
        state.autoRefillEnabled = false;
      } else {
        state.autoRefillEnabled = true;
      }
      state.enablingAutoRefill = false;
      state.enableAutoRefillFailed = false;
      state.enabledAutoRefill = true;
      state.lastActionAt = Date.now();
    },
    enableAutoRefillFailure: (state, { payload }) => {
      console.log("enableAutoRefillFailure payload", payload);
      // state.autoRefillEnabled = false
      state.enablingAutoRefill = false;
      state.enableAutoRefillFailed = true;
      state.enabledAutoRefill = false;
    },

    disableAutoRefill: (state) => {
      state.disablingAutoRefill = true;
      state.disabledAutoRefill = false;
      state.disableAutoRefillFailed = false;
      state.enabledAutoRefill = false;
    },
    
    disableAutoRefillSuccess: (state, { payload }) => {
      console.log("disableAutoRefillSuccess payload", payload);
      state.autoRefillEnabled = false;
      state.disablingAutoRefill = false;
      state.disableAutoRefillFailed = false;
      state.disabledAutoRefill = true;
      state.lastActionAt = Date.now();
    },
    disableAutoRefillFailure: (state, { payload }) => {
      console.log("disableAutoRefillFailure payload", payload);
      // state.autoRefillEnabled = false
      state.disablingAutoRefill = false;
      state.disableAutoRefillFailed = true;
      state.disabledAutoRefill = false;
    },

    fetchPromos: (state) => {
      state.fetchingPromos = true;
      state.fetchedPromos = false;
      state.fetchPromosFailed = false;
    },
    fetchPromosSuccess: (state, { payload }) => {
      console.log("fetchPromosSuccess payload", payload);
      state.promos = payload.prepaidPromotions;
      /* state.promos = { Sample Promos can be found in ../__mocks__/ } */
      state.fetchingPromos = false;
      state.fetchedPromos = true;
      state.fetchPromosFailed = false;
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
      state.lastActionAt = Date.now();
    },
    
    fetchPromosFailure: (state, { payload }) => {
      console.log("fetchPromosFailure payload", payload);
      state.fetchingPromos = false;
      state.fetchedPromos = false;
      state.fetchPromosFailed = true;
    },

    fetchTaxData: (state) => {
      state.fetchingTaxData = true;
      state.fetchedTaxData = false;
      state.fetchTaxDataFailed = false;
    },
    fetchTaxDataSuccess: (state, { payload }) => {
      console.log("fetchTaxDataSuccess payload", payload);
      state.fetchingTaxData = false;
      state.fetchTaxDataFailed = false;
      state.fetchedTaxData = true;
      state.taxData = payload;

      // XXX Is it safe to assume zero tax when this is missing?
      if (payload.totalTaxAmt === null || payload.totalTaxAmt === undefined) {
        state.cartTaxes = 0;
      } else {
        state.cartTaxes = payload.totalTaxAmt;
      }
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    fetchTaxDataFailure: (state, { payload }) => {
      console.log("fetchTaxDataFailure payload", payload);
      state.fetchingTaxData = false;
      state.fetchTaxDataFailed = true;
      state.fetchedTaxData = false;
    },
    
    refillPrepaidAccount: (state) => {
      state.refillingPrepaidAccount = true;
      state.refilledPrepaidAccount = false;
      state.refillPrepaidAccountFailed = false;
    },
    refillPrepaidAccountSuccess: (state, { payload }) => {
      console.log("refillPrepaidAccountSuccess payload", payload);
      state.refillingPrepaidAccount = false;
      state.refilledPrepaidAccount = true;
      state.refillPrepaidAccountFailed = false;
      state.refill = payload.response;
      if (payload.response?.status !== "SUCCESS") {
        console.warn(
          "Possible failure; Expected response.status = SUCCESS, got response.status =",
          payload.response?.status
        );
      }
      if (
        !(
          payload.response?.orderDetails &&
          payload.response.orderDetails.orderNumber
        )
      ) {
        console.log(
          "Possible failure; expected orderDetails.orderNumber in the response"
        );
      }
      state.lastActionAt = Date.now();
    },
    refillPrepaidAccountFailure: (state, { payload }) => {
      console.log("refillPrepaidAccountFailure payload", payload);
      state.refillingPrepaidAccount = false;
      state.refilledPrepaidAccount = false;
      state.refillPrepaidAccountFailed = true;
      state.refillErrorCode = payload.errorCode;
    },

    redeemRefill: (state) => {
      state.redeemingRefill = true;
      state.redeemedRefill = false;
      state.redeemRefillFailed = false;
    },
    
    redeemRefillSuccess: (state, { payload }) => {
      console.log("redeemRefillSuccess payload", payload);
      state.redeemingRefill = true;
      state.redeemedRefill = true;
      state.redeemRefillFailed = false;
      state.pinRefill = payload;
      state.lastActionAt = Date.now();
    },
    redeemRefillFailure: (state, { payload }) => {
      console.log("redeemRefillFailure payload", payload);
      state.redeemingRefill = false;
      state.redeemedRefill = false;
      state.redeemRefillFailed = true;
      state.pinRefill = payload;
    },

    fetchPaymentInfo: (state) => {
      state.fetchingPaymentInfo = true;
      state.fetchedPaymentInfo = false;
      state.fetchPaymentInfoFailed = false;
    },
    fetchPaymentInfoSuccess: (state, { payload }) => {
      console.log("fetchPaymentInfoSuccess payload", payload);
      if (
        payload.businessErrorCode &&
        payload.businessErrorCode === "BUS_PMT_006"
      ) {
        //  "message": "No payment instrument details found for this customer",
        console.log(
          "❗️ No payment instrument details found for this customer"
        );
      }
      state.paymentInfo = payload.paymentInfo;
      if (payload.paymentInfo) {
        state.autoRefillEnabled = payload.paymentInfo.enrollAutoPayment;
      }
      state.fetchingPaymentInfo = false;
      state.fetchPaymentInfoFailed = false;
      state.fetchedPaymentInfo = true;
      state.lastActionAt = Date.now();
    },
    fetchPaymentInfoFailure: (state, { payload }) => {
      console.log("fetchPaymentInfoFailure payload", payload);
      state.fetchingPaymentInfo = false;
      state.fetchPaymentInfoFailed = true;
      state.fetchedPaymentInfo = false;
    },

    addPaymentInstrument: (state) => {
      resetPaymentInstrumentState(state);
      state.addingPaymentInstrument = true;
    },
    
    addPaymentInstrumentSuccess: (state, { payload }) => {
      console.log("addPaymentInstrumentSuccess payload", payload);
      state.addedPaymentInstrument = true;
      state.addingPaymentInstrument = false;
      state.addPaymentInstrumentFailed = false;
      state.lastActionAt = Date.now();
    },
    addPaymentInstrumentFailure: (state, { payload }) => {
      console.log("addPaymentInstrumentFailure payload", payload);
      state.addedPaymentInstrument = false;
      state.addingPaymentInstrument = false;
      state.addPaymentInstrumentFailed = true;
      state.paymentInstrumentErrorCode = payload.businessErrorCode;
    },

    removePaymentInstrument: (state) => {
      resetPaymentInstrumentState(state);
      state.removingPaymentInstrument = true;
    },
    removePaymentInstrumentSuccess: (state, { payload }) => {
      console.log("removePaymentInstrumentSuccess payload", payload);
      state.removedPaymentInstrument = true;
      state.removingPaymentInstrument = false;
      state.removePaymentInstrumentFailed = false;
      state.billing = { ...blankBillingData };
      state.lastActionAt = Date.now();
    },
    removePaymentInstrumentFailure: (state, { payload }) => {
      console.log("removePaymentInstrumentFailure payload", payload);
      state.removedPaymentInstrument = false;
      state.removingPaymentInstrument = false;
      state.removePaymentInstrumentFailed = true;
    },

    updatePaymentInstrument: (state) => {
      resetPaymentInstrumentState(state);
      state.updatingPaymentInstrument = true;
    },
    updatePaymentInstrumentSuccess: (state, { payload }) => {
      console.log("updatePaymentInstrumentSuccess payload", payload);
      state.updatedPaymentInstrument = true;
      state.updatingPaymentInstrument = false;
      state.updatePaymentInstrumentFailed = false;
      state.lastActionAt = Date.now();
    },
    updatePaymentInstrumentFailure: (state, { payload }) => {
      console.log("updatePaymentInstrumentFailure payload", payload);
      state.updatedPaymentInstrument = false;
      state.updatingPaymentInstrument = false;
      state.updatePaymentInstrumentFailed = true;
    },

    validateActivationCode: (state, { payload }) => {
      state.validatingActivationCode = true;
      state.validatedActivationCode = false;
      state.validateActivationCodeFailed = false;
    },
    validateActivationCodeSuccess: (state, { payload }) => {
      console.log("validateActivationCodeSuccess payload", payload);
      state.activationCode = payload.pinDetails[0];
      let expDate = new Date(Date.parse(state.activationCode.expirationDate)); // parse just the date, not the time port
      state.activationCode.formattedExpirationDate = sprintf(
        `%d/%d/%d`,
        expDate.getMonth() + 1,
        expDate.getDate(),
        expDate.getFullYear()
      );
      state.validatedActivationCode = true;
      state.validatingActivationCode = false;
      state.validateActivationCodeFailed = false;
      state.lastActionAt = Date.now();
    },
    validateActivationCodeFailure: (state, { payload }) => {
      console.log("validateActivationCodeFailure payload", payload);
      state.activationCode = null;
      state.validatedActivationCode = false;
      state.validatingActivationCode = false;
      state.validateActivationCodeFailed = true;
    },

    fetchIdentityInfo: (state) => {
      state.fetchingIdentity = true;
    },
    fetchIdentityInfoSuccess: (state, { payload }) => {
      console.log("fetchIdentityInfoSuccess payload", payload);
      state.identity = payload.identity;
      state.identityAccounts = payload.identityAccounts;
      state.pronoun = payload.pronoun;
      state.chosenName = payload.chosenName;
      state.fetchingIdentity = false;
      state.fetchIdentityFailed = false;
      state.fetchedIdentity = true;
      state.lastActionAt = Date.now();
    },
    fetchIdentityInfoFailure: (state, { payload }) => {
      console.log("fetchIdentityInfoFailure payload", payload);
      state.fetchingIdentity = false;
      state.fetchIdentityFailed = true;
      state.fetchedIdentity = false;
      state.identityErrorCode = payload.errorCode;
      state.identityErrorMessage = payload.errorMessage;
    },

    updateCustomer: (state) => {
      state.updatingIdentity = true;
      state.updatedIdentity = false;
      state.updateIdentityFailed = false;
    },
    updateCustomerSuccess: (state, { payload }) => {
      console.log("updateCustomerSuccess payload", payload);
      state.updatingIdentity = false;
      state.updateIdentityFailed = false;
      state.updatedIdentity = true;
      state.lastActionAt = Date.now();
    },
    updateCustomerFailure: (state, { payload }) => {
      console.log("updateCustomerFailure payload", payload);
      state.updatingIdentity = false;
      state.updateIdentityFailed = true;
      state.updatedIdentity = false;
      state.identityErrorCode = payload.errorCode;
      state.identityErrorMessage = payload.errorMessage;
    },

    updateIDCMTCustomerInfo: (state) => {
      state.updatingIDCMTCustomerInfo = true;
      state.updatedIDCMTCustomerInfo = false;
      state.updateIDCMTCustomerInfoFailed = false;
    },
    updateIDCMTCustomerInfoSuccess: (state, { payload }) => {
      console.log("updateIDCMTCustomerInfoSuccess payload", payload);
      state.updatingIDCMTCustomerInfo = false;
      state.updatedIDCMTCustomerInfo = true;
      state.updateIDCMTCustomerInfoFailed = false;
      state.lastActionAt = Date.now();
    },
    updateIDCMTCustomerInfoFailure: (state, { payload }) => {
      console.log("updateIDCMTCustomerInfoFailure payload", payload);
      state.updatingIDCMTCustomerInfo = false;
      state.updatedIDCMTCustomerInfo = false;
      state.updateIDCMTCustomerInfoFailed = true;
    },

    updateCustomerInfo: (state, { payload }) => {
      state.dashboard.customerInfo = {
        ...state.dashboard.customerInfo,
        ...payload,
      };
      let currentAccount;
      if (state.identityAccounts) {
        currentAccount = state.identityAccounts.find((acct) => {
          return payload.accountId === acct.accountNumber;
        });
        currentAccount.prepaidSMSOptIn = payload.smsOptIn;
        currentAccount.prepaidLangPreference = payload.language;
      }
    },

    fetchCustomerDashboard: (state) => {
      state.fetchingDashboard = true;
      state.fetchedDashboard = false;
    },
    fetchCustomerDashboardSuccess: (state, { payload }) => {
      console.log("fetchCustomerDashboardSuccess payload", payload);
      state.dashboard = payload;

      state.hsdService = getHSDService(state.dashboard);
      state.tv200Service = getTv200Service(state.dashboard);
      state.xitvProducts = getXITVServices(state.dashboard);
      state.xitvBasic = getXITVBasic(state);

      state.availableHSDServices = getAvailableHSDServices(
        state.dashboard.availablePlans
      );
      state.hsd30Service = state.availableHSDServices.find(
        (plan) => plan.productCode === "HSD_030"
      );
      state.hsd7Service = state.availableHSDServices.find(
        (plan) => plan.productCode === "HSD_007"
      );

      state.availableXitvBasic = getAvailableXITVBasic(
        state.dashboard.availablePlans
      );
      state.availableXitvPackages = getAvailableXitvPackages(
        state.dashboard.availablePlans
      );
      state.availableXitvAddOns = getAvailableXitvAddOns(
        state.dashboard.availablePlans
      );
      state.availableXitvSpanishAddOns = getAvailableXitvSpanishAddOns(
        state.dashboard.availablePlans
      );

      let xitvPackageCodes = state.availableXitvPackages.map(
        (p) => p.productCode
      );
      // console.log('xitvPackageCodes', xitvPackageCodes)
      state.cart.packages = state.xitvProducts
        .filter((product) => xitvPackageCodes.includes(product.productCode))
        .map((product) => product.productCode);

      let xitvEnglishAddonCodes = state.availableXitvAddOns.map(
        (p) => p.productCode
      );
      let xitvSpanishAddonCodes = state.availableXitvSpanishAddOns.map(
        (p) => p.productCode
      );
      let xitvAddonCodes = [...xitvEnglishAddonCodes, ...xitvSpanishAddonCodes];
      // console.log('xitvAddonCodes', xitvAddonCodes)
      state.cart.addOns = state.xitvProducts
        .filter((product) => xitvAddonCodes.includes(product.productCode))
        .map((product) => product.productCode);

      if (
        (state.cart.addOns.length || state.cart.packages.length) &&
        !state.cart.packages.includes("VDO_XITV_30")
      ) {
        state.cart.packages = [...state.cart.packages, "VDO_XITV_30"];
      }

      if (state.dashboard.availablePlans) {
        let indexTvInAvailableServices =
          state.dashboard.availablePlans.findIndex(
            (p) => p.productCode === "VDO_XITV_30"
          );
        state.tvServiceAvailableForRefill = indexTvInAvailableServices >= 0;
      } else {
        state.tvServiceAvailableForRefill = false;
      }

      state.acpDetail = state?.dashboard?.acpDetail;

      state.cartCount = getCartCount(state.cart,state.cartCount);
      state.cartSubtotal = getCartSubtotal(
        state.cart,
        state.dashboard,
        state.promos,
        dashboardSelectors.getHaveValidACP(state) &&
          !dashboardSelectors.getIsTribalACP(state)
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;

      state.isVanAccount = state.dashboard?.customerInfo?.accountType === "VAN";
      state.fetchingDashboard = false;
      state.fetchDashboardFailed = false;
      state.fetchedDashboard = true;
      state.lastActionAt = Date.now();
    },
    fetchCustomerDashboardFailure: (state, { payload }) => {
      console.log("fetchCustomerDashboardFailure payload", payload);
      state.fetchingDashboard = false;
      state.fetchDashboardFailed = true;
      state.dashboardErrorCode = payload.errorCode;
      state.dashboardErrorMessage = payload.errorMessage;
    },

    restartDevice: (state) => {
      state.restartingDevice = true;
    },
    restartDeviceSuccess: (state, { payload }) => {
      console.log("restartDeviceSuccess payload", payload);
      state.restartDeviceStatus = payload;
      state.restartingDevice = false;
      state.restartingDeviceFailed = false;
      state.restartedDevice = true;
      state.lastActionAt = Date.now();
    },
    restartDeviceFailure: (state, { payload }) => {
      console.log("restartDeviceFailure payload", payload);
      state.restartingDevice = false;
      state.restartingDeviceFailed = true;
      state.restartedDevice = false;
    },

    fetchAuxAccount: (state) => {
      state.fetchingAuxAccount = true;
      state.fetchedAuxAccount = false;
      state.fetchAuxAccountFailed = false;
      state.updatedScheduledMove = false;
    },
    fetchAuxAccountSuccess: (state, { payload }) => {
      console.log("fetchAuxAccountSuccess payload", payload);
      state.auxAccount = payload;
      state.fetchingAuxAccount = false;
      state.fetchAuxAccountFailed = false;
      state.fetchedAuxAccount = true;

      if (
        state.auxAccount &&
        state.auxAccount.auxDetails &&
        state.auxAccount.auxDetails.length
      ) {
        state.scheduledMove = state.auxAccount.auxDetails.find((a) => {
          return (
            a.auxAcctType === "MOVE" &&
            a.auxAcctScheduleDate &&
            a.auxAcctStatus !== "Closed"
          );
        });
        if (state.scheduledMove) {
          state.haveScheduledMove = true;
        }
      }
      state.lastActionAt = Date.now();
    },
    fetchAuxAccountFailure: (state, { payload }) => {
      console.log("fetchAuxAccountFailure payload", payload);
      state.fetchingAuxAccount = false;
      state.fetchedAuxAccount = false;
      state.fetchAuxAccountFailed = true;
    },

    createAuxAccount: (state) => {
      state.creatingAuxAccount = true;
      state.createdAuxAccount = false;
      state.createAuxAccountFailed = false;
      state.updatedScheduledMove = false;
    },
    createAuxAccountSuccess: (state, { payload }) => {
      console.log("createAuxAccountSuccess payload", payload);
      state.newAuxAccount = payload;
      state.creatingAuxAccount = false;
      state.createAuxAccountFailed = false;
      state.createdAuxAccount = true;
      state.lastActionAt = Date.now();
    },
    createAuxAccountFailure: (state, { payload }) => {
      console.log("createAuxAccountFailure payload", payload);
      state.creatingAuxAccount = false;
      state.createdAuxAccount = false;
      state.createAuxAccountFailed = true;
    },
    updateScheduledMove: (state) => {
      state.updatingScheduledMove = true;
      state.updatedScheduledMove = false;
      state.updateScheduledMoveFailed = false;
    },
    updateScheduledMoveSuccess: (state, { payload }) => {
      console.log("updateScheduledMoveSuccess payload", payload);
      state.updatingScheduledMove = false;
      state.updatedScheduledMove = true;
      state.updateScheduledMoveFailed = false;

      // XXX These should only be done on Cancel, not date change.
      // May neeed to change in the future if we add support for changing dates
      state.scheduledMove = null;
      state.haveScheduledMove = false;
      state.cancelledScheduledMove = true;
      state.createdAuxAccount = false;
      state.newAuxAccount = null;
      state.auxAccount = null;
      state.lastActionAt = Date.now();
    },
    updateScheduledMoveFailure: (state, { payload }) => {
      console.log("createAuxAccountFailure payload", payload);
      state.updatingScheduledMove = false;
      state.updatedScheduledMove = false;
      state.updateScheduledMoveFailed = true;
    },
    updateCpcResponse: (state, { payload }) => {
      state.cpcResponse = payload;
    },
    fetchBroadbandFactsContent:(state,{payload})=>{
      console.log("BROADBAND FACTS PAYLOAD >>>", payload)
      state.broadbandFactsResponse = payload;
    },
    fetchBroadbandFactsContentSuccess: (state, { payload }) => {
      console.log("fetchBroadbandFactsContentSuccess payload", payload);
      state.addedPaymentInstrument = true;
      state.addingPaymentInstrument = false;
      state.addPaymentInstrumentFailed = false;
      state.lastActionAt = Date.now();
    },
    fetchBroadbandFactsContentFailure: (state, { payload }) => {
      console.log("fetchBroadbandFactsContentFailure payload", payload);
      state.addedPaymentInstrument = false;
      state.addingPaymentInstrument = false;
      state.addPaymentInstrumentFailed = true;
      state.paymentInstrumentErrorCode = payload.businessErrorCode;
    },
  },
});

export { dashboard };
export const {
  pingSession,
  pingSessionSuccess,
  pingSessionFailure,
  expireSession,
  setSessionError,
  updateFirstName,
  updateLastName,
  toggleField,
  updateField,
  togglePackage,
  updateCpcResponse,
  updateScheduledMoveFailure,
  updateScheduledMoveSuccess,
  updateScheduledMove,
  createAuxAccountFailure,
  createAuxAccountSuccess,
  createAuxAccount,
  fetchAuxAccountFailure,
  fetchAuxAccountSuccess,
  fetchAuxAccount,
  restartDeviceFailure,
  restartDeviceSuccess,
  restartDevice,
  fetchCustomerDashboardFailure,
  fetchCustomerDashboardSuccess,
  fetchCustomerDashboard,
  updateIDCMTCustomerInfoFailure,
  updateIDCMTCustomerInfoSuccess,
  updateIDCMTCustomerInfo,
  updateCustomerFailure,
  updateCustomerSuccess,
  updateCustomer,
  fetchIdentityInfoFailure,
  fetchIdentityInfoSuccess,
  fetchIdentityInfo,
  validateActivationCodeFailure,
  validateActivationCodeSuccess,
  validateActivationCode,
  updatePaymentInstrumentFailure,
  updatePaymentInstrumentSuccess,
  updatePaymentInstrument,
  removePaymentInstrumentFailure,
  removePaymentInstrumentSuccess,
  removePaymentInstrument,
  addPaymentInstrumentFailure,
  addPaymentInstrumentSuccess,
  addPaymentInstrument,
  fetchPaymentInfoFailure,
  fetchPaymentInfoSuccess,
  fetchPaymentInfo,
  redeemRefillFailure,
  redeemRefillSuccess,
  redeemRefill,
  refillPrepaidAccountFailure,
  refillPrepaidAccountSuccess,
  refillPrepaidAccount,
  fetchTaxDataFailure,
  fetchTaxDataSuccess,
  fetchTaxData,
  fetchPromosFailure,
  fetchPromosSuccess,
  fetchPromos,
  disableAutoRefillFailure,
  disableAutoRefillSuccess,
  disableAutoRefill,
  enableAutoRefillFailure,
  enableAutoRefillSuccess,
  enableAutoRefill,
  fetchPrepaidTransactionsFailure,
  fetchPrepaidTransactions,
  resetBilling,
  clearCart,
  updateInternet,
  updateCustomerInfo,
  toggleInternet,
  fetchPrepaidTransactionsSuccess,
  addWinbackPromo,
  removePromo,
  addPromo,
  removeAllTvProducts,
  fetchBroadbandFactsContent,
  fetchBroadbandFactsContentSuccess,
  fetchBroadbandFactsContentFailure,
} = dashboard.actions;
export default dashboard.reducer;
