import DashboardLayout from '../../layouts/DashboardLayout'
import { useHistory } from 'react-router-dom'
import ProgressBar from '../../components/ProgressBar'
import Cart from '../../components/refill/Cart'
import StickyCart from '../../components/refill/StickyCart'
import { useEffect, useState } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import PaymentMethod from '../../components/dashboard/PaymentMethod'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const MoveRefillPayment = (props) => {
  const {
    cartContent,
    locale,
    paymentInfo,
    fetchedPaymentInfo,
    updateBilling,
    addedPaymentInstrument,
    cartCount,
    dashboard,
    refill,
    cart,
    havePromoInCart,
    cartTotal
  } = props
  const cartComponentContent = { cartContent }

  const [processNext, setProcessNext] = useState(false)
  const [formValid, updateFormStatus] = useState(true)
  const [loadCPCForm, setLoadCPCForm] = useState(false)
  const [startCPCEvents, setStartCPCEvents] = useState(false)

  useEffect(() => {
    props.updatePageClass('dashboard refill move-refill')
    trackEvent({
      event: 'move refill flow',
      moveStep: 'step 2',
      moveStepName: 'payment',
      moveStepDetail: paymentInfo ? "saved debit credit" : "debit credit",
    })
  }, []) // eslint-disable-line

  const history = useHistory()
  useEffect(() => {
    sessionStorage.removeItem("alreadyLoaded");
    setStartCPCEvents(true)
  }, [])
  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true)
  }, [startCPCEvents])

  const handleNext = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    updateBilling({ submitAndContinue: true })
    trackEvent({
      event: "move refill info",
      moveStep: "step 2",
      moveStepName: "payment",
      moveEvent: "payment method component",
      moveDetail: "add submit",
    });
  }
  const onContinue = () => {
    history.push('/' + locale + '/move/refill-review')
  }

  const onUpdateField = (e) => {
    console.log(e)
  }
  useEffect(() => {
    if (paymentInfo || addedPaymentInstrument) {
      history?.push('/' + locale + '/move/refill-review')
    }
    // eslint-disable-next-line
  }, [fetchedPaymentInfo])

  const goToOrderReview = () => {
    console.log('goToOrderReview')
  }
  const setFormValid = (status) => {
    handleFormStatus(status)
  }
  const handleFormStatus = (formStatus) => {
    updateFormStatus(formStatus)
  }

  return (
    <DashboardLayout locale={props.locale} pageTitle={'Move Refill Payment'}>
      <TrackUserData data={dashboard} />
      <TrackPurchaseData offerIncluded={havePromoInCart} price={cartTotal} productID={cart?.internet} productType={'prepaid-refill'} quantity={cartCount} transactionID={""} screenName="payment"/>
      {props.refillContent && props.refillContent.loaded && fetchedPaymentInfo && (
        <div>
          <ProgressBar title={'Add a payment for your refill'} percent={66} />
          <div className="main__width-constraint edit-payment">
            <br />
            <h1 className="page__title">
              {
                props.refillContent.refillFlow.orderConfirmation
                  .enterYourPaymentInformation
              }
            </h1>
            <br />
            <div className="main__desktop-body">
              <PaymentMethod
                onUpdateField={onUpdateField}
                goToOrderReview={goToOrderReview}
                setFormValid={setFormValid}
                nextClicked={processNext}
                startCPCEvents={startCPCEvents}
                minimalView={false}
                moveView={true}
                refillView={true}
                hideButton={true}
                ignoreSaved={true}
                refillMoveReview={true}
                refillMovePayment={true}
                onContinue={onContinue}
              />
              <div className="cart__display-large">
                <hr />
                <div className="card card--clear card--small card--right">
                  <input
                    type="button"
                    className="button button--inline"
                    data-testid="button"
                    value={'Next'}
                    onClick={(e) => handleNext(e)}
                    //disabled={!formValid}
                    disabled={cartTotal === 0}
                    data-tracking={digitalDataButtonClick('handle-next', 'Next')}
                  />
                </div>
              </div>
            </div>
            <div className="cart__display-large main__desktop-sidebar-right">
              <Cart content={cartComponentContent} />
            </div>
            <div className="cart__display-small-medium">
              <StickyCart
                nextDisabled={cartTotal === 0}
                handleNext={handleNext}
                cartComponentContent={cartComponentContent}
              />
            </div>
          </div>
        </div>
      )}
      <TrackPageComplete />
    </DashboardLayout>
  )
}

export default withDashboard(MoveRefillPayment)
