import { useEffect, useState } from "react";
import PinSubmissionForm from "../../components/refill/PinSubmissionForm";
import PaymentMethod from "../../components/dashboard/PaymentMethod";
import HSDAccordion from "../../components/refill/HSDAccordion";
import Tv200Accordion from "../../components/refill/Tv200Accordion";
import withDashboard from "../../redux/connectors/dashboard";
import { trackEvent } from "../../utils/utils";
import { useForm } from "react-hook-form";
import { digitalDataButtonClick } from "../../utils/adobe-analytics";

const RefillTab = (props) => {
  const {
    refillContent,
    updateProgress,
    updatePinFlow,
    setPrepaidInstantUnavailableNotice,
    fetchedDashboard,
    paymentInfo,
    fetchedPaymentInfo,
    cpcResponse,
    updateField,
    handleFormStatus,
    fetchBroadbandFactsContent
  } = props;

  const [showPinTab, setShowPinTab] = useState(false);
  const showTv200 = false;
  const trackClick = (isPinFlow) => {
    trackEvent({
      event: "pin refill info",
      refillStep: "step 1",
      refillStepName: "start refill",
      refillEvent: "refill payment tab",
      refillDetail: isPinFlow ? "refill pin code" : "debit credit card",
    });
  };
  const [startCPCEvents, setStartCPCEvents] = useState(false);
  const [processNext, setProcessNext] = useState(false);
  const { handleSubmit, register, trigger, errors, formState } = useForm({
    mode: "onChange",
  });
  const { isValid } = formState;
  const setFormValid = (status) => {
    handleFormStatus(status);
  };
  
  useEffect(() => {
    setStartCPCEvents(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!fetchedDashboard && !fetchedPaymentInfo) return <div />;

  const onUpdateField = (e) => {
    console.log(e);
  };

  const goToOrderReview = (e) => {
    console.log("goToOrderReviewgoToOrderReview");
  };

  return (
    <div className="main__width-constraint">
      <div className="refill-tab">
        <button
          className={`refill-tab-button ${showPinTab ? "" : "active"}`}
          onClick={() => {
            setShowPinTab(false);
            updateProgress(33);
            updatePinFlow(false);
            trackClick(false);
          }}
          data-tracking={digitalDataButtonClick('refill-cart-debit', refillContent.refillFlow.cart.debitCreditCard)} 
        >
          {refillContent.refillFlow.cart.debitCreditCard}
        </button>
        <button
          className={`refill-tab-button ${showPinTab ? "active" : ""}`}
          onClick={() => {
            setShowPinTab(true);
            updateProgress(33);
            updatePinFlow(true);
            trackClick(true);
          }}
          data-tracking={digitalDataButtonClick('refill-cart-pin', refillContent.refillFlow.cart.refillPiNCode)}
        >
          {refillContent.refillFlow.cart.refillPiNCode}
        </button>
        {!showPinTab ? (
          <div className="edit-payment">
            {fetchedPaymentInfo && paymentInfo ? (
              <>
                <PaymentMethod
                  onUpdateField={onUpdateField}
                  goToOrderReview={goToOrderReview}
                  setFormValid={setFormValid}
                  nextClicked={processNext}
                  startCPCEvents={startCPCEvents}
                  minimalView={true}
                  refillView={true} />
              </>
            ) : null}
            <HSDAccordion
              setPrepaidInstantUnavailableNotice={setPrepaidInstantUnavailableNotice} />
            {showTv200 ? <Tv200Accordion /> : null}
          </div>
        ) : (
          <div>
            <PinSubmissionForm updateProgress={updateProgress} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withDashboard(RefillTab);
