// You’re all set!
// Your payment has been processed successfully. Want to make it easier next time? Log in to  your account at my.xfinityprepaid.com to set up Auto-Pay!

// Need help? We’re here for you. You can always contact Customer Care at 1-855-75-PREPAID.

const errorCodeMap = {
    GENERIC: {
        en: "Something's not right.\nWe're having trouble completing your request. Please contact Customer Care at 1-855-75-PREPAID so we can help you!",
        es: 'Algo no está bien.\nEstamos teniendo dificultades para completar tu solicitud. Comunícate con nuestro equipo de Atención al Cliente al 855-75-PREPAGO para que te podamos ayudar.'
    },
    BUS_CUSTOMER_DASHBOARD_009: {
        en: 'Hmm, we’re having trouble locating your account. Please double-check the information you entered and try again.',
        es: 'Hmm, we’re having trouble locating your account. Please double-check the information you entered and try again.'
    },
    // errorCode: "BUS_CUSTOMER_DASHBOARD_025"
    // errorMessage: "When Flow is Ezrefill, Valid Phone and Zip and HouseNumber should be provided"
    BUS_CUSTOMER_DASHBOARD_025: {
        en: 'Hmm, we’re having trouble locating your account. Please double-check the information you entered and try again.',
        es: 'Hmm, we’re having trouble locating your account. Please double-check the information you entered and try again.'
    },
    'PAYMENT-1175': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1175",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1175'
    },
    'PAYMENT-8372': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. The billing address you used is not valid. Please update your credit card billing address and try again.\n\nError Code: PAYMENT-8372",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. La dirección de facturación que usaste no es válida. Actualiza la dirección de facturación de tu tarjeta de crédito e inténtalo de nuevo.\n\nError Code: PAYMENT-8372'
    },
    'PAYMENT-1151': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1151",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1151'
    },
    'PAYMENT-1003': {
        en: 'Hmm, something’s not right here.\nIt looks like the card number you used does not match the type of card you selected. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1003',
        es: 'Mmm, algo no está bien.\nParece que el número de la tarjeta que usaste no coincide con el tipo de tarjeta que seleccionaste. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1003'
    },
    'PAYMENT-1009': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1009",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1009'
    },
    'PAYMENT-8015': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you entered isn't working. Please double-check the information you entered or try again with another card—and if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8015",
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8015'
    },
    'PAYMENT-1156': {
        en: "Hmm, something’s not right here.\nIt looks like the CVV you provided isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1156",
        es: 'Mmm, algo no está bien.\nParece que el CVV que proporcionaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1156'
    },
    'PAYMENT-1147': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1147",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1147'
    },
    'PAYMENT-1195': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1195",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1195'
    },
    'PAYMENT-1239': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1239",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1239'
    },
    'PAYMENT-8032': {
        en: 'Uh oh! Something went wrong.\nIt looks like the connection timed out, and we can’t complete your request. Please check that you have an internet connection, and try again.\n\nError Code: PAYMENT-8032',
        es: '¡Oh, oh! Se ha producido un error.\nParece que se agotó el tiempo de conexión y no podemos completar tu solicitud. Comprueba si tienes conexión al Internet e inténtalo de nuevo.\n\nError Code: PAYMENT-8032'
    },
    'PAYMENT-1149': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1149",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1149'
    },
    'PAYMENT-1150': {
        en: 'Hmm, something’s not right here.\nIt looks like your card is expired. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1150',
        es: 'Mmm, algo no está bien.\nParece que tu tarjeta está vencida. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1150'
    },
    'PAYMENT-1197': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1197",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1197'
    },
    'PAYMENT-8023': {
        en: "Sorry, this isn’t working.\nIt looks like the CVV you provided isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-8023",
        es: 'Mmm, algo no está bien.\nParece que el CVV que proporcionaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-8023'
    },
    'PAYMENT-1194': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1194",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1194'
    },
    'PAYMENT-8373': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again.\n\nError Code: PAYMENT-8373",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo.\n\nError Code: PAYMENT-8373'
    },
    'PAYMENT-1244': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1244",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1244'
    },
    'PAYMENT-1225': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1225",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1225'
    },
    'PAYMENT-1146': {
        en: "Hmm, something’s not right here.\nWe're having trouble processing your transaction. Please try again.\n\nError Code: PAYMENT-1146",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo.\n\nError Code: PAYMENT-1146'
    },
    'PAYMENT-1243': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1243",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1243'
    },
    'PAYMENT-8132': {
        en: 'Uh oh! Something went wrong.\nIt looks like the connection reset, and we can’t complete your request. Please check that you have an internet connection, and try again.\n\nError Code: PAYMENT-8132',
        es: '¡Oh, oh! Se ha producido un error.\nParece que se reinició la conexión y no podemos completar tu solicitud. Comprueba si tienes conexión al Internet e inténtalo de nuevo.\n\nError Code: PAYMENT-8132'
    },
    'PAYMENT-1310': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again. \n\nError Code: PAYMENT-1310",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo.\n\nError Code: PAYMENT-1310'
    },
    'PAYMENT-1188': {
        en: "Uh oh! Something went wrong.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1188",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1188'
    },
    'PAYMENT-1164': {
        en: 'Uh oh! Something went wrong.\nIt looks like the connection timed out, and we can’t complete your request. Please check that you have an internet connection, and try again.\n\nError Code: PAYMENT-1164',
        es: '¡Oh, oh! Se ha producido un error.\nParece que se agotó el tiempo de conexión y no podemos completar tu solicitud. Comprueba si tienes conexión al Internet e inténtalo de nuevo.\n\nError Code: PAYMENT-1164'
    },
    'PAYMENT-8017': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8373",
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8017'
    },
    'PAYMENT-8312': {
          en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-8312",
          es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-8312'
    },
    'PAYMENT-1309': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again.\n\nError Code: PAYMENT-1309",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo.\n\nError Code: PAYMENT-1309'
    },
    'PAYMENT-1167': {
        en: "Hmm, something’s not right here.\nIt looks like the CVV you provided isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1167",
        es: 'Mmm, algo no está bien.\nParece que el CVV que proporcionaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1167'
    },
    'PAYMENT-8039': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again.\n\nError Code: PAYMENT-8039",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo.\n\nError Code: PAYMENT-8039'
    },
    'PAYMENT-8094': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-8094",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-8094'
    },
    'PAYMENT-8000': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again.\n\nError Code: PAYMENT-8000",
        es: 'Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo.\n\nError Code: PAYMENT-8000'
    },
    'PAYMENT-8097': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8097",
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8097'
    },
    'PAYMENT-1043': {
        en: 'Hmm, something’s not right here.\nIt looks like the expiration date you entered has already passed. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1043',
        es: 'Mmm, algo no está bien.\nParece que la fecha de vencimiento que ingresaste ya ha pasado. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1043'
    },
    'PAYMENT-8006': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8006",
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8006'
    },
    'PAYMENT-8096': {
        en: 'Something went wrong.\nSorry, but we’re having trouble completing your request. Please try again—and if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8096',
        es: 'Se ha producido un error. Lo sentimos, pero estamos teniendo dificultades para completar tu solicitud. Inténtalo de nuevo y, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8096'
    },
    'PAYMENT-8349': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8349",
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8349'
    },
    'PAYMENT-8600': {
        en: "Sorry, this isn't working.\nIt looks like something went wrong with the connection, and we can’t complete your request. Please check your internet connection, and try again. If that doesn’t work, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8600",
        es: 'Lo sentimos, pero esto no funciona. Parece que se produjo un error con la conexión y no podemos completar tu solicitud. Comprueba si tienes conexión al Internet e inténtalo de nuevo. Si eso no funciona, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8600'
    },
    'PAYMENT-1240': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1240",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1240'
    },
    'PAYMENT-8524': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card—and if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-8524",
        es: 'Mmm, algo no está bien. Parece que el número de tarjeta que ingresaste no funciona.  Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta, y si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-8524'
    },
    'PAYMENT-9900': {
        en: "Hmm, something’s not right here.\nIt looks like the card you used isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-9900",
        es: 'Mmm, algo no está bien.\nParece que la tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-9900'
    },
    'PAYMENT-1001': {
        en: 'Hmm, something’s not right here.\nIt looks like the length of the card number you entered is not valid. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-1001',
        es: 'Mmm, algo no está bien.\nParece que la longitud del número de la tarjeta que ingresaste no es válida. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-1001'
    },
    'PAYMENT-1038': {
        en: 'Oops! Something is missing.\nPlease add your address information and try again.\n\nError Code: PAYMENT-1038',
        es: '¡Uy! Falta algo.\nAñade la información de tu dirección e inténtalo de nuevo.\n\nError Code: PAYMENT-1038'
    },
    'PAYMENT-1204': {
        en: "Sorry, this isn’t working.\nWe're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: PAYMENT-1204",
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: PAYMENT-1204'
    },
    'PAYMENT-8001': {
        en: 'Oops! Something is missing.\nPlease double-check the information you entered and try again.\n\nError Code: PAYMENT-8001',
        es: '¡Uy! Falta algo.\nComprueba si la información que ingresaste es correcta e inténtalo de nuevo.\n\nError Code: PAYMENT-8001'
    },
    'PAYMENT-1004': {
        en: 'Oops! Something is missing.\nPlease enter your credit card information and try again.\n\nError Code: PAYMENT-1004',
        es: '¡Uy! Falta algo.\nIngresa los datos de tu tarjeta de crédito e inténtalo de nuevo.\n\nError Code: PAYMENT-1004'
    },
    'PAYMENT-8319': {
        en: "Hmm, something’s not right here.\nIt looks like the card number you entered isn't working. Please double-check the information you entered or try again with another card.\n\nError Code: PAYMENT-8319",
        es: 'Mmm, algo no está bien.\nParece que el número de tarjeta que ingresaste no funciona. Comprueba si la información que ingresaste es correcta o inténtalo de nuevo con otra tarjeta.\n\nError Code: PAYMENT-8319'
    },
    'SYS-CUSTACT-02': {
        en: 'Sorry, this isn’t working.\nIt looks like a system error occurred, and we can’t complete your request. But don’t worry! We’ll be up and running again soon. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: SYS_CUSTACT_02',
        es: 'Lo sentimos, pero esto no funciona. Parece que se produjo un error en el sistema y no podemos completar tu solicitud. ¡Pero no te preocupes! Nos pondremos en marcha de nuevo muy pronto. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: SYS_CUSTACT_02'
    },
    'SYS-CUSTACT-04': {
        en: 'Sorry, this isn’t working.\nWe’re having trouble establishing a connection to our database. Please check that you have an internet connection, and try again. If that doesn’t work, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: SYS-CUSTACT_04',
        es: 'Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para conectarnos con nuestra base de datos. Comprueba si tienes conexión al Internet e inténtalo de nuevo. Si eso no funciona, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO. Error Code: SYS_CUSTACT_04'
    },
    'SYS-CUSTACT-06': {
        en: 'Sorry, this isn’t working.\nWe’re having trouble establishing a connection to our database. Please check that you have an internet connection, and try again. If that doesn’t work, you can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: SYS_CUSTACT_06',
        es: '"Lo sentimos, pero esto no funciona.\nEstamos teniendo dificultades para conectarnos con nuestra base de datos. Comprueba si tienes conexión al Internet e inténtalo de nuevo. Si eso no funciona, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO.\n\nError Code: SYS-CUSTACT_06'
    },
    'BUS-PINLESS-ACT-LOCATION': {
        en: "Something's not right.\nWe're having trouble completing your request. Please contact Customer Care at 1-855-75-PREPAID so we can help you!\n\nError Code: BUS_PINLESS_ACT_LOCATION",
        es: 'Algo no está bien. Estamos teniendo dificultades para completar tu solicitud. Comunícate con nuestro equipo de Atención al Cliente al 855-75-PREPAGO para que te podamos ayudar. Error Code:  BUS_PINLESS_ACT_LOCATION'
    },
    'BUS-PINLESS-ACT-PHONE': {
        en: "Hmm, let's try that again.\nIt looks like there's already an account with that phone number. Please double-check the phone number you provided and try again.\n\nNeed help? We’re here for you. You can always contact Customer Care at 1-855-75-PREPAID.\n\nError Code: BUS_PINLESS_ACT_PHONE",
        es: 'Vamos a intentarlo de nuevo.\nParece que ya hay una cuenta con ese número de teléfono. Comprueba si el número de teléfono que proporcionaste es correcto e inténtalo de nuevo.\n\n¿Necesitas ayuda? Estamos aquí para ti. Siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO.\n\nError Code: BUS_PINLESS_ACT_PHONE.'
    },
    'SYS_PMT_003': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'SYS_PMT_007': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'SYS_PMT_008': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'SYS_PMT_009': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'SYS_PMT_010': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'BUS_PMT_003': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'BUS_PMT_004': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'BUS_PMT_006': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'PAYMENT-8813': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'PAYMENT-9010': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    },
    'PAYMENT-3010': {
        en: "Sorry, this isn’t working. We're having trouble processing your transaction. Please try again, or if you need more help, you can always contact Customer Care at 1-855-75-PREPAID.",
        es: "Lo sentimos, pero esto no funciona. Estamos teniendo dificultades para procesar tu transacción. Inténtalo de nuevo o, si necesitas más ayuda, siempre puedes comunicarte con el Servicio de Atención al Cliente al 1-855-75-PREPAGO."
    }
}

function errorForCode(code, locale = 'en') {
    console.log('error for code, locale', code, locale)

    let key = code
    if (!errorCodeMap[key]) {
          key = key.replace(/[_]/g, '-')
    }
    if (errorCodeMap[key]) {
        if (errorCodeMap[key][locale]) {
            return errorCodeMap[key][locale]
        } else if (errorCodeMap[key].en) {
            return errorCodeMap[key].en
        }
    }
    return errorCodeMap.GENERIC[locale] || errorCodeMap.GENERIC.en
  }

const genericErrorTitle = {
    en: "Something's not right.",
    es: 'Algo no está bien.'
}

const genericErrorMessage = {
    en: "We're having trouble completing your request. Please contact Customer Care at 1-855-75-PREPAID so we can help you!",
    es: 'Estamos teniendo dificultades para completar tu solicitud. Comunícate con nuestro equipo de Atención al Cliente al 855-75-PREPAGO para que te podamos ayudar.'
}

export {
    errorForCode,
    genericErrorTitle,
    genericErrorMessage
}
