import React, { useState, useEffect } from 'react'
import { digitalDataButtonClick } from '../utils/adobe-analytics'

interface ComponentProps {
    text?: String
    style?: String
    modal?: String
    displayModal?: Boolean
    onClose?: Function
}
const NotificationBanner = ({text, style, modal, displayModal, onClose}: ComponentProps) => {

    const [showBanner, setShowBanner] = useState(displayModal)
    const closeModal = () => {
        setShowBanner(false)
        if (onClose) {
            onClose()
        }
    }

    useEffect(() => {
        setShowBanner(displayModal)
        // eslint-disable-next-line
    }, [displayModal])

    return (
        <div className={`notification-banner success ${style ? style : ''} ${showBanner ? '' : 'hide'}`}>
            <div className='main__width-constraint'>
                <div className="icon" aria-label="Yes, this was helpful">
                <img alt="check mark" className="icon" src='/site-assets/img/icon-check-black.svg' />
                </div>
                <div className="text">{text}</div>
                <div className="close-modal">
                    <button className="close-modal__btn" data-testid="btn-close-modal" onClick={() => closeModal()} data-tracking={digitalDataButtonClick('close-modal', 'Close', true)}><span className="visually-hidden">Close</span></button>
                    </div>
            </div>
        </div>
    )

}

export default NotificationBanner
