import React from "react";
import ActivationLayout from "../../layouts/ActivationLayout";
import { Helmet } from "react-helmet";
import NotificationBanner from "../../components/NotificationBanner";
import withActivation from "../../redux/connectors/activation";
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const formatPhoneNumber = (phone) => {
  return `${phone?.slice(0, 3)}-${phone?.slice(3, 6)}-${phone?.slice(6, 10)}`;
};

const PermissionError = (props) => {
  let { locale, sitecoreContent, fetchedContent, identity, fetchedIdentity, dashboard } =
    props;
  console.log(locale);
  let xfinityId;

  if (fetchedIdentity) {
    if (identity.idcmtMobileVerified) {
      xfinityId = formatPhoneNumber(identity.idcmtMobile);
    } else if (identity.idcmtUid) {
      xfinityId = identity.idcmtUid;
    } else if (identity.idcmtEmailVerified) {
      xfinityId = identity.idcmtEmail;
    }
  }

  if (!fetchedContent) {
    return <div />;
  }

  return (
    <ActivationLayout wrapperClass="permission-error">
      <TrackUserData data={dashboard} />
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Permission Error - Xfinity Prepaid</title>
          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <NotificationBanner
          text={
            sitecoreContent?.activation?.insufficientPermissionError
              ?.insufficientPermissionError.alert?.message
          }
          displayStyle="alert"
          modal="true"
        />

        <div className="permission-error__content">
          <h1 className="page__title">
            {
              sitecoreContent?.activation?.insufficientPermissionError
                .insufficientPermissionError?.title
            }
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html:
                sitecoreContent?.activation?.insufficientPermissionError
                  ?.insufficientPermissionError?.description,
            }}
          />

          <dl>
            <dt>Name</dt>
            <dd>
              {identity?.idcmtFirstName} {identity?.idcmtLastName}
            </dd>
            {xfinityId && <dt>Xfinity ID</dt>}
            {xfinityId && <dd>{xfinityId}</dd>}
            {identity?.idcmtMobile && <dt>Mobile Number</dt>}
            {identity?.idcmtMobile && (
              <dd>{formatPhoneNumber(identity?.idcmtMobile)}</dd>
            )}
            {identity?.idcmtEmail && <dt>Email Address</dt>}
            {identity?.idcmtEmail && (
              <dd>identity?.idcmtEmail?.toLowerCase()</dd>
            )}
          </dl>

          {/* <div className="note">
                        <p>If you do not know who your Account Manager is you can find this and <a href="#">manage your account here</a>.</p>
                    </div> */}
        </div>
      </React.Fragment>
      <TrackPageComplete />
    </ActivationLayout>
  );
};

export default withActivation(PermissionError);
