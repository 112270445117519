import { useState, useEffect } from 'react'
import ProgressSpinner from '../ProgressSpinner'
import Accordion from '../Accordion'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const ResetDevice = (props) => {
    const {
        dashboardContent,
        restartDevice,
        restartedDevice,
        restartDeviceFailed,
        accountId,
        hsdService,
    } = props

    const [stage, setStage] = useState('intro')
    const [contentBlock, setContentBlock] = useState()

    const resetInternet = () => {
        setStage('callResetInternet')
        trackEvent({
            event: 'dashboard',
            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
            dashboardEvent: 'reset my internet router modal',
            dashboardDetail: 'submit'

            })
        restartDevice({accountId, macAddress: hsdService?.macId})
    }

    useEffect(() => {
        if (restartedDevice || restartDeviceFailed) {
            setStage('resetSuccess')
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'reset my internet router modal',
                dashboardDetail: 'success'

                })
        }
    }, [restartedDevice, restartDeviceFailed])

    // for any general updates that need to happen when the stage is changed
    useEffect(() => {
        let displayElements
        switch (stage) {
            case 'intro':
                displayElements = (
                    <div className="reset-intro">
                        <h2>{dashboardContent.dashboardArea?.collapsed.resetInternetDevice}</h2>
                        <p>{dashboardContent.dashboardArea?.collapsed.troubleConnectingToInternet}</p>
                        <button data-testid="reset-01" className="button" onClick={() => resetInternet()} data-tracking={digitalDataButtonClick('reset-internet', dashboardContent.dashboardArea?.collapsed.resetInternet.text)}>{dashboardContent.dashboardArea?.collapsed.resetInternet.text}</button>
                    </div>
                )
                break
            case 'callResetInternet':
                displayElements = (
                    <ProgressSpinner loadingText={dashboardContent.dashboardArea?.collapsed.resettingInternetDevice.loadingScreenText + '…'} />
                )
                break
            case 'resetSuccess':
                displayElements = (
                    <div className="reset-success">
                        <h3>
                            <img src="/site-assets/img/icon-check-circle.svg" className="icon check" alt="complete"></img>
                            {dashboardContent.dashboardArea?.collapsed.signalSentSuccessfully.text}
                        </h3>
                        <Accordion items={dashboardContent.dashboardArea?.collapsed.modalQuestions} eventObj={{event: 'dasbhoard', dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),  dashboardEvent: 'reset my internet router modal', dashboardDetail: 'Still Having Trouble Connecting?'}} eventDetailCategory='accordionText' />
                    </div>
                )
                break
            case 'resetFail':
                displayElements = (
                    <div className="reset-fail">
                        <h3>There was an issue</h3>
                        <p>Please try again.</p>
                    </div>
                )
                break
        }
        setContentBlock(displayElements)
        // eslint-disable-next-line
    }, [stage])

    return (
        <div className="dashboard__reset-device">
            {contentBlock}
        </div>
    )

}

export default withDashboard(ResetDevice)