import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import { digitalDataButtonClick, digitalDataModalOpen } from '../../utils/adobe-analytics'

interface ComponentProps {
    paymentMethodContent?: any
    modalCloseButton?: string
    buttonClasses?: any
    onRemoveCard?: any
    autoRefill?: any
    isTestFile?:boolean
}

const RemoveCCModal = ({paymentMethodContent, modalCloseButton, buttonClasses, onRemoveCard, autoRefill, isTestFile}:ComponentProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)

    const openModal = () => {
        setModalIsOpen(true)
        // document.body.className += ' modal-open'
        document.body.classList.add('modal-open')
    }

    const closeModal = () => {
        setModalIsOpen(false)
        // document.body.className = document.body.className.replace(new RegExp(/modal-open/g), '')
        document.body.classList.remove('modal-open')
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }
    const removeCard = () => {
        document.removeEventListener("mousedown", handleClick)
        closeModal()
        onRemoveCard()
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener("mousedown", handleClick)
        } else {
            document.removeEventListener("mousedown", handleClick)
        }
        // eslint-disable-next-line
    }, [modalIsOpen])

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <button data-testid="01-modal" type="button" className={buttonClasses} onClick={openModal} data-tracking={digitalDataModalOpen('payment-method')}>{ paymentMethodContent?.deleteCardTitle }</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="modal modal--visible"
                overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                    <div ref={node} className="modal__body">
                        <h3 id="modal__title" tabIndex={0} className="modal__title">{ paymentMethodContent?.deleteCardTitle }</h3>
                        <p>{ paymentMethodContent?.areYouSureYouWantToDelete }</p>
                        { autoRefill ? 
                        <p>{ paymentMethodContent?.looksLikeYouAreEnrolledInAutoRefill }</p>
                        : null}
                        <br />
                        <br />
                        <div className="button--container">
                            <button className="button" onClick={removeCard} data-tracking={digitalDataButtonClick('remove-card', paymentMethodContent?.yesDelete?.text)}>{ paymentMethodContent?.yesDelete?.text }</button>
                            <br />
                            <br />
                            <button data-testid="01-close" className="button--link" onClick={closeModal} data-tracking={digitalDataButtonClick('keep-card', paymentMethodContent?.noKeepCard?.text)}>{ paymentMethodContent?.noKeepCard?.text }</button>
                        </div>

                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', modalCloseButton ? modalCloseButton : '', true)}>
                            { modalCloseButton }
                            <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default RemoveCCModal