import axios from 'axios';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const fetchIdentityInfo = async ({custGuid, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/v1/customermanagement/fetchPrepaidIdentityInfo?trackingId=${trackingId}`

    const data = {
        attribute: 'CUSTOMER_GUID',
        value: custGuid
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId,
        }
    })

    return response.data
};

export const updateIDCMTCustomerInfo = async ({custGuid, accountId, mobileNo, emailId, languagePreference, smsOptIn, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/v1/customermanagement/updateIDCMTCustomerInfo?trackingId=${trackingId}`

    const data = {
        custguid: custGuid,
        accountId,
        languagePreference,
        smsOptIn
    }
    if (emailId) {
        data['emailId'] = emailId
    }
    if (mobileNo) {
        data['mobileNo'] = mobileNo
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    });

    return response.data
};

export const updateCustomer = async ({custGuid, accountId, firstName, lastName, languagePreference, smsOptIn, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/v1/customermanagement/update-customer?trackingId=${trackingId}`

    const data = {
        firstName,
        lastName,
        custguid: custGuid,
        accountId,
        languagePreference,
        smsOptIn,
        trackingId,
        source: 'ActivationGateway'
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'ActivationGateway',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const fetchCustomerDashboard = async ({accountId, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/v1/customermanagement/fetchCustomerDashboard?trackingId=${trackingId}`

    const data = {
        accountId,
        billerLookup: true,
        flow: "DASHBOARD",
        getAvailablePlans: true,
        getCIMAInfo: true,
        getCustomerInfo: true,
        getDevicesInfo: true,
        getPaymentInfo: true,
        getServiceAddress: true,
        getACPDetails: true,
        getPlanStatus: true,
        houseKey: "",
        houseNumber: "",
        locationId: "",
        phone: "",
        pin: "",
        zip: ""
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const restartDevice = async ({accountId, macAddress, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/api/v1/PinlessActivation/reprovision-device?trackingId=${trackingId}`

    const data = {
        accountId: accountId,
        deviceDetails: [
            {
                macId: macAddress,
                resetOnly: true,
                prepaidProduct: {
                    lob: 'HSD'
                },
            },
        ],
        source: "DIGITAL2.0",
        trackingId
    }

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const fetchPrepaidTransactions = async ({accountId, phoneNumber, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/get-prepaidpayment-transactions?trackingId=${trackingId}`

    const data = {
        accountId,
        tn: phoneNumber,
        trackingId,
        source: 'DIGITAL2.0',
    }

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const fetchPaymentInfo = async ({accountId, phoneNumber, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/get-custpayment-info?trackingId=${trackingId}`

    const data = {
        accountId,
        tn: phoneNumber,
        trackingId,
        source: 'DIGITAL2.0',
    }

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const enableAutoRefill = async ({accountId, phoneNumber, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/enroll-autopay?trackingId=${trackingId}`

    const data = {
        accountId,
        tn: phoneNumber,
        trackingId,
        source: 'DIGITAL2.0',
    }

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const disableAutoRefill = async ({accountId, phoneNumber, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/cancel-autopay?trackingId=${trackingId}`

    const data = {
        accountId,
        tn: phoneNumber,
        trackingId,
        source: 'DIGITAL2.0',
    }

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
};

export const addPaymentInstrument = async ({payload, sessionId, trackingId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/add-secure-prepaid-payment-instrument?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId,
            trackingid: trackingId
        }
    })

    return response.data
};
export const removePaymentInstrument = async ({payload, sessionId, trackingId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/remove-prepaidpayment-instrument?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId,
             trackingid: trackingId
        }
    })

    return response.data
};

export const updatePaymentInstrument = async ({payload, sessionId, trackingId}) => {
    const url = `${apiBaseUrl}/secure2/payment-service/modify-prepaidpayment-instrument?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId,
            trackingid: trackingId
        }
    })

    return response.data
};

export const validateActivationCode = async ({accountId, pin, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/api/v1/accountService/validateActivationCode?trackingId=${trackingId}`

    const data = {
        pinDetails: [
            {
                accountId,
                lob: 'HSD',
                pin
            }
        ]
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId,
        }
    })

    return response.data
};

export const fetchPromos = async ({accountId, locationId, trackingId, sessionId }) => {
    const url = `${apiBaseUrl}/secure2/api/v1/offer-service/get-promo?trackingId=${trackingId}`
    const data = {
        accountId,
        locationId,
        source: 'prepaid-ui-portal',
        flow: 'DASHBOARD',
        trackingId
    }
    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
}

export const fetchTaxData = async ({products, geoCode, trackingId}) => {
    // const products = [
    //     {
    //         productCode: 'HSD_030',
    //         price: 45
    //     }
    // ]

    const data = {
        source: 'DIGITAL2.0', // 'CTP',
        status: '',
        busErrorCode: '',
        message: '',
        sysErrorCode: '',
        geoCode, // state.dashboardData.customerInfo.geoCode,
        trackingId,
        products
    }

    const response =  await axios.post(`${apiBaseUrl}/oauth/tax/emm-pp-vertex-service/v1/vertex/ctp/get-tax?trackingId=${trackingId}`, data)

    return response.data
}

export const refillPrepaidAccount = async ({payload, trackingId, sessionId}) => {
    // const url = `${apiBaseUrl}/oauth2/api/v1/accountService/refillPrepaidAccount?trackingId=${trackingId}`
    const url = `${apiBaseUrl}/secure2/api/v1/accountService/refillPrepaidAccount?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    })

    return response.data
}

export const redeemRefill = async ({accountId, code, hsdService, trackingId, sessionId}) => {
    const data = {
        version: "1.0",
        trackingId: trackingId,
        source: "DIGITAL2.0",
        accountId,
        codes: [
            {
                refillCode: code,
                lob: "HSD",
                deviceId: hsdService?.deviceId, // "78:78:87:78:57:59",
                deviceSerialNumber: hsdService?.deviceSerialNumber, // "250137024LNH",
                deviceStatus: hsdService?.deviceStatus[0], // "A", as in Active
                deviceLob: hsdService?.deviceLob, // "HSD",
                transactionStatus: "SUCCESS"
            }
        ]
    }

    const response =  await axios.post(`${apiBaseUrl}/secure2/api/v1/PinlessActivation/redeem-refill-activation?trackingId=${trackingId}`, data, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
}


export const createAuxAccount = async ({payload, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/api/v1/accountService/createAuxAccount?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    })

    return response.data
}

export const getAuxAccount = async ({payload, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/v1/customercare/getAuxAccountDetails?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    })

    return response.data
}

export const updateScheduledMove = async ({payload, trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/v1/customercare/updateScheduledMove?trackingId=${trackingId}`

    // Update date
    // {"action": "U",  "confirmationId": "AUX000010064", "newScheduleDate": "2021-05-07"}

    // Cancel move
    // {"action": "C",  "confirmationId": "AUX000010064"}


    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId,
            'x-session-id': sessionId
        }
    })

    return response.data
}

export const pingSession = async ({trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/ping?trackingId=${trackingId}`

    const response = await axios.post(url, {}, {
        headers: {
            'x-session-id': sessionId,
        }
    })

    return response.data
};

export const fetchBroadbandFactsContent = async ({payload, sessionId, trackingId}) => {
    console.log("FCC APIs PAYLOAD >>>", payload)
    // const url = process.env.REACT_APP_FCC_COMPONENT_API
    const url = "https://ctp-gateway-stg.codebig2.net/nutritionlabel"

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            'x-session-id': sessionId,
            trackingid: trackingId
        }
    })

    return response.data
};
