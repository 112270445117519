/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from 'react'
import ModalCheckout from '../refill/ModalCheckout'
import withDashboard from '../../redux/connectors/dashboard'
import WinbackCartBanner from './WinbackCartBanner'
import CartWinbackPromo from './CartWinbackPromo'
import { getProductContent, getProductPrice, formatMoney } from '../../utils/utils'
import CartRefillPromo from './CartRefillPromo'
import CartRefillPromoDetail from './CartRefillPromoDetail'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const Cart = (props) => {
    const {
        content,
        cart,
        cartContent,
        cartTaxes,
        cartSubtotal,
        cartTotal,
        dashboard,
        refillContent,
        havePromoInCart,
        isWinbackPromo,
        promos,
        fetchedTaxData,
        refill,
        showLineOffer,
        haveValidACP,
        isTribalACP
    } = props

    const [editCart, updateEditCart] = useState(false)
    const [tvProducts, updateTvProducts] = useState<any[]>([])
    const [internetProduct, updateInternetProduct] = useState<any>({})

    const showingRefillConfirmation = refill && refill.cart && window.location.href.includes('confirmation')
    const activeCart = showingRefillConfirmation ? refill.cart : cart
    const activeCartTaxes = showingRefillConfirmation ? refill.cartTaxes : cartTaxes
    const activeCartSubtotal = showingRefillConfirmation ? refill.cartSubtotal : cartSubtotal
    const activeCartTotal = showingRefillConfirmation ? refill.cartTotal : cartTotal
    const activePromos = showingRefillConfirmation ? refill.promos : promos
    let activeHavePromoInCart = showingRefillConfirmation ? refill.havePromoInCart : havePromoInCart
    const activeIsWinbackPromo = showingRefillConfirmation ? refill.isWinbackPromo : isWinbackPromo
    const activeHaveValidACP = showingRefillConfirmation ? refill.haveValidACP : haveValidACP
    const activeIsTribalACP = showingRefillConfirmation ? refill.isTribalACP : isTribalACP
    const activeAcpDiscount = showingRefillConfirmation ? refill.benefitAmount : dashboard?.acpDetail?.acpBenefits?.[0].benefitAmount

    if (!activePromos || !activePromos.discounts) {
        activeHavePromoInCart = false
    }

    useEffect(() => {
        if (activeCart?.internet !== null && activeCart?.internet !== '') {
            let internetItem: any
            internetItem = getProductContent(activeCart?.internet, cartContent.products)
            updateInternetProduct(internetItem)
        }
        let array = [{}]
        array.shift()
        if (activeCart?.packages?.length > 0) {
            for (let i = 0; activeCart.packages.length > i; i++) {
                let tvItem: any;
                tvItem = getProductContent(activeCart.packages[i], cartContent.products)
                let tvItemClone = Object.create(tvItem)
                tvItemClone.cartCode = activeCart.packages[i]
                array.push(tvItemClone)
                updateTvProducts(array)
            }
        } else {
            updateTvProducts([])
        }
        if (activeCart?.addOns?.length > 0) {
            for (let i = 0; activeCart.addOns.length > i; i++) {
                let tvItem: any;
                tvItem = getProductContent(activeCart.addOns[i], cartContent.products)
                let tvItemClone = Object.create(tvItem)
                tvItemClone.cartCode = activeCart.addOns[i]
                array.push(tvItemClone)
                updateTvProducts(array)
            }
        }
    }, [cart, cartContent]) // eslint-disable-line


    const renderTVItems = tvProducts.length && tvProducts.map((product, index) => {
        // logic for removal link
        let title, bodyText, modalType
        // Remove XITV Basic
        if (product.productData.name === 'XITV') {
            title = content?.cartContent?.cartContent?.cartRemovalBasic?.modalTitle
            bodyText = content?.cartContent?.cartContent?.cartRemovalBasic?.modalContent
            modalType = 'tvBasic'
        }
        // Remove bundled package and tv basic
        else if (product.typeOfPackage && product.originalPrice !== product.price) {
            title = content?.cartContent?.cartContent?.cartRemovalPacks?.modalTitle
            bodyText = content?.cartContent?.cartContent?.cartRemovalPacks?.modalContent
            modalType = 'addOn'
        }
        // Remove Package
        else if (!product.isAddOn) {
            title = content?.cartContent?.cartContent?.cartRemovalPremium?.modalTitle
            bodyText = content?.cartContent?.cartContent?.cartRemovalPacks?.modalContent
            modalType = 'tvPackage'
        }
        // Remove Add On
        else if (product.isAddOn && !product.isSpanish) {
            title = content?.cartContent?.cartContent?.cartRemovalPremium?.modalTitle
            bodyText = content?.cartContent?.cartContent?.cartRemovalPremium?.modalContent
            modalType = 'addOn'
        }
        // Remove Spanish Add On
        else if (product.isAddOn && product.isSpanish) {
            title = content?.cartContent?.cartContent?.cartRemovalPremium?.modalTitle
            bodyText = content?.cartContent?.cartContent?.cartRemovalPremium?.modalContentSpanish
            modalType = 'addOn'
        }

        // render items
        return (
            <tr key={index} className="line-item">
                <th className="line-item__label">
                    <span>
                    { product.productData?.plans[0]?.planDisplayText }
                    {
                        product.originalPrice !== product.price && product.typeOfPackage ?

                        <span>
                            {content?.refillContent?.refillFlow?.cart?.plusInstantTVBasic}
                        </span> : null
                    }
                    </span>
                    {editCart && modalType &&
                        <div className="line-item__remove">
                            <ModalCheckout
                                title={title}
                                bodyText={bodyText}
                                buttonClasses={'button--link--large'}
                                ctaText={content?.cartContent?.cartContent?.cartModule?.removeProductText}
                                content={content?.cartContent?.cartContent}
                                modalType={modalType}
                                packageName={product.cartCode}
                                packageDisplayName={product.productData.plans[0].planDesc}
                                updateEditCart={updateEditCart}
                                />
                        </div>
                    }
                </th>
                <td className="line-item__price">
                    <span className="price-full"><span>$</span>{ formatMoney(getProductPrice(product.cartCode, dashboard?.availablePlans)) }</span>
                </td>
            </tr>
        )
    })

    return (
        <div className="cart">
            {/* CART LIST */}
            <div className="width-100">

                {/* SECTION A */}
                <div>
                    {activeHavePromoInCart && activeCart.internet === "HSD_030" && activeIsWinbackPromo && 
                        <WinbackCartBanner />
                    }
                    { activeCart?.internet !== null && activeCart?.internet !== '' ?
                    <div className="card">
                        <table className="totals">
                            <thead>
                            </thead>

                            <caption className="totals__caption">
                                <div className="card__header">
                                    {showLineOffer ? 
                                    <h2 className="card__header-title">{refillContent?.refillFlow?.winback?.offerIncludes}</h2>
                                    : 
                                    <h2 className="card__header-title">{content?.cartContent?.cartContent?.cartModule?.prepaidInternetCartSectionLabel}</h2>
                                    }
                                </div>
                            </caption>
                            <tbody>

                                {activeHavePromoInCart && activeCart.internet === "HSD_030" ?
                                <>
                                { activeIsWinbackPromo ? 
                                <CartWinbackPromo /> : <CartRefillPromo />}
                                </> :
                                <tr className="line-item" data-testid="internet-product">
                                    <th className="line-item__label">{activeCart?.internetRefills > 1 ? activeCart?.internetRefills + "X ":""}{ activeCart?.internet === 'HSD_030' ? '30 days of ' : '7 days of '}{ internetProduct?.shortName}</th>
                                    { !activeHaveValidACP || activeIsTribalACP ? 
                                    <td className="line-item__price">
                                        <span className="price-full"><span>$</span>{ formatMoney(getProductPrice(activeCart?.internet, dashboard?.availablePlans) * activeCart?.internetRefills) }</span>
                                    </td> :
                                    <td className="line-item__price">
                                        <span className="price-full"><span>$</span>{ formatMoney(getProductPrice(activeCart?.internet, dashboard?.availablePlans) * activeCart?.internetRefills) }</span>
                                    </td>
                                    }
                                </tr>
                                }
                                { activeHaveValidACP && activeIsTribalACP && 
                                    <tr className="line-item">
                                        <td>
                                            <span className="acp-tribal-title">
                                                { refillContent?.refillFlow?.acP?.enrolledInAcPTc }
                                            </span>
                                            <span className="acp-tribal-disclaimer">
                                                { refillContent?.refillFlow?.acP?.becauseYouAreEnrolled }
                                            </span>
                                        </td>
                                    </tr>
                                }

                                { activeHaveValidACP && !activeIsTribalACP && cart.internet === 'HSD_030' &&
                                <tr className="line-item">
                                    <th className="line-item__label">*${activeAcpDiscount} { refillContent?.refillFlow?.acP?.after30AcP }</th>
                                    <td className="line-item__price">
                                        <span className="price-full">- <span>$</span>{ formatMoney(activeAcpDiscount) }</span>
                                    </td>
                                </tr>
                                }
                                { activeHaveValidACP && !activeIsTribalACP && cart.internet === 'HSD_030' &&
                                <tr className="line-item">
                                    <td className="line-item__price">
                                        <span className="price-full price-acp-total"><span>$</span>{ formatMoney(getProductPrice(activeCart.internet, dashboard?.availablePlans) * activeCart.internetRefills - activeAcpDiscount) }</span>
                                    </td>
                                </tr>
                                }
                                { showLineOffer && 
                                <>
                                    <tr className="line-item">
                                        <th className="line-item__label">{activeCart.internetRefills > 1 ? activeCart.internetRefills + "x ":""}{ activeCart.internet === 'HSD_030' ? '30 days of ' : '7 days of '}{ internetProduct?.shortName}</th>
                                        <td className="line-item__price">
                                            <span className="price-full"><span>$</span>{ formatMoney(getProductPrice(activeCart.internet, dashboard?.availablePlans) * activeCart.internetRefills) }</span>
                                        </td>
                                    </tr>
                                    <tr className="line-item">
                                        <th className="line-item__label">{activeCart.internetRefills > 1 ? activeCart.internetRefills + "x ":""}{ activeCart.internet === 'HSD_030' ? '30 days of ' : '7 days of '}{ internetProduct?.shortName}</th>
                                        <td className="line-item__price">
                                            <span className="price-full price-old"><span>$</span>{ formatMoney(getProductPrice(activeCart.internet, dashboard?.availablePlans) * activeCart.internetRefills) }</span>
                                        </td>
                                    </tr>
                                    <tr className="line-item">
                                        <td className="line-item__price">
                                            <span className="price-full">FREE</span>
                                        </td>
                                    </tr>
                                </>
                                }
                                {/* Refill Promo */}
                                {activeHavePromoInCart && activeCart.internet === "HSD_030" && !activeIsWinbackPromo &&
                                <CartRefillPromoDetail />}
                            </tbody>
                        </table>

                    </div>
                : null }
                </div>

                {/* SECTION B */}
                {tvProducts.length > 0 ?
                <div>
                    <div className="card ">
                        <table className="totals">
                            <thead>
                            </thead>
                            <caption className="totals__caption">
                                <div className="card__header">
                                    <h2 id="edit-xitv-label" className="card__header-title">{content?.cartContent?.cartContent?.cartModule?.prepaidInstantTvCartSectionLabel}</h2>
                                    <button
                                        type="button"
                                        className="totals__edit link-callout"
                                        aria-labelledby="edit-xitv"
                                        data-testid="edit-cart-button"
                                        onClick={() => updateEditCart(!editCart)}
                                        data-tracking={digitalDataButtonClick('edit-cart', editCart ? 'OK' : 'Edit')}
                                    >
                                        { editCart ? 'OK' : 'Edit'}
                                    </button>
                                </div>
                            </caption>
                            <tbody data-testid="tv-product">
                                { renderTVItems }
                            </tbody>
                        </table>
                    </div>
                </div>
                : null }
            </div>


            {/* SUBTOTAL */}
            {/*
            <div className="card card--clear card--order-subtotal">
                <table v-if="activeCartTotal != activeCartSubtotal && showDetails" className="order-price-summary-table">
                    <caption className="vh">
                    {{ cartContent.cartModule.subtotalLabel }}
                    </caption>
                    <tr>
                        <th className="order-price-summary-table__label">
                            {{ cartContent.cartModule.subtotalLabel }}
                        </th>
                        <td className="order-price-summary-table__price">
                            <div className="price-full">
                                <span>$</span>{{ formatMoney(activeCartSubtotal) }}
                            </div>
                        </td>
                    </tr>
                    <tr v-if="showDetails">
                        <!-- XXX -->
                        <th className="order-price-summary-table__label">
                            {{ cartContent.cartModule.shippingLabel }}
                        </th>
                        <td className="order-price-summary-table__price">
                            <div v-if="!showFreeShipping" className="price-full">
                                <span>$</span>{{ formatMoney(cartShipping) }}
                            </div>
                            <div v-if="showFreeShipping" className="price-full">
                                {{ freeShippingCartText }}
                            </div>
                        </td>
                    </tr>
                    <tr v-if="showDetails">
                        <th className="order-price-summary-table__label">
                            {{ cartContent.cartModule.taxesLabel }}
                        </th>
                        <td className="order-price-summary-table__price">
                            <div className="price-full">
                                <span>$</span>{{ formatMoney(activeCartTaxes) }}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            */}

            {/* TOTAL */}

            { fetchedTaxData ?
                <div className="card card--clear card--order-subtotal">
                    <table className="order-price-summary-table">
                        <caption className="vh">
                            { cartContent?.cartContent?.cartModule?.subtotalLabel }
                        </caption>
                        <tbody>
                            <tr>
                                <th className="order-price-summary-table__label">
                                { cartContent?.cartContent?.cartModule?.subtotalLabel }
                                </th>
                                <td className="order-price-summary-table__price">
                                <div className="price-full">
                                    <span>$</span>{ formatMoney(activeCartSubtotal) }
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="order-price-summary-table__label">
                                    { content?.cartContent?.cartContent?.cartModule?.taxesLabel }
                                </th>
                                <td className="order-price-summary-table__price">
                                    <div className="price-full">
                                    <span>$</span>{ formatMoney(activeCartTaxes) }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                : null }

            <div className="card card--clear card--order-total">
                <table className="order-price-summary-table">
                    <thead>

                    </thead>
                    <caption className="vh">
                        {content?.cartContent?.cartContent?.cartModule?.totalPriceText}
                    </caption>
                    <tbody>
                        <tr>
                            <th className="order-price-summary-table__label">
                                {content?.cartContent?.cartContent?.cartModule?.totalPriceText}
                            </th>
                            <td className="order-price-summary-table__price">
                                <div className="price-full" tabIndex={-1}>
                                <span>$</span>{ formatMoney(activeCartTotal) }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )

}
export default withDashboard(Cart)