import { useState, useEffect } from "react"
import DashboardLayout from "../../layouts/DashboardLayout"
import ProgressBar from "../../components/ProgressBar"
import PaymentMethod from "../../components/dashboard/PaymentMethod"
import HSDAccordion from "../../components/refill/HSDAccordion"
import Cart from "../../components/refill/Cart"
import NotificationBanner from "../../components/NotificationBanner"
import StickyCart from "../../components/refill/StickyCart"
import withDashboard from "../../redux/connectors/dashboard"
import { weekdays, months } from "../../utils/utils"
import { trackEvent } from "../../utils/utils"
import { digitalDataAddToCart, digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import { useHistory } from "react-router-dom"

const MoveRefillRequired = (props) => {
    const {
        locale,
        currentLocale,
        scheduledMoveDate,
        paymentInfo,
        fetchedPaymentInfo,
        cartContent,
        refillContent,
        cartCount,
        moveContent,
        dashboard,
        cart,
        havePromoInCart,
        cartTotal
    } = props
    const history = useHistory();

    const cartComponentContent = { cartContent, refillContent }

    const [progress] = useState(33)
    const [moveDate, setMoveDate] = useState<any>()
    const [hasSavedCard, setHasSavedCard] = useState(false)
    const [prepaidInstantUnavailableNotice, setPrepaidInstantUnavailableNotice] = useState(false)
    const [processNext, setProcessNext] = useState(false)
    const [formValid, updateFormStatus] = useState(true)
    const [startCPCEvents, setStartCPCEvents] = useState(false)

    const [loadCPCForm, setLoadCPCForm] = useState(false)
   useEffect(() => {
       setStartCPCEvents(true)
       
}, []) // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
   startCPCEvents && setLoadCPCForm(true)
}, [startCPCEvents])

    useEffect(() => {
        if(fetchedPaymentInfo && paymentInfo) {
            setHasSavedCard(true)
        }
    }, [fetchedPaymentInfo, paymentInfo])

    useEffect(() => {

        props.updatePageClass("dashboard refill move-refill")
        if (!scheduledMoveDate) {
            console.log("No scheduled move date")
            history?.push(`/${currentLocale}/move/schedule`)
            return
        }

        setMoveDate(new Date(scheduledMoveDate))

        trackEvent({
            event: "move refill flow",
            moveStep: "step 1",
            moveStepName: "start refill"
        })

    }, []) // eslint-disable-line

    const handleNext = (e) => {
        e.preventDefault();
        e.stopPropagation();
        sessionStorage.removeItem("cpcTermsAndConditions");
        sessionStorage.removeItem("alreadyLoaded");
        trackEvent({
            event:"move refill info",
            moveStep:"step 1",
            moveStepName:"start refill",
            moveEvent:"next step cta",
            moveDetail:"next"
        })
        digitalDataAddToCart(
          "refill within move flow",
          "addToCart",
          ".move",
          havePromoInCart,
          cart?.internet
        );
        if (hasSavedCard) {
            history.push("/" + locale + "/move/refill-review")
            trackEvent({
                event:"move refill flow",
                moveStep:"step 2",
                moveStepName:"payment"
            })
        } else {
            history?.push("/" + locale + "/move/refill-payment")
        }
    }

    const onUpdateField = (e) => {
        console.log(e);
    }

    const goToOrderReview = (e) => {
        console.log(e);
      };
     const handleFormStatus = (formStatus) => {
        updateFormStatus(formStatus)
    }
     const setFormValid = (status) => {
        handleFormStatus(status)
     }
     


    return (
        <DashboardLayout locale={props.locale} pageTitle={moveContent?.refillRequired?.progress}>
            <TrackUserData data={dashboard} />
            <ProgressBar title={"A refill is required"} percent={progress} />
            <div className="main__width-constraint move">
                <br />
                <h1 className="page__title">{moveContent?.refillRequired?.title}</h1>
                <div className="main__desktop-body">
                    <div className="fullbleed-offsets">
                        <p>{moveContent?.refillRequired?.subhead}</p>
                        {moveDate &&
                        <div className="service-section__row">
                            <div className="address">
                                <b>{moveContent?.refillRequired?.requestedMoveDate}</b><br></br>
                                <span>
                                    {weekdays[moveDate.getUTCDay()]}, {months[moveDate.getUTCMonth()]} {moveDate.getUTCDate()}, {moveDate.getUTCFullYear()}
                                </span>
                            </div>
                            <button className="button--link" onClick={() => history.push(`/${currentLocale}/move/schedule`)} data-tracking={digitalDataButtonClick('edit-date', 'Edit Date')}>Edit Date</button>
                        </div>}
                    </div>
                </div>
                <div className="cart__display-large main__desktop-sidebar-right">
                </div>
                <div className="main__desktop-body">
                    <div className="edit-payment">
                    {fetchedPaymentInfo && paymentInfo ? 
                        <>
                            <PaymentMethod onUpdateField={onUpdateField} goToOrderReview={goToOrderReview} setFormValid={setFormValid} nextClicked={processNext} startCPCEvents={startCPCEvents} minimalView={true} refillMoveView={true}/> 

                        </>
                        : null }
                        <HSDAccordion setPrepaidInstantUnavailableNotice={setPrepaidInstantUnavailableNotice} isMoveRefill={true}/>    
                    </div>  
                    <NotificationBanner text={moveContent?.refillRequired?.disclosure} displayStyle="alert-inline" modal="false" />
                    <div className="cart__display-large">
                        <hr/>
                        <div className="card card--clear card--small card--right">
                            <input disabled={cartTotal === 0} type="button" className="button button--inline" data-testid="account-01" value={"Next"} onClick={(e) => {digitalDataButtonClick('handle-next', 'Next', false, undefined, true, '.edit-payment'); handleNext(e)}} />
                        </div>
                    </div>
                </div>
                <div className="cart__display-large main__desktop-sidebar-right">
                    <Cart content={cartComponentContent} />
                </div>
                <div className="cart__display-small-medium">
                    <StickyCart
                        nextDisabled={cartTotal === 0}
                        handleNext={handleNext}
                        cartComponentContent={cartComponentContent}
                    />
                </div>          
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveRefillRequired)