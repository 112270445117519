import {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import withActivation from '../../redux/connectors/activation'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

const clientSide = (typeof window !== "undefined")

const detectLocale = () => {
    if (clientSide && window.location.pathname.indexOf('/es/') === 0) {
        return 'es'
    } else {
        return 'en'
    }
}

function Header(props) {
    const {locale, updateLocale} = props
    const location = useLocation()

    useEffect(() => {
        updateLocale(detectLocale())
    }, [location, updateLocale])

    const isEnglish = locale === 'en'

    let otherLocalePath = '/en/activation'

    if (clientSide) {
        if (isEnglish) {
            otherLocalePath = window.location.pathname.replace('/en/', '/es/')
        } else {
            otherLocalePath = window.location.pathname.replace('/es/', '/en/')
        }
    }
    const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
    return (
        <div className="header">
            <div className="header__width-constraint">
                <div className="header__left">
                    <a
                    href={window.location.href}
                    className="xfinity-logo"
                    data-tracking={digitalDataLinkClick('xfinity-logo', window.location.href, 'xfinity')}
                    >
                    <span className="vh">xfinity</span>
                    </a>
                </div>
                <nav
                    className="header__right"
                    aria-label="Utility Navigation"
                >
                    <div className="header-language-switch">
                        <Link to={otherLocalePath} data-tracking={digitalDataLinkClick('language', otherLocalePath, isEnglish ? 'Español' : 'English')}>{isEnglish ? 'Español' : 'English'}</Link>
                    </div>

                    <a href={logoutURL} className="header__account-link header__account-link-logout" data-tracking={digitalDataLinkClick('logout', 'https://oauth.xfinity.com/oauth/sp-logout?client_id=xfinity_prepaid_digital', 'Logout')}>
                        Logout
                    </a>
                </nav>
            </div>
        </div>
    )
}

export default withActivation(Header)
