import axios from 'axios';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const clientSide = typeof window !== 'undefined'

if (clientSide) {
    if (!window.location.host.includes('local')) {
        apiBaseUrl = `${window.location.protocol}//${window.location.hostname}`
    }
}

// console.log('⚡️ apiBaseUrl', apiBaseUrl)

export const fetchToken = async ({trackingId}) => {
    const url = `${apiBaseUrl}/token?trackingId=${trackingId}`
    await axios.get(url)

    // console.log('token response', response)

    // XXX Need to get the token out of the cookie. BUT, may not be needed for anything.
    // X-XSRF-TOKEN header is added automatically by axios once the cookie is set.
    const xsrfToken = null

    return {xsrfToken}
}

export const fetchEnglishActivationContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/activation`
    const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER
    const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD

    const response = await axios.get(url, {
        headers: (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) ? {
            Authorization: `Basic ${btoa(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD)}`
        } : {}
    })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishActivationContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/activation`
    const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER
    const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD

    const response = await axios.get(url, {
        headers: (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) ? {
            Authorization: `Basic ${btoa(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD)}`
        } : {}
    })
    // console.log('content response', response.data)
    return response.data
}

export const fetchIdentityInfo = async ({custGuid, trackingId}) => {
    // const url = `https://my-dev.xfinityprepaid.io/secure/v1/customermanagement/fetchPrepaidIdentityInfo?trackingId=${trackingId}`
    const url = `${apiBaseUrl}/secure/v1/customermanagement/fetchPrepaidIdentityInfo?trackingId=${trackingId}`

    const data = {
        attribute: 'CUSTOMER_GUID',
        value: custGuid
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId
        }
    })

    return response.data
};

export const fetchCustomerDashboard = async ({accountId, trackingId}) => {
    const url = `${apiBaseUrl}/secure/v1/customermanagement/fetchCustomerDashboard?trackingId=${trackingId}`

    const data = {
        accountId,
        billerLookup: false,
        flow: "DASHBOARD", // ACTIVATION doesn't work, gives 400 error
        getAvailablePlans: true,
        getCIMAInfo: true,
        getCustomerInfo: true,
        getDevicesInfo: true,
        getPaymentInfo: true,
        getServiceAddress: true,
        houseKey: "",
        houseNumber: "",
        locationId: "",
        phone: "",
        pin: "",
        zip: ""
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId
        }
    })

    return response.data
};

export const loadPrepaidAccount = async ({accountId, trackingId}) => {

    const url = `${apiBaseUrl}/secure/api/v1/util-service/load-prepaid-account?trackingId=${trackingId}`

    const data = {
        accountId,
        trackingId,
        source: 'DIGITAL2.0'
    }

    const response = await axios.post(url, data)

    return response.data
};

export const fetchDeviceStatus = async ({trackingId, accountId, cpeIP=''}) => {

    const url = `${apiBaseUrl}/secure/api/v1/PinlessActivation/validate-prepaid-device?trackingId=${trackingId}`

    const data = {
        accountId,
        trackingId,
        cpeIP: cpeIP
    }

    const response = await axios.post(url, data)

    return response.data
};

export const fetchMacStatus = async ({mac, trackingId}) => {
    const url = `${apiBaseUrl}/secure/api/v1/PinlessActivation/validate-mac?trackingId=${trackingId}`

    const data = { mac }

    const response = await axios.post(url, data)

    return response.data
};

export const activatePrepaidAccount = async (data) => {
    const trackingId = data.trackingId

    const url = `${apiBaseUrl}/secure/api/v1/accountService/prepaidAccountActivation?trackingId=${trackingId}`

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId
        }
    })

    return {...response.data, httpStatusCode: response.status}
}

export const restartDevice = async ({accountId, macAddress, trackingId}) => {
    const url = `${apiBaseUrl}/secure/api/v1/PinlessActivation/reprovision-device?trackingId=${trackingId}`

    const data = {
        accountId: accountId,
        deviceDetails: [
            {
                macId: macAddress,
                resetOnly: true,
                prepaidProduct: {
                    lob: 'HSD'
                },
            },
        ],
        source: "DIGITAL2.0",
        trackingId
    }

    const response = await axios.post(url, data)

    return response.data
};
