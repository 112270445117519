import DashboardLayout from '../../layouts/DashboardLayout'
import MoveNotificationCard from '../../components/move/MoveNotificationCard'
import withDashboard from '../../redux/connectors/dashboard'
import { useEffect } from 'react'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const Notifications = (props) => {
    const {
        currentLocale,
        haveScheduledMove,
        history,
        dashboard
    } = props
  
    useEffect(() => {
        if (!haveScheduledMove) {
            history.push(`/${currentLocale}/dashboard`)
            return
        }

        props.updatePageClass('dashboard')
    }, []) // eslint-disable-line

    const renderPageComponents = () => {
        return (
            // eslint-disable-next-line
            <div>
                <div  className="main__width-constraint">
                    <h1 className="page__title">{props.dashboardContent?.dashboardArea?.notifications?.welcomeBack.replace('[First Name]', props.dashboard?.customerInfo?.firstName)}</h1>
                    <MoveNotificationCard content={{placeholder: null}} locale={props.locale} />
                </div>
            </div>
        )
    }

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Notifications'}>
            <TrackUserData data={dashboard} />
            {props.dashboardContent.loaded &&
            <div>
                {renderPageComponents()}
            </div>}
            <TrackPageComplete />
        </DashboardLayout>
    )
}
export default withDashboard(Notifications)