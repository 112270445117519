import axios from 'axios';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const fetchToken = async ({trackingId}) => {
    const url = `${apiBaseUrl}/token?trackingId=${trackingId}`
    await axios.get(url)

    // console.log('fetchToken response', response)

    // XXX Need to get the token out of the cookie. BUT, may not be needed for anything.
    // X-XSRF-TOKEN header is added automatically by axios once the cookie is set.
    const xsrfToken = null

    return {xsrfToken}
}

export const getAccount = async ({trackingId, sessionId}) => {
    const url = `${apiBaseUrl}/secure2/api/v1/PinlessActivation/get-account?source=DIGITAL2.0&trackingId=${trackingId}`
    const response = await axios.get(url, {
        headers: {
            'x-session-id': sessionId
        }
    })

    return response.data
}
