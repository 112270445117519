import React, { FunctionComponent } from "react";

export type TrackingDataProps = {
  type?: string;
  trackingInfo?: unknown;
  dataTrackingAppend?: boolean;
};

export const TrackingData: FunctionComponent<TrackingDataProps> = ({
  type = "digitalData",
  trackingInfo,
  dataTrackingAppend,
}) => {
  return (
    <script
      type={`tracking-data-${type}`}
      data-tracking-append={dataTrackingAppend && ""}
    >
      {JSON.stringify(trackingInfo)}
    </script>
  );
};

export default TrackingData;
