import * as React from "react";

export interface ContentContextInterface {
  content: object
  loaded?: boolean,
}

const ctxt = React.createContext<ContentContextInterface | null>(null);

export const ContentContextProvider = ctxt.Provider;

export const ContentContextConsumer = ctxt.Consumer;
