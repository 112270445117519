import DashboardLayout from "../../layouts/DashboardLayout";
import LinkList from "../../components/dashboard/LinkList";
import withDashboard from "../../redux/connectors/dashboard";
import PromoBanner from "../../components/dashboard/PromoBanner";
import WinbackBanner from "../../components/refill/WinbackBanner";
import { useEffect, useState } from "react";
import PaymentMethod from "../../components/dashboard/PaymentMethod";
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const EditPayment = (props) => {
  const {
    dashboardContent,
    locale,
    haveScheduledMove,
    haveValidPromo,
    deactivatedAccount,
    isWinbackPromo,
    dashboard
  } = props;

  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [cpcProcessing, setCpcProcessing] = useState(false); // eslint-disable-line

  const handleFormStatus = (formStatus) => {
    updateFormStatus(formStatus);
  };

  const links =
    dashboardContent?.accountInfoArea?.paymentMethod?.additionalItems;

  useEffect(() => {
    props.updatePageClass(
      "edit-payment"
    );
  }, []); // eslint-disable-line

  const [loadCPCForm, setLoadCPCForm] = useState(false);

  const onUpdateField = (e) => {
    console.log(e);
  };

  const [startCPCEvents, setStartCPCEvents] = useState(false);

  useEffect(() => {
    setStartCPCEvents(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);

  const goToOrderReview = (e) => {
    console.log("goToOrderReview");
  };
  const setFormValid = (status) => {
    handleFormStatus(status);
  };

  return (
    <DashboardLayout locale={props.locale} pageTitle={"Edit Payment"}>
      <TrackUserData data={dashboard} />
      {haveValidPromo && !deactivatedAccount && !isWinbackPromo && (
        <PromoBanner
          title={
            dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner
              ?.title
          }
          body={
            dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner
              ?.body
          }
          link={`/${locale}/refill`}
        />
      )}
      {haveValidPromo && !deactivatedAccount && isWinbackPromo && (
        <WinbackBanner />
      )}
      <div className="main__width-constraint">
        <h1 className="page__title">
          {dashboardContent?.accountInfoArea?.paymentMethod?.title}
        </h1>

        <PaymentMethod
          onUpdateField={onUpdateField}
          goToOrderReview={goToOrderReview}
          setFormValid={setFormValid}
          nextClicked={processNext}
          startCPCEvents={startCPCEvents}
          minimalView={false}
          showAutoRefill={true}
        />

        {links && links.icons && (
          <div className="card">
            <LinkList
              content={links}
              locale={locale}
              haveScheduledMove={haveScheduledMove}
            />
          </div>
        )}
      </div>
      <TrackPageComplete />
    </DashboardLayout>
  );
};
export default withDashboard(EditPayment);
