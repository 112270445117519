import {put, select, call, takeEvery} from 'redux-saga/effects'

import {
    fetchIdentityInfo,
    updateCustomer,
    updateIDCMTCustomerInfo,
    fetchCustomerDashboard,
    restartDevice,
    fetchPrepaidTransactions,
    fetchPaymentInfo,
    enableAutoRefill,
    disableAutoRefill,
    addPaymentInstrument,
    removePaymentInstrument,
    updatePaymentInstrument,
    validateActivationCode,
    fetchPromos,
    fetchTaxData,
    refillPrepaidAccount,
    redeemRefill,
    createAuxAccount,
    getAuxAccount,
    updateScheduledMove,
    pingSession,
    fetchBroadbandFactsContent
} from './apis'

import { dashboard } from './store'
import { digitalDataAPIError } from '../../utils/adobe-analytics'

export const getTrackingId = (state) =>  state?.common.trackingId 
export const getSessionId = (state) => state?.common.sessionId
const getSessionData = (state) => ({sessionError: state?.dashboard.sessionError, lastActionAt: state?.dashboard.lastActionAt})
const getDashboardState = (state) => state?.dashboard

const isSessionError = (error) => {
    if (error && error.response) {
        const status = error.response?.status
        const data = error.response?.data
        // Session errors returned by the LambaEdgeRequestValidator function will always
        // have either `expired` or `rejected` set to true in the response body
        const haveSessionError = (status === 403) && data && (data.expired || data.rejected)
        if (haveSessionError) {
            console.log('A session error has occurred', data)
            return true
        }
    }
    return false
}

function* fetchIdentityInfoSaga({payload}) {
    console.log('fetchIdentityInfoSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(fetchIdentityInfo, {...payload, trackingId, sessionId})
        yield put(dashboard.actions.fetchIdentityInfoSuccess(response.response))
    } catch (error: any) {
        console.error('fetchIdentityInfoSaga error', error)

        digitalDataAPIError('fetchIdentityInfoSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        if (isSessionError(error)) {
            console.log('fetchIdentityInfoSaga error', error)
            yield put(dashboard.actions.setSessionError(error))
        } else {
            let errorCode = null, errorMessage = null
            if (error.response && error.response?.data) {
                errorCode = error.response?.data?.errorCode
                errorMessage = error.response?.data?.errorMessage
            }
            yield put(dashboard.actions.fetchIdentityInfoFailure({errorCode, errorMessage}))
        }
    }
}

function* fetchIdentityInfoSagaConfig() {
    yield takeEvery(dashboard.actions.fetchIdentityInfo, fetchIdentityInfoSaga)
}


function* updateCustomerSaga({payload}) {
    console.log('updateCustomerSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(updateCustomer, {...payload, trackingId, sessionId})
        // console.log('updateCustomerSaga response', response)
        yield call(updateIDCMTCustomerInfo, {...payload, trackingId, sessionId})
        // console.log('updateCustomerSaga response2', response2)
        yield put(dashboard.actions.updateCustomerSuccess(response.response))
    } catch (error: any) {
        console.error('updateCustomerSaga error', error)

        digitalDataAPIError('updateCustomerSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            let errorCode = null, errorMessage = null
            if (error.response && error.response?.data) {
                errorCode = error.response?.data?.errorCode
                errorMessage = error.response?.data?.errorMessage
            }
            yield put(dashboard.actions.updateCustomerFailure({errorCode, errorMessage}))
        }
    }
}

function* updateCustomerSagaConfig() {
    yield takeEvery(dashboard.actions.updateCustomer, updateCustomerSaga)
}


function* updateIDCMTCustomerInfoSaga({payload}) {
    console.log('updateIDCMTCustomerInfo')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(updateIDCMTCustomerInfo, {...payload, trackingId, sessionId})
        // console.log('updateIDCMTCustomerInfoSaga response', response)
        yield put(dashboard.actions.updateIDCMTCustomerInfoSuccess(response.response))
    } catch (error: any) {
        console.error('updateCustomerSaga error', error)

        digitalDataAPIError('updateIDCMTCustomerInfo', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            console.log('updateCustomerSaga error', error)
            let errorCode = null, errorMessage = null
            if (error.response && error.response?.data) {
                errorCode = error.response?.data?.errorCode
                errorMessage = error.response?.data?.errorMessage
            }
            yield put(dashboard.actions.updateIDCMTCustomerInfoFailure({errorCode, errorMessage}))
        }
    }
}

function* updateIDCMTCustomerInfoSagaConfig() {
    yield takeEvery(dashboard.actions.updateIDCMTCustomerInfo, updateIDCMTCustomerInfoSaga)
}

function* fetchCustomerDashboardSaga({payload}) {
    console.log('fetchCustomerDashboardSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(fetchCustomerDashboard, {...payload, trackingId, sessionId})
        yield put(dashboard.actions.fetchCustomerDashboardSuccess(response.response))
    } catch (error: any) {
        console.error('fetchCustomerDashboardSaga error', error)

        digitalDataAPIError('fetchCustomerDashboardSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            console.log('fetchCustomerDashboardSaga error', error)
            yield put(dashboard.actions.setSessionError(error))
        } else {
            let errorCode = null, errorMessage = null
            if (error.response && error.response?.data) {
                errorCode = error.response?.data?.errorCode
                errorMessage = error.response?.data?.errorMessage
            }
            yield put(dashboard.actions.fetchCustomerDashboardFailure({errorCode, errorMessage}))
        }
    }
}

function* fetchCustomerDashboardSagaConfig() {
    yield takeEvery(dashboard.actions.fetchCustomerDashboard, fetchCustomerDashboardSaga)
}


function* restartDeviceSaga({payload}) {
    console.log('restartDeviceSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(restartDevice, {...payload, trackingId})
        yield put(dashboard.actions.restartDeviceSuccess(response))
    } catch (error: any) {
        console.error('restartDeviceSaga error', error)

        digitalDataAPIError('restartDeviceSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            console.log('restartDeviceSaga error', error)
            yield put(dashboard.actions.setSessionError(error))
        } else {
            const errorCode = error.response?.data?.errorCode
            const errorMessage = error.response?.data?.errorMessage
            yield put(dashboard.actions.restartDeviceFailure({errorCode, errorMessage}))
        }
    }
}

function* restartDeviceSagaConfig() {
    yield takeEvery(dashboard.actions.restartDevice, restartDeviceSaga)
}


function* fetchPrepaidTransactionsSaga({payload}) {
    console.log('fetchPrepaidTransactionsSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(fetchPrepaidTransactions, {...payload, trackingId, sessionId})

        if (response.businessErrorCode) {
            yield put(dashboard.actions.fetchPrepaidTransactionsFailure(response))
        } else {
            yield put(dashboard.actions.fetchPrepaidTransactionsSuccess(response))
        }
    } catch (error: any) {
        console.error('fetchPrepaidTransactionsSaga error', error)

        digitalDataAPIError('fetchPrepaidTransactionsSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            console.log('fetchPrepaidTransactionsSaga error', error)
            yield put(dashboard.actions.fetchPrepaidTransactionsFailure({response: error}))
        }
    }
}

function* fetchPrepaidTransactionsSagaConfig() {
    yield takeEvery(dashboard.actions.fetchPrepaidTransactions, fetchPrepaidTransactionsSaga)
}

function* fetchPaymentInfoSaga({payload}) {
    console.log('fetchPaymentInfoSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(fetchPaymentInfo, {...payload, trackingId, sessionId})
        yield put(dashboard.actions.fetchPaymentInfoSuccess(response))
    } catch (error: any) {
        console.error('fetchPaymentInfoSaga error', error)

        digitalDataAPIError('fetchPaymentInfoSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            console.log('fetchPaymentInfoSaga error', error)
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.fetchPaymentInfoFailure({response: error.response?.data}))
        }
    }
}

function* fetchPaymentInfoSagaConfig() {
    yield takeEvery(dashboard.actions.fetchPaymentInfo, fetchPaymentInfoSaga)
}

function* enableAutoRefillSaga({payload}) {
    console.log('enableAutoRefillSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(enableAutoRefill, {...payload, trackingId, sessionId})
        if (response.businessErrorCode) {
            yield put(dashboard.actions.enableAutoRefillFailure(response))
        } else{
            yield put(dashboard.actions.enableAutoRefillSuccess(response))
        }
    } catch (error:any) {
        console.log('enableAutoRefillSaga error', error)
        digitalDataAPIError('enableAutoRefillSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.enableAutoRefillFailure({response: error.response?.data}))
        }
    }
}

function* enableAutoRefillSagaConfig() {
    yield takeEvery(dashboard.actions.enableAutoRefill, enableAutoRefillSaga)
}


function* disableAutoRefillSaga({payload}) {
    console.log('disableAutoRefillSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(disableAutoRefill, {...payload, trackingId, sessionId})
        yield put(dashboard.actions.disableAutoRefillSuccess(response))
    } catch (error: any) {
        console.error('disableAutoRefillSaga error', error)

        digitalDataAPIError('disableAutoRefillSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.disableAutoRefillFailure({response: error.response?.data}))
        }
    }
}

function* disableAutoRefillSagaConfig() {
    yield takeEvery(dashboard.actions.disableAutoRefill, disableAutoRefillSaga)
}


function* addPaymentInstrumentSaga({payload}) {
    console.log('addPaymentInstrumentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(addPaymentInstrument, {payload, trackingId, sessionId})

        if (response.businessErrorCode) {
            yield put(dashboard.actions.addPaymentInstrumentFailure(response))
        } else {
            yield put(dashboard.actions.addPaymentInstrumentSuccess(response))
        }
    } catch (error: any) {
        console.error('addPaymentInstrumentSaga error', error)

        digitalDataAPIError('addPaymentInstrumentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.addPaymentInstrumentFailure(error?.response ? error.response?.data :{}))
        }
    }
}

function* addPaymentInstrumentSagaConfig() {
    yield takeEvery(dashboard.actions.addPaymentInstrument, addPaymentInstrumentSaga)
}
function* fetchBroadbandFactsContentSagaConfig() {
    yield takeEvery(dashboard.actions.fetchBroadbandFactsContent, fetchBroadbandFactsContentSaga)
}


function* removePaymentInstrumentSaga({payload}) {
    console.log('removePaymentInstrumentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(removePaymentInstrument, {payload, trackingId, sessionId})

        if (response?.businessErrorCode) {
            yield put(dashboard.actions.removePaymentInstrumentFailure(response))
        } else {
            yield put(dashboard.actions.removePaymentInstrumentSuccess(response))
        }
    } catch (error:any) {
        console.log('removePaymentInstrumentSaga error', error)

        digitalDataAPIError('removePaymentInstrumentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.removePaymentInstrumentFailure({response: error.response?.data}))
        }
    }
}

function* removePaymentInstrumentSagaConfig() {
    yield takeEvery(dashboard.actions.removePaymentInstrument, removePaymentInstrumentSaga)
}


function* updatePaymentInstrumentSaga({payload}) {
    console.log('updatePaymentInstrumentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(updatePaymentInstrument, {payload, trackingId, sessionId})

        if (response.businessErrorCode) {
            yield put(dashboard.actions.updatePaymentInstrumentFailure(response))
        } else {
            yield put(dashboard.actions.updatePaymentInstrumentSuccess(response))
        }
    } catch (error: any) {
        console.error('updatePaymentInstrumentSaga error', error)

        digitalDataAPIError('updatePaymentInstrumentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.updatePaymentInstrumentFailure({response: error.response?.data}))
        }
    }
}

function* updatePaymentInstrumentSagaConfig() {
    yield takeEvery(dashboard.actions.updatePaymentInstrument, updatePaymentInstrumentSaga)
}

function* validateActivationCodeSaga({payload}) {
    console.log('validateActivationCodeSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(validateActivationCode, {...payload, trackingId, sessionId})
        if (response.errorCode) {
            yield put(dashboard.actions.validateActivationCodeFailure({response: response.response}))
        } else {
            yield put(dashboard.actions.validateActivationCodeSuccess(response.response))
        }
    } catch (error: any) {
        console.error('validateActivationCodeSaga error', error)

        digitalDataAPIError('validateActivationCodeSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.validateActivationCodeFailure({response: error.response?.data}))
        }
    }
}

function* validateActivationCodeSagaConfig() {
    yield takeEvery(dashboard.actions.validateActivationCode, validateActivationCodeSaga)
}

function* fetchPromosSaga({payload}) {
    console.log('fetchPromosSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(fetchPromos, {...payload, trackingId, sessionId})
        yield put(dashboard.actions.fetchPromosSuccess(response))
    } catch (error: any) {
        console.error('fetchPromosSaga error', error)

        digitalDataAPIError('fetchPromosSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.fetchPromosFailure({response: error.response}))
        }
    }
}

function* fetchPromosSagaConfig() {
    yield takeEvery(dashboard.actions.fetchPromos, fetchPromosSaga)
}


function* fetchTaxDataSaga({payload}) {
    console.log('fetchTaxDataSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const dstate = yield select(getDashboardState)

        const products:any[] = []

        const productCodes = dstate?.cart.internet
            ? [dstate?.cart.internet, ...dstate?.cart.packages, ...dstate?.cart.addOns]
            : [...dstate?.cart.packages, ...dstate?.cart.addOns]

        productCodes.forEach(productCode => {
            products.push({
                productCode,
                price: dstate.dashboard.availablePlans.find(s => s.productCode === productCode).planPrice
            })
        })

        const geoCode = dstate.dashboard.customerInfo.geoCode

        const response = yield call(fetchTaxData, {...payload, products, geoCode, trackingId})
        yield put(dashboard.actions.fetchTaxDataSuccess(response))
    } catch (error: any) {
        console.error('fetchTaxDataSaga error', error)
        
        digitalDataAPIError('fetchTaxDataSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(dashboard.actions.fetchTaxDataFailure({response: error.response?.data}))
    }
}

function* fetchTaxDataSagaConfig() {
    yield takeEvery(dashboard.actions.fetchTaxData, fetchTaxDataSaga)
}


function* refillPrepaidAccountSaga({payload}) {
    console.log('refillPrepaidAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(refillPrepaidAccount, {payload, trackingId, sessionId})
        if (response.errorCode) {
            yield put(dashboard.actions.refillPrepaidAccountFailure(response))
        } else {
            yield put(dashboard.actions.refillPrepaidAccountSuccess(response))
        }
    } catch (error: any) {
        console.error('refillPrepaidAccountSaga error', error)

        digitalDataAPIError('refillPrepaidAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.refillPrepaidAccountFailure(error.response ? error.response?.data : {}))
        }
    }
}

function* refillPrepaidAccountSagaConfig() {
    yield takeEvery(dashboard.actions.refillPrepaidAccount, refillPrepaidAccountSaga)
}


function* redeemRefillSaga({payload}) {
    console.log('redeemRefillSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(redeemRefill, {...payload, trackingId, sessionId})
        if (response.businessErrorCode || response.status === 'FAILURE') {
            yield put(dashboard.actions.redeemRefillFailure(response))
        } else {
            yield put(dashboard.actions.redeemRefillSuccess(response))
        }
    } catch (error: any) {
        console.error('redeemRefillSaga error', error)

        digitalDataAPIError('redeemRefillSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            console.log('redeemRefillSaga error', error)
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.redeemRefillFailure({response: error.response?.data}))
        }
    }
}

function* redeemRefillSagaConfig() {
    yield takeEvery(dashboard.actions.redeemRefill, redeemRefillSaga)
}



function* fetchAuxAccountSaga({payload}) {
    console.log('fetchAuxAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(getAuxAccount, {payload, trackingId, sessionId})
        if (response.errorCode) {
            yield put(dashboard.actions.fetchAuxAccountFailure(response))
        } else {
            yield put(dashboard.actions.fetchAuxAccountSuccess(response.response))
        }
    } catch (error: any) {
        console.error('fetchAuxAccountSaga error', error)

        digitalDataAPIError('fetchAuxAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.fetchAuxAccountFailure({response: error.response?.data}))
        }
    }
}

function* fetchAuxAccountSagaConfig() {
    yield takeEvery(dashboard.actions.fetchAuxAccount, fetchAuxAccountSaga)
}

function* createAuxAccountSaga({payload}) {
    console.log('createAuxAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(createAuxAccount, {payload, trackingId, sessionId})
        if (response.errorCode) {
            yield put(dashboard.actions.createAuxAccountFailure(response))
        } else {
            yield put(dashboard.actions.createAuxAccountSuccess(response.response))
        }
    } catch (error: any) {
        console.error('createAuxAccountSaga error', error)

        digitalDataAPIError('createAuxAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.createAuxAccountFailure(error))
        }
    }
}

function* createAuxAccountSagaConfig() {
    yield takeEvery(dashboard.actions.createAuxAccount, createAuxAccountSaga)
}

function* updateScheduledMoveSaga({payload}) {
    console.log('updateScheduledMoveSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(updateScheduledMove, {payload, trackingId, sessionId})
        if (response.errorCode) {
            yield put(dashboard.actions.updateScheduledMoveFailure(response))
        } else {
            yield put(dashboard.actions.updateScheduledMoveSuccess(response.response))
        }
    } catch (error: any) {
        console.error('updateScheduledMoveSaga error', error)

        digitalDataAPIError('updateScheduledMoveSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.updateScheduledMoveFailure(error))
        }

    }
}

function* updateScheduledMoveSagaConfig() {
    yield takeEvery(dashboard.actions.updateScheduledMove, updateScheduledMoveSaga)
}


function* pingSessionSaga({payload}) {
    // console.log('pingSessionSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const sessionData = yield select(getSessionData)

        const now = Date.now()
        const sessionTimeout = 14 * 60 * 1000;
        const expiresAt = sessionData.lastActionAt + sessionTimeout
        const diff =  expiresAt - now
        const sessionOk = !!sessionId && !sessionData.sessionError
        // ping if forced or less than 5 minutes until session expires
        const shouldPingServer = (payload && payload.force) || (diff < (5 * 60 * 1000))

        // console.log('shouldPingServer =', shouldPingServer, ', sessionOk =', sessionOk, ' diff =', diff, 'expiresAt =', expiresAt, 'lastActionAt =', sessionData.lastActionAt)

        if (sessionOk && shouldPingServer) {
            const response = yield call(pingSession, {trackingId, sessionId})
            yield put(dashboard.actions.pingSessionSuccess({...response, pinged: true}))
        } else {
            yield put(dashboard.actions.pingSessionSuccess({pinged: false}))
        }
    } catch (error: any) {
        console.error('pingSessionSaga error', error)

        digitalDataAPIError('pingSessionSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.pingSessionFailure(error.response))
        }
    }
}

function* pingSessionSagaConfig() {
    yield takeEvery(dashboard.actions.pingSession, pingSessionSaga)
}

function* fetchBroadbandFactsContentSaga({payload}) {
    console.log('fetchBroadbandFactsContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(fetchBroadbandFactsContent, {payload, trackingId, sessionId})

        if (response) {
            yield put(dashboard.actions.fetchBroadbandFactsContentFailure(response))
        } 
        else {
            yield put(dashboard.actions.fetchBroadbandFactsContentSuccess(response))
        }
    } catch (error: any) {
        console.error('fetchBroadbandFactsContentSaga error', error)

        digitalDataAPIError('fetchBroadbandFactsContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        if (isSessionError(error)) {
            yield put(dashboard.actions.setSessionError(error))
        } else {
            yield put(dashboard.actions.addPaymentInstrumentFailure(error?.response ? error.response?.data :{}))
        }
    }
}

export {
    fetchIdentityInfoSaga,
    fetchIdentityInfoSagaConfig,
    updateCustomerSaga,
    updateCustomerSagaConfig,
    updateIDCMTCustomerInfoSaga,
    updateIDCMTCustomerInfoSagaConfig,
    fetchCustomerDashboardSaga,
    fetchCustomerDashboardSagaConfig,
    restartDeviceSaga,
    restartDeviceSagaConfig,
    fetchPrepaidTransactionsSaga,
    fetchPrepaidTransactionsSagaConfig,
    fetchPaymentInfoSaga,
    fetchPaymentInfoSagaConfig,
    enableAutoRefillSaga,
    enableAutoRefillSagaConfig,
    disableAutoRefillSaga,
    disableAutoRefillSagaConfig,
    addPaymentInstrumentSaga,
    addPaymentInstrumentSagaConfig,
    removePaymentInstrumentSaga,
    removePaymentInstrumentSagaConfig,
    updatePaymentInstrumentSaga,
    updatePaymentInstrumentSagaConfig,
    validateActivationCodeSaga,
    validateActivationCodeSagaConfig,
    fetchPromosSaga,
    fetchPromosSagaConfig,
    fetchTaxDataSaga,
    fetchTaxDataSagaConfig,
    refillPrepaidAccountSaga,
    refillPrepaidAccountSagaConfig,
    redeemRefillSaga,
    redeemRefillSagaConfig,
    fetchAuxAccountSaga,
    fetchAuxAccountSagaConfig,
    createAuxAccountSaga,
    createAuxAccountSagaConfig,
    updateScheduledMoveSaga,
    updateScheduledMoveSagaConfig,
    pingSessionSaga,
    pingSessionSagaConfig,
    fetchBroadbandFactsContentSagaConfig
}
