import withLitepay from '../../redux/connectors/litepay'
import { formatMoney } from '../../utils/utils'

const Cart = (props) => {

    const {
        cartContent,
        cartSubtotal,
        cartTaxes,
        cartTotal,
        dashboard,
        showDetails,
        litepayPages,
        isTribalACP,
        haveValidACP
    } = props
    return (
        <div className="cart">
            {/* CART LIST */}
            <div className="width-100">

                {/* SECTION A */}
                <div>
                    <div className="card ">
                        <table className="totals">
                            <caption className="totals__caption">
                                <div className="card__header">
                                    <h2 className="card__header-title">
                                        {litepayPages?.litepay?.productName}
                                    </h2>
                                </div>
                            </caption>
                            <tbody>
                                <tr className="line-item">
                                    <th className="line-item__label">
                                        {litepayPages?.litepay?.productName}
                                    </th>
                                    <td className="line-item__price">
                                        <span className="price-full"><span>$</span>{ dashboard?.availablePlans ? formatMoney(dashboard?.availablePlans[0].planPrice) : '' }</span>
                                    </td>
                                </tr>
                                { haveValidACP && isTribalACP && 
                                    <tr className="line-item">
                                        <td>
                                            <span className="acp-tribal-title">
                                                { litepayPages?.acP?.enrolledInApCTribalCredit }
                                            </span>
                                            <span className="acp-tribal-disclaimer">
                                                { litepayPages?.acP?.becauseYouAreEnrolledInAcP }
                                            </span>
                                        </td>
                                    </tr>
                                }
                                { haveValidACP && !isTribalACP &&
                                <tr className="line-item">
                                    <th className="line-item__label">*${ dashboard?.acpDetail?.acpBenefits[0].benefitAmount } { litepayPages?.acP?.after30AcPCredit }</th>
                                    <td className="line-item__price">
                                        <span className="price-full">- <span>$</span>{ formatMoney(dashboard?.acpDetail?.acpBenefits[0].benefitAmount) }</span>
                                    </td>
                                </tr>
                                }
                                { haveValidACP && !isTribalACP &&
                                <tr className="line-item">
                                    <td className="line-item__price">
                                        <span className="price-full price-acp-total"><span>$</span>{ formatMoney(cartSubtotal) }</span>
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* SUBTOTAL */}
            {showDetails ? 
                <div className="card card--clear card--order-subtotal">
                    <table className="order-price-summary-table">
                        <caption className="vh">
                            { cartContent?.cartContent?.cartModule?.subtotalLabel }
                        </caption>
                        <tbody>
                            <tr>
                                <th className="order-price-summary-table__label">
                                    { cartContent?.cartContent?.cartModule?.subtotalLabel }
                                </th>
                                <td className="order-price-summary-table__price">
                                    <div className="price-full">
                                        <span>$</span>{ formatMoney(cartSubtotal) }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="order-price-summary-table__label">
                                    { cartContent?.cartContent?.cartModule?.taxesLabel }
                                </th>
                                <td className="order-price-summary-table__price">
                                    <div className="price-full">
                                    <span>$</span>{ formatMoney(cartTaxes) }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                : null}

            {/* TOTAL */}
            { showDetails && 
                <div className="card card--clear">
                    <table className="order-price-summary-table">
                        <thead>

                        </thead>
                        <caption className="vh">
                            {cartContent?.cartContent?.cartModule?.totalPriceText}
                        </caption>
                        <tbody>
                            <tr>
                                <th className="order-price-summary-table__label">
                                    {cartContent?.cartContent?.cartModule?.totalPriceText}
                                </th>
                                <td className="order-price-summary-table__price">
                                    <div className="price-full" tabIndex={-1}>
                                    <span>$</span>{ showDetails ? formatMoney(cartTotal) : formatMoney(cartSubtotal) }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )

}
export default withLitepay(Cart)