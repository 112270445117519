import { createSlice } from '@reduxjs/toolkit'
interface CommonState {
    shopBaseUrl: string
    locales: ['en','es']
    currentLocale: 'en'|'es'

    inApp: boolean
    behindTheModem: boolean

    accountData: any | null
    gettingAccount: boolean
    gotAccount: boolean
    getAccountFailed: boolean

    mediaBaseUrl: string
    pageClass: string

    accountId: string | null
    custGuid: string | null
    sessionId: string | null

    progress: number
    progressTitle: string

    notificationMessage: string
    confirmationMessage: string

    showErrorPage: boolean
    error: string

    haveComcastApiError: boolean
    comcastApiErrors: string[]

    trackingId: string

    xsrfToken: string | null
    fetchedToken: boolean
    fetchingToken: boolean
    fetchTokenFailed: boolean

    isTestFile:boolean
}

const initialState = {
    shopBaseUrl: '',
    locales: ['en', 'es'],
    currentLocale: 'en',

    inApp: false,
    behindTheModem: false,

    accountData: null,
    gettingAccount: false,
    gotAccount: false,
    getAccountFailed: false,

    mediaBaseUrl: '',
    pageClass: '',

    accountId: '',
    custGuid: '',
    sessionId: null,

    progress: 0,
    progressTitle: '',

    notificationMessage: '',
    confirmationMessage: '',

    showErrorPage: false,
    error: '',

    haveComcastApiError: false,
    comcastApiErrors: [],

    trackingId: '',

    xsrfToken: null, // XXX not populated, not needed?
    fetchedToken: false,
    fetchingToken: false,
    fetchTokenFailed: false,

    isTestFile:false,
} as CommonState

const common = createSlice({
    name: 'common',
    initialState,
    reducers: {
        generateTrackingId: (state, {payload}) => {
            if (!state.trackingId) {
                state.trackingId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    var r = Math.random() * 16 | 0
                    var v = c === 'x' ? r : (r & 0x3 | 0x8) // eslint-disable-line no-mixed-operators
                    return v.toString(16)
                  })
            }
        },

        updateCurrentLocale: (state, {payload}) => {
            state.currentLocale = payload
            /* if (state.currentLocale === 'es') {
                state.content = {...state.spanishContent}
            } else {
                state.content = {...state.englishContent}
            } */
        },

        updatePageClass: (state, {payload}) => {
            state.pageClass = payload
        },

        updateInAppStatus: (state, {payload}) => {
            state.inApp = payload
        },

        updateBehindTheModemStatus: (state, {payload}) => {
            state.behindTheModem = payload
        },

        updateAccountId: (state, {payload}) => {
            state.accountId = payload
        },

        updateCustGuid: (state, {payload}) => {
            state.custGuid = payload
        },

        updateSessionId: (state, {payload}) => {
            state.sessionId = payload
        },

        updateProgress: (state, {payload}) => {
            state.progressTitle = payload.title
            state.progress = payload.progress
        },

        setNotificationMessage: (state, {payload}) => {
            state.notificationMessage = payload
        },
        clearNotificationMessage: (state, {payload}) => {
            state.notificationMessage = ''
        },

        setConfirmationMessage: (state, {payload}) => {
            state.notificationMessage = payload
        },
        clearConfirmationMessage: (state, {payload}) => {
            state.notificationMessage = ''
        },

        fetchToken: (state) => {
            state.fetchingToken = true
        },
        fetchTokenSuccess: (state, {payload}) => {
            console.log('fetchTokenSuccess payload', payload)
            state.xsrfToken = payload.xsrfToken
            state.fetchingToken = false
            state.fetchTokenFailed = false
            state.fetchedToken = true
        },
        fetchTokenFailure: (state, {payload}) => {
            console.log('fetchTokenFailure payload', payload)
            state.fetchedToken = false
            state.fetchingToken = false
            state.fetchTokenFailed = true
        },

        getAccount: (state) => {
            state.gettingAccount = true
        },
        getAccountSuccess: (state, {payload}) => {
            console.log('getAccountSuccess payload', payload)
            state.accountData = payload
            if (payload.mac) {
                state.behindTheModem = true
            }
            state.gettingAccount = false
            state.getAccountFailed = false
            state.gotAccount = true
        },
        getAccountFailure: (state, {payload}) => {
            console.log('getAccountFailure payload', payload)
            state.gotAccount = false
            state.gettingAccount = false
            state.getAccountFailed = true
        },    }
})

export { common };
export const {
  generateTrackingId,
  updateCurrentLocale,
  updatePageClass,
  updateInAppStatus,
  updateBehindTheModemStatus,
  updateAccountId,
  updateCustGuid,
  updateSessionId,
  updateProgress,
  setNotificationMessage,
  clearNotificationMessage,
  setConfirmationMessage,
  clearConfirmationMessage,
  fetchToken,
  fetchTokenSuccess,
  fetchTokenFailure,
  getAccount,
  getAccountSuccess,
  getAccountFailure,
} = common.actions;
export default common.reducer;
