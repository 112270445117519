const ActivationSuccessBanner = ({text}) => {

    return (
        <div className="alert-banner alert-banner-success">
            <div className="main__width-constraint">
                <div className="icon" aria-label="Yes, this was helpful">
                    <img alt='Yes, this was helpful' src='/site-assets/img/icon-check-black.svg'></img>
                </div>
                <div className="text">{text}</div>
            </div>

        </div>
    )

}

export default ActivationSuccessBanner
