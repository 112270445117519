import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataModalOpen } from '../../utils/adobe-analytics'
// import AutoRefillTable from './AutoRefillTable'

// interface ComponentProps {
//     title?: String
//     modalCloseButton?: String
//     buttonClasses?: any
//     ctaText?: String
// }

const AutoRefillModal = (props) => {
    const {
        title,
        modalCloseButton,
        buttonClasses,
        ctaText,
    } = props

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)


    const openModal = () => {
        setModalIsOpen(true)
        // document.body.className += ' modal-open'
        document.body.classList.add('modal-open')
        trackEvent({
            event: 'dashboard',
            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
            dashboardEvent: 'auto refill modal',
            dashboardDetail: 'open'
        })
    }

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false)
            // document.body.className = document.body.className.replace(new RegExp(/modal-open/g), '')
            document.body.classList.remove('modal-open')
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, []) // eslint-disable-line

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
            // inside click
            return
        }
        // outside click
        closeModal()
    }

    const cancelAutoRefill = () => {
        // disableAutoRefill({accountId, phoneNumber: dashboard.customerInfo.phoneNumber})
        if (props.onCancel) {
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'auto refill modal',
                dashboardDetail: 'cancel clicked'
            })
            props.onCancel()
        }
        closeModal()
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
           <button type="button" data-testid="openModal" className={`auto-refill-badge ${buttonClasses}`} onClick={openModal} data-tracking={digitalDataModalOpen('auto-refill')}>{ ctaText }</button>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                    <div ref={node} className="modal__body">
                        <h3 id="modal__title" tabIndex={0} className="modal__title">{ title }</h3>
                        {/* <AutoRefillTable /> */}
                        <div className="button--container">
                            <button
                                className="button--link"
                                onClick={() => cancelAutoRefill()}
                                data-tracking={digitalDataButtonClick('cancel-auto-refill', 'Cancel Auto Refill')}
                            >Cancel Auto Refill</button>
                        </div>

                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', modalCloseButton, true)}>
                            { modalCloseButton }
                            <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withDashboard(AutoRefillModal)