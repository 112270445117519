import { useState, useEffect } from 'react';
import { digitalDataButtonClick } from '../../utils/adobe-analytics';

export default function ConfirmationButton(props){

    const [animationActive, setAnimationActive] = useState(false);
    const [animationComplete, setAnimationComplete] = useState(false);
    const animTime = 2000;

    useEffect(()=>{
        props.isTestFile && props.CB();
    });
    const styles = {
        touchButton: {
            animation: "grow-circle forwards " + props.animTime/1000+"s"
        }
    };
    const longPressEvent = useLongPress(
        function(){ 
            props.CB();
            setAnimationComplete(true);
        }, 
        animTime, 
        setAnimationActive
    );

    return (
        <div>
            <button data-testid="btn-confirm" className="confirm-button" {...longPressEvent} data-tracking={digitalDataButtonClick('confirm', props.title)}>
                {props.title}
            </button>
            {
                animationActive ? <div style={styles.touchButton} className="confirm-button--animation active"></div> : <div className="confirm-button--animation"></div>
            }
            {
                animationComplete ? <div className="confirm-button--check"></div> : <div></div>
            }
        </div>
    )
}

function useLongPress(callback = () => {}, ms = 300, completeFunction) {
    const [startLongPress, setStartLongPress] = useState(false);
  
    useEffect(() => {
      let timerId;
      if (startLongPress) {
          completeFunction(true);
          timerId = setTimeout(callback, ms);
  
      } else {
          completeFunction(false);
  
        clearTimeout(timerId);
      }
  
      return () => {
        clearTimeout(timerId);
      };
  
    }, [callback, ms, startLongPress, completeFunction]);
  
    return {
      onMouseDown: () => setStartLongPress(true),
      onMouseUp: () => setStartLongPress(false),
      onMouseLeave: () => setStartLongPress(false),
      onTouchStart: () => setStartLongPress(true),
      onTouchEnd: () => setStartLongPress(false),
    };
  }
  