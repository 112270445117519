import { put, select, call, takeEvery } from 'redux-saga/effects'
import { fetchToken, getAccount } from './apis'
import { common } from './store'
import { digitalDataAPIError } from '../../utils/adobe-analytics'

const getTrackingId = (state) => state?.common?.trackingId
const getSessionId = (state) => state?.common?.sessionId

function* fetchTokenSaga({payload}) {
    // console.log('fetchTokenSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchToken, {trackingId});
        yield put(common?.actions?.fetchTokenSuccess(response));
    } catch (error: any) {
        console.log('token catch error', error)
        digitalDataAPIError('fetchProductDetailsSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(common?.actions?.fetchTokenFailure({}));
    }
}

function* fetchTokenSagaConfig() {
    yield takeEvery(common?.actions?.fetchToken, fetchTokenSaga);
}

function* getAccountSaga({payload}) {
    console.log('getAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(getAccount, {trackingId, sessionId});
        yield put(common?.actions?.getAccountSuccess(response));
    } catch (error: any) {
        console.log('getAccount catch error', error, error?.response?.data)
        digitalDataAPIError('getAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(common?.actions?.getAccountFailure(error?.response?.data));
    }
}

function* getAccountSagaConfig() {
    yield takeEvery(common?.actions?.getAccount, getAccountSaga);
}

export {
    fetchTokenSagaConfig,
    getAccountSagaConfig,
    fetchTokenSaga,
    getAccountSaga
}

