import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import withBuyflow from '../../redux/connectors/buyflow'
import AccordionText from '../AccordionText'
import PromoModalAccordion from '../buyflow/PromoModalAccordion'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataLinkClick, digitalDataModalOpen } from '../../utils/adobe-analytics'

const ModalPromoCta = (props) => {
    const {
        togglePromo,
        modalContent,
        hsdPromoId
    } = props
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)

    const openModal = () => {
        setModalIsOpen(true)
        document.body.classList.add('modal-open')

        trackEvent({
            'event':'buyflow info',
            'buyStep':'step 4',
            'buyStepName':'order review',
            'buyEvent':'offer acquisition tile',
            'buyDetail':'click'
        })
    }

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false)
            document.body.classList.remove('modal-open')
            document.removeEventListener("mousedown", handleClick)
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener("mousedown", handleClick)
        } else {
            document.removeEventListener("mousedown", handleClick)
            trackEvent({
                event: 'buyflow info',
                buyStep: 'step 4',
                buyStepName: 'order review',
                buyEvent: 'offer acquisition tile',
                buyDetail: 'impression'
            })
        }
        
        // eslint-disable-next-line
    }, [modalIsOpen])

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }
    const handlePurchaseClick = () => {
        togglePromo(hsdPromoId)

        trackEvent({
            event: 'buyflow info',
            buyStep: 'step 4',
            buyStepName: 'order review',
            buyEvent: 'offer acquisition tile',
            buyDetail: 'add to cart'
        })
        setModalIsOpen(!modalIsOpen)
        document.body.classList.remove('modal-open')
        document.removeEventListener("mousedown", handleClick)
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <div className="promo-card-intro">{ modalContent?.bannerAddTitle }</div>
            <button className="card promo-card" onClick={openModal} data-tracking={digitalDataModalOpen('promo')}>
                <div className='promo-card_wrapper'>
                    <div className="promo-card_img-container">
                        <img className="display-large" src={modalContent?.bannerImage?.desktopImage?.url} alt={modalContent?.bannerImage?.desktopImage?.alt} />
                        <img className="display-small-medium" src={modalContent?.bannerImage?.mobileImage?.url} alt={modalContent?.bannerImage?.mobileImage?.alt} />
                        <div className="promo-card_flag">{ modalContent?.bannerAddPrompt }</div>
                    </div>
                    <div className="promo-card_text-container">
                        { modalContent?.bannerAddText }
                    </div>
                </div>
            </button>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content">
                <div ref={node} className="modal__body direct-promo">
                            <h3>{modalContent?.title}</h3>
                            <p>{modalContent?.body}</p>
                            <div className="promo-v2-text" >
                                <button type="button" className="button--link--large" onClick={() => handlePurchaseClick()} data-tracking={digitalDataButtonClick('purchase', modalContent?.purchase?.text)}>{ modalContent?.purchase?.text }</button>
                                <div>
                                    <table className="price-wrap">
                                        <tbody>
                                            <tr>
                                                <td className="price-wrap__amount">
                                                <span>$</span>{ modalContent?.price }
                                                </td>
                                                <td className="price-wrap__label">
                                                <strong>{ modalContent?.priceForUpTo }</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <AccordionText title={modalContent?.pricingOtherInfo} body={modalContent?.pricingOtherInfoContent} />
                                </div>
                            </div>
                            <div className="direct-promo__hero-banner">
                                <img className="display-large" src={modalContent?.bannerImage?.desktopImage?.url} alt={modalContent?.bannerImage?.desktopImage?.alt} />
                                <img className="display-small-medium" src={modalContent?.bannerImage?.mobileImage?.url} alt={modalContent?.bannerImage?.mobileImage?.alt} />
                                <div className="direct-promo__hero-banner-container">
                                    <div className="main__width-constraint clear">
                                        {modalContent?.saveLabel}
                                    </div>
                                </div>
                            </div>

                            <div id="main-content">
                                <div className="main__width-constraint">
                                    <div className="card card-promo">
                                        <div className="card__summary">
                                            <div className="accordion direct-promo-accordion direct-promo-accordion-v2" role="tablist" multiselectable="true">
                                            <h4>{ modalContent?.faQs?.item?.title }</h4>
                                            <a href={modalContent?.allSupport?.link?.url} target="_blank" rel="noreferrer" className="accordion-link" data-tracking={digitalDataLinkClick('accordion-link', modalContent?.allSupport?.link?.url, modalContent?.allSupport.text)}>{ modalContent?.allSupport.text }</a>
                                            <PromoModalAccordion items={modalContent?.faQs.item.children[0] || []} />
                                            </div>
                                        </div>
                                            <div className="button--container">
                                                <button className="button" onClick={() => handlePurchaseClick()} data-tracking={digitalDataButtonClick('purchase', modalContent?.purchase.text)}>{modalContent?.purchase.text}</button>
                                                <br />
                                                <button className="button--link" onClick={() => closeModal()} data-tracking={digitalDataButtonClick('close-modal', modalContent?.decline, true)}>{modalContent?.decline}</button>
                                            </div>
                                    </div>

                                </div>
                            </div>
                            <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', 'Close', true)}>
                                Close
                                <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                            </button>
                        </div >
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withBuyflow(ModalPromoCta)