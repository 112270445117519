import { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import ProgressBar from "../../components/ProgressBar";
import RefillTab from "../../components/refill/refillTab";
import Cart from "../../components/refill/Cart";
import StickyCart from "../../components/refill/StickyCart";
import withDashboard from "../../redux/connectors/dashboard";
import { trackEvent } from "../../utils/utils";
import WinbackPromoModal from "../../components/refill/WinbackPromoModal";
import {
  digitalDataButtonClick,
  digitalDataAddToCart,
} from "../../utils/adobe-analytics";
import { useHistory } from "react-router-dom";
import TrackPageComplete from "../../utils/TrackPageComplete";
import TrackUserData from "../../utils/TrackUserData";
import BroadbandFacts from "../../components/refill/BroadbandFacts";
import SpanishBroadbandFacts from "../../components/Spanish_BroadbandFacts";

export const Refill = (props) => {
  const {
    cartContent,
    locale,
    refillContent,
    paymentInfo,
    fetchedPaymentInfo,
    cart,
    hsdService,
    cartCount,
    haveValidPromo,
    isWinbackPromo,
    havePromoInCart,
    clearCart,
    refill,
    dashboard,
    cartTotal,
    isTestFile,
  } = props;
  const cartComponentContent = { cartContent, refillContent };
  const history = useHistory();
  const showingRefillConfirmation =
    refill && refill.cart && window.location.href.includes("confirmation");
  const activeCart = showingRefillConfirmation ? refill.cart : cart;

  const [progress, setProgress] = useState(33);
  const [isPinFlow, setPinFlow] = useState(false);
  const [hasSavedCard, setHasSavedCard] = useState(false);
  const [prepaidInstantUnavailableNotice, setPrepaidInstantUnavailableNotice] =
    useState(false);
  const [displayPromoModal, setDisplayPromoModal] = useState(false);

  const updateProgress = (newProgress) => {
    setProgress(newProgress);
  };

  const updatePinFlow = (value) => {
    setPinFlow(value);
  };

  useEffect(() => {
    props.updatePageClass("dashboard refill");

    let productString = cart.internet;
    if (cart.packages.length > 0) {
      productString += ", " + cart.packages.join(", ");
    }
    if (cart.addOns.length > 0) {
      productString += ", " + cart.addOns.join(", ");
    }
    trackEvent({
      event: "refill flow",
      refillStep: "step 1",
      refillStepName: "start refill",
      refillDetail: "",
    });
    trackEvent({
      event: "refill info",
      refillStep: "step 1",
      refillStepName: "start refill",
      refillEvent: "previous product detailed",
      refillDetail: productString,
    });
    trackEvent({
      event: "refill info",
      refillStep: "step 1",
      refillStepName: "start refill",
      refillEvent: "days left of service",
      refillDetail:
        hsdService && hsdService.daysRemaining > 0
          ? hsdService.daysRemaining + " days"
          : "serviceExpired",
    });
    if (havePromoInCart && isWinbackPromo) {
      clearCart();
    }
    sessionStorage.removeItem("refill-completed");
  }, []);

  useEffect(() => {
    if (fetchedPaymentInfo && paymentInfo) {
      setHasSavedCard(true);
    }
  }, [fetchedPaymentInfo, paymentInfo]);
  useEffect(() => {
    const alreadyLoaded = sessionStorage.getItem("alreadyLoadedRefill");
    if (!alreadyLoaded) {
      sessionStorage.setItem("alreadyLoadedRefill", JSON.stringify(true));
      sessionStorage.removeItem("errorReloadCount");
      window.location.reload();
    }
  }, []);

  const handleNext = (e) => {
    console.log("unavailable xitv: ", prepaidInstantUnavailableNotice);
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.removeItem("cpcTermsAndConditions");

    digitalDataButtonClick(
      "handle-next",
      "Next",
      false,
      undefined,
      true,
      ".refill"
    );
    digitalDataAddToCart(
      "standard refill flow",
      "addToCart",
      ".refill",
      havePromoInCart,
      cart?.internet
    );
    trackEvent({
      event: "refill info",
      refillStep: "step 1",
      refillStepName: "start refill",
      refillEvent: "next step cta",
      refillDetail: "Next",
    });
    if (
      haveValidPromo &&
      !havePromoInCart &&
      cartCount === 1 &&
      cart.internet === "HSD_030"
    ) {
      setDisplayPromoModal(true);
    } else {
      if (hasSavedCard) {
        console.log("redirecitng to review page...");
        trackEvent({
          event: "refill flow",
          refillStep: "step 2",
          refillStepName: "payment",
          refillStepDetail: "saved debit credit",
        });
        history.push("/" + locale + "/refill/review");
      } else {
        console.log("redirecitng to payment page...");
        history.push("/" + locale + "/refill/payment");
      }
    }
  };
  const [formValid, updateFromStatus] = useState(false);

  const handleFormStatus = (formStatus) => {
    updateFromStatus(formStatus);
  };

  return (
    <DashboardLayout
      locale={props.locale}
      pageTitle={refillContent?.refillFlow?.newScenarios?.refillXfinityPrepaid}
    >
      <TrackUserData data={dashboard} />
      {refillContent.loaded && (
        <div>
          <ProgressBar title={"Customize your refill"} percent={progress} />
          <div className="main__width-constraint refill">
            <br />
            <h1 className="page__title">
              {refillContent.refillFlow.cart.title}
            </h1>
            <br />
            <div className="main__desktop-body">
              <RefillTab
                handleFormStatus={handleFormStatus}
                updateProgress={updateProgress}
                updatePinFlow={updatePinFlow}
                setPrepaidInstantUnavailableNotice={
                  setPrepaidInstantUnavailableNotice
                }
              />
              {!isPinFlow && (
                <div className="cart__display-large">
                  <hr />
                  <div className="card card--clear card--small card--right">
                    <input
                      disabled={cartTotal === 0}
                      alt="Next"
                      type="button"
                      className="button button--inline"
                      value={"Next"}
                      onClick={(e) => handleNext(e)}
                    />
                  </div>
                </div>
              )}
              {haveValidPromo && isWinbackPromo && (
                <WinbackPromoModal
                  displayPromoModal={displayPromoModal}
                  setDisplayPromoModal={setDisplayPromoModal}
                  modalCloseButton="Close"
                />
              )}
            </div>
            {!isPinFlow && (
              <div className="cart__display-large main__desktop-sidebar-right">
                <Cart content={cartComponentContent} />
                {process.env.REACT_APP_FCC_DISPLAYED === "true" &&
                  (locale === "es" ? (
                    <SpanishBroadbandFacts
                      tierId={activeCart?.internet}
                    ></SpanishBroadbandFacts>
                  ) : (
                    <BroadbandFacts
                      tierId={activeCart?.internet}
                      servicePlanType="PREPAID"
                      lineOfBusiness="INTERNET"
                      sourceServerId={window.location.hostname}
                      sourceSystemId="PREPAID"
                      key={activeCart?.internet}
                    ></BroadbandFacts>
                  ))}
              </div>
            )}
            {!isPinFlow && (
              <div className="cart__display-small-medium">
                <StickyCart
                  nextDisabled={cartTotal === 0}
                  handleNext={handleNext}
                  cartComponentContent={cartComponentContent}
                />
                {process.env.REACT_APP_FCC_DISPLAYED === "true" &&
                  (locale === "es" ? (
                    <SpanishBroadbandFacts
                      tierId={activeCart?.internet}
                    ></SpanishBroadbandFacts>
                  ) : (
                    <BroadbandFacts
                      tierId={activeCart?.internet}
                      servicePlanType="PREPAID"
                      lineOfBusiness="INTERNET"
                      sourceServerId={window.location.hostname}
                      sourceSystemId="PREPAID"
                      key={activeCart?.internet}
                    ></BroadbandFacts>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
      <TrackPageComplete />
    </DashboardLayout>
  );
};

export default withDashboard(Refill);
