import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import RouteCheck from '../../components/buyflow/RouteCheck'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

export const BuyFlowRouteCheck = props => {
    const {locale, location, cartContent, dashboard} = props

    return (
        <BuyFlowLayout locale={locale} loadScreen="true">
            <TrackUserData data={dashboard} />
            <RouteCheck content={cartContent} query={location?.search} />
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}
