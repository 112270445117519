import { useState, useEffect } from 'react'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataLinkClick } from '../../utils/adobe-analytics'

const ExistingAccount = ({isMove, setStage, content, accountType, addressData}) => {

    const [screen, setScreen] = useState('start')
    const heading = accountType === 'prepaid' ? content?.servicibilityCheck.existingPrepaidAccount.title : content?.servicibilityCheck.existingPostpaidAccount.title
    useEffect(() => {
        if (isMove) {
            trackEvent({
                event: 'move info',
                moveStep: 'step 1',
                moveStepName: 'start move',
                moveEvent: 'check availability',
                moveDetail: 'service existing'
            })
        }
        // eslint-disable-next-line
    }, [])
    let lowerScreen
    if (screen === 'start') lowerScreen = (
        <div className="merchant-prepaid__white-bg">
            <div className="merchant-prepaid__your-account">
                <p className="merchant-prepaid__subtitle">{content.servicibilityCheck.existingPostpaidAccount.isThisYourAccount}</p>
                <a href={content.servicibilityCheck.existingPostpaidAccount.goToMyAccount.link.url} target={content.servicibilityCheck.existingPostpaidAccount.goToMyAccount.link.target || '_top'} className="button" data-tracking={digitalDataLinkClick('merchant-prepaid', content.servicibilityCheck.existingPostpaidAccount.goToMyAccount.link.url, content.servicibilityCheck.existingPostpaidAccount.goToMyAccount.text)}>{content.servicibilityCheck.existingPostpaidAccount.goToMyAccount.text}</a>
                <div className="merchant-prepaid__block">{content.servicibilityCheck.existingPostpaidAccount.no}<button className="merchant-prepaid__bodylink" onClick={() => setScreen('moving')} data-tracking={digitalDataButtonClick('moving', content.servicibilityCheck.existingPostpaidAccount.movingText)}>{content.servicibilityCheck.existingPostpaidAccount.movingText}</button></div>
            </div>
        </div>
    )
    else lowerScreen = (
        <div className='merchant-prepaid__white-bg'>
            <div className="merchant-prepaid__your-account">
                <p>{content?.servicibilityCheck.existingPostpaidAccount.newCustomerInstruction}</p>
                <p>{content?.servicibilityCheck.existingPostpaidAccount.newCustomerPhone}</p>
            </div>
            
        </div>
    )

    return (
        <div className="merchant-prepaid__existing-account">
            <section className=" merchant-prepaid__banner">
                <h1 className="merchant-prepaid__title">{heading}</h1>
                <div className='merchant-prepaid__banner-address'>
                    <div className="merchant-prepaid__eligible-address">
                        <p>
                            {addressData.address} {addressData.unit}, {addressData.city}, {addressData.state} {addressData.zip} 
                            <br /><button data-testid="stage" className="merchant-prepaid__bodylink" onClick={() => {setStage('addressCheck'); sessionStorage.removeItem('xppdata')}} data-tracking={digitalDataButtonClick('change-address', content.servicibilityCheck.existingPostpaidAccount.changeAddress)}>{content.servicibilityCheck.existingPostpaidAccount.changeAddress}</button>
                        </p>
                    
                    </div>
                </div>
            </section>
            
            
            <div>
                {lowerScreen}
            </div>
        </div>
    )
}

export default ExistingAccount
