import React, {useEffect, useState, useRef} from 'react'
import { Link, useLocation } from 'react-router-dom'
import StickyCta from '../dashboard/StickyCta'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataLinkClick } from '../../utils/adobe-analytics'

function Header(props) {
    const [activeIndex, setActiveIndex] = useState(null)
    const [activeMobileIndex, setActiveMobileIndex] = useState(0)
    const [showMobileMenu, setMobileMenu] = useState(false)
    const [menu, setMenu] = useState(Object)
    const [pageType, setPageType] = useState('')
    const [showStickyCTA, setShowStickyCTA] = useState(true)
    const [ready, setReady] = useState(false)
    const clientSide = (typeof window !== "undefined")

    const {
        currentLocale,
        chosenName,
        dashboardContent,
        dashboard,
        deactivatedAccount,
        reconnectContent,
        haveScheduledMove
    } = props

    // const isEnglish = currentLocale === 'en'
    // let otherLocalePath = '/en/dashboard'

    // if (clientSide) {
    //    if (isEnglish) otherLocalePath = window.location.pathname.replace('/en/', '/es/')
    //    else otherLocalePath = window.location.pathname.replace('/es/', '/en/')
    // }

    const node = useRef<HTMLUListElement | null>(null)

    const detectPageLocation = () => {
        if (clientSide && window.location.pathname.endsWith('/dashboard/')) {
            setPageType('My Prepaid')
        } else if (clientSide && window.location.pathname.endsWith('/dashboard')) {
            setPageType('My Prepaid')
        } else if (clientSide && window.location.pathname.indexOf('detail-hsd') >= 0) {
            setPageType('My Prepaid')
        } else if (clientSide && window.location.pathname.indexOf('detail-xitv') >= 0) {
            setPageType('My Prepaid')
        } else if (clientSide && window.location.pathname.indexOf('detail-tv200') >= 0) {
            setPageType('My Prepaid')
        } else if (clientSide && window.location.pathname.indexOf('account-information') >= 0) {
            setPageType('Account Information')
            setActiveMobileIndex(1)
        } else if (clientSide && window.location.pathname.indexOf('edit-payment') >= 0) {
            setPageType('Account Information')
            setActiveMobileIndex(1)
        } else if (clientSide && window.location.pathname.indexOf('auto-refill') >= 0) {
            setPageType('Account Information')
            setActiveMobileIndex(1)
        } else if (clientSide && window.location.pathname.indexOf('payment-history') >= 0) {
            setPageType('Account Information')
            setActiveMobileIndex(1)
        } else if (clientSide && window.location.pathname.endsWith('/refill')) {
            setShowStickyCTA(false)
        } else if (clientSide && window.location.pathname.indexOf('move') >= 0) {
            setShowStickyCTA(false)
        } else if (clientSide && window.location.pathname.indexOf('reconnect') >= 0) {
            setShowStickyCTA(false)
        }
    }

    // XXX TODO Should most of this be moved out of the Header?
    let location = useLocation()
    useEffect(() => {
        // console.log(`Location changed to ${window.location.pathname}`)
        // XXX Assume if location changed, that a modal should no longer be open.
        // May or may not cause issues... let's see.
        document.body.classList.remove('modal-open')

        // Also assume if location changed, that any dropdown menu should be collapsed
        // This fixes a bug where the dropdown won't close if you select the current page
        setActiveIndex(null)

        // And... let's make sure we're scrolled back to the top after a route change
        window.scrollTo(0,0)

        setMobileMenu(false)
        detectPageLocation()
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (dashboardContent.loaded && dashboardContent?.dashboardArea?.accountDashboard?.menu?.item?.children) {
            const parsedMenu = {
                refill: {},
                activate: {},
                account: {},
                chat: {},
                nav: [{}]
            }
            const menuList = [{}]
            dashboardContent?.dashboardArea?.accountDashboard?.menu?.item?.children.forEach((obj: {linkType: String}) => {
                if (obj.linkType === 'refill') {
                    parsedMenu.refill = obj
                } else if (obj.linkType === 'activate') {
                    parsedMenu.activate = obj
                } else if (obj.linkType === 'account') {
                    parsedMenu.account = obj
                } else if (obj.linkType === 'chat') {
                    parsedMenu.chat = obj
                } else {
                    menuList.push(obj)
                }
            })
            menuList.shift()
            parsedMenu.nav = menuList

            setMenu(parsedMenu)
            setReady(true)
        }
        // console.log(content)

    // eslint-disable-next-line
    }, [dashboardContent])

    const onMenuClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null)
        } else {
            setActiveIndex(index)
        }
    }
    const toggleMobileMenu = () => {
        if (showMobileMenu) {
            setMobileMenu(false)
            // document.body.className = document.body.className.replace(new RegExp(/modal-open/g), '')
            document.body.classList.remove('modal-open')
        } else {
            setMobileMenu(true)
            // document.body.className += ' modal-open'
            document.body.classList.add('modal-open')
        }
    }
    const onMobileMenuClick = (index) => {
        if (activeIndex === index) {
            setActiveMobileIndex(-1)
        } else {
            setActiveMobileIndex(index)
        }
    }
    const captureEnterKey = (event, index) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            onMenuClick(index)
        }
    }
    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        setActiveIndex(null)
    }

    const trackClick = (item) => {
        if (item.linkType === 'move') {
            trackEvent({
                event: 'dashboard',
                navItem: 'dashboard nav',
                navLabel: item.linkText

                })
        }
    }
    const trackReconnect = () => {
        trackEvent({
            event: 'dashboard',
            dashboardPage: 'reconnect',
            dashboardEvent: 'reactivate',
            dashboardDetail: ''
        })
    }
    const renderDropdown = (item, index) => {
        const renderedDropdownLinks = item.list.map((subItem, index) => {
            // updating to never render XITV menu item
            if (subItem?.link?.url && subItem.link.url.includes('detail-xitv')) {
                return null
            }

            if (subItem?.linkType && subItem.linkType === 'move' && haveScheduledMove) {
                return null
            }
            return (
                    <li key={index} className="main-flyout__item ">
                        <Link to={`/${currentLocale}${subItem.link.url}`} className="main-flyout__link" data-testid="track-click-event" onClick={(e) => trackClick(subItem)} data-tracking={digitalDataLinkClick('main-flyout', `/${currentLocale}${subItem.link.url}`, subItem.linkText)}>
                            {subItem.linkText}
                        </Link>
                    </li>
            )
        })
        return (
            <React.Fragment>
                <div className="accordion__icon js-accordion-icon">
                    <span className="line" />
                    <span className="line" />
                </div>
                {/* eslint-disable-next-line */}
                <ul
                    aria-expanded={activeIndex === index ? 'true' : 'false'}
                    aria-hidden={activeIndex === index ? 'false' : 'true'}
                    className="main-flyout"
                    aria-label="main-flyout"
                    role="group"
                >
                    {renderedDropdownLinks}
                </ul>
            </React.Fragment>
        )
    }

    const renderedBottomLinks = menu?.nav?.map((item, index) => {
        if (item.list) {
            return (
            <React.Fragment key={index}>
                <li className={`main-nav__item ${activeIndex === index ? "is_open" : ""} ${pageType === item.linkText ? 'active' : ''}`}>
                    <span tabIndex={0} className="main-nav__link" data-testid="move-click-event" onClick={() => onMenuClick(index)} onKeyDown={(e) => captureEnterKey(e, index)} data-tracking={digitalDataButtonClick('main-nav-link', item.linkText)}>{item.linkText}</span>
                    {renderDropdown(item, index)}
                </li>
            </React.Fragment>
            )
        } else {
            return (
            <React.Fragment key={index}>
                <li className="main-nav__item">
                    <a href={item.link.url} className="main-nav__link" target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('main-nav', item.link.url, item.linkText)}>{item.linkText}</a>
                    {/* XXX these links are external. <a> is correct
                    <Link to={`/${currentLocale}${item.link.url}`} className="main-nav__link" target="_blank" rel="noreferrer">{item.linkText}</Link>*/}
                </li>
            </React.Fragment>
            )
        }
    })

    const renderMobileSubnav = (item, index) => {
        const renderedDropdownLinks = item.list.map((subItem, index) => {
            // updating to never render XITV menu item
            if (subItem?.link?.url && subItem.link.url.includes('detail-xitv')) {
                return null
            }

            if (subItem?.linkType && subItem.linkType === 'move' && haveScheduledMove) {
                return null
            }

            return (
                    <li key={index} className="off-canvas-menu__item ">
                        <Link to={`/${currentLocale}${subItem.link.url}`} data-testid="track-click-mobile-event" onClick={(e) => trackClick(subItem)} className="off-canvas-menu__link" data-tracking={digitalDataLinkClick('off-canvas-menu-item', `/${currentLocale}${subItem.link.url}`, subItem.linkText)}>
                            {subItem.linkText}
                        </Link>
                    </li>
            )
        })
        return (
            <React.Fragment>
                {/* eslint-disable-next-line */}
                <ul
                    aria-expanded={activeMobileIndex === index ? 'true' : 'false'}
                    aria-hidden={activeMobileIndex === index ? 'false' : 'true'}
                    className="off-canvas-menu__submenu"
                    aria-label="main-flyout"
                    role="group"
                >
                    {renderedDropdownLinks}
                </ul>
            </React.Fragment>
        )
    }

    const renderedMobileMenu = menu?.nav?.map((item, index) => {
            if (item.list) {
            return (
            <React.Fragment key={index}>
                <li className={`off-canvas-menu__link ${activeMobileIndex === index ? "active" : ""}`} data-testid="mobile-menu-click-event" onClick={() => onMobileMenuClick(index)} data-tracking={digitalDataButtonClick('mobile-subnav', item.linkText)}>
                    <span tabIndex={0}>{item.linkText}</span>
                    {renderMobileSubnav(item, index)}
                </li>
            </React.Fragment>
            )
        } else {
            return (
            <React.Fragment key={index}>
                <li className="off-canvas-menu__link">
                    <a href={item.link.url} target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('off-canvas-menu', item.link.url, item.linkText)}>{item.linkText}</a>
                    {/* XXX these links are external. <a> is correct
                    <Link to={`/${currentLocale}${item.link.url}`} target="_blank" rel="noreferrer">{item.linkText}</Link>*/}
                </li>
            </React.Fragment>
            )
        }
    })

    let homeLink = `/${currentLocale}/dashboard`
    if (window.location.pathname.includes('account-picker')) {
        homeLink = '/?s=1'
    }
    const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
    return (
        !ready ? <div />
        :<div className="header">
            <header role="banner">
                <div className="alert-bar" />
                <div>
                    <div className="header__top">
                        <div className="header__width-constraint">
                            <Link to={homeLink} title="Xfinity Prepaid Home" className="xfinity-logo" data-tracking={digitalDataLinkClick('xfinity-logo', homeLink, 'Xfinity Prepaid Home')}>
                                <span className="vh">Xfinity Prepaid Home</span>
                            </Link>
                            <div className="logo__vertical-line"></div>
                            <div className="prepaid-logo"></div>
                            {haveScheduledMove && <Link
                                to={`/${currentLocale}/dashboard/notifications`}
                                data-testid="header-account-link"
                                className="header__account-link header__account-link-desktop"
                                onClick={() => {
                                    trackEvent({
                                        event: 'dashboard',
                                        dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                                        dashboardEvent: 'notification',
                                        dashboardDetail: 'impression'
                                        })
                                }}
                                data-tracking={digitalDataLinkClick('header-account', `/${currentLocale}/dashboard/notifications`, 'Hi' + chosenName?.chosenFirstName ? chosenName?.chosenFirstName : dashboard?.customerInfo?.firstName)}
                            >

                                <span className="numberCircle">
                                    1
                                </span>

                                Hi {(chosenName?.chosenFirstName) ? chosenName?.chosenFirstName : dashboard?.customerInfo?.firstName}
                            </Link>}
                            {!haveScheduledMove && dashboard &&
                            <span className="header__account-link header__account-link-desktop">
                                Hi {(chosenName?.chosenFirstName) ? chosenName?.chosenFirstName : dashboard?.customerInfo?.firstName}
                            </span>}

                            <a className="vh" href="#main" data-tracking={digitalDataLinkClick('skip-to-content', '#main', 'Content')}>Skip to Content</a>
                            <div className="header__right">
                                {/*
                                <ul className="header-language__list header-language__list-desktop">
                                    <li className="header-language__item">
                                        <Link className="header-language__link" to={otherLocalePath}>{currentLocale === 'en' ? 'Español' : 'English'}</Link>
                                    </li>
                                </ul>
                                */}
                                <a href={logoutURL} className="header__account-link header__account-link-logout header__account-link-desktop" data-tracking={digitalDataLinkClick('header-account', 'https://oauth.xfinity.com/oauth/sp-logout?client_id=xfinity_prepaid_digital', 'Logout')}>
                                    Logout
                                </a>
                                {haveScheduledMove &&
                                <Link 
                                    to={`/${currentLocale}/dashboard/notifications`}
                                    data-testid="track-event-link"
                                    className="header__account-link header__account-link-mobile" onClick={() => {
                                    trackEvent({
                                        event: 'dashboard',
                                        dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                                        dashboardEvent: 'notification',
                                        dashboardDetail: 'impression'
                                        })
                                    }}
                                    data-tracking={digitalDataLinkClick('notification', `/${currentLocale}/dashboard/notifications`, 'Hi ' + chosenName?.chosenFirstName ? chosenName?.chosenFirstName : dashboard?.customerInfo?.firstName)}
                                >

                                    <span className="numberCircle">
                                    1
                                    </span>
                                    Hi {(chosenName?.chosenFirstName) ? chosenName?.chosenFirstName : dashboard?.customerInfo?.firstName}
                                </Link>}
                                {!haveScheduledMove && dashboard &&
                                <span className="header__account-link header__account-link-mobile">
                                    Hi {(chosenName?.chosenFirstName) ? chosenName?.chosenFirstName : dashboard?.customerInfo?.firstName}
                                </span>}
                                <button
                                    aria-expanded={showMobileMenu}
                                    className="offcanvas-trigger dashboard"
                                    type="button"
                                    data-toggle="offCanvasTop"
                                    aria-controls="offCanvasTop"
                                    onClick={() => {
                                        toggleMobileMenu();
                                    }}
                                    data-tracking={digitalDataButtonClick('mobile-menu', 'Menu')}
                                >
                                    <span className="offcanvas-trigger-text vh">Menu</span>
                                    <div className="hamburger">
                                    <span className="line" />
                                    <span className="line" />
                                    <span className="line" />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="header__bottom">
                        <div className="wrapper">
                            <nav className="main-nav" role="navigation">
                                <ul ref={node} className="main-nav__list">
                                    {renderedBottomLinks}
                                </ul>
                                { deactivatedAccount ?
                                <Link to={`/${currentLocale}/reconnect`} onClick={trackReconnect} className="button button--inline" data-tracking={digitalDataLinkClick('reactivate-your-account', `/${currentLocale}/reconnect`, reconnectContent?.step1?.reactivateYourAccount)}>
                                    { reconnectContent?.step1?.reactivateYourAccount }
                                </Link> :

                                <Link
                                    to={`/${currentLocale}/refill`}
                                    data-testid="link-refill-link"
                                    className="button button--inline"
                                    onClick={() => {
                                        trackEvent({
                                            event: 'dashboard',
                                            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                                            dashboardEvent: 'refill now',
                                            dashboardDetail: 'internet'
                                        })
                                    }}
                                    data-tracking={digitalDataLinkClick('refill-now', `/${currentLocale}/refill`, menu?.refill?.linkText)}
                                >
                                    {menu?.refill?.linkText}
                                </Link>
                                }
                            </nav>
                        </div>
                    </div>
                    <div
                        id="offCanvasTop"
                        className={`offcanvas off-canvas position-top is-transition-push ${showMobileMenu ? "is-open" : ""}`}
                        data-off-canvas="m2xy2f-off-canvas"
                        data-content-scroll="false"
                        aria-hidden="false"
                    >
                        <ul className="vertical menu" data-accordion-menu="">
                            {renderedMobileMenu}
                            <li className="off-canvas-menu__item refill-button logged-out-visible">
                            { deactivatedAccount ?
                                <Link 
                                    to={`/${currentLocale}/reconnect`}
                                    onClick={trackReconnect}
                                    className="off-canvas-menu__link"
                                    data-tracking={digitalDataLinkClick('reactivate-your-account', `/${currentLocale}/reconnect`, reconnectContent?.step1?.reactivateYourAccount)}
                                >
                                    { reconnectContent?.step1?.reactivateYourAccount }
                                </Link> :

                                <Link 
                                    to={`/${currentLocale}/refill`}
                                    data-testid="link-canvas-menu"
                                    className="off-canvas-menu__link "
                                    onClick={() =>{
                                        trackEvent({
                                            event: 'dashboard',
                                            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                                            dashboardEvent: 'refill now',
                                            dashboardDetail: 'internet'
                                        })
                                    }}
                                    data-tracking={digitalDataLinkClick('refill-now', `/${currentLocale}/refill`, menu?.refill?.linkText)}
                                >
                                { menu?.refill?.linkText }
                                </Link>
                                }
                            </li>
                            <li className="off-canvas-menu__item login-button logged-out-visible">
                            <a href={menu?.account?.link?.url} className="off-canvas-menu__link" data-tracking={digitalDataLinkClick('account', menu?.account?.link?.url, menu?.account?.linkText )}>
                                { menu?.account?.linkText }
                            </a>
                            </li>
                        </ul>
                        {/*
                        <ul className="language-list" data-current-language="en">
                            Language
                            <li className="language-list__item spanish">
                                <Link className="language-list__link js-data-track" to={otherLocalePath}>{currentLocale === 'en' ? 'Español' : 'English'}</Link>
                            </li>
                        </ul>
                        */}
                    </div>
                </div>
            </header>
            {showStickyCTA ? <StickyCta content={menu?.refill} /> : null}
        </div>
    )
}

export default withDashboard(Header)