import { put, select, call, takeEvery } from "redux-saga/effects";
import {
  fetchToken,
  validateServiceability,
  fetchProductDetails,
  createSession,
  restoreDigitalSession,
  getPromos,
  fetchTaxData,
  getPaymentId,
  submitOrder,
  addPaymentInstrument
} from "./apis";
import { buyflow } from "./store";
import selectors from "../buyflow/selectors";
import { digitalDataAPIError } from "../../utils/adobe-analytics";

const getTrackingId = (state) => state.buyflow.trackingId;
const getLocationId = (state) => state.buyflow.locationId;
const getMarketId = (state) => state.buyflow.marketId;
const getCartSubtotal = (state) => state.buyflow.cartSubtotal;
const getDigitalSessionId = (state) => state.buyflow.digitalSessionId;
const getProductsByName = (state) => selectors.getProductsByName(state.buyflow);
const getShippingCode = (state) => state.buyflow.shippingCode;
const getShippingRate = (state) => state.buyflow.shippingRate;
const getGeoCode = (state) => state.buyflow.geoCode;
const getLocale = (state) => state.buyflow.locale;
const getSessionId = (state) => state.common.sessionId

function* fetchTokenSaga({ payload }) {
  console.log("fetchTokenSaga");
  try {
    const trackingId = yield select(getTrackingId);
    const response = yield call(fetchToken, { trackingId });
    yield put(buyflow.actions.fetchTokenSuccess(response));
  } catch (error: any) {
    console.log("token catch error", error);
    digitalDataAPIError('fetchTokenSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.fetchTokenFailure({}));
  }
}

function* fetchTokenSagaConfig() {
  yield takeEvery(buyflow.actions.fetchToken, fetchTokenSaga);
}

function* validateServiceabilitySaga({ payload }) {
  console.log("validateServiceabilitySaga");
  try {
    const trackingId = yield select(getTrackingId);
    const locationId = yield select(getLocationId);
    const response = yield call(validateServiceability, {
      locationId,
      trackingId,
    });
    yield put(buyflow.actions.validateServiceabilitySuccess(response));
  } catch (error: any) {
    console.log("validateServiceabilitySaga catch error", error);
    digitalDataAPIError('validateServiceabilitySaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.validateServiceabilityFailure({}));
  }
}

function* validateServiceabilitySagaConfig() {
  yield takeEvery(
    buyflow.actions.validateServiceability,
    validateServiceabilitySaga
  );
}

function* fetchProductDetailsSaga({ payload }) {
  console.log("fetchProductDetailsSaga");
  try {
    const trackingId = yield select(getTrackingId);
    const marketId = yield select(getMarketId);
    const response = yield call(fetchProductDetails, { marketId, trackingId });
    yield put(buyflow.actions.fetchProductDetailsSuccess(response));
  } catch (error: any) {
    console.log("fetchProductDetailsSaga catch error", error);
    digitalDataAPIError('fetchProductDetailsSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.fetchProductDetailsFailure({}));
  }
}

function* fetchProductDetailsSagaConfig() {
  yield takeEvery(buyflow.actions.fetchProductDetails, fetchProductDetailsSaga);
}

function* createSessionSaga({ payload }) {
  console.log("createSessionSaga");
  try {
    const trackingId = yield select(getTrackingId);
    const locale = yield select(getLocale);
    let redirectUrl = `https://${window.location.hostname}/${locale}/buy/session`;
    if (window.location.port) {
      redirectUrl = `http://${window.location.hostname}:${window.location.port}/${locale}/buy/session`;
    }
    console.log("redirect url: ", redirectUrl);
    let subtotal = yield select(getCartSubtotal);
    let data: any;
    data = {
      affiliate: "PREPAID",
      channel: "web",
      handOffPayload: {
        redirectUrl,
        location: {
          locationId: yield select(getLocationId),
          marketId: yield select(getMarketId),
        },
        consumerSessionId: yield select(getTrackingId),
        preOrderView: {
          offers: [
            // {
            //   name: 'Standard Double Play',
            //   price: '89.99',
            //   action: 'ADD',
            //   descriptions: [
            //     {
            //       description: 'Performance Pro Internet | Download speeds up to 150 Mbps; Upload speeds up to 5 Mbps'
            //     },
            //     {
            //       description: 'Limited Basic, Kids & Family programming, Entertainment programming, Sports & News programming | 125+ Channels'
            //     }
            //   ]
            // }
          ],
          addons_devices: [
            // {
            //   name: 'HBO',
            //   price: '15.00',
            //   action: 'ADD'
            // },
            // {
            //   name: 'Showtime',
            //   price: '12.00',
            //   action: 'ADD'
            // },
          ],
          invoice: [
            {
              name: "Cart Sub-Total",
              price: subtotal.toFixed(2),
            },
          ],
        },
      },
    };
    const response = yield call(createSession, { data, trackingId });
    yield put(buyflow.actions.createSessionSuccess(response));
  } catch (error: any) {
    console.log("createSessionSaga catch error", error);
    digitalDataAPIError('createSessionSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.createSessionFailure({}));
  }
}
function* createSessionSagaConfig() {
  yield takeEvery(buyflow.actions.createSession, createSessionSaga);
}
function* restoreDigitalSessionSaga({ payload }) {
  console.log("restoreDigitalSessionSaga");
  try {
    const digitalSessionId = yield select(getDigitalSessionId);
    const response = yield call(restoreDigitalSession, { digitalSessionId });
    yield put(buyflow.actions.restoreDigitalSessionSuccess(response));
  } catch (error: any) {
    console.log("restoreDigitalSessionSaga catch error", error);
    digitalDataAPIError('restoreDigitalSessionSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.restoreDigitalSessionFailure({}));
  }
}

function* restoreDigitalSessionSagaConfig() {
  yield takeEvery(
    buyflow.actions.restoreDigitalSession,
    restoreDigitalSessionSaga
  );
}

function* getPromosSaga({ payload }) {
  console.log("getPromosSaga");
  try {
    const trackingId = yield select(getTrackingId);
    const marketId = yield select(getMarketId);
    const locationId = yield select(getLocationId);
    let data: any;
    data = {
      marketId: marketId,
      locationId: locationId,
      source: "prepaid-ui-portal",
      flow: "BUY",
      trackingId: trackingId,
    };
    const response = yield call(getPromos, { data, trackingId });
    yield put(buyflow.actions.getPromosSuccess(response));
  } catch (error: any) {
    console.log("getPromosSaga catch error", error);
    digitalDataAPIError('getPromosSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.getPromosFailure({}));
  }
}
function* getPromosSagaConfig() {
  yield takeEvery(buyflow.actions.getPromos, getPromosSaga);
}

function* fetchTaxDataSaga({ payload }) {
  console.log("getTaxDataSaga");
  try {
    const productsByName = yield select(getProductsByName);
    const shippingCode = yield select(getShippingCode);
    const shippingRate = yield select(getShippingRate);
    const trackingId = yield select(getTrackingId);
    const geoCode = yield select(getGeoCode);
    let subtotal = yield select(getCartSubtotal);
    let haveXB3 = false;
    if (productsByName) {
      haveXB3 = Object.keys(productsByName).includes("ECO-HSD-XB3");
    }
    const products = [
      {
        productCode: haveXB3 ? "ECO-HSD-XB3" : "ECO-HSD-WIFI",
        // XXX Make dynamic? Is this in Sitecore?
        // Add $35 equipment fee + $45 for 30 days of service
        price: subtotal,
      },
      {
        productCode: shippingCode,
        price: shippingRate,
      },
    ];
    let data: any;
    data = {
      source: "CTP",
      geoCode: geoCode,
      trackingId: trackingId, // production always uses the value '0000111234'. should we use state.trackingId?
      status: "",
      busErrorCode: "",
      message: "",
      sysErrorCode: "",
      products: products,
    };
    const response = yield call(fetchTaxData, { data, trackingId });
    yield put(buyflow.actions.fetchTaxDataSuccess(response));
  } catch (error: any) {
    console.log("getTaxDataSaga catch error", error);
    digitalDataAPIError('getTaxDataSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.fetchTaxDataFailure({}));
  }
}
function* getTaxDataSagaConfig() {
  yield takeEvery(buyflow.actions.fetchTaxData, fetchTaxDataSaga);
}

function* submitOrderSaga({ payload }) {
  console.log("submitOrderSaga");
  try {
    const trackingId = yield select(getTrackingId);
    const response = yield call(submitOrder, { payload, trackingId });
    yield put(buyflow.actions.submitOrderSuccess(response));
  } catch (error: any) {
    console.log("submitOrderSaga catch error", error);
    digitalDataAPIError('submitOrderSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.submitOrderFailure({}));
  }
}

function* submitOrderSagaConfig() {
  yield takeEvery(buyflow.actions.submitOrder, submitOrderSaga);
}

function* getPaymentIdSaga({ payload }) {
  console.log("getPaymentIdSaga");
  try {
    const trackingId = yield select(getTrackingId);
    const response = yield call(getPaymentId, { trackingId });
    yield put(buyflow.actions.getPaymentIdSuccess(response));
  } catch (error: any) {
    console.log("getPaymentIdSaga catch error", error);
    digitalDataAPIError('getPaymentIdSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
    yield put(buyflow.actions.getPaymentIdFailure({}));
  }
}
function* getPaymentIdSagaConfig() {
  yield takeEvery(buyflow.actions.getPaymentId, getPaymentIdSaga);
}

function* addPaymentInstrumentSaga({payload}) {
  console.log('addPaymentInstrumentSaga')
  try {
      const trackingId = yield select(getTrackingId)
      const sessionId = yield select(getSessionId)
      const response = yield call(addPaymentInstrument, {payload, trackingId, sessionId})

      if (response.businessErrorCode) {
          yield put(buyflow.actions.addPaymentInstrumentFailure(response))
      } else {
          yield put(buyflow.actions.addPaymentInstrumentSuccess(response))
      }
  } catch (error: any) {
      console.error('addPaymentInstrumentSaga error', error)
      digitalDataAPIError('addPaymentInstrumentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
      yield put(buyflow.actions.addPaymentInstrumentFailure(error.response.data))
  }
}

function* addPaymentInstrumentSagaConfig() {
  yield takeEvery(buyflow.actions.addPaymentInstrument, addPaymentInstrumentSaga)
}


export {
  fetchTokenSagaConfig,
  validateServiceabilitySagaConfig,
  fetchProductDetailsSagaConfig,
  createSessionSagaConfig,
  restoreDigitalSessionSagaConfig,
  getPromosSagaConfig,
  getTaxDataSagaConfig,
  submitOrderSagaConfig,
  getPaymentIdSagaConfig,
  addPaymentInstrumentSagaConfig
};
