import { useState } from 'react'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const MultiAddressList = ({setStage, addressList, content, setSearchAddress, setAddressData, setSelectedLocation}) => {

    const [selectedAddressId, setSelectedAddressId] = useState(0)

    const items = addressList

    const [filter, setFilter] = useState('')

    const [radioGroupChecked, setRadioGroupChecked] = useState(false)

    const [currentPaginationPage, setCurrentPaginationPage] = useState(1)

    let pages = 1
    const perPage = 10

    const filteredList = items.filter(item => filter === '' || item.address.addressLine2.toLowerCase().includes(filter.toLowerCase()))

    const paginationList = () => {
        let list = filteredList,
            currentPage = currentPaginationPage
        if (list.length <= perPage) {
            pages = 1;
        } else {
            pages = Math.ceil(list.length / perPage)
        }

        var start = 0;

        if (list.length < perPage) {
            currentPage = 1;
        } else if (currentPage > 1) {
            start = (currentPage - 1) * perPage;
        }

        list = list.slice(start, start + perPage);
        return list
    } 
    const resetForm = () => {
        setRadioGroupChecked(false)
        if (currentPaginationPage !== 1) {
            setCurrentPaginationPage(1)
        }
        resetRadioGroup()
    }
    const resetRadioGroup = () => {
        if (selectedAddressId !== 0) {
            setSelectedAddressId(0)
            setRadioGroupChecked(false)
        }
    }
    const centerPages = () => {
        if (pages <= 7) {
            let pgs : any[] = []
            for (var i = 2; i < pages; i++) {
                pgs.push(i)
            }
            return pgs
        } else {
            if (currentPaginationPage < 5) {
                return [2, 3, 4, 5]
            }

            if (currentPaginationPage > (pages - 3)) {
                return [pages - 3, pages - 2, pages - 1];
            }

            return [currentPaginationPage -1, currentPaginationPage, currentPaginationPage + 1];
        }
    }

    const renderedFilterList = paginationList().map((item, index) => {
        const id = index + 1
        const idName = 'address' + id
        const AddressSelect = (e) => {
            setRadioGroupChecked(true)
            setSelectedAddressId(e.target.value)
        }
        return (
            <div className="merchant-prepaid__multi-address-item" key={idName}>
                
                <div className="merchant-prepaid__radio radio-list__item">
                    <input type="radio" name="address" id={idName} value={id} className="radio-list__input" onChange={AddressSelect} />
                    <label htmlFor={idName} className={`radio-list__label ${radioGroupChecked ? "" : "remove-check"}`}>
                    {item.address.addressLine1}, <strong>{ item.address.addressLine2 ? item.address.addressLine2 + ', ' : ''}</strong>
					{item.address.city}, {item.address.state} {item.address.zip}
                    </label>
                </div>
            </div>
        )
    })
    const renderPagination = () => {
        if (filteredList.length > perPage) {
            return (
                <div className="check-availability-pag">
                    <ul className="check-availability-pag__list">
                        <li className="check-availability-pag__item check-availability-pag__item--prev">
                            <button className="check-availability-pag__btn check-availability-pag__btn--prev" onClick={previousPage} data-tracking={digitalDataButtonClick('previous-page', content.servicibilityCheck.multiUnit.backLinkLabel)}>{content.servicibilityCheck.multiUnit.backLinkLabel}</button>
                        </li>
                        <li className="check-availability-pag__item">
                            <button
                                className="check-availability-pag__link"
                                aria-label={1 === currentPaginationPage ? content?.servicibilityCheck.multiUnit.numberAccessibilityLabel.text.replace('{page-number}', 1) : content?.servicibilityCheck.multiUnit.pageAccessibilityLabel.text.replace('{page-number}', 1)}
                                aria-current={1 === currentPaginationPage ? 'true' : 'false'}
                                onClick={() => {updatePage(1)}}
                                data-tracking={digitalDataButtonClick('current-page', '1')}
                            > 1</button>
					    </li>
                        {renderPreviousElipsis()}
                        {rendereCenterPages}
                        {renderNextElipsis()}
                        <li className="check-availability-pag__item">
                            <button
                                className="check-availability-pag__link"
                                aria-label={pages === currentPaginationPage ? content?.servicibilityCheck.multiUnit.numberAccessibilityLabel.text.replace('{page-number}', pages) : content?.servicibilityCheck.multiUnit.pageAccessibilityLabel.text.replace('{page-number}', pages)}
                                aria-current={pages === currentPaginationPage ? 'true' : 'false'}
                                onClick={() => {updatePage(pages)}}
                                data-tracking={digitalDataButtonClick('pages', pages.toString())}
                            > {pages}</button>
					    </li>
                        <li className="check-availability-pag__item check-availability-pag__item--next">
            <button className="check-availability-pag__btn check-availability-pag__btn--next" onClick={nextPage} data-tracking={digitalDataButtonClick('check-next-availability', content.servicibilityCheck.multiUnit.nextLinkLabel)}>{content?.servicibilityCheck.multiUnit.nextLinkLabel}</button>
                        </li>
                    </ul>
                </div>
            )
        } else {
            return null
        }
    }
    const rendereCenterPages = centerPages().map((item, index) => {
        return (
            <li className="check-availability-pag__item" key={item}>
                <button
                    className="check-availability-pag__link"
                    aria-label={item === currentPaginationPage ? content?.servicibilityCheck.multiUnit.numberAccessibilityLabel.text.replace('{page-number}', item) : content?.servicibilityCheck.multiUnit.pageAccessibilityLabel.text.replace('{page-number}', item)}
                    aria-current={item === currentPaginationPage ? 'true' : 'false'}
                    onClick={() => updatePage(item)}
                    data-tracking={digitalDataButtonClick('check-availability', item)}
                > 
                    {item}
                </button>
            </li>
        )
    })
    const renderPreviousElipsis = () => {
        if (pages > 7 && currentPaginationPage > 4) {
            return (
                <li className="check-availability-pag__item">... </li>
            )
        } else return null
    }
    const renderNextElipsis = () => {
        if (pages > 7 && currentPaginationPage < (pages - 2)) {
            return (
                <li className="check-availability-pag__item">... </li>
            )
        } else return null
    }
    const submitAddress = () => {
        setSearchAddress({
            address: paginationList()[selectedAddressId - 1].address.addressLine1,
            unit: paginationList()[selectedAddressId - 1].address.addressLine2,
            zip: paginationList()[selectedAddressId - 1].address.zip
        })
        setAddressData({
            serviceAddress: paginationList()[selectedAddressId - 1].address.addressLine1,
            serviceZipCode: paginationList()[selectedAddressId - 1].address.zip
        })
        setSelectedLocation(paginationList()[selectedAddressId - 1])
        setStage('validateAddress')
    }
    const nextPage = () => {
        if (currentPaginationPage < pages) {
            setCurrentPaginationPage(currentPaginationPage + 1)
        }
    }
    const previousPage = () => {
        if (currentPaginationPage > 1) {
            setCurrentPaginationPage(currentPaginationPage - 1)
        }
    }
    const updatePage = (newPage) => {
        setCurrentPaginationPage(newPage)
    }

    const introText = content?.servicibilityCheck.multiUnit.introText.replace('{{count}}', filteredList.length)

    return (
        <div className="merchant-prepaid__multiunit">
        
            <button className="merchant-prepaid__back" onClick={() => setStage('addressCheck')} data-tracking={digitalDataButtonClick('back', content?.dictionary.global.back)}>{content?.dictionary.global.back}</button>
            <section className="merchant-prepaid__banner merchant-prepaid__banner--no-top-pad">
                <h1 className='merchant-prepaid__title'>{content?.servicibilityCheck.multiUnit.title}</h1>
                <p className="merchant-prepaid__subtitle">{introText}</p>
            </section>
            <fieldset className="card merchant-prepaid__form">
                <p>{content?.servicibilityCheck.multiUnit.filterIntro}</p>
                <label htmlFor="checkServiceApt" className="form-label">{content?.servicibilityCheck.multiUnit.filterLabel.text}</label>
                <input type="text" id='checkServiceApt' onChange={event => {setFilter(event.target.value); resetForm()}}  value={filter} />
            </fieldset>
            
            <fieldset className="merchant-prepaid__white-bg">
                {renderedFilterList}
                {renderPagination()}
            </fieldset>
            <div className="merchant-prepaid__button-wrap">
                <button className="button button--hollow" onClick={() => setStage('addressCheck')} data-tracking={digitalDataButtonClick('try-another-address', content.servicibilityCheck.multiUnit.tryAnotherAddress)}>{content.servicibilityCheck.multiUnit.tryAnotherAddress}</button>
                <button data-testid="stage"  className="button" onClick={submitAddress} disabled={selectedAddressId === 0 || filteredList.length === 0} data-tracking={digitalDataButtonClick('submit-address', content.servicibilityCheck.multiUnit.confirmAddress)}>{content.servicibilityCheck.multiUnit.confirmAddress}</button>
            </div>
            
        </div>
    )
}

export default MultiAddressList
