import { trackEvent } from '../../utils/utils'
import withDashboard from '../../redux/connectors/dashboard'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { digitalDataAddToCart, digitalDataButtonClick } from '../../utils/adobe-analytics'

export const WinbackBanner = (props) => {
    const {
        promos,
        addWinbackPromo,
        clearCart,
        fetchedPaymentInfo,
        paymentInfo,
        currentLocale,
        refillContent,
        toggleInternet,
        cart,
        havePromoInCart
    } = props

    const history = useHistory()

    useEffect(() => {
        clearCart()
        if (cart?.internet !== 'HSD_030') {
            toggleInternet('HSD_030')
        }
        trackEvent({
            event: 'dashboard',
            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
            dashboardEvent: 'offer winback banner',
            dashboardDetail: 'impression'
        })
    }, []) // eslint-disable-line

    const addPromoAndRoute = () => {
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'offer winback banner',
                dashboardDetail: 'click'
            })
            trackEvent({
                event: 'refill flow',
                refillStep: 'step 1',
                refillStepName: 'start refill',
                refillStepDetail: 'saved debit credit'
            })
            addWinbackPromo(promos?.discounts[0]?.discId)
            digitalDataAddToCart(
              "standard refill flow",
              "addToCart",
              ".promo-banner",
              havePromoInCart,
              cart?.internet
            );
            trackEvent({
              event: "addToCart",
              ecommerce: {
                currencyCode: "USD",
                add: {
                  products: [
                    {
                      name: "30 days plan for customer",
                      id: "HSD_030",
                      price: 90,
                      dimension1: "refill",
                      dimension4: "website",
                      quantity: 3,
                    },
                  ],
                },
              },
            });
            if (fetchedPaymentInfo && paymentInfo) {
                console.log('redirecitng to review page...')
                trackEvent({
                    event: 'refill flow',
                    refillStep: 'step 2',
                    refillStepName: 'payment',
                    refillStepDetail: 'saved debit credit'
                })
                history.push('/' + currentLocale + '/refill/review')
            } else {
                console.log('redirecitng to payment page...')
                history.push('/' + currentLocale + '/refill/payment')
            }
    }
    return (
        <div className="promo-banner">
            <div data-testid="01-tab" className="promo-banner__content" onClick={addPromoAndRoute} data-tracking={digitalDataButtonClick('promo-banner', refillContent?.refillFlow?.winback?.buy230Day)}>
                <div className="promo-banner__content__copy">
                    <h3>{refillContent?.refillFlow?.winback?.buy230Day}</h3>
                    <p>{refillContent?.refillFlow?.winback?.save45Today}</p>
                </div>
                <div className="promo-banner__content__link">
                    <div className="promo-banner__content__link__icon">
                        <span className="line" /><span className="line" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withDashboard(WinbackBanner)