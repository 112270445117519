import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import { isObject } from '../../utils/utils'
import withBuyflow from '../../redux/connectors/buyflow'
import Cart from '../../components/buyflow/Cart'
import { CSSTransition } from 'react-transition-group'
import { GlobalModal as Modal } from '../../components/Modal'
import StickyCart from '../../components/buyflow/StickyCart'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataAccordionClick, digitalDataAddToCart } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const DirectPromo = (props) => {
    const {
        locale, 
        cartPages,
        updatePageClass,
        updateProgress,
        hsdPromoId,
        haveValidPromos,
        togglePromo,
        prepaidInternetProduct,
        cartContent,
        dashboard
    } = props

    const [pageTitle, setPageTitle] = useState('Xfinity Prepaid')
    const [expanded, setExpanded] = useState(false)

    const history = useHistory()

    useEffect(() => {
        if (isObject(cartPages)) setPageTitle(cartPages.step0DirectPromo.pageTitle)
        updatePageClass('direct-promo')
        updateProgress({ title: cartPages?.step0DirectPromo?.statusLabel, progress: 20 })
    }, [cartPages]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (haveValidPromos) {
            togglePromo(hsdPromoId)
        }
    }, [haveValidPromos]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        trackEvent({
            event: 'buyflow info',
            buyStep: 'step 2',
            buyStepName: 'offer acquisition',
            buyEvent: 'offer acquisition screen',
            buyDetail: 'impression'
        })
        let products: any
        products = []
        for (let i = 0; i < prepaidInternetProduct?.productData?.plans.length; i++) {
            let product: any
            product = {}
            product.name = prepaidInternetProduct?.productData.plans[i].planDisplayText
            product.id = prepaidInternetProduct?.productData.plans[i].plan
            product.price = prepaidInternetProduct?.productData.plans[i].rate
            product.dimension1 = 'buy'
            product.dimension4 = 'website'
            product.quantity = 1
            products.push(product)
        }
        trackEvent({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 2 },
                    products
                }
            }
        })

        if (products.length > 0) {
            digitalDataAddToCart(
              "new customer buy flow",
              "next-step",
              ".buyflow-prepaid",
              false,
              products.length > 0 ? products[0].sku : ""
            );
        }

        // Add internet package GTM code on page load
        trackEvent({
            event: 'addToCart',
            ecommerce: {
            currencyCode: 'USD',
                add: {
                    products
                }
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = () => {
        setExpanded(!expanded)
    }

    const handleNext = () => {
        history.push('/' + locale + '/buy/session')
        trackEvent({
            'event':'buyflow info',
            'buyStep':'step 2',
            'buyStepName':'offer acquisition',
            'buyEvent':'next step cta',
            'buyDetail': 'next'
        })
    }

    return (
        <BuyFlowLayout locale={locale} pageTitle={'Auto Refill'}>
            <TrackUserData data={dashboard} />
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="page__header">
                <h1 className="page__title">{ pageTitle } </h1>
                <p> { cartPages?.step0DirectPromo?.pageDescription }</p>
            </div>
            <div className="direct-promo__hero-banner">
                <img className="cart__display-large" src={`${cartPages?.step0DirectPromo?.promoImageDesktop?.url}`} alt={cartPages?.step0DirectPromo?.promoImageDesktop?.alt} />
                <img className="cart__display-small-medium" src={`${cartPages?.step0DirectPromo?.promoImageMobile?.url}`} alt={cartPages?.step0DirectPromo?.promoImageMobile?.alt} />
                <div className="direct-promo__hero-banner-container">
                    <div className="main__width-constraint clear">
                        { cartPages?.step0DirectPromo?.promoImageOverlayText }
                    </div>
                </div>
            </div>
            <div className="main__desktop-body">
                <div className="main__width-constraint">
                    <div className="card card-promo">
                        <div className="card__summary">
                            <div className="accordion direct-promo-accordion" role="tablist" multiselectable="true">
                                <div className="accordion__item">
                                    <div
                                        id="'01-tab'"
                                        className={`accordion__tab direct-promo-accordion__tab ${expanded ? "" : "active"}`}
                                        role="tab"
                                        aria-expanded={expanded}
                                        aria-controls="01-panel"
                                        onClick={() => {
                                            toggle();
                                            digitalDataAccordionClick('direct-promo', '$' + cartPages?.step1Internet?.promoModal?.price + ' ' + cartPages?.step1Internet?.promoModal?.priceForUpTo, '.accordion-tab');
                                        }}
                                    >
                                        <table v-if="cartPages.step1Internet.promoModal" className="price-wrap">
                                            <tbody>
                                                <tr>
                                                    <td className="price-wrap__amount">
                                                        <span>$</span>{ cartPages?.step1Internet?.promoModal?.price }
                                                    </td>
                                                    <td className="price-wrap__label">
                                                        <strong>{ cartPages?.step1Internet?.promoModal?.priceForUpTo }</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="accordion__icon">
                                            <span className="line" /><span className="line" />
                                        </div>
                                    </div>

                                    <CSSTransition
                                        in={expanded}
                                        timeout={{ enter: 500, exit: 0 }}
                                        classNames="expand"
                                    >
                                        <div
                                            id="'01-panel'"
                                            className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
                                            tabIndex={0}
                                            role="tabpanel"
                                        >
                                            <Cart showDetails={false} allowEditing={false} showLineOffer={true} />
                                        </div>
                                    </CSSTransition>
                                </div>
                            </div>
                            <Modal
                                ctaText={cartPages?.step0DirectPromo?.pricingDisclaimerModalTitle}
                                buttonClasses='button--link--underline text-center'
                                title={cartPages?.step0DirectPromo?.pricingDisclaimerModalTitle}
                                body={prepaidInternetProduct?.pricingDisclaimerModalContent}
                                showTooltip={true}
                                toolTip={cartPages?.step0DirectPromo?.pricingDisclaimerModalContent}
                            />
                        </div>
                    </div>
                    <div>
                        <hr />
                        <div className="card card--clear card--small card--right cart__display-large">
                            <input value={cartContent?.cartModule?.nextButtonLabel} data-testid="handle-next" type="button" className="button button--inline" onClick={() => {digitalDataButtonClick('handle-next', cartContent?.cartModule?.nextButtonLabel, false, undefined, true, '.card'); handleNext()}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cart__display-large main__desktop-sidebar-right">
                    <Cart showDetails={true}/>
            </div>
            <div className="cart__display-small-medium">
                <StickyCart handleNext={handleNext} showDetails={true} />
            </div>
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}

export default withBuyflow(DirectPromo)
