import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/utils'
import { useEffect } from 'react'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

export const PromoBanner = ({title, body, link}) => {
    useEffect(() => {
        trackEvent({
            event: 'dashboard',
            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
            dashboardEvent: 'offer acquisition banner',
            dashboardDetail: 'impression'
        })
    }, [])
    return (
        <div className="promo-banner">
            <Link
                to={link}
                data-testid='promo-01'
                className="promo-banner__content"
                onClick={() => {
                    trackEvent({
                        event: 'dashboard',
                        dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                        dashboardEvent: 'offer acquisition banner',
                        dashboardDetail: 'click'
                    })
                }}
                data-tracking={digitalDataLinkClick('promo-banner', link, title)}
            >
                <div className="promo-banner__content__copy">
                    <h3>{title}</h3>
                    <p>{body}</p>
                </div>
                <div className="promo-banner__content__link">
                    <div className="promo-banner__content__link__icon">
                        <span className="line" /><span className="line" />
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default PromoBanner