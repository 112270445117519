import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { trackEvent } from '../../utils/utils'
import { digitalDataAccordionClick } from '../../utils/adobe-analytics';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
      // extends React's HTMLAttributes
      multiselectable?: string;
    }
}

interface ComponentProps {
    items?: any
    title?: any
    eventObj?: any
    eventDetailCategory?: any
    eventDetailCategoryPretext?: any
    type?: string
}

const PromoModalAccordion = ({items, title, eventObj, eventDetailCategory, eventDetailCategoryPretext, type}: ComponentProps) => {
    const [activeIndex, setActiveIndex] = useState(null)
    const renderedItems = items?.map((item, index) => {
        const onTitleClick = (index) => {
            if (activeIndex === index) {
                setActiveIndex(null)
            } else {
                setActiveIndex(index)
                // analytics
                if (eventObj && eventDetailCategory) {
                    let question = eventDetailCategoryPretext ? eventDetailCategoryPretext + ' - ' + items[index].question : items[index].question
                    eventObj[eventDetailCategory] = question
                    trackEvent(eventObj)
                }
            }
        }
        const captureEnterKey = (event, index) => {
            if (event.keyCode === 13 || event.keyCode === 32) {
                onTitleClick(index)
            }
        }

        return (
            <React.Fragment key={item.title}>
                <div className="accordion__item">
                    <div
                        id="01-tab"
                        tabIndex={0}
                        role="tab"
                        aria-expanded={activeIndex === index}
                        aria-controls="01-panel"
                        className={`accordion__tab ${activeIndex === index ? "" : "active"}`}
                        onClick={() => {
                            onTitleClick(index);
                            digitalDataAccordionClick(
                                "promo",
                                item.title,
                                '.accordion-tab'
                            )
                        }}
                        onKeyDown={(e) => captureEnterKey(e, index)}
                    >
                    <div className="accordion__icon">
                        <span className="line" /><span className="line" />
                    </div>
                    {item.title}
                    </div>
                    <CSSTransition
                        in={activeIndex === index}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames="expand"
                    >
                    <div
                        className={`accordion__panel ${activeIndex === index ? "" : "expand-exit-done"}`}
                        id="01-panel"
                        aria-hidden={activeIndex !== index}
                        aria-labelledby="01-tab"
                        tabIndex={0}
                        role="tabpanel"
                    >
                        <span dangerouslySetInnerHTML={{__html: item.description}} />
                    </div>
                    </CSSTransition>
                </div>
            </React.Fragment>
        )
    })
    return (
        <div className="accordion accordion__faq-group" role="tablist" multiselectable="true">
            {title ? <div className="accordion__title">{title}</div> : ''}
            {renderedItems}
        </div>
    )
}

export default PromoModalAccordion
