import withBuyflow from "../../redux/connectors/buyflow";
import { formatMoney } from "../../utils/utils";
import { useState } from "react";
import PromoCartModal from "../buyflow/PromoCartModal";
import { digitalDataButtonClick } from "../../utils/adobe-analytics";

const Cart = (props) => {
  const [editCart, updateEditCart] = useState(false);

  const {
    cart,
    cartContent,
    cartShipping,
    cartSubtotal,
    cartTaxes,
    cartTotal,
    prepaidInternetProduct,
    showDetails,
    showFreeShipping,
    showLineOffer,
  } = props;

  const renderInternetProducts =
    prepaidInternetProduct?.productData?.plans.length &&
    prepaidInternetProduct?.productData?.plans.map((plan, index) => {
      // render items
      return (
        <tr key={index} className="line-item">
          <th className="line-item__label">{plan.planDisplayText}</th>
          <td className="line-item__price">
            <span className="price-full">
              <span>{formatMoney(plan.rate) === "0.00" ? "" : "$"}</span>
              {formatMoney(plan.rate) === "0.00"
                ? cartContent?.cartModule?.freeShippingCartText
                : formatMoney(plan.rate)}
            </span>
          </td>
        </tr>
      );
    });
  const renderLinePromo = () => {
    return (
      <>
        <tr className="line-item">
          <th className="line-item__label">
            {cartContent?.cartModule.productLabel}
          </th>
          <td className="line-item__price price-wrap--old">
            <span className="price-full price-wrap__amount">
              <span>$</span>45.00
            </span>
          </td>
        </tr>
        <tr className="line-item">
          <th className="line-item__label" />
          <td className="line-item__price">
            <span className="price-full">
              <span>$</span>10.00
            </span>
          </td>
        </tr>
      </>
    );
  };
  const renderPromo = () => {
    return (
      <div>
        <div className="card">
          <table className="totals">
            <caption className="totals__caption">
              <div className="card__header">
                <h2 id="edit-promo-label" className="card__header-title">
                  {cartContent?.cartModule?.productLabelTitle}
                </h2>
                <button
                  type="button"
                  className="totals__edit link-callout"
                  aria-labelledby="edit-xitv"
                  onClick={() => updateEditCart(!editCart)}
                  data-tracking={digitalDataButtonClick('update-edit-cart', editCart ? "OK" : "Edit")}
                >
                  {editCart ? "OK" : "Edit"}
                </button>
              </div>
            </caption>
            <tbody>
              <tr className="line-item">
                <th className="line-item__label">
                  <span>{cartContent?.cartModule?.productLabel}</span>
                  <div className="line-item__remove">
                    {editCart && (
                      <div className="line-item__remove">
                        <PromoCartModal
                          title={"test"}
                          bodyText={"bodyText"}
                          buttonClasses={"button--link--large"}
                          ctaText={"Remove"}
                          content={"test"}
                          updateEditCart={updateEditCart}
                        />
                      </div>
                    )}
                  </div>
                </th>
                <td className="line-item__price price-wrap--old">
                  <span className="price-full price-wrap__amount">
                    <span>$</span>
                    {formatMoney(
                      cart?.promos[0].discountDetails[0].originPricePerUnit
                    )}
                  </span>
                </td>
              </tr>

              <tr className="line-item">
                <th className="line-item__label" />
                <td className="line-item__price">
                  <span className="price-full">
                    <span>$</span>
                    {formatMoney(
                      cart?.promos[0].discountDetails[0].effectivePrice
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="cart">
      {/* CART LIST */}
      <div className="width-100">
        {/* SECTION A */}
        <div>
          <div className="card ">
            <table className="totals">
              <caption className="totals__caption">
                <div className="card__header">
                  <h2 className="card__header-title">
                    {cartContent?.cartModule?.prepaidInternetCartSectionLabel}
                  </h2>
                </div>
              </caption>
              <tbody>
                {renderInternetProducts}
                {/* promo line item */}
                {showLineOffer ? renderLinePromo() : null}
              </tbody>
            </table>
          </div>
        </div>

        {/* SECTION B - Promo */}
        {cart?.promos.length > 0 && !showLineOffer ? renderPromo() : null}
      </div>

      {/* SUBTOTAL */}
      <div className="card card--clear card--order-subtotal">
        {showDetails ? (
          <table className="order-price-summary-table">
            <caption className="vh">
              {cartContent?.cartModule?.subtotalLabel}
            </caption>
            <tbody>
              <tr>
                <th className="order-price-summary-table__label">
                  {cartContent?.cartModule?.subtotalLabel}
                </th>
                <td className="order-price-summary-table__price">
                  <div className="price-full">
                    <span>$</span>
                    {formatMoney(cartSubtotal)}
                  </div>
                </td>
              </tr>
              <tr>
                <th className="order-price-summary-table__label">
                  {cartContent?.cartModule?.shippingLabel}
                </th>
                <td className="order-price-summary-table__price">
                  {!showFreeShipping ? (
                    <div className="price-full">
                      <span>$</span>
                      {formatMoney(cartShipping)}
                    </div>
                  ) : null}
                  {showFreeShipping ? (
                    <div className="price-full">
                      {cartContent?.cartModule?.freeShippingCartText}
                    </div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <th className="order-price-summary-table__label">
                  {cartContent?.cartModule.taxesLabel}
                </th>
                <td className="order-price-summary-table__price">
                  <div className="price-full">
                    <span>$</span>
                    {formatMoney(cartTaxes)}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>

      {/* TOTAL */}

      <div className="card card--clear card--order-total">
        <table className="order-price-summary-table">
          <thead></thead>
          <caption className="vh">
            {cartContent?.cartModule.totalPriceText}
          </caption>
          <tbody>
            <tr>
              <th className="order-price-summary-table__label">
                {cartContent?.cartModule.totalPriceText}
              </th>
              <td className="order-price-summary-table__price">
                <div className="price-full" tabIndex={-1}>
                  <span>$</span>
                  {showDetails
                    ? formatMoney(cartTotal)
                    : formatMoney(cartSubtotal)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default withBuyflow(Cart);
