import { ContentContextProvider } from '../lib/ContentContext'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { contentArrayToObj, dictionaryToObject } from '../utils/utils'
import Header from '../components/Header'
import Footer from '../components/Footer'

const MerchantLayout: FunctionComponent<{locale}> = props => {
    const [contentObj, setContentObj] = useState({content: {}, loaded: false});
    const [locale, setLocale] = useState('en');
    
    const fetchData = useCallback(async () => {
        const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER;
        const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD;
        let MERCHANT_CONTENT_API:string = process.env.REACT_APP_MERCHANT_CONTENT_API!;
        let DICTIONARY_API:string = process.env.REACT_APP_DICTIONARY_API!;
        let params = {};

        if (props.locale !== 'en') {
            MERCHANT_CONTENT_API = MERCHANT_CONTENT_API.replace('/api/', '/api/' + props.locale + '/');
            DICTIONARY_API = DICTIONARY_API.replace('/api/', '/api/' + props.locale + '/');
        }

        if (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) {
            params = {
                headers: new Headers({
                    'Authorization': 'Basic ' + Buffer.from(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD).toString('base64')
                })
            }
        }

        const [sourceContent, sourceDictionary] = await Promise.all([
            fetch(MERCHANT_CONTENT_API, params).then(r => r.json()),
            fetch(DICTIONARY_API, params).then(r => r.json())
        ]);

        let fetchedContent: {[k: string]: any} = contentArrayToObj(sourceContent.merchantFlow);
        fetchedContent.dictionary = dictionaryToObject(sourceDictionary.dictionary);
        setContentObj({content: fetchedContent, loaded: true});
    }, [props.locale]);

    useEffect(() => {
        if (contentObj?.loaded && locale !== props.locale) {
            fetchData().catch(console.error);
        }
        setLocale(props.locale);
        return () => {
            setContentObj({content: {}, loaded: true});
            setLocale('en');
        }
    }, [props.locale, contentObj?.loaded, locale, fetchData]);
    
    useEffect(() => {
        fetchData().catch(console.error);
        return () => {
            setContentObj({content: {}, loaded: true});
        }
    }, [fetchData]);

    if (contentObj?.loaded) {
        return (
            <ContentContextProvider value={contentObj}>
                <div className="prepaid-wrapper merchant-prepaid" data-testid="merchant-prepaid">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="robots" content="noindex,nofollow" />
                        <title>Fast and reliable home internet</title>
                        <link rel="icon" href="/favicon.ico" />
                    </Helmet>

                    <Header locale={locale} disableLogout="true" />
                    <main>
                        <div id="main" role="main" className="main">
                            <div className="main__width-constraint">
                                {props.children}
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </ContentContextProvider>
        );
    } else return null;
}

export default MerchantLayout;