import { createSelector } from 'reselect'
const getACP = state => state?.litepay?.dashboard?.acpDetail || state.acpDetail
const selectors = {
    getHaveValidACP: createSelector(
        [getACP],
        (ACP) => {
            let valid = false
            if (ACP && ACP.acpStatus === 'Approved') {
                const benefitCode = ACP.acpBenefits[0]?.benefitCode
                if (benefitCode) {
                    valid = true
                }
            }
            return valid
        }
    ),
    getIsTribalACP: createSelector(
        [getACP],
        (ACP) => {
            let isTribal = false
            if (ACP && ACP.customerType === 'Tribal') {
                isTribal = true
            }
            return isTribal
        }
    )
}

export default selectors
