import { useState, useEffect } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
import withBuyflow from "../../redux/connectors/buyflow";
import { stripHtml, formatMoney } from "../../utils/utils";
import { GlobalModal as Modal } from "../Modal";
import ModalPromo from "./ModalPromo";
import Cart from "./Cart";
import StickyCart from "../buyflow/StickyCart";
import { trackEvent } from "../../utils/utils";
import ProgressSpinner from "../ProgressSpinner";
import { digitalDataButtonClick } from "../../utils/adobe-analytics";
import BroadbandFacts from "../refill/BroadbandFacts";
import SpanishBroadbandFacts from "../../components/Spanish_BroadbandFacts";

const Internet = (props) => {
  const {
    locale,
    cartPages,
    cartContent,
    updateProgress,
    prepaidInternetProduct,
    haveValidPromos,
    havePromoInCart,
    fetchedProductDetails,
    checkoutContent,
  } = props;
  const cartComponentContent = { cartContent };

  // text formatting
  const subheaderText = stripHtml(cartPages.step1Internet.pageDescription);

  // set up plans

  const [plansEl, setPlansEl] = useState();

  useEffect(() => {
    if (fetchedProductDetails) {
      const plans = prepaidInternetProduct.productData.plans?.map(
        (plan, index) => {
          const amount = formatMoney(plan.rate);
          return (
            <React.Fragment key={index}>
              <tr className="line-item">
                <th className="line-item__label">{plan.planDisplayText}</th>
                <td className="line-item__price">
                  <span className="price-full">
                    <span>{amount === "0.00" ? "" : "$"}</span>
                    {amount === "0.00"
                      ? cartContent?.cartModule?.freeShippingCartText
                      : amount}
                  </span>
                </td>
              </tr>
              {plan.chargeCode === "HW" ? (
                <tr className="line-item">
                  <td className="line-item__disclaimer">
                    {checkoutContent?.orderReview?.limitOneGateway}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        }
      );
      setPlansEl(plans);
    }
  }, [prepaidInternetProduct, fetchedProductDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  // nav logic
  const history = useHistory();
  const [displayPromoModal, setDisplayPromoModal] = useState(false);
  const handleNext = () => {
    trackEvent({
      event: "buyflow info",
      buyStep: "step 2",
      buyStepName: "internet",
      buyEvent: "next step cta",
      buyDetail: cartContent?.cartModule.nextButtonLabel,
    });
    if (
      haveValidPromos &&
      cartPages.step1Internet.promoModal &&
      !havePromoInCart
    )
      setDisplayPromoModal(true);
    else history.push("/" + locale + "/buy/session");
  };

  // page load
  useEffect(() => {
    // progress bar
    updateProgress({
      title: cartPages.step1Internet.statusLabel,
      progress: 20,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (fetchedProductDetails) {
    return (
      <>
        <div className="page__header">
          <h1 className="page__title">{cartPages.step1Internet.pageTitle}</h1>
        </div>

        <div id="main-content" className="main__desktop-body">
          <div className="main__width-constraint">
            <div className="card">
              <div className="card__summary">
                <div className="form-section-header form-section-header--subform">
                  {subheaderText}
                </div>
                <table className="totals">
                  <tbody>{plansEl}</tbody>
                </table>
                <Modal
                  ctaText={prepaidInternetProduct.pricingDisclaimerModalTitle}
                  buttonClasses="button--link--underline"
                  title={prepaidInternetProduct.pricingDisclaimerModalTitle}
                  body={prepaidInternetProduct.pricingDisclaimerModalContent}
                  showTooltip={true}
                  toolTip={prepaidInternetProduct.pricingDisclaimerModalContent}
                />
              </div>

              <div className="card__summary">
                <div className="form-section-header form-section-header--subform">
                  <br />
                </div>

                <div className="card__centered">
                  <img
                    src={prepaidInternetProduct.desktopImage?.url}
                    alt={prepaidInternetProduct.desktopImage?.alt}
                  />
                  <br />
                  <br />
                  <Modal
                    ctaText={
                      cartPages.step1Internet.productDetailsModalLinkText
                    }
                    buttonClasses="button--link--large"
                    title={cartPages.step1Internet.productDetailsModalTitle}
                    body={
                      cartPages.step1Internet.productDetailsModalDescription
                    }
                    showTooltip={true}
                    toolTip={
                      cartPages.step1Internet.productDetailsModalDescription
                    }
                  />
                </div>
              </div>
            </div>

            <ModalPromo
              displayPromoModal={displayPromoModal}
              setDisplayPromoModal={setDisplayPromoModal}
              modalContent={cartPages.step1Internet.promoModal}
              modalCloseButton="Close"
            />

            <div className="cart__display-large">
              <hr />
              <div className="card card--clear card--small card--right">
                <input
                  type="button"
                  className="button button--inline"
                  value={cartContent?.cartModule.nextButtonLabel}
                  onClick={() => handleNext()}
                  data-tracking={digitalDataButtonClick(
                    "card",
                    cartContent?.cartModule.nextButtonLabel
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="cart__display-large main__desktop-sidebar-right">
          <Cart />
          {process.env.REACT_APP_FCC_DISPLAYED === "true" &&
            (locale === "es" ? (
              <SpanishBroadbandFacts tierId="HSD_030"></SpanishBroadbandFacts>
            ) : (
              <BroadbandFacts
                tierId="HSD_030"
                servicePlanType="PREPAID"
                lineOfBusiness="INTERNET"
                sourceServerId={window.location.hostname}
                sourceSystemId="PREPAID"
              ></BroadbandFacts>
            ))}
        </div>

        <div className="cart__display-small-medium">
          <StickyCart
            nextDisabled={false}
            handleNext={handleNext}
            cartComponentContent={cartComponentContent}
          />
          {process.env.REACT_APP_FCC_DISPLAYED === "true"  &&
            (locale === "es" ? (
              <SpanishBroadbandFacts tierId="HSD_030"></SpanishBroadbandFacts>
            ) : (
              <BroadbandFacts
                tierId="HSD_030"
                servicePlanType="PREPAID"
                lineOfBusiness="INTERNET"
                sourceServerId={window.location.hostname}
                sourceSystemId="PREPAID"
              ></BroadbandFacts>
            ))}
        </div>
      </>
    );
  } else {
    return <ProgressSpinner loadingText="loading..." />;
  }
};

export default withBuyflow(Internet);
