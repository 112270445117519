import { createSlice } from '@reduxjs/toolkit';

// TODO PROMOS

interface CartState {
    processingOrder: boolean
    orderPlaced: boolean
    orderFailed: boolean
    orderId: string

    billingName: string // XXX API actually breaks these out and first and last name...
    billingFirstName: string
    billingLastName: string
    billingAddressLine1: string
    billingAddressLine2: string
    billingCity: string
    billingState: string
    billingZip: string
    billingCountry: string // 'US',
    billingCardNumber: string
    billingCardDisplayNumber: string
    billingCardNumberLength: 0,
    billingCardNumberLastFour: string
    billingCardType: string
    billingCardNiceType: string
    billingExpirationMonth: string
    billingExpirationYear: string
    billingCvv: string
    billingAddress: boolean

    shippingSameAsBilling: boolean
    shippingFirstName: string // XXX Shipping first and last name are not in the new design
    shippingLastName: string
    shippingAddressLine1: string
    shippingAddressLine2: string
    shippingCity: string
    shippingState: string
    shippingZip: string

    shippingCode: string // 'STD',
    shippingRate: number // '9.95',
    freeShippingCartText: string
    freeShippingPromoText: string
    freeShippingChargeStatement: string
    showFreeShipping: boolean
    shippingDescription: string // 'Standard',
    shippingTaxDetails: object[]
    shippingTaxAmount: number // tax amount related to shipping, to deduct when shipping is free

    activationSource: 'COESALES'

    emailOptIn: boolean // XXX This is an old field but no longer in the form, always default to true?
    smsOptIn: boolean // XXX This is a new field, not sure how to send it!

    geoCode: string
    email: string
    confirmEmail: string
    tn: string
    displayTn: string
    langPref: 'ENGLISH'|'SPANISH'
    houseKey: string // '08096271505026',

    bundleDetails: object[]

    deviceDetails: object[]

    prepaidPromotions: any

    taxData: any
    taxDataLoaded: boolean

    cart: {
        packages: object[]
        addOns: object[]
        promos: object[]
        haveStandaloneInstantTV: boolean
        directCartFlow: boolean
        offerInCart: boolean
        cartCount: number
    }

    productDetails: any
    fetchedProductDetails: boolean
    fetchingProductDetails: boolean
    fetchProductDetailsFailed: boolean
}

const initialState = {
    processingOrder: false,
    orderPlaced: false,
    orderFailed: false,
    orderId: '',
    accountId: '',

    eligibilityConfirmed: false,
    productDetailsLoaded: false,

    haveComcastApiError: false,
    comcastApiErrors: [],

    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    locationId: '',
    marketId: '',

    billingName: '', // XXX API actually breaks these out and first and last name...
    billingFirstName: '',
    billingLastName: '',
    billingAddressLine1: '',
    billingAddressLine2: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    billingCountry: '', // 'US',
    billingCardNumber: '',
    billingCardDisplayNumber: '',
    billingCardNumberLength: 0,
    billingCardNumberLastFour: '',
    billingCardType: '',
    billingCardNiceType: '',
    billingExpirationMonth: '',
    billingExpirationYear: '',
    billingCvv: '',
    billingAddress: true,

    shippingSameAsBilling: true,
    shippingFirstName: '', // XXX Shipping first and last name are not in the new design
    shippingLastName: '',
    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',

    shippingCode: 'STD', // setting this as the default since it is required for the Tax API and is not consistently being passed to us from product API
    shippingRate: 0, // '9.95',
    freeShippingCartText: '',
    freeShippingPromoText: '',
    freeShippingChargeStatement: '',
    showFreeShipping: false,
    shippingDescription: '', // 'Standard',
    shippingTaxDetails: [],
    shippingTaxAmount: 0.0, // tax amount related to shipping, to deduct when shipping is free

    activationSource: 'COESALES',

    emailOptIn: false, // XXX This is an old field but no longer in the form, always default to true?
    smsOptIn: false, // XXX This is a new field, not sure how to send it!

    geoCode: '',
    email: '',
    confirmEmail: '',
    tn: '',
    displayTn: '',
    langPref: 'ENGLISH',
    houseKey: '', // '08096271505026',
    serviceabilityBusinessErrorCode: null,

    bundleDetails: [],

    deviceDetails: [
        // {
        //   prepaidProduct: {
        //     prepaidProductCode: 'ECO-HSD-WIFI',
        //     prepaidProductName: 'And $35* one-time equipment and start-up fee',
        //     lob: 'HSD',
        //     planCode: 'KIT_WIFI',
        //     rate: 80,
        //     chargeCode: [
        //       {
        //         chargeCode: 'HW',
        //         prodTaxDetails: [
        //           {
        //             taxCode: '199',
        //             taxAmount: 3.39
        //           }
        //         ]
        //       },
        //       {
        //         chargeCode: 'SC',
        //         prodTaxDetails: []
        //       }
        //     ],
        //     planStatus: ''
        //   }
        // }
    ],

    serviceabilityData: {},
    prepaidPromotions: {},

    taxData: {},
    taxDataLoaded: false,

    cart: {
        packages: [],
        addOns: [],
        promos: [],
        haveStandaloneInstantTV: false,
        directCartFlow: false,
        offerInCart: false,
        cartCount: 1
    },

    productDetails: {},
    fetchedProductDetails: false,
    fetchingProductDetails: false,
    fetchProductDetailsFailed: false

} as CartState

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setDirectCartFlow: (state, {payload}) => {
            state.cart.directCartFlow = payload
        },
        fetchProductDetails: (state) => {
            state.fetchingProductDetails = true;
        },
        fetchProductDetailsSuccess: (state, {payload}) => {
            console.log('fetchProductDetails success', payload)

            const data = payload

            state.productDetails = data

            state.shippingCode = data.shipping.name
            state.shippingRate = parseFloat(data.shipping.rate)
            state.shippingDescription = data.shipping.desc

            state.fetchingProductDetails = false
            state.fetchProductDetailsFailed = false
            state.fetchedProductDetails = true
        },
        fetchProductDetailsFailure: (state, {payload}) => {
            console.log('fetchProductDetails failure', payload)
            state.fetchedProductDetails = false;
            state.fetchingProductDetails = false;
            state.fetchProductDetailsFailed = true;
        },
    },
})

export { cart }
