import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import TimeoutCircle from '../components/TimeoutCircle'
import withDashboard from '../redux/connectors/dashboard'
import { digitalDataButtonClick, digitalDataLinkClick } from '../utils/adobe-analytics';

let timer;

const performLogout = () => {
    console.log('session timed out, logging out')
    const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID+"&state=expired";
    window.location.href = logoutURL
}

const TimeoutModal = (props) => {
    const {
        dashboardContent,
        dictionaryContent,
        isOpen,
        pingSession
    } = props

    const node = useRef<HTMLDivElement | null>(null)

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [haveDocumentListener, setHaveDocumentListener] = useState(false)

    const closeModal = () => {
        if (timer) {
            console.log('Clearing countdown timer...')
            clearTimeout(timer)
            timer = null
        }

        pingSession({force: true})

        // console.log('removing event listener')
        document.removeEventListener("mousedown", handleClick)
        setHaveDocumentListener(false)

        setModalIsOpen(false)
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (modalIsOpen && !haveDocumentListener) {
            // console.log('adding event listener')
            document.addEventListener("mousedown", handleClick)
            setHaveDocumentListener(true)
            // console.log(dictionaryContent, isOpen, dashboardContent)
        }
    }, [modalIsOpen, haveDocumentListener]) // eslint-disable-line

    useEffect(() => {
        if (isOpen) {
            setModalIsOpen(isOpen)

            timer = setTimeout(() => {
                performLogout()
            }, 120000)
        } else {
            if (modalIsOpen) closeModal()

            if (timer) {
                console.log('Clearing countdown timer...')
                clearTimeout(timer)
                timer = null
            }
        }
    }, [isOpen]) // eslint-disable-line

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
            // inside click
            return
        }

        // outside click
        closeModal()
    }
    const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
    return (
        <div data-testid="stop-01"
            className='modal-item'
            onClick={stopPropagation}
        >
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="modal modal--visible"
                overlayClassName="modal__overlay"
            >
                <div className="modal__content timeout-modal" data-testid="modal">
                    <div ref={node} className="modal__body" data-testid="modal-body">
                        <h3 id="modal__title" tabIndex={0} className="modal__title">
                            {dashboardContent?.dashboardArea?.timeoutModal?.title}
                        </h3>
                        <p>
                            {dashboardContent?.dashboardArea?.timeoutModal?.yourSessionWillTimeout}
                        </p>

                        {modalIsOpen && <TimeoutCircle />}

                        <button className="button" onClick={closeModal} data-testid="keep-session-active-button" data-tracking={digitalDataButtonClick('keep-session-active', dashboardContent?.dashboardArea?.timeoutModal?.keepSessionActive?.text)}>
                            {dashboardContent?.dashboardArea?.timeoutModal?.keepSessionActive?.text}
                        </button>

                        <a href={logoutURL} className="button link-callout" data-tracking={digitalDataLinkClick('logout', 'https://oauth.xfinity.com/oauth/sp-logout?client_id=xfinity_prepaid_digital', dashboardContent?.dashboardArea?.timeoutModal?.loGOuTNoW?.text)}>
                            {dashboardContent?.dashboardArea?.timeoutModal?.loGOuTNoW?.text}
                        </a>
                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-testid="close-modal-button" data-tracking={digitalDataButtonClick('modal-close', 'Close this dialog window')}>
                            {dictionaryContent?.global?.close}
                            <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withDashboard(TimeoutModal)