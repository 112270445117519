import { useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
//import ProgressSpinner from '../../components/ProgressSpinner'
import ConfirmationButton from '../../components/move/ConfirmationButton'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const MoveScheduleNow = props => {
    const {
        history,
        moveContent,
        dictionaryContent,
        auxAccount,
        dashboard,
        isTestFile
    } = props

    useEffect(() => {
        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 3',
            moveStepName: 'ready to move',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
    }, []) // eslint-disable-line

    //Dynamic Variables
    const animTime = 2000;
    function callbackAPI(){
        console.log("Trigger API");
        trackEvent({
            event: 'move info',
            moveStep: 'step 3',
            moveStepName: 'ready to move',
            moveEvent: 'next step cta',
            moveDetail: 'press and hold to move'
        })
        history?.push("/en/move/now-confirmation")
    }


    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move Schedule Now'}>
            <TrackUserData data={dashboard} />
            <div className="main__width-constraint move">
                <div className="card move--now">
                    <button data-testid="back-01" className="back-button" onClick={() => history?.goBack()} data-tracking={digitalDataButtonClick('back', dictionaryContent?.global?.back)}>
                        {dictionaryContent?.global?.back}
                    </button>
                    <div>
                        <h1 className="page__title">
                            {moveContent?.moveScheduled?.itLooksLikeYoureReadyToMoveToday}
                        </h1>
                        <div className="button--container"><button className="link-callout" onClick={() => history?.goBack()} data-tracking={digitalDataButtonClick('select-different-date', moveContent?.moveScheduled?.noSelectADifferentDate?.text)}>{moveContent?.moveScheduled?.noSelectADifferentDate?.text}</button> </div>
                        <hr />
                        <div className="warning-row"><span className="warning-sign"></span> <b>{moveContent?.moveNow?.yourServiceWillBeDisconnected}</b></div>
                        <p>{moveContent?.moveNow?.onceYouCompleteThisStep}</p>
                        <b className="">{moveContent?.moveNow?.youWillNotHaveAnInternetConnection}</b>
                        <div className="button--container confirm-button--container">
                        <ConfirmationButton title={moveContent?.moveNow?.presSHolDTOMovE?.text} animTime={animTime} CB={callbackAPI} isTestFile={isTestFile}></ConfirmationButton>
                        </div>
                    </div>
                </div>
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveScheduleNow)