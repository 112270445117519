import { useState } from 'react'
import Modal from 'react-modal'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataModalOpen } from '../../utils/adobe-analytics'

interface ComponentProps {
    content?: any
    ctaLabel?: any
    ctaClass?: any
    closeBtnTxt?: any
    contentCloseBtnTxt?: any
    contentImageSrc?: any
    contentImageAlt?: any
    eventObj?: any
    eventDetailCategory?: any
}

const MerchantModal = ({content, ctaLabel, ctaClass, closeBtnTxt, contentCloseBtnTxt, contentImageSrc, contentImageAlt, eventObj, eventDetailCategory}:ComponentProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const openModal = () => toggleModal(true)

    const closeModal = () => toggleModal(false)

    const toggleModal = openMe => {
        const state = openMe ? true : false;
        setModalIsOpen(state)
        // analytics
        if (eventObj && eventDetailCategory) {
            const cat = openMe ? 'FAQ Open' : 'FAQ Close'
            eventObj[eventDetailCategory] = cat
            trackEvent(eventObj)
        }
    }

    const contentCloseBtn = contentCloseBtnTxt ? <button className="button support-modal__content-close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', contentCloseBtnTxt, true)}>{contentCloseBtnTxt}<span /></button> : ''

    return (
        <div className="support-modal-wrapper">
            <button type="button" className={ctaClass ? ctaClass : 'button support-modal-wrapper__init-button'} onClick={openModal} data-tracking={digitalDataModalOpen('support-modal')}>{ctaLabel}</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Connection Support - Step-by-step Guide"
                className="support-modal merchant-modal"
                overlayClassName="support-modal__overlay"
            >
                <div className="support-modal__header">
                    <button className="close-modal" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', closeBtnTxt ? closeBtnTxt : 'Close', true)}>{closeBtnTxt ? closeBtnTxt : 'Close'}<span /></button>
                </div>
                <div className="support-modal__content">
                    {content}
                    {contentImageSrc &&
                        <div className="support-modal__image-wrap">
                            <img src={contentImageSrc} alt={contentImageAlt}/>
                        </div>
                    }
                </div>
                <div className="merchant-prepaid__button-wrap">

                {contentCloseBtn}
                </div>
            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default MerchantModal