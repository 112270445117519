import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GlobalModal as Modal } from "../Modal";
import withDashboard from "../../redux/connectors/dashboard";
import Payment from "../Payment";
import ProgressSpinner from "../../components/ProgressSpinner";
import { fetchTaxData } from "../../redux/dashboard/apis";
import PurchaseAgreement from "../../components/refill/PurchaseAgreement";
import { trackEvent } from "../../utils/utils";
import { formatMoney } from "../../utils/utils";
import { sprintf } from "sprintf-js";
import { JSEncrypt } from "jsencrypt";
import PaymentComponent from "../PaymentComponent";
import { digitalDataAddToCart, digitalDataButtonClick } from "../../utils/adobe-analytics";

const PREPAID_PUBLIC_KEY =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCL0uzNpAvff4QkZCbyF3N1U5e54pnWSi7E514ETZ+4/hybGJ5XoneG2twZzSb1YTa/KE7wPNyl4y4w0tUC9eMuXG9c6U8CELHDwmcr84VrO0B6Na0ckvMaE7cWxKx7dEMoxT+LMG/MdAKhInvq95xxSxq/SpMeQr1WjB2whqI06QIDAQAB";

function encrypt(content) {
  const crypto = new JSEncrypt({});

  crypto.setPublicKey(PREPAID_PUBLIC_KEY);
  return crypto.encrypt(content);
}

const Refill = (props) => {
  const {
    // behindTheModem,
    currentLocale,
    refillContent,
    reconnectContent,
    cartContent,
    accountId,
    custGuid,
    trackingId,
    dashboard,
    hsdService,
    billing,
    createAuxAccount,
    createdAuxAccount,
    createAuxAccountFailed,
    newAuxAccount,
    auxAccount,
    fetchAuxAccount,
    fetchedAuxAccount,
    fetchAuxAccountFailed,
    updateField,
    updateCpcResponse,
    addPaymentInstrument,
    isTestFile,
    cart,
  } = props;

  const [activeIndex, setActiveIndex] = useState(1);
  const [hsdProduct, setHsdProduct] = useState("HSD_030");
  const [taxAmount, setTaxAmount] = useState(0.0);
  const [taxData, setTaxData] = useState<any>(null);
  const [total, setTotal] = useState(0.0);
  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [cpcProcessing, setCpcProcessing] = useState(false); // eslint-disable-line

  const [submitting, setSubmitting] = useState(false);
  const [cpcToken, setCpcToken] = useState();
  const [loadCPCForm, setLoadCPCForm] = useState(false);
  const [startCPCEvents, setStartCPCEvents] = useState(false);
  const [isCPCLoaded, setCPCLoader] = useState(true);
  const [products,setProducts] = useState<any[]>([]);

  const history = useHistory();

  const hsdPrice = (product) => {
    if (product === "HSD_007") {
      return 15;
    } else {
      return 45;
    }
  };

  const updateHsdProduct = (e) => {
    console.log("e", e);
    console.log("e.target.value", e.target.value);
    setHsdProduct(e.target.value);
    toggleAnalytics(e.target.value);
  };

  const updateFormValid = (valid) => {
    updateFormStatus(valid);
    if (valid) {
      setProcessNext(false);
    }
  };
 
  const handleNext = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setProcessNext(true);
    console.log("handleNext");
  };

  const paymentProcessing = (value) => {
    setCpcProcessing(value);
  };

  const paymentProcessed = (response) => {
    setCpcProcessing(false);
    console.log("CPC token received: ", response);
    updateCpcResponse(response);
    handleReconnect(response);
    onContinue(response);
  };

  const paymentLoaded = (value) => {
    setCPCLoader(value);
  };

  useEffect(() => {
    let products: any;
    products = [];
    let toggleProduct: any;
    toggleProduct = {};
    toggleProduct.name = "HSD 30 Day Refill";
    toggleProduct.id = "HSD_030";
    toggleProduct.price = 45;
    toggleProduct.dimension1 = "reconnect";
    toggleProduct.dimension4 = "website";
    toggleProduct.quantity = 1;
    products.push(toggleProduct);
    trackEvent({
      event: "addToCart",
      ecommerce: {
        currencyCode: "USD",
        add: {
          products,
        },
      },
    });
  }, []);
  const onUpdateField = (e) => {
    console.log(e);
  };
  useEffect(() => {
    setStartCPCEvents(true);
  }, []);
  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);
  useEffect(() => {
    setTotal(hsdPrice(hsdProduct + taxAmount));

    const products: any[] = [];

    const productCodes = [hsdProduct];

    productCodes.forEach((productCode) => {
      products.push({
        productCode,
        price: hsdPrice(hsdProduct),
      });
    });

    const geoCode = dashboard?.customerInfo?.geoCode;

    fetchTaxData({ products, geoCode, trackingId })
      .then((response) => {
        setTaxData(response);
        console.log("taxData", response);
        let amount = response?.totalTaxAmount || 0;
        setTaxAmount(amount);
        setTotal(hsdPrice(hsdProduct) + amount);
      })
      .catch((error) => {
        console.error("error fetching taxData", error);
      });
  }, [hsdProduct]); // eslint-disable-line

  const onContinue = (response) => {
    console.log("billing info is valid, continuing...");
    setSubmitting(true);

    const expDate = sprintf(
      "%02d%02d",
      parseInt(billing.expirationMonth),
      parseInt(billing.expirationYear) - 2000
    );

    let prodTaxDetails: any = {};

    if (taxData.products && taxData.products.length) {
      taxData.products.forEach((product) => {
        prodTaxDetails[product.productCode] = {};

        if (product.productType && product.productType[0]) {
          prodTaxDetails[product.productCode] =
            product.productType[0].vertexCodes.map((item) => {
              return { taxCode: item.vertexCode, taxAmount: item.amt };
            });
        }
      });
    }

    let payload = {
      channel: "ONLINE",
      flow: "RECONNECT",
      accountID: accountId,
      custGuid,
      updateCustomer: false,
      customerInfo: {
        email: dashboard.customerInfo.emailAddress,
        emailOptin: dashboard.customerInfo.emailOptIn,
        smsOptin: dashboard.customerInfo.smsOptIn,
        firstName: response?.paymentInfo
          ? response?.paymentInfo?.firstName
          : dashboard.customerInfo.firstName,
        lastName: response?.paymentInfo
          ? response?.paymentInfo?.lastName
          : dashboard.customerInfo.lastName,
        langPref: dashboard.customerInfo.language,
        phone: dashboard.customerInfo.phoneNumber,
        serviceAddress: {
          addressLine1: response?.paymentInfo?.address,
          addressLine2: response?.paymentInfo?.addressLine2,
          city: response?.paymentInfo?.city,
          state: response?.paymentInfo?.state,
          zip: response?.paymentInfo?.zipCode,
          houseKey: props.serviceabilityData.houseKey,
          locationID: dashboard.customerInfo.locationId,
        },
      },
      paymentInfo: {
        // always manually entered
        amount: hsdPrice(hsdProduct) + taxAmount,
        billingAddress: billing.useServiceAddressForBilling
          ? {
              addressLine1: response?.paymentInfo?.address,
              addressLine2: response?.paymentInfo?.addressLine2,
              city: response?.paymentInfo?.city,
              state: response?.paymentInfo?.state,
              zip: response?.paymentInfo?.zipCode,
            }
          : {
              addressLine1: billing?.addressLine1,
              addressLine2: billing?.addressLine2,
              city: billing?.city,
              state: billing?.state,
              zip: billing?.zip,
            },
        paymentInstToken: response?.cpcData?.cardDetails?.token,
        prepaidPaymentID: dashboard?.customerInfo?.prepaidPaymentID,
        cardNumber: response?.cpcData?.cardDetails?.cardLast4Digits,
        cardType: response?.cpcData?.cardDetails?.cardType,
        expirationDate: response?.cpcData?.cardDetails?.expirationDate,
        cardFirstName: response?.paymentInfo?.firstName,
        cardLastName: response?.paymentInfo?.lastName,
        duplicateOverride: true, // always true
        processingNetwork: "CREDIT",
      },
      prepaidProducts: [
        {
          chargeCode: prodTaxDetails[hsdProduct]?.length
            ? [
                {
                  amount: hsdPrice(hsdProduct),
                  chargeCode: hsdProduct,
                  prodTaxDetails: prodTaxDetails[hsdProduct],
                },
              ]
            : [],
          lob: "HSD",
          macId: hsdService.macId,
          planCode: hsdProduct,
          planQty: 1,
          rate: hsdPrice(hsdProduct),
        },
      ],
      isCPC: true,
      operatorUserName: null,
    };

    updateField({ field: "fetchedAuxAccount", value: false });

    createAuxAccount(payload);
  };

  useEffect(() => {
    if (createdAuxAccount) {
      if (newAuxAccount) {
        console.log("Waiting 10 seconds to fetch new aux account...");
        setTimeout(() => {
          fetchAuxAccount({
            accountId,
            auxConfNo: newAuxAccount.auxAccountConfNo,
            checkMoveEligibility: true,
            checkReconnectEligibility: true,
          });
        }, 10000);
      } else {
        console.log("A new aux account does not exist");
        history.push(`/${currentLocale}/error`);
      }
    }
  }, [createdAuxAccount]); // eslint-disable-line

  useEffect(() => {
    if (createdAuxAccount && fetchedAuxAccount) {
      console.log("fetchedAuxAccount");

      if (submitting || isTestFile) {
        setSubmitting(false);
        if (
          auxAccount?.auxDetails?.length &&
          auxAccount.auxDetails[0].auxAcctError
        ) {
          console.error(
            "auxAcctError: " + auxAccount.auxDetails[0].auxAcctError
          );
          history.push(`/${currentLocale}/error`);
          return;
        } else {
          setActiveIndex(3);
        }
      }

      let products: any;
      products = [];
      let toggleProduct: any;
      toggleProduct = {};
      let productName = "HSD 30 Day Refill";
      let productCode = "HSD_030";
      let productPrice = 45;
      if (hsdProduct === "HSD_007") {
        productName = "HSD 7 Day Refill";
        productCode = "HSD_007";
        productPrice = 15;
      }
      toggleProduct.name = productName;
      toggleProduct.id = productCode;
      toggleProduct.price = productPrice;
      toggleProduct.dimension1 = "reconnect";
      toggleProduct.dimension4 = "website";
      toggleProduct.quantity = 1;
      products.push(toggleProduct);

      trackEvent({
        event: "purchase",
        ecommerce: {
          purchase: {
            actionField: {
              id: newAuxAccount?.auxAccountConfNo,
              revenue: productPrice,
            },
            products,
          },
        },
      });

      //const digitalDataObj = window.digitalData;
      let offerIncluded = false;
      let price = productPrice.toString();
      let productID = toggleProduct.id;
      let productType = "prepaid-refill";
      let quantity = 1;
      let transactionID = newAuxAccount?.auxAccountConfNo;
      let buyflowType = "reconnect|prepaid";
      let buyflowStep = "order-confirmation";
      //window.digitalData.page.attributes.pageLoadEvent="Purchase";

      const purchaseData = {
        product: [
          {
            productInfo: {
              offerIncluded,
              price:
                typeof price === "string" && price.includes("$")
                  ? Number(price.replace("$", ""))
                  : Number(price),
              productID,
              productType,
              quantity,
            },
          },
        ],
        transaction: {
          transactionID,
          attributes: {
            buyflowType,
            buyflowStep,
          },
        },
      };
      //Object.assign(digitalDataObj, purchaseData);

      trackEvent({
        event: "reconnect flow",
        reconnectStep: "step 3",
        reconnectStepName: "refill complete",
      });
    }
  }, [fetchedAuxAccount]); // eslint-disable-line

  useEffect(() => {
    if (fetchAuxAccountFailed) {
      console.error("Failed to fetch aux account details");
      history.push(`/${currentLocale}/error`);
    }
  }, [fetchAuxAccountFailed]); // eslint-disable-line

  useEffect(() => {
    if (createAuxAccountFailed) {
      console.error("Failed to create aux account, an API error occurred");
      history.push(`/${currentLocale}/error`);
    }
  }, [createAuxAccountFailed]); // eslint-disable-line

  const handleReconnect = (response) => {
    trackEvent({
      event: "reconnect info",
      reconnectStep: "step 2",
      reconnectStepName: "refill payment",
      reconnectEvent: "next step cta",
      reconnectDetail: "Make Refill Payment",
    });
    const payload = {
      custInfo: {
        accountId,
        firstName: response?.paymentInfo?.firstName,
        lastName: response?.paymentInfo?.lastName,
        addressLine1: response?.paymentInfo?.address,
        addressLine2: response?.paymentInfo?.addressLine2,
        city: response?.paymentInfo?.city,
        state: response?.paymentInfo?.state,
        zip: response?.paymentInfo?.zipCode,
      },
      enrollAutoPayment: billing.enrollInAutoRefill,
      processingNetwork: "CREDIT",
      addInstrumentToWallet: "true",
      paymentInstToken: response?.cpcData?.cardDetails?.token,
      isCPC: true,
      expirationDate: response?.cpcData?.cardDetails?.expirationDate,
      cardType: response?.cpcData?.cardDetails?.cardType,
      cardNumber: response?.cpcData?.cardDetails?.cardLast4Digits,
      savePayment: false,
    };
    addPaymentInstrument(payload);
  };
  const toggleAnalytics = (product) => {
    let products: any;
    products = [];
    let productName = "HSD 30 Day Refill";
    let productCode = "HSD_030";
    let productPrice = 45;
    let otherProductName = "HSD 7 Day Refill";
    let otherProductCode = "HSD_007";
    let otherProductPrice = 15;
    if (product === "HSD_007") {
      productName = "HSD 7 Day Refill";
      productCode = product;
      productPrice = 15;
      otherProductName = "HSD 30 Day Refill";
      otherProductCode = "HSD_030";
      otherProductPrice = 45;
    }
    let toggleProduct: any;
    toggleProduct = {};
    toggleProduct.name = productName;
    toggleProduct.id = productCode;
    toggleProduct.price = productPrice;
    toggleProduct.dimension1 = "reconnect";
    toggleProduct.dimension4 = "website";
    toggleProduct.quantity = 1;
    products.push(toggleProduct);
    trackEvent({
      event: "addToCart",
      ecommerce: {
        currencyCode: "USD",
        add: {
          products,
        },
      },
    });
    products = [];
    toggleProduct = {};
    toggleProduct.name = otherProductName;
    toggleProduct.id = otherProductCode;
    toggleProduct.price = otherProductPrice;
    toggleProduct.dimension1 = "reconnect";
    toggleProduct.dimension4 = "website";
    toggleProduct.quantity = 1;
    products.push(toggleProduct);
    trackEvent({
      event: "removeFromCart",
      ecommerce: {
        currencyCode: "USD",
        remove: {
          products,
        },
      },
    });
  };

  const renderCart = () => {
    return (
      <div className="card">
        <table className="totals">
          <thead></thead>
          {/* <caption className="totals__caption">
                        <div className="card__header">
                            <h2 className="card__header-title">{reconnectContent.step2.weveSentAVerification} { dashboard?.customerInfo?.emailAddress }</h2>
                            <p className="card__header-subtitle">{reconnectContent.step2.weveSentAVerification} { dashboard?.customerInfo?.emailAddress }</p>
                        </div>
                    </caption> */}
          <tbody>
            <tr className="line-item">
              <th className="line-item__label">
                {hsdProduct === "HSD_007"
                  ? reconnectContent?.step3?._7DaysOfPrepaidInternet
                  : reconnectContent?.step3?._30DaysOfPrepaidInternet}
              </th>
              <td className="line-item__price">
                <span className="price-full price-full--refill">
                  <span>$</span>
                  {formatMoney(hsdPrice(hsdProduct))}
                </span>
                {/* <span className="price-full price-full--refill"><span>$</span>22.50</span> */}
              </td>
            </tr>
            <tr className="line-item line-item--title">
              <td>
                <Modal
                  ctaText={
                    refillContent?.refillFlow?.orderConfirmation?.promoModal
                      ?.pricingOtherInfo
                  }
                  buttonClasses="button--link"
                  title={
                    refillContent?.refillFlow?.orderConfirmation?.promoModal
                      ?.pricingOtherInfo
                  }
                  body={
                    refillContent?.refillFlow?.orderConfirmation?.promoModal
                      ?.pricingOtherInfoContent
                  }
                  type="reconnect-pricing-30"
                />
              </td>
            </tr>
            <tr className="line-item"></tr>
            <tr className="line-item sub">
              <th className="order-price-summary-table__label">
                {cartContent?.cartContent?.cartModule?.subtotalLabel}
              </th>
              <td className="order-price-summary-table__price">
                <div className="price-full">
                  <span>$</span>
                  {formatMoney(hsdPrice(hsdProduct))}
                </div>
              </td>
            </tr>
            <tr className="line-item sub separator">
              <th className="order-price-summary-table__label">
                {cartContent?.cartContent?.cartModule?.taxesLabel}
              </th>
              <td className="order-price-summary-table__price">
                <div className="price-full">
                  <span>$</span>
                  {formatMoney(taxAmount)}
                </div>
              </td>
            </tr>
            <tr className="line-item sub">
              <th className="order-price-summary-table__label">
                {cartContent?.cartContent?.cartModule?.totalPriceText}
              </th>
              <td className="order-price-summary-table__price">
                <div className="price-full">
                  <span>$</span>
                  {formatMoney(total)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const paymentError = (response) => {
    //console.log('cpc error message', response.message)
      let errorType;
      let errorDetail;
      if (response.type === "form") {
        errorType = "error validation";
        errorDetail = response.message;
      } else {
        errorType = "error";
        errorDetail = response.messageDetail;
      }
      if(response.type !== "form"){
      trackEvent({
        event: "reconnect info",
        reconectStep: "step 2",
        reconnectStepName: "refill payment",
        reconectEvent: errorType,
        reconectDetail: errorDetail,
      });
      }
  }
  const renderTheContent = () => {
    switch (activeIndex) {
      case 1:
        return (
          <div className="accordion__content null-top-padding">
            <div className="card">
              <ul className="reconnect-radio-list">
                <li>
                  <input
                    onChange={updateHsdProduct}
                    type="radio"
                    id={"reconnectRadio_1"}
                    name={"reconnectRadio"}
                    className={"selectable radio__input"}
                    value={"HSD_030"}
                    checked={hsdProduct === "HSD_030"}
                  />
                  <label
                    className="reconnect-label radio__label"
                    htmlFor={"reconnectRadio_1"}
                  >
                    <span></span>
                    {props.reconnectContent?.step3?._30DaysOfPrepaidInternet}
                    <div className="reconnect-radio-list--price">
                      <sup>$</sup>
                      {formatMoney(hsdPrice("HSD_030"))}
                    </div>
                    <br></br>
                    <Modal
                      ctaText={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfo
                      }
                      buttonClasses="button--link"
                      title={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfo
                      }
                      body={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfoContent
                      }
                      type="reconnect-pricing-30"
                    />
                  </label>
                </li>
                <li>
                  <input
                    onChange={updateHsdProduct}
                    type="radio"
                    id={"reconnectRadio_2"}
                    name={"reconnectRadio"}
                    value={"HSD_007"}
                    checked={hsdProduct === "HSD_007"}
                    className="radio__input"
                  />
                  <label
                    className="reconnect-label radio__label"
                    htmlFor={"reconnectRadio_2"}
                  >
                    <span></span>
                    {props.reconnectContent?.step3?._7DaysOfPrepaidInternet}
                    <div className="reconnect-radio-list--price">
                      <sup>$</sup>
                      {formatMoney(hsdPrice("HSD_007"))}
                    </div>
                    <br></br>
                    <Modal
                      ctaText={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfo
                      }
                      buttonClasses="button--link"
                      title={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfo
                      }
                      body={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfoContent
                      }
                      type="reconnect-pricing-7"
                    />
                  </label>
                </li>
              </ul>
              <p></p>
            </div>
            <div className="button--container">
              <button
                className="button"
                onClick={() => {
                  setActiveIndex(2);
                  trackEvent({
                    event: "reconnect info",
                    reconnectStep: "step 1",
                    reconnectStepName: "start reconnect",
                    reconnectEvent: "next step cta",
                    reconnectDetail: reconnectContent.step1.purchaseRefill.text,
                  });

                  trackEvent({
                    event: "reconnect flow",
                    reconnectStep: "step 2",
                    reconnectStepName: "refill payment",
                  });
                }}
                data-tracking={digitalDataButtonClick('purchase-refill', reconnectContent.step1.purchaseRefill.text)}
              >
                {reconnectContent?.step1?.purchaseRefill.text}
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="main__width-constraint edit-payment">
            {renderCart()}
            <div className="card">
              <div className={cpcProcessing || isCPCLoaded ? "" : "vh"}>
                <ProgressSpinner
                  loadingText={isCPCLoaded ? "Loading..." : "Processing..."}
                />
              </div>
              <div className={cpcProcessing ? "vh" : ""}>
                {loadCPCForm && <PaymentComponent cpcPageType="CardOnly" />}
                <Payment
                  addressLine1={dashboard?.serviceAddress?.addressLine1}
                  addressLine2={dashboard?.serviceAddress?.addressLine2}
                  city={dashboard?.serviceAddress?.city}
                  zip={dashboard?.serviceAddress?.zip}
                  UsState={dashboard?.serviceAddress?.state}
                  trackingId={trackingId}
                  custGuid={dashboard?.customerInfo?.prepaidPaymentID}
                  firstName={dashboard?.customerInfo?.firstName}
                  lastName={dashboard?.customerInfo?.lastName}
                  processNext={processNext}
                  paymentProcessing={paymentProcessing}
                  paymentProcessed={paymentProcessed}
                  updateFormValid={updateFormValid}
                  showAddress={false}
                  channelName={"PREPAID_TKN"}
                  sourceSystemId={"PREPAID_TKN_CPC"}
                  enableFraudManager={false}
                  cpcMessageDisplayLocation={"top"}
                  displayAddressOverride={true}
                  displayAutoPayEnroll={false}
                  displayStoredPaymentOption={false}
                  customerClass={"residential"}
                  paymentToken={cpcToken ? cpcToken : ""}
                  loadCPCForm={loadCPCForm}
                  paymentInstToken={cpcToken}
                  onUpdateField={onUpdateField}
                  storePaymentInstrumentLongTerm={false}
                  cpcPageTypeVal={"CardOnly"}
                  paymentError={paymentError}
                  paymentLoaded={paymentLoaded}
                />
              </div>
            </div>
            <PurchaseAgreement
              handleNext={(e) => {
                handleNext(e);
              }}
              billingIsValid={formValid}
            />
          </div>
        );
      case 3:
        return (
          <React.Fragment>
            <div className="card null-margin">
              <h2 className="card__header-title">
                {reconnectContent?.step2?.thankYouForRefilling}
              </h2>
              <hr />
            </div>
            {renderCart()}
            <div className="card null-margin">
              <div className="button--container">
                <button
                  onClick={() => {
                    trackEvent({
                      event: "reconnect info",
                      reconnectStep: "step 3",
                      reconnectStepName: "refill complete",
                      reconnectEvent: "next step cta",
                      reconnectDetail:
                        reconnectContent?.step3?.continueToReactivation?.text,
                    });
                    trackEvent({
                      event: "reconnect flow",
                      reconnectStep: "step 4",
                      reconnectStepName: "reconnect review",
                    });
                    if (props.onFinish) {
                      props.onFinish();
                    }
                  }}
                  className="button"
                  data-tracking={digitalDataButtonClick('continue-to-reactivation', reconnectContent?.step3?.continueToReactivation?.text)}
                >
                  {reconnectContent?.step3?.continueToReactivation?.text}
                </button>
              </div>
            </div>
          </React.Fragment>
        );
    }
  };

  if (submitting) {
    return (
      <ProgressSpinner
        loadingText={reconnectContent?.step2?.processingYourPayment}
      />
    );
  } else {
    return (
      <div>
        {activeIndex === 2 && (
          <div className="card null-margin">
            <button
              onClick={() => setActiveIndex(1)}
              className="button button--link back-btn"
              data-tracking={digitalDataButtonClick('back', 'Back')}
            >
              Back
            </button>
            <hr />
          </div>
        )}

        {renderTheContent()}
      </div>
    );
  }
};

export default withDashboard(Refill);
