import { createSlice } from "@reduxjs/toolkit";
import { errorForCode } from "../../lib/errors";
import litepaySelectors from "../litepay/selectors";
import { common } from "../common/store";

let sitecoreUrl = `https://${window.location.hostname}`;
if (process.env.REACT_APP_API_BASE_URL) {
  sitecoreUrl = process.env.REACT_APP_API_BASE_URL;
}

interface LitepayState {
  lastActionAt: number;
  ready: boolean;

  trackingId: string | null;

  locale: string;

  isVanAccount: boolean;

  xsrfToken: string | null;
  fetchedToken: boolean;
  fetchingToken: boolean;
  fetchingTokenFailed: boolean;

  content: any | null;
  englishContent: any | null;
  spanishContent: any | null;
  fetchedContent: boolean;
  fetchingEnglishContent: boolean;
  fetchingEnglishContentFailed: boolean;
  fetchedEnglishContent: boolean;
  fetchingSpanishContent: boolean;
  fetchingSpanishContentFailed: boolean;
  fetchedSpanishContent: boolean;

  checkoutContent: any | null;

  litepayPages: any | null;

  menu: any | null;

  billingName: string;
  billingFirstName: string;
  billingLastName: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry: string;
  billingCardNumber: string;
  billingCardDisplayNumber: string;
  billingCardNumberLength: number;
  billingCardNumberLastFour: string;
  billingCardType: string;
  billingCardNiceType: string;
  billingExpirationMonth: string;
  billingExpirationYear: string;
  billingCvv: string;

  billingZipLookup: string;
  billingUnit: string;
  billingPhoneNumber: string;
  tn: string;

  showErrorPage: boolean;
  error: string;

  showLocateServicesNotification: boolean;
  locateServicesNotificationMessage: string;

  showMultipleAccountsNotification: boolean;
  multipleAccountsNotificationMessage: string;

  showCreditCardFormNotification: boolean;
  creditCardFormNotificationMessage: string;

  navigationUrl: string;
  completedStep: number;
  displayNavigationModal: boolean;
  showStickyCart: boolean;

  // API stuff
  locatingService: boolean;
  locatedService: boolean;
  hasValidPlan: boolean;
  dashboardData: any | null;

  processingRefill: boolean;
  orderPlaced: boolean;
  orderFailed: boolean;
  orderNetworkError: boolean;
  orderNumber: string;
  orderConfirmationViewed: boolean;
  stepNumber: number;

  dashboard: any | null;
  fetchedDashboard: boolean;
  fetchingDashboard: boolean;
  fetchingDashboardFailed: boolean;
  dashboardErrorCode: string | null;
  dashboardErrorMessage: string | null;

  fetchedTaxData: boolean;
  fetchingTaxData: boolean;
  fetchTaxDataFailed: boolean;
  cartTaxes: number;
  cartSubtotal: number;
  cartTotal: number;

  acpDetail: any | null;

  refill: any | null;
  refillingPrepaidAccount: boolean;
  refilledPrepaidAccount: boolean;
  refillPrepaidAccountFailed: boolean;

  refillErrorCode: string | null;

  cpcResponse: any;

  addingPaymentInstrument: boolean;
  addedPaymentInstrument: boolean;
  addPaymentInstrumentFailed: boolean;

  paymentInstrumentErrorCode: string | null;

  isBack: boolean;
}

const updateReadyStatus = (state) => {
  let ready = true;
  ready = ready && state.fetchedEnglishContent;
  ready = ready && state.fetchedSpanishContent;
  ready = ready && state.fetchedIdentity;
  ready = ready && state.fetchedDashboard;
  // ready = ready && state.fetchedAccount
  ready = ready && state.fetchedDeviceStatus;
  state.ready = ready;
  state.fetchedContent =
    state.fetchedEnglishContent && state.fetchedSpanishContent;
};

const initialState = {
  lastActionAt: Date.now(),
  ready: false,

  trackingId: null,

  locale: "en",

  isVanAccount: false,

  xsrfToken: null, // XXX not populated, not needed?
  fetchedToken: false,
  fetchingToken: false,
  fetchingTokenFailed: false,

  content: {},
  englishContent: {},
  spanishContent: {},
  fetchedContent: false,
  fetchingEnglishContent: false,
  fetchingEnglishContentFailed: false,
  fetchedEnglishContent: false,
  fetchingSpanishContent: false,
  fetchingSpanishContentFailed: false,
  fetchedSpanishContent: false,

  checkoutContent: null,

  litepayPages: {
    refill: {},
  },
  menu: {
    text: "Menu",
    refillText: "Refill my prepaid Service",
    logo: {
      text: "",
      alt: "",
      url: "https://www.xfinity.com/learn/internet-service",
      secondaryUrl: sitecoreUrl,
      target: "",
    },
    navigation: [],
  },

  billingName: "", // XXX API actually breaks these out and first and last name...
  billingFirstName: "",
  billingLastName: "",
  billingAddressLine1: "",
  billingAddressLine2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  billingCountry: "",
  billingCardNumber: "",
  billingCardDisplayNumber: "",
  billingCardNumberLength: 0,
  billingCardNumberLastFour: "",
  billingCardType: "",
  billingCardNiceType: "",
  billingExpirationMonth: "",
  billingExpirationYear: "",
  billingCvv: "",

  billingZipLookup: "",
  billingUnit: "",
  billingPhoneNumber: "",
  tn: "",

  cardTypeMap: {
    mastercard: "MASTER_CARD",
    visa: "Visa",
    discover: "Discover",
    "american-express": "AMERICAN_EXPRESS",
  },

  showErrorPage: false,
  error: "",

  showLocateServicesNotification: false,
  locateServicesNotificationMessage: "",

  showMultipleAccountsNotification: false,
  multipleAccountsNotificationMessage: "",

  showCreditCardFormNotification: false,
  creditCardFormNotificationMessage: "",

  navigationUrl: "",
  completedStep: 0,
  displayNavigationModal: false,
  showStickyCart: false,

  // API stuff
  locatingService: false,
  locatedService: false,
  hasValidPlan: true,
  taxData: {},
  dashboardData: {},

  processingRefill: false,
  orderPlaced: false,
  orderFailed: false,
  orderNetworkError: false,
  orderNumber: "",
  orderConfirmationViewed: false,
  stepNumber: 1,

  dashboard: null,
  fetchedDashboard: false,
  fetchingDashboard: false,
  fetchingDashboardFailed: false,
  dashboardErrorCode: "",
  dashboardErrorMessage: "",

  fetchedTaxData: false,
  fetchingTaxData: false,
  fetchTaxDataFailed: false,
  cartTaxes: 0,
  cartSubtotal: 0,
  cartTotal: 0,

  acpDetail: {
    customerType: "",
    acpStatus: "",
    deniedReasonCode: "",
    deniedReasonDesc: "",
    acpBenefits: [
      {
        benefitPlan: "",
        benefitPeriod: "",
        benefitCode: "",
        benefitAmount: 0,
      },
    ],
  },

  refill: null,
  refillingPrepaidAccount: false,
  refilledPrepaidAccount: false,
  refillPrepaidAccountFailed: false,

  refillErrorCode: null,

  cpcResponse: {},

  addingPaymentInstrument: false,
  addedPaymentInstrument: false,
  addPaymentInstrumentFailed: false,

  paymentInstrumentErrorCode: null,
  isBack: false

} as LitepayState;

const getCartSubtotal = (validNonTribalACP, dashboard) => {
  let subtotal = 0;

  if (dashboard?.availablePlans?.length) {
    subtotal += dashboard?.availablePlans[0].planPrice;
  }
  if (validNonTribalACP) {
    subtotal -= dashboard.acpDetail.acpBenefits[0].benefitAmount;
  }

  return subtotal;
};
const resetPaymentInstrumentState = (state) => {
  state.addedPaymentInstrument = false;
  state.addingPaymentInstrument = false;
  state.addPaymentFailed = false;
  state.updatedPaymentInstrument = false;
  state.updatingPaymentInstrument = false;
  state.updatePaymentInstrumentFailed = false;
  state.removedPaymentInstrument = false;
  state.removingPaymentInstrument = false;
  state.removePaymentInstrumentFailed = false;
};

const litepay = createSlice({
  name: "litepay",
  initialState,
  reducers: {
    generateTrackingId: (state, { payload }) => {
      if (!state.trackingId) {
        state.trackingId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0;
            var v = c === "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line no-mixed-operators
            return v.toString(16);
          }
        );
      }
    },

    updateLocale: (state, { payload }) => {
      state.locale = payload;
      if (state.locale === "es") {
        state.content = { ...state.spanishContent };
      } else {
        state.content = { ...state.englishContent };
      }
      const litePayPages = {};
      state.content.litepay?.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          litePayPages[key] = obj[key];
        });
      });
      state.litepayPages = litePayPages;
      // console.log(litePayPages)
      if (state.litepayPages.litepay?.menu.item.children) {
        let menuList: any;
        menuList = [];
        state.litepayPages.litepay.menu.item.children.forEach((obj) => {
          if (obj.linkType === "refill") {
            state.menu.refill = obj;
          } else if (obj.linkType === "activate") {
            state.menu.activate = obj;
          } else if (obj.linkType === "account") {
            state.menu.account = obj;
          } else {
            menuList.push(obj);
          }
        });
        state.menu.navigation = menuList;
      }
    },
    updateField: (state, { payload }) => {
      state[payload.field] = payload.value;
      if (payload.field === "billingName") {
        const parts = payload.value.replace(/[ ]+/g, " ").split(" ");
        if (parts.length >= 2) {
          state.billingFirstName = parts[0];
          state.billingLastName = parts[parts.length - 1];
        }
      }
    },
    toggleField: (state, { payload }) => {
      state[payload.field] = !state[payload.field];
    },

    fetchToken: (state) => {
      state.fetchingToken = true;
      state.lastActionAt = Date.now();
    },
    fetchTokenSuccess: (state, { payload }) => {
      // console.log('fetchTokenSuccess payload', payload)
      state.xsrfToken = payload.xsrfToken;
      state.fetchingToken = false;
      state.fetchingTokenFailed = false;
      state.fetchedToken = true;
      state.lastActionAt = Date.now();
    },
    fetchTokenFailure: (state, { payload }) => {
      console.log("fetchTokenFailure payload", payload);
      state.fetchedToken = false;
      state.fetchingToken = false;
      state.fetchingTokenFailed = true;
      state.lastActionAt = Date.now();
    },

    fetchEnglishLitepayContent: (state) => {
      state.fetchingEnglishContent = true;
      state.lastActionAt = Date.now();
    },
    fetchEnglishLitepayContentSuccess: (state, { payload }) => {
      // console.log('🇺🇸 fetchEnglishLitepayContentSuccess payload', payload)

      let content: any = {};
      content.litepay = payload.litepay;

      console.log("🇺🇸 Updating English content...", content);
      state.englishContent = content;
      if (state.locale === "en") {
        state.content = { ...content };
      }
      state.fetchingEnglishContent = false;
      state.fetchingEnglishContentFailed = false;
      state.fetchedEnglishContent = true;
      updateReadyStatus(state);
      state.lastActionAt = Date.now();
    },
    fetchEnglishLitepayContentFailure: (state, { payload }) => {
      console.log("fetchEnglishLitepayContentFailure payload", payload);
      state.fetchingEnglishContent = false;
      state.fetchingEnglishContentFailed = true;
      state.lastActionAt = Date.now();
    },

    fetchSpanishLitepayContent: (state) => {
      state.fetchingSpanishContent = true;
      state.lastActionAt = Date.now();
    },
    fetchSpanishLitepayContentSuccess: (state, { payload }) => {
      // console.log('fetchSpanishLitepayContentSuccess payload', payload)

      let content: any = {};
      content.litepay = payload.litepay;

      console.log("🇲🇽 Updating Spanish content...", content);
      state.spanishContent = content;
      if (state.locale === "es") {
        state.content = { ...content };
      }
      state.fetchingSpanishContent = false;
      state.fetchingSpanishContentFailed = false;
      state.fetchedSpanishContent = true;
      updateReadyStatus(state);
      state.lastActionAt = Date.now();
    },
    fetchSpanishLitepayContentFailure: (state, { payload }) => {
      console.log("fetchSpanishLitepayContentFailure payload", payload);
      state.fetchingSpanishContent = false;
      state.fetchingSpanishContentFailed = true;
      state.lastActionAt = Date.now();
    },
    updateCheckoutContent: (state, { payload }) => {
      let buyflowContent;
      buyflowContent = payload;
      // console.log('buyflow content', buyflowContent)
      let checkoutContent: any;
      checkoutContent = {};
      buyflowContent.checkout?.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          checkoutContent[key] = obj[key];
        });
      });

      // console.log('buyflow checkout content', checkoutContent)

      const errors = {};
      checkoutContent.errors?.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          errors[key] = obj[key];
        });
      });

      // console.log('buyflow checkout content errors', errors)

      checkoutContent.errors = errors;

      state.checkoutContent = checkoutContent;
    },
    fetchCustomerDashboard: (state) => {
      state.fetchingDashboard = true;
      state.showMultipleAccountsNotification = false;
      state.showLocateServicesNotification = false;
      state.locatingService = true;
      state.lastActionAt = Date.now();
    },
    fetchCustomerDashboardSuccess: (state, { payload }) => {
      console.log("fetchCustomerDashboardSuccess payload", payload);
      state.dashboard = payload;
      state.isVanAccount = state.dashboard?.customerInfo?.accountType === "VAN";
      state.fetchingDashboard = false;
      state.fetchingDashboardFailed = false;
      state.fetchedDashboard = true;
      state.locatingService = false;
      state.locatedService = true;
      state.acpDetail = payload.acpDetail;
      state.cartSubtotal = getCartSubtotal(
        litepaySelectors.getHaveValidACP(state) &&
          !litepaySelectors.getIsTribalACP(state),
        state.dashboard
      );
      updateReadyStatus(state);
      state.lastActionAt = Date.now();
    },
    fetchCustomerDashboardFailure: (state, { payload }) => {
      console.log("fetchCustomerDashboardFailure payload", payload);
      state.fetchingDashboard = false;
      state.fetchingDashboardFailed = true;
      state.dashboardErrorCode = payload?.errorCode;
      state.dashboardErrorMessage = payload?.errorMessage;
      if (payload?.errorCode === "BUS_CUSTOMER_DASHBOARD_027") {
        state.showMultipleAccountsNotification = true;
        state.multipleAccountsNotificationMessage = errorForCode(
          payload?.errorCode,
          state?.locale
        );
      } else {
        state.showLocateServicesNotification = true;
        state.locateServicesNotificationMessage = errorForCode(
          payload?.errorCode,
          state?.locale
        );
      }
      state.lastActionAt = Date.now();
      state.locatingService = false;
      state.locatedService = false;
    },
    fetchTaxData: (state) => {
      state.fetchingTaxData = true;
      state.fetchedTaxData = false;
      state.fetchTaxDataFailed = false;
    },
    fetchTaxDataSuccess: (state, { payload }) => {
      console.log("fetchTaxDataSuccess payload", payload);
      state.fetchingTaxData = false;
      state.fetchTaxDataFailed = false;
      state.fetchedTaxData = true;
      state.cartTaxes = payload;

      // XXX Is it safe to assume zero tax when this is missing?
      if (payload.totalTaxAmt === null || payload.totalTaxAmt === undefined) {
        state.cartTaxes = 0;
      } else {
        state.cartTaxes = payload.totalTaxAmt;
      }
      state.cartSubtotal = getCartSubtotal(
        litepaySelectors.getHaveValidACP(state) &&
          !litepaySelectors.getIsTribalACP(state),
        state.dashboard
      );
      state.cartTotal = state.cartSubtotal + state.cartTaxes;
    },
    fetchTaxDataFailure: (state, { payload }) => {
      console.log("fetchTaxDataFailure payload", payload);
      state.fetchingTaxData = false;
      state.fetchTaxDataFailed = true;
      state.fetchedTaxData = false;
      state.cartSubtotal = getCartSubtotal(
        litepaySelectors.getHaveValidACP(state) &&
          !litepaySelectors.getIsTribalACP(state),
        state.dashboard
      );
      state.cartTotal = state.cartSubtotal;
    },

    refillPrepaidAccount: (state) => {
      state.refillingPrepaidAccount = true;
      state.refilledPrepaidAccount = false;
      state.refillPrepaidAccountFailed = false;
    },
    refillPrepaidAccountSuccess: (state, { payload }) => {
      console.log("refillPrepaidAccountSuccess payload", payload);
      state.refillingPrepaidAccount = false;
      state.refilledPrepaidAccount = true;
      state.refillPrepaidAccountFailed = false;
      state.refill = payload.response;
      if (payload.response?.status !== "SUCCESS") {
        console.warn(
          "Possible failure; Expected response.status = SUCCESS, got response.status =",
          payload.response?.status
        );
      }
      if (
        !(
          payload.response?.orderDetails &&
          payload.response?.orderDetails.orderNumber
        )
      ) {
        console.warn(
          "Possible failure; expected orderDetails.orderNumber in the response"
        );
      } else {
        state.orderNumber = payload.response.orderDetails.orderNumber;
      }
      state.lastActionAt = Date.now();
    },
    refillPrepaidAccountFailure: (state, { payload }) => {
      console.log("refillPrepaidAccountFailure payload", payload);
      state.refillingPrepaidAccount = false;
      state.refilledPrepaidAccount = false;
      state.refillPrepaidAccountFailed = true;
      state.refillErrorCode = payload.errorCode;
    },
    updateCpcResponse: (state, { payload }) => {
      state.cpcResponse = payload;
    },

    addPaymentInstrument: (state) => {
      resetPaymentInstrumentState(state);
      state.addingPaymentInstrument = true;
    },
    addPaymentInstrumentSuccess: (state, { payload }) => {
      console.log("addPaymentInstrumentSuccess payload", payload);
      state.addedPaymentInstrument = true;
      state.addingPaymentInstrument = false;
      state.addPaymentInstrumentFailed = false;
      state.lastActionAt = Date.now();
    },
    addPaymentInstrumentFailure: (state, { payload }) => {
      console.log("addPaymentInstrumentFailure payload", payload);
      state.addedPaymentInstrument = false;
      state.addingPaymentInstrument = false;
      state.addPaymentInstrumentFailed = true;
      state.paymentInstrumentErrorCode = payload.businessErrorCode;
    },
    updatePageStep: (state)=>{
        state.isBack=true
    },   
    resetPageStep: (state)=>{
      state.isBack=false
    }
  },
});

export { litepay };
export const {
  generateTrackingId,
  updateLocale,
  resetPageStep,
  updatePageStep,
  fetchToken,
  fetchTokenSuccess,
  addPaymentInstrument,
  addPaymentInstrumentSuccess,
  addPaymentInstrumentFailure,
  fetchCustomerDashboardSuccess,
  toggleField,
  fetchEnglishLitepayContent,
  updateCpcResponse,
  fetchCustomerDashboardFailure,
  refillPrepaidAccountFailure,
  refillPrepaidAccountSuccess,
  refillPrepaidAccount,
  fetchTaxDataFailure,
  fetchTaxData,
  fetchCustomerDashboard,
  fetchSpanishLitepayContentFailure,
  fetchSpanishLitepayContentSuccess,
  fetchSpanishLitepayContent,
  fetchEnglishLitepayContentFailure,
  fetchEnglishLitepayContentSuccess,
  fetchTokenFailure,
  fetchTaxDataSuccess,
  updateCheckoutContent
} = litepay.actions;
export default litepay.reducer;
