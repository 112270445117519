import DashboardLayout from '../../layouts/DashboardLayout'
//import ProgressSpinner from '../../components/ProgressSpinner'
import ConfirmationButton from '../../components/move/ConfirmationButton';
import { useEffect, useState } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import { weekdays, months, trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const MoveScheduleFinalize = (props) => {
    const {
        history,
        currentLocale,
        scheduledMoveDate,
        moveContent,
        dashboard,
        accountId,
        custGuid,
        auxAccount,
        createAuxAccount,
        creatingAuxAccount,
        createdAuxAccount,
        createAuxAccountFailed,
    } = props

    const [address, setAddress] = useState('')
    const [unit, setUnit] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [state, setState] = useState('')
    const [moveDate, setMoveDate] = useState<any>()
    const [addressData, setAddressData] = useState<any>(null)
    const [isColdTap, setIsColdTap] = useState(false)

    //Dynamic Variables
    const animTime = 2000;

    useEffect(() => {
        props.updatePageClass('dashboard dashboard-move')

        console.log('scheduledMoveDate', scheduledMoveDate)
        if (!scheduledMoveDate) {
            console.error('No scheduled move date')
            history?.push(`/${currentLocale}/move/schedule`)
            return
        }

        setMoveDate(new Date(scheduledMoveDate))

        let dataSrc = sessionStorage.getItem('xppdata')
        if (dataSrc) {
            const data = JSON.parse(dataSrc);
            if (data.address) {
                setAddressData(data)
                setAddress(data.address.toUpperCase())
                setUnit(data.unit.toUpperCase())
                setCity(data.city.toUpperCase())
                setZip(data.zip.toUpperCase())
                setState(data.state.toUpperCase())
                setIsColdTap(data.isColdTap)
            }
        }

        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 3',
            moveStepName: 'ready to move',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
    }, []) // eslint-disable-line

    function performMove() {
        if (!creatingAuxAccount) {
            trackEvent({
                event: 'move info',
                moveStep: 'step 3',
                moveStepName: 'ready to move',
                moveEvent: 'next step cta',
                moveDetail: 'press and hold to move'
            })
    
            //Trigger API
            if (!addressData) {
                console.error('Service availability data not found')
                return
            }
    
            let payload = {
                channel: 'ONLINE',
                flow: 'MOVE',
                accountID: accountId,
                custGuid,
                updateCustomer:false,
                customerInfo: {
                    email: dashboard.customerInfo.emailAddress,
                    emailOptin: dashboard.customerInfo.emailOptIn,
                    smsOptin: dashboard.customerInfo.smsOptIn,
                    firstName: dashboard.customerInfo.firstName,
                    lastName: dashboard.customerInfo.lastName,
                    langPref: dashboard.customerInfo.language,
                    phone: dashboard.customerInfo.phoneNumber,
                    serviceAddress: {
                        addressLine1: addressData.address,
                        addressLine2: addressData.unit,
                        city: addressData.city,
                        houseKey: addressData.houseKey,
                        locationID: addressData.locationId,
                        state: addressData.state,
                        zip: addressData.zip
                    },
                },
                operatorUserName: null,
                scheduledMoveDate
            }
    
            console.log('createAuxAccount payload', payload)
    
            createAuxAccount(payload)
        }
    }

    useEffect(() => {
        if (createdAuxAccount) {
            history?.push(`/${currentLocale}/move/schedule-confirmation`)
        }
    }, [createdAuxAccount]) // eslint-disable-line

    useEffect(() => {
        if (createAuxAccountFailed) {
            console.error('Failed to create aux account, an API error occurred')
            history?.push(`/${currentLocale}/error`)
            // history.push(`/${currentLocale}/move/now-confirmation`)
        }
    }, [createAuxAccountFailed]) // eslint-disable-line

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move Schedule Finalize'}>
            <TrackUserData data={dashboard} />
            <div className="main__width-constraint move move--finalize">
                <h1 className="page__title">
                    {moveContent?.moveScheduled?.letsFinalizeYourMove}
                </h1>
                <div className="service-section">
                <div className="fullbleed-offsets">
                    <b>{moveContent?.moveScheduled?.serviceRequestDetail}</b>
                    <hr></hr>
                    <div className="service-section__row">
                        <div className="address">
                            <b> {moveContent?.moveNow?.toDestinationAddress}</b><br></br>
                            <div>{address}</div>
                            {unit !== '' ? <div>{unit}</div> : null }
                            {city}, {state} {zip}
                            <p></p>
                            <b>{moveContent?.moveNow?.fromPreviousAddress}</b><br></br>
                            {dashboard?.serviceAddress?.addressLine1}
                            {dashboard?.serviceAddress?.addressLine2 !== '' ? <div>{dashboard?.serviceAddress?.addressLine2}</div> : null }
                            {dashboard?.serviceAddress?.city}, {dashboard?.serviceAddress?.state} {dashboard?.serviceAddress?.zip}
                        </div>
                    </div>
                    <p></p>
                    {moveDate &&
                    <div className="service-section__row">
                        <div className="address">
                            <b>Move Date</b><br></br>
                            <span>
                                {weekdays[moveDate.getUTCDay()]}, {months[moveDate.getUTCMonth()]} {moveDate.getUTCDate()}, {moveDate.getUTCFullYear()}
                            </span>
                        </div>
                        <button className="button--link" onClick={() => history.push(`/${currentLocale}/move/schedule`)} data-tracking={digitalDataButtonClick('edit-date', 'Edit Date')}>Edit Date</button>
                    </div>}
                    <hr></hr>
                    </div>
                </div>
                <div className="confirm-button--container"><ConfirmationButton title={moveContent?.moveNow?.presSHolDTOMovE?.text} animTime={animTime} CB={performMove}></ConfirmationButton></div>

                <div className="fullbleed-offsets">
                    { isColdTap ? 
                    <p>{ moveContent?.moveScheduled?.onTheScheduledDate }</p> 
                    : 
                    <p>{moveContent?.moveScheduled?.allMoveRequests}</p>
                    }
                    
                </div>
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveScheduleFinalize)