import TrackingData from './TrackingData';

const TrackUserData = (data) => {
  let authenticationType;
  let customerType;
  if(window.location.href.includes('/buy') || window.location.href.includes('/litepay')){
    authenticationType ="unauthenticated"
    customerType= "new"
  }else{
    authenticationType ="active authenticated";
    customerType= "existing"
  }
  
 
  if (!data?.data) {
    return null;
  }

  return <TrackingData trackingInfo={{
    user: [
      {
        profile: [
          {
            profileInfo: {
              authenticationType: authenticationType,
              customerType: customerType,
              recognizationType: "", // TBD
              acpStatus: data?.data?.acpDetail ? data?.data?.acpDetail?.acpStatus : "",
              prepaid: {
                accountStatus: data?.data?.customerInfo?.prepaidAccountStatus,
                currentService: data?.data?.subscriptionDetails ? data?.data?.subscriptionDetails[0]?.deviceLob : "",
                paymentMethodExists: data?.data?.paymentDetail?.cardNumber ? true : false,
                serviceActivationState: data?.data?.customerInfo?.prepaidAccountStatus,
                serviceDaysRemaining: data?.data?.subscriptionDetails ? data?.data?.subscriptionDetails[0]?.daysRemaining : "",
                serviceStatus: data?.data?.subscriptionDetails ? data?.data?.subscriptionDetails[0]?.deviceStatus : "",
                services: {
                  LOB: [
                    {
                      name: "internet",
                      tier: "prepaid",
                    },
                  ],
                  productType: "single play",
                },
              },
            },
          },
        ],
        segment: {
          zipServiceAddress: data?.data?.serviceAddress ? data?.data?.serviceAddress?.zip : data?.data?.address?.zip,
        },
      },
    ]
  }}></TrackingData>
};

export default TrackUserData;