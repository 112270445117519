import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ProgressSpinner from '../ProgressSpinner'
import withBuyflow from '../../redux/connectors/buyflow'

const RouteCheck = props => {
    const {
        content,
        query,
        updateLocationId,
        updateMarketId,
        updateDigitalSessionId,
        updateShouldRestoreSession,
        setDirectCartFlow,
        generateTrackingId,
        fetchToken,
        fetchedToken,
        validateServiceability,
        fetchProductDetails
    } = props

    // to initiate route check use url:
    // http://localhost:3000/en/buy?lm=MjAzODQ2NDM2Ojg1MjkxMDAwMDc4MA%3D%3D

    // get access to history API from React Router
    const history = useHistory()

    // query received, this will always run on component load
    useEffect(() => {

        // set up redirecting to check availability if we do not meet criteria as checked below

        // const goToLoginPage = () => window.location.replace('https://my.xfinityprepaid.com?langPref=EN')

        // check for presence of query string
        if (query) {
            // get query string parts
            const params = new URLSearchParams(query)
            let lm = params.get('lm')
            let locationId, marketId
            const digitalSessionId = params.get('digitalSessionId')
            if (lm) {
                lm = atob(decodeURIComponent(lm))
                const parts = lm.split(':')
                locationId = parts[0]
                marketId = parts[1]
                updateLocationId(locationId)
                updateMarketId(marketId)
                console.log('locationId', locationId)
                console.log('marketId', marketId)
            }

            // PROMO - come back to
            const promo = params.get('promo')
            if (promo === 'true'){
                setDirectCartFlow(true)
                history.push('/' + props.locale + '/buy/direct-promo')
                return
            }

            if (digitalSessionId) {
                console.log('digitalSessionId', digitalSessionId)
                updateDigitalSessionId(digitalSessionId)
                updateShouldRestoreSession(true)
            }

            generateTrackingId()
            fetchToken()
        }

    }, [query]) // eslint-disable-line

    useEffect(() => {
        if (fetchedToken) {
            validateServiceability()
            fetchProductDetails()
        }
    }, [fetchedToken, validateServiceability, fetchProductDetails])

    return (
        <div className="merchant-prepaid__check">
            <ProgressSpinner loadingText={content?.cartLoadingScreen.loadingScreenText} />
        </div>
    )


}

export default withBuyflow(RouteCheck)