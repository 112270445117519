import { useHistory } from "react-router-dom";
import withBuyflow from "../redux/connectors/buyflow";
import { digitalDataLinkClick } from "../utils/adobe-analytics";

function Header(props) {
  const history = useHistory()
  const {
        locale,
        disableLogout,
        updateLocale
    } = props

  const calculatePath = () => {
    let url = ''
    if (locale === 'es') url = window.location.pathname.replace('/es/', '/en/');
    else url = window.location.pathname.replace('/en/', '/es/');

    return url
    }

  const updateLanguage = () => {
    const newLanguage = locale === 'en'  ? 'es' : 'en';
    updateLocale(newLanguage);
    history.push(calculatePath());
  };

  const handleButtonClick = () => {
    updateLanguage();
  };
  const logoutURL="https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
  return (
    <div className="header" data-testid="header">
      <div className="header__width-constraint">
        <div className="header__left">
          <div className="xfinity-logo">
            <span className="vh">xfinity</span>
          </div>
        </div>
        <nav className="header__right" aria-label="Utility Navigation">
          <div className="header-language-switch">
            <button type="button" onClick={() => handleButtonClick()} data-testid="language-button" data-tracking={digitalDataLinkClick('language', calculatePath(), locale === 'en' ? 'Español' : 'English')}>{locale === 'en' ? 'Español' : 'English'}</button>
            {!disableLogout &&
              <a className="header-language-switch-logout" href={logoutURL} data-tracking={digitalDataLinkClick('logout', 'https://oauth.xfinity.com/oauth/sp-logout?client_id=xfinity_prepaid_digital', 'Log out')}>Log out</a>
            }
          </div>
        </nav>
      </div>
    </div>
  )
}

export default withBuyflow(Header)
