import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const StatusCircle = ({daysLeft, strokeColor, tooltipMessage, radius, stroke, showTooltip}) => {
    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const [uiStrokeColor, setUiStrokeColor] = useState('#47C684')
    const [displayNumber, setDisplayNumber] = useState(0)
    const [progress, setProgress] = useState(0)
    const [strokeDashOffset, setStrokeDashOffset] = useState(circumference)
    const [isPaused, setIsPaused] = useState(false)

    const startProgress = (initialProgress, initialDisplayNumber) => {
        // this.setState({ progress: 0, strokeDashOffset: 258, displayNumber: 0 })
        setProgress(0)
        setStrokeDashOffset(circumference)
        setDisplayNumber(0)

        const interval = setInterval(() => {
            initialProgress++
            setProgress(initialProgress)
            setStrokeDashOffset(circumference - initialProgress / 100 * circumference)
            if (initialProgress === 100) {
                clearInterval(interval)
            }
        }, 7)
        const countRate = 100 / daysLeft * 7
        const numberInterval = setInterval(() => {
            initialDisplayNumber++
            setDisplayNumber(initialDisplayNumber)
            if (initialDisplayNumber === daysLeft) {
                clearInterval(numberInterval)
            }
        }, countRate)
    }

    const warningBtn = useRef<HTMLButtonElement | null>(null)
    const focusWarningBtn = () => {
        if (warningBtn !== null && warningBtn.current !== null) {
            warningBtn.current.focus()
        }
    }

    const renderHsdTooltip = () => {
        if (daysLeft > 0 && daysLeft <= 10 && showTooltip) {
            return (
                <React.Fragment>
                    <button data-testid="warning-01" className="icon-warning-tooltip button-reset" ref={warningBtn} onClick={ () => { focusWarningBtn() } } data-tracking={digitalDataButtonClick('reset', 'Warning tooltip')}>
                        <img title="warningTooltip" alt='warning tooltip button' src="/site-assets/img/icon-warning-tooltip.svg" />
                    </button>
                    <div className="toolTip">
                        {tooltipMessage}
                    </div>
                </React.Fragment>
            )
        } else return
    }

    useEffect(() => {
        if (strokeColor) {
            setUiStrokeColor(strokeColor)
        }
        let initialDisplayNumber = displayNumber
        let initialProgress = progress
        setTimeout(
            function() {
                startProgress(initialProgress, initialDisplayNumber)
            }, 1000
        )
        if (strokeColor === '#2b9cd8') {
            setIsPaused(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={`status-circle ${isPaused ? 'paused' : ''}`}>
            <svg
            height={radius * 2}
            width={radius * 2}
            >
            <circle
                stroke={uiStrokeColor}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset: strokeDashOffset }}
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                fill="transparent"
                data-testid="circle"
            />
            </svg>
            {renderHsdTooltip()}
            <div className="status-circle-content">
            <div className="status-circle-days" data-testid="status-circle-days">
                { displayNumber }
            </div>
            <div className="status-circle-label">
                Day{daysLeft > 1 ? 's' : ''}
            </div>
            </div>
        </div>
    )
}

export default StatusCircle