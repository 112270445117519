import React from 'react'
import { useState, useEffect } from 'react'

const TimeoutCircle = () => {
    const normalizedRadius = 80 - 2 * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const [uiStrokeColor] = useState('#2b9cd8')
    const [progress, setProgress] = useState(120)
    const [strokeDashOffset, setStrokeDashOffset] = useState(circumference - progress / 120 * circumference)
    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(2)

    const startProgress = (initialProgress, initialMinutes, initialSeconds) => {

        const interval = setInterval(() => {
            initialProgress--
            setProgress(initialProgress)
            setStrokeDashOffset(circumference - initialProgress / 120 * circumference)
            if (initialProgress === 0) {
                clearInterval(interval)
            }
        }, 1000 )

        const timerInterval = setInterval(() => {
            if (initialSeconds === 0) {
                if (initialMinutes > 0) {
                    initialSeconds = 59
                    initialMinutes--
                }
            } else {
                initialSeconds--
            }
            if (initialMinutes === 0 && initialSeconds === 0) {
                setMinutes(initialMinutes)
                setSeconds(initialSeconds)
                clearInterval(timerInterval)
            }
            else {
                if (initialSeconds === 59) {
                    if (initialMinutes >= 0) {
                        setMinutes(initialMinutes);
                        setSeconds(59)
                    }
                } else {
                    setSeconds(initialSeconds);
                }
            }
        }, 1000)
    }

    useEffect(() => {
        let initialProgress = progress
        let initialMinutes = minutes
        let initialSeconds = seconds
        const timer = setTimeout(
            function() {
                startProgress(initialProgress, initialMinutes, initialSeconds)
            }, 1000
        )
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="status-circle">
            <svg
                height={80 * 2}
                width={80 * 2}
                data-testid="svg"
            >
            <circle
                stroke={uiStrokeColor}
                strokeDasharray={circumference}
                style={{ strokeDashoffset: strokeDashOffset }}
                strokeWidth={2}
                r={normalizedRadius}
                cx={80}
                cy={80}
                fill="transparent"
                data-testid="circle"
            />
            </svg>
            <div className="status-circle-content">
            <div className="status-circle-label">
                Time Remaining
            </div>
            <div className="status-circle-days" data-testid="status-circle-days">
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </div>
            </div>
        </div>
    )
}

export default TimeoutCircle