/*
    Store small, common utility functions here
*/
declare global {
    interface Window { dataLayer: any[]; }
}

export const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'

]
export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const trackEvent = obj => {
    const dataLayer = window.dataLayer = window.dataLayer || []
    dataLayer.push(obj)
    if (window['logDataLayer']) {
        console.log(obj)
    }

}

// here we convert the source content into an object we can work with easier
export const contentArrayToObj = (arr: any) => {
    let obj = {}
    for (let section of arr) {
        for (const [key, value] of Object.entries(section)) obj[key] = value
    }
    return obj
}

// dictionary API has its own way of formatting data so need to iterate over it differently
export const dictionaryToObject = (arr: any) => {
    let obj = {}
    for (let section of arr) {
        let key: any;
        let value: any;
        for ([key, value] of Object.entries(section)) {
            let newObj = {}
            for (const entry of value) {
                let subkey: any;
                let subvalue: any;
                for ([subkey, subvalue] of Object.entries(entry)) newObj[subkey] = subvalue.value
            }
            obj[key] = newObj
        }
    }
    return obj
}

/* here we convert the dashboard content into an object we can work with easier */
export const dashboardToObj = (arr: any) => {
    let obj = {}
    let cleanContent = {}
    for (let section of arr) {
        const keys = Object.keys(section)
        if (keys.length > 0) {
            if (keys.length !== 1) {
                console.warn('Expected a single key in accountDashboard area, found multiple')
            }
            const key = keys[0]
            obj[key] = section[key]
        }
    }
    const areaKeys = Object.keys(obj)
    areaKeys.forEach((areaKey) => {
        obj[areaKey].forEach((areaField) => {
            const fieldKeys = Object.keys(areaField)
            const fieldKey = fieldKeys[0]
            cleanContent[areaKey] = cleanContent[areaKey] || {}
            cleanContent[areaKey][fieldKey] = areaField[fieldKey]
        })
    })
    return cleanContent
}

/* here we convert the buyflow content into an object we can work with easier */
export const buyflowToObj = (arr: any) => {
    let obj = {checkoutItems: {}, checkout: []}
    for (let section of arr) {
        for (const [key, value] of Object.entries(section)) obj[key] = value
    }
    let checkout = {}
    if (obj.checkout.length > 0) {
        for (let section of obj.checkout) {
            for (const [key, value] of Object.entries(section)) checkout[key] = value
        }
        obj.checkoutItems = checkout
    }

    return obj
}
/* here we convert the buyflow content into an object we can work with easier */
export const stripHtml = (value: string) => {
    const div = document.createElement('div')
    div.innerHTML = value
    const text = div.textContent || div.textContent || ''
    return text
}

/* money formatting */
export const formatMoney = (amount, dropTrailingZeroes?: boolean) => {
    amount = parseFloat(amount)
    let returnAmount = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    if (dropTrailingZeroes) returnAmount = returnAmount.replace(/\.00$/,'')
    return returnAmount
}

/* validate something is an object */
export const isObject = obj => (obj && Object.keys(obj).length !== 0)

/* format phone number */
export const formatPhoneNumber = phone => {
    return `${phone?.slice(0,3)}-${phone?.slice(3,6)}-${phone?.slice(6,10)}`
}

/* get user ID from Identity node */
export const idFromIdentityObj = identity => {
    let xfinityId
    if (identity.idcmtMobileVerified) xfinityId = formatPhoneNumber(identity.idcmtMobile)
    else if (identity.idcmtUid) xfinityId = identity.idcmtUid
    else if (identity.idcmtEmailVerified) xfinityId = identity.idcmtEmail
    return xfinityId
}

/* get product's associated sitecore asset content */

export const getProductContent = (productCode, productContentArray) => {
    let productContent = {}
    for (let i = 0; i < productContentArray?.length; i++) {
        if (productCode.includes(productContentArray[i].productData.name) && productContentArray[i].productData.name !== "XITV") {
            productContent = productContentArray[i]
            return productContent
        } else if(productContentArray[i].productData.name === "ECO-HSD-WIFI" && (productCode === 'HSD_030' || productCode === 'HSD_007')) {
            productContent = productContentArray[i]
            return productContent
        }
         else if (productContentArray[i].productData.name === "XITV") {
            productContent = productContentArray[i]
            return productContent
        }
    }
    return productContent
}

/* get product's associated api price */

export const getProductPrice = (productCodeToFind, availableProducts) => {
    let price = 0
        let product: any
        product = availableProducts?.find( ({ productCode}) => productCode === productCodeToFind )
        price = product?.planPrice
    return price
}
/* get product's associated Name */

export const getProductName = (productCodeToFind, availableProducts) => {
    let name = ''
        let product: any
        product = availableProducts?.find( ({ productCode}) => productCode === productCodeToFind )
        name = product?.planDesc
    return name
}

/* convert date in format "2018-10-18T12:57:36+0000" to a date object */
export const dateObjectFromApiDate = date => {
    const s = date.split(/[^0-9]/).map(str => parseInt(str))
    return Date.UTC(s[0], s[1] - 1, s[2], s[3], s[4], s[5])
}
