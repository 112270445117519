import { useEffect, useState } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import CancelMoveModal from '../../components/move/CancelMoveModal'
import { weekdays, months } from '../../utils/utils'

function isDST(d) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();
}

const MoveNotificationCard = (props) => {
    const [address, setAddress] = useState('')
    const [unit, setUnit] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [state, setState] = useState('')
    const [auxAccountNumber, setAuxAccountNumber] = useState('')
    const [moveDate, setMoveDate] = useState<any>(null)
    const [dayBeforeMoveDate, setDayBeforeMoveDate] = useState<any>(null)
    const [cancellable, setCancellable] = useState(false)

    const {
        dashboard,
        moveContent,
        scheduledMove,
        haveScheduledMove
    } = props


    useEffect(() => {
        if (scheduledMove) {
            setAddress(scheduledMove.toAddress.addressLine1.toUpperCase())
            setUnit(scheduledMove.toAddress.addressLine2 ? scheduledMove.toAddress.addressLine2.toUpperCase() : '')
            setCity(scheduledMove.toAddress.city.toUpperCase())
            setZip(scheduledMove.toAddress.zip.toUpperCase())
            setState(scheduledMove.toAddress.state.toUpperCase())
            setAuxAccountNumber(scheduledMove.auxAcctConfNo)

            setMoveDate(new Date(scheduledMove.auxAcctScheduleDate))

            const d = new Date(scheduledMove.auxAcctScheduleDate)
            d.setDate(d.getDate() - 1)
            setDayBeforeMoveDate(d)

            let cancelBy = new Date(scheduledMove.auxAcctScheduleDate)
            cancelBy.setUTCDate(cancelBy.getUTCDate())
            cancelBy.setUTCHours(isDST(cancelBy) ? 4 : 5)

            let now = new Date()
            console.log('now', now, 'cancelBy', cancelBy)
            if (now < cancelBy) {
                setCancellable(true)
            }
        }
    }, [scheduledMove]) // eslint-disable-line

    return (
        <div className="notification-card">
            <div className="main__width-constraint">
                <div className="item">
                    <div className="item-title-badge" />
                    <div className="item-title">
                        {moveContent?.moveScheduled?.moveScheduled}
                    </div>
                    <div className="item-body">
                    {moveContent?.moveScheduled?.allMoveRequests}
                    </div>
                    <br />
                    <div className="service-section">
                        <div>
                            {haveScheduledMove && <div className="service-section__row">
                                <div className="address">
                                    <b>{moveContent?.moveScheduled?.confirmationNumber}</b><br></br>
                                    {auxAccountNumber}
                                    <p></p>
                                    <b>{moveContent?.moveNow?.toDestinationAddress}</b><br></br>
                                    <div>{address}</div>
                                    {unit !== '' ? <div>{unit}</div> : null }
                                    {city}, {state} {zip}
                                    <p></p>
                                    <b>{moveContent?.moveNow?.fromPreviousAddress}</b><br></br>
                                    {dashboard?.serviceAddress?.addressLine1}
                                    {dashboard?.serviceAddress?.addressLine2 !== '' ? <div>{dashboard?.serviceAddress?.addressLine2}</div> : null }
                                    {dashboard?.serviceAddress?.city}, {dashboard?.serviceAddress?.state} {dashboard?.serviceAddress?.zip}
                                </div>
                            </div>}
                            <p></p>
                            {moveDate && <div className="service-section__row">
                                <div className="address">
                                    <b>{moveContent?.moveScheduled?.moveDate}</b><br></br>
                                    <span>
                                        {weekdays[moveDate.getUTCDay()]}, {months[moveDate.getUTCMonth()]} {moveDate.getUTCDate()}, {moveDate.getUTCFullYear()}
                                    </span>
                                </div>
                            </div>}
                            <hr />
                            {dayBeforeMoveDate && <div className="button--container">
                                <p>
                                    {moveContent?.moveScheduled?.cancellationsOfThisMove} {weekdays[dayBeforeMoveDate.getUTCDay()]}, {months[dayBeforeMoveDate.getUTCMonth()]}, {dayBeforeMoveDate.getUTCDate()}, {moveDate.getUTCFullYear()}
                                </p>
                                {cancellable &&
                                <CancelMoveModal buttonClass="link-callout" buttonText={moveContent?.moveScheduled?.canceLThiSMovE?.text} modalType='moveNotificationCancel'/>}
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withDashboard(MoveNotificationCard)