import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import ActivationServiceStatus from './ActivationServiceStatus'
import ActivationAccountName from './ActivationAccountName'
import { CSSTransition } from 'react-transition-group'
import { GlobalModal as Modal } from '../Modal'
import { trackEvent } from '../../utils/utils'
import withActivation from '../../redux/connectors/activation'
import { digitalDataButtonClick, digitalDataAccordionClick } from '../../utils/adobe-analytics'

const formatPhoneNumber = (phone) => {
    return `${phone.slice(0,3)}-${phone.slice(3,6)}-${phone.slice(6,10)}`
}

const ActivationAccordion = (props) => {
    const {items, content, ready, identity, fetchedIdentity, firstName, lastName, isVanAccount, locale, isTestFile} = props
    const [activeIndex, setActiveIndex] = useState(-1)
    const [firstTime, setFirstTime] = useState(true)
    const [tick, setTick] = useState(0)
    const forceUpdate = useCallback(() => setTick(tick + 1), [tick]);

    const history = useHistory()

    let accountIdItem, accountNameItem, deviceItem, activationItem;
    let accountIdItemIndex, accountNameItemIndex, deviceItemIndex, activationItemIndex;

    let accordionItems = [...items?.filter(item => !item.hidden)]

    // console.log('isVanAccount', isVanAccount)
    if (accordionItems.length) {
        accordionItems.forEach((item, index) => {
            item.index = index
            if (item.type === 'account-id') {
                accountIdItem = item
            } else if (item.type === 'account-name') {
                // eslint-disable-next-line
                accountNameItem = item
            } else if (item.type === 'device') {
                deviceItem = item
            } else if (item.type === 'activation') {
                activationItem = item
            }
        })
    }

    if (accountNameItem !== undefined) {
        accountIdItemIndex = 0
        accountNameItemIndex = 1
        deviceItemIndex = 2
        activationItemIndex = 3
    } else {
        accountIdItemIndex = 0
        accountNameItemIndex = null
        deviceItemIndex = 1
        activationItemIndex = 2
    }

    if (ready) {
        if ( accountIdItem!== undefined) {
        accountIdItem.title = content?.xfinityIDStep?.stepLabel?.text
        accountIdItem.content = content?.xfinityIDStep
        }
        if (accountNameItem !== undefined) {
            accountNameItem.title = content?.accountNameStep?.accountOwnersName
            accountNameItem.content = content?.accountNameStep
        }
        if ( deviceItem!== undefined) {
        deviceItem.title = content?.internetDeviceLocationStep?.stepLabelComplete?.text
        deviceItem.content = content?.internetDeviceLocationStep
        }
        if ( activationItem!== undefined) {
        activationItem.title = content?.activatePrepaidServiceStep?.stepLabel?.text
        activationItem.content = content?.activatePrepaidServiceStep
        }
    }

    useEffect(() => {
        if(!deviceItem?.readyForActivation || !deviceItem.deviceLocated) {
            deviceItem.status = 'incomplete'
            if (deviceItem.readyForActivation) {
                deviceItem.title = content?.internetDeviceLocationStep?.stepLabelIncomplete?.text
            } else {
                deviceItem.title = content?.internetDeviceLocationStep?.serviceNotActiveInternetSublabel
            }
        }
        if (firstTime) {
            setFirstTime(false)
            if (isVanAccount && !firstName && !lastName) {
                setTimeout(() => {
                    accountIdItem.status = 'complete'
                    forceUpdate()
                }, 500)
                setTimeout(() => {
                    setActiveIndex(accountNameItemIndex)
                }, 1000)

            } else if(!deviceItem.readyForActivation || !deviceItem.deviceLocated) {
                if (isVanAccount) {
                    setTimeout(() => {
                        accountNameItem.status = 'complete'
                        forceUpdate()
                    }, 500)
                } else {
                    setTimeout(() => {
                        accountIdItem.status = 'complete'
                        forceUpdate()
                    }, 500)
                }
                setTimeout(() => {
                    setActiveIndex(deviceItemIndex)
                }, 1000)
            } else {
                setTimeout(() => {
                    accountIdItem.status = 'complete'
                    forceUpdate()
                }, 500)

                setTimeout(() => {
                    deviceItem.status = 'complete'
                    sessionStorage.setItem('activationFlow', 'modem located')
                    trackEvent({
                        event: 'activation flow',
                        activateStep: 'step 1',
                        activateStepName: 'start activation',
                        activateStepDetail: 'modem located'
                    })
                    forceUpdate()
                }, 1000)

                setTimeout(() => {
                    setActiveIndex(activationItemIndex)
                }, 1500)
            }
        }
    }, [content]) // eslint-disable-line react-hooks/exhaustive-deps

    const completeDeviceStep = () => {
        deviceItem.status = 'complete'
        deviceItem.manualMacCheck = true
        deviceItem.title = content?.internetDeviceLocationStep?.stepLabelComplete?.text
        setActiveIndex(-1)
        setActiveIndex(deviceItemIndex)
        // forceUpdate()
        setTimeout(() => {
            setActiveIndex(activationItemIndex)
        }, 1000)
    }

    const completeAccountNameStep = () => {
        accountNameItem.status = 'complete'
        // console.log('deviceItem', deviceItem)
        if(deviceItem.readyForActivation && deviceItem.deviceLocated) {
            deviceItem.status = 'complete'
        }

        setActiveIndex(-1)
        if (deviceItem.status === 'complete') {
            setActiveIndex(activationItemIndex)
        } else {
            setActiveIndex(deviceItemIndex)
            setTimeout(() => {
                setActiveIndex(activationItemIndex)
            }, 1000)
        }

    }

    const renderAccordionIcon = (item, index) => {
        const itemNumber = index + 1
        if (item.status && item.status === 'complete') {
            return(
                <img src="/site-assets/img/icon-check-circle.svg" className="icon check" alt="complete"></img>
            )
        } else {
            return(
                <span className="number">
                    { itemNumber }
                </span>
            )
        }
    }
    const renderAccordionContent = (item, index) => {
        let xfinityId;
        if (fetchedIdentity) {
            if (identity.idcmtMobileVerified) {
                xfinityId = formatPhoneNumber(identity.idcmtMobile)
            } else if (identity.idcmtUid) {
                xfinityId = identity.idcmtUid
            } else if (identity.idcmtEmailVerified) {
                xfinityId = identity.idcmtEmail
            }
        }

        if (item.type === 'account-id') {
            return(!fetchedIdentity ? <div /> :
                <div className="card">
                    {!isVanAccount && <div>
                        <div className='account-block'>
                            <b>{content?.xfinityIDStep?.nameLabel?.text}</b><br />
                            {identity.idcmtFirstName} {identity.idcmtLastName}
                        </div>
                    </div>}

                    {xfinityId && <div className='account-block'>
                        <div className="modal-button-container">
                            <b className="information-label">{content?.xfinityIDStep?.xfinityIDLabel?.text}</b><br />
                            {xfinityId}
                            <Modal
                            ctaText={content?.xfinityIDStep?.aboutYourIDLinkText}
                            buttonClasses='link-callout modal-button'
                            title={content?.xfinityIDStep?.aboutYourIDModalContent?.title}
                            body={content?.xfinityIDStep?.aboutYourIDModalContent?.text}
                            />
                        </div>
                    </div>}

                    {identity.idcmtMobile &&
                    <div className='account-block'>
                        <div className="modal-button-container">
                            <b className="information-label">{content?.xfinityIDStep?.mobileNumberLabel?.text}</b><br />
                            {formatPhoneNumber(identity.idcmtMobile)}
                            {identity.idcmtMobileVerified ?
                                <div className="cima-label cima-verified">
                                    {content?.xfinityIDStep?.verifiedPhoneNumberText}
                                </div> :
                                <Modal
                                ctaText={content?.xfinityIDStep?.unverifiedPhoneNumberText}
                                buttonClasses='modal-button'
                                title={content?.xfinityIDStep?.unverifiedPhoneNumber?.title}
                                isVerificationModal='true'
                                body={content?.xfinityIDStep?.unverifiedPhoneNumber?.text}
                                /> }
                        </div>
                    </div>}

                    {identity.idcmtEmail && <div className='account-block'>
                        <div className="modal-button-container">
                            <b className="information-label">Email Address:</b><br />
                            {identity.idcmtEmail?.toLowerCase()}
                            {identity.idcmtEmailVerified ?
                                <div className="cima-label cima-verified">
                                    verified
                                </div> :
                                <Modal
                                ctaText='unverified'
                                buttonClasses='modal-button'
                                title={item.content?.unverifiedEmailModal?.title}
                                isVerificationModal='true'
                                body={item.content?.unverifiedEmailModal?.text}
                                /> }
                        </div>
                    </div>}
                </div>
            )
        } else if (item.type === 'account-name') {
            return(
                <ActivationAccountName
                    item={accountNameItem}
                    completeAccountNameStep={completeAccountNameStep}
                />
            )
        } else if (item.type === 'device') {
            return(
                <ActivationServiceStatus
                    item={item}
                    startAnimation={activeIndex === index}
                    completeDeviceStep={completeDeviceStep}
                />
            )
        } else if (item.type === 'activation') {
            let readyToActivate = deviceItem.status === 'complete'

            if (isVanAccount) {
                readyToActivate = readyToActivate && firstName && firstName?.length && lastName && lastName?.length
            }

            // console.log(readyToActivate)

            return(
                <div className="card">
                    { deviceItem.status === 'complete' && deviceItem.manualMacCheck && <p>{ item.content?.stepDescriptionAfterMacAddressInput }</p> }
                    {!isTestFile ? (
                        <button
                        className="button form-submit-button"
                        disabled={!readyToActivate}
                        onClick={() => { 
                            const flow = sessionStorage.getItem('activationFlow')
                            trackEvent({
                                event: 'activation flow',
                                activateStep: 'step 2',
                                activateStepName: 'activate service',
                                activateStepDetail: flow
                            })
                            history.replace(`/${locale}/activation/activate`)}
                        }
                        data-tracking={digitalDataButtonClick('activation-flow-step-2', item.content?.buttonText)}
                    >{ item.content?.buttonText }</button>
                    ):(
                         <button
                         data-testid="01-submitButton"
                        className="button form-submit-button"
                        disabled={false}
                        onClick={() => { 
                            const flow = sessionStorage.getItem('activationFlow')
                            trackEvent({
                                event: 'activation flow',
                                activateStep: 'step 2',
                                activateStepName: 'activate service',
                                activateStepDetail: flow
                            })
                            history?.replace(`/${locale}/activation/activate`)}
                        }
                    >{ item.content?.buttonText }</button>
                    )
        }
                </div>
            )
        }
    }

    const renderedItems = accordionItems.map((item, index) => {
        const onTitleClick = (index) => {
            if (activeIndex === index) {
                setActiveIndex(accountIdItemIndex)
            } else {
                setActiveIndex(index)
            }
        }
        const captureEnterKey = (event, index) => {
            if (event.keyCode === 13 || event.keyCode === 32) {
                onTitleClick(index)
            }
        }
        return (
            <React.Fragment key={item.id}>
                <div className="accordion__item">
                    <div
                    data-testid={`title_${item.id}`}
                    id="01-tab"
                    tabIndex={0}
                    role="tab"
                    aria-expanded={activeIndex === index}
                    aria-controls="01-panel"
                    className={`accordion__tab ${activeIndex === index ? "expanded" : ""} ${index === 0 ? "accordion__tab-first" : ""} ${index === items.length - 1 ? "accordion__tab-last" : ""} ${item.type === 'activation' && deviceItem.status === 'complete' ? "active" : ""} ${item.status === 'complete' ? "complete" : ""}`}
                    onClick={() => {
                        onTitleClick(index);
                        digitalDataAccordionClick(
                            "activation-steps",
                            item.title,
                            '.accordion-tab'
                        )
                    }}
                    onKeyDown={(e) => captureEnterKey(e, index)}
                    >
                        { renderAccordionIcon(item, index) }
                    {item.title}
                    </div>
                    <CSSTransition
                        in={activeIndex === index}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames="expand"
                    >
                        <div
                        className={`accordion__panel ${activeIndex === index ? "" : "expand-exit-done"}`}
                        id="01-panel"
                        aria-hidden={activeIndex !== index}
                        aria-labelledby="01-tab"
                        tabIndex={0}
                        role="tabpanel"
                        >
                            { renderAccordionContent(item, index)}
                        </div>
                    </CSSTransition>
                </div>
            </React.Fragment>
        );
    })

    if (ready) {
        return (
            <div className="accordion__faq-group">
                <div className="accordion accordion-refill" role="tablist" multiselectable="true">
                    {renderedItems}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default withActivation(ActivationAccordion)
