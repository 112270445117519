import DashboardLayout from '../../layouts/DashboardLayout'
import ServiceDetail from '../../components/dashboard/ServiceDetail'
import DeviceDetail from '../../components/dashboard/DeviceDetails'
import Accordion from '../../components/Accordion'
import withDashboard from '../../redux/connectors/dashboard'
import PromoBanner from '../../components/dashboard/PromoBanner'
import WinbackBanner from '../../components/refill/WinbackBanner'
import { useEffect } from 'react'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const DetailHsd = (props) => {
    const {
        hsdService,
        dashboardContent,
        dictionaryContent,
        refillContent,
        haveValidPromo,
        deactivatedAccount,
        isWinbackPromo,
        locale,
        dashboard
    } = props

    const serviceDetailContent = {dashboardContent, dictionaryContent, refillContent}
    const autoRefill = true

    useEffect(() => {
        props.updatePageClass('dashboard dashboard-home')
    }, []) // eslint-disable-line

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Internet Detail'}>
            <TrackUserData data={dashboard} />
            {haveValidPromo && !deactivatedAccount && !isWinbackPromo &&
                <PromoBanner title={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.title} body={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.body} link={`/${locale}/refill`} />
            }
            {haveValidPromo && !deactivatedAccount && isWinbackPromo &&
                <WinbackBanner />
            }
            <div>
                <ServiceDetail content={serviceDetailContent} service={hsdService} autoRefill={autoRefill} />
                <div  className="main__width-constraint">
                    <DeviceDetail content={hsdService} />
                    <Accordion items={dashboardContent?.dashboardArea?.prepaidServices?.internetSupport} link={{title: dashboardContent?.dashboardArea?.prepaidServices?.allSupport?.text, target: dashboardContent?.dashboardArea?.prepaidServices?.allSupport?.link?.target, url:dashboardContent?.dashboardArea?.prepaidServices?.allSupport?.link?.url }} title={dashboardContent?.dashboardArea?.prepaidServices?.allSupport?.text} eventObj={{event: 'accordion', accordionPage: 'dashboard myprepaid internet', accordionType: "faq", faqSubcategory: dashboardContent?.dashboardArea?.prepaidServices?.allSupport?.text}} eventDetailCategory='accordionText' />
                </div>
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}
export default withDashboard(DetailHsd)