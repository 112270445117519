import { useEffect } from 'react'
import axios from 'axios'
import ProgressSpinner from '../ProgressSpinner'
import { digitalDataAPIError } from '../../utils/adobe-analytics'

const ValidateCode = ({setStage, content, addressData, codeData, setErrorType}) => {

    const contentError = () => {
        setErrorType('content error')
        setStage('serviceable')
    }

    const connectionError = err => {
        setErrorType('connection error')
        setStage('serviceable')
    }

    const codeError = businessErrorCode => {
        let errorType
        const unavailableDeviceCodes = ['BUS_VAN_ASSIGN_004', 'BUS_VAN_ASSIGN_016', 'BUS_VAN_ASSIGN_022']
        // not a valid error code
        if (businessErrorCode === 'BUS_VAN_ASSIGN_015') errorType = 'invalid code'
        else if (unavailableDeviceCodes.indexOf(businessErrorCode) !== -1) errorType = 'unavailable code'
        else errorType = 'unknown error'
        setErrorType(errorType)
        setStage('serviceable')
    }

    const confirmCode = () => {
        setErrorType(false)
        setStage('readytobuy')
    }

    // get API address
    const VAN_SERVICE_API = process.env.REACT_APP_VAN_SERVICE_API!
    const checkCode = () => {
        // set up parameters for our api call
        const params = {
            address: {
                addressLine1: addressData.address,
                addressLine2: addressData.unit,
                city: addressData.city,
                state: addressData.state,
                zip: addressData.zip
            },
            deviceCode: codeData.code,
            language: language,
            locationId: addressData.locationId,
            phoneNo: codeData.mobileNumber,
            retailerId: "Dollar General",
            source: "Incomm",
            trackingId: addressData.trackingId
        }
        // now fire this baby
        axios.post(VAN_SERVICE_API, params, { timeout: 60000 }).then(response => {
            const data = response.data
            // failed API response
            if (data.status !== 'SUCCESS' || data.systemErrorCode) contentError()
            // other type of errors
            else if (data.businessErrorCode) codeError(data.businessErrorCode)
            // everything has worked, let's move to final screen
            else confirmCode()
        }).catch(error => {
            digitalDataAPIError('fetchTokenSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, '.merchant-prepaid__check');
            connectionError(error) 
        })
    }

    // set up which language we are using
    let language = 'English'
    const clientSide = (typeof window !== 'undefined')
    const detectLocale = () => {
        if (clientSide && window.location.pathname.indexOf('/es/') === 0) language = 'Spanish'
        else language = 'English'
    }

    // this is what fires on component load to kick everything off
    useEffect(() => {
        detectLocale()
        checkCode()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="merchant-prepaid__check">
            <ProgressSpinner loadingText={content.deviceCodeEntry.processingLoadingScreen.loadingScreenText} />
            <p className="merchant-prepaid__check--content" dangerouslySetInnerHTML={{__html: content.deviceCodeEntry.processingLoadingScreen.loadingScreenDescription}} />
        </div>
    )
}

export default ValidateCode
