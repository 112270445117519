import { createSelector } from 'reselect'

const isEmptyObject = ob => {
    for (const i in ob) return false
    return true
}

const getProductContent = state => state?.productContent
const getPrepaidPromotions = state => state?.prepaidPromotions
const getPromos = state => state?.validPromos
const getCart = state => state?.cart
const getProductDetails = state => state?.productDetails
const getTaxData = state => state?.taxData

const selectors = {

    getPrepaidInternetProduct: createSelector(
        [getProductContent, getProductDetails],
        (productContent, productDetails) => {
            if (isEmptyObject(productContent)) return null
            let internetProduct: any = {}
            internetProduct = productContent.find(product => product.productData?.lob === 'HSD')
            let tempInternetProduct = {...internetProduct}
            let tempProductData = {...tempInternetProduct.productData}
            let tempPlans = tempProductData.plans?.map(a => ({...a}))
            if (!isEmptyObject(productDetails)) {
              tempInternetProduct.actualPrice = 0
              tempPlans.forEach((plan, index) => {
                let newRate = productDetails?.products?.[0].product.plans.find(product => product.chargeCode === plan.chargeCode).rate
                tempPlans[index].rate = Number(newRate)
                tempInternetProduct.actualPrice += Number(plan.rate)
              })
              tempProductData.plans = tempPlans
              tempInternetProduct.productData = tempProductData
            }
            return tempInternetProduct
        }
    ),

    getValidPromos: createSelector(
        [getPrepaidPromotions],
        (prepaidPromotions) => {
            if (prepaidPromotions && prepaidPromotions.discounts && prepaidPromotions.discounts.length) {
                return prepaidPromotions.discounts.filter((discount) => {
                  let valid = false
                  const now = Date.now()
                  if (discount.discStartDate) {
                    const s = discount.discStartDate.split(/[^0-9]/).map(str => parseInt(str))
                    // apparently months are 0-11 in javascript, not 1-12
                    const startDate = Date.UTC(s[0], s[1] - 1, s[2], s[3], s[4], s[5])
                    if (startDate && (startDate <= now)) {
                      if (discount.discEndDate) {
                        const e = discount.discEndDate.split(/[^0-9]/)
                        const endDate = Date.UTC(e[0], e[1] - 1, e[2], e[3], e[4], e[5])
                        // valid if we have not reached the endDate yet
                        // XXX need to make sure endDate timestamp is end of the day/start of next day (midnight)
                        valid = now <= endDate
                      } else {
                        // no end date, assuming it's not expired
                        valid = true
                      }
                    }
                    valid = valid && (discount.discountDetails && discount.discountDetails.length === 1)
                    valid = valid && (discount.discountDetails[0].product === 'ECO-HSD-030')
                  }
                  return valid
                })
              }
              return []
        }
    ),

    getHsdPromoId: createSelector(
        [getPromos],
        (validPromos) => {
          if (!isEmptyObject(validPromos) && validPromos.length) {
            return validPromos[0].discId
        }
          return null
        }
    ),

    getHaveValidPromos: createSelector(
        [getPromos],
        (validPromos) => {
            if (!isEmptyObject(validPromos) && validPromos.length) {
                return validPromos.length > 0
            }
            return null
        }
    ),

    getHavePromoInCart: createSelector(
        [getCart],
        (cart) => {
            return cart?.promos && cart?.promos.length > 0
        }
    ),

    getProductsByName: createSelector(
      [getProductDetails],
      (productDetails) => {
        console.log('product details', productDetails?.products)
        if (productDetails && productDetails?.products) {
          const productMap = {}
          productDetails?.products.forEach((item) => {
            productMap[item.product.name] = item.product
          })
        return productMap
        } else return
      }
    ),

    getProductTaxDetailsByName: createSelector(
      [getTaxData],
      (taxData) => {
        const productMap = {}
        if (taxData?.products) {
          taxData.products.forEach((item) => {
            const taxDataByChargeCode = {}
            if (item.productType) {
              item.productType.forEach((type) => {
                taxDataByChargeCode[type.type] = type.vertexCodes.map((item) => {
                  return { taxCode: item.vertexCode, taxAmount: item.amt }
                })
              })
            } else {
              console.log('XXX something went wrong, tax data missing')
            }
            productMap[item.productCode] = taxDataByChargeCode
          })
        }
        return productMap
      }
    )

}

export default selectors
