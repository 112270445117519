import DashboardLayout from '../../layouts/DashboardLayout'
import { useHistory } from 'react-router-dom'
import ProgressBar from '../../components/ProgressBar'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import withDashboard from '../../redux/connectors/dashboard'
import Cart from '../../components/refill/Cart'
import { formatMoney, trackEvent, getProductPrice, getProductName } from '../../utils/utils'
import { sprintf } from 'sprintf-js'
import ProgressSpinner from '../../components/ProgressSpinner'
import { digitalDataButtonClick, digitalDataLinkClick, digitalDataAccordionClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

let timerRan = false

const RefillConfirmation = (props) => {
    const {
        locale,
        cartContent,
        refillContent,
        cartTotal,
        paymentInfo,
        cart,
        dashboard,
        currentLocale,
        fetchedDashboard,
        fetchDashboard,
        fetchAuxAccount,
        fetchedAuxAccount,
        accountId,
        fetchPaymentInfo,
        fetchedPaymentInfo,
        resetBilling,
        updateField,
        activationCode,
        pinRefill,
        havePromoInCart,
        isWinbackPromo,
        // promos,
        hsdService,
        refill,
        fetchPromos,
        fetchedPromos,
        isTestFile,
        cartCount,
        refilledPrepaidAccount
    } = props

    const [progress] = useState(100)
    const [expanded, setExpanded] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)
    const [finalizedRefill, setFinalizedRefill] = useState(false)    

    //TODO: switch between flows, depending on which tab we arrived from
    const [isPinFlow] = useState(!!activationCode && !!pinRefill)
    /* const updateProgress = (newProgress) => {
        setProgress(newProgress)
    } */

    const history = useHistory()
    const [products, setProducts] = useState<any[]>([]);

    const toggle = () => {
        setExpanded(!expanded)
        console.log(expanded)
    }

    const cartComponentContent = { cartContent, refillContent }

    useEffect(() => {
        if(isTestFile){
            timerRan = true
        }
        if (fetchedDashboard && fetchedPaymentInfo && fetchedPromos && fetchedAuxAccount && timerRan) {
            setFinalizedRefill(true)
        }

    sessionStorage.setItem("refill-completed","true")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedDashboard, fetchedPaymentInfo, fetchedPromos, fetchedAuxAccount])

    useEffect(() => {
        if (!fetchedDashboard) {
            history.replace(`/${locale}/dashboard`)
            return;
        }
        setOrderTotal(cartTotal)

        setTimeout(() => {
            fetchDashboard({accountId})
            fetchPaymentInfo({accountId, phoneNumber: dashboard.customerInfo.phoneNumber})
            fetchPromos({
                accountId,
                locationId: dashboard.customerInfo.locationId,
            })
            fetchAuxAccount({
                accountId,
                auxConfNo: '',
                checkMoveEligibility: true,
                checkReconnectEligibility: true
            })
            timerRan = true
        }, 500)

        updateField({field: 'refilledPrepaidAccount', value: false})
        resetBilling()

        if (isPinFlow) {
            trackEvent({
                event: 'pin refill flow',
                refillStep: 'step 4',
                refillStepName: 'order complete',
                refillStepDetail: 'redeem code'
            })
            updateField({field: 'redeemedRefill', value: false})
        } else {
            trackEvent({
                event: 'refill flow',
                refillStep: 'step 4',
                refillStepName: 'order complete',
                refillStepDetail: paymentInfo ? 'saved debit credit' : 'debit credit'
            })

            let products: any
            products = []
            if (cart.internet !== '' && cart.internet !== null && dashboard.availablePlans && dashboard.availablePlans !== null) {
                let toggleProduct: any
                    toggleProduct = {}
                    toggleProduct.name = getProductName(cart.internet, dashboard.availablePlans)
                    toggleProduct.id = cart.internet
                    toggleProduct.price = getProductPrice(cart.internet, dashboard.availablePlans)
                    toggleProduct.dimension1 = 'refill'
                    toggleProduct.dimension4 = 'website'
                    toggleProduct.quantity = cart.internetRefills
                    products.push(toggleProduct)

                /* if (havePromoInCart && promos.discounts && promos.discounts.length > 0) {
                    let promoProduct: any
                    promoProduct = {}
                    promoProduct.name = promos.discounts[0].discShortDesc
                    promoProduct.id = promos.discounts[0].discId
                    promoProduct.price = promos.discounts[0].discPrice
                    promoProduct.dimension1 = 'refill'
                    promoProduct.dimension4 = 'website'
                    products.push(promoProduct)

                } */
            }
            if (cart.packages.length > 0 && dashboard.availablePlans && dashboard.availablePlans !== null) {
                for (let i=0; cart.packages.length > i; i++) {
                    let toggleProduct: any
                    toggleProduct = {}
                    toggleProduct.name = getProductName(cart.packages[i], dashboard.availablePlans)
                    toggleProduct.id = cart.packages[i]
                    toggleProduct.price = getProductPrice(cart.packages[i], dashboard.availablePlans)
                    toggleProduct.dimension1 = 'refill'
                    toggleProduct.dimension4 = 'website'
                    toggleProduct.quantity = 1
                    products.push(toggleProduct)
                }
            }
            if (cart.addOns.length > 0 && dashboard.availablePlans && dashboard.availablePlans !== null ) {
                for (let i=0; cart.addOns.length > i; i++) {
                    let toggleProduct: any
                    toggleProduct = {}
                    toggleProduct.name = getProductName(cart.addOns[i], dashboard.availablePlans)
                    toggleProduct.id = cart.addOns[i]
                    toggleProduct.price = getProductPrice(cart.addOns[i], dashboard.availablePlans)
                    toggleProduct.dimension1 = 'refill'
                    toggleProduct.dimension4 = 'website'
                    toggleProduct.quantity = 1
                    products.push(toggleProduct)
                }
            }
            setProducts(products);
            trackEvent({
                event: 'purchase',
                ecommerce: {
                purchase: {
                    actionField: {
                    id: refill?.orderDetails?.orderNumber,
                    revenue: cartTotal
                    },
                    products
                }
                }
            })
        }
        if (havePromoInCart) {
            trackEvent({
                event: 'refill info',
                refillStep: 'step 4',
                refillStepName: 'order complete',
                refillEvent: isWinbackPromo ? 'offer winback' : 'offer acquisition',
                refillDetail: 'purchase'
            })
        }
        if (dashboard?.acpDetail?.acpStatus !== 'Approved' && dashboard?.acpDetail?.acpStatus !== 'Denied') {
            trackEvent({
                event: 'refill info',
                refillStep: 'step 4',
                refillStepName: 'order complete',
                refillEvent: 'acp learn tile',
                refillDetail: 'impression'
            })
        }

    }, []) // eslint-disable-line

    const handleClick = (e) => {
        e.preventDefault()
        trackEvent({
            event: 'refill info',
            refillStep: 'step 4',
            refillStepName: 'order complete',
            refillEvent: 'next step cta',
            refillDetail: props.refillContent.refillFlow.cart.continueToMyAccount.text
        })
        history.push(`/${props.currentLocale}/dashboard`)
    }

    return (
      <DashboardLayout locale={props.locale} pageTitle={"Confirmation"}>
        <TrackUserData data={dashboard} />
        <TrackPurchaseData
          offerIncluded={havePromoInCart}
          price={cartTotal}
          productID={cart?.internet}
          productType={"prepaid-refill"}
          quantity={cartCount}
          transactionID={refill?.orderDetails?.orderNumber}
          screenName="confirmation"
        />
        {props.refillContent.loaded &&
        props.fetchedDashboard &&
        finalizedRefill ? (
          <div>
            <ProgressBar title={"Refill successful"} percent={progress} />

            <div className="main__width-constraint refill refill__confirmation">
              <p></p>
              <h1 className="page__title">
                {props.refillContent.refillFlow.cart.thankYouForRefilling}
              </h1>
              <p>
                <span>
                  {props.refillContent.refillFlow.cart.weveSentAVerification}
                </span>
              </p>
              <p></p>
              <div
                className="accordion order-confirm-accordion"
                role="tablist"
                multiselectable="true"
              >
                <div className="accordion__item">
                  <div
                    id="'01-tab'"
                    className={
                      expanded
                        ? "accordion__tab order-confirm-accordion__tab expanded"
                        : "accordion__tab order-confirm-accordion__tab"
                    }
                    role="tab"
                    onClick={() => {
                      toggle();
                      digitalDataAccordionClick(
                        "order-confirmation",
                        props.refillContent.refillFlow.cart.orderDetails +
                          " $" +
                          formatMoney(orderTotal),
                        ".accordion__tab"
                      );
                    }}
                  >
                    <h2 className="order-confirm-accordion__title">
                      {props.refillContent.refillFlow.cart.orderDetails}
                      {!isPinFlow && (
                        <b>
                          <sup>$</sup>
                          {formatMoney(orderTotal)}
                        </b>
                      )}
                    </h2>

                    {expanded ? (
                      <button
                        className="button button--link"
                        data-tracking={digitalDataButtonClick(
                          "close-details",
                          props.refillContent.refillFlow.cart.closeDetails.text
                        )}
                      >
                        {props.refillContent.refillFlow.cart.closeDetails.text}
                      </button>
                    ) : (
                      <button
                        className="button button--link"
                        data-tracking={digitalDataButtonClick(
                          "view-details",
                          props.refillContent.refillFlow.orderConfirmation
                            .viewDetails?.text
                        )}
                      >
                        {
                          props.refillContent.refillFlow.orderConfirmation
                            .viewDetails?.text
                        }
                      </button>
                    )}

                    <div className="accordion__icon">
                      <span className="line" />
                      <span className="line" />
                    </div>
                  </div>

                  {isPinFlow ? (
                    <div
                      id="'01-panel'"
                      className={`accordion__panel ${
                        expanded ? "" : "expand-exit-done"
                      }`}
                      tabIndex={0}
                      role="tabpanel"
                    >
                      <div className="card">
                        <div className="card">
                          <b>{props.refillContent.refillFlow.cart.orderDate}</b>
                          <br></br>
                          {sprintf(
                            `%d/%d/%d`,
                            new Date().getMonth() + 1,
                            new Date().getDate(),
                            new Date().getFullYear()
                          )}
                          <hr></hr>
                          <b>
                            {
                              props.refillContent.refillFlow.cart
                                .refillExpirationDates
                            }
                          </b>
                          <p className="l1 subtitle">Prepaid Internet</p>
                          <h3>{hsdService.formattedExpirationDate}</h3>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="'01-panel'"
                      className={`accordion__panel ${
                        expanded ? "" : "expand-exit-done"
                      }`}
                      tabIndex={0}
                      role="tabpanel"
                    >
                      <Cart content={cartComponentContent} />
                    </div>
                  )}
                </div>
              </div>
              {cart?.internet !== "HSD_007" ? (
                <div className="card">
                  {
                    props.refillContent.refillFlow.refillHsdXitv
                      .enrollInAutoRefillNow
                  }
                  <a
                    href={
                      window.location.origin +
                      `/${currentLocale}/dashboard/auto-refill`
                    }
                    className="button button--link float-right"
                    data-tracking={digitalDataLinkClick(
                      "auto-refill",
                      `/${currentLocale}/dashboard/auto-refill`,
                      props.refillContent.refillFlow.refillHsdXitv.enroll.text
                    )}
                  >
                    {props.refillContent.refillFlow.refillHsdXitv.enroll.text}
                  </a>
                  <hr></hr>

                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          props.refillContent.refillFlow.refillHsdXitv
                            .keepYourServicesActive,
                      }}
                    ></span>
                    {/* <button className="button button--link">{props.refillContent.refillFlow.refillHsdXitv.termsAndConditions.text}</button> */}
                  </p>
                </div>
              ) : null}
              <div className="button--container">
                <button
                  className="button"
                  onClick={handleClick}
                  data-tracking={digitalDataButtonClick(
                    "continue-to-my-account",
                    props.refillContent.refillFlow.cart.continueToMyAccount.text
                  )}
                >
                  {props.refillContent.refillFlow.cart.continueToMyAccount.text}
                </button>{" "}
              </div>
            </div>
          </div>
        ) : (
          <ProgressSpinner loadingText={"Finalizing refill ..."} />
        )}
        <TrackPageComplete />
      </DashboardLayout>
    );
}


export default withDashboard(RefillConfirmation)