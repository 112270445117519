import { useState, useEffect } from 'react'
import AddressCheck from './AddressCheck'
import ValidateAddress from './ValidateAddress'
import DeviceCode from './DeviceCode'
import MultiAddressList from './MultiAddressList'
import ExistingAccount from './ExistingAccount'
import Unserviceable from './Unserviceable'
import ValidateCode from './ValidateCode'
import ReadyToBuy from './ReadyToBuy'

export const CheckAvailability = ({isMove, content, isTestFile}) => {

    const [stage, setStage] = useState('addressCheck')
    const [addressData, setAddressData] = useState({})
    const [addressList, setAddressList] = useState([])
    const [searchAddress, setSearchAddress] = useState({})
    const [selectedLocation, setSelectedLocation] = useState({})
    const [errorType, setErrorType] = useState(false)
    const [codeData, setCodeData] = useState(false)

    // for any general updates that need to happen when the stage is changed
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [stage])

    //TODO: determine the needed differences between the dollar general UX / messaging & the move flow, apply the changes with the isMove flag
    
    switch (stage) {

        case 'addressCheck':
            return <AddressCheck isTestFile={isTestFile} isMove={isMove} setStage={setStage} content={content} addressData={addressData} searchAddress={searchAddress} setSearchAddress={setSearchAddress} setAddressData={setAddressData} setSelectedLocation={setSelectedLocation} errorType={errorType} setErrorType={setErrorType} data-testid="address-check" />

        case 'validateAddress':
            return <ValidateAddress isTestFile={isTestFile} isMove={isMove} setStage={setStage} setAddressList={setAddressList} content={content} searchAddress={searchAddress} addressData={addressData} setAddressData={setAddressData} selectedLocation={selectedLocation} setErrorType={setErrorType} />

        case 'addressMultiUnit':
            return <MultiAddressList setStage={setStage} content={content} setSearchAddress={setSearchAddress} setAddressData={setAddressData} setSelectedLocation={setSelectedLocation} addressList={addressList} />

        case 'serviceable':
            return <DeviceCode setStage={setStage} content={content} addressData={addressData} codeData={codeData} setCodeData={setCodeData} errorType={errorType} setErrorType={setErrorType} />

        case 'unserviceable':
            return <Unserviceable isMove={isMove} setStage={setStage} content={content} addressData={addressData} />

        case 'existingPrepaidAccount':
            return <ExistingAccount isMove={isMove} setStage={setStage} content={content} accountType="prepaid" addressData={addressData} />

        case 'existingPostpaidAccount':
            return <ExistingAccount isMove={isMove} setStage={setStage} content={content} accountType="postpaid" addressData={addressData} />

        case 'codeCheckSubmit':
            return <ValidateCode setStage={setStage} content={content} addressData={addressData} codeData={codeData} setErrorType={setErrorType} />

        case 'readytobuy':
            return <ReadyToBuy content={content} addressData={addressData} />

        default:
            return null

    }

    
}