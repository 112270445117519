import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import NotificationBanner from '../../components/NotificationBanner'
import { useEffect } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const PermissionError = (props) => {
    const { dashboard, dashboardContent, dictionaryContent } = props; 

    const renderNotificationBanner = () => {
        return (
            // eslint-disable-next-line
            // XXX TODO This is in activation content currently
            <NotificationBanner text={dashboardContent?.accountInfoArea?.insufficientPermissionError?.alert?.message} displayStyle="alert" modal="true" />
        )
    }
    const renderPage = () => {
        return (
            <div className='permission-error'>
                <div className="permission-error__content">
                    <h1 className="page__title">
                        { /* XXX TODO This is in activation content currently */}
                        {dashboardContent?.accountInfoArea?.insufficientPermissionError?.title}
                    </h1>
                    { /* XXX TODO This is in activation content currently */}
                    <div dangerouslySetInnerHTML={{__html: dashboardContent?.accountInfoArea?.insufficientPermissionError?.description}} />

                    <dl>
                        <dt>{dictionaryContent?.global?.name}</dt>
                        <dd>{dashboard?.customerInfo?.firstName} {dashboard?.customerInfo?.lastName}</dd>
                        <dt>{dictionaryContent?.global?.xfinityID}</dt>
                        <dd>{dashboard?.cimaDetails?.loginID}</dd>
                        <dt>{dictionaryContent?.formLabels?.mobileNumber}</dt>
                        <dd>{dashboard?.customerInfo?.phoneNumber}</dd>
                        <dt>{dictionaryContent?.formLabels?.emailAddress}</dt>
                        <dd>{dashboard?.customerInfo?.emailAddress}</dd>
                    </dl>
                </div>
            </div>
        )
    }

    useEffect(() => {
        props.updatePageClass('permission-error')
    }, []) // eslint-disable-line

    return (

        <DashboardLayout locale={props.locale} pageTitle={'Permission Error'}>
            <TrackUserData data={dashboard} />
            {props.dashboardContent.loaded && props.dictionaryContent.loaded &&
            <React.Fragment>
                <div className="main__width-constraint">
                    {renderNotificationBanner()}
                    {renderPage()}
                </div>
            </React.Fragment>}
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(PermissionError)