import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BuyFlowLayout from "../../layouts/BuyFlowLayout";
import { isObject } from "../../utils/utils";
import withBuyflow from "../../redux/connectors/buyflow";
import Cart from "../../components/buyflow/Cart";
import StickyCart from "../../components/buyflow/StickyCart";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { trackEvent } from "../../utils/utils";
import Payment from "../../components/Payment";
import ProgressSpinner from "../../components/ProgressSpinner";
import PaymentComponent from "../../components/PaymentComponent";
import NotificationBanner from "../../components/NotificationBanner";
import { errorForCode } from "../../lib/errors";
import { digitalDataButtonClick } from "../../utils/adobe-analytics";
import TrackPageComplete from "../../utils/TrackPageComplete";
import TrackUserData from "../../utils/TrackUserData";
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const BuyflowOrderDetails = (props) => {
  const {
    locale,
    cartPages,
    checkoutContent,
    cartContent,
    updateProgress,
    showFreeShipping,
    shippingRate,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    prepaidInternetProduct,
    trackingId,
    digitalSession,
    updateCpcResponse,
    getPaymentId,
    paymentId,
    addPaymentInstrument,
    addPaymentInstrumentFailed,
    paymentInstrumentErrorCode,
    currentLocale,
    addedPaymentInstrument,
    backToOrder,
    serviceabilityData,
    cartTotal
  } = props;

  const [pageTitle, setPageTitle] = useState("Xfinity Prepaid");
  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [cpcProcessing, setCpcProcessing] = useState(false);
  const [loadCPCForm, setLoadCPCForm] = useState(false);
  const [startCPCEvents, setStartCPCEvents] = useState(false);
  const [cpcToken, setCpcToken] = useState();
  const [apiError, setApiError] = useState("");
  const [isCPCLoaded, setCPCLoader] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [isapiError, setErrorApi] = useState(true);

  const history = useHistory();
  const { errors } = useForm();

  const updateFormValid = (valid) => {
    updateFormStatus(valid);
    if (valid) {
      setProcessNext(false);
    }
  };
  const onUpdateField = (e) => {
    if (
      e.action === "CPC_INFO_EVENT" &&
      e.dataLayerKey === "CpcCardTermsAndConditionSelected"
    ) {
      const termsAndConditons =
        e.data?.event[0]?.eventInfo?.CpcCardTermsAndConditionSelected;
      if (termsAndConditons) {
        sessionStorage.setItem(
          "cpcTermsAndConditions",
          termsAndConditons.currentValue
        );
      }
    }
  };

  useEffect(() => {
    //This retrieves the payment ID for the buyflow, as we are not fetching dashboard
    getPaymentId();
    console.log("paymentID inside order-details", paymentId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStartCPCEvents(true);
  }, []);
  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);

  useEffect(() => {
    if (isObject(cartPages))
    setProducts([]);
      setPageTitle("Payment and Delivery - Xfinity Prepaid");
    updateProgress({
      title: checkoutContent?.paymentAndDelivery?.statusLabel,
      progress: 80,
    });
    let products: any;
    products = [];
    for (
      let i = 0;
      i < prepaidInternetProduct?.productData?.plans?.length;
      i++
    ) {
      let product: any;
      product = {};
      product.name =
        prepaidInternetProduct?.productData?.plans[i]?.planDisplayText;
      product.id = prepaidInternetProduct?.productData?.plans[i]?.plan;
      product.sku = prepaidInternetProduct?.productData?.plans[i].sku
      product.price = prepaidInternetProduct?.productData?.plans[i]?.rate;
      product.dimension1 = "buy";
      product.dimension4 = "website";
      product.quantity = 1;
      products.push(product);
    }
    setProducts(products);
            
    trackEvent({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 6 },
          products,
        },
      },
    });
  }, [cartPages]); // eslint-disable-line react-hooks/exhaustive-deps
  

  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);

  useEffect(() => {
    if (addPaymentInstrumentFailed) {
      const error = errorForCode(
        paymentInstrumentErrorCode || "",
        currentLocale
      );
      if (isapiError && isCPCLoaded && cpcProcessing) {
        setApiError(error);
      }
    }
  }, [addPaymentInstrumentFailed,isCPCLoaded,cpcProcessing]); // eslint-disable-line

  useEffect(() => {
    if (addedPaymentInstrument && !backToOrder) {
      history.push("/" + locale + "/buy/order-review");
    }
  }, [addedPaymentInstrument]); // eslint-disable-line

  useEffect(() => {  
    if ((cpcProcessing && isCPCLoaded && apiError !== "")) {
      let errorReloadCount: any;
      errorReloadCount = sessionStorage.getItem("errorReloadCount");
      if (errorReloadCount == null) {
        errorReloadCount = 1;
      } else {
        errorReloadCount = Number(errorReloadCount) + 1;
      }
      sessionStorage.setItem("errorReloadCount", errorReloadCount);
      sessionStorage.setItem("currentURL",window.location.pathname);
      setTimeout(
        function () {
          setCpcProcessing(false);
          setCPCLoader(false);
          setApiError("");
          setErrorApi(false);   
        },
        30000
      );
      
        const isNewCustomer = sessionStorage.getItem("isNewCustomer");
        if (isNewCustomer ? errorReloadCount >= 5 : errorReloadCount >= 7) {
          sessionStorage.removeItem("errorReloadCount");
          const prepaidURL:any = process.env.REACT_APP_SHOPBASE_URL
          window.location.replace(prepaidURL)
        }
    } else {
      setErrorApi(true);
    } 
  }, [cpcProcessing,isCPCLoaded, apiError]);


  const handleNext = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setProcessNext(true);
    console.log("handleNext");
    if (formValid) {
      console.log("validated is true");
    } else {
      console.log("onError", errors);
    }
  };

  const paymentProcessing = (value) => {
    setCpcProcessing(value);
  };

  const paymentProcessed = (response) => {
    console.log("CPC token received: ", response);
    updateCpcResponse(response);
    setCpcToken(response?.cpcData?.cardDetails?.token);
    console.log("cpcToken val", cpcToken);
    onUpdateField(response);
    const enrollInAutoRefillSession =
      sessionStorage.getItem("enrollInAutoRefill");
    const savePaymentChekboxVal =
    sessionStorage.getItem("cpcTermsAndConditions");
    const enrollInAutoRefill =
      enrollInAutoRefillSession === "true" && savePaymentChekboxVal === "true" ? true : false;
    const payload = {
      custInfo: {
        accountId: null,
        firstName: response?.paymentInfo?.firstName,
        lastName: response?.paymentInfo?.lastName,
        addressLine1: response?.paymentInfo?.address,
        addressLine2: response?.paymentInfo?.addressLine2,
        city: response?.paymentInfo?.city,
        state: response?.paymentInfo?.state,
        zip: response?.paymentInfo?.zipCode,
      },
      enrollAutoPayment: enrollInAutoRefill,
      processingNetwork: "CREDIT",
      addInstrumentToWallet: "true",
      paymentInstToken: response?.cpcData?.cardDetails?.token,
      isCPC: true,
      expirationDate: response?.cpcData?.cardDetails?.expirationDate,
      cardType: response?.cpcData?.cardDetails?.cardType,
      cardNumber: response?.cpcData?.cardDetails?.cardLast4Digits,
      prepaidPaymentID: response?.cpcData?.customerId,
      savePayment:
        sessionStorage.getItem("cpcTermsAndConditions") === "true"
          ? true
          : false,
    };
    addPaymentInstrument(payload);
  };

  const paymentError = (response) => {
    let errorType;
    let errorDetail;
    if (response.type === "form") {
      errorType = "error validation";
      errorDetail = response.message;
    } else {
      errorType = "error";
      errorDetail = response.messageDetail;
    }
    if (response.type !== "form") {
      trackEvent({
        event: "buyflow info",
        buyStep: "step 3",
        buyStepName: "payment",
        buyEvent: errorType,
        buyDetail: errorDetail,
      });
    }
  };

  const paymentLoaded = (value) => {
    setCPCLoader(value);
    let errorReloadCount:any = sessionStorage.getItem("errorReloadCount");
    if(errorReloadCount >=2){
    setTimeout(
      function () {
        setApiError("");
      },
      10000
    );
  }
  };

  const onBlurField = (e) => {
    if (e.target.name === "enrollInAutoRefill") {
      sessionStorage.setItem("enrollInAutoRefill", e.target.checked);
    }
  };
  return (
    <BuyFlowLayout locale={locale}>
      <TrackUserData data={serviceabilityData} />
      <TrackPurchaseData offerIncluded={false} price={cartTotal} productID={products.length > 0 ? products[0].sku : ''} productType={'prepaid-kit'} quantity={1} transactionID={""} screenName="payment"/>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="main_width-constraint clear">
        {apiError ? (
          <NotificationBanner
            text={apiError}
            displayStyle="alert"
            modal="true"
          />
        ) : null}
        <div className="page__header">
          <h1 className="page__title">
            {checkoutContent?.paymentAndDelivery?.paymentAndDeliveryPageTitle}
          </h1>
        </div>
        <div className="main__desktop-body">
          <form id="payment-form">
            <div className="main__width-constraint">
              <div className="card">
                <div className={cpcProcessing || isCPCLoaded ? "" : "vh"}>
                  <ProgressSpinner
                    loadingText={isCPCLoaded ? "Loading..." : "Processing..."}
                  />
                </div>
                <div className={cpcProcessing ? "vh" : ""}>
                  {loadCPCForm && paymentId && (
                    <PaymentComponent cpcPageType="CardOnly" />
                  )}

                  {paymentId && (
                    <Payment
                      addressLine1={addressLine1}
                      addressLine2={addressLine2}
                      city={city}
                      zip={zip}
                      UsState={state}
                      trackingId={trackingId}
                      custGuid={paymentId}
                      firstName={digitalSession?.name?.firstName}
                      lastName={digitalSession?.name?.lastName}
                      processNext={processNext}
                      paymentProcessing={paymentProcessing}
                      paymentProcessed={paymentProcessed}
                      updateFormValid={updateFormValid}
                      showAddress={false}
                      channelName={"PREPAID_LITE"}
                      sourceSystemId={"PREPAID_LITE_CPC"}
                      enableFraudManager={false}
                      cpcMessageDisplayLocation={"top"}
                      displayAddressOverride={true}
                      displayAutoPayEnroll={false}
                      displayStoredPaymentOption={
                        sessionStorage.getItem("isNewCustomer") === "true"
                          ? true
                          : false
                      }
                      customerClass={"residential"}
                      paymentToken={cpcToken ? cpcToken : ""}
                      loadCPCForm={loadCPCForm}
                      onUpdateField={onUpdateField}
                      storePaymentInstrumentLongTerm={
                        sessionStorage.getItem("cpcTermsAndConditions") ===
                        "true"
                          ? true
                          : false
                      }
                      cpcPageTypeVal={"CardOnly"}
                      paymentError={paymentError}
                      paymentLoaded={paymentLoaded}
                    />
                  )}
                </div>
              </div>
              {sessionStorage.getItem("isNewCustomer") === "true" ? (
                <div className="auto-refill-selection">
                  <div className="card__header">
                    <h2 className="card__header-title">
                      {
                        checkoutContent?.paymentAndDelivery
                          ?.enrollInAutoRefillNow
                      }
                    </h2>
                  </div>
                  <div className="checkbox">
                    <input
                      id="enrollInAutoRefill"
                      data-testid="enroll-auto"
                      type="checkbox"
                      className="checkbox__input"
                      name="enrollInAutoRefill"
                      disabled={
                        sessionStorage.getItem("cpcTermsAndConditions") ===
                        "true"
                          ? false
                          : true
                      }
                      onChange={(e) => onBlurField(e)}
                    />
                    <label
                      className="checkbox__label"
                      htmlFor="enrollInAutoRefill"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            checkoutContent?.paymentAndDelivery
                              ?.keepYourServicesActive,
                        }}
                      ></p>
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="card">
                <fieldset form="shipping-payment">
                  <div className="card__header">
                    <legend className="card__header-title">
                      {
                        checkoutContent?.paymentAndDelivery
                          ?.shippingAddressSectionLabel
                      }
                    </legend>
                  </div>
                  <table className="totals">
                    <tbody>
                      <tr className="line-item">
                        {!showFreeShipping ? (
                          <th
                            className="line-item__label"
                            v-html="content.shippingChargeStatement"
                          >
                            {
                              checkoutContent?.paymentAndDelivery
                                ?.shippingChargeStatement
                            }
                          </th>
                        ) : null}
                        {showFreeShipping ? (
                          <th
                            className="line-item__label"
                            v-html="content.shippingChargeStatement"
                          >
                            {
                              checkoutContent.orderReview
                                ?.freeShippingChargeStatement
                            }
                          </th>
                        ) : null}
                        {!showFreeShipping ? (
                          <td className="line-item__price">
                            <span className="price-full">{shippingRate}</span>
                          </td>
                        ) : null}
                        {showFreeShipping ? (
                          <td className="line-item__price">
                            <span className="price-full">
                              {cartContent?.cartModule?.freeShippingCartText}
                            </span>
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                  <div className="shown-if-checked">
                    <div>{addressLine1}</div>
                    {addressLine2 ? <div>{addressLine2}</div> : null}
                    <div>
                      {city}, {state} {zip}
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="cart__display-large">
                <hr />
                <div className="card card--clear card--small card--right">
                  <input
                    value={cartContent?.cartModule?.nextButtonLabel}
                    type="button"
                    data-testid="handle-next"
                    className="button button--inline next-button"
                    onClick={(e) => handleNext(e)}
                    disabled={!formValid}
                    data-tracking={digitalDataButtonClick(
                      "handle-next",
                      cartContent?.cartModule?.nextButtonLabel
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="cart__display-large main__desktop-sidebar-right">
          <Cart showDetails={true} />
        </div>
      </div>
      <div className="cart__display-small-medium">
        <StickyCart
          handleNext={handleNext}
          showDetails={true}
          nextDisabled={!formValid}
        />
      </div>
      <TrackPageComplete />
    </BuyFlowLayout>
  );
};

export default withBuyflow(BuyflowOrderDetails);
