import axios from 'axios';

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const clientSide = typeof window !== 'undefined'

if (clientSide) {
    if (!window.location.host.includes('local')) {
        apiBaseUrl = `${window.location.protocol}//${window.location.hostname}`
    }
}

// console.log('⚡️ apiBaseUrl', apiBaseUrl)

export const fetchToken = async ({trackingId}) => {
    const url = `${apiBaseUrl}/token?trackingId=${trackingId}`
    await axios.get(url)

    // console.log('token response', response)

    // XXX Need to get the token out of the cookie. BUT, may not be needed for anything.
    // X-XSRF-TOKEN header is added automatically by axios once the cookie is set.
    const xsrfToken = null

    return {xsrfToken}
}

export const fetchEnglishLitepayContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/my.xfinityprepaid/litepay`
    const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER
    const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD

    const response = await axios.get(url, {
        headers: (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) ? {
            Authorization: `Basic ${btoa(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD)}`
        } : {}
    })
    // console.log('content response', response.data)
    return response.data
}

export const fetchSpanishLitepayContent = async ({trackingId}) => {
    const url = `${apiBaseUrl}/-/api/es/my.xfinityprepaid/litepay`
    const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER
    const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD

    const response = await axios.get(url, {
        headers: (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) ? {
            Authorization: `Basic ${btoa(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD)}`
        } : {}
    })
    // console.log('content response', response.data)
    return response.data
}

export const fetchCustomerDashboard = async ({trackingId, phoneNumber, zipCode}) => {
    const url = `${apiBaseUrl}/secure/v1/customermanagement/fetchCustomerDashboard?trackingId=${trackingId}`

    const data = {
        billerLookup: false,
        flow: "EZREFILL",
        getAvailablePlans: true,
        getACPDetails: true,
        getCustomerInfo: true,
        getDevicesInfo: true,
        getServiceAddress: true,
        houseNumber: "",
        phone: phoneNumber,
        zip: zipCode
    }

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId
        }
    })

    return response.data
};

export const fetchTaxData = async ({geoCode, trackingId}) => {
    const products = [
            {
            productCode: 'HSD_030',
            price: 45
            }
        ]
    const data = {
        source: 'DIGITAL2.0', // 'CTP',
        status: '',
        busErrorCode: '',
        message: '',
        sysErrorCode: '',
        geoCode, // state.dashboardData.customerInfo.geoCode,
        trackingId,
        products
    }

    const response =  await axios.post(`${apiBaseUrl}/oauth/tax/emm-pp-vertex-service/v1/vertex/ctp/get-tax?trackingId=${trackingId}`, data)

    return response.data
}

export const refillPrepaidAccount = async ({payload, trackingId}) => {
    // const url = `${apiBaseUrl}/oauth2/api/v1/accountService/refillPrepaidAccount?trackingId=${trackingId}`
    const url = `${apiBaseUrl}/oauth2/api/v1/accountService/refillPrepaidAccount?trackingId=${trackingId}`

    const data = payload

    const response = await axios.post(url, data, {
        headers: {
            source: 'DIGITAL2.0',
            trackingid: trackingId
        }
    })

    return response.data
}
export const addPaymentInstrument = async ({payload, sessionId, trackingId}) => {
    const url = `${apiBaseUrl}/secure/payment-service/add-secure-prepaid-payment-instrument?trackingId=${trackingId}`

    const data = payload
    console.log('sessionID in litepay',sessionId);

    const response = await axios.post(url, data, {
        headers: {
            // 'x-session-id': sessionId
            source: 'DIGITAL2.0',
            trackingid: trackingId
        }
    })

    return response.data
};