import DashboardLayout from "../../layouts/DashboardLayout";
import AutoRefillComponent from "../../components/dashboard/AutoRefilComponent";
import withDashboard from "../../redux/connectors/dashboard";
import PromoBanner from "../../components/dashboard/PromoBanner";
import WinbackBanner from "../../components/refill/WinbackBanner";
import { useEffect, useState } from "react";
import PaymentMethod from "../../components/dashboard/PaymentMethod";
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const AutoRefill = (props) => {
  const {
    dashboardContent,
    haveValidPromo,
    deactivatedAccount,
    isWinbackPromo,
    locale,
    dashboard
  } = props;

  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [loadCPCForm, setLoadCPCForm] = useState(false);
  const [cpcProcessing, setCpcProcessing] = useState(false); // eslint-disable-line
  const [startCPCEvents, setStartCPCEvents] = useState(false);

  useEffect(() => {
    setStartCPCEvents(true);
  }, []);

  useEffect(() => {
    props.updatePageClass("edit-payment");
    setLoadCPCForm(true);
  }, []); // eslint-disable-line
  const onUpdateField = (e) => {
    console.log(e);
  };
  const handleFormStatus = (formStatus) => {
    updateFormStatus(formStatus);
  };
  const goToOrderReview = (e) => {
    console.log("goToOrderReviewgoToOrderReview");
  };
  const setFormValid = (status) => {
    handleFormStatus(status);
  };

  return (
    <DashboardLayout locale={props.locale} pageTitle={"Auto Refill"}>
      <TrackUserData data={dashboard} />
      {haveValidPromo && !deactivatedAccount && !isWinbackPromo && (
        <PromoBanner
          title={
            dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner
              ?.title
          }
          body={
            dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner
              ?.body
          }
          link={`/${locale}/refill`}
        />
      )}
      {haveValidPromo && !deactivatedAccount && isWinbackPromo && (
        <WinbackBanner />
      )}
      <div className="main__width-constraint">
        <h1 className="page__title">
          {dashboardContent?.dashboardArea?.autoRefill?.title}
        </h1>

        <PaymentMethod
          onUpdateField={onUpdateField}
          goToOrderReview={goToOrderReview}
          setFormValid={setFormValid}
          nextClicked={processNext}
          startCPCEvents={startCPCEvents}
          minimalView={true}
        />
        {props.paymentInfo && <AutoRefillComponent />}
      </div>
      <TrackPageComplete />
    </DashboardLayout>
  );
};
export default withDashboard(AutoRefill);
