import { connect } from "react-redux";
import { buyflow } from "../buyflow/store";
import { common } from "../common/store";
import selectors from "../buyflow/selectors";

const mapStateToProps = (state) => ({
  digitalSessionId: state?.buyflow?.digitalSessionId,
  digitalSession: state?.buyflow?.digitalSession,
  shouldRestoreSession: state?.buyflow?.shouldRestoreSession,

  shopBaseUrl: state?.buyflow?.shopBaseUrl,
  redirectToShop: state?.buyflow?.redirectToShop,
  locales: state?.buyflow?.locales,
  locale: state?.buyflow?.locale,
  pageClass: state?.common?.pageClass,
  unrecoverableActivationErrors: state?.buyflow?.unrecoverableActivationErrors,
  successActivationErrors: state?.buyflow?.successActivationErrors,

  mediaBaseUrl: state?.buyflow?.mediaBaseUrl,
  progress: state?.buyflow?.progress,
  progressTitle: state?.buyflow?.progressTitle,

  showNotification: state?.buyflow?.showNotification,
  notificationMessage: state?.buyflow?.notificationMessage,
  errorCode:state?.buyflow?.errorCode,
  showErrorPage: state?.buyflow?.showErrorPage,
  error: state?.buyflow?.error,

  paymentProcessing: state?.buyflow?.paymentProcessing,
  orderPlaced: state?.buyflow?.orderPlaced,
  orderFailed: state?.buyflow?.orderFailed,
  orderId: state?.buyflow?.orderId,
  accountId: state?.buyflow?.accountId,

  eligibilityConfirmed: state?.buyflow?.eligibilityConfirmed,
  productDetailsLoaded: state?.buyflow?.productDetailsLoaded,

  haveComcastApiError: state?.buyflow?.haveComcastApiError,
  comcastApiErrors: state?.buyflow?.comcastApiErrors,

  firstName: state?.buyflow?.firstName,
  lastName: state?.buyflow?.lastName,
  addressLine1: state?.buyflow?.addressLine1,
  addressLine2: state?.buyflow?.addressLine2,
  city: state?.buyflow?.city,
  state: state?.buyflow?.state,
  zip: state?.buyflow?.zip,
  locationId: state?.buyflow?.locationId,
  marketId: state?.buyflow?.marketId,

  billingName: state?.buyflow?.billingName,
  billingFirstName: state?.buyflow?.billingFirstName,
  billingLastName: state?.buyflow?.billingLastName,
  billingAddressLine1: state?.buyflow?.billingAddressLine1,
  billingAddressLine2: state?.buyflow?.billingAddressLine2,
  billingCity: state?.buyflow?.billingCity,
  billingState: state?.buyflow?.billingState,
  billingZip: state?.buyflow?.billingZip,
  billingCountry: state?.buyflow?.billingCountry,
  billingCardNumber: state?.buyflow?.billingCardNumber,
  billingCardDisplayNumber: state?.buyflow?.billingCardDisplayNumber,
  billingCardNumberLength: state?.buyflow?.billingCardNumberLength,
  billingCardNumberLastFour: state?.buyflow?.billingCardNumberLastFour,
  billingCardType: state?.buyflow?.billingCardType,
  billingCardNiceType: state?.buyflow?.billingCardNiceType,
  billingExpirationMonth: state?.buyflow?.billingExpirationMonth,
  billingExpirationYear: state?.buyflow?.billingExpirationYear,
  billingCvv: state?.buyflow?.billingCvv,
  billingAddress: state?.buyflow?.billingAddress,

  cardTypeMap: state?.buyflow?.cardTypeMap,

  shippingSameAsBilling: state?.buyflow?.shippingSameAsBilling,
  shippingFirstName: state?.buyflow?.shippingFirstName,
  shippingLastName: state?.buyflow?.shippingLastName,
  shippingAddressLine1: state?.buyflow?.shippingAddressLine1,
  shippingAddressLine2: state?.buyflow?.shippingAddressLine2,
  shippingCity: state?.buyflow?.shippingCity,
  shippingState: state?.buyflow?.shippingState,
  shippingZip: state?.buyflow?.shippingZip,

  shippingCode: state?.buyflow?.shippingCode,
  shippingRate: state?.buyflow?.shippingRate,
  freeShippingCartText: state?.buyflow?.freeShippingCartText,
  freeShippingPromoText: state?.buyflow?.freeShippingPromoText,
  freeShippingChargeStatement: state?.buyflow?.freeShippingChargeStatement,
  showFreeShipping: state?.buyflow?.showFreeShipping,
  shippingDescription: state?.buyflow?.shippingDescription,
  shippingTaxDetails: state?.buyflow?.shippingTaxDetails,
  shippingTaxAmount: state?.buyflow?.shippingTaxAmount,

  trackingId: state?.buyflow?.trackingId,
  activationSource: state?.buyflow?.activationSource,

  emailOptIn: state?.buyflow?.emailOptIn,
  smsOptIn: state?.buyflow?.smsOptIn,

  geoCode: state?.buyflow?.geoCode,
  email: state?.buyflow?.email,
  confirmEmail: state?.buyflow?.confirmEmail,
  tn: state?.buyflow?.tn,
  displayTn: state?.buyflow?.displayTn,
  langPref: state?.buyflow?.langPref,
  houseKey: state?.buyflow?.houseKey,
  serviceabilityBusinessErrorCode: state?.buyflow?.serviceabilityBusinessErrorCode,

  bundleDetails: state?.buyflow?.bundleDetails,

  deviceDetails: state?.buyflow?.deviceDetails,
  cartContentLoaded: state?.buyflow?.cartContentLoaded,
  cartContent: state?.buyflow?.cartContent,
  dictionaryContentLoaded: state?.buyflow?.dictionaryContentLoaded,
  dictionaryContent: state?.buyflow?.dictionaryContent,
  cartPages: state?.buyflow?.cartPages,
  productContent: state?.buyflow?.productContent,

  checkoutContentLoaded: state?.buyflow?.checkoutContentLoaded,
  checkoutContent: state?.buyflow?.checkoutContent,
  buyflowContent: state?.buyflow?.buyflowContent,
  headerContent: state?.buyflow?.headerContent,
  footerContent: state?.buyflow?.footerContent,

  // From Comcast APIs
  serviceabilityData: state?.buyflow?.serviceabilityData,
  productDetails: state?.buyflow?.productDetails,
  prepaidPromotions: state?.buyflow?.prepaidPromotions,
  validPromos: state?.buyflow?.validPromos,
  haveValidPromos: state?.buyflow?.haveValidPromos,
  hsdPromoId: state?.buyflow?.hsdPromoId,

  taxData: state?.buyflow?.taxData,
  taxDataLoaded: state?.buyflow?.taxDataLoaded,

  cart: state?.buyflow?.cart,
  cartSubtotal: state?.buyflow?.cartSubtotal,
  cartTaxes: state?.buyflow?.cartTaxes,
  cartTotal: state?.buyflow?.cartTotal,

  xsrfToken: state?.buyflow?.xsrfToken,
  fetchedToken: state?.buyflow?.fetchedToken,
  fetchingToken: state?.buyflow?.fetchingToken,
  fetchTokenFailed: state?.buyflow?.fetchToken,

  validatedServiceability: state?.buyflow?.validatedServiceability,
  validatingServiceability: state?.buyflow?.validatingServiceability,
  validateServiceabilityFailed: state?.buyflow?.validateServiceabilityFailed,

  fetchedProductDetails: state?.buyflow?.fetchedProductDetails,
  fetchingProductDetails: state?.buyflow?.fetchingProductDetails,
  fetchProductDetailsFailed: state?.buyflow?.fetchProductDetailsFailed,

  createdSession: state?.buyflow?.createdSession,
  creatingSession: state?.buyflow?.creatingSession,
  createSessionFailed: state?.buyflow?.createSessionFailed,

  restoredSession: state?.buyflow?.restoredSession,
  restoringSession: state?.buyflow?.restoringSession,
  restoreSessionFailed: state?.buyflow?.restoreSessionFailed,

  fetchedPromos: state?.buyflow?.fetchedPromos,
  fetchingPromos: state?.buyflow?.fetchingPromos,
  fetchPromosFailed: state?.buyflow?.fetchPromosFailed,

  fetchedTaxData: state?.buyflow?.fetchedTaxData,
  fetchingTaxData: state?.buyflow?.fetchingTaxData,
  fetchTaxDataFailed: state?.buyflow?.fetchTaxDataFailed,

  submitedOrder: state?.buyflow?.submitedOrder,
  submitingOrder: state?.buyflow?.submitingOrder,
  submitOrderFailed: state?.buyflow?.submitOrderFailed,

  asrHandoffComplete: state?.buyflow?.asrHandoffComplete,

  cpcResponse: state?.buyflow?.cpcResponse,

  paymentId: state?.buyflow?.paymentId,

  fetchingPaymentId: state?.buyflow?.fetchingPaymentId,
  fetchedPaymentId: state?.buyflow?.fetchedPaymentId,
  fetchPaymentIdFailed: state?.buyflow?.fetchPaymentIdFailed,

  // SELECTORS
  prepaidInternetProduct: selectors.getPrepaidInternetProduct(state?.buyflow),
  havePromoInCart: selectors.getHavePromoInCart(state?.buyflow),
  productsByName: selectors.getProductsByName(state?.buyflow),
  productTaxDetailsByName: selectors.getProductTaxDetailsByName(state?.buyflow),

  addingPaymentInstrument: state?.buyflow?.addingPaymentInstrument,
  addedPaymentInstrument: state?.buyflow?.addedPaymentInstrument,
  addPaymentInstrumentFailed: state?.buyflow?.addPaymentInstrumentFailed,
  paymentInstrumentErrorCode: state?.buyflow?.paymentInstrumentErrorCode,

  sessionId: state?.common?.sessionId,
  backToOrder: state?.buyflow?.backToOrder,

});

const mapDispatchToProps = {
  generateTrackingId: buyflow?.actions?.generateTrackingId,
  updateLocale: buyflow?.actions?.updateLocale,
  updatePageClass: common?.actions?.updatePageClass,
  updateField: buyflow?.actions?.updateField,
  updateProgress: buyflow?.actions?.updateProgress,
  togglePromo: buyflow?.actions?.togglePromo,
  updateLocationId: buyflow?.actions?.updateLocationId,
  updateMarketId: buyflow?.actions?.updateMarketId,
  updateDigitalSessionId: buyflow?.actions?.updateDigitalSessionId,
  updateShouldRestoreSession: buyflow?.actions?.updateShouldRestoreSession,
  setDirectCartFlow: buyflow?.actions?.setDirectCartFlow,
  updateCartContent: buyflow?.actions?.updateCartContent,
  updateCheckoutContent: buyflow?.actions?.updateCheckoutContent,
  updateDictionaryContent: buyflow?.actions?.updateDictionaryContent,
  fetchToken: buyflow?.actions?.fetchToken,
  validateServiceability: buyflow?.actions?.validateServiceability,
  fetchProductDetails: buyflow?.actions?.fetchProductDetails,
  createSession: buyflow?.actions?.createSession,
  restoreDigitalSession: buyflow?.actions?.restoreDigitalSession,
  getPromos: buyflow?.actions?.getPromos,
  fetchTaxData: buyflow?.actions?.fetchTaxData,
  submitOrder: buyflow?.actions?.submitOrder,
  updateCpcResponse: buyflow?.actions?.updateCpcResponse,
  getPaymentId: buyflow?.actions?.getPaymentId,
  resetPaymentId: buyflow?.actions?.resetPaymentId,
  addPaymentInstrument: buyflow?.actions?.addPaymentInstrument,
};

export default connect(mapStateToProps, mapDispatchToProps);
