import { useEffect } from 'react'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const Unserviceable = ({isMove, setStage, content, addressData}) => {

    // inital load
    useEffect(() => {
        // analytics
        if (isMove) {
            trackEvent({
                event: 'move info',
                moveStep: 'step 1',
                moveStepName: 'start move',
                moveEvent: 'check availability',
                moveDetail: 'service unavailable'
            })
        } else {
            trackEvent({
                event: 'blind sale serviceability check',
                blindSaleEvent: 'error',
                blindSaleDetail: 'service not available'
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="merchant-prepaid__unserviceable">
            <div className="merchant-prepaid__banner merchant-prepaid__banner--yellow">
                <h1 className="merchant-prepaid__title">{content?.servicibilityCheck.noService.title}</h1>
                <p className="merchant-prepaid__subtitle">{content?.servicibilityCheck.noService.message}</p>
            </div>
        
            <div className="merchant-prepaid__unserviceable-address">
                <div className="merchant-prepaid__eligible-address">
                    <img className="merchant-prepaid__error-icon" alt="" src="/site-assets/img/icon-fail.svg"></img>
                    <p>{addressData.address} {addressData.unit}, {addressData.city}, {addressData.state} {addressData.zip}</p>
                </div>
            </div>
    
            <div className="merchant-prepaid__button-wrap">
                <button data-testid="stage" className="button" onClick={() => {setStage('addressCheck'); sessionStorage.removeItem('xppdata')}} data-tracking={digitalDataButtonClick('no-service', content.servicibilityCheck.noService.ctAButtonText)}>{content.servicibilityCheck.noService.ctAButtonText}</button>
            </div>
            
        </div>
    )
}

export default Unserviceable
