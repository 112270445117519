import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import withBuyflow from '../../redux/connectors/buyflow'
import { digitalDataButtonClick, digitalDataModalOpen } from '../../utils/adobe-analytics'

const PromoCartModal = (props) => {
    const {
        buttonClasses,
        cartContent,
        ctaText,
        fetchTaxData,
        fetchedTaxData,
        hsdPromoId,
        modalCloseButton,
        togglePromo,
        updateEditCart
    } = props
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)


    const openModal = () => {
        setModalIsOpen(true)
        document.body.classList.add('modal-open')
    }

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false)
            document.body.classList.remove('modal-open')
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener("mousedown", handleClick)
        } else {
            document.removeEventListener("mousedown", handleClick)
        }
        // eslint-disable-next-line
    }, [modalIsOpen])

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }
    const removePromo = () => {
        togglePromo(hsdPromoId)
        if (fetchedTaxData) {
            fetchTaxData()
        }
        updateEditCart(false)
        closeModal()
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <button type="button" className={buttonClasses} onClick={openModal} data-tracking={digitalDataModalOpen('activation-conflict')}>{ ctaText }</button>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                    <div ref={node} className="modal__body">
                        <h3 id="modal__title" tabIndex={0} className="modal__title">{ cartContent?.cartRemovalPromo?.modalTitle }</h3>
                        <div dangerouslySetInnerHTML={{__html: cartContent?.cartRemovalPromo?.modalContent}} />
                        <br />
                        <br />
                        <div className="button--container"><div className="button--container">
                            <button className="button" onClick={removePromo} data-tracking={digitalDataButtonClick('cart-removal-promo', cartContent?.cartRemovalPromo?.buttonText)}>{ cartContent?.cartRemovalPromo?.buttonText }</button>
                            <br />
                            <br />
                            { cartContent?.cartRemovalPromo?.keepLeadIn } <button className="button--link" onClick={closeModal} data-tracking={digitalDataButtonClick('cart-keep-promo', cartContent?.cartRemovalPromo?.keepText)}>{ cartContent?.cartRemovalPromo?.keepText }</button>
                            </div>
                        </div>
                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', 'Close this dialog window', true)}>
                            { modalCloseButton }
                            <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withBuyflow(PromoCartModal)