import {GlobalModal as Modal } from '../Modal'
import { formatPhoneNumber, idFromIdentityObj } from '../../utils/utils'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

const UserInformation = ({content}) => {
    const xfinityId = content?.identity ? idFromIdentityObj(content?.identity) : 'unavailable'
    const phone = content?.identity?.idcmtMobile || content?.dashboard?.customerInfo?.phoneNumber

    return (
        <div className="card activation-page">
            <div className="card__header">
                <h2 className="card__header-title">{content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.myInformation}</h2>
            </div>
            <div>
                <div className="account-block">
                    <b>{content?.dictionaryContent?.global?.name}</b><br />
                    {(content?.chosenName?.chosenFirstName) ? content?.chosenName?.chosenFirstName : content?.dashboard?.customerInfo?.firstName}
                    {(content?.chosenName?.chosenMiddleName) ? ' ' + content?.chosenName?.chosenMiddleName : ''}
                    {(content?.chosenName?.chosenLastName) ? ' ' + content?.chosenName?.chosenLastName : ' ' + content?.dashboard?.customerInfo?.lastName}
                </div>
            </div>
            
            {content?.pronoun && <div className="account-block">
                <div className="modal-button-container">
                    <b className="information-label">
                        {content?.dictionaryContent?.global?.pronoun}
                    </b><br />
                    {content?.pronoun}
                </div>
            </div>}
            
            <div className="account-block">
                <div className="modal-button-container">
                    <b className="information-label">
                        {content?.dictionaryContent?.global?.xfinityID}
                    </b><br />
                    {xfinityId}
                    <div className="modal-item">
                        <Modal 
                            buttonClasses={'link-callout modal-button'} 
                            body={content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.orderReview?.aboutYourIdModalText}
                            title=''
                            ctaText={content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.orderReview?.aboutYourIdLinkLabel}
                        />
                    </div>
                </div>
            </div>

            <div className="account-block">
                <div className="modal-button-container">
                    <b className="information-label">{content?.dictionaryContent?.formLabels.mobileNumber}</b>
                    <br />{formatPhoneNumber(phone)}
                    {content?.identity.idcmtMobileVerified ?
                        <div className="cima-label cima-verified">
                            {content?.dashboardContent?.accountInfoArea.myInformationCommunications.orderReview.mobileNumberVerifiedLabel}
                        </div> :
                        <div className="modal-item ">
                            <Modal
                                ctaText={content?.dashboardContent?.accountInfoArea.myInformationCommunications.orderReview?.mobileNumberUnverifiedLabel}
                                buttonClasses='cima-label cima-unverified'
                                title=''
                                isVerificationModal='true'
                                body={content?.dashboardContent?.accountInfoArea.myInformationCommunications.orderReview?.mobileNumberUnverifiedModalText}
                            />
                        </div>
                    }
                </div>
            </div>

            <div className="account-block">
                <div className="modal-button-container">
                    <b className="information-label">Email</b>
                    <br />{content?.dashboard?.customerInfo?.emailAddress}
                    {content?.identity?.idcmtEmailVerified ?
                        <div className="cima-label cima-verified">
                            {content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.orderReview?.emailVerifiedLabel}
                        </div> :
                        <div className="modal-item ">
                            <Modal
                                ctaText={content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.orderReview?.emilUnverifiedLabel}
                                buttonClasses='cima-label cima-unverified'
                                title=''
                                isVerificationModal='true'
                                body={content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.orderReview?.emailUnverifiedModalText}
                            />
                        </div>
                    }
                </div>
            </div>

            <br/>
            <a href={content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.updateMyAccountInfo?.link?.url} rel="noreferrer" target={content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.updateMyAccountInfo?.link?.target} className="link-callout link-external" data-tracking={digitalDataLinkClick('update-account-info', content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.updateMyAccountInfo?.link?.url, content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.updateMyAccountInfo?.text)}>
                {content?.dashboardContent?.accountInfoArea?.myInformationCommunications?.updateMyAccountInfo?.text}
            </a>
        </div>
    )
}

export default UserInformation