/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { fcc } from "../assets/constant/fcc.json";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "xfinity-broadband-facts": any;
    }
  }
}
const SpanishBroadbandFacts = (props) => {
  const { tierId } = props;

  const [hidden, setHidden] = useState(true);
  const [activeButton, setButtonID] = useState("collapse");
  const [fccLabelId, setfccLabelId] = useState("");
  const [fccLabel30DaysId, setfccLabel30DaysId] = useState(
    "F0003768165BIPVIB7KX4J975K"
  );
  const [fccLabel7DaysId, setfccLabel7DaysId] = useState(
    "F0003768165GZ3CHBCWMG7TUXR"
  );
  const readMoreClick = (val) => {
    setHidden(!hidden);
    setButtonID(val);
  };

  useEffect(() => {
    const fccLabel_Id =
      tierId === "HSD_030" ? fccLabel30DaysId : fccLabel7DaysId;
    setfccLabelId(fccLabel_Id);
  }, [fccLabelId, tierId]);

  return (
    <div className="broadband-facts spanish-broadband-facts">
      <div className="width-100">
        <div className="card">
          <div className={"card-body " + activeButton}>
            <article className="false bb-facts-body-font3 bb-facts-text-neutral-base">
              <header>
                <h3
                  className="bb-facts-display-font1"
                  data-test-id="fcc-label-header"
                >
                  {fcc[0].fccSpanish.fccHeaders.headerTitle}
                </h3>
                <h4
                  className="bb-facts-body-font1"
                  data-test-id="fcc-label-brand"
                >
                  {fcc[0].fccSpanish.fccHeaders.headerXfinity}
                </h4>
                <h4
                  className="bb-facts-body-font1 bb-facts-text-expressive"
                  data-test-id="fcc-label-tier"
                >
                  {tierId === "HSD_030"
                    ? fcc[0].fccSpanish.fccHeaders.header30Days
                    : fcc[0].fccSpanish.fccHeaders.header07Days}
                </h4>
                <p
                  className="bb-facts-text-neutral-base"
                  data-test-id="fcc-label-header-description"
                >
                  {fcc[0].fccSpanish.fccHeaders.headerText}
                </p>
              </header>
              <article>
                <section>
                  <div className="bb-label-ui-charges-section">
                    <span className="bb-facts-inline-block">
                      <h5
                        className="bb-facts-font-bold"
                        data-test-id="fcc-label-monthly-price-header"
                      >
                        {fcc[0].fccSpanish.fccPrice.priceTitle}
                      </h5>
                    </span>
                    <span
                      className="bb-facts-font-bold bb-facts-keep-right"
                      data-test-id="fcc-label-monthly-price-pricing"
                    >
                      {tierId === "HSD_030"
                        ? fcc[0].fccSpanish.fccPrice.Price45
                        : fcc[0].fccSpanish.fccPrice.Price15}
                    </span>
                  </div>
                  <div className="bb-facts-border-bottom bb-facts-description">
                    <div
                      className=""
                      data-test-id="fcc-label-monthly-price-section-description"
                    >
                      {tierId === "HSD_030"
                        ? fcc[0].fccSpanish.fccDescription30
                        : fcc[0].fccSpanish.fccDescription07}
                    </div>
                    <div
                      className="bb-facts-font-italic bb-label-ui-our-policy-link bb-facts-text-neutral-down bb-facts-clear-both"
                      data-test-id="fcc-label-monthly-price-section-subtext"
                    ></div>
                  </div>
                </section>
              </article>
              <section>
                <div className="bb-label-ui-charges-section">
                  <span className="bb-facts-inline-block">
                    <h5
                      className="bb-facts-font-bold"
                      data-test-id="fcc-label-additional-charges-header"
                    >
                      {fcc[0].fccSpanish.charges.monthlyChargesDescription}
                    </h5>
                  </span>
                </div>
                <article>
                  <div className="bb-facts-border-bottom bb-facts-description">
                    <div
                      className=""
                      data-test-id="fcc-label-provider-monthly-charges-description"
                    >
                      {fcc[0].fccSpanish.charges.prepaidRefillHeader}
                    </div>
                    <div
                      className="bb-facts-font-italic bb-label-ui-our-policy-link bb-facts-text-neutral-down bb-facts-clear-both"
                      data-test-id="fcc-label-provider-monthly-charges-subtext"
                    ></div>
                  </div>
                  <div className="bb-facts-display-table bb-facts-full-width bb-facts-padding-vertical bb-facts-border-bottom">
                    <div className="bb-facts-display-table-row">
                      <div className="bb-facts-subsection-inner-element bb-facts-display-table-cell">
                        <h6
                          className="bb-label-subsec-header"
                          data-test-id="fcc-label-additional-charges-fees-PREPAID_REFILL-header"
                        >
                          {fcc[0].fccSpanish.charges.prepaidRefillSubHeader}
                        </h6>
                      </div>
                      <div className="bb-facts-font-bold bb-facts-display-table-cell bb-facts-text-right">
                        <div data-test-id="fcc-label-additional-charges-fees-PREPAID_REFILL-pricing">
                          {tierId === "HSD_030"
                            ? fcc[0].fccSpanish.charges.prepaidRefill45
                            : fcc[0].fccSpanish.charges.prepaidRefill15}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bb-facts-display-table bb-facts-full-width bb-facts-padding-vertical bb-facts-border-bottom">
                    <div className="bb-facts-display-table-row">
                      <div className="bb-facts-subsection-inner-element bb-facts-display-table-cell">
                        <h6
                          className="bb-label-subsec-header 0"
                          data-test-id="fcc-label-additional-charges-gateway-xfinity-modem-header"
                        >
                          {fcc[0].fccSpanish.charges.gatewayXfinityModemHeader}
                        </h6>
                      </div>
                      <div className="bb-facts-font-bold bb-facts-display-table-cell bb-facts-text-right">
                        <div data-test-id="fcc-label-additional-charges-gateway-xfinity-modem-pricing">
                          {fcc[0].fccSpanish.charges.gatewayXfinityModemPricing}
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <article>
                  <div className="bb-facts-border-bottom bb-facts-description">
                    <div
                      className=""
                      data-test-id="fcc-label-other-fees-description"
                    >
                      {fcc[0].fccSpanish.charges.otherFeesDescription}
                    </div>
                    <div
                      className="bb-facts-font-italic bb-label-ui-our-policy-link bb-facts-text-neutral-down bb-facts-clear-both"
                      data-test-id="fcc-label-other-fees-subtext"
                    ></div>
                  </div>
                  <div className="bb-facts-display-table bb-facts-full-width bb-facts-padding-vertical bb-facts-border-bottom">
                    <div className="bb-facts-display-table-row">
                      <div className="bb-facts-subsection-inner-element bb-facts-display-table-cell">
                        <h6
                          className="bb-label-subsec-header"
                          data-test-id="fcc-label-additional-charges-fee-govt-tax-header"
                        >
                          {fcc[0].fccSpanish.charges.feeGovtTaxHeader}
                        </h6>
                      </div>
                      <div className="bb-facts-font-bold bb-facts-display-table-cell bb-facts-text-right">
                        <div data-test-id="fcc-label-additional-charges-fee-govt-tax-pricing">
                          {fcc[0].fccSpanish.charges.feeGovtTaxPricing}
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
              <article></article>
              <article>
                <section>
                  <div className="bb-label-ui-charges-section">
                    <span className="bb-facts-inline-block">
                      <h5
                        className="bb-facts-font-bold"
                        data-test-id="fcc-label-speed-included-header"
                      >
                        {fcc[0].fccSpanish.fccSpeed.speedIncludedHeader}
                      </h5>
                    </span>
                  </div>
                  <div>
                    <div className="bb-facts-display-table bb-facts-full-width bb-facts-padding-vertical bb-facts-border-bottom">
                      <div className="bb-facts-display-table-row">
                        <div className="bb-facts-display-table-cell">
                          <h6
                            className="bb-label-subsec-header"
                            data-test-id="fcc-label-speed-included-download-speed-header"
                          >
                            {
                              fcc[0].fccSpanish.fccSpeed
                                .speedIncludedDownloadSpeedHeader
                            }
                          </h6>
                        </div>
                        <div className="bb-facts-font-bold bb-facts-display-table-cell bb-facts-text-right">
                          <div data-test-id="fcc-label-speed-included-download-speed-pricing">
                            {
                              fcc[0].fccSpanish.fccSpeed
                                .speedIncludedDownloadSpeedPricing
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bb-facts-display-table bb-facts-full-width bb-facts-padding-vertical bb-facts-border-bottom">
                      <div className="bb-facts-display-table-row">
                        <div className="bb-facts-display-table-cell">
                          <h6
                            className="bb-label-subsec-header"
                            data-test-id="fcc-label-speed-included-upload-speed-header"
                          >
                            {
                              fcc[0].fccSpanish.fccSpeed
                                .speedIncludedUploadSpeedHeader
                            }
                          </h6>
                        </div>
                        <div className="bb-facts-font-bold bb-facts-display-table-cell bb-facts-text-right">
                          <div data-test-id="fcc-label-speed-included-upload-speed-pricing">
                            {
                              fcc[0].fccSpanish.fccSpeed
                                .speedIncludedUploadSpeedPricing
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bb-facts-display-table bb-facts-full-width bb-facts-padding-vertical bb-facts-border-bottom">
                      <div className="bb-facts-display-table-row">
                        <div className="bb-facts-display-table-cell">
                          <h6
                            className="bb-label-subsec-header"
                            data-test-id="fcc-label-speed-included-latency-header"
                          >
                            {
                              fcc[0].fccSpanish.fccSpeed
                                .speedIncludedLatencyHeader
                            }
                          </h6>
                        </div>
                        <div className="bb-facts-font-bold bb-facts-display-table-cell bb-facts-text-right">
                          <div data-test-id="fcc-label-speed-included-latency-pricing">
                            {
                              fcc[0].fccSpanish.fccSpeed
                                .speedIncludedLatencyPricing
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
              <section>
                <div className="bb-label-ui-charges-section">
                  <span className="bb-facts-inline-block">
                    <h5
                      className="bb-facts-font-bold"
                      data-test-id="fcc-label-data-included-header"
                    >
                      {fcc[0].fccSpanish.dataIncluded.dataIncludedHeader}
                    </h5>
                  </span>
                  <span
                    className="bb-facts-font-bold bb-facts-keep-right"
                    data-test-id="fcc-label-data-included-pricing"
                  >
                    {fcc[0].fccSpanish.dataIncluded.dataIncludedPricing}
                  </span>
                </div>
                <div className="bb-facts-border-bottom bb-facts-description">
                  <div
                    className="bb-facts-keep-left"
                    data-test-id="fcc-label-data-included-section-description"
                  >
                    {
                      fcc[0].fccSpanish.dataIncluded
                        .dataIncludedSectionDescription
                    }
                  </div>
                  <div
                    className="bb-facts-keep-right bb-facts-font-bold"
                    data-test-id="fcc-label-data-included-section-pricing"
                  >
                    {fcc[0].fccSpanish.dataIncluded.dataIncludedSectionPricing}
                  </div>
                  <div
                    className="bb-facts-font-italic bb-label-ui-our-policy-link bb-facts-text-neutral-down bb-facts-clear-both"
                    data-test-id="fcc-label-data-included-section-subtext"
                  ></div>
                </div>
              </section>
              <footer>
                <section>
                  <div className="bb-label-ui-charges-section">
                    <span className="bb-facts-inline-block">
                      <h5
                        className="bb-facts-font-bold"
                        data-test-id="fcc-label-network-management-header"
                      >
                        {
                          fcc[0].fccSpanish.networkManagement
                            .networkManagementHeader
                        }
                      </h5>
                    </span>
                    <span
                      className="bb-facts-keep-right"
                      data-test-id="fcc-label-network-management-description"
                    >
                      {
                        fcc[0].fccSpanish.networkManagement
                          .networkManagementDetails.networkManagementDescription
                      }
                      {""}
                      <a
                        href={
                          fcc[0].fccSpanish.networkManagement
                            .networkManagementDetails.networkManagementUrl
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {
                          fcc[0].fccSpanish.networkManagement
                            .networkManagementDetails.networkManagementUrlTitle
                        }
                      </a>
                    </span>
                  </div>
                </section>
                <section>
                  <div className="bb-label-ui-charges-section">
                    <span className="bb-facts-inline-block">
                      <h5
                        className="bb-facts-font-bold"
                        data-test-id="fcc-label-privacy-header"
                      >
                        {
                          fcc[0].fccSpanish.networkManagement.privacyLabel
                            .privacyHeader
                        }
                      </h5>
                    </span>
                    <span
                      className="bb-facts-keep-right"
                      data-test-id="fcc-label-privacy-description"
                    >
                      {
                        fcc[0].fccSpanish.networkManagement.privacyLabel
                          .privacyDescription
                      }
                      {""}
                      <a
                        href={
                          fcc[0].fccSpanish.networkManagement.privacyLabel
                            .privacyUrl
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {
                          fcc[0].fccSpanish.networkManagement.privacyLabel
                            .privacyTitle
                        }
                      </a>
                    </span>
                  </div>
                </section>
                <section>
                  <div className="bb-label-ui-charges-section">
                    <span className="bb-facts-inline-block">
                      <h5
                        className="bb-facts-font-bold"
                        data-test-id="fcc-label-contact-us-header"
                      >
                        {fcc[0].fccSpanish.contactUsLabel.contactUsHeader}
                      </h5>
                    </span>
                    <span
                      className="bb-facts-keep-right"
                      data-test-id="fcc-label-contact-us-description"
                    >
                      <a
                        href={fcc[0].fccSpanish.contactUsLabel.contactUsURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {fcc[0].fccSpanish.contactUsLabel.contactUsDescription}
                      </a>
                    </span>
                  </div>
                  <div className="bb-facts-border-bottom bb-facts-description">
                    <div
                      className=""
                      data-test-id="fcc-label-contact-us-section-description"
                    >
                      {
                        fcc[0].fccSpanish.contactUsLabel
                          .contactUsSectionDescription
                      }
                      {""}
                      <a
                        href={fcc[0].fccSpanish.contactUsLabel.govConsumerUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {fcc[0].fccSpanish.contactUsLabel.govConsumer}
                      </a>
                    </div>
                    <div
                      className="bb-facts-font-italic bb-label-ui-our-policy-link bb-facts-text-neutral-down bb-facts-clear-both"
                      data-test-id="fcc-label-contact-us-section-subtext"
                    ></div>
                  </div>
                </section>
                <section
                  className="bb-facts-border-bottom bb-facts-description bb-facts-text-neutral2-base"
                  data-test-id="fcc-label-labelid"
                >
                  {fccLabelId}
                </section>
              </footer>
            </article>
          </div>
          <div className="card-footer">
            {hidden ? (
              <button
                type="button"
                className="cima-label cima-unverified read-more-button-text readmore-less-arrow"
                onClick={() => {
                  readMoreClick("expand");
                }}
              >
                {fcc[0].fccSpanish.cardfooter.readMore}
              </button>
            ) : (
              <button
                type="button"
                className="cima-label cima-unverified read-less-button-text readmore-less-arrow"
                onClick={() => {
                  readMoreClick("collapse");
                }}
              >
                {fcc[0].fccSpanish.cardfooter.readLess}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpanishBroadbandFacts;
