import withLitepay from "../../redux/connectors/litepay";
import { useState, useEffect } from "react";
import PaymentComponent from "../PaymentComponent";
import Payment from "../Payment";
import ProgressSpinner from "../ProgressSpinner";
import NotificationBanner from "../../components/NotificationBanner";
import { errorForCode } from "../../lib/errors";
import { trackEvent } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { digitalDataButtonClick } from "../../utils/adobe-analytics";

const PaymentForm = (props) => {
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    dictionaryContent,
    goToOrderReview,
    litepayPages,
    trackingId,
    dashboard,
    setFormValid,
    nextClicked,
    startCPCEvents,
    onUpdateField,
    updateCpcResponse,
    addPaymentInstrument,
    addPaymentInstrumentFailed,
    paymentInstrumentErrorCode,
    currentLocale,
    addedPaymentInstrument,
    resetPageStep,
    isBack,
    fetchedDashboard,
  } = props;

  const [processNext, setProcessNext] = useState(false);
  const [formValid, updateFormStatus] = useState(true);
  const [cpcProcessing, setCpcProcessing] = useState(false);
  const [loadCPCForm, setLoadCPCForm] = useState(false);
  const [cpcToken, setCpcToken] = useState();
  const [apiError, setApiError] = useState("");
  const [isCPCLoaded, setCPCLoader] = useState(true);
  const [isapiError, setErrorApi] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (nextClicked) {
      setProcessNext(nextClicked);
    }
  }, [nextClicked]);

  useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
  }, [startCPCEvents]);

  useEffect(() => {
    updateFormStatus(!cpcProcessing);
    setFormValid(!cpcProcessing);
  }, [cpcProcessing]); // eslint-disable-line

  const updateFormValid = (valid) => {
    setFormValid(valid);
    updateFormStatus(valid);
    if (valid) {
      setProcessNext(false);
    }
  };
  useEffect(() => {
    if (addPaymentInstrumentFailed) {
      const error = errorForCode(
        paymentInstrumentErrorCode || "",
        currentLocale
      );
      if(isapiError && isCPCLoaded && cpcProcessing){
      setApiError(error);
    }
    }
  }, [addPaymentInstrumentFailed,isCPCLoaded,cpcProcessing]); // eslint-disable-line

  useEffect(() => {
    resetPageStep();
    if (addedPaymentInstrument && !isBack) {
      goToOrderReview();
      //window.digitalData.transaction.attributes.buyflowStep = "order-review";

    }
  }, [addedPaymentInstrument]); // eslint-disable-line

  // useEffect(() => {
  //   if (fetchedDashboard) {
  //     const digitalDataObj = window.digitalData;
  //     const userProfile = {
  //       user: [
  //         {
  //           profile: [
  //             {
  //               profileInfo: {
  //                 authenticationType: "unauthenticated",
  //                 customerType: "new",
  //                 recognizationType: "", // TBD
  //                 prepaid: {
  //                   accountStatus:
  //                     dashboard?.customerInfo?.prepaidAccountStatus,
  //                   currentService: dashboard?.subscriptionDetails
  //                     ? dashboard?.subscriptionDetails[0]?.deviceLob
  //                     : "",
  //                   payment: dashboard?.paymentDetail?.cardNumber
  //                     ? "saved"
  //                     : "unsaved",
  //                   serviceActivationState:
  //                     dashboard?.customerInfo?.prepaidAccountStatus,
  //                   serviceDaysRemaining: dashboard?.data?.subscriptionDetails
  //                     ? dashboard?.subscriptionDetails[0]?.daysRemaining
  //                     : "",
  //                   serviceStatus: dashboard?.data?.subscriptionDetails
  //                     ? dashboard?.subscriptionDetails[0]?.deviceStatus
  //                     : "",
  //                   services: {
  //                     LOB: [
  //                       {
  //                         name: "internet",
  //                         tier: "prepaid",
  //                       },
  //                     ],
  //                     productType: "single play",
  //                   },
  //                 },
  //               },
  //             },
  //           ],
  //           segment: {
  //             zipServiceAddress: dashboard?.serviceAddress
  //               ? dashboard?.serviceAddress?.zip
  //               : "",
  //           },
  //         },
  //       ],
  //     };
  //    Object.assign(digitalDataObj, userProfile);
  //    digitalDataObj.transaction.attributes.buyflowStep = "payment";
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchedDashboard]);

  useEffect(() => {  
    let errorReloadCount: any;
    errorReloadCount = sessionStorage.getItem("errorReloadCount");
    if(errorReloadCount >= 3){
      sessionStorage.removeItem("errorReloadCount");
    }
    if ((cpcProcessing && isCPCLoaded && apiError !== "")) {       
      sessionStorage.setItem("currentURL",window.location.pathname);
      setTimeout(
        function () {
          setCpcProcessing(false);
          setCPCLoader(false);
          setApiError("");
          setErrorApi(false); 
          if (errorReloadCount == null) {
            errorReloadCount = 1;
          } else {
            errorReloadCount = Number(errorReloadCount) + 1;
          }
          sessionStorage.setItem("errorReloadCount", errorReloadCount);  
        },
        30000
      );
    
      
    } else {
      setErrorApi(true);
    } 
    if (errorReloadCount >= 3) {
      sessionStorage.removeItem("errorReloadCount");
      const prepaidURL:any = process.env.REACT_APP_SHOPBASE_URL
      window.location.replace(prepaidURL)
    }
  }, [cpcProcessing, isCPCLoaded,apiError]);


  const paymentProcessing = (value) => {
    setCpcProcessing(value);
  };

  const paymentProcessed = (response) => {
    setCpcToken(response?.cpcData?.cardDetails?.token);
    onUpdateField(response);
    updateCpcResponse(response);
    const payload = {
      custInfo: {
        // accountId:"8069100018607134",
        accountId: dashboard.customerInfo.accountId,
        firstName: response?.paymentInfo?.firstName,
        lastName: response?.paymentInfo?.lastName,
        addressLine1: response?.paymentInfo?.address,
        addressLine2: response?.paymentInfo?.addressLine2,
        city: response?.paymentInfo?.city,
        state: response?.paymentInfo?.state,
        zip: response?.paymentInfo?.zipCode,
      },
      enrollAutoPayment: false,
      processingNetwork: "CREDIT",
      addInstrumentToWallet: "true",
      paymentInstToken: response?.cpcData?.cardDetails?.token,
      isCPC: true,
      expirationDate: response?.cpcData?.cardDetails?.expirationDate,
      cardType: response?.cpcData?.cardDetails?.cardType,
      cardNumber: response?.cpcData?.cardDetails?.cardLast4Digits,
      savePayment: false,
    };
    addPaymentInstrument(payload);
  };

  const handleNext = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setProcessNext(true);
    if (formValid) {
      console.log("validated is true");
    } else {
      console.log("onError");
    }
  };
  const paymentError = (response) => {
    let errorType;
    let errorDetail;
    if (response.type === "form") {
      errorType = "error validation";
      errorDetail = response.message;
    } else {
      errorType = "error";
      errorDetail = response.messageDetail;
    }
    if (response.type !== "form") {
      trackEvent({
        event: "litepay info",
        litepayStep: "step 3",
        litepayStepName: "payment",
        litepayEvent: errorType,
        litepayDetail: errorDetail,
      });
    }
  };

  const paymentLoaded = (value) => {
    setCPCLoader(value);
    let errorReloadCount:any = sessionStorage.getItem("errorReloadCount");
    if(errorReloadCount >=2){
    setTimeout(
      function () {
        setApiError("");
      },
      10000
    );
  }
  };

  return (
    <form id="payment-form">
      <div className="main__width-constraint">
        {apiError ? (
          <NotificationBanner
            text={apiError}
            displayStyle="alert"
            modal="true"
          />
        ) : null}
        <div className="card">
          {litepayPages?.litepay?.paymentStep?.title && (
            <div className="card__header">
              <h4 className="payment-method-header">
                {litepayPages?.litepay?.paymentStep?.title}
              </h4>
            </div>
          )}
          <div className={cpcProcessing || isCPCLoaded ? "" : "vh"}>
            <ProgressSpinner
              loadingText={isCPCLoaded ? "Loading..." : "Processing..."}
            />
          </div>

          <div className={cpcProcessing ? "vh" : ""}>
            {loadCPCForm && <PaymentComponent cpcPageType="CardOnly" />}

            <Payment
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              city={city}
              zip={zip}
              UsState={state}
              trackingId={trackingId}
              custGuid={dashboard?.customerInfo?.prepaidPaymentID}
              firstName={dashboard?.customerInfo?.firstName}
              lastName={dashboard?.customerInfo?.lastName}
              processNext={processNext}
              paymentProcessing={paymentProcessing}
              paymentProcessed={paymentProcessed}
              updateFormValid={updateFormValid}
              showAddress={false}
              loadCPCForm={loadCPCForm}
              paymentInstToken={cpcToken}
              onUpdateField={onUpdateField}
              channelName={"PREPAID_LITE"}
              sourceSystemId={"PREPAID_LITE_CPC"}
              enableFraudManager={false}
              cpcMessageDisplayLocation={"top"}
              displayAddressOverride={false}
              displayAutoPayEnroll={false}
              displayStoredPaymentOption={false}
              customerClass={"residential"}
              paymentToken={cpcToken ? cpcToken : ""}
              storePaymentInstrumentLongTerm={false}
              cpcPageTypeVal={"CardOnly"}
              paymentError={paymentError}
              paymentLoaded={paymentLoaded}
            />
          </div>
        </div>
      </div>
      <button
        data-testid="next-step-btn"
        className="button next-step"
        onClick={handleNext}
        disabled={!formValid}
        data-tracking={digitalDataButtonClick(
          "next-step",
          dictionaryContent?.global?.nextStep
        )}
      >
        {dictionaryContent?.global?.nextStep}
      </button>
    </form>
  );
};
export default withLitepay(PaymentForm);
