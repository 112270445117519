import React, { useState } from 'react'
import { GlobalModal as Modal } from '../components/Modal'
import { trackEvent } from '../utils/utils'
import { digitalDataButtonClick } from '../utils/adobe-analytics'

interface ComponentProps {
    text?: any
    displayStyle?: String
    modal?: string
    modalCtaText?: any
    modalText?: string
    modalTitle?: string
    modalBody?: string
}
const NotificationBanner = ({text, displayStyle, modal, modalCtaText, modalTitle, modalText }: ComponentProps) => {

    const spriteUrl = '/site-assets/sprites/vector.spritesheet.svg'
    const [showBanner, setShowBanner] = useState(true)
    const closeModal = () => {
        setShowBanner(false)
    }

    const handleClick = (e) => {
        if (e.target.matches('.multiple-accounts-link')) {
            trackEvent({
                event: 'litepay info',
                litepayStep: 'step 1',
                litepayStepName: 'find your account',
                litepayEvent: 'multiple accounts associated',
                litepayDetail: 'login'
            })
        }
    }

    return (
        <div className={`notification-banner ${displayStyle ? displayStyle : ''} ${showBanner ? '' : 'hide'} ${modalCtaText ? 'notification-banner--contains-modal' : ''}`} onClick={(e) => handleClick(e) } data-tracking={digitalDataButtonClick('multiple-accounts-associated', modalCtaText)}>
            <div className='main__width-constraint'>
                <div className="icon" aria-label="Yes, this was helpful">
                    <svg>
                        <use href={spriteUrl + "#icon-alert"} />
                    </svg>
                </div>
                <div dangerouslySetInnerHTML={{__html: text }} className="text" />
                {modalCtaText ?
                    <Modal
                        ctaText={modalCtaText}
                        buttonClasses='button--link--large'
                        title={modalTitle}
                        body={modalText}
                        type={modal}
                    />
                    : false
                }
                {modal ?
                    <div className="close-modal">
                        <button className="close-modal__btn" onClick={() => closeModal()} data-tracking={digitalDataButtonClick('close-modal', 'Close', true)}><span className="visually-hidden">Close</span></button>
                    </div>
                    : false
                }
            </div>
        </div>
    )

}

export default NotificationBanner
