import React from 'react'

interface ComponentProps {
    loadingText: string
    dark?: any
}

const ProgressSpinner = ({loadingText, dark}: ComponentProps) => {
    return (
        <div>
            <div className={`${dark ? 'progress-spinner progress-spinner--dark' : 'progress-spinner'}`}>
                <div className="progress-spinner__loader">
                    <div className="progress-spinner__text">
                        <span dangerouslySetInnerHTML={{__html: loadingText}} />
                    </div>

                    <div className="progress-spinner__circle" />
                </div>
            </div>
        </div>
    )
}

export default ProgressSpinner
