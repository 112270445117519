import { useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import withDashboard from '../../redux/connectors/dashboard'
//import ProgressSpinner from '../../components/ProgressSpinner'
import ServiceStatus from '../../components/dashboard/ServiceStatus'
import StatusRow from '../../components/move/StatusRow'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '../../utils/utils'
import { sprintf } from 'sprintf-js'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const AddressConfirmation = props => {

    const {
        hsdService,
        updateField,
        dashboard
    } =  props

    useEffect(() => {
        props.updatePageClass('dashboard move-flow')
        trackEvent({
            event: 'move info',
            moveStep: 'step 1',
            moveStepName: 'start move',
            moveEvent: 'check availability',
            moveDetail: 'service available'
        })
        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility?.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 2',
            moveStepName: 'move review',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
    }, []) // eslint-disable-line
    const { autoRefillEnabled, moveContent, auxAccount } = props
    // console.log("AddressConfirmationAddressConfirmation");
    /*const renderLoader = (content) => {
        return (
            <div className="main__width-constraint">
                <ProgressSpinner  loadingText='Loading Activation' />
            </div>
        )
    }*/

    //Change Address is a link to check availability flow
    const history = useHistory();

    const moveNow = () => {
            trackEvent({
                event: 'move info',
                moveStep: 'step 2',
                moveStepName: 'move review',
                moveEvent: 'next step cta',
                moveDetail: 'move now'
            })
            if (hsdService.daysRemaining > 0 ) {
                history.push("/en/move/now")
            } else {
                const date = new Date()
                const dateString = sprintf('%04d-%02d-%02d', date.getFullYear(), date.getMonth() + 1, date.getDate())
                updateField({field: 'scheduledMoveDate', value: dateString})
                console.log(dateString)
                history.push("/en/move/refill-required")
            }
    }

    const moveLater = () => {
        trackEvent({
            event: 'move info',
            moveStep: 'step 2',
            moveStepName: 'move review',
            moveEvent: 'next step cta',
            moveDetail: 'schedule my move'
        })
        history.push("/en/move/schedule")
    }

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move Address Confirmation'}>
            <TrackUserData data={dashboard} />
            <div>
                <div className="main__width-constraint">
                    <div className="card">
                        <h1 className="page__title">
                            {/* {dashboardContent?.activation?.accountPicker?.accountPickerPage.title} */}
                            {moveContent?.moveNow?.everythingLookCorrect}

                        </h1>
                        <StatusRow />
                    </div>
                </div>
                {/* TODO: Determine if warning row should be an option on ServiceStatus, StatusRow or a stand alone element */}
                <ServiceStatus isMoving={true} isPaused={false} autoRefill={autoRefillEnabled} />
                <div className="main__width-constraint">
                    <div className="card">
                        { hsdService ? 
                        <div className="button--container">
                            <button data-testid="btn-moveNow" className="button" onClick={() => {moveNow()} } data-tracking={digitalDataButtonClick('refill-move-now', hsdService.daysRemaining > 0 ? moveContent?.moveNow?.moveNow?.text : moveContent?.moveNow?.refillAndMoveNow?.text)}>
                                { hsdService.daysRemaining > 0 ? moveContent?.moveNow?.moveNow?.text : moveContent?.moveNow?.refillAndMoveNow?.text }
                            </button>
                            <hr></hr>
                            <button data-testid="btn-moveLater" className="button button--hollow" onClick={() => moveLater()} data-tracking={digitalDataButtonClick('refill-scheduled-move', hsdService.daysRemaining > 0 ? moveContent?.moveScheduled?.scheduleMyMove?.text : moveContent?.moveScheduled?.refillAndScheduleMyMove?.text)}>
                                { hsdService.daysRemaining > 0 ? moveContent?.moveScheduled?.scheduleMyMove?.text : moveContent?.moveScheduled?.refillAndScheduleMyMove?.text }
                            </button>
                        </div> : null
                        }
                        <div className="move--disclaimer">{ moveContent?.moveNow?.allActivePrepaidServicesWillBePaused }</div>
                    </div>
                </div>
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(AddressConfirmation)