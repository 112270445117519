import { put, select, call, takeEvery } from 'redux-saga/effects'
import { fetchProductDetails } from './apis'
import { cart } from './store'
import { digitalDataAPIError } from '../../utils/adobe-analytics'

const getTrackingId = (state) => state.common.trackingId
const getMarketId = (state) => state.buyflow.marketId

function* fetchProductDetailsSaga({payload}) {
    console.log('fetchProductDetailsSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const marketId = yield select(getMarketId)
        const response = yield call(fetchProductDetails, {marketId, trackingId});
        yield put(cart.actions.fetchProductDetailsSuccess(response));
    } catch (error: any) {
        console.log('fetchProductDetailsSaga catch error', error)
        digitalDataAPIError('fetchProductDetailsSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');
        yield put(cart.actions.fetchProductDetailsFailure({}));
    }
}

function* fetchProductDetailsSagaConfig() {
    yield takeEvery(cart.actions.fetchProductDetails, fetchProductDetailsSaga);
}

export {
    fetchProductDetailsSagaConfig,
}

