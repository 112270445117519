import {put, select, call, takeEvery} from 'redux-saga/effects';
import { trackEvent } from '../../utils/utils';

import {
    fetchToken,
    fetchEnglishLitepayContent,
    fetchSpanishLitepayContent,
    fetchCustomerDashboard,
    fetchTaxData,
    refillPrepaidAccount,
    addPaymentInstrument,
} from './apis';

import {litepay} from './store';
import { digitalDataAPIError } from '../../utils/adobe-analytics';

const getTrackingId = (state) => state?.litepay?.trackingId
const getPhoneNumber = (state) => state?.litepay?.tn
const getZipCode = (state) => state?.litepay?.billingZipLookup
const getGeoCode = (state) => state?.litepay?.dashboard?.customerInfo?.geoCode
const getSessionId = (state) => state?.common?.sessionId


function* fetchTokenSaga({payload}) {
    console.log('fetchTokenSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchToken, {trackingId});
        yield put(litepay?.actions?.fetchTokenSuccess(response));
    } catch (error: any) {
        console.log('token catch error', error)

        digitalDataAPIError('fetchTokenSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.fetchTokenFailure({}));
    }
}

function* fetchTokenSagaConfig() {
    yield takeEvery(litepay?.actions?.fetchToken, fetchTokenSaga);
}

function* fetchEnglishLitepayContentSaga({payload}) {
    console.log('🇺🇸 fetchEnglishLitepayContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchEnglishLitepayContent, {trackingId});
        yield put(litepay?.actions?.fetchEnglishLitepayContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error?.response?.data.errorCode
        const errorMessage = error?.response?.data.errorMessage

        digitalDataAPIError('fetchEnglishLitepayContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.fetchEnglishLitepayContentFailure({errorCode, errorMessage}));
    }
}

function* fetchEnglishLitepayContentSagaConfig() {
    yield takeEvery(litepay?.actions?.fetchEnglishLitepayContent, fetchEnglishLitepayContentSaga);
}

function* fetchSpanishLitepayContentSaga({payload}) {
    console.log('🇲🇽 fetchSpanishLitepayContentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(fetchSpanishLitepayContent, {trackingId});
        yield put(litepay?.actions?.fetchSpanishLitepayContentSuccess(response));
    } catch (error: any) {
        console.log('Error : ', error);
        const errorCode = error?.response?.data.errorCode
        const errorMessage = error?.response?.data.errorMessage

        digitalDataAPIError('fetchSpanishLitepayContentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.fetchSpanishLitepayContentFailure({errorCode, errorMessage}));
    }
}

function* fetchSpanishLitepayContentSagaConfig() {
    yield takeEvery(litepay?.actions?.fetchSpanishLitepayContent, fetchSpanishLitepayContentSaga);
}


function* fetchCustomerDashboardSaga({payload}) {
    console.log('fetchCustomerDashboardSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const phoneNumber = yield select(getPhoneNumber)
        const zipCode = yield select(getZipCode)
        const response = yield call(fetchCustomerDashboard, {...payload, trackingId, phoneNumber, zipCode});
        if (response.response.customerInfo?.prepaidAccountStatus === 'Preactivated') {
            yield put(litepay.actions.fetchCustomerDashboardFailure({errorCode: 'PREACTIVATED_ERROR', errorMessage: 'ERROR'}));
        } else {
            yield put(litepay.actions.fetchCustomerDashboardSuccess(response.response));
        }
    } catch (error:any) {
        console.log('Error : ', error);
        let errorCode = null, errorMessage = null;
        if (error.response && error.response?.data) {
            errorCode = error.response?.data.errorCode
            errorMessage = error.response?.data.errorMessage
        }

        digitalDataAPIError('fetchCustomerDashboardSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.fetchCustomerDashboardFailure({errorCode, errorMessage}));
    }
}

function* fetchCustomerDashboardSagaConfig() {
    yield takeEvery(litepay?.actions?.fetchCustomerDashboard, fetchCustomerDashboardSaga);
}

function* fetchTaxDataSaga({payload}) {
    console.log('fetchTaxDataSaga')
    try {
        const trackingId = yield select(getTrackingId)

        const geoCode = yield select(getGeoCode)

        const response = yield call(fetchTaxData, {...payload, geoCode, trackingId})
        yield put(litepay?.actions?.fetchTaxDataSuccess(response))
    } catch (error: any) {
        console.error('fetchTaxDataSaga error', error)

        digitalDataAPIError('fetchTaxDataSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.fetchTaxDataFailure({response: error.response?.data}))
    }
}

function* fetchTaxDataSagaConfig() {
    yield takeEvery(litepay?.actions?.fetchTaxData, fetchTaxDataSaga)
}


function* refillPrepaidAccountSaga({payload}) {
    console.log('refillPrepaidAccountSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const response = yield call(refillPrepaidAccount, {payload, trackingId})
        if (response.errorCode) {
            yield put(litepay?.actions?.refillPrepaidAccountFailure(response))
        } else {
            yield put(litepay?.actions?.refillPrepaidAccountSuccess(response))
        }
    } catch (error: any) {
        console.error('refillPrepaidAccountSaga error', error)
        trackEvent({
            event: 'litepay info',
            litepayStep: 'step 3',
            litepayStepName: 'order review',
            litepayEvent: 'error',
            litepayDetail: 'api'
        })

        digitalDataAPIError('refillPrepaidAccountSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.refillPrepaidAccountFailure(error.response ? error.response?.data : {}))
    }
}

function* refillPrepaidAccountSagaConfig() {
    yield takeEvery(litepay?.actions?.refillPrepaidAccount, refillPrepaidAccountSaga)
}

function* addPaymentInstrumentSaga({payload}) {
    console.log('addPaymentInstrumentSaga')
    try {
        const trackingId = yield select(getTrackingId)
        const sessionId = yield select(getSessionId)
        const response = yield call(addPaymentInstrument, {payload, trackingId, sessionId})

        if (response.businessErrorCode) {
            yield put(litepay?.actions?.addPaymentInstrumentFailure(response))
        } else {
            yield put(litepay?.actions?.addPaymentInstrumentSuccess(response))
        }
    } catch (error:any) {
        console.log('addPaymentInstrumentSaga error', error)

        digitalDataAPIError('addPaymentInstrumentSaga', error?.response?.data?.errorCode, error?.response?.data?.errorMessage, 'body');

        yield put(litepay?.actions?.addPaymentInstrumentFailure(error.response?.data))
    }
}

function* addPaymentInstrumentSagaConfig() {
    yield takeEvery(litepay?.actions?.addPaymentInstrument, addPaymentInstrumentSaga)
}

export {
    fetchTokenSagaConfig,
    fetchTokenSaga,
    fetchEnglishLitepayContentSagaConfig,
    fetchEnglishLitepayContentSaga,
    fetchSpanishLitepayContentSagaConfig,
    fetchSpanishLitepayContentSaga,
    fetchCustomerDashboardSagaConfig,
    fetchCustomerDashboardSaga,
    fetchTaxDataSagaConfig,
    fetchTaxDataSaga,
    refillPrepaidAccountSagaConfig,
    refillPrepaidAccountSaga,
    addPaymentInstrumentSagaConfig,
    addPaymentInstrumentSaga
}

