import DashboardLayout from '../../layouts/DashboardLayout'
import LinkList from '../../components/dashboard/LinkList'
import UserInformation from '../../components/dashboard/UserInformation'
import CommunicationPreferences from '../../components/dashboard/CommunicationPreferences'
import withDashboard from '../../redux/connectors/dashboard'
import PromoBanner from '../../components/dashboard/PromoBanner'
import WinbackBanner from '../../components/refill/WinbackBanner'
import { useEffect } from 'react'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const AccountInformation = (props) => {
    //console.log('props',JSON.stringify(props))
    const {
        dashboard,
        dashboardContent,
        deactivatedAccount,
        dictionaryContent,
        haveScheduledMove, 
        haveValidPromo,
        identity,
        isWinbackPromo,
        locale,
        pronoun,
        chosenName
    } = props
    const accountContent = { dashboard, dashboardContent, dictionaryContent, identity, pronoun, chosenName }
    const links = dashboardContent?.accountInfoArea?.myInformationCommunications?.additionalItems

    useEffect(() => {
        props.updatePageClass('dashboard dashboard-account-information')
    }, []) // eslint-disable-line

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Account Information'}>
            <TrackUserData data={dashboard} />
            {haveValidPromo && !deactivatedAccount && !isWinbackPromo &&
                <PromoBanner title={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.title} body={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.body} link={`/${locale}/refill`} />
            }
            {haveValidPromo && !deactivatedAccount && isWinbackPromo &&
                <WinbackBanner />
            }
            <div  className="main__width-constraint">
            <h1 className="page__title">{dashboardContent?.accountInfoArea?.myInformationCommunications?.title}</h1>
                <UserInformation content={accountContent} />
                <CommunicationPreferences />

                {links && links.icons && <div className="card activation-page">
                    <LinkList content={links} locale={locale} haveScheduledMove={haveScheduledMove} />
                </div>}
            </div>
            <TrackPageComplete />
        </DashboardLayout>
    )
}
export default withDashboard(AccountInformation)