import Header from '../components/activation/Header'
import Footer from '../components/Footer'
// import Head from "next/head"
// import ContentContext from '../lib/ContentContext'
// import ActivationWrapper from './ActivationWrapper'
import withActivation from '../redux/connectors/activation'
import {useEffect} from 'react'
import { Helmet } from 'react-helmet'

// 60s in a min x 15 mins x 1000 (to convert to milliseconds)
const sessionTimeout = 60 * 15 * 1000;
let sessionTimer;

const performLogout = () => {
    console.log('session timeout, logging out')
    window.location.href = "https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
}

const ActivationLayout = props => {
    const {fetchedContent, lastActionAt} = props
    useEffect(() => {
        if (sessionTimer) {
            // console.log('resetting timer')
            clearTimeout(sessionTimer)
            sessionTimer = null
        }
        sessionTimer = setTimeout(performLogout, sessionTimeout)
    }, [lastActionAt])

    const clientSide = (typeof window !== "undefined")
    // Always show error page even if content isn't loaded
    const errorPage = clientSide && window.location.pathname.includes('/error')

    return !fetchedContent && !errorPage ? <div /> :
        <div className={`activation-page ${props.wrapperClass ? props.wrapperClass : ''}`}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Activation - Xfinity Prepaid</title>
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Header />
            <main>
                <div id="main" role="main" className="main">
                    <div className="main__width-constraint">
                        {props.children}
                    </div>
                </div>
            </main>
            {!props.inApp && <Footer />}
        </div>
}

export default withActivation(ActivationLayout);
