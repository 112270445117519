import { useState, useRef } from 'react'
import Modal from 'react-modal'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const WelcomeModal = ({showModal,isTestFile}) => {

    const screens = {
        "step0": {
            "primaryButtonText": "Take a Tour",
            "secondaryButtonText": "Continue to my prepaid Account",
            "text": "<h1>Welcome to Xfinity Prepaid</h1><p>Go surf, stream, shop and share!</p>",
            "title": "Activation Successful",
            "imgUrl": "../../site-assets/img/icon-check-circle.svg"
            },
            "step1": {
            "primaryButtonText": "Next",
            "secondaryButtonText": "Back",
            "text": "<div class='welcome-modal__title'>Connect your modem to your cable outlet.</div><p>From your new dashboard, we'll show you the status of your current services.</p>",
            "title": "Step 1",
            "imgUrl": "../../site-assets/placeholder/SINGLE_Activate_HSDOnly_1.gif"
            },
            "step2": {
            "primaryButtonText": "Next",
            "secondaryButtonText": "Previous",
            "text": "<div class='welcome-modal__title'>Easy refills on the go</div><p>We'll make it easy to refill your service(s) with just a few clicks.</p>",
            "title": "Step 2",
            "imgUrl": "../../site-assets/placeholder/SINGLE_Activate_HSDOnly_2.gif"
            },
            "step3": {
            "primaryButtonText": "Next",
            "secondaryButtonText": "Previous",
            "text": "<div class='welcome-modal__title'>Special offers and promotions</div><p>Plus, we'll show you relevant offers & promotions so you never miss a deal.</p>",
            "title": "Step 3",
            "imgUrl": "../../site-assets/placeholder/SINGLE_Activate_HSDOnly_3.gif"
            },
            "step4": {
            "primaryButtonText": "Continue to Account",
            "secondaryButtonText": "Previous",
            "text": "<div class='welcome-modal__title'>We're here to help</div><p>Get answers to your questions or reset your device if you're having trouble connecting.</p>",
            "title": "Step 3",
            "imgUrl": "../../site-assets/placeholder/SINGLE_Activate_HSDOnly_4.gif"
            }
        }

    const [modalIsOpen, setModalIsOpen] = useState(showModal)
    const [currentStep, setCurrentStep] = useState(0)
    const [activeScreen, setActiveScreen] = useState(screens['step0'])
    const totalScreens = Object.keys(screens).length - 1
    const [contentRef, setContentRef] = useState(Object)
    const prevBtn = useRef<HTMLButtonElement | null>(null)
    const nextBtn = useRef<HTMLButtonElement | null>(null)

    const closeModal = () => setModalIsOpen(false)

    const setStep = (step) => {
        const thisStep = step || 0;
        setCurrentStep(thisStep)
        setActiveScreen(screens['step' + thisStep])
        if (step) resetScreen()
    }

    const resetScreen = () => {
        if (contentRef !== null && prevBtn.current !== null && nextBtn.current !== null) {
            !isTestFile && contentRef.scrollTo(0, 0)
            prevBtn.current.blur()
            nextBtn.current.blur()
        }
    }

    return (
        <div className="welcome-modal-wrapper">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                onAfterClose={setStep}
                contentLabel="Welcome to Xfinity Prepaid tour"
                className="modal modal--visible welcome-modal"
                overlayClassName="support-modal__overlay"
                contentRef={(node) => setContentRef(node)}
            >
                <div className="modal__content">
                    { currentStep === 0 ?
                    <div className="modal__body first-step">
                        <img className="success" src={activeScreen.imgUrl} alt="test"></img>
                        <div className="success-title">{activeScreen.title}</div>
                        <div className="support-modal__content" dangerouslySetInnerHTML={{__html: activeScreen.text}} />
                        <div>
                                <button data-testid="01-primary"
                                    onClick={() => { currentStep === totalScreens ? closeModal() : setStep(currentStep + 1) }}
                                    className="btn-next button"
                                    ref={nextBtn}
                                    data-tracking={digitalDataButtonClick('next-step', activeScreen.primaryButtonText)}
                                >{activeScreen.primaryButtonText}</button>
                                <button
                                    onClick={closeModal}
                                    className="btn-prev button--link"
                                    ref={prevBtn}
                                    data-tracking={digitalDataButtonClick('previous-step', activeScreen.secondaryButtonText)}
                                >{activeScreen.secondaryButtonText}</button>
                            </div>
                        <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', 'Close', true)}>
                            Close
                            <img title="close" alt="" className="icon" src='/site-assets/img/icon-x.svg' />
                        </button>
                    </div>
                        :
                    <div>
                        <div className="welcome-modal__header">
                            <div className="step">Step {currentStep}/{totalScreens}</div>
                        </div>
                        <div className="modal__body">
                            <img className="animation" src={activeScreen.imgUrl} alt="test"></img>
                            <div className="support-modal__content" dangerouslySetInnerHTML={{__html: activeScreen.text}} />
                            <div className="support-modal__footer">
                                <button data-testid="01-secondary"
                                    onClick={() => { setStep(currentStep - 1) }}
                                    className="btn-prev button--link"
                                    ref={prevBtn}
                                    data-tracking={digitalDataButtonClick('previous-step', activeScreen.secondaryButtonText)}
                                >{activeScreen.secondaryButtonText}</button>
                                <button
                                    onClick={() => { currentStep === totalScreens ? closeModal() : setStep(currentStep + 1) }}
                                    className={`btn-next button ${currentStep !== totalScreens ? 'button--hollow' : ''}`}
                                    ref={nextBtn}
                                    data-tracking={digitalDataButtonClick('next-step', activeScreen.primaryButtonText)}
                                >{activeScreen.primaryButtonText}</button>
                            </div>
                            <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', 'Close', true)}>
                                Close
                                <img title="close" alt="" className="icon" src='/site-assets/img/icon-x.svg' />
                            </button>
                        </div>
                    </div>
                    }
                </div>
            </Modal>
        </div>
    )

}

Modal.setAppElement('body')

export default WelcomeModal
