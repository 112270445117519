import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import StatusCircle from "../dashboard/StatusCircle";
import AutoRefillModal from "../dashboard/AutoRefillModal";
import { GlobalModal as Modal } from "../Modal";
// import NotificationBanner from '../../components/NotificationBanner'
import withDashboard from "../../redux/connectors/dashboard";
import { trackEvent } from "../../utils/utils";
import {
  digitalDataButtonClick,
  digitalDataLinkClick,
} from "../../utils/adobe-analytics";

const ServiceStatus = ({
  dashboardContent,
  moveContent,
  dictionaryContent,
  currentLocale,
  hsdService,
  autoRefill,
  isMoving,
  deactivatedAccount,
  reconnectContent,
  isPaused,
  autoRefillEnabled,
}) => {
  const getCircleColor = (days) => {
    if (isPaused) {
      return "#2b9cd8";
    } else {
      if (days <= 10 && days > 0 && !autoRefill) {
        // orange
        return "#FFA700";
      } else if (days <= 0 && !autoRefill) {
        // red
        return "#c90318";
      } else {
        // green
        return "#47C684";
      }
    }
  };
  const history = useHistory();

  const trackClick = () => {
    if (isMoving) {
      trackEvent({
        event: "move info",
        moveStep: "step 2",
        moveStepName: "move review",
        moveEvent: "refill now",
        moveDetail: "internet",
      });
    }
  };

  const goToRefill = (e) => {
    e.preventDefault();
    e.stopPropagation();
    trackClick();
    history?.push(`/${currentLocale}/refill`);
  };
  const renderRefillButton = (days, component) => {
    if ((days <= 10 && !autoRefill && !isMoving) || days <= 0) {
      return (
        <button
          data-testid="refill-now"
          className="button button--hollow-white"
          onClick={(e) => {
            goToRefill(e);
            sendRefillAnalytics(component);
          }}
          data-tracking={digitalDataButtonClick(
            "refill",
            dashboardContent?.dashboardArea?.prepaidServices?.refillNow.text
          )}
        >
          {dashboardContent?.dashboardArea?.prepaidServices?.refillNow.text}
        </button>
      );
    } else {
      return null;
    }
  };
  const goToReconnect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    trackEvent({
      event: "dashboard",
      dashboardPage: "reconnect",
      dashboardEvent: "reactivate",
      dashboardDetail: "",
    });
    history?.push(`/${currentLocale}/reconnect`);
  };
  const renderReconnectButton = () => {
    return (
      <button
        data-testid="btn-reconnect"
        className="button"
        onClick={goToReconnect}
        data-tracking={digitalDataButtonClick(
          "reactivate-account",
          reconnectContent?.step1?.reactivateYourAccount
        )}
      >
        {reconnectContent?.step1?.reactivateYourAccount}
      </button>
    );
  };
  const sendAnalytics = (component) => {
    trackEvent({
      event: "dashboard",
      dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
      dashboardEvent: "services component",
      dashboardDetail: component,
    });
  };

  const sendRefillAnalytics = (component) => {
    if (!isMoving) {
      trackEvent({
        event: "dashboard",
        dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
        dashboardEvent: "refill now",
        dashboardDetail: component,
      });
    }
  };

  const checkInternetStatus = () => {
    // TODO: if props.moving is true establish /move/ as back destination
    // Active Service

    if (hsdService?.daysRemaining > 0 && !deactivatedAccount) {
      // Active Service
      // console.log("Active Service");
      if (isMoving) {
        // console.log("Active Service + Moving");

        return (
          <div className="service-status__active">
            <div className="service-status__title service-status__title--warning">
              All active Prepaid services will be paused once your move request
              has been started. Services will be unpaused once the activation
              process is complete.
            </div>
            {moveContent?.acP?.youWillNeedToReEnrollInAcP && (
              <div className="service-status__title service-status__title--warning">
                <div
                  dangerouslySetInnerHTML={{
                    __html: moveContent?.acP?.youWillNeedToReEnrollInAcP,
                  }}
                />
              </div>
            )}
            {moveContent?.acP?.ifYourNewAreaIsNotServiceable && (
              <div className="service-status__text">
                {moveContent?.acP?.ifYourNewAreaIsNotServiceable}
              </div>
            )}
            {/* eslint-disable-next-line */}
            {/* {props.moving ? <NotificationBanner text={"All active Prepaid services will be paused once your move request has started. Services will be unpaused once the activation process is complete"} displayStyle="alert-inline" modal="false" /> : <div></div>} */}

            {renderInternetStatus()}
          </div>
        );
        // Active Service + Standard Flow
      } else {
        // console.log("Active Service + Standard Flow");
        return (
          <div className="service-status__active">
            <div className="service-status__title">
              {dashboardContent?.dashboardArea?.prepaidServices?.title}
              {autoRefill && (
                <AutoRefillModal
                  title={
                    dashboardContent?.dashboardArea?.prepaidServiceStates
                      ?.autoRefillCurrentlyActive
                  }
                  ctaText={
                    dashboardContent?.dashboardArea?.prepaidServiceStates
                      ?.autoRefillOn?.text
                  }
                  onCancel={() => {
                    history.push(`/${currentLocale}/dashboard/auto-refill`);
                  }}
                />
              )}
            </div>
            {/* eslint-disable-next-line */}
            {/* {props.moving ? <NotificationBanner text={"All active Prepaid services will be paused once your move request has started. Services will be unpaused once the activation process is complete"} displayStyle="alert-inline" modal="false" /> : <div></div>} */}

            {renderInternetStatus()}
          </div>
        );
      }
      //Service Expired
    } else {
      // console.log("Service Expired");
      //Service Expired + Account Deactivated
      if (deactivatedAccount) {
        //Service Expired + Account Deactivated + Moving
        if (isMoving) {
          // console.log("Service Expired + Account Deactivated + Moving");
          return (
            <div className="service-status__expired">
              <img
                title="expired"
                className="icon icon-expired pulse"
                alt="service expired"
                src="/site-assets/img/icon-expired.svg"
              />
              <div className="service-status__expired-warning">
                {
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidServiceHasExpired
                }
              </div>
              <div className="service-status__recheck-warning">
                Once you have refilled, you will have to return to the dashboard
                and start the move process over again.
              </div>
              {renderRefillButton(hsdService.daysRemaining, "internet")}
              <Modal
                type={"move"}
                ctaText={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.whatDoesThisMean?.text
                }
                buttonClasses="button--link"
                title={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidInternetHasExpired?.title
                }
                body={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidInternetHasExpired?.text
                }
              />
            </div>
          );
          //Service Expired + Account Deactivated
        } else {
          // console.log("Service Expired + Account Deactivated");
          return (
            <div className="service-status__expired">
              <img
                title="expired"
                className="icon icon-expired pulse"
                alt="service expired"
                src="/site-assets/img/icon-expired.svg"
              />
              <div className="service-status__expired-warning">
                {
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidServiceHasExpired
                }
              </div>

              <Modal
                type="reconnect-dashboard"
                ctaText={
                  reconnectContent?.accountDashboard?.whatDoesThisMeanModal
                    ?.title
                }
                buttonClasses="button--link"
                title={
                  reconnectContent?.accountDashboard?.whatDoesThisMeanModal
                    ?.title
                }
                body={
                  reconnectContent?.accountDashboard?.whatDoesThisMeanModal.text
                }
              />
              {renderReconnectButton()}
              {/* <div className="service-status__promo">Save 50% on your first 30-day Prepaid Internet Refill</div> */}
            </div>
          );
        }
        //Service Expired + Account Active
      } else {
        // console.log("Service Expired + Account Active");
        //Service Expired + Account Active + Moving
        if (isMoving) {
          // console.log("Service Expired + Account Active + Moving");

          return (
            <div className="service-status__expired">
              <img
                title="expired"
                className="icon icon-expired pulse"
                alt="service expired"
                src="/site-assets/img/icon-expired.svg"
              />
              <div className="service-status__expired-warning">
                {
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidServiceHasExpired
                }
              </div>
              <p className="service-status__recheck-warning">
                After refill you will need to recheck your address
              </p>

              <Modal
                ctaText={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.whatDoesThisMean?.text
                }
                buttonClasses="button--link"
                title={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidInternetHasExpired?.title
                }
                body={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidInternetHasExpired?.text
                }
              />
            </div>
          );
          //Service Expired + Account Active
        } else {
          // console.log("Service Expired + Account Active");
          return (
            <div className="service-status__expired">
              <img
                title="expired"
                className="icon icon-expired pulse"
                alt="service expired"
                src="/site-assets/img/icon-expired.svg"
              />
              <div className="service-status__expired-warning">
                {
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidServiceHasExpired
                }
              </div>
              <Modal
                ctaText={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.whatDoesThisMean?.text
                }
                buttonClasses="button--link"
                title={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidInternetHasExpired?.title
                }
                body={
                  dashboardContent?.dashboardArea?.prepaidServiceStates
                    ?.prepaidInternetHasExpired?.text
                }
              />
              {renderRefillButton(hsdService.daysRemaining, "internet")}
            </div>
          );
        }
      }
    }
  };
  const renderInternetStatus = () => {
    //TODO: if props.moving is true establish /move/ as back destination

    if (hsdService.daysRemaining > 0) {
      return (
        <div className="service-status__active-item service-status__active-item-internet">
          <StatusCircle
            daysLeft={hsdService.daysRemaining}
            radius={45}
            stroke={2}
            strokeColor={getCircleColor(hsdService.daysRemaining)}
            showTooltip={false}
            tooltipMessage={
              dashboardContent?.dashboardArea?.prepaidServiceStates
                ?.activePrepaidInternetIsRequiredToUsePrepaidAddOns?.title
            }
          />
          {!isMoving && !isPaused ? (
            <Link
              to={`/${currentLocale}/dashboard/detail-hsd`}
              className="service-status__active-item-content"
              onClick={() => sendAnalytics("internet")}
              data-tracking={digitalDataLinkClick(
                "service-status-active",
                `/${currentLocale}/dashboard/detail-hsd`,
                "Internet"
              )}
            >
              <div className="title">Internet</div>
              <div className="expiration">
                {dictionaryContent?.global?.expires} {hsdService.expirationDate}
              </div>
              {renderRefillButton(hsdService.daysRemaining, "internet")}
            </Link>
          ) : (
            <div className="service-status__active-item-content">
              <div className="title">Internet</div>
              <div className="expiration">
                {dictionaryContent?.global?.expires} {hsdService.expirationDate}
              </div>
              {renderRefillButton(hsdService.daysRemaining, "internet")}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="service-status__active-item service-status__active-item-internet">
          <div className="status-circle">
            <img
              alt="expired"
              className="icon icon-expired pulse"
              src="/site-assets/img/icon-expired.svg"
            />
          </div>
          <Link
            to={`/${currentLocale}/dashboard/detail-hsd`}
            className="service-status__active-item-content"
            onClick={() => sendAnalytics("internet")}
            data-tracking={digitalDataLinkClick(
              "service-status-active",
              `/${currentLocale}/dashboard/detail-hsd`,
              "Internet"
            )}
          >
            <div className="title">Internet</div>
            <div className="expiration">
              {dictionaryContent?.global?.expired} {hsdService.expirationDate}
            </div>
            {renderRefillButton(hsdService.daysRemaining, "internet")}
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="service-status">
      <div className="main__width-constraint">{checkInternetStatus()}</div>
      <div className="autoEnrollSection">
        {!autoRefillEnabled &&
          !deactivatedAccount &&
          hsdService?.daysRemaining > 0 && (
            <a
              className="button button--inline"
              href={`/${currentLocale}/dashboard/auto-refill`}
              data-tracking={digitalDataLinkClick(
                "service-status-auto-enroll",
                `/${currentLocale}/dashboard/auto-refill`,
                "enrollAutoRefill"
              )}
            >{`${
              !autoRefillEnabled
                ? dashboardContent?.dashboardArea?.autoRefill
                    ?.enrollInAutoRefill?.text
                : dashboardContent?.dashboardArea?.autoRefill?.cancelAutoRefill
                    ?.text
            }`}</a>
          )}
      </div>
    </div>
  );
};

export default withDashboard(ServiceStatus);
