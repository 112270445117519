import { useEffect, useState, useRef} from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import withLitepay from '../../redux/connectors/litepay'
import { digitalDataButtonClick, digitalDataLinkClick } from '../../utils/adobe-analytics'

function Header(props) {

    const {
        currentLocale,
        updateLocale,
        menu
    } = props

    const node = useRef<HTMLUListElement | null>(null)

    const [activeIndex, setActiveIndex] = useState(null)
    const [activeMobileIndex, setActiveMobileIndex] = useState(2)
    const [showMobileMenu, setMobileMenu] = useState(false)

    const calculatePath = () => {
        let url = ''
        if (currentLocale === 'es') url = window.location.pathname.replace('/es/', '/en/')
        else url = window.location.pathname.replace('/en/', '/es/')

        return url
    }

    const updateLanguage = () => {
        updateLocale(currentLocale)
    }

    useEffect(() => {
        updateLanguage()
    }, [currentLocale]) // eslint-disable-line

    const onMenuClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null)
        } else {
            setActiveIndex(index)
        }
    }

    const captureEnterKey = (event, index) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            onMenuClick(index)
        }
    }
    
    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        setActiveIndex(null)
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => {
            document.removeEventListener("mousedown", handleClick)
        }
    }, []) // eslint-disable-line

    const toggleMobileMenu = () => {
        if (showMobileMenu) {
            setMobileMenu(false)
            // document.body.className = document.body.className.replace(new RegExp(/modal-open/g), '')
            document.body.classList.remove('modal-open')
        } else {
            setMobileMenu(true)
            // document.body.className += ' modal-open'
            document.body.classList.add('modal-open')
        }
    }
    const onMobileMenuClick = (index) => {
        if (activeIndex === index) {
            setActiveMobileIndex(-1)
        } else {
            setActiveMobileIndex(index)
        }
    }

    const renderDropdown = (item, index) => {
        const renderedDropdownLinks = item.list.map((subItem, index) => {
            return (
                    <li key={index} className="main-flyout__item ">
                        <a href={subItem.link.url} className="main-flyout__link" target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('main-flyout', subItem.link.url, subItem.linkText)}>{subItem.linkText}</a>
                    </li>
            )
        })
        return (
            <React.Fragment>
                <div className="accordion__icon js-accordion-icon">
                    <span className="line" />
                    <span className="line" />
                </div>
                {/* eslint-disable-next-line */}
                <ul
                    aria-expanded={activeIndex === index ? 'true' : 'false'}
                    aria-hidden={activeIndex === index ? 'false' : 'true'}
                    className="main-flyout"
                    aria-label="main-flyout"
                    role="group"
                >
                    {renderedDropdownLinks}
                </ul>
            </React.Fragment>
        )
    }

    const renderMobileSubnav = (item, index) => {
        const renderedDropdownLinks = item.list.map((subItem, index) => {
            return (
                <li key={index} className="off-canvas-menu__item ">
                    <a href={subItem.link.url} className="off-canvas-menu__link" target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('off-canvas-menu-item', subItem.link.url, subItem.linkText)}>{subItem.linkText}</a>
                </li>
            )
        })
        return (
            <React.Fragment>
                {/* eslint-disable-next-line */}
                <ul
                    aria-expanded={activeMobileIndex === index ? 'true' : 'false'}
                    aria-hidden={activeMobileIndex === index ? 'false' : 'true'}
                    className="off-canvas-menu__submenu"
                    aria-label="main-flyout"
                    role="group"
                >
                    {renderedDropdownLinks}
                </ul>
            </React.Fragment>
        )
    }

    const renderedMobileMenu = menu?.navigation?.map((item, index) => {
            if (item.list) {
            return (
            <React.Fragment key={index}>
                <li data-testid="link-text" className={`off-canvas-menu__link ${activeMobileIndex === index ? "active" : ""}`} onClick={() => onMobileMenuClick(index)} data-tracking={digitalDataButtonClick('render-mobile-subnav', item.linkText)}>
                    <span tabIndex={0}>{item.linkText}</span>
                    {renderMobileSubnav(item, index)}
                </li>
            </React.Fragment>
            )
        } else {
            if (item.linkType !== 'chat') {
                return (
                <React.Fragment key={index}>
                    <li className="off-canvas-menu__link">
                        <a href={item.link.url} target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('off-canvas-menu', item.link.url, item.linkText)}>{item.linkText}</a>
                        {/* XXX these links are external. <a> is correct
                        <Link to={`/${currentLocale}${item.link.url}`} target="_blank" rel="noreferrer">{item.linkText}</Link>*/}
                    </li>
                </React.Fragment>
                )
            } else return null
        }
    })

    const renderedBottomLinks = menu?.navigation?.map((item, index) => {
        if (item.list) {
            return (
            <React.Fragment key={index}>
                <li className={`main-nav__item ${activeIndex === index ? "is_open" : ""} ${index === 2 ? 'active' : ''}`}>
                    <span data-testid="nav-link" tabIndex={0} className="main-nav__link" onClick={() => onMenuClick(index)} onKeyDown={(e) => captureEnterKey(e, index)} data-tracking={digitalDataButtonClick('render-dropdown', item.linkText)}>{item.linkText}</span>
                    {renderDropdown(item, index)}
                </li>
            </React.Fragment>
            )
        } else {
            if (item.linkType !== 'chat') {
                return (
                <React.Fragment key={index}>
                    <li className="main-nav__item">
                        <a href={item.link.url} className="main-nav__link" target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('main-flyout', item.link.url, item.linkText)}>{item.linkText}</a>
                        {/* XXX these links are external. <a> is correct
                        <Link to={`/${currentLocale}${item.link.url}`} className="main-nav__link" target="_blank" rel="noreferrer">{item.linkText}</Link>*/}
                    </li>
                </React.Fragment>
                )
            } else return null
        }
    })

    const navigateToUrl = () => {
        const url = calculatePath();
        window.location.href = url;
    };

    const handleButtonClick = () => {
        updateLanguage();
        navigateToUrl();
    };

    return (
        <div className="header">
            <header role="banner">
                <div className="header__top">
                    <div className="header__width-constraint">
                        <a className="vh" href="#main" data-tracking={digitalDataLinkClick('skip-to-content', '#main', 'Skip to Content')}>Skip to Content</a>
                        <div className="header__left">
                            <a href={menu?.logo.url}className="xfinity-logo" target="_blank" rel="noopener noreferrer" data-tracking={digitalDataLinkClick('menu-logo', menu?.logo.url, 'xfinity')}><span className='vh'>xfinity</span></a>
                            <a href={menu?.logo.secondaryUrl} className="xfinity-logo secondary" target="_blank" rel="noopener noreferrer" data-tracking={digitalDataLinkClick('secondary-logo', menu?.logo.secondaryUrl, 'xfinity prepaid')}><span className='vh'>xfinity prepaid</span></a>
                        </div>
                        <nav className="header__right" aria-label="Utility Navigation">
                        <ul className="header-language__list header-language__list-desktop">
                            <li className="header-language__item">
                                <button type="button" className='header-language-switch' data-testid="language" onClick={() => handleButtonClick()} data-tracking={digitalDataLinkClick('language', calculatePath(), currentLocale === 'en' ? 'Español' : 'English')}>{currentLocale === 'en' ? 'Español' : 'English'}</button>
                            </li>
                        </ul>                
                            <a className='header__account-link header__account-link-logout header__account-link-desktop' href={menu?.account?.link?.url} data-tracking={digitalDataLinkClick('account', menu?.account?.link?.url, menu?.account?.linkText)}>{menu?.account?.linkText}</a>
                            <button
                                aria-expanded={showMobileMenu}
                                className="offcanvas-trigger dashboard"
                                type="button"
                                data-toggle="offCanvasTop"
                                aria-controls="offCanvasTop"
                                data-testid="toggle-menu-button"
                                onClick={() => {toggleMobileMenu()}}
                                data-tracking={digitalDataButtonClick('mobile-menu', 'Menu')}
                            >
                                <span className="offcanvas-trigger-text vh">Menu</span>
                                <div className="hamburger">
                                <span className="line" />
                                <span className="line" />
                                <span className="line" />
                                </div>
                            </button>
                        </nav>
                    </div>
                </div>
                <div className="header__bottom">
                    <div className="wrapper">
                        <nav className="main-nav" role="navigation">
                            <ul ref={node} className="main-nav__list">
                                {renderedBottomLinks}
                            </ul>
                        </nav>
                    </div>
                </div>
                <div
                    id="offCanvasTop"
                    className={`offcanvas off-canvas position-top is-transition-push ${showMobileMenu ? "is-open" : ""}`}
                    data-off-canvas="m2xy2f-off-canvas"
                    data-content-scroll="false"
                    aria-hidden="false"
                >
                    <ul className="vertical menu" data-accordion-menu="">
                        {renderedMobileMenu}
                        <li className="off-canvas-menu__item login-button logged-out-visible">
                            <a href={menu?.refill?.link?.url} className="off-canvas-menu__link" data-tracking={digitalDataLinkClick('refill', menu?.refill?.link?.url, menu?.refill?.linkText)}>
                                { menu?.refill?.linkText }
                            </a>
                        </li>
                        <li className="off-canvas-menu__item login-button logged-out-visible">
                            <a href={menu?.account?.link?.url} className="off-canvas-menu__link" data-tracking={digitalDataLinkClick('account', menu?.account?.link?.url, menu?.account?.linkText)}>
                                { menu?.account?.linkText }
                            </a>
                        </li>
                    </ul>
                    <ul className="language-list" data-current-language="en">
                        Language
                        <li className="language-list__item spanish">
                            <button type="button" className="language-list__link js-data-track" data-testid="language-list" onClick={() => handleButtonClick()} data-tracking={digitalDataLinkClick('language', calculatePath(), currentLocale === 'en' ? 'Español' : 'English')}>{currentLocale === 'en' ? 'Español' : 'English'}</button>
                        </li>
                    </ul>
                </div>
            </header>
        </div>
    )
}

export default withLitepay(Header)
