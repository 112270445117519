import DashboardLayout from '../../layouts/DashboardLayout'
import ConflictModal from '../../components/ConflictModal'
import LinkList from '../../components/dashboard/LinkList'
import ServiceStatus from '../../components/dashboard/ServiceStatus'
import WelcomeModal from '../../components/dashboard/WelcomeModal'
import PromoBanner from '../../components/dashboard/PromoBanner'
import WinbackBanner from '../../components/refill/WinbackBanner'
import withDashboard from '../../redux/connectors/dashboard'
import Accordion from '../../components/Accordion'
import SuccessBanner from '../../components/SuccessBanner'
import { trackEvent } from '../../utils/utils'

import { useEffect, useState } from 'react'
import InfoStripe from '../../components/dashboard/InfoStripe'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

let reloadTimer,
    stripeText,
    stripeTitle,
    stripeAnalytics,
    stripePhone

const Dashboard = (props) => {
    const {
        accountId,
        autoRefillEnabled,
        cancelledScheduledMove,
        custGuid,
        dashboard,
        dashboardContent,
        deactivatedAccount,
        fetchedIdentity,
        haveScheduledMove,
        haveValidPromo,
        identity,
        identityAccounts,
        isWinbackPromo,
        locale,
        ready,
        reconnectContent,
        updateField,
        updateIDCMTCustomerInfo
    } = props

    // XXX TODO Move to state
    // const showConflictModal = false
    const showWelcomeModal = false

    const [showCancelSuccess, setShowCancelSuccess] = useState(false)
    const [showConflictModal, setShowConflictModal] = useState(false)
    const [haveEmailMatch, setHaveEmailMatch] = useState(false)
    const [havePhoneMatch, setHavePhoneMatch] = useState(false)

    useEffect(() => {
        props.updatePageClass('dashboard dashboard-home')
        sessionStorage.removeItem("refill-completed");
        sessionStorage.removeItem("errorReloadCount");
        return () => clearTimeout(reloadTimer)
    }, []) // eslint-disable-line



    // reload page if ready state hasn't updated automatically 
    useEffect(() => {
        // console.log('lastActionAt update', lastActionAt)
        if (reloadTimer) {
            // console.log('Resetting session timer...')
            clearTimeout(reloadTimer)
            reloadTimer = null
        }
        reloadTimer = setTimeout(() => {
            if (!ready) {
                sessionStorage.removeItem("errorReloadCount");
                window.location.reload()
            } else {
                return
            }
        }, 20000)
    }, [ready])

    useEffect(() => {
        if (cancelledScheduledMove) {
            setShowCancelSuccess(true)
            updateField({field: 'cancelledScheduledMove', value: false})
        }
    }, [cancelledScheduledMove, updateField])

    useEffect(() => {
        if (fetchedIdentity && identityAccounts && identityAccounts.length) {
            let syncUpRequired = false

            let acct = identityAccounts.find(acct => {
                return accountId === acct.accountNumber
            })

            if (acct) {
                setHaveEmailMatch(acct.isPrepaidEmailMatching === 'true')
                setHavePhoneMatch(acct.isPrepaidPhoneMatching === 'true')
                syncUpRequired = syncUpRequired || (acct.isPrepaidPhoneMatching === 'NA' && acct.isPrepaidEmailMatching === 'false')
                syncUpRequired = syncUpRequired || (acct.isPrepaidPhoneMatching === 'false' && acct.isPrepaidEmailMatching === 'NA')
                syncUpRequired = syncUpRequired || (acct.isPrepaidPhoneMatching === 'false' && acct.isPrepaidEmailMatching === 'false')
                syncUpRequired = syncUpRequired || (acct.isPrepaidPhoneMatching === 'true' && acct.isPrepaidEmailMatching === 'false')
                syncUpRequired = syncUpRequired || (acct.isPrepaidPhoneMatching === 'false' && acct.isPrepaidEmailMatching === 'true')

                if (syncUpRequired) {
                    const hasSeenSyncUpModal = sessionStorage.getItem('xpp_su')

                    if (!hasSeenSyncUpModal) {
                        sessionStorage.setItem('xpp_su', '1')
                        setShowConflictModal(true)
                    }
                }
            }
        }
    }, [fetchedIdentity, identityAccounts, accountId])

    /**
     * Gets the ACP content based on ACP status and customer type to feed into the InfoStripe component
    */
    useEffect(() => {
        switch(dashboard?.acpDetail?.acpStatus) {
            case 'Approved':
                if (haveScheduledMove) {
                    stripePhone = dashboardContent?.accountInfoArea?.acP?.phone
                    stripeTitle = dashboardContent?.accountInfoArea?.acP?.enrolled
                    stripeText = dashboardContent?.accountInfoArea?.acP?.moveIsComplete
      
                    break
                }
                else if (dashboard?.acpDetail?.customerType === 'Tribal') {
                    stripeTitle = dashboardContent?.accountInfoArea?.acP?.enrolledInAcPTribal
                    stripeText = dashboardContent?.accountInfoArea?.acP?.reimbursement
      
                    break
                }
                else {
                    stripeTitle = dashboardContent?.accountInfoArea?.acP?.enrolled
                    stripeText = dashboardContent?.accountInfoArea?.acP?.youWillReceive
      
                    break
                }
            case 'De Enrolled':
                stripeAnalytics = {
                    event: 'dashboard',
                    dashboardPage: 'account dashboard',
                    dashboardEvent: 'acp reenroll tile',
                    dashboardDetail: 'call'
                }
                stripePhone = dashboardContent?.accountInfoArea?.acP?.phone
                stripeTitle = dashboardContent?.accountInfoArea?.acP?.acPBenefitNotice
                stripeText = dashboardContent?.accountInfoArea?.acP?.xfinityPrepaidServiceStatusReenroll
      
                break
      
            default:
        }
    }, [dashboard?.acpDetail?.acpStatus]) // eslint-disable-line

    useEffect(() => {
        if (dashboard?.planStatusDetails?.[0]?.planStatus) {
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'service state',
                dashboardDetail: dashboard?.planStatusDetails?.[0]?.planStatus + '|' + dashboard?.planStatusDetails?.[0]?.expiryDate
            })
            trackEvent({
                event: 'acp customer',
                acpStatus: dashboard?.acpDetail?.acpStatus
            })
        }
        if (stripeAnalytics) {
            trackEvent({
                event: 'dashboard',
                dashboardPage: 'account dashboard',
                dashboardEvent: 'acp reenroll tile',
                dashboardDetail: 'impression'
            })
        }
    }, [dashboard?.acpDetail?.acpStatus]) // eslint-disable-line

    const resolveConflict = ({language, smsOptIn}) => {
        console.log('language', language, 'smsOptIn', smsOptIn)
        let data = {
            custGuid,
            accountId,
            languagePreference: language,
            smsOptIn: smsOptIn ? 'Y' : 'N'
        }
        if (!haveEmailMatch && identity.idcmtEmail) {
            data['emailId'] = identity.idcmtEmail
        }
        if (!havePhoneMatch && identity.idcmtMobile) {
            console.log('updating phone')
            data['mobileNo'] = identity.idcmtMobile
        }
        updateIDCMTCustomerInfo(data)
    }
    // determine if we have a promotion available to show
    // const [displayPromo, setDisplayPromo] = useState(false)
    // useEffect(() => {
    //     if (haveValidPromo) setDisplayPromo(true)
    // }, [haveValidPromo])

    const renderConflictModal = () => {
        return (
            <ConflictModal
                defaultOpen={showConflictModal}
                title=""
                modalCloseButton="Close"
                dashboard={dashboard}
                identity={identity}
                modalContent={dashboardContent?.dashboardArea?.syncUpModal}
                onSubmit={resolveConflict}
            />
        )
    }
    const renderPageComponents = () => {
        const links = dashboardContent?.dashboardArea?.collapsed?.helpfulLinks
        return (
            <div>
                {haveValidPromo && !deactivatedAccount && !isWinbackPromo &&
                    <PromoBanner title={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.title} body={dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.banner?.body} link={`/${locale}/refill`} />
                } 
                {haveValidPromo && !deactivatedAccount && isWinbackPromo &&
                    <WinbackBanner />
                }
                <WelcomeModal showModal={showWelcomeModal} isTestFile={false} />
                <ServiceStatus isMoving={false} isPaused={false} autoRefill={autoRefillEnabled} />
                {deactivatedAccount &&
                <div className="main__width-constraint">
                    <Accordion title={"Reconnect Support"}items={reconnectContent?.accountDashboard?.faQ} eventObj={{event: 'dasbhoard', accordionType: 'faq', accordionPage: 'dashboard reconnect', faqSubcategory: "Reconnect Support"}} type='reconnect' eventDetailCategory='accordionText' />
                </div>}
                {(dashboard?.acpDetail?.acpStatus === 'De Enrolled' || dashboard?.acpDetail?.acpStatus === 'Approved') &&
                    <InfoStripe Title={stripeTitle} Text={stripeText} Phone={stripePhone} analytics={stripeAnalytics} />
                }
                {!deactivatedAccount && links && links.icons &&
                <LinkList content={dashboardContent?.dashboardArea?.collapsed?.helpfulLinks} locale={locale} haveScheduledMove={haveScheduledMove} />}
            </div>
        )
    }

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Account Dashboard'} reconnectHeader={deactivatedAccount ? true : false}>
            <TrackUserData data={dashboard} />
            {showCancelSuccess && <SuccessBanner text={'Your scheduled move has been cancelled'} displayModal={true} />}
            { renderPageComponents() }
            { renderConflictModal() }
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(Dashboard)
