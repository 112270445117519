import withDashboard from '../../redux/connectors/dashboard'
import { GlobalModal as Modal } from '../Modal'

const CartRefillPromoDetail = (props) => {
    const {
        dashboardContent,
        refillContent
    } = props


    return (
        <>
            <tr>
                <td className="line-item__promo">
                    <div className="line-item__promo__top">
                        <div className="line-item__promo__top__text" dangerouslySetInnerHTML={{__html: dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.cartDetails?.offerText}} />
                    </div>
                    <div className="line-item__promo__bottom">
                        <p>{dashboardContent?.dashboardArea?.autoRefill?.refillPromotion?.cartDetails?.disclaimer}</p>
                        <Modal
                            ctaText={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                            buttonClasses='button--link--underline'
                            title={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                            body={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfoContent}
                        />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default withDashboard(CartRefillPromoDetail)