/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "xfinity-broadband-facts": any;
    }
  }
}
const BroadbandFacts = (props) => {
  const {
    tierId,
    servicePlanType,
    lineOfBusiness,
    sourceServerId,
    sourceSystemId,
  } = props;
  const [hidden, setHidden] = useState(true);
  const [activeButton, setButtonID] = useState("collapse");
  const readMoreClick = (val) => {
    setHidden(!hidden);
    setButtonID(val);
  };

  return (
    <div className="broadband-facts">
      <div className="width-100">
        <div className="card">
          <div className={"card-body " + activeButton}>
            <xfinity-broadband-facts
              tier-ids={tierId}
              service-plan-type={servicePlanType}
              line-of-business={lineOfBusiness}
              source-server-id={sourceServerId}
              source-system-id={sourceSystemId}
            ></xfinity-broadband-facts>
          </div>
          <div className="card-footer">
            {hidden ? (
              <button
                type="button"
                className="cima-label cima-unverified read-more-button-text readmore-less-arrow"
                onClick={() => {
                  readMoreClick("expand");
                }}
              >
                Read more
              </button>
            ) : (
              <button
                type="button"
                className="cima-label cima-unverified read-less-button-text readmore-less-arrow"
                onClick={() => {
                  readMoreClick("collapse");
                }}
              >
                Read less
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadbandFacts;
