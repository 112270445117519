import { useState, useEffect } from 'react'

const StatusCircle = (props) => {
    const radius = 45
    const stroke = 2
    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const [uiStrokeColor] = useState('#47C684')
    const [displayNumber, setDisplayNumber] = useState(0)
    const [, setProgress] = useState(0)
    const [strokeDashOffset, setStrokeDashOffset] = useState(circumference)
    const [daysLeft] = useState(props.days || 30)

    const startProgress = (initialProgress, initialDisplayNumber) => {
        // this.setState({ progress: 0, strokeDashOffset: 258, displayNumber: 0 })
        setProgress(0)
        setStrokeDashOffset(circumference)
        setDisplayNumber(0)

        const interval = setInterval(() => {
            initialProgress++
            setProgress(initialProgress)
            setStrokeDashOffset(circumference - initialProgress / 100 * circumference)
            if (initialProgress === 100) {
                clearInterval(interval)
            }
        }, 7)
        const countRate = 100 / daysLeft * 7
        const numberInterval = setInterval(() => {
            initialDisplayNumber++
            setDisplayNumber(initialDisplayNumber)
            if (initialDisplayNumber === daysLeft) {
                clearInterval(numberInterval)
            }
        }, countRate)
    }

    useEffect(() => {
        if (props.startAnimation) {
            let initialDisplayNumber = 0
            let initialProgress = 0

            setTimeout(() => {
                startProgress(initialProgress, initialDisplayNumber)
            }, 250)
        }
        // eslint-disable-next-line
    }, [props.startAnimation])

    return (
        <div className="status-circle">
            <svg
                height={radius * 2}
                width={radius * 2}
            >
            <circle
                stroke={uiStrokeColor}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset: strokeDashOffset }}
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                fill="transparent"
                data-testid="circle"
            />
            </svg>
            <div className="status-circle-content">
            <div className="status-circle-days" data-testid="status-circle-days">
                { displayNumber }
            </div>
            <div className="status-circle-label">
                Day{daysLeft > 1 ? 's' : ''}
            </div>
            </div>
        </div>
    )
}

export { StatusCircle }
