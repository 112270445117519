import { useState, useEffect } from 'react'
import { GlobalModal as Modal } from '../Modal'
import ProgressSpinner from '../ProgressSpinner'
import { StatusCircle } from './StatusCircle'
import withActivation from '../../redux/connectors/activation'
import { trackEvent } from '../../utils/utils'
import { digitalDataValidationError } from '../../utils/adobe-analytics'

const ServiceStatus = (props) => {
    const {
        macStatusValid,
        fetchingMacStatus,
        fetchMacStatus,
        macStatusFailure,
        item,
        completeDeviceStep,
        startAnimation,
        dashboard
    } = props

    const [value, setValue] = useState('')

    useEffect(() => {
        if (!item?.readyForActivation) {
            sessionStorage.setItem('activationFlow', 'service is not ready')
            trackEvent({
                event: 'activation flow',
                activateStep: 'step 1',
                activateStepName: 'start activation',
                activateStepDetail: 'service is not ready'
            })
        }
        if (!item.deviceLocated) {
            sessionStorage.setItem('activationFlow', 'modem not located')
            trackEvent({
                event: 'activation flow',
                activateStep: 'step 1',
                activateStepName: 'start activation',
                activateStepDetail: 'modem not located'
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!fetchingMacStatus) {
            if (macStatusFailure) {
                trackEvent({
                    event: 'activation info',
                    activateStep: 'step 1',
                    activateStepName: 'start activation',
                    activateEvent: 'locate modem',
                    activateDetail: "error don't recognize address"
                })
                digitalDataValidationError('activation service status', ['mac-search'], "MAC address invalid", ".service-status");
            }
        }
    }, [fetchingMacStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        let mac = event.target.value.toUpperCase()
        var r = /([a-f0-9]{2})([a-f0-9]{1})/i,
        str = mac.replace(/[^a-f0-9]/ig, "")

        while (r.test(str)) {
            str = str.replace(r, '$1:$2')
        }

        event.target.value = str.slice(0, 17)
        setValue(event.target.value)
    }

    const verifyMAC = (e) => {
        trackEvent({
            event: 'activation info',
            activateStep: 'step 1',
            activateStepName: 'start activation',
            activateEvent: 'locate modem',
            activateDetail: 'submit mac address'
        })
        e.preventDefault()
        fetchMacStatus({mac: value})
    }

    const renderErrorLabel = (haveFailure) => {
        if (haveFailure && !fetchingMacStatus) {
            return (
                <label id="mac-error" className="validation-error" htmlFor="mac" dangerouslySetInnerHTML={{__html: item?.content?.maCAddressSubmissionErrorAlert?.message}} />
            )
        } else {
            return null
        }
    }

    const renderMacForm = (item) => {
        if (!macStatusValid && !fetchingMacStatus) {
            return (
                <form  id="mac-search" data-testid="mac-search1" onSubmit={verifyMAC}>
                    <label htmlFor="mac">MAC address</label>
                    <input data-testid="mac-01" id="mac" placeholder="e.g., 12:34:56:78:90:AB" value={value} className={`${macStatusFailure ? 'validation-error' : ''}`}  maxLength={17} onChange={handleChange} type="text" />
                    {renderErrorLabel(macStatusFailure)}
                    <input type="submit" value={item?.content?.submitMaCAddressButtonText}  disabled={value.length < 17} className="button form-submit-button"/>
                </form>
            )
        } else {
            if (macStatusValid) {
                trackEvent({
                    event: 'activation info',
                    activateStep: 'step 1',
                    activateStepName: 'start activation',
                    activateEvent: 'locate modem',
                    activateDetail: "success connect mac address"
                })
                item.deviceLocated = true
                setTimeout(() => {
                    completeDeviceStep()
                }, 500)
            }

            return (
                <ProgressSpinner loadingText={item?.content?.connectingToServiceScreen?.loadingScreenText}/>
            )
        }
    }

    const renderExtraContent = (item) => {
        if (!item.deviceLocated) {
            return(
                <div>
                    <div className="service-status__inactive-body-text">
                        <p>{item?.content?.internetProductErrorBodyCopy}</p>
                    </div>
                    <Modal
                        ctaText={item?.content?.whereCanIFindMyMaCAddressModalLinkText}
                        buttonClasses='button--link'
                        title=''
                        body={item?.content?.whereCanIFindMyMaCAddressModal?.text}
                        type='macAddress'
                    />

                    <div className="card">
                        {renderMacForm(item)}
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
    const renderStatusItems = (item, dashboard) => {
        if (!item?.readyForActivation) {
            return(
                <div>
                    <div className="service-status__active-item service-status__active-item-internet">
                        <div className="status-circle">
                            <img alt="" title="expired" className="icon icon-expired pulse" src="/site-assets/img/icon-connection-problem.svg" />
                        </div>
                        <div className="service-status__active-item-content">
                            <div className="title">
                            {item?.content?.internetProductErrorLabel}
                            </div>
                            <div className="activation">
                            {item?.content?.coldTapErrorLabel}
                            </div>
                        </div>
                    </div>
                    <div className="service-status__inactive-body-title">
                        {item?.content?.serviceNotActiveDescriptionTop}
                    </div>
                    <div className="service-status__inactive-body-text">
                        {item?.content?.serviceNotActiveDescriptionBottom}
                    </div>
                    <Modal
                        ctaText={item?.content?.serviceNotActiveModalLinkText}
                        buttonClasses='button--link'
                        title={item?.content?.serviceNotActiveModal?.title}
                        body={item?.content?.serviceNotActiveModal?.text}
                        type='activationFurtherSupport'
                    />
                </div>
            )
        } else if (!item.deviceLocated && macStatusFailure) {
            return(
                <div>
                    <div className="service-status__active-item service-status__active-item-internet">
                        <div className="status-circle">
                            <img alt="" title="expired" className="icon icon-expired pulse" src="/site-assets/img/icon-connection-problem.svg" />
                        </div>
                        <div className="service-status__active-item-content">
                            <div className="title">
                                {item?.content?.internetProductLabel}
                            </div>
                            <div className="activation">
                                {item?.content?.maCAddressSubmissionErrorAlert?.title}
                            </div>
                        </div>
                    </div>
                    { renderExtraContent(item)}
                </div>
            )
        } else {
            let hsdDays = 30
            let xitvDays = 30
            if (dashboard?.auxSubsPlans) {
                let hsd = dashboard?.auxSubsPlans.find(p => p.planLob === 'HSD')
                let xitv = dashboard?.auxSubsPlans.find(p => p.productCode === 'VDO_XITV_30')
                if (hsd) {
                    hsdDays = hsd.avlblPlanDays
                }
                if (xitv) {
                    xitvDays = xitv.avlblPlanDays
                }
            }
            return(
                <div>
                    { dashboard?.subscriptionDetails && dashboard?.subscriptionDetails.length >= 1 ?
                        <div className="service-status__active-item service-status__active-item-internet">
                            <StatusCircle startAnimation={startAnimation} days={hsdDays} />
                            <div className="service-status__active-item-content">
                                <div className="title">
                                    {item?.content?.internetProductLabel}
                                </div>
                                <div className="activation">
                                    {`${!item.deviceLocated ? item?.content?.internetProductErrorBodyCopy : item?.content?.internetProductDescription}`}
                                </div>
                            </div>
                        </div> :
                        <div /> }

                    { dashboard?.subscriptionDetails && dashboard?.subscriptionDetails.length >= 2 ?
                        <div className="service-status__active-item service-status__active-item-internet">
                            <StatusCircle startAnimation={startAnimation} days={xitvDays} />
                            <div className="service-status__active-item-content">
                                <div className="title">
                                    {item?.content?.tVProductLabel}
                                </div>
                                <div className="activation">
                                    {item?.content?.tVProductDescription}
                                </div>
                            </div>
                        </div> :
                        <div /> }

                    { renderExtraContent(item)}
                </div>
            )
        }
    }

    return (
        <div className="service-status">
            <div className="service-status__title">
                {item?.content?.myPrepaidServicesLabel}
            </div>

            {renderStatusItems(item, dashboard)}
        </div>
    )
}

export default withActivation(ServiceStatus)
