import DashboardLayout from "../../layouts/DashboardLayout";
import ProgressBar from "../../components/ProgressBar";
import ProgressSpinner from "../../components/ProgressSpinner";
import NotificationBanner from "../../components/NotificationBanner";
import PurchaseAgreement from "../../components/refill/PurchaseAgreement";
import Cart from "../../components/refill/Cart";
import { useEffect, useState } from "react";
import withDashboard from "../../redux/connectors/dashboard";
import { JSEncrypt } from "jsencrypt";
import { errorForCode } from "../../lib/errors";
import {trackEvent} from "../../utils/utils";
import PaymentMethod from "../../components/dashboard/PaymentMethod";
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

// XXX this is repeated in PaymentMethod.tsx – TODO pull out into common lib

const PREPAID_PUBLIC_KEY = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCL0uzNpAvff4QkZCbyF3N1U5e54pnWSi7E514ETZ+4/hybGJ5XoneG2twZzSb1YTa/KE7wPNyl4y4w0tUC9eMuXG9c6U8CELHDwmcr84VrO0B6Na0ckvMaE7cWxKx7dEMoxT+LMG/MdAKhInvq95xxSxq/SpMeQr1WjB2whqI06QIDAQAB";

function encrypt(content) {
    const crypto = new JSEncrypt({});

    crypto.setPublicKey(PREPAID_PUBLIC_KEY);
    return crypto.encrypt(content);
}

const MoveRefillReview = (props) => {
    const {
        cartContent,
        dictionaryContent,
        locale,
        dashboard,
        fetchedDashboard,
        paymentInfo,
        billing,
        cart,
        cartTaxes,
        cartSubtotal,
        cartTotal,
        taxData,
        fetchTaxData,
        fetchedTaxData,
        accountId,
        refill,
        refillPrepaidAccount,
        refillingPrepaidAccount,
        refilledPrepaidAccount,
        refillPrepaidAccountFailed,
        refillErrorCode,
        resetBilling,
        currentLocale,
        history,
        havePromoInCart,
        promos,
        updateField,
        haveValidACP,
        isTribalACP,
        cpcResponse,
        isTestFile,
        cartCount
    } = props

    const cartComponentContent = { cartContent };

    const [refillError, setRefillError] = useState("");
    const [editingPayment, setEditingPayment] = useState(false);
    const [formValid, updateFormStatus] = useState(true);
    const [startCPCEvents, setStartCPCEvents] = useState(false);
    const [loadCPCForm, setLoadCPCForm] = useState(false);
    const [processNext, setProcessNext] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isVisible, setVisible] = useState(false)

    const handleFormStatus = (formStatus) => {
        updateFormStatus(formStatus);
        }

        
    useEffect(() => {
        props.updatePageClass("dashboard refill");
        trackEvent({
            event:"move refill flow",
            moveStep:"step 3",
            moveStepName:"order review",
        });
    }, []); // eslint-disable-line

    useEffect(() => {
        setStartCPCEvents(true);
        
 }, []); // eslint-disable-line react-hooks/exhaustive-deps

 useEffect(() => {
    startCPCEvents && setLoadCPCForm(true);
 }, [startCPCEvents]);

    useEffect(() => {
        if (fetchedDashboard) {
            fetchTaxData();
        }
    }, [fetchedDashboard]); // eslint-disable-line
    
    const handleNext = (e) => {
        e.preventDefault();
        e.stopPropagation();
        trackEvent({
            event:"move refill info",
            moveStep:"step 3",
            moveStepName:"order review",
            moveEvent:"next step cta",
            moveDetail:"next",
        });

        setRefillError("");


        let cardType;
        if (paymentInfo) {
            cardType = paymentInfo.cardType;
        } else {
            cardType = billing?.cardType;
        }

        let prodTaxDetails:any = {};

        if (taxData?.products && taxData?.products.length) {
            taxData.products.forEach((product) => {
                prodTaxDetails[product.productCode] = {};

                if (product.productType && product.productType[0]) {
                    prodTaxDetails[product.productCode] = product.productType[0].vertexCodes.map((item) => {
                        return { taxCode: item.vertexCode, taxAmount: item.amt }
                    });
                }
            })
        }

         const productCodes = cart?.internet ? [cart?.internet, ...cart?.packages, ...cart.addOns] : [...cart?.packages, ...cart.addOns];
        const products:any = [];

        productCodes.forEach((productCode) => {
            const plan = dashboard.availablePlans.find(s => s.productCode === productCode);
            let taxDetails = [];
            if (Object.keys(prodTaxDetails[plan.productCode]).length !== 0) {
                taxDetails = prodTaxDetails[plan.productCode];
            }
            products?.push({
                chargeCode: [
                    {
                        amount: plan.planPrice,
                        chargeCode: plan.productCode,
                        prodTaxDetails: taxDetails,
                    }
                ],
                lob: plan.planLob,
                planCode: plan.productCode,
                planQty: plan.productCode.includes("HSD") ? cart.internetRefills : 1,
                rate: plan.planPrice,
            });
        });
        let acpBenefits: any = [];
        if (haveValidACP && !isTribalACP) {
            acpBenefits?.push({
            benefitAmount: dashboard.acpDetail.acpBenefits[0].benefitAmount,
            benefitPlan: dashboard.acpDetail.acpBenefits[0].benefitPlan,
            benefitCode: dashboard.acpDetail.acpBenefits[0].benefitCode,
            });
        }

        const data:any = {
            channel: "ONLINE",
            customerInfo: {
                accountID:!isTestFile?  encrypt(accountId): accountId,
                phone: "",
                zip: "",
            },
            flow: "REFILL",
            paymentInfo: {
                amount: parseFloat(cartTotal.toFixed(2)),
                billingAddress: {
                    addressLine1: cpcResponse?.paymentInfo?.address,
                    addressLine2: cpcResponse?.paymentInfo?.addressLine2,
                    city: cpcResponse?.paymentInfo?.city,
                    country: "US",
                    state: cpcResponse?.paymentInfo?.state,
                    zip: cpcResponse?.paymentInfo?.zip,
                },
                processingNetwork: "CREDIT",
                isServiceAddress: billing.useServiceAddressForBilling, 
                saveCardInfo: billing.savePaymentMethod,
                },
            prepaidProducts: products,
            acpBenefits,
            referenceID: dashboard.referenceID,
            termsVersion: "1.0",
            macId: "",
            serialNo: "",
        };
        
        if (paymentInfo) {
            data.paymentInfo.paymentInstToken = paymentInfo.paymentInstToken;
        } else {
            if (!billing?.isValid) {
                console.error("Expected billing.isValid to be true when there is no payment instrument!");
                history?.push(`/${locale}/error`);
                return;
            }
            data.paymentInfo = {
                ...data.paymentInfo,
                cardNumber:!isTestFile? encrypt(billing.cardNumberLastFour):billing.cardNumberLastFour,
                cardType:!isTestFile? encrypt(billing.cardType):billing.cardType,
                expirationDate:!isTestFile? encrypt(billing.expirationDate): billing.expirationDate,
                firstName: cpcResponse?.cpcData?.customerDetails?.firstName,
                lastName: cpcResponse?.cpcData?.customerDetails?.lastName,
                isCPC: true, 
                paymentInstToken: cpcResponse.cpcData?.cardDetails?.token,
                prepaidPaymentID: dashboard?.customerInfo?.prepaidPaymentID,
            };
        }

        if (havePromoInCart && promos && promos.discounts) {
            const discount  = promos.discounts.find(d => d.discId === cart.promos[0]);
            data.discountDetails = [
                {
                    discStatus: "A",
                    discountId: discount?.discId,
                    offerType: "INSTANT",
                },
            ];
            const hsdProduct =!isTestFile? data.prepaidProducts.find(p => p.planCode === "HSD_030"):true;
           if (hsdProduct) {
                hsdProduct.rate = hsdProduct.rate += parseInt(discount?.discPrice);
                if (hsdProduct.chargeCode.length) {
                    hsdProduct.chargeCode[0].amount += parseInt(discount?.discPrice);
                }
            }
        }
        refillPrepaidAccount(data);
    };

    const onUpdateField = (e) => {
        console.log(e);
    }

    const setFormValid = (status) => {
        handleFormStatus(status);
     }

     
    const goToOrderReview = (e) => {
        console.log(e);
     }
    useEffect(() => {
        if (refilledPrepaidAccount) {
            updateField({field: "refill", value: {...refill, cart, cartTaxes, cartSubtotal, cartTotal, havePromoInCart, promos, benefitAmount: dashboard?.acpDetail?.acpBenefits?.[0].benefitAmount}});
            resetBilling();
            sessionStorage.removeItem("cpcTermsAndConditions");
            history?.push("/" + locale + "/move/refill-confirmation");
        } else if (refillPrepaidAccountFailed) {
            console.warn("Uh-oh, something went wrong with refill", refillErrorCode);
            const error = errorForCode(refillErrorCode||"", currentLocale);
            setRefillError(error);
        }

    }, [refilledPrepaidAccount, refillPrepaidAccountFailed]); // eslint-disable-line

    let contentReady = props.refillContent && props.refillContent.loaded && fetchedTaxData && !refillingPrepaidAccount;
    let showSpinner = false;

    if (refillingPrepaidAccount || !contentReady) {
        showSpinner = true;
    }

    return (
        <DashboardLayout locale={props.locale} pageTitle={"Refill Review"}>
            <TrackUserData data={dashboard} />
            <TrackPurchaseData offerIncluded={havePromoInCart} price={cartTotal} productID={cart?.internet} productType={'prepaid-refill'} quantity={cartCount} transactionID={""} screenName="review"/>
            {refillError ? <NotificationBanner text={refillError} displayStyle="alert" modal="true" /> : null}
            {showSpinner && <ProgressSpinner loadingText={dictionaryContent?.global?.updating + "…"} />}
            {contentReady &&
            <div>
                <ProgressBar title={"Customize your refill"} percent={66} />
                <div className="main__width-constraint edit-payment">
                    <br />
                    <h1 className="page__title">{props.refillContent.refillFlow.orderConfirmation.reviewYourOrder}</h1>
                    <br />
                    <div className="main__desktop-body">

                        <div className="cart__display-small-medium">
                            <Cart content={cartComponentContent} />
                        </div>
                        <PaymentMethod
                        onUpdateField={onUpdateField} 
                        goToOrderReview={goToOrderReview} 
                        setFormValid={setFormValid} 
                        nextClicked={processNext} 
                        startCPCEvents={startCPCEvents}
                            minimalView={true}
                            refillView={true}
                            refillMoveView={true}
                            refillMoveReview={true}
                            refillCPCEdit={true}
                            onEdit={() => {
                                setEditingPayment(true)
                            }}
                            onUpdate={() => {
                                setEditingPayment(false)
                            }}
                        />                       
                         <PurchaseAgreement handleNext={handleNext} billingIsValid={!editingPayment} />
                    </div>
                    <div className="cart__display-large main__desktop-sidebar-right">
                        <Cart content={cartComponentContent} />
                    </div>
                </div>
            </div>}
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveRefillReview)