import { useState, useEffect } from 'react'
import ProgressSpinner from '../ProgressSpinner'
import SuccessBanner from '../SuccessBanner'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataLinkClick } from '../../utils/adobe-analytics'

const CommunicationPreferences = (props) => {
    const {
        dashboard,
        dashboardContent,
        dictionaryContent,
        accountId,
        custGuid,
        updatedIdentity,
        updateIdentityFailed,
        updateCustomer,
        updateCustomerInfo,
        identityAccounts
    } = props
    const [updatingData, setUpdatingData] = useState(false)
    const [showSuccessBanner, setShowSuccesBanner] = useState(false)

    // set preferences on load
    const [initialLanguagePref, setInitialLanguagePref] = useState('')
    const [languagePref, setLanguagePref] = useState('')
    const [languagePrefHeading, setLanguagePrefHeading] = useState('')
    const [initialCommunicationPref, setInitialCommunicationPref] = useState(false)
    const [communicationPref, setCommunicationPref] = useState(false)
    const [communicationPrefHeading, setCommunicationPrefHeading] = useState('')

    useEffect(() => {
        const langStr = dashboard.customerInfo.language === 'SPANISH' ? dictionaryContent.global.espanol : dictionaryContent.global.english
        setLanguagePrefHeading(langStr)
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const smsOptIn = hasSmsOptIn()
        setInitialCommunicationPref(smsOptIn)
        setCommunicationPref(smsOptIn)

        const communicationPrefStr = smsOptIn ? dashboardContent.accountInfoArea.myInformationCommunications.on : dashboardContent.accountInfoArea.myInformationCommunications.off
        setCommunicationPrefHeading(communicationPrefStr)

    }, [dashboard]) // eslint-disable-line

    const hasSmsOptIn = () => {
        let currentAccount, smsOptIn;
        if (identityAccounts) {
            currentAccount = identityAccounts.find(acct => {
                return accountId === acct.accountNumber
            })
        }

        // console.log('currentAccount', currentAccount)

        if (!currentAccount) {
            console.error('hasSmsOptIn: Could not find identity')
        } else {
            smsOptIn = currentAccount.prepaidSMSOptIn === 'Y'
        }
        return smsOptIn
    }

    // screen toggling
    const [editCommunication, setEditCommunication] = useState(false)
    const [editText, setEditText] = useState('')
    useEffect(() => {
        const btnTxt = editCommunication ? dictionaryContent.global.back : dictionaryContent.global.edit
        setEditText(btnTxt)
        // reset form when opening
        if (editCommunication) {
            const contentLanguagePref = dashboard?.customerInfo?.language
            setInitialLanguagePref(contentLanguagePref)
            setLanguagePref(contentLanguagePref)

            const smsOptIn = hasSmsOptIn()
            setInitialCommunicationPref(smsOptIn)
            setCommunicationPref(smsOptIn)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCommunication])

    // determine if submit button is active
    const [submitActive, setSubmitActive] = useState(false)
    useEffect(() => {
        const isActive = languagePref !== initialLanguagePref || communicationPref !== initialCommunicationPref
        setSubmitActive(isActive)
    }, [languagePref, initialLanguagePref, communicationPref, initialCommunicationPref])

    // save all settings
    const submitPrefs = e => {
        e.preventDefault()
        setUpdatingData(true)
        updateCustomer({
            firstName: dashboard.customerInfo.firstName,
            lastName: dashboard.customerInfo.lastName,
            custGuid,
            accountId,
            languagePreference: languagePref,
            smsOptIn: communicationPref ? 'Y' : 'N'
        })
    }

    useEffect(() => {
        if (updatedIdentity && updatingData) {
            setEditCommunication(false)
            setUpdatingData(false)
            setShowSuccesBanner(true)
            updateCustomerInfo({accountId, language: languagePref, smsOptIn: communicationPref ? 'Y' : 'N'})
            setSubmitActive(false)
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'communicaiton preferences',
                dashboardDetail: 'update success'
    
                })
        }
        if (updateIdentityFailed && updatingData) {
            setUpdatingData(false)
            console.log('FAILED')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedIdentity, updateIdentityFailed])

    return (
        <div className={editCommunication ? "card padding-bottom" : "card"}>

            <SuccessBanner text={dashboardContent.accountInfoArea.myInformationCommunications.updatedCommunicationPreferences.message} displayModal={showSuccessBanner} />

            <div className="card__header">
                <h2 className="card__header-title">{dashboardContent.accountInfoArea.myInformationCommunications.communicationPreferences}</h2>
                <button
                    type="button"
                    className="totals__edit link-callout"
                    aria-labelledby="edit-xitv"
                    data-testid="edit-communication-button"
                    onClick={() => {
                        if (!editCommunication) {
                            trackEvent({
                                event: 'dashboard',
                                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                                dashboardEvent: 'communicaiton preferences',
                                dashboardDetail: 'edit'
                    
                                })
                        }
                        setEditCommunication(!editCommunication)
                    }}
                    data-tracking={digitalDataButtonClick('totals-edit', editText)}
                >
                    {editText}
                </button>
            </div>

            {updatingData ?
                <ProgressSpinner loadingText="Updating..." /> :
                <div>
                    <h5>{dashboardContent.accountInfoArea.myInformationCommunications.marketingCommunications}</h5>
                    <p className="card__disclaimer">
                        {dashboardContent.accountInfoArea.myInformationCommunications.youCanUpdateYourCommunications}
                        <br /><br />
                        <a href={dashboardContent.accountInfoArea.myInformationCommunications.managePreferences.link.url} rel="noreferrer" target={dashboardContent.accountInfoArea.myInformationCommunications.managePreferences.link.target} className="link-callout link-external" data-tracking={digitalDataLinkClick('card-disclaimer', dashboardContent.accountInfoArea.myInformationCommunications.managePreferences.link.url, dashboardContent.accountInfoArea.myInformationCommunications.managePreferences.text)}>
                            {dashboardContent.accountInfoArea.myInformationCommunications.managePreferences.text}
                        </a>
                    </p>
                    <br />
                    <h5>{dashboardContent.accountInfoArea.myInformationCommunications.orderReview.languagePreferenceLabel}</h5>

                    <form onSubmit={e => submitPrefs(e)} data-testid="form-submit-prefs">

                        {!editCommunication ?
                            <p>{languagePrefHeading}<br /></p> :
                            <div className="flex">
                                <div className=" flex-item radio radio--inline">
                                    <input id="language_en" checked={languagePref === 'ENGLISH'} onChange={() => setLanguagePref('ENGLISH')} type="radio" name="langPref" value="ENGLISH" className="radio__input" data-testid="language-preference-radio-button"/>
                                    <label htmlFor="language_en" className="radio__label">{dictionaryContent.global.english}</label>
                                </div>
                                <div className="flex-item radio radio--inline">
                                    <input id="language_es" checked={languagePref === 'SPANISH'} onChange={() => setLanguagePref('SPANISH')} type="radio" name="langPref" value="SPANISH" className="radio__input" />
                                    <label htmlFor="language_es" className="radio__label">{dictionaryContent.global.espanol}</label>
                                </div>
                            </div>
                        }

                        <br />
                        <h5>{dashboardContent.accountInfoArea.myInformationCommunications.serviceRefillCommunications}</h5>

                        {!editCommunication ?
                            <p>{communicationPrefHeading}<br /></p> :
                            <label className="switch">
                                <input type="checkbox" id="togBtn" checked={communicationPref} onChange={e => setCommunicationPref(e.target.checked)}  />
                                <div className="slider round">
                                    <span className="on">{dashboardContent.accountInfoArea.myInformationCommunications.on}</span>
                                    <span className="off">{dashboardContent.accountInfoArea.myInformationCommunications.off}</span>
                                </div>
                                <br/>
                                <br/>
                            </label>
                        }
                        <p>
                            {dashboardContent.accountInfoArea.myInformationCommunications.yesReceiveAutomatedMarketingTextMessages}
                        </p>

                        {editCommunication &&
                            <button className="button right" disabled={!submitActive} data-tracking={digitalDataButtonClick('my-information-update', dashboardContent.accountInfoArea.myInformationCommunications.update.text)}>
                                {dashboardContent.accountInfoArea.myInformationCommunications.update.text}
                            </button>
                        }

                    </form>

                </div>
            }
        </div>
    )
}

export default withDashboard(CommunicationPreferences)