import withDashboard from '../../redux/connectors/dashboard'
import { useState, useEffect } from 'react'

const WinbackCartBanner = (props) => {
    const {
        refillContent
    } = props

    const [showCheckoutPromo, updateShowCheckoutPromo] = useState(false)

    useEffect(() => {
        if (window.location.href.includes('confirmation')) {
            updateShowCheckoutPromo(true)
        }
    }, [])

    return (
        <div className={showCheckoutPromo ? 'winback-promo-cart-banner checkout' : 'winback-promo-cart-banner'}>
            <div className='refillIcon'>
            <img src="../../site-assets/placeholder/Internet_Refill_30.svg" alt="Refill Icon" />
            </div>
            <div className='title'>
                {refillContent?.refillFlow?.winback?.youGotAFree30}
            </div>
            <div className='body'>
            {refillContent?.refillFlow?.winback?._30DayInternetRefill}
            </div>
            <div className='emphasis'>
            {refillContent?.refillFlow?.winback?.andSaved45}
            </div>
        </div>
    )
}

export default withDashboard(WinbackCartBanner)