import { useEffect } from 'react'
import LitepayLayout from '../../layouts/LitepayLayout'
import withLitepay from '../../redux/connectors/litepay'
import ProgressSpinner from '../../components/ProgressSpinner'
import { useHistory } from 'react-router-dom'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const Litepay = props => {
    const {
        buyflowContent,
        dictionaryContent,
        cartContent,
        fetchedContent,
        currentLocale,
        dashboard
    } = props

    const history = useHistory()

    useEffect(() => {

        if (buyflowContent.loaded && dictionaryContent.loaded && cartContent.loaded && fetchedContent) {
            history.push('/' + currentLocale + '/litepay/refill')
        }

    }, [ buyflowContent, dictionaryContent, cartContent]) // eslint-disable-line


    return (
        <LitepayLayout locale={props.locale}>
            <TrackUserData data={dashboard} />
            <ProgressSpinner loadingText={'Loading...'} />
            <TrackPageComplete />
        </LitepayLayout>
    )
}

export default withLitepay(Litepay)