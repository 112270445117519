import {connect} from 'react-redux';
import {activation} from '../activation/store';

const mapStateToProps = (state) => ({
    ready: state.activation.ready,
    inApp: state.activation.inApp,
    behindTheModem: state.activation.behindTheModem,
    accountId: state.activation.accountId,
    custGuid: state.activation.custGuid,
    macAddress: state.activation.macAddress,
    trackingId: state.activation.trackingId,
    locale: state.activation.locale,
    lastActionAt: state.activation.lastActionAt,
    firstName: state.activation.firstName,
    lastName: state.activation.lastName,
    isVanAccount: state.activation.isVanAccount,

    xsrfToken: state.activation.xsrfToken,
    fetchedToken: state.activation.fetchedToken,
    fetchingToken: state.activation.fetchingToken,
    fetchingTokenFailed: state.activation.fetchingTokenFailed,

    sitecoreContent: state.activation.content,
    fetchedContent: state.activation.fetchedContent,
    fetchedEnglishContent: state.activation.fetchedEnglishContent,
    fetchingEnglishContent: state.activation.fetchingEnglishContent,
    fetchingEnglishContentFailed: state.activation.fetchingEnglishContentFailed,
    fetchedSpanishContent: state.activation.fetchedSpanishContent,
    fetchingSpanishContent: state.activation.fetchingSpanishContent,
    fetchingSpanishContentFailed: state.activation.fetchingSpanishContentFailed,

    identity: state.activation.identity,
    identityAccounts: state.activation.identityAccounts,
    fetchedIdentity: state.activation.fetchedIdentity,
    fetchingIdentity: state.activation.fetchingIdentity,
    fetchIdentityFailed: state.activation.fetchingIdentityFailed,

    dashboard: state.activation.dashboard,
    fetchedDashboard: state.activation.fetchedDashboard,
    fetchingDashboard: state.activation.fetchingDashboard,
    fetchingDashboardFailed: state.activation.fetchingDashboardFailed,

    account: state.activation.account,
    fetchedAccount: state.activation.fetchedAccount,
    fetchingAccount: state.activation.fetchingAccount,
    fetchingAccountFailed: state.activation.fetchingAccountFailed,

    deviceStatus: state.activation.deviceStatus,
    fetchedDeviceStatus: state.activation.fetchedDeviceStatus,
    fetchingDeviceStatus: state.activation.fetchingDeviceStatus,
    fetchingDeviceStatusFailed: state.activation.fetchingDeviceStatusFailed,

    macStatus: state.activation.macStatus,
    macStatusValid: state.activation.macStatusValid,
    macStatusFailure: state.activation.macStatusFailure,
    fetchedMacStatus: state.activation.fetchedMacStatus,
    fetchingMacStatus: state.activation.fetchingMacStatus,
    fetchingMacStatusFailed: state.activation.fetchingMacStatusFailed,

    activationStatus: state.activation.activationStatus,
    activatedPrepaidAccount: state.activation.activatedPrepaidAccount,
    activatingPrepaidAccount: state.activation.activatingPrepaidAccount,
    activatingPrepaidAccountFailed: state.activation.activatingPrepaidAccountFailed,

    restartDeviceStatus: state.activation.restartDeviceStatus,
    restartedDevice: state.activation.restartedDevice,
    restartingDevice: state.activation.restartingDevice,
    restartDeviceFailed: state.activation.restartDeviceFailed

});

const mapDispatchToProps = {
    generateTrackingId: activation.actions.generateTrackingId,
    updateLocale: activation.actions.updateLocale,
    updateInAppStatus: activation.actions.updateInAppStatus,
    updateBehindTheModemStatus: activation.actions.updateBehindTheModemStatus,
    updateAccountId: activation.actions.updateAccountId,
    updateCustGuid: activation.actions.updateCustGuid,
    updateFirstName: activation.actions.updateFirstName,
    updateLastName: activation.actions.updateLastName,
    fetchToken: activation.actions.fetchToken,
    fetchEnglishActivationContent: activation.actions.fetchEnglishActivationContent,
    fetchSpanishActivationContent: activation.actions.fetchSpanishActivationContent,
    fetchIdentityInfo: activation.actions.fetchIdentityInfo,
    fetchCustomerDashboard: activation.actions.fetchCustomerDashboard,
    loadPrepaidAccount: activation.actions.loadPrepaidAccount,
    fetchDeviceStatus: activation.actions.fetchDeviceStatus,
    fetchMacStatus: activation.actions.fetchMacStatus,
    activatePrepaidAccount: activation.actions.activatePrepaidAccount,
    restartDevice: activation.actions.restartDevice
};

export default connect(mapStateToProps, mapDispatchToProps);
