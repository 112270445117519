import { connect } from "react-redux";
import { litepay } from "../litepay/store";
import contentSelectors from "../content/selectors";
import { content } from "../content/store";
import { common } from "../common/store";
import litepaySelectors from "../litepay/selectors";

const mapStateToProps = (state) => ({
  ready: state.litepay.ready,
  isVanAccount: state.litepay.isVanAccount,
  currentLocale: state.common.currentLocale,

  xsrfToken: state.litepay.xsrfToken,
  fetchedToken: state.litepay.fetchedToken,
  fetchingToken: state.litepay.fetchingToken,
  fetchingTokenFailed: state.litepay.fetchingTokenFailed,

  sitecoreContent: state.litepay.content,
  fetchedContent: state.litepay.fetchedContent,
  fetchedEnglishContent: state.litepay.fetchedEnglishContent,
  fetchingEnglishContent: state.litepay.fetchingEnglishContent,
  fetchingEnglishContentFailed: state.litepay.fetchingEnglishContentFailed,
  fetchedSpanishContent: state.litepay.fetchedSpanishContent,
  fetchingSpanishContent: state.litepay.fetchingSpanishContent,
  fetchingSpanishContentFailed: state.litepay.fetchingSpanishContentFailed,

  checkoutContent: state.litepay.checkoutContent,

  dashboard: state.litepay.dashboard,
  fetchedDashboard: state.litepay.fetchedDashboard,
  fetchingDashboard: state.litepay.fetchingDashboard,
  fetchTaxDataFailed: state.litepay.fetchTaxDataFailed,
  menu: state.litepay.menu,

  fetchedTaxData: state.litepay.fetchedTaxData,
  fetchingTaxData: state.litepay.fetchingTaxData,
  fetchingTaxDataFailed: state.litepay.fetchingTaxDataFailed,
  cartTaxes: state.litepay.cartTaxes,
  cartSubtotal: state.litepay.cartSubtotal,
  cartTotal: state.litepay.cartTotal,

  billingName: state.litepay.billingName,
  billingFirstName: state.litepay.billingFirstName,
  billingLastName: state.litepay.billingLastName,
  billingAddressLine1: state.litepay.billingAddressLine1,
  billingAddressLine2: state.litepay.billingAddressLine2,
  billingCity: state.litepay.billingCity,
  billingState: state.litepay.billingState,
  billingZip: state.litepay.billingZip,
  billingCountry: state.litepay.billingCountry,
  billingCardNumber: state.litepay.billingCardNumber,
  billingCardDisplayNumber: state.litepay.billingCardDisplayNumber,
  billingCardNumberLength: state.litepay.billingCardNumberLength,
  billingCardNumberLastFour: state.litepay.billingCardNumberLastFour,
  billingCardType: state.litepay.billingCardType,
  billingCardNiceType: state.litepay.billingCardNiceType,
  billingExpirationMonth: state.litepay.billingExpirationMonth,
  billingExpirationYear: state.litepay.billingExpirationYear,
  billingCvv: state.litepay.billingCvv,

  billingZipLookup: state.litepay.billingZipLookup,
  billingUnit: state.litepay.billingUnit,
  billingPhoneNumber: state.litepay.billingPhoneNumber,
  tn: state.litepay.tn,

  cardTypeMap: state.litepay.cardTypeMap,

  showErrorPage: state.litepay.showErrorPage,
  error: state.litepay.error,

  showLocateServicesNotification: state.litepay.showLocateServicesNotification,
  locateServicesNotificationMessage:
    state.litepay.locateServicesNotificationMessage,

  showMultipleAccountsNotification:
    state.litepay.showMultipleAccountsNotification,
  multipleAccountsNotificationMessage:
    state.litepay.multipleAccountsNotificationMessage,

  showCreditCardFormNotification: state.litepay.showCreditCardFormNotification,
  creditCardFormNotificationMessage:
    state.litepay.creditCardFormNotificationMessage,

  navigationUrl: state.litepay.navigationUrl,
  completedStep: state.litepay.completedStep,
  displayNavigationModal: state.litepay.displayNavigationModal,
  showStickyCart: state.litepay.showStickyCart,

  // API stuff
  locatingService: state.litepay.locatingService,
  locatedService: state.litepay.locatedService,
  hasValidPlan: state.litepay.hasValidPlan,
  taxData: state.litepay.taxData,
  dashboardData: state.litepay.dashboardData,

  processingRefill: state.litepay.processingRefill,
  orderPlaced: state.litepay.orderPlaced,
  orderFailed: state.litepay.orderFailed,
  orderNetworkError: state.litepay.orderNetworkError,
  orderNumber: state.litepay.orderNumber,
  orderConfirmationViewed: state.litepay.orderConfirmationViewed,
  stepNumber: state.litepay.stepNumber,

  litepayPages: state.litepay.litepayPages,

  acpDetail: state.litepay.acpDetail,

  refill: state.litepay.refill,
  refillingPrepaidAccount: state.litepay.refillingPrepaidAccount,
  refilledPrepaidAccount: state.litepay.refilledPrepaidAccount,
  refillPrepaidAccountFailed: state.litepay.refillPrepaidAccountFailed,

  refillErrorCode: state.litepay.refillErrorCode,

  cpcResponse: state.litepay.cpcResponse,

  // CONTENT SELECTORS
  dictionaryContent: contentSelectors.getDictionaryContent(state),
  cartContent: contentSelectors.getCartContent(state),
  buyflowContent: contentSelectors.getBuyflowContent(state),

  // OTHER SELECTORS
  haveValidACP: litepaySelectors.getHaveValidACP(state),
  isTribalACP: litepaySelectors.getIsTribalACP(state),

  addingPaymentInstrument: state.litepay.addingPaymentInstrument,
  addedPaymentInstrument: state.litepay.addedPaymentInstrument,
  addPaymentInstrumentFailed: state.litepay.addPaymentInstrumentFailed,
  paymentInstrumentErrorCode: state.litepay.paymentInstrumentErrorCode,

  sessionId: state.common.sessionId,
  accountId: state.common.accountId,

  isBack: state.litepay.isBack,

  trackingId: state.litepay.trackingId,
});

const mapDispatchToProps = {
  generateTrackingId: litepay.actions.generateTrackingId,
  updateLocale: litepay.actions.updateLocale,
  updateCurrentLocale: common.actions.updateCurrentLocale,
  fetchToken: litepay.actions.fetchToken,
  toggleField: litepay.actions.toggleField,
  updateField: litepay.actions.updateField,
  fetchTaxData: litepay.actions.fetchTaxData,
  refillPrepaidAccount: litepay.actions.refillPrepaidAccount,
  fetchEnglishLitepayContent: litepay.actions.fetchEnglishLitepayContent,
  fetchSpanishLitepayContent: litepay.actions.fetchSpanishLitepayContent,
  updateCheckoutContent: litepay.actions.updateCheckoutContent,
  fetchCustomerDashboard: litepay.actions.fetchCustomerDashboard,
  fetchEnglishDictionaryContent: content.actions.fetchEnglishDictionaryContent,
  fetchSpanishDictionaryContent: content.actions.fetchSpanishDictionaryContent,
  fetchSpanishCartContent: content.actions.fetchSpanishCartContent,
  fetchEnglishCartContent: content.actions.fetchEnglishCartContent,
  fetchEnglishBuyflowContent: content.actions.fetchEnglishBuyflowContent,
  fetchSpanishBuyflowContent: content.actions.fetchSpanishBuyflowContent,
  updateCpcResponse: litepay.actions.updateCpcResponse,
  addPaymentInstrument: litepay.actions.addPaymentInstrument,
  updatePageStep:  litepay.actions.updatePageStep,
  resetPageStep: litepay.actions.resetPageStep
};

export default connect(mapStateToProps, mapDispatchToProps);
