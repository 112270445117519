import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { GlobalModal as Modal } from "../Modal";
import withDashboard from "../../redux/connectors/dashboard";
import {
  getProductName,
  getProductPrice,
  trackEvent,
  formatMoney,
} from "../../utils/utils";
import { sprintf } from "sprintf-js";
import {
  digitalDataAccordionClick,
  digitalDataAddToCart,
  digitalDataButtonClick,
} from "../../utils/adobe-analytics";

const HSDAccordion = (props) => {
  const {
    setPrepaidInstantUnavailableNotice,
    hsd30Service,
    hsd7Service,
    hsdService,
    refillContent,
    dashboard,
    fetchedDashboard,
    cart,
    toggleInternet,
    updateInternet,
    haveValidPromo,
    havePromoInCart,
    promos,
    paymentInfo,
    dashboardContent,
    moveDateGap,
    isMoveRefill,
    addWinbackPromo,
    clearCart,
    isWinbackPromo,
    addPromo,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [count, setCount] = useState(1);
  const [selectedHsd, setSelectedHsd] = useState(hsd30Service);

  const dimension1 = isMoveRefill ? "move" : "refill";

  useEffect(() => {
    updateInternet({
      name: selectedHsd?.productCode,
      refills: count,
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      haveValidPromo &&
      selectedHsd?.productCode === "HSD_030" &&
      !isWinbackPromo
    ) {
      trackEvent({
        event: "refill info",
        refillStep: "step 1",
        refillStepName: "start refill",
        refillEvent: "offer acquisition component",
        refillDetail: "impression",
      });
    }
  }, [havePromoInCart]); // eslint-disable-line

  const onTitleClick = () => {
    if (!expanded && !isMoveRefill) {
      trackEvent({
        event: "refill info",
        refillStep: "step 1",
        refillStepName: "start refill",
        refillEvent: "Prepaid Internet accordion",
        refillDetail: "open",
      });
    }
    setExpanded(!expanded);
  };
  const captureEnterKey = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      onTitleClick();
    }
  };
  let maxRefillCount = 6;
  if (haveValidPromo) {
    maxRefillCount = 5;
  }
  const changeCount = (
    event,
    step,
    productCode,
    specificValue: null | number = null
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const newCount = specificValue ? specificValue : count + step;
    if (haveValidPromo && productCode === "HSD_030" && isWinbackPromo) {
      if (newCount >= 2 && !havePromoInCart) {
        addWinbackPromo(promos?.discounts[0].discId);
      } else if (newCount === 1) {
        clearCart();
      }
      setCount(newCount);
    }
    const product = {
      name: productCode,
      refills: newCount,
    };
    updateInternet(product);

    let products: any;
    products = [];
    let toggleProduct: any;
    toggleProduct = {};
    toggleProduct.name = getProductName(productCode, dashboard?.availablePlans);
    toggleProduct.id = productCode;
    toggleProduct.price = getProductPrice(
      productCode,
      dashboard?.availablePlans
    );
    toggleProduct.dimension1 = dimension1;
    toggleProduct.dimension4 = "website";
    toggleProduct.quantity = 1;
    products.push(toggleProduct);
    trackEvent({
      event: step > 0 ? "addToCart" : "removeFromCart",
      ecommerce: {
        currencyCode: "USD",
        add: {
          products,
        },
      },
    });
    if (step > 0) {
      digitalDataAddToCart(
        "standard refill flow",
        "addToCart",
        ".accordion__tab--refill-selector",
        havePromoInCart,
        cart?.internet
      );
    }
    setCount(newCount);
  };

  const select7Day = () => {
    clearCart();
    setCount(1);
    setPrepaidInstantUnavailableNotice(true);
    setSelectedHsd(hsd7Service);
    let addedProducts: any;
    addedProducts = [];
    let sevenDayProduct: any;
    sevenDayProduct = {};
    sevenDayProduct.name = getProductName("HSD_007", dashboard?.availablePlans);
    sevenDayProduct.id = "HSD_007";
    sevenDayProduct.price = getProductPrice(
      "HSD_007",
      dashboard?.availablePlans
    );
    sevenDayProduct.dimension1 = dimension1;
    sevenDayProduct.dimension4 = "website";
    sevenDayProduct.quantity = count;
    addedProducts.push(sevenDayProduct);
    let removedProducts: any;
    removedProducts = [];
    let thirtyDayProduct: any;
    thirtyDayProduct = {};
    thirtyDayProduct.name = getProductName(
      "HSD_030",
      dashboard?.availablePlans
    );
    thirtyDayProduct.id = "HSD_007";
    thirtyDayProduct.price = getProductPrice(
      "HSD_030",
      dashboard?.availablePlans
    );
    thirtyDayProduct.dimension1 = dimension1;
    thirtyDayProduct.dimension4 = "website";
    thirtyDayProduct.quantity = count;
    removedProducts.push(thirtyDayProduct);
    if (cart.internet !== "" || cart.internet !== null) {
      trackEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            addedProducts,
          },
        },
      });
      trackEvent({
        event: "removeFromCart",
        ecommerce: {
          currencyCode: "USD",
          remove: {
            removedProducts,
          },
        },
      });
      digitalDataAddToCart(
        "standard refill flow",
        "addToCart",
        ".accordion__tab--refill-selector",
        havePromoInCart,
        cart?.internet
      );
      toggleInternet("HSD_007");
    }
  };

  const select30Day = () => {
    clearCart();
    setCount(1);
    if (haveValidPromo && !havePromoInCart && !isWinbackPromo) {
      addPromo(promos?.discounts[0].discId);
    }
    setPrepaidInstantUnavailableNotice(false);
    setSelectedHsd(hsd30Service);
    let removedProducts: any;
    removedProducts = [];
    let sevenDayProduct: any;
    sevenDayProduct = {};
    sevenDayProduct.name = getProductName("HSD_007", dashboard?.availablePlans);
    sevenDayProduct.id = "HSD_007";
    sevenDayProduct.price = getProductPrice(
      "HSD_007",
      dashboard?.availablePlans
    );
    sevenDayProduct.dimension1 = dimension1;
    sevenDayProduct.dimension4 = "website";
    sevenDayProduct.quantity = count;
    removedProducts.push(sevenDayProduct);
    let addedProducts: any;
    addedProducts = [];
    let thirtyDayProduct: any;
    thirtyDayProduct = {};
    thirtyDayProduct.name = getProductName(
      "HSD_030",
      dashboard?.availablePlans
    );
    thirtyDayProduct.id = "HSD_030";
    thirtyDayProduct.price = getProductPrice(
      "HSD_030",
      dashboard?.availablePlans
    );
    thirtyDayProduct.dimension1 = dimension1;
    thirtyDayProduct.dimension4 = "website";
    thirtyDayProduct.quantity = count;
    addedProducts.push(thirtyDayProduct);
    if (cart.internet !== "" || cart.internet !== null) {
      trackEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            addedProducts,
          },
        },
      });
      trackEvent({
        event: "removeFromCart",
        ecommerce: {
          currencyCode: "USD",
          remove: {
            removedProducts,
          },
        },
      });
      digitalDataAddToCart(
        "standard refill flow",
        "addToCart",
        ".accordion__tab--refill-selector",
        havePromoInCart,
        cart?.internet
      );
      toggleInternet("HSD_030");
    }
  };

  const toggleProduct = (event, productCode) => {
    let products: any;
    products = [];
    let toggleProduct: any;
    toggleProduct = {};
    toggleProduct.name = getProductName(productCode, dashboard?.availablePlans);
    toggleProduct.id = productCode;
    toggleProduct.price = getProductPrice(
      productCode,
      dashboard?.availablePlans
    );
    toggleProduct.dimension1 = dimension1;
    toggleProduct.dimension4 = "website";
    toggleProduct.quantity = count;
    products.push(toggleProduct);
    if (
      havePromoInCart &&
      promos?.discounts &&
      promos?.discounts.length > 0 &&
      !isWinbackPromo
    ) {
      let promoProduct: any;
      promoProduct = {};
      promoProduct.name = promos?.discounts[0].discShortDesc;
      promoProduct.id = promos?.discounts[0].discId;
      promoProduct.price = promos?.discounts[0].discPrice;
      promoProduct.dimension1 = dimension1;
      promoProduct.dimension4 = "website";
      products.push(promoProduct);
    }
    if (cart.internet !== "" || cart.internet !== null) {
      trackEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            products,
          },
        },
      });
      digitalDataAddToCart(
        "standard refill flow",
        "addToCart",
        ".accordion__tab--refill-selector",
        havePromoInCart,
        cart?.internet
      );
    } else {
      trackEvent({
        event: "removeFromCart",
        ecommerce: {
          currencyCode: "USD",
          remove: {
            products,
          },
        },
      });
    }
    toggleInternet(productCode);
  };

  const checkboxChanged = () => {
    console.log("changed");
  };

  // XXX FIXME HARDCODED
  // set up promo pricing
  const promoDiscount = 35;
  const [priceTotalWithPromo, setPriceTotalWithPromo] = useState(0);
  useEffect(() => {
    let total = selectedHsd?.planPrice * count - promoDiscount;
    setPriceTotalWithPromo(formatMoney(total, true));
  }, [selectedHsd, count]);
  useEffect(() => {
    if (cart?.addOns?.length === 0 && cart.packages.length === 0) {
      let products: any;
      products = [];
      if (cart.internet !== "" && cart.internet !== null) {
        let toggleProduct: any;
        toggleProduct = {};
        toggleProduct.name = getProductName(
          cart.internet,
          dashboard?.availablePlans
        );
        toggleProduct.id = cart.internet;
        toggleProduct.price = getProductPrice(
          cart.internet,
          dashboard?.availablePlans
        );
        toggleProduct.dimension1 = dimension1;
        toggleProduct.dimension4 = "website";
        toggleProduct.quantity = cart.internetRefills;
        products.push(toggleProduct);
        digitalDataAddToCart(
          "standard refill flow",
          "addToCart",
          ".accordion__tab--refill-selector",
          havePromoInCart,
          cart?.internet
        );
      }
      trackEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            products,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (!fetchedDashboard) {
    return null;
  }
  return (
    <div
      className="accordion accordion--with-input dashboard"
      role="tablist"
      multiselectable="true"
      style={{ marginTop: paymentInfo ? "1.25rem" : 0 }}
      data-testid="accordion"
    >
      <div className="accordion__item">
        <div
          id="01-tab"
          tabIndex={0}
          role="tab"
          aria-expanded={expanded}
          aria-controls="01-panel"
          className={`accordion__tab ${expanded ? "" : "active"}`}
          onClick={() => {
            onTitleClick();
            digitalDataAccordionClick(
              "hsd",
              "Prepaid Internet",
              ".accordion__tab"
            );
          }}
          onKeyDown={(e) => captureEnterKey(e)}
          data-testid="accordion-title"
        >
          <div className="checkbox">
            <input
              id="HSD"
              aria-describedby="description-for-HSD"
              checked={
                cart?.internet !== null &&
                cart?.internet !== "" &&
                (cart?.internet === "HSD_030" || cart?.internet === "HSD_007")
              }
              onChange={checkboxChanged}
              disabled={hsdService?.daysRemaining <= 0}
              type="checkbox"
              name="xitv-channel-packs"
              className="checkbox__input"
              data-testid="checkbox-input"
            />
            <label
              htmlFor="HSD"
              className="checkbox__label"
              onClick={(e) => toggleProduct(e, selectedHsd?.productCode)}
              data-testid="checkbox-label"
            >
              <span className="h3">
                {/* hsd30Service.planDesc */}Prepaid Internet
              </span>
            </label>
            <div className="checkbox__content">
              <div id="description-for-HSD">
                <table
                  className={
                    `price-wrap` +
                    (haveValidPromo &&
                    selectedHsd?.productCode === "HSD_030" &&
                    !isWinbackPromo
                      ? " price-wrap--old"
                      : "")
                  }
                >
                  <tbody>
                    <tr>
                      <td
                        className="price-wrap__amount"
                        data-testid="price-amount"
                      >
                        <span>$</span>
                        {selectedHsd?.planPrice * count}
                      </td>
                      <td className="price-wrap__label">
                        <strong>
                          for {selectedHsd?.planDuration * count} days
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="accordion__tab--refill-selector">
                <div className="accordion__tab--cols">
                  <div className="col-left selector-title">
                    {refillContent?.refillFlow?.cart?.noOfRefills}
                  </div>
                  <div className="col-right selector-buttons">
                    <button
                      type="button"
                      className="selector-button selector-buttons--left"
                      onClick={(e) =>
                        changeCount(e, -1, selectedHsd?.productCode)
                      }
                      disabled={count === 1}
                      data-testid="selector-buttons--left"
                      data-tracking={digitalDataButtonClick(
                        "refill-subtract",
                        (count - 1).toString()
                      )}
                    ></button>
                    <span className="selector-count">{count}</span>
                    <button
                      type="button"
                      className="selector-button selector-buttons--right"
                      onClick={(e) =>
                        changeCount(e, 1, selectedHsd?.productCode)
                      }
                      disabled={count === maxRefillCount}
                      data-testid="selector-buttons--right"
                      data-tracking={digitalDataButtonClick(
                        "refill-add",
                        (count + 1).toString()
                      )}
                    ></button>
                  </div>
                </div>
              </div>
              {haveValidPromo &&
                selectedHsd?.productCode === "HSD_030" &&
                !isWinbackPromo && (
                  <div className="accordion__tab--refill-special">
                    <table className="price-wrap price-wrap--multi">
                      <tbody>
                        <tr>
                          <td className="price-wrap__amount">
                            <span>$</span>
                            {priceTotalWithPromo}
                          </td>
                          <td className="price-wrap__label">
                            <strong>
                              {
                                dashboardContent?.dashboardArea?.autoRefill
                                  ?.refillPromotion?.dynamicRefill?.info
                              }
                            </strong>
                            <Modal
                              ctaText={
                                refillContent?.refillFlow?.orderConfirmation
                                  ?.promoModal?.pricingOtherInfo
                              }
                              buttonClasses="button--link--underline"
                              title={
                                refillContent?.refillFlow?.orderConfirmation
                                  ?.promoModal?.pricingOtherInfo
                              }
                              body={
                                refillContent?.refillFlow?.orderConfirmation
                                  ?.promoModal?.pricingOtherInfoContent
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
          <div className="accordion__icon">
            <span className="line" />
            <span className="line" />
          </div>
        </div>
        <CSSTransition
          in={expanded}
          timeout={{ enter: 500, exit: 0 }}
          classNames="expand"
        >
          <div
            className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
            id="01-panel"
            aria-hidden={!expanded}
            aria-labelledby="01-tab"
            tabIndex={0}
            role="tabpanel"
          >
            <div className="accordion__content card">
              <ul className="refill-radio-list">
                <li>
                  <input
                    onClick={() => {
                      digitalDataButtonClick(
                        "hsd-service",
                        "30 days of Prepaid Internet",
                        false,
                        undefined,
                        true,
                        ".refill-radio-list"
                      );
                      select30Day();
                    }}
                    defaultChecked
                    type="radio"
                    id={"hsd"}
                    name={"hsdRadio"}
                    className={hsd7Service ? "selectable" : "non-selectable"}
                    data-testid="30-day-radio-button"
                  />
                  <label htmlFor={"hsd"}>
                    <span></span>30 days of Prepaid Internet
                    <div className="refill-radio-list--price">
                      <sup>$</sup>
                      {sprintf("%2.2f", hsd30Service?.planPrice)}
                    </div>
                    <br></br>
                    <Modal
                      ctaText={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfo
                      }
                      buttonClasses={
                        hsd7Service
                          ? "button--link"
                          : "button--link no-padding no-margin"
                      }
                      title={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfo
                      }
                      body={
                        refillContent?.refillFlow?.orderConfirmation?.promoModal
                          ?.pricingOtherInfoContent
                      }
                    />
                  </label>
                </li>
                {hsd7Service && moveDateGap <= 7 && (
                  <li>
                    <input
                      onClick={() => {
                        digitalDataButtonClick(
                          "hsd-service",
                          "7 days of Prepaid Internet",
                          false,
                          undefined,
                          true,
                          ".refill-radio-list"
                        );
                        select7Day();
                      }}
                      type="radio"
                      id={"hsd2"}
                      name={"hsdRadio"}
                      data-testid="7-day-radio-button"
                    />
                    <label htmlFor={"hsd2"}>
                      <span></span>7 days of Prepaid Internet
                      <div className="refill-radio-list--price">
                        <sup>$</sup>
                        {sprintf("%2.2f", hsd7Service?.planPrice)}
                      </div>
                      <br></br>
                      <Modal
                        ctaText={
                          refillContent?.refillFlow?.orderConfirmation
                            ?.promoModal?.pricingOtherInfo
                        }
                        buttonClasses="button--link"
                        title={
                          refillContent?.refillFlow?.orderConfirmation
                            ?.promoModal?.pricingOtherInfo
                        }
                        body={
                          refillContent?.refillFlow?.orderConfirmation
                            ?.promoModal?.pricingOtherInfoContent
                        }
                      />
                    </label>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default withDashboard(HSDAccordion);
