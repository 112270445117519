const DeviceDetails = ({content}) => {
    return (
        <div className="device-details">
            <h2>
            {content.name} Details
            </h2>
            <div className="device-details-item">
                <div className="device-details-item-label">
                    Device Serial Number
                </div>
                {content.deviceSerialNumber}
            </div>
            <div className="device-details-item">
                <div className="device-details-item-label">
                    MAC Address
                </div>
                {content.deviceId}
            </div>
    </div>
    )
}

export default DeviceDetails