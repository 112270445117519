import { useState, useEffect } from 'react'
// import AutoRefillTable from './AutoRefillTable'
import AutoRefillModal from './AutoRefillModal'
import SuccessBanner from '../SuccessBanner'
import ProgressSpinner from '../ProgressSpinner'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const AutoRefillComponent = (props) => {
    const {
        dashboardContent,
        refillContent,
        fetchedDashboard,
        accountId,
        dashboard,
        autoRefillEnabled,
        enableAutoRefill,
        disableAutoRefill,
        enabledAutoRefill,
        disabledAutoRefill
    } = props

    const [refillTermsChecked, updateRefillTermsChecked] = useState(false)
    const [updatingData, updateUpdatingData] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')

    const toggleAutoRefill = ()  => {
        setSuccessMessage('')
        updateUpdatingData(true)
        if (autoRefillEnabled) {
            disableAutoRefill({accountId, phoneNumber: dashboard?.customerInfo.phoneNumber})
        } else {
            enableAutoRefill({accountId, phoneNumber: dashboard?.customerInfo.phoneNumber})
        }
        updateRefillTermsChecked(false)
    }

    useEffect(() => {
        if (updatingData && (enabledAutoRefill || disabledAutoRefill)) {
            setTimeout(() => {
                updateUpdatingData(false)
                if (autoRefillEnabled) {
                    setSuccessMessage(dashboardContent?.dashboardArea?.autoRefill?.successfullyEnrolledInAutoRefill?.message)
                    trackEvent({
                        event: 'dashboard',
                        dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                        dashboardEvent: 'auto refill set up',
                        dashboardDetail: 'enroll success'
                    })
                } else {
                    setSuccessMessage('Successfuly disabled auto-refill')
                    trackEvent({
                        event: 'dashboard',
                        dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                        dashboardEvent: 'auto refill set up',
                        dashboardDetail: 'cancel success'
                    })
                }
            }, 800)
        }
    }, [enabledAutoRefill, disabledAutoRefill]) // eslint-disable-line

    return (
        <div className="card auto-refill">
            <SuccessBanner
                text={successMessage}
                displayModal={!!successMessage}
                onClose={() => setSuccessMessage('')}
            />
            { updatingData || !dashboardContent.loaded || !refillContent.loaded || !fetchedDashboard
            ? <ProgressSpinner loadingText={dashboardContent?.dashboardArea?.autoRefill?.savingYourAutoRefillPreferences?.loadingScreenText} />
            : <div>
                    <div className="card__header">
                        <h2 className="card__header-title">{`${autoRefillEnabled ? dashboardContent?.dashboardArea?.autoRefill?.yourAutoRefillDetails : dashboardContent?.dashboardArea?.autoRefill?.setUpAutoRefill}`}</h2>
                        {autoRefillEnabled &&
                        <AutoRefillModal
                            title={refillContent?.refillFlow?.orderConfirmation?.autoRefillIsCurrentlyActive}
                            ctaText={refillContent?.refillFlow?.orderConfirmation?.autoRefillOn?.text}
                            onCancel={() => toggleAutoRefill()}
                        />}
                        <div className="auto-refill__disclaimer" dangerouslySetInnerHTML={{__html: dashboardContent?.dashboardArea?.autoRefill?.autoRefillWillAutomaticallyCharge}} />
                    </div>
                    {/* <AutoRefillTable /> */}
                    {!autoRefillEnabled ?
                    <div className="checkbox">
                    <input
                    id="terms"
                    type="checkbox"
                    name="terms"
                    className="checkbox__input"
                    checked={refillTermsChecked}
                    onChange={() => updateRefillTermsChecked(!refillTermsChecked)}
                    />
                    <label
                        htmlFor="terms"
                        className="checkbox__label"
                        dangerouslySetInnerHTML={{__html: dashboardContent?.dashboardArea?.autoRefill?.iWouldLikeToBeEnrolled}}
                    ></label>
                </div> : null}
                <div className="button--container">
                    <button className="button" data-testid="btn-toggle-AutoRefill" disabled={!autoRefillEnabled && !refillTermsChecked} onClick={() => {
                        trackEvent({
                            event: 'dashboard',
                            dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                            dashboardEvent: 'auto refill set up',
                            dashboardDetail: autoRefillEnabled ? 'cancel submit' : 'enroll submit'
                        })
                        toggleAutoRefill()
                    }}
                    data-tracking={digitalDataButtonClick('auto-refill-setup', `${!autoRefillEnabled ? dashboardContent?.dashboardArea?.autoRefill?.enrollInAutoRefill?.text : dashboardContent?.dashboardArea?.autoRefill?.cancelAutoRefill?.text}`)}
                    >{`${!autoRefillEnabled ? dashboardContent?.dashboardArea?.autoRefill?.enrollInAutoRefill?.text : dashboardContent?.dashboardArea?.autoRefill?.cancelAutoRefill?.text}`}</button>
                </div>
            </div>}
        </div>
    )
}

export default withDashboard(AutoRefillComponent)