import MerchantLayout from '../../layouts/MerchantLayout'
import { ContentContextConsumer } from '../../lib/ContentContext'
import { CheckAvailability } from '../../components/merchant/CheckAvailability'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

export const DollarGeneral = props => {
    const { dashboard } = props;
    
    return (
        <MerchantLayout locale={props?.locale}>
            <TrackUserData data={dashboard} />
            <ContentContextConsumer>
                {appContext =>
                    appContext && (
                        <CheckAvailability isMove={false} content={appContext?.content} data-testid="check-availability" isTestFile={props.isTestFile}/>
                    )
                }
            </ContentContextConsumer>
            <TrackPageComplete />
        </MerchantLayout>
    )
}