import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { digitalDataAccordionClick } from '../utils/adobe-analytics'

const AccordionText = (props) => {
    const [expanded, setExpanded] = useState(false)
    const {
        title,
        body
    } = props


    const toggle = () => {
        setExpanded(!expanded)
    }

    return (
        <div className="accordion accordion--text" role="tablist" multiselectable="true">
            <div className="accordion__item">
                <div
                    id="'01-tab'"
                    className={`accordion__tab ${expanded ? "" : "active"}`}
                    role="tab"
                    aria-expanded={expanded}
                    aria-controls="01-panel"
                    onClick={() => {
                        toggle();
                        digitalDataAccordionClick(
                            "accordion-text",
                            title,
                            '.accordion__tab'
                        )
                    }}
                >
                    <div>{title}</div>
                    <div className="accordion__icon">
                        <span className="line" /><span className="line" />
                    </div>
                </div>

                <CSSTransition
                    in={expanded}
                    timeout={{ enter: 500, exit: 0 }}
                    classNames="expand"
                >
                    <div
                        id="'01-panel'"
                        className={`accordion__panel ${expanded ? "closed" : "expand-exit-done"}`}
                        tabIndex={0}
                        role="tabpanel"
                    >
                        <div dangerouslySetInnerHTML={{__html: body}} />
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

export default AccordionText