import {useState} from 'react'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const PurchaseAgreementForm = ({litepayPages, billingIsValid, processRefill}) => {

    const [checked, setChecked] = useState(false)

    return (
        <div className="card">
            <form>
                <div className="agreement-selection">
                    <div className="card__header">
                        <h2 className="card__header-title">
                        {litepayPages?.litepay?.confirmStep?.termsTitle}
                        </h2>
                    </div>
                    <div className="checkbox">
                        <input
                        id="purchaseAgreement"
                        type="checkbox"
                        className="checkbox__input"
                        name="purchaseAgreement"
                        onChange={() => setChecked(!checked)}
                        />
                        <label className="checkbox__label" htmlFor="purchaseAgreement">

                        <p dangerouslySetInnerHTML={{__html: litepayPages?.litepay?.confirmStep?.terms}} />

                        </label>
                    </div>
                </div>
                <div className="button--container">
                    <button className="button" disabled={!checked || !billingIsValid} onClick={(e) => {processRefill(e)}} data-tracking={digitalDataButtonClick('confirm-step', litepayPages?.litepay?.confirmStep?.buttonLabel)}>
                        {litepayPages?.litepay?.confirmStep?.buttonLabel}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default PurchaseAgreementForm