import { useState } from 'react'
import { formatMoney } from '../../utils/utils'
import withLitepay from '../../redux/connectors/litepay'
import Cart from './Cart'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const StickyCart = (props) => {
    const {
        handleNext,
        nextDisabled,
        showDetails,
        dictionaryContent,
        cartTotal
    } = props
    const [expanded, setExpanded] = useState(false)

    return (
        <div className={`sticky-cart js-sticky-cart sticky-cart--transition ${expanded ? "sticky-cart--expanded" : ""}`}>

            <div data-testid="expanded-sticky-cart" className="sticky-cart__sticker" tabIndex={0} onClick={() => setExpanded(!expanded)} data-tracking={digitalDataButtonClick('sticky-cart', dictionaryContent?.global?.item)}>
                <div className="sticky-cart__sticker-width-constraint">
                    <div className="flex flex--v-center flex--justify" data-flex-start="tiny">
                        <div className="flex-item">
                            <span className="sticky-cart__button js-sticky-cart__button">
                            <img alt="cart" className="icon" src='/site-assets/img/icon-cart.svg' />
                            1 {dictionaryContent?.global?.item}

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.929 284.929" className="sticky-cart__button-icon sticky-cart__button-icon--down">
                                <path
                                d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                />
                            </svg>


                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.929 284.929" className="sticky-cart__button-icon sticky-cart__button-icon--up">
                                <path
                                d="M282.082,195.285L149.028,62.24c-1.901-1.903-4.088-2.856-6.562-2.856s-4.665,0.953-6.567,2.856L2.856,195.285
                                C0.95,197.191,0,199.378,0,201.853c0,2.474,0.953,4.664,2.856,6.566l14.272,14.271c1.903,1.903,4.093,2.854,6.567,2.854
                                c2.474,0,4.664-0.951,6.567-2.854l112.204-112.202l112.208,112.209c1.902,1.903,4.093,2.848,6.563,2.848
                                c2.478,0,4.668-0.951,6.57-2.848l14.274-14.277c1.902-1.902,2.847-4.093,2.847-6.566
                                C284.929,199.378,283.984,197.188,282.082,195.285z"
                                />
                            </svg>
                            </span>

                            <div className="price price--large" tabIndex={-1} aria-hidden="true">
                                <div className="price__table">
                                    <div className="price__symbol">
                                    $
                                    </div>
                                    {showDetails ?
                                    <div className="price__amount">
                                    { formatMoney(cartTotal) }
                                    </div> :
                                    <div className="price__amount">
                                    { formatMoney(cartTotal) }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                          <input disabled={nextDisabled || false} value={dictionaryContent?.global?.nextStep} type="button" className="button" alt="Next" onClick={(e) => {digitalDataButtonClick('handle-next', dictionaryContent?.global?.nextStep, false, undefined, true, '.sticky-cart'); handleNext(e)}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-cart__body">
                <div className="sticky-cart__body--width-constraint sticky-cart__sticker-width-constraint">
                    <Cart showDetails={true}/>
                </div>
            </div>
        </div>
    )
}
export default withLitepay(StickyCart)