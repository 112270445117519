import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import withBuyflow from '../../redux/connectors/buyflow'
import { CSSTransition } from 'react-transition-group'
import Cart from '../buyflow/Cart'
import { useHistory } from 'react-router-dom'
import AccordionText from '../AccordionText'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataAccordionClick } from '../../utils/adobe-analytics'

const ModalPromo = (props) => {
    const {
        cartPages,
        modalCloseButton,
        togglePromo,
        modalContent,
        displayPromoModal,
        hsdPromoId,
        locale
    } = props
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const node = useRef<HTMLDivElement | null>(null)


    const history = useHistory()

    const closeModal = () => {
        if (modalIsOpen) {
            setModalIsOpen(false)
            document.removeEventListener("mousedown", handleClick)
            document.body.classList.remove('modal-open')
            history.push('/' + locale + '/buy/session')
            trackEvent({
                'event':'buyflow info',
                'buyStep':'step 2',
                'buyStepName':'internet',
                'buyEvent':'offer acquisition modal',
                'buyDetail':'close'
            })
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener("mousedown", handleClick)
        } else {
            document.removeEventListener("mousedown", handleClick)
        }
        // eslint-disable-next-line
    }, [modalIsOpen])
    useEffect(() => {
        if (displayPromoModal) {
        setModalIsOpen(true)
        document.body.classList.add('modal-open')
        trackEvent({
            'event':'buyflow info',
            'buyStep':'step 2',
            'buyStepName':'internet',
            'buyEvent':'offer acquisition modal',
            'buyDetail':'impression'
        })
        }
    }, [displayPromoModal])

    const handleClick = e => {
        if (null !== node.current && node.current.contains(e.target)) {
          // inside click
            return
        }
        // outside click
        closeModal()
    }
    
    const handlePurchaseClick = () => {
        togglePromo(hsdPromoId)

        trackEvent({
            'event':'buyflow info',
            'buyStep':'step 2',
            'buyStepName':'internet',
            'buyEvent':'offer acquisition modal',
            'buyDetail':'click'
        })
        setModalIsOpen(!modalIsOpen)
        document.removeEventListener("mousedown", handleClick)
        history.push('/' + locale + '/buy/session')
    }

    const toggleAccordion = () => {
        setExpanded(!expanded)
    }


    return (
        <div
            className='modal-item'
            onClick={stopPropagation}
        >
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className="modal modal--visible"
            overlayClassName="modal__overlay"
            >
                <div className="modal__content activation-conflict-modal">
                <div ref={node} className="modal__body direct-promo">
                            <h3>{modalContent?.title}</h3>
                            <p>{modalContent?.body}</p>
                            <div className="direct-promo__hero-banner">
                                <img className="display-large" src={modalContent?.bannerImage.desktopImage.url} alt={modalContent?.bannerImage.desktopImage.alt} />
                                <img className="display-small-medium" src={modalContent?.bannerImage.mobileImage.url} alt={modalContent?.bannerImage.mobileImage.alt} />
                                <div className="direct-promo__hero-banner-container">
                                    <div className="main__width-constraint clear">
                                        {modalContent?.saveLabel}
                                    </div>
                                </div>
                            </div>

                            <div id="main-content">
                                <div className="main__width-constraint">
                                    <div className="card card-promo">
                                        <div className="card__summary">
                                            <div v-if="version == 'version1'" className="accordion direct-promo-accordion" role="tablist" multiselectable="true">
                                                <div className="accordion direct-promo-accordion" role="tablist" multiselectable="true">
                                                    <div className="accordion__item">
                                                        <div
                                                            id="'01-tab'"
                                                            className={`accordion__tab direct-promo-accordion__tab ${expanded ? "" : "active"}`}
                                                            role="tab"
                                                            aria-expanded={expanded}
                                                            aria-controls="01-panel"
                                                            onClick={() => {
                                                                toggleAccordion();
                                                                digitalDataAccordionClick(
                                                                    "promo",
                                                                    '$' + cartPages?.step1Internet?.promoModal?.price + ' ' + cartPages?.step1Internet?.promoModal?.priceForUpTo,
                                                                    '.accordion-tab'
                                                                )
                                                            }}
                                                        >
                                                            <table v-if="cartPages.step1Internet.promoModal" className="price-wrap">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="price-wrap__amount">
                                                                            <span>$</span>{cartPages?.step1Internet?.promoModal?.price}
                                                                        </td>
                                                                        <td className="price-wrap__label">
                                                                            <strong>{cartPages?.step1Internet?.promoModal?.priceForUpTo}</strong>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="accordion__icon">
                                                                <span className="line" /><span className="line" />
                                                            </div>
                                                        </div>

                                                        <CSSTransition
                                                            in={expanded}
                                                            timeout={{ enter: 500, exit: 0 }}
                                                            classNames="expand"
                                                        >
                                                            <div
                                                                id="'01-panel'"
                                                                className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
                                                                tabIndex={0}
                                                                role="tabpanel"
                                                            >
                                                                <Cart showDetails={false} allowEditing={false} showLineOffer={true} />
                                                            </div>
                                                        </CSSTransition>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                            <div className="button--container">
                                                <button className="button" onClick={() => handlePurchaseClick()} data-tracking={digitalDataButtonClick('purchase', modalContent?.purchase.text)}>{modalContent?.purchase.text}</button>
                                                <br />
                                                <br />
                                                <AccordionText title={modalContent.pricingOtherInfo} body={modalContent.pricingOtherInfoContent} />
                                                <button className="button--link" onClick={() => closeModal()} data-tracking={digitalDataButtonClick('close-modal', modalContent?.decline, true)}>{modalContent?.decline}</button>
                                            </div>
                                    </div>

                                </div>
                            </div>
                            <button data-a11y-dialog-hide aria-label="Close this dialog window" className="modal__close" onClick={closeModal} data-tracking={digitalDataButtonClick('close-modal', modalCloseButton, true)}>
                                {modalCloseButton}
                                <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                            </button>
                        </div >
                </div>

            </Modal>
        </div>
    )
}

Modal.setAppElement('body')

export default withBuyflow(ModalPromo)
