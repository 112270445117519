import { useState, useEffect, useCallback, FunctionComponent } from 'react'
import { digitalDataLinkClick } from '../utils/adobe-analytics'

const Footer: FunctionComponent<any> = props => {
    const [content, setContent] = useState({ footer: {
        copyrightStatement: '',
        link1Destination: { url: '', target: '' },
        link1Label: '',
        link2Destination: { url: '', target: '' },
        link2Label: '',
        link3Destination: { url: '', target: '' },
        link3Label: '',
        link4Destination: { url: '', target: '' },
        link4Label: '',
        link5Destination: { url: '', target: '' },
        link5Label: ''
    }, loaded: false })

    const fetchData = useCallback(async () => {
        const API_AUTHORIZATION_USER = process.env.REACT_APP_API_AUTHORIZATION_USER;
        const API_AUTHORIZATION_PASSWORD = process.env.REACT_APP_API_AUTHORIZATION_PASSWORD;
        const BUYFLOW_API:string = process.env.REACT_APP_BUYFLOW_CONTENT_API!;
        const buyflowContent = {};
        let params = {};

        if (API_AUTHORIZATION_USER && API_AUTHORIZATION_PASSWORD) {
            params = {
                headers: new Headers({
                    'Authorization': 'Basic ' + Buffer.from(API_AUTHORIZATION_USER + ':' + API_AUTHORIZATION_PASSWORD).toString('base64')
                })
            }
        }

        const [sourceContent] = await Promise.all([
            fetch(BUYFLOW_API, params).then(r => r.json())
        ]);

        sourceContent?.buyflow.forEach((obj) => {
            const keys = Object.keys(obj);
            keys.forEach((key) => {
                buyflowContent[key] = obj[key]
            });
        });
        const footerContent = buyflowContent['buyflowFooter'];
        setContent({footer: footerContent, loaded: true});
    }, []);

    useEffect(() => {
        fetchData().catch(console.error);
        return () => {
            setContent({ footer: {
                copyrightStatement: '',
                link1Destination: { url: '', target: '' },
                link1Label: '',
                link2Destination: { url: '', target: '' },
                link2Label: '',
                link3Destination: { url: '', target: '' },
                link3Label: '',
                link4Destination: { url: '', target: '' },
                link4Label: '',
                link5Destination: { url: '', target: '' },
                link5Label: ''
            }, loaded: false });
          };
    }, [fetchData]);

    if (content?.loaded) {
        return (
            <div className="footer" data-testid="footer">
                <div className="footer__width-constraint">
                    <div className="footer__flex">
                        <nav aria-label="Footer Utility Links">
                        <ul className="footer__utils">
                            <li dangerouslySetInnerHTML={{__html: content?.footer?.copyrightStatement!}} />
                            <li>
                                <a href={content?.footer?.link1Destination?.url} target={content?.footer?.link1Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', content?.footer?.link1Destination?.url, content?.footer?.link1Label)}>{content?.footer?.link1Label}</a>
                            </li>
                            <li>
                                <a href={content?.footer?.link2Destination?.url} target={content?.footer?.link2Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', content?.footer?.link2Destination?.url, content?.footer?.link2Label)}>{content?.footer?.link2Label}</a>
                            </li>
                            <li>
                                <a href={content?.footer?.link3Destination?.url} target={content?.footer?.link3Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', content?.footer?.link3Destination?.url, content?.footer?.link3Label)}>{content?.footer?.link3Label}</a>
                            </li>
                            <li>
                                <a href={content?.footer?.link4Destination?.url} target={content?.footer?.link4Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', content?.footer?.link4Destination?.url, content?.footer?.link4Label)}>{content?.footer?.link4Label}</a>
                            </li>
                            <li>
                                <a href={content?.footer?.link5Destination?.url} target={content?.footer?.link5Destination?.target} rel="noreferrer noopener" data-tracking={digitalDataLinkClick('footer-utility', content?.footer?.link5Destination?.url, content?.footer?.link5Label)}>{content?.footer?.link5Label}</a>
                            </li>
                        </ul>
                        </nav>
                        <div className="showLogo footer__logo">
                            <div className="comcast-logo" aria-label="Comcast" />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else return null;
}

export default Footer;