import {useEffect, useState} from 'react'
import withBuyflow from '../../redux/connectors/buyflow'

let alternateTextTimer
const ProgressSpinner = (props) => {
    const {
        checkoutContent
    } = props

    const [showAlternateText, updateShowAlternateLoadingText] = useState(false)
    
    useEffect(() => {
        alternateTextTimer = setTimeout(function () { 
            updateShowAlternateLoadingText(true)
        }, 15 * 1000)
        return () => clearTimeout(alternateTextTimer)
    }, []) // eslint-disable-line
    return (
        <div>
            <div className="progress-spinner">
                <div className="progress-spinner__loader">
                    <div className="progress-spinner__text">
                        <span dangerouslySetInnerHTML={{__html: checkoutContent?.checkoutLoadingScreenAfterRedirect?.loadingScreenText}} />
                    </div>

                    <div className="progress-spinner__circle" />
                    {showAlternateText ? 
                    <div v-if="showAlternateText" className="progress-spinner__timeout">
                        { checkoutContent?.checkoutLoadingScreenAfterRedirect?.loadingScreenTimeoutMessage }
                    </div>
                    : null }
                </div>
            </div>
        </div>
    )
}

export default withBuyflow(ProgressSpinner)
