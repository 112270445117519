import { GlobalModal as Modal } from '../Modal'
import ResetDevice from './ResetDevice'
import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/utils'
import { digitalDataLinkClick } from '../../utils/adobe-analytics'

const LinkList = (props) => {
    const {
        content,
        haveScheduledMove,
        locale
    } = props

    const lookupValuetoAction = {
        'help': '/support/general',
        'offers-promos': '/dashboard',
        'payment-history': '/dashboard/payment-history',
        'payment-method': '/dashboard/edit-payment',
        'refill-cash': '/refill',
        'reset-router': null,
        'setup-refill': '/dashboard/auto-refill',
        'move': '/move/'

    }

    //TODO replace static object with data:
    //const renderLinkList = content.icons.map((item, index) => {
    const renderLinkList = content?.icons.map((item, index) => {
        if (item.lookupValue === 'move' && haveScheduledMove) {
            return null
        }

        const trackClick = () => {
            trackEvent({
                event: 'dashboard',
                dashboardPage: document.title.replace(' - Xfinity Prepaid', ''),
                dashboardEvent: 'helpful links',
                dashboardDetail: item.lookupName

                })
        }
        const action = lookupValuetoAction[item.lookupValue]
        const linkContent = (
            <>
                <div className="link-list-item-icon">
                    <img src={`/site-assets/img/${item.lookupValue}.svg`} alt={item.lookupName} />
                </div>
                <div className="link-list-item-description">
                    { item.lookupName }
                </div>
            </>
        )
        // create a link element
        const createInternalLink = link => {
            return (
                <Link key={index} to={link} onClick={(e) => trackClick()} data-testid="linkContent1" className="link-list-item" data-tracking={digitalDataLinkClick('link-list-item', link, item.lookupName)}>
                    {linkContent}
                </Link>
            )
        }
        const createExternalLink = link => {
            return (
                <a key={index} className="link-list-item" href={link} onClick={(e) => trackClick()} data-testid="linkContent2" target="_blank" rel="noreferrer" data-tracking={digitalDataLinkClick('link-list-item', link, item.lookupName)}>
                    {linkContent}
                </a>
            )
        }
        let thisItem
        // determine if we have a link
        if (item?.lookupLink?.url) thisItem = createExternalLink(item.lookupLink.url)
        else if ((typeof action === 'string' || action instanceof String) && item.lookupValue !== 'help') thisItem = createInternalLink('/' + locale + action)
        else if ((typeof action === 'string' || action instanceof String) && item.lookupValue === 'help') { 
            const base = process.env.REACT_APP_SHOPBASE_URL+'/support/general';
            thisItem = createExternalLink(base)
          }
        // if not link then special condition
        else if (item.lookupValue === 'reset-router') {
            thisItem = (<Modal
                key={index}
                modalWrapClasses={'link-list-item'}
                buttonClasses="basic-button"
                ctaText={linkContent}
                component={<ResetDevice />}
                onOpen={() => {
                    trackClick()
                }}
                />)
        }
        return thisItem
    })

    return (
        <div className="link-list">
            <div className="main__width-constraint">
                <div className="card__header-title">
                    {content?.headerText}
                </div>
                <hr />
                <div className="link-list-items">
                    {renderLinkList}
                </div>
            </div>
        </div>
    )

}

export default LinkList