import { useState } from 'react'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

export const NotificationBanner = props => {
    const { notificationMessage } = props
    const [hidden, setHidden] = useState(false)

    return (
        <div className={`notification-banner ${hidden ? 'hide ' : ''}`}>
            <div className="well well--warning">
                <div className="well__text">
                    <img title="warning" alt="warning" className="icon" src='../../site-assets/img/icon-warning.svg' />
                    <p>{notificationMessage}</p>
                </div>
                <button data-a11y-dialog-hide aria-label="Close this dialog" className="well__close" onClick={() => setHidden(true)} data-tracking={digitalDataButtonClick('close-modal', 'Close this dialog', true)}>
                    <img title="close" alt="close" className="icon" src='../../site-assets/img/icon-x.svg' />
                </button>
            </div>
        </div>
    )

    
}