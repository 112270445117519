import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import withBuyflow from '../../redux/connectors/buyflow'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom';
import { digitalData404PageError } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: require('../../assets/animations/404.json'),
    speed:1
}

const BuyFlowNotFound = (props) => {
    const {
        locale, 
        cartPages,
        updateProgress,
        cartContent,
        dashboard
    } = props

    const [pageTitle, setPageTitle] = useState('Xfinity Prepaid')
    const location = useLocation();
    
    useEffect(() => {
        digitalData404PageError("404 page", "404", "page not found", ".error-page");
    }, [location.pathname]);

    useEffect(() => {
        setPageTitle('Error - Xfinity Prepaid')
        updateProgress({ title: '', progress: 100 })
    }, [cartPages]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BuyFlowLayout locale={locale}>
            <TrackUserData data={dashboard} />
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="main__width-constraint error-page">
                <Lottie
                    options={defaultOptions}
                    height={300}
                    width={300}
                />
                <div dangerouslySetInnerHTML={{__html: cartContent?._404Page?._404PageContent}} />
            </div>
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}

export default withBuyflow(BuyFlowNotFound)
