import { useEffect } from 'react';
import { pageComplete } from "./adobe-analytics";

const TrackPageComplete = () => {
  useEffect(() => {
    pageComplete();
  }, []);
  return null;
};

export default TrackPageComplete;