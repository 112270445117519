
export const ProgressBar = props => {
    const { title, progress } = props

    
    return (
        <div className="progress-bar">
            <div className="progress-bar__text progress-bar__width-constraint">
                <p className="progress-bar__title">
                    {title}
                </p>
            </div>
            <div className="progress-bar__elm">
                <div style={{width: `calc(100% - ${progress}%)`}} className="progress-bar__empty" />
            </div>
        </div>
    )

    
}