import axios from "axios";
let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const clientSide = typeof window !== "undefined";

if (clientSide) {
  if (!window.location.host.includes("local")) {
    apiBaseUrl = `${window.location.protocol}//${window.location.hostname}`;
  }
}

// console.log('⚡️ apiBaseUrl', apiBaseUrl)

export const fetchToken = async ({ trackingId }) => {
  const url = `${apiBaseUrl}/token?trackingId=${trackingId}`;
  await axios.get(url);

  // console.log('fetchToken response', response)

  // XXX Need to get the token out of the cookie. BUT, may not be needed for anything.
  // X-XSRF-TOKEN header is added automatically by axios once the cookie is set.
  const xsrfToken = null;

  return { xsrfToken };
};

export const validateServiceability = async ({ locationId, trackingId }) => {
  console.log("api.validateServiceability");
  const url = `${apiBaseUrl}/secure/api/v1/serviceability/address/validate-customer-serviceability?source=CP&trackingId=${trackingId}&locationId=${locationId}`;
  const response = await axios.get(url);

  console.log("validateServiceability response", response.data);

  return response.data;
};

export const fetchProductDetails = async ({ marketId, trackingId }) => {
  console.log("api.fetchProductDetails", marketId, trackingId);
  const url = `${apiBaseUrl}/secure/api/v1/util-service/product-detail?source=123&trackingId=${trackingId}&marketId=${marketId}`;
  const response = await axios.get(url);

  // console.log('fetchProductDetails response', response.data)

  return response.data;
};

export const createSession = async ({ data, trackingId }) => {
  console.log("api.createSession", data);
  let url = apiBaseUrl;
  const apiUrl = `${url}/secure/session?trackingId=${trackingId}`;
  const response = await axios.post(apiUrl, data, {
    headers: {
      source: "DIGITAL2.0",
      trackingid: trackingId,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const restoreDigitalSession = async ({ digitalSessionId }) => {
  console.log("api.restoreSession", digitalSessionId);
  let url = apiBaseUrl;
  const apiUrl = `${url}/secure/session?digitalSessionId=${digitalSessionId}`;
  const response = await axios.get(apiUrl, {
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
};
export const getPromos = async ({ data, trackingId }) => {
  console.log("api.getPromos", data);
  let url = apiBaseUrl;
  const apiUrl = `${url}/secure/api/v1/offer-service/get-promo?trackingId=${trackingId}`;
  const response = await axios.post(apiUrl, data);
  return response.data;
};
export const fetchTaxData = async ({ data, trackingId }) => {
  console.log("api.fetchTaxData", data);
  let url = process.env.REACT_APP_CA_API_BASE_URL;
  const apiUrl = `${url}/oauth/tax/emm-pp-vertex-service/v1/vertex/ctp/get-tax?trackingId=${trackingId}`;
  const response = await axios.post(apiUrl, data);
  return response.data;
};
export const submitOrder = async ({ payload, trackingId }) => {
  console.log("api.submitOrder", payload);
  let url = apiBaseUrl;
  const apiUrl = `${url}/secure/api/v1/PinlessActivation/prepaid-customer-activation?trackingId=${trackingId}`;
  const response = await axios.post(apiUrl, payload);
  return response.data;
};
export const getPaymentId = async ({ trackingId }) => {
  console.log("api.getPaymentId");
  let url = apiBaseUrl;
  const apiUrl = `${url}/secure/v1/customermanagement/prepaidPaymentID?trackingId=${trackingId}`;
  const response = await axios.get(apiUrl, {
    headers: {
      source: "DIGITAL2.0",
      trackingid: trackingId,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const addPaymentInstrument = async ({payload, sessionId, trackingId}) => {
  const url = `${apiBaseUrl}/secure/payment-service/add-secure-prepaid-payment-instrument?trackingId=${trackingId}`

  const data = payload

  const response = await axios.post(url, data, {
    headers: {
      source: "DIGITAL2.0",
      trackingid: trackingId,
      }
  })

  return response.data
};