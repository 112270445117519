import ActivationLayout from '../../layouts/ActivationLayout'
import ActivationStepsAccordion from '../../components/activation/ActivationStepsAccordion'
// import ConflictModal from '../../components/ConflictModal'
import withActivation from '../../redux/connectors/activation'
import ProgressSpinner from '../../components/ProgressSpinner'
import { useEffect, useState } from 'react'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

// Accordion will render various content depending on items[2] connection: active/inactive and deviceLocated: true/false
const items = [
    {
        title: '',
        content: null,
        id: 1,
        type: 'account-id',
    },
    {
        title: 'Account Owner\'s Name',
        content: null,
        id: 2,
        type: 'account-name',
        hidden: false
    },
    {
        title: '',
        content: null,
        id: 3,
        readyForActivation: true,
        deviceLocated: false,
        type: 'device',
    },
    {
        title: '',
        content: null,
        id: 4,
        type: 'activation',
    }
]

const renderHeaderContent = (content, firstName, isVanAccount) => {
    if (items[2].readyForActivation && items[2].deviceLocated === true) {
        return (
            <div>
                <h1 className="page__title">
                    {isVanAccount ? content?.stepsCompleteBlock?.title.replace(', [FirstName]', '')
                    : content?.stepsCompleteBlock?.title.replace('[FirstName]', firstName)}
                </h1>
                <p>
                    {content?.stepsCompleteBlock?.text}
                </p>
            </div>
        )
    } else if (items[2].readyForActivation && items[2].deviceLocated === false) {
        return (
            <div>
                <h1 className="page__title">
                    {content?.stepsIncompleteBlock?.title.replace('[FirstName]', firstName)}
                </h1>
                <p>
                    {content?.stepsIncompleteBlock?.text}
                </p>
            </div>
        )
    } else {
        return (
            <div>
                <h1 className="page__title">
                    {content?.serviceNotActiveBlock?.title.replace('[FirstName]', firstName)}
                </h1>
                <p>
                    {content?.serviceNotActiveBlock?.text}
                </p>
            </div>
        )
    }
}

const renderLoader = (content) => {
    return (
        <div className="main__width-constraint">
            <ProgressSpinner  loadingText='Loading Activation' />
        </div>
    )
}

const renderBody = (content, isVanAccount, dashboard, deviceStatus, items) => {
    // assumptions: deviceStatus.sroYN tells us if a service is ready to be activated?
    // If so, this is where we set the active/inactive toggle
    // another assumption is that deviceStatus.devices[0].deviceStatus will tell us if the modem has been found or not.
    // This will set the deviceLocated toggle, which shows/hides the manual MAC entry

    if (deviceStatus?.sroYN === 'N') {
        items[2].readyForActivation = true
    } else {
        items[2].readyForActivation = false
    }
    if (deviceStatus?.devices && deviceStatus?.devices.length && (deviceStatus?.devices[0].deviceStatus === 'FOUND')) {
        items[2].deviceLocated = true
    } else { // 'NOTFOUND'
        items[2].deviceLocated = false
    }
    return (
        <div>
            { renderHeaderContent(content?.activation?.activationFlow?.activatePrepaidService, dashboard?.customerInfo?.firstName, isVanAccount)}
            <ActivationStepsAccordion items={items} content={content?.activation?.activationFlow?.activatePrepaidService} />
        </div>
    )
}

const Home = (props) => {
    const {
        updateBehindTheModemStatus,
        updateInAppStatus,
        updateAccountId,
        updateCustGuid,
        generateTrackingId,
        fetchToken,
        fetchEnglishActivationContent,
        fetchSpanishActivationContent,
        fetchIdentityInfo,
        fetchCustomerDashboard,
        fetchDeviceStatus,
        fetchedToken,
        fetchedContent,
        fetchingEnglishContentFailed,
        fetchingSpanishContentFailed,
        fetchingIdentityFailed,
        fetchingDashboardFailed,
        fetchingDeviceStatusFailed,

        sitecoreContent,
        deviceStatus,
        dashboard,
        identityAccounts,
        accountId,
        custGuid,
        isVanAccount,
        locale,
        ready,
        history,
    } = props

    const [loading, setLoading] = useState(true)

    const clientSide = (typeof window !== "undefined")

    useEffect(() => {
        let urlParams = new URLSearchParams(clientSide ? window.location.search : '');

        const bhr = urlParams.get('bhr')
        const mobile = urlParams.get('mobile')

        let accountIdParam = urlParams.get('accountId')
        if (accountIdParam) {
            accountIdParam = atob(accountIdParam).replace(/["]/g, '')
        }

        let custGuidParam = urlParams.get('guid')

        if (custGuidParam) {
            custGuidParam = atob(custGuidParam)
        }

        console.log('accountId', accountIdParam, 'custGuid', custGuidParam, 'bhr', bhr)

        updateBehindTheModemStatus(bhr && bhr === 'true')
        updateInAppStatus(mobile && mobile === 'true')
        updateAccountId(accountIdParam)
        updateCustGuid(custGuidParam)
        generateTrackingId()

        fetchToken()

        // 60s in a min x 15 mins x 1000 (to convert to milliseconds)
        const sessionTimeout = 60 * 15 * 1000;
        setTimeout(() => {
            console.log('session timeout, logging out')
            window.location.href = "https://oauth.xfinity.com/oauth/sp-logout?client_id="+process.env.REACT_APP_LOGOUT_CLIENT_ID;
        }, sessionTimeout)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fetchedToken) {
            if (!accountId || !custGuid) {
                history?.replace(`/${locale}/activation/error`)
            } else {
                fetchEnglishActivationContent()
                fetchSpanishActivationContent()
                fetchIdentityInfo({custGuid})
                fetchCustomerDashboard({accountId})
                // loadPrepaidAccount({accountId})
                fetchDeviceStatus({accountId, cpeIP: ''})
            }
        }
    }, [fetchedToken]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        items[1].hidden = !isVanAccount
    }, [isVanAccount])

    useEffect(() => {
        // console.log('failures', fetchingEnglishContentFailed, fetchingSpanishContentFailed, fetchingIdentityFailed, fetchingDashboardFailed, fetchingDeviceStatusFailed)
        let failed = fetchingEnglishContentFailed || fetchingSpanishContentFailed || fetchingDashboardFailed
        failed = failed || fetchingIdentityFailed || fetchingDeviceStatusFailed

        if (failed) {
            setTimeout(() => {
                history?.replace(`/${locale}/activation/error`)
            }, 3000);
        }
    }, [fetchingEnglishContentFailed, fetchingSpanishContentFailed, fetchingIdentityFailed, fetchingDashboardFailed, fetchingDeviceStatusFailed, locale, history])


    useEffect(() => {
        if (ready) {
            if (identityAccounts && identityAccounts.length) {
                let thisAccount = identityAccounts.find(acct => acct.accountNumber === accountId)

                if (thisAccount) {
                    if (thisAccount.role !== 'ACCOUNT_OWNER') {
                        history.push(`/${locale}/activation/permission-error`)
                    }
                }
            }

            setLoading(!ready)
        }
    }, [ready, identityAccounts, accountId, history, locale])

    return (
        <ActivationLayout>
            <TrackUserData data={dashboard} />
            <div>
                {loading || !fetchedContent
                ? renderLoader(sitecoreContent)
                : renderBody(sitecoreContent, isVanAccount, dashboard, deviceStatus, items)}
            </div>
            <TrackPageComplete />
        </ActivationLayout>
    )
}

const ActivationHome = withActivation(Home)
export default ActivationHome

