import { useEffect } from 'react'
import Accordion from '../Accordion'
import { trackEvent } from '../../utils/utils'
import MerchantModal from './MerchantModal'

const ReadyToBuy = ({content, addressData}) => {

    // content for timed checkout modal
    const timedCheckoutModalContent = (
        <>
            <h2>{content.success.headsUpModal.title}</h2>
            <p>{content.success.headsUpModal.text}</p>
        </>
    )

    // convert FAQ content
    const convertToAccordionItemArray = arr => {
        let itemArr : any[] = []
        for (let item of arr) {
            itemArr.push({
                question: item.question,
                answer: item.answer
            })
        }
        return itemArr
    }
    const FAQs = convertToAccordionItemArray(content.success.faqs)
    // TEMP
    const coldTapIntro = "After purchase, please wait 48 hours to begin installation. We'll notify you when your service is ready for activation."

    // inital load
    useEffect(() => {
        // analytics
        trackEvent({
            event: 'blind sale serviceability check',
            blindSaleEvent: 'flow',
            blindSaleDetail: 'step 3 ready to buy'
        })
    }, [])

    return (
        <div className="merchant-prepaid__readytobuy">
            <section className='merchant-prepaid__banner merchant-prepaid__banner--green merchant-prepaid__banner--image'>
                <span>
                    <h1 className="merchant-prepaid__title">{content.success.title}</h1>
                    <p className="merchant-prepaid__subtitle">{content.success.introText}</p>
                    {addressData.coldTapFlag && <p className="merchant-prepaid__subtitle">{coldTapIntro}</p> }
                    <div className="merchant-prepaid__heads-up">
                        <img src="/site-assets/img/clock-dark.svg" role="presentation" alt=""/>
                        <div className="merchant-prepaid__heads-up__message">{content.success.headsUpIntro}
                            <MerchantModal content={timedCheckoutModalContent}
                                ctaLabel={content.success.headsUpModal.primaryButtonText}
                                closeBtnTxt={content.dictionary.global.close}
                                ctaClass="merchant-prepaid__bodylink merchant-prepaid__heads-up__cta" />
                        </div>
                    </div>
                </span>
                <div className="merchant-prepaid__banner-image">
                    <img src={content.success.productImage.mobileImage.url} alt={content.success.productImage.mobileImage.alt}></img>
                    <div className="merchant-prepaid__callout">
                        <p className='merchant-prepaid__callout--title'>{content.success.promo.title}</p>
                        <p className='merchant-prepaid__callout--copy'>{content.success.promo.text}</p>
                    </div>
                </div>
                
            </section>
            
            <section className="merchant-prepaid__faq merchant-prepaid__white-bg">
                <h2 className="merchant-prepaid__faq-headline">{content.success.supportTitle}</h2>
                <Accordion items={FAQs} eventObj={{event: 'accordion', accordionType: 'faq', accordionPage: 'dollargeneral', faqSubcategory: content.success.supportTitle}} eventDetailCategory='accordionText' />
            </section>
            
        </div>
    )
}

export default ReadyToBuy