import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom'
import './scss/main.scss'
import { AppRouter } from './router'
import reportWebVitals from './reportWebVitals'

import TagManager from 'react-gtm-module'

import {configurePrepaidStore} from './redux';
const {Provider, store} = configurePrepaidStore();

// const tagManagerArgs = {
//   gtmId: 'GTM-KSK4F25'
// }

declare global {
  interface Window {
      buildDate:string | undefined;
  }
}

const buildDate = process.env.REACT_APP_BUILD_DATE
window.buildDate = buildDate ? new Date(Date.parse(buildDate)).toString() : 'unknown'

if (buildDate && !window.location.host.includes('local')) {
  console.log(`🛠 Built ${window.buildDate}`)
}

//TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}><AppRouter /></Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()