import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser'
import { Element } from 'domhandler/lib/node'
import MerchantModal from './MerchantModal'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'

const DeviceCode = ({setStage, content, addressData, codeData, setCodeData, errorType, setErrorType}) => {

    const { handleSubmit, register, setValue, trigger, errors, setError } = useForm()

    // action when Code Check form is submitted
    const submitCode = values => {
        const consolidatedPhone = values.mobileNumber.replace(/\D/g, '')
        setCodeData({
            code: values.devicecode,
            mobileNumber: consolidatedPhone
        })
        setErrorType(false)
        setStage('codeCheckSubmit')
    }

    // device code checks
    const [code, setCode] = useState('')
    useEffect(() => {
        if (code) {
            const input = code.replace(/\D/g, '')
            const formatted = input.substring(0, 6)
			setValue('devicecode', formatted)
            if (errors.devicecode) trigger('devicecode')
            // here is where we chack if an error came back from the API
            if (errorType) checkError()
        }
    }, [code]) // eslint-disable-line react-hooks/exhaustive-deps

    // format phone number
    const [phone, setPhone] = useState('')
    useEffect(() => {
        if (phone) {
            const input = phone.replace(/\D/g, '')
			const first = input.substring(0, 3)
			const middle = input.substring(3, 6)
			const last = input.substring(6, 10)
			let formatted = ''
			if (input.length > 6) formatted = `(${first}) ${middle}-${last}`
            else if (input.length > 3) formatted = `(${first}) ${middle}`
            else if (input.length > 0) formatted = `(${first}`
            setValue('mobileNumber', formatted)
            // trigger field validation if validation has already failed
            if (errors.mobileNumber) trigger('mobileNumber')
        }
    }, [phone]) // eslint-disable-line react-hooks/exhaustive-deps

    // states for other input
    /*
    const [consent, setConsent] = useState(false)
    const consentToggle = checked => {
        setConsent(checked)
        // analytics
        if (checked) {
            trackEvent({
                event: 'blind sale serviceability check',
                blindSaleEvent: 'ready to buy',
                blindSaleDetail: 'sms opt-in'
            })
        }
    }
    */

    // moving this check to a function instead of directly on the button so that it runs with every render
    const isDisabled = () => {
        //if (!code || !phone || !consent || errors.devicecode || errors.mobileNumber) return true
        if (!code || !phone || errors.devicecode || errors.mobileNumber) return true
    }

    // errors
    const checkError = () => {
        // establish error text
        let errorText
        if (errorType === 'invalid code') errorText = "Device code not found, please confirm the number or try a different device code from a different starter kit"
        else if (errorType === 'unavailable code') errorText = "Device code error, please try a different device code from a different starter kit"
        else errorText = "Something went wrong, please try again"
        // insert error text as input error
        setError('devicecode', {
            type: 'manual',
            message: errorText
        })
        // one-time only error so set global errorType to false
        setErrorType(false)
        // send to analytics
        trackEvent({
            event: 'blind sale serviceability check',
            blindSaleEvent: 'error',
            blindSaleDetail: 'device code not found'
        })
    }

    // inital load
    const [deviceCodeLabel, setDeviceCodeLabel] = useState(<div/>)
    useEffect(() => {
        if (codeData) {
            setCode(codeData.code)
            setPhone(codeData.mobileNumber)
        }
        // "Where can I find this?" modal
        const findCodeModalContent = (
            <>
                <h2>{content.deviceCodeEntry.deviceCodeModal.title}</h2>
                <p>{content.deviceCodeEntry.deviceCodeModal.text}</p>
            </>
        )
        // logic for swapping FAQ modal link into content
        const processLabelText = text => {
            return (<label htmlFor="devicecode">{
                parse(text, {
                    replace: domNode => {
                        if (domNode instanceof Element && domNode.attribs && domNode.attribs.href === '#device-code-modal') return (
                            <MerchantModal content={findCodeModalContent} ctaLabel={domNode.children[0]['data']} closeBtnTxt={content.dictionary.global.close}
                                contentCloseBtnTxt={content.deviceCodeEntry.deviceCodeModal.primaryButtonText}
                                contentImageSrc={content.deviceCodeEntry.deviceCodeModalImage.mobileImage.url}
                                contentImageAlt={content.deviceCodeEntry.deviceCodeModalImage.mobileImage.alt}
                                ctaClass="merchant-prepaid__bodylink side-space" />
                        )
                }})
            }</label>)
        }
        setDeviceCodeLabel(processLabelText(content.deviceCodeEntry.deviceCodeLabel))
        // analytics
        trackEvent({
            event: 'blind sale serviceability check',
            blindSaleEvent: 'flow',
            blindSaleDetail: 'step 2 available'
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="merchant-prepaid__serviceable">

            <section className="merchant-prepaid__banner">
                <h1 className="merchant-prepaid__title">{content.deviceCodeEntry.title}</h1>
                <p className="merchant-prepaid__subtitle">{content.deviceCodeEntry.introText}</p>
                <div className="merchant-prepaid__banner-address">
                    <span className="merchant-prepaid__check-icon"></span>
                    <div className="merchant-prepaid__eligible-address">
                        <p>{addressData.address} {addressData.unit}, {addressData.city}, {addressData.state} {addressData.zip}</p>
                        <button onClick={() => {setStage('addressCheck'); sessionStorage.removeItem('xppdata')}} data-tracking={digitalDataButtonClick('change-address', content.deviceCodeEntry.changeAddress)}>{content.deviceCodeEntry.changeAddress}</button>
                    </div>
                </div>
                
            </section>
            
            <section className="merchant-prepaid__white-bg merchant-prepaid__banner merchant-prepaid__banner--sub">
                <h2 className="merchant-prepaid__title">{content.deviceCodeEntry.deviceCodeBlock.title}</h2>
                <p className="merchant-prepaid__subtitle" dangerouslySetInnerHTML={{__html: content.deviceCodeEntry.deviceCodeBlock.text}}/>
            </section>

            <form onSubmit={handleSubmit(submitCode)} className="form merchant-prepaid__form">
                <div className="merchant-prepaid__form-row merchant-prepaid__code-entry--sm-top-pad merchant-prepaid__white-bg">
                    <div className="merchant-prepaid__form-row--full">
                        {deviceCodeLabel}
                        
                        <input id="devicecode" name="devicecode" type="number" autoComplete="nope" inputMode="numeric"
                            onChange={e => setCode(e.target.value)}
                            ref={register({
                                required: content.dictionary.formLabels.fieldIsRequired,
                                pattern: {
                                    value: /^[0-9]{6}?$/,
                                    message: content.deviceCodeEntry.deviceCodeError
                                }
                            })}
                        />
                        {errors.devicecode && <p className="validation-error">{errors.devicecode.message}</p>}
                    </div>
                </div>
                <div className="merchant-prepaid__code-entry">
                        <label htmlFor="mobileNumber">{content.dictionary.formLabels.confirmMobileNumber}</label>
                        <input id="mobileNumber" name="mobileNumber" type="text" autoComplete="nope" inputMode="numeric"
                            onChange={e => setPhone(e.target.value)}
                            ref={register({
                                required: content.dictionary.formLabels.fieldIsRequired,
                                pattern: {
                                    value: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/,
                                    message: content.dictionary.formLabels.pleaseEnterAValidPhoneNumber
                                }
                            })}
                        />
                        {errors.mobileNumber && <p className="validation-error">{errors.mobileNumber.message}</p>}
                </div>
                {/*
                <div className="merchant-prepaid__form-row">
                    <label htmlFor="consentCheck" className="merchant-prepaid__checkbox">
                        <input id="consentCheck" name="consentCheck" type="checkbox"
                            onChange={e => consentToggle(e.target.checked)}
                            ref={register({ required: content.deviceCodeEntry.confirmCheckboxError })}
                        />
                        <span className="merchant-prepaid__checkbox--select"></span>
                        {content.deviceCodeEntry.confirmCheckboxText}
                    </label>
                    {errors.consentCheck && <p className="validation-error">{errors.consentCheck.message}</p>}
                </div>
                */}
                <div className="merchant-prepaid__button-wrap merchant-prepaid__button-wrap--small-top">
                    <button className="button" type="submit" disabled={isDisabled()} data-tracking={digitalDataButtonClick('device-code-entry', content.deviceCodeEntry.buttonText)}>{content.deviceCodeEntry.buttonText}</button>
                </div>
            </form>

        </div>
    )
}

export default DeviceCode