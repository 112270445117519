/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { generate, parse } from "build-number-generator";

declare global {
  interface Window {
    digitalData: any;
  }
}

export const pageStart = () => {
  document.dispatchEvent(
    new CustomEvent("c-tracking-log-page-start", { bubbles: true })
  );
};

export const pageComplete = () => {
  document.dispatchEvent(
    new CustomEvent("c-tracking-log-page", { bubbles: true })
  );
};

export const getLanguage = () => {
  return window.localStorage.getItem("language")
    ? window.localStorage.getItem("language")
    : window.location.pathname.includes("/es/")
    ? "es"
    : "en";
};

export const getSubCategories = () => {
  const url = window.location.href;
  const pathname = window.location.pathname;
  const isDashboard =
    (url.includes("my.xfinityprepaid.com") ||
      url.includes("my-stg.xfinityprepaid.com") ||
      url.includes("my-qa.xfinityprepaid.com") ||
      url.includes("localhost:3000/")) &&
    !(url.includes("/buy/") || url.includes("/litepay/"));
  const pathnameSplitFiltered = pathname
    .split("/")
    .filter((i) => i !== "" && i !== "en" && i !== "es");
  const subCategory1 = isDashboard ? "account" : "";
  const subCategory2 = pathnameSplitFiltered[1]
    ? pathnameSplitFiltered[0]
    : null;
    let categories:string[] = [];
    if(subCategory1.length){
       categories = [subCategory1];
    }
  if (subCategory2) {
    categories.push(subCategory2);
  }
  return categories;
};

export const getScreenName = () => {
  let screenName = "";
  const url = window.location.href;
  const pathname = window.location.pathname;
  const pathnameSplit = pathname.split("/");
  const pathnameSplitFiltered = pathnameSplit.filter(
    (i) => i !== "" && i !== "en" && i !== "es"
  );

  if (
    url === "https://www.xfinityprepaid.com/" ||
    url === "https://www-stg.xfinityprepaid.com/" ||
    url === "https://www-qa.xfinityprepaid.com/" ||
    url === "http://localhost:8080/"
  ) {
    screenName = "home";
  } else if (
    url === "https://my.xfinityprepaid.com/" ||
    url === "https://my-stg.xfinityprepaid.com/" ||
    url === "https://my-qa.xfinityprepaid.com/" ||
    url === "http://localhost:3000/"
  ) {
    screenName = "warm-transfer";
  } else if (pathnameSplitFiltered[1]) {
    screenName = pathnameSplitFiltered[1];
  } else if (pathnameSplitFiltered[0]) {
    screenName = pathnameSplitFiltered[0];
  }

  return screenName;
};

export const getReleaseVersion = () => {
  const date = generate();
  const parsedDate = parse(date);
  return window.buildDate || parsedDate.toString();
};

export const getZipCode = () => {
  const xppData = window.sessionStorage.getItem("xppdata");
  const localStorageZip = window.localStorage.getItem("zipCode");
  return xppData
    ? JSON.parse(xppData).zip
    : localStorageZip
    ? localStorageZip
    : "";
};

// Button Click
export const digitalDataButtonClick = (
  name: string,
  text: string,
  isModal?: boolean,
  componentID?: string,
  fireEvent?: boolean,
  selector?: string
) => {
  const eventName = "button-click";
  let obj = {
    eventMethod: "send-Event",
    eventAction: text,
    eventName: eventName,
    linkMeta: {
      clickType: eventName,
      placementContext: name + "-button"
    },
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  if (fireEvent && selector) {
    document.querySelector(selector)?.dispatchEvent(
      new CustomEvent("c-tracking-log-dom", {
        detail: {
          ...obj,
        },
        composed: true,
        bubbles: true,
      })
    );
  } else {
    return JSON.stringify(obj);
  }
};

// Link Click
export const digitalDataLinkClick = (
  name: string,
  href: string,
  text: string,
  isModal?: boolean,
  componentID?: string
) => {
  const eventName = "a click";
  let obj = {
    eventMethod: "send-Event",
    eventAction: text,
    eventName: eventName,
    linkMeta: {
      clickType: eventName,
      placementContext: name + "-link",
      destinationUrl: href,
    },
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  return JSON.stringify(obj);
};

// Image Click
export const digitalDataImageClick = (
  name: string,
  href: string,
  text: string,
  isModal?: boolean,
  componentID?: string
) => {
  const eventName = "image-click";
  let obj = {
    eventMethod: "send-Event",
    eventAction: text,
    eventName: eventName,
    linkMeta: {
      clickType: eventName,
      placementContext: name + "-image",
      destinationUrl: href,
    },
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  return JSON.stringify(obj);
};

// Navigation Click
export const digitalDataCarouselNavigationClick = (
  name: string,
  action: string,
  selector: string,
  isModal?: boolean,
  componentID?: string
) => {
  const eventName = "carousel-click";
  let obj = {
    eventMethod: "send-Event",
    eventAction: action,
    eventName: eventName,
    linkMeta: {
      clickType: eventName,
      placementContext: name + "-carousel-navigation"
    },
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: true,
      bubbles: true,
    })
  );
};

// Accordion Click
export const digitalDataAccordionClick = (
  name: string,
  title: string,
  selector: string,
  isModal?: boolean,
  componentID?: string
) => {
  const eventName = "accordion-click";
  let obj = {
    eventMethod: "send-Event",
    eventAction: title,
    eventName: eventName,
    linkMeta: {
      clickType: eventName,
      placementContext: name + "-accordion"
    },
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: true,
      bubbles: true,
    })
  );
};

// Email Provided Input
export const digitalDataEmailProvided = (
  name: string,
  emailProvided: boolean,
  text: string,
  isModal?: boolean,
  componentID?: string
) => {
  const eventName = "button-click";
  let obj = {
    eventMethod: "send-Event",
    emailProvided: emailProvided,
    eventAction: text,
    eventName: eventName,
    linkMeta: {
      clickType: eventName,
      placementContext: name + "-email-provided"
    },
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  return JSON.stringify(obj);
};

// Modal Open
export const digitalDataModalOpen = (name: string, fireEvent?: boolean) => {
  const eventName = "opened";
  const obj = {
    eventMethod: "overlay-Track",
    eventName: eventName,
    modalName: name + "-modal",
  };

  if (fireEvent) {
    document.body?.dispatchEvent(
      new CustomEvent("c-tracking-log-dom", {
        detail: {
          ...obj,
        },
        composed: true,
        bubbles: true,
      })
    );
  } else {
    return JSON.stringify(obj);
  }
};

export const digitalDataModalScreenChange = (
  name: string,
  selector: string
) => {
  const eventName = "opened";
  const obj = {
    eventMethod: "overlay-Track",
    eventName: eventName,
    modalName: name + "-modal-screen",
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// Validation Error
export const digitalDataValidationError = (
  name: string,
  errorElements: any[],
  errorMessage: string,
  selector: string,
  isModal?: boolean
) => {
  const obj = {
    eventMethod: "send-Error",
    errorCode: errorElements.join(),
    errorMessage: errorMessage,
    errorType: "error-validation",
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// API Error
export const digitalDataAPIError = (
  name: string,
  errorCode: string,
  errorMessage: string,
  selector: string,
  isModal?: boolean
) => {
  const obj = {
    eventMethod: "send-Error",
    errorCode: errorCode,
    errorMessage: errorMessage,
    errorType: "error-api",
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// 404 Page Error
export const digitalData404PageError = (
  name: string,
  errorCode: string,
  errorMessage: string,
  selector: string,
  isModal?: boolean
) => {
  const obj = {
    eventMethod: "send-Error",
    errorCode: errorCode,
    errorMessage: errorMessage,
    errorType: "error-404page",
    pageName: "resi|sales|error||404",
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// Product View
export const digitalDataProductView = (
  offerIncluded: boolean,
  selector: string,
  productID: string
) => {
  const obj = {
    eventMethod: "product-View",
    product: [
      {
        productInfo: {
          offerIncluded: offerIncluded,
          productID: productID,
          productType: "prepaid-kit",
        },
      },
    ],
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// Add to Cart - New Customer Buy Flow/Standard Refill Flow/Move Within Refill Flow
export const digitalDataAddToCart = (
  name: string,
  text: string,
  selector: string,
  offerIncluded: boolean,
  productID: string,
  componentID?: string
) => {
  const eventName = "button-click";
  let obj = {
    eventMethod: "cart-Addition",
    eventAction: text,
    eventName: "button-click",
    linkMeta: {
      clickType: eventName,
      placementContext: name,
    },
    product: [
      {
        productInfo: {
          offerIncluded: offerIncluded,
          productID: productID,
          productType: "prepaid-kit",
        },
      },
    ],
  };

  if (componentID) {
    obj = Object.assign(obj, {
      component: [
        {
          componentInfo: {
            name,
            componentID,
          },
        },
      ],
    });
  }

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// Purchase
export const digitalDataPurchase = (
  offerIncluded: boolean,
  price: string,
  productID: string,
  productType: string,
  quantity: number,
  buyflowStep: string,
  buyflowType: string,
  transactionID: string,
  selector: string
) => {
  const obj = {
    attributes: {
      pageLoadEvent: "Purchase",
    },
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};

// Search Find A Retailer/Support
export const digitalDataSearch = (
  isFindARetailer: boolean,
  searchTerm: string,
  searchResultsNumber: number,
  selector: string
) => {
  const onsiteSearchTerm = isFindARetailer
    ? "find-a-retailer|" + searchTerm
    : "support|" + searchTerm;
  const obj = {
    eventMethod: "internalSearch-Track",
    eventName: "search tracking",
    page: {
      pageInfo: {
        onsiteSearchResultsNumber: searchResultsNumber,
        onsiteSearchTerm: onsiteSearchTerm,
      },
    },
  };

  document.querySelector(selector)?.dispatchEvent(
    new CustomEvent("c-tracking-log-dom", {
      detail: {
        ...obj,
      },
      composed: false,
      bubbles: true,
    })
  );
};
