import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BuyFlowLayout from '../../layouts/BuyFlowLayout'
import { useHistory  } from 'react-router'
import ProgressSpinner from '../../components/buyflow/ProgressSpinner'
import withBuyflow from '../../redux/connectors/buyflow'
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const isHeroCM = window.location.hostname?.indexOf('cm.herodigital.dev') !== -1
const isProd = ['my.xfinityprepaid.com'].includes(window.location.hostname) || ['my-stg.xfinityprepaid.com'].includes(window.location.hostname) 

const Session = (props) => {
    const {
        locale,
        updateProgress,
        checkoutContent,
        digitalSessionId,
        shouldRestoreSession,
        updateShouldRestoreSession,
        restoreDigitalSession,
        restoredDigitalSession,
        validatedServiceability,
        fetchedProductDetails,
        locationId,
        marketId,
        cart,
        createSession,
        updateDigitalSessionId,
        updateLocationId,
        updateMarketId,
        digitalSession,
        fetchTaxData,
        fetchedTaxData,
        updateLocale,
        dashboard
    } = props
    const [pageTitle, setPageTitle] = useState('Xfinity Prepaid')

    const history = useHistory()

    useEffect(() => {
        setPageTitle(checkoutContent?.checkoutLoadingScreenBeforeRedirect?.loadingScreenText)
    }, [checkoutContent])

    // page load
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const myParam = urlParams.get('digitalSessionId')
        if (myParam) {
            updateShouldRestoreSession(true)
            console.log('returning from CIMA with sessionID :', myParam)
            let lang = 'en'
            if (window.location.pathname.includes('/es/')) {
                lang = 'es'
            }
            updateLocale(lang)
            updateDigitalSessionId(myParam)
            restoreDigitalSession(myParam)
            const locationId = sessionStorage.getItem('locationId')
            const marketId = sessionStorage.getItem('marketId')
            console.log('locationId', locationId, 'marketId', marketId)
            updateLocationId(locationId)
            updateMarketId(marketId)

        } else {
            if (!shouldRestoreSession) {
                console.log('Creating new session...')
                sessionStorage.setItem('locationId', locationId)
                sessionStorage.setItem('marketId', marketId)
                sessionStorage.setItem('cart', JSON.stringify(cart))
                createSession()
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (validatedServiceability && fetchedProductDetails) {
            fetchTaxData()
        }
    }, [restoredDigitalSession, validatedServiceability, fetchedProductDetails]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

        // progress bar
        updateProgress({ title: checkoutContent?.checkoutLoadingScreenBeforeRedirect?.loadingScreenText, progress: 40 })
        if (checkoutContent && shouldRestoreSession) {
            updateProgress({ title: checkoutContent?.checkoutLoadingScreenAfterRedirect?.loadingScreenText, progress: 50 })
        }
    }, [checkoutContent, shouldRestoreSession]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (digitalSessionId && !shouldRestoreSession) {
            if (isHeroCM || isProd) {
                window.location.href = 'https://digital.xfinity.com/router?digitalSessionId=' + digitalSessionId.toString()
            } else {
                window.location.href = 'https://digital-stage.xfinity.com/router?digitalSessionId=' + digitalSessionId.toString()
            }
        } else return
    }, [digitalSessionId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log('digitalSession: ', digitalSession)
        if (digitalSession && digitalSession.contact && validatedServiceability && fetchedTaxData) {
            continueOrder()
        }
    }, [digitalSession, fetchedTaxData]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const continueOrder = () => {
        let ok = true
        ok = ok && !!digitalSession.contact.phoneNumber
        ok = ok && !!digitalSession.contact.email
        ok = ok && !!digitalSession.name.firstName
        ok = ok && !!digitalSession.name.lastName
      // XXX Is this really needed? If custGuid is missing and everything else is present,
      // there isn't going to be any editable field on the customer info page.
        ok = ok && !!digitalSession.identity.custGuid

        if (ok) {
            sessionStorage.setItem("isNewCustomer",JSON.stringify(true))
            sessionStorage.removeItem("errorReloadCount");
            history?.push('/' + locale + '/buy/order-details')
        } else {
            // this.$store.commit('buy/showNotification', 'Could not create Xfinity ID. Please complete details below and proceed.')
            sessionStorage.setItem("isNewCustomer",JSON.stringify(false))
            sessionStorage.removeItem("errorReloadCount");
            history?.push('/' + locale + '/buy/customer-info')
        }
    }

    return (
        <BuyFlowLayout locale={locale}>
            <TrackUserData data={dashboard} />
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <ProgressSpinner />
            <TrackPageComplete />
        </BuyFlowLayout>
    )
}

export default withBuyflow(Session)
