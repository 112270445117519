import React, { useState, useEffect } from 'react'
import withDashboard from '../redux/connectors/dashboard'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '../utils/utils'
import { digitalDataButtonClick } from '../utils/adobe-analytics'

const AddressList = (props) => {
  const {
    dashboardContent,
    openAccounts,
    closedAccounts,
    custGuid,
    sessionId,
    currentLocale,
    updateAccountId,
    fetchDashboard,
    updateField,
    isTestFile,
  } = props

    const [selectedAccount, setSelectedAccount] = useState('')

    const history = useHistory()

    useEffect(() => {
        trackEvent({
            event: 'login',
            loginPage: 'account picker',
            loginEvent: 'number of accounts',
            loginDetail: openAccounts?.length + closedAccounts?.length
        })
    },[]) // eslint-disable-line

    const goToSelectedAccount = () => {
        updateAccountId(selectedAccount)
        updateField({field: 'ready', value: false})
        fetchDashboard({accountId: selectedAccount})

        const sd = sessionStorage.getItem('xpp_acct')
        const sessionData = sd ? JSON.parse(sd) : {}

        sessionStorage.setItem('xpp_acct', JSON.stringify({
            ...sessionData,
            accountId: selectedAccount,
            custGuid: custGuid,
            sessionId: sessionId,
        }))

        trackEvent({
            event: 'login',
            loginPage: 'account picker',
            loginEvent: 'main cta',
            loginDetail: dashboardContent?.accountPicker?.accountPicker?.continue?.text
        })

        // history.push(`/${currentLocale}/dashboard?accountId=${encodeURIComponent(btoa(selectedAccount))}&guid=${encodeURIComponent(btoa(custGuid))}`)
        let dashboardUrl = `/${currentLocale}/dashboard`
        const params:string[] = []
        if (sessionData.bhr) {
            params.push('bhr=true')
        }
        if (sessionData.mobile) {
            params.push('mobile=true')
        }
        if (params.length) {
            dashboardUrl += `?${params.join('&')}`
        }

        history.push(dashboardUrl)
    }

    const renderItems = (accounts) => {
        const renderedAddresses = accounts.map((account, index) => {
            // const id = index + 1 + baseIndex
            // const idName = 'address' + id

            const selectAccount = (e) => {
                setSelectedAccount(e.target.value)
            }
            return (
                <div className="address-list__item radio" key={account.accountNumber}>
                    <input type="radio" className="radio__input" name="address" id={`account-${account.accountNumber}`} value={account.accountNumber} onChange={selectAccount} />
                    <label className="radio__label" htmlFor={`account-${account.accountNumber}`}>
                        {/*{item.address1}
                        <span>{item.address2}</span>*/}
                        {account.idcmtServiceAddress}
                        <span>{account.idcmtServiceCity}, {account.idcmtServiceState} {account.idcmtServicePostalCode}</span>
                    </label>
                </div>
            )
        })
        return (
            <div>
                { renderedAddresses }
            </div>
        )
    }

    return (
        <div className="address-list">
            { openAccounts?.length > 0 ?
                <div>
                    <div className="form-field__title">{dashboardContent?.accountPicker?.accountPicker?.yourCurrentAccount}</div>
                    <fieldset>
                        {renderItems(openAccounts)}
                    </fieldset>
                </div>
            : null}
            { closedAccounts?.length > 0 ?
                <div>
                    <div className="form-field__title">{dashboardContent?.accountPicker?.accountPicker?.yourPastAccounts}</div>
                    <fieldset>
                        {renderItems(closedAccounts)}
                    </fieldset>
                </div>
            : null }
            {openAccounts?.length > 0 || closedAccounts?.length > 0 ? (
                !isTestFile ? (
                    <button
                        className="button continue"
                        disabled={!selectedAccount}
                        onClick={goToSelectedAccount}
                        data-tracking={digitalDataButtonClick('continue', dashboardContent?.accountPicker?.accountPicker?.continue?.text)}
                    >
                        {dashboardContent?.accountPicker?.accountPicker?.continue?.text}
                    </button>
                ) : (
                    <button
                        data-testid="account-01"
                        className="button continue"
                        disabled={false}
                        onClick={goToSelectedAccount}
                        data-tracking={digitalDataButtonClick('continue', dashboardContent?.accountPicker?.accountPicker?.continue?.text)}
                    >
                        {dashboardContent?.accountPicker?.accountPicker?.continue?.text}
                    </button>
                )
            ) : null}
        </div>
    );
};

export default withDashboard(AddressList)
