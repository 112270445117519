import { useEffect, useState } from "react";
import { digitalDataValidationError } from "../utils/adobe-analytics";

const Payment = (props) => {
  const {
    addressLine1,
    addressLine2,
    city,
    zip,
    UsState,
    trackingId,
    custGuid,
    firstName,
    lastName,
    processNext,
    paymentProcessing,
    paymentProcessed,
    updateFormValid,
    showAddress,
    loadCPCForm,
    onUpdateField,
    sourceSystemId,
    channelName,
    enableFraudManager,
    cpcMessageDisplayLocation,
    displayAddressOverride,
    displayAutoPayEnroll,
    displayStoredPaymentOption,
    customerClass,
    paymentToken, 
    isSubmitPayment,
    storePaymentInstrumentLongTerm,
    cpcPageTypeVal,
    paymentError,
    paymentLoaded,
  } = props;
  const pageType = cpcPageTypeVal; //differs for existing wallet
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    loadCPCForm && init();
    return () => {
      window.removeEventListener("message", attachEventListner);
    };
  }, [loadCPCForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processNext) {
      console.log("payment should be processing");
      onContinuePayment();
    } else {
      console.log("processNext is false");
    }
  }, [processNext]); // eslint-disable-line react-hooks/exhaustive-deps

  let channelData: any = Object.assign({});

  useEffect(() => {
    return () => {
      window.removeEventListener("message", attachEventListner);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  };

  const storeChannelInfo = (channelInfo) => {
    state.channelInfo = channelInfo;
  };

  const updateField = (data) => {
    onUpdateField(data);
  };

  let currentState = {
    currentPaymentType: cpcPageTypeVal,
    channelInfo: {},
    isEdit: false,
    storeChannelInfo: storeChannelInfo,
  };

  let state = useState(currentState)[0];

  const onContinuePayment = () => {
    prepareChannelData();

    var message = {
      channel: "jump-iframe",
      action: "CPC_FORM_SUBMIT",
      cpcPageType: pageType,
      channelData: channelData,
    };

    document
      ?.querySelector("jump-web-component")
      ?.shadowRoot?.querySelector("iframe")
      ?.contentWindow?.postMessage(JSON.stringify(message), "*");
  };

  const attachEventListner = (message) => {
    if (
      message &&
      message.data &&
      typeof message.data === "string" &&
      message.data.indexOf('"action":') >= 0
    ) {
      let data = JSON.parse(message.data);
      updateField(data);
      paymentLoaded(true);
      switch (data.action) {
        case "CPC_FORM_SUBMIT_RESPONSE":
          let result = data.cpcData.submissionDetails;
          console.log("CPC_FORM_SUBMIT_RESPONSE data", data);
          if (result.cpcStatus.toLowerCase() === "success") {
            setIsDisabled(true);
            updateFormValid(false);
            let message: any = {};
            message.action = "JUMP-RESPONSE-RECEIVED";
            message.data = data;
            window.postMessage(JSON.stringify(message), "*");
            paymentProcessed(data);
            // storeChannelInfo(data);
          } else {
            setIsDisabled(false);
            updateFormValid(false);
            paymentProcessing(false);
            paymentLoaded(false);
          }
          break;
        case "CPC_CONFIG_READY":
          if(currentState.currentPaymentType==="CardOnlyWithEdit"){
            data.config.cpcPageType ="CardOnlyWithEdit";
          }
          data.channelData = channelData;
          console.log("CPC_CONFIG_READY ChannelData", data);
          data.action = "CPC_CONFIG_SUBMIT";
          document
            ?.querySelector("jump-web-component")
            ?.shadowRoot?.querySelector("iframe")
            ?.contentWindow?.postMessage(JSON.stringify(data), "*");
          paymentLoaded(false);
          break;
        case "CPC_FORM_PROCESSING":
          console.log("channel app - CPC_FORM_PROCESSING", data);
          if (data && data.callInProgress) {
            setIsDisabled(true);
            updateFormValid(true);
            paymentProcessing(true);
            paymentLoaded(false);
          }
          break;
        case "CPC_ERROR":
          //error handling
          console.log("channel app - CPC_ERROR", data);
          setIsDisabled(false);
          updateFormValid(isDisabled);
          paymentProcessing(false);
          paymentLoaded(false);
          if (data.isFormValid) {
            setIsDisabled(false);
            updateFormValid(data.isFormValid);
          } else {
            if(data.type !== "form"){
              digitalDataValidationError('payment', ['any'], 'payment information invalid', 'body');
            }
            setIsDisabled(true);
            updateFormValid(data.isFormValid);
            paymentError(data);
          }
          break;
        case "CPC_PAGE_RESIZE":
          paymentLoaded(false);
          break;
        case "CPC_INFO_EVENT":
          paymentLoaded(false);
          break;
        default:
          break;
      }
    }
  };

  function init() {
    window.addEventListener("message", attachEventListner);
    prepareChannelData();
  }

  function prepareChannelData() {
    channelData = {
      channelDetails: {
        sourceServerId: window.location.hostname,
        sourceSystemId: sourceSystemId,
        timestamp: new Date().toISOString(),
        trackingId: trackingId,
        partnerId: "Comcast",
        sessionId: custGuid,
        channelName: channelName,
        enableFraudManager: enableFraudManager,
        cpcMessageDisplayLocation: cpcMessageDisplayLocation,
        customerClass: customerClass,
      },
      config: {
        displayAddressOverride: displayAddressOverride,
        displayAutoPayEnroll: displayAutoPayEnroll,
        displayStoredPaymentOption: displayStoredPaymentOption,
        disableCardChange:true,
        termsAndConditionsDisplayOption:"modal"
      },
      customerDetails: {
        cimaUserToken: getCookie("CIMA-Token") || process.env.REACT_APP_CIMA_USER_TOKEN,
        walletId: custGuid,
        paymentToken: paymentToken,
        firstName: firstName,
        lastName: lastName,
        displayAddress: showAddress,
        address: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: UsState,
        zip: zip,
        storePaymentInstrumentLongTerm: storePaymentInstrumentLongTerm,
        defaultAddress: true,
        addressList: [
          {
            addressLabel: "",
            defaultAddress: true,
            address: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: UsState,
            zip: zip,
          },
        ],
      },
      isSubmitPayment: isSubmitPayment,
    };

    if (channelData.channelDetails.channelName === "PREPAID_LITE") {
       delete channelData.customerDetails["cimaUserToken"];
    }
    if(cpcPageTypeVal==="CardOnly"){
      delete channelData.customerDetails["paymentToken"];
    }
    if (
      channelData.customerDetails.paymentToken === "" ||
      channelData.customerDetails.paymentToken === undefined
    ) {
      delete channelData.customerDetails["addressList"];
    }
  }

  return null;
};

export default Payment;
