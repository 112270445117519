// import DashboardLayout from '../../layouts/DashboardLayout'
import AddressList from '../../components/AddressList'
import { useEffect, useState } from 'react'
import ProgressSpinner from '../../components/ProgressSpinner'
import TimeoutModal from '../../components/TimeoutModal'
import withDashboard from '../../redux/connectors/dashboard'
import Header from '../../components/dashboard/Header'
import Footer from '../../components/dashboard/Footer'
import { Helmet } from 'react-helmet'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

// 60s in a min x 12.5 mins x 1000 (to convert to milliseconds)
const sessionTimeout = 60 * 12.5 * 1000;
let sessionTimer;

const sd = sessionStorage.getItem('xpp_acct')
const sessionData = sd ? JSON.parse(sd) : {}

const AccountPicker = (props) => {
    const {
        dashboardContent,
        pageClass,
        dictionaryContent,
        fetchedEnglishAllContent,
        fetchingEnglishAllContent,
        fetchEnglishAllContent,
        fetchedSpanishAllContent,
        fetchingSpanishAllContent,
        fetchSpanishAllContent,
        identityAccounts,
        fetchedIdentity,
        fetchingIdentity,
        fetchIdentity,
        custGuid,
        updateCustGuid,
        fetchedToken,
        fetchToken,
        generateTrackingId,
        history,
        locale,
        updateAccountId,
        updateField,
        fetchDashboard,
        sessionId,
        updateSessionId,
        lastActionAt,
        sessionError,
        dashboard
    } = props

    const [ready, setReady] = useState(false)
    const [accountsProcessed, setAccountsProcessed] = useState(false)
    const [openAccounts, setOpenAccounts] = useState<Array<any>>([])
    const [closedAccounts, setClosedAccounts] = useState<Array<any>>([])

    const [showTimeoutModal, setShowTimeoutModal] = useState(false)

    useEffect(() => {
        // console.log('lastActionAt update', lastActionAt)
        if (sessionTimer) {
            // console.log('Resetting session timer...')
            clearTimeout(sessionTimer)
            sessionTimer = null
            setShowTimeoutModal(false)
        }
        sessionTimer = setTimeout(() => {
            setShowTimeoutModal(true)
        }, sessionTimeout)
    }, [lastActionAt])

    useEffect(() => {
        if (sessionError) {
            if (sessionError.expired) {
                history?.replace(`/${locale}/timeout-error`)
            } else {
                history?.replace(`/${locale}/error`)
            }
        }
    }, [sessionError]) // eslint-disable-line

    useEffect(() => {
        props.updatePageClass('account-picker')

        if (!custGuid) {
            let ssAccountData = sessionStorage.getItem('xpp_acct')
            if (ssAccountData) {
                console.log('found ssAccountData in sessionStorage', ssAccountData)
                let data = JSON.parse(ssAccountData)
                if (data.custGuid) {
                    updateCustGuid(data.custGuid)
                    updateSessionId(data.sessionId)
                } else {
                    console.error('No custGuid in store, cannot show account picker')
                    history?.replace(`/${locale}/error`)
                }
            }
        }

        if (!fetchedToken) {
            generateTrackingId()
            fetchToken()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (fetchedToken) {
            if (!dictionaryContent.loaded) {
                if (!(fetchedEnglishAllContent || fetchingEnglishAllContent)) {
                    fetchEnglishAllContent()
                }
                if (!(fetchedSpanishAllContent || fetchingSpanishAllContent)) {
                    fetchSpanishAllContent()
                }
            }
        }
    }, [fetchedToken]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log('fetchedToken', fetchedToken, 'custGuid', custGuid)
        if (fetchedToken && custGuid) {
            if (!fetchedIdentity && !fetchingIdentity) {
                console.log('fetchIdentity custGuid', custGuid)
                fetchIdentity({custGuid})
            }
        }
    }, [fetchedToken, custGuid]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (fetchedIdentity) {
            let open:Array<any> = []
            let closed:Array<any> = []
            let all:Array<any> = []

            identityAccounts.forEach(account => {
                if (account.presentInPrepaidDB) {
                    all.push(account)
                    if (account.prepaidStatus === 'Open') {
                        open.push(account)
                    } else if (account.prepaidStatus === 'Closed') {
                        closed.push(account)
                    }
                }
            })

            if (all.length === 1) {
                let selectedAccount = all[0]
                updateAccountId(selectedAccount.accountNumber)
                updateField({field: 'ready', value: false})
                fetchDashboard({accountId: selectedAccount.accountNumber})

                sessionStorage.setItem('xpp_acct', JSON.stringify({
                    ...sessionData,
                    accountId: selectedAccount,
                    custGuid: custGuid,
                    sessionId: sessionId
                }))

                // history.push(`/${currentLocale}/dashboard?accountId=${encodeURIComponent(btoa(selectedAccount))}&guid=${encodeURIComponent(btoa(custGuid))}`)
                let dashboardUrl = `/${locale}/dashboard`
                const params:string[] = []
                if (sessionData.bhr) {
                    params.push('bhr=true')
                }
                if (sessionData.mobile) {
                    params.push('mobile=true')
                }
                if (params.length) {
                    dashboardUrl += `?${params.join('&')}`
                }

                history?.push(dashboardUrl)

            } else {
                setOpenAccounts(open)
                setClosedAccounts(closed)
                setAccountsProcessed(true)
            }
        }
    }, [fetchedIdentity, identityAccounts]) // eslint-disable-line

    useEffect(() => {
        if (fetchedIdentity && fetchedEnglishAllContent && fetchedSpanishAllContent && accountsProcessed) {
            setReady(true)
        }
    }, [fetchedIdentity, fetchedEnglishAllContent, fetchedSpanishAllContent, accountsProcessed])

    // let items = {
    //     current: [
    //         {
    //             address1: '222 Test street',
    //             address2: 'Test 123, Philadelphia, PA, 19103'
    //         }
    //     ],
    //     previous: [
    //         {
    //             address1: '1701 John F Kennedy Blvd',
    //             address2: 'FL 10, Philadelphia, PA, 19103'
    //         },
    //         {
    //             address1: '3428 Beach Road',
    //             address2: 'FL 10, Philadelphia, PA, 19103'
    //         }
    //     ]
    // }

    const trackClick = (e) => {
        if (e.target.tagName === 'A') {
            trackEvent({
                event: 'login',
                loginPage: 'account picker',
                loginEvent: 'main cta',
                loginDetail: 'manage your account here'
            })
        }
    }

    if (dashboardContent.loaded && ready) {
        return (
            <div className={`prepaid-wrapper ${pageClass}`}>
                <TrackUserData data={dashboard} />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Account Picker - XfinityPrepaid</title>
                    <link rel="icon" href="/favicon.ico" />
                </Helmet>

                <Header />

                <main>
                    <div id="main" role="main" className="main">
                        <div className="main__width-constraint">
                            <h1 className="page__title">
                                {dashboardContent?.accountPicker?.accountPicker?.title}
                            </h1>

                            <div className="account-picker-subtitle" >
                                {dashboardContent?.accountPicker?.accountPicker?.pleaseSelectFromTheListOfAddressesBelow}
                            </div>

                            <AddressList openAccounts={openAccounts} closedAccounts={closedAccounts} />
                            <p onClick={(e) => {trackClick(e)}} dangerouslySetInnerHTML={{__html: dashboardContent?.accountPicker?.accountPicker?.ifTheAddressIsNotListedAbove}} data-tracking={digitalDataButtonClick('address-not-listed', 'If the address is not listed above')} />
                        </div>

                        {<TimeoutModal isOpen={showTimeoutModal} />}
                    </div>
                </main>
                <Footer />
                <TrackPageComplete />
            </div>
        )
    }
    else return (
        <div id="main" className="main" role="main">
            <div className="main__width-constraint">
                <ProgressSpinner loadingText="Loading..." />
            </div>
        </div>
    )
}

export default withDashboard(AccountPicker)