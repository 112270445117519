import React, { useEffect, useState } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import { digitalDataButtonClick } from '../../utils/adobe-analytics'


const Calendar = (props) => {
    const {
        hsdService,
        moveContent
    } = props
    const [calendarWeeks, setCalendarWeeks] = useState([[{date: '', day: 1, month: 1, year: 1, available: true, inRange: false, class: ''}]])
    const [selection, setSelection] = useState('')
    useEffect(() => {
        const getCalendarWeeksForUpcomingDays = (days = 30) => {
            const today = new Date()
            today.setHours(0)
            today.setMinutes(0)
            today.setSeconds(0)
            today.setMilliseconds(0)
            //console.log('today', today)
            const dayOfTheWeek = today.getDay() // 0 = Sunday, 1 = Monday, etc.
            const dayDurationInMilliseconds = 86400 * 1000
            const startOfFirstWeek = new Date(today.getTime() - (dayDurationInMilliseconds * dayOfTheWeek) - (dayDurationInMilliseconds * 14))
            //console.log('startOfFirstWeek', startOfFirstWeek)
        
            const lastDay = new Date(today.getTime() + (dayDurationInMilliseconds * days))
            //console.log(`lastDay (${days} days later)`, lastDay)
            const endOfLastWeek = new Date(lastDay.getTime() + ((6 - lastDay.getDay()) * dayDurationInMilliseconds))
            endOfLastWeek.setHours(23)
            endOfLastWeek.setMinutes(59)
            endOfLastWeek.setSeconds(59)
        
            //console.log('endOfLastWeek', endOfLastWeek)
        
            const duration = endOfLastWeek.getTime() - startOfFirstWeek.getTime()
            //console.log('duration', duration)
            const weeksToShow = Math.ceil(duration / (7 * dayDurationInMilliseconds))
        
            //console.log('weeksToShow', weeksToShow)
        
            let weeks: any
            weeks = []
        
            let day = 0
            let previousDay = new Date (startOfFirstWeek.getTime() - dayDurationInMilliseconds)
        
            for (let i = 1; i <= weeksToShow; i++) {
                let week: any
                week = []
                for (let j = 1; j <= 7; j++) {
                    const ts = startOfFirstWeek.getTime() + (day * dayDurationInMilliseconds)
                    let date = new Date(ts)
                    if (date.getDate() === previousDay.getDate()) {
                        const daylightSavingsAdjustment = startOfFirstWeek.getTime() + (day * dayDurationInMilliseconds) + (60 * 60 * 1000)
                        date = new Date(daylightSavingsAdjustment)
                    }
            
                    let available = true
                    let calendarClass = ''
            
                    // available within next 30 days
                    available = available && (date >= today)
                    if (date.getTime() === today.getTime()) {
                        calendarClass = 'today'
                    }
                    let potentialDays = 0
                    if (hsdService?.daysRemaining >= 30) {
                        potentialDays = 30
                    } else potentialDays = hsdService?.daysRemaining
                    available = available && date <= new Date(today.getTime() + (potentialDays * dayDurationInMilliseconds))
                    let inRange = date >= today && date <= new Date(today.getTime() + (30 * dayDurationInMilliseconds))
            
                    // except the 2nd day
                    const startOfSecondDay = today.getTime() + dayDurationInMilliseconds
                    const startOfThirdDay = today.getTime() + (2 * dayDurationInMilliseconds)
                    if (ts >= startOfSecondDay && ts < startOfThirdDay) {
                        available = false
                        inRange = false
                    }
                    const startOfLastDay = today.getTime() + (potentialDays * dayDurationInMilliseconds)
                    if (date.getTime() === startOfThirdDay) {
                        calendarClass = 'first-range'
                    }
                    if (date.getTime() === startOfLastDay) {
                        calendarClass = 'last-range'
                    }
                    if (inRange) {
                        calendarClass += ' in-range'
                    }
            
                    week.push({
                        date,
                        day: date.getDate(),
                        month: date.getMonth() + 1,
                        year: date.getFullYear(),
                        available,
                        inRange,
                        class: calendarClass
                    })
                    previousDay = date
                    day += 1
                }
                weeks.push(week)
            }
        
            return weeks
        }
        
        
        const weeks = getCalendarWeeksForUpcomingDays(45)
        setCalendarWeeks(weeks)
    }, [hsdService])

    const selectDate = (day) => {
        setSelection(day.date.toString())
    }

    const renderedWeeks = calendarWeeks.map((item, index) => {
        if (Array.isArray(item) && item !== null) {
            const renderedDays = item.map((day, dayIndex) => {
                return (
                    <td key={dayIndex} className={`${day.available ? 'available' : ''} ${day.class} ${selection === day.date.toString() ? 'selected' : ''}`}>
                        { day.inRange ? 
                            day.available ? 
                                <button onClick={(e) => { props.onSelectFunc(day); selectDate(day)}} data-tracking={digitalDataButtonClick('dav-available', day.day.toString())}>{day.day}</button>
                                :
                                <button data-testid="btn-onSelectFunc" onClick={(e) => {props.onSelectFunc(day); selectDate(day)}} data-tracking={digitalDataButtonClick('additional-refill-needed', moveContent?.moveScheduled?.anAdditionalPrepaidInternetRefillIsNeeded)}>
                                    {day.day}
                                    <div className={`toolTip ${dayIndex < 3 ? 'left' : ''} ${dayIndex === 3 ? 'center' : ''} ${dayIndex > 3 ? 'right' : ''}`}>{moveContent?.moveScheduled?.anAdditionalPrepaidInternetRefillIsNeeded}</div>
                                </button> 
                    : <span>{day.day}</span>}
                        
                    </td>
                )
            })
        
            return (
                <tr key={index}>
                    {renderedDays}
                </tr>
            )
        } else return null
    })

    return (
        <div className="card calendar">
            <table className={`move-calendar ${selection !== '' ? 'selection' : ''}`}>
                <thead></thead>
                <tbody>
                <tr>
                    <th>SUN</th>
                    <th>MON</th>
                    <th>TUE</th>
                    <th>WED</th>
                    <th>THU</th>
                    <th>FRI</th>
                    <th>SAT</th>
                </tr>
                {renderedWeeks}
                </tbody>
            </table>
        </div>
    )
}

export default withDashboard(Calendar)