import { createSelector } from 'reselect'
import { dateObjectFromApiDate } from '../../utils/utils'
import { promoList } from './promos/valid-promos.json'

const getPromos = state => state.dashboard?.promos
const getCart = state => state.dashboard?.cart
const getACP = state => state.dashboard?.acpDetail

const selectors = {
    getHaveValidPromo: createSelector(
        [getPromos],
        (promos) => {
            /*
                TODO: Promos should be enabled should be enabled via a feature flag instead of 
                checking for valid promo.
            */
            let found = false
            if (promos?.discounts?.length) {
                
                for (var key in promoList) {
                    // eslint-disable-next-line
                    promoList[key].forEach( function(item) {
                        const validDiscountID = item
                        // for now we only use the first promo
                        const promo = promos.discounts[0]
                        // first let's make sure we have a valid ID and product code
                        if (promo.discId === validDiscountID) {
                            const now = Date.now()
                            // now check start date
                            const startDate = dateObjectFromApiDate(promo.discStartDate)
                            // make sure we are past the designated start date
                            if (startDate <= now) {
                                // new we check on end date
                                if (!promo.discEndDate || dateObjectFromApiDate(promo.discEndDate) <= now) {
                                    // finally, all conditions passed so assign this object to promoData
                                    found = true
                                }
                            }
                        }
                    })
                }
            }
            return found;
        }
    ),
    getIsWinbackPromo: createSelector(
        [getPromos],
        (promos) => {
            /*
                TODO:
                Valid winback promos should be returned if promos are enabled via getHaveValidPromo
                and a promo controlled by externalized content in S3. 
                validDiscount would then change to an method that returns Boolean
            */
            let isWinbackPromo = false
            if (promos?.discounts?.length) {
                promoList?.winback.forEach( function(item) {
                    const validDiscountID = item
                    // for now we only use the first promo
                    const promo = promos.discounts[0]
                    if (promo.discId === validDiscountID) {
                        isWinbackPromo = true
                    }
                })
            }
            return isWinbackPromo
        }
    ),
    getHavePromoInCart: createSelector(
        [getCart],
        (cart) => {
            if (cart?.promos) {
                // console.log('cart.promos.length', cart.promos.length, 'havePromoInCart', cart.promos.length > 0)
                return cart.promos.length > 0
            }
            else return false
        }
    ),
    getIsRefillConfirmationPage: createSelector(
        [],
        () => {
            if (window.location.href.includes('confirmation')) {
            return true
            } else {
            return false
            }
        }
    ),
    getHaveValidACP: createSelector(
        [getACP],
        (ACP) => {
            let valid = false
            if (ACP && ACP.acpStatus === 'Approved') {
                const benefitCode = ACP.acpBenefits[0]?.benefitCode
                if (benefitCode) {
                    valid = true
                }
            }
            
            return valid
        }
    ),
    getIsTribalACP: createSelector(
        [getACP],
        (ACP) => {
            let isTribal = false
            if (ACP && ACP.customerType === 'Tribal') {
                isTribal = true
            }
            return isTribal
        }
    )
}

export default selectors
