import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import Refill from './Refill'
import withDashboard from '../../redux/connectors/dashboard'
import { trackEvent } from '../../utils/utils'
import { digitalDataButtonClick, digitalDataAccordionClick } from '../../utils/adobe-analytics'

const ReconnectAccordion = (props) => {
    const {
        reconnectContent,
        currentLocale,
        custGuid,
        behindTheModem,
        inApp,
        auxAccount,
        serviceabilityData
    } = props

    const [items, setItems] = useState<any[]>([])
    const [activeIndex, setActiveIndex] = useState(-1)
    const [firstTime, setFirstTime] = useState(true)

    const {
        dashboard
    } = props

    useEffect(() => {
        if (!reconnectContent.loaded) {
            return
        }

        if (firstTime) {
            setItems([
                {
                    title: reconnectContent.step1.internetDeviceLocated,
                    content: {
                        stepLabel: {
                            text: reconnectContent.step3.xfinityIDCreated,
                            cssClass: ''
                        }
                    },
                    id: 1,
                    type: 'account-id',
                    index: 0,
                    status: 'complete'
                },
                {
                    title: reconnectContent.step1.refillPrepaidInternet,
                    content: {
                        stepLabel: {
                            text: reconnectContent.step3.xfinityIDCreated,
                            cssClass: ''
                        }
                    },
                    id: 2,
                    readyForActivation: true,
                    type: 'refill',
                    index: 1,
                    status: 'incomplete'
                },
                {
                    title: reconnectContent.step1.reactivateYourAccount,
                    content: {
                        stepLabel: {
                            text: reconnectContent.step3.activatePrepaidService,
                            cssClass: ''
                        },
                        stepDescription: '',
                        stepDescriptionAfterMacAddressInput: reconnectContent.step3.itLooksLikeYoureGoodToGo,
                        buttonText: reconnectContent.step3.completeActivation
                    },
                    id: 3,
                    type: 'activation',
                    index: 2,
                    status: 'incomplete'
                }
            ])
        }
    }, [reconnectContent.loaded]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (items.length && firstTime) {
            setFirstTime(false)

            setTimeout(() => {
                let newItems = [...items]
                console.log('newItems', newItems)
                newItems[0].status = 'complete'
                setItems(newItems)
            }, 500)
            setTimeout(() => {
                setActiveIndex(1)
            }, 1000)
        }
    }, [items]) // eslint-disable-line

    const renderAccordionIcon = (item, index) => {
        const itemNumber = index + 1
        if (item.status && item.status === 'complete') {
            return(
                <img src="/site-assets/img/icon-check-circle.svg" className="icon check" alt="complete"></img>
            )
        } else {
            return(
                <span className="number">
                    { itemNumber }
                </span>
            )
        }
    }

    const renderAccordionContent = (item, index) => {
        switch(item.id){
            case 1:
            return (
                <div className="card card__address-status full-bleed">
                    <div className="card__address-status--content">
                        {dashboard?.serviceAddress?.addressLine1}
                        {dashboard?.serviceAddress?.addressLine2 !== '' ? <div>{dashboard?.serviceAddress?.addressLine2}</div> : null }
                        {dashboard?.serviceAddress?.city}, {dashboard?.serviceAddress?.state} {dashboard?.serviceAddress?.zip}
                    </div>
                </div>
            );

            case 2:
            return (
                <Refill
                    serviceabilityData={serviceabilityData}
                    onFinish={() => {
                        let newItems = [...items]
                        newItems[1].readyForActivation = true
                        newItems[1].status = 'complete'
                        setItems(newItems)
                        setActiveIndex(2)
                    }}
                />
            );

            case 3:
            return (
                <div className="card ">
                    <div>
                        <p>{reconnectContent.step3.everythingLooksGreat}</p><br></br>
                        <div className="button--container">
                            <button
                                onClick={() => {
                                    trackEvent({
                                        event: 'reconnect info',
                                        reconnectStep: 'step 4',
                                        reconnectStepName: 'reconnect review',
                                        reconnectEvent: 'next step cta',
                                        reconnectDetail: reconnectContent.step3.continueToReactivation.text
                                    })

                                    let activationUrl = `/${currentLocale}/activation?accountId=${btoa(auxAccount?.auxDetails[0]?.auxAccountId)}`
                                    activationUrl += `&guid=${btoa(custGuid)}`
                                    activationUrl += `&bhr=${behindTheModem ? 'true' : 'false'}`
                                    activationUrl += `&mobile=${inApp ? 'true' : 'false'}`

                                    console.log('activationUrl', activationUrl)
                                    window.location.href = activationUrl
                                }}
                                className="button"
                                data-tracking={digitalDataButtonClick('continue-to-reactivation', reconnectContent.step3.continueToReactivation.text)} 
                            >{reconnectContent.step3.continueToReactivation.text}</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderItems = () => {

        return items.map((item, index) => {
            const onTitleClick = (index) => {
                if (activeIndex === index) {
                    setActiveIndex(0)
                } else if (index === 2 && items[1].status !== 'complete') {
                    // do nothing if step 3 clicks and step 2 isn't completed
                } else {
                    setActiveIndex(index)
                }
            }

            const captureEnterKey = (event, index) => {
                if (event.keyCode === 13 || event.keyCode === 32) {
                    onTitleClick(index)
                }
            }

            return (
                <React.Fragment key={item.id}>
                    <div className="accordion__item">
                        <div
                            id="01-tab"
                            tabIndex={0}
                            role="tab"
                            aria-expanded={activeIndex === index}
                            aria-controls="01-panel"
                            className={`accordion__tab ${activeIndex === index ? "expanded" : ""} ${index === 0 ? "accordion__tab-first" : ""} ${index === items.length - 1 ? "accordion__tab-last" : ""} ${item.type === 'activation' && items[1].status === 'complete' ? "active" : ""} ${item.status === 'complete' ? "complete" : ""} ${index === 2 && items[1].status !== 'complete' ? 'disabled' : ''}`}
                            onClick={() => {
                                onTitleClick(index);
                                digitalDataAccordionClick('reconnect-steps', item.title, '.accordion-tab')
                            }}
                            onKeyDown={(e) => captureEnterKey(e, index)}
                        >
                            { renderAccordionIcon(item, index) }
                            {item.title}
                        </div>
                        <CSSTransition
                            in={activeIndex === index}
                            timeout={{ enter: 500, exit: 0 }}
                            classNames="expand"
                        >
                            <div
                                className={`accordion__panel ${activeIndex === index ? "" : "expand-exit-done"}`}
                                id="01-panel"
                                aria-hidden={activeIndex !== index}
                                aria-labelledby="01-tab"
                                tabIndex={0}
                                role="tabpanel"
                            >
                                { renderAccordionContent(item, index) }
                            </div>
                        </CSSTransition>
                    </div>
                </React.Fragment>
            );
        })
    }

    return (
        !reconnectContent.loaded && !items.length
        ? <div />
        : <div>
            <div className="accordion accordion-refill" role="tablist" multiselectable="true">
                {renderItems()}
            </div>
        </div>
    )
}

export default withDashboard(ReconnectAccordion)
