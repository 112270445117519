import DashboardLayout from '../../layouts/DashboardLayout'
import ProgressSpinner from '../../components/ProgressSpinner'
import withDashboard from '../../redux/connectors/dashboard'
import { useEffect, useState } from 'react'
import CancelMoveModal from '../../components/move/CancelMoveModal'
import { weekdays, months, trackEvent } from '../../utils/utils'
import { digitalDataButtonClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';

const MoveScheduleConfirmation = (props) => {

    const [address, setAddress] = useState('')
    const [unit, setUnit] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [state, setState] = useState('')
    const [isColdTap, setIsColdTap] = useState(false)
    const [moveDate, setMoveDate] = useState<any>()
    const [loading, setLoading] = useState(false)

    const {
        history,
        currentLocale,
        scheduledMoveDate,
        scheduledMove,
        moveContent,
        dashboard,
        newAuxAccount,
        auxAccount,
        fetchAuxAccount,
        fetchedAuxAccount,
        fetchAuxAccountFailed,
        accountId,
    } = props

    useEffect(() => {
        props.updatePageClass('dashboard dashboard-confirm')

        console.log('scheduledMoveDate', scheduledMoveDate)
        if (!scheduledMoveDate) {
            console.error('No scheduled move date')
            history?.push(`/${currentLocale}/move/schedule`)
            return
        }

        setMoveDate(new Date(scheduledMoveDate))

        let dataSrc = sessionStorage.getItem('xppdata')
        if (dataSrc) {
            const data = JSON.parse(dataSrc);
            if (data.address) {
                setAddress(data.address.toUpperCase())
                setUnit(data.unit.toUpperCase())
                setCity(data.city.toUpperCase())
                setZip(data.zip.toUpperCase())
                setState(data.state.toUpperCase())
                setIsColdTap(data.isColdTap)
            }
        }

        let accountExpired = false
        if (auxAccount && auxAccount.moveEligibility.hsdRefillRequired) {
            accountExpired = true
        }
        trackEvent({
            event: 'move flow',
            moveStep: 'step 4',
            moveStepName: 'move complete',
            moveStepDetail: accountExpired ? 'expired' : 'active'
        })
        trackEvent({
          event: 'move info',
          moveStep: 'step 4',
          moveStepName: 'move complete',
          moveEvent: 'move type',
          moveDetail: 'schedule move'
        })

        setLoading(true)

        if (newAuxAccount) {
            setTimeout(() => {
                fetchAuxAccount({
                    accountId,
                    auxConfNo: newAuxAccount.auxAccountConfNo,
                    checkMoveEligibility: true,
                    checkReconnectEligibility: true
                })
            }, 10000)
        }
        else {
            console.log('A new aux account does not exist')
            history?.replace(`/${currentLocale}/error`)
        }


    }, []) // eslint-disable-line

    useEffect(() => {
        if (fetchedAuxAccount) {
            console.log('fetchedAuxAccount')
            if (loading) {
                setLoading(false)
            }
            sessionStorage.removeItem('xppdata')
        }
    }, [fetchedAuxAccount]) // eslint-disable-line

    useEffect(() => {
        if (fetchAuxAccountFailed) {
            console.error('Failed to fetch aux account details')
            history?.replace(`/${currentLocale}/error`)
        }
    }, [fetchAuxAccountFailed]) // eslint-disable-line

    const returnToDashboard = () => {
        trackEvent({
            event: 'move info',
            moveStep: 'step 4',
            moveStepName: 'move complete',
            moveEvent: 'next step cta',
            moveDetail: moveContent?.moveScheduled?.returnToMyAccount?.text
        })

        history?.replace(`/${currentLocale}/dashboard`)
    }

    let ready = moveContent.loaded && !loading

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Move Schedule Confirmation'}>
            <TrackUserData data={dashboard} />
            {!ready && <ProgressSpinner loadingText={'Loading...'} />}
            {ready &&
            <div className="main__width-constraint move move--now move--finalize">
                <div className="check-mark"></div>
                <div className="fullbleed-offsets">
                    <h1 className="page__title">
                        {moveContent?.moveScheduled?.thankYouYourMoveRequest}
                    </h1>
                    { isColdTap &&
                    <div>
                        <hr></hr>
                        <b>{ moveContent?.moveScheduled?.onTheScheduledDate }</b>
                    </div>}
                </div>
                <div className="service-section">
                    <div className="fullbleed-offsets">
                        <b>{ moveContent?.moveScheduled?.serviceTransferRequestDetails }</b>
                        <hr></hr>
                        <div className="service-section__row">
                            <div className="address">
                                {scheduledMove && <b> {moveContent?.moveScheduled?.confirmationNumber}</b>}<br></br>
                                {scheduledMove ? scheduledMove.auxAcctConfNo : ''}
                                <p></p>
                                <b> {moveContent?.moveNow?.toDestinationAddress}</b><br></br>
                                <div>{address}</div>
                                {unit !== '' ? <div>{unit}</div> : null }
                                {city}, {state} {zip}
                                <p></p>
                                <b>{moveContent?.moveNow?.fromPreviousAddress}</b><br></br>
                                {dashboard?.serviceAddress?.addressLine1}
                                {dashboard?.serviceAddress?.addressLine2 !== '' ? <div>{dashboard?.serviceAddress?.addressLine2}</div> : null }
                                {dashboard?.serviceAddress?.city}, {dashboard?.serviceAddress?.state} {dashboard?.serviceAddress?.zip}
                            </div>
                        </div>
                        <p></p>
                        {moveDate &&
                        <div className="service-section__row">
                            <div className="address">
                                <b>{moveContent?.moveScheduled?.moveDate}</b><br></br>
                                <span>
                                    {weekdays[moveDate.getUTCDay()]}, {months[moveDate.getUTCMonth()]} {moveDate.getUTCDate()}, {moveDate.getUTCFullYear()}
                                </span>
                            </div>
                        </div>}
                        <hr></hr>
                        <div className="service-section__footer">
                            <CancelMoveModal buttonClass="button--link" buttonText={moveContent?.moveScheduled?.canceLThiSMovE?.text} modalType='moveConfirmationCancel' />
                        </div>
                    </div>
                </div>
                <div className="button--container">
                    <button className="button" onClick={() => returnToDashboard()} data-tracking={digitalDataButtonClick('return-to-my-account', moveContent?.moveScheduled?.returnToMyAccount?.text)}>{moveContent?.moveScheduled?.returnToMyAccount?.text}</button>
                </div>
            </div>}
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveScheduleConfirmation)
