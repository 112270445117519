import { useState, useEffect } from 'react'

interface ComponentProps {
    title?: String
    percent?: Number
}
const ProgressBar = ({title, percent}: ComponentProps) => {

    const [progressTitle, setProgressTitle] = useState(title)
    const [progress, setProgress] = useState(percent)

    useEffect(() => {
        setProgress(percent)
        setProgressTitle(title)
        // eslint-disable-next-line
    }, [percent, title])

    return (
        <div className="progress-bar">
            <div className="progress-bar__text">
                <div className="main__width-constraint">
                    <p className="progress-bar__title">
                        { progressTitle }
                    </p>
                </div>
                
            </div>
            <div className="progress-bar__elm">
            <div style={{width: `calc(100% - ${progress}%)`}} className="progress-bar__empty" />
            </div>
        </div>
    )

}

export default ProgressBar
