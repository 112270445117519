import DashboardLayout from '../../layouts/DashboardLayout'
import { useHistory } from 'react-router-dom'
import ProgressBar from '../../components/ProgressBar'
import { useState, useEffect } from 'react'
import withDashboard from '../../redux/connectors/dashboard'
import Cart from '../../components/refill/Cart'
import { formatMoney, trackEvent, getProductName, getProductPrice } from '../../utils/utils'
import { getProductContent, weekdays, months } from '../../utils/utils'
import { sprintf } from 'sprintf-js'
import ProgressSpinner from '../../components/ProgressSpinner'
import { digitalDataButtonClick, digitalDataAccordionClick } from '../../utils/adobe-analytics';
import TrackPageComplete from '../../utils/TrackPageComplete';
import TrackUserData from '../../utils/TrackUserData';
import TrackPurchaseData from '../../utils/TrackPurchaseData';

let timerRan = false

const MoveRefillConfirmation = (props) => {
    const {
        locale,
        cartContent,
        refillContent,
        cartTotal,
        dashboard,
        fetchedDashboard,
        fetchDashboard,
        fetchAuxAccount,
        fetchedAuxAccount,
        accountId,
        fetchPaymentInfo,
        fetchedPaymentInfo,
        resetBilling,
        updateField,
        fetchPromos,
        fetchedPromos,
        hsdService,
        refill,
        cart,
        scheduledMoveDate,
        moveContent,
        havePromoInCart,
        cartCount,
        isTestFile,
    } = props

    isTestFile && (timerRan = true);
    const today = new Date()
    const dayDurationInMilliseconds = 86400 * 1000
    const expirationDay = new Date(today.getTime() + (hsdService.daysRemaining * dayDurationInMilliseconds))

    const [progress] = useState(100)
    const [expanded, setExpanded] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)
    const [internetProduct, updateInternetProduct] = useState<any>({})
    const [finalizedRefill, setFinalizedRefill] = useState(false)

    const showingRefillConfirmation = refill && refill.cart && window.location.href.includes('confirmation')
    const activeCart = !isTestFile ? (showingRefillConfirmation ? refill?.cart : cart): true

    const history = useHistory();

    const toggle = () => {
        setExpanded(!expanded);
        console.log(expanded);
    }

    const cartComponentContent = { cartContent, refillContent }


    useEffect(() => {
        if (fetchedDashboard && fetchedPaymentInfo && fetchedPromos && fetchedAuxAccount && timerRan) {
            setFinalizedRefill(true)
        }
    }, [fetchedDashboard, fetchedPaymentInfo, fetchedPromos, fetchedAuxAccount])

    useEffect(() => {
        props.updatePageClass('dashboard refill move-refill move-refill-confirmation')
        if (!fetchedDashboard) {
            history?.replace(`/${locale}/move`)
            return;
        }
        console.log(hsdService)
        if (activeCart?.internet !== null && activeCart?.internet !== '') {
            let internetItem: any
            internetItem = getProductContent(activeCart?.internet, cartContent.products)
            updateInternetProduct(internetItem)
        }
        setOrderTotal(cartTotal)

        setTimeout(() => {
            fetchDashboard({accountId})
            fetchPaymentInfo({accountId, phoneNumber: dashboard?.customerInfo.phoneNumber})
            fetchPromos({
                accountId,
                locationId: dashboard?.customerInfo.locationId,
            })
            fetchAuxAccount({
                accountId,
                auxConfNo: '',
                checkMoveEligibility: true,
                checkReconnectEligibility: true
            })
            timerRan = true
        }, 500)

        trackEvent({
            event:'move refill flow',
            moveStep:'step 4',
            moveStepName:'order complete'
        })

        //Needed for analytics
        let products: any
        products = []
        if (cart?.internet !== '' && cart?.internet !== null && dashboard?.availablePlans && dashboard?.availablePlans !== null) {
            let toggleProduct: any
                toggleProduct = {}
                toggleProduct.name = getProductName(cart?.internet, dashboard?.availablePlans)
                toggleProduct.id = cart?.internet
                toggleProduct.price = getProductPrice(cart?.internet, dashboard?.availablePlans)
                toggleProduct.dimension1 = 'move'
                toggleProduct.dimension4 = 'website'
                toggleProduct.quantity = cart.internetRefills
                products.push(toggleProduct)
        }

        trackEvent({
            event: 'purchase',
            ecommerce: {
            purchase: {
                actionField: {
                id: refill?.orderDetails?.orderNumber,
                revenue: cartTotal
                },
                products
            }
            }
        })

        updateField({field: 'refilledPrepaidAccount', value: false})
        resetBilling()

    }, []) // eslint-disable-line

    const handleClick = (e) => {
        e.preventDefault()

        trackEvent({
            event:'move refill info',
            moveStep:'step 4',
            moveStepName:'order complete',
            moveEvent: 'next step cta',
            moveDetail: moveContent?.refillConfirmation?.button
        })

        const date = new Date()
        const dateString = sprintf('%04d-%02d-%02d', date.getFullYear(), date.getMonth() + 1, date.getDate())
        if (scheduledMoveDate) {
            // check if it is a move now scenario
            if (scheduledMoveDate === dateString) {
                history?.push("/en/move/now")
            } else {
                history?.push("/en/move/schedule-finalize")
            }
        } else {
            history?.push("/en/move/address-confirmation")
        }
    }

    return (
        <DashboardLayout locale={props.locale} pageTitle={'Confirmation'}>
            <TrackUserData data={dashboard} />
            <TrackPurchaseData offerIncluded={havePromoInCart} price={cartTotal} productID={cart?.internet} productType={'prepaid-refill'} quantity={cartCount} transactionID={refill?.orderDetails?.orderNumber} screenName="confirmation"/>
        {props.refillContent.loaded && props.fetchedDashboard && finalizedRefill ?
        <div>
            <ProgressBar title={moveContent?.refillConfirmation?.refillSuccessful} percent={progress} />

            <div className="main__width-constraint refill refill__confirmation">
                <p></p><h1 className="page__title">{moveContent?.refillConfirmation?.title}</h1>
                <p>
                    <span>{moveContent?.refillConfirmation?.subhead}</span>
                </p>
                <p></p>
                <div className="accordion order-confirm-accordion" role="tablist" multiselectable="true">
                    <div className="accordion__item">
                        <div
                            id="'01-tab'"
                            data-testid="01-tab"
                            className={expanded ? "accordion__tab order-confirm-accordion__tab expanded" : "accordion__tab order-confirm-accordion__tab"}
                            role="tab"
                            onClick={() => {
                                toggle();
                                digitalDataAccordionClick('move-refill-confirmation', refillContent.refillFlow.cart.orderDetails + ' $' + formatMoney(orderTotal), '.accordion-tab');
                            }}
                        >
                        <h2 className="order-confirm-accordion__title">
                            {refillContent.refillFlow.cart.orderDetails}
                            <b><sup>$</sup>{formatMoney(orderTotal)}</b>
                        </h2>

                        {expanded ? <button className="button button--link" data-tracking={digitalDataButtonClick('close-details', refillContent.refillFlow.cart.closeDetails?.text)}>{refillContent.refillFlow.cart.closeDetails?.text}</button>  : <button className="button button--link" data-tracking={digitalDataButtonClick('view-details', props.refillContent.refillFlow.orderConfirmation.viewDetails?.text)}>{props.refillContent.refillFlow.orderConfirmation.viewDetails?.text}</button> }

                        <div className="accordion__icon">
                            <span className="line" /><span className="line" />
                        </div>
                        </div>

                        
                        <div
                            id="'01-panel'"
                            className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
                            tabIndex={0}
                            role="tabpanel"
                        >
                            <Cart content={cartComponentContent} />
                        </div>
                    </div>
                    </div>
                    <div className="card">
                        <div className="service-section">
                            <div className="service-section__row">
                                <div className="address">
                                    <b>{moveContent?.refillConfirmation?.orderDate}</b><br></br>
                                    <span>
                                        {weekdays[today.getUTCDay()]}, {months[today.getUTCMonth()]} {today.getUTCDate()}, {today.getUTCFullYear()}
                                    </span>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className="service-section__row">
                                <div className="address">
                                    <b>{moveContent?.refillConfirmation?.refillExpirationDate}</b><br></br><br />
                                    <p>{ activeCart?.internet === 'HSD_030' ? '30 days of ' : '7 days of '}{ internetProduct?.shortName}</p>
                                    <p>
                                        <strong>
                                            {weekdays[expirationDay.getUTCDay()]}, {months[expirationDay.getUTCMonth()]} {expirationDay.getUTCDate()}, {expirationDay.getUTCFullYear()}
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="button--container"><button  data-testid="btn-handleClick" className="button" onClick={handleClick} data-tracking={digitalDataButtonClick('refill-confirmation', moveContent?.refillConfirmation?.button)}>{moveContent?.refillConfirmation?.button}</button> </div>
                </div>
            </div>
            : <ProgressSpinner loadingText={'Finalizing refill ...'}  />}
            <TrackPageComplete />
        </DashboardLayout>
    )
}

export default withDashboard(MoveRefillConfirmation)