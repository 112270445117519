import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import {GlobalModal as Modal } from '../Modal'
import withDashboard from '../../redux/connectors/dashboard'
import { digitalDataAccordionClick } from '../../utils/adobe-analytics'

const Tv200Accordion = (props) => {
    const {
        refillContent
    } = props
    const [expanded, setExpanded] = useState(false)
    
    const onTitleClick = () => {
        setExpanded(!expanded)
    }
    const captureEnterKey = (event) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            onTitleClick()
        }
    }
    return (
        <div className="accordion accordion--with-input dashboard" role="tablist" multiselectable="true">
            <div className="accordion__item">
                    <div
                        id="01-tab"
                        tabIndex={0}
                        role="tab"
                        aria-expanded={expanded}
                        aria-controls="01-panel"
                        className={`accordion__tab ${expanded ? "" : "active"}`}
                        onClick={() => {
                            onTitleClick();
                            digitalDataAccordionClick('tv-200', 'TV200', '.accordion-tab');
                        }}
                        onKeyDown={(e) => captureEnterKey(e)}
                    >
                        <div className="checkbox">
                            <input id="TV200" aria-describedby="description-for-TV200" type="checkbox" name="xitv-channel-packs" className="checkbox__input" /> 
                            <label htmlFor="TV200" className="checkbox__label">
                                <span className="h3">TV200
                                </span>
                            </label> 
                            <div className="checkbox__content">
                                <div id="description-for-XITV_ENTRTMNT">
                                    <table className="price-wrap">
                                        <tbody>
                                            <tr>
                                                <td className="price-wrap__amount">
                                                    <span>$</span>45
                                                </td> 
                                                <td className="price-wrap__label">
                                                    <strong>for 30 days</strong> 
                                                    <br></br> EXPIRED <span>12/30/18</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="accordion__icon">
                            <span className="line" /><span className="line" />
                        </div>
                    </div>
                    <CSSTransition
                        in={expanded}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames="expand"
                    >
                    <div
                        className={`accordion__panel ${expanded ? "" : "expand-exit-done"}`}
                        id="01-panel"
                        aria-hidden={!expanded}
                        aria-labelledby="01-tab"
                        tabIndex={0}
                        role="tabpanel"
                    >
                        <div className="accordion__content card">
                            <ul className="refill-radio-list">
                                <li className="refill-radio-list-title">
                                    TV 200
                                </li>
                                <li>
                                <input type="radio" id={"tv200"} name={"tv200Radio"}  />
                                <label htmlFor={"tv200"} ><span></span>7 days of TV 200<div className="refill-radio-list--price"><sup>$</sup>17.00</div>
                                <br></br>
                                <Modal
                                    ctaText={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    buttonClasses='button--link'
                                    title={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    body={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfoContent}
                                />
                                </label>
                                </li>
                                <li>
                                <input type="radio" id={"tv2002"} defaultChecked name={"tv200Radio"}  />
                                <label htmlFor={"tv2002"}><span></span>30 days of TV 200<div className="refill-radio-list--price"><sup>$</sup>45.00</div>
                                <br></br>
                                <Modal
                                    ctaText={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    buttonClasses='button--link'
                                    title={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    body={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfoContent}
                                />
                                </label>
                                </li>
                            </ul>
                            <ul className="refill-radio-list">
                                <li className="refill-radio-list-title">
                                    TV 200 Spanish
                                </li>
                                <li>
                                <input type="radio" id={"tv200spanish"} name={"tv200spanishRadio"}  />
                                <label htmlFor={"tv200spanish"} ><span></span>7 days of TV 200<div className="refill-radio-list--price"><sup>$</sup>17.00</div>
                                <br></br>
                                <Modal
                                    ctaText={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    buttonClasses='button--link'
                                    title={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    body={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfoContent}
                                />
                                </label>
                                </li>
                                <li>
                                <input type="radio" id={"tv200spanish2"} name={"tv200spanishRadio"}  />
                                <label htmlFor={"tv200spanish2"}><span></span>30 days of TV 200<div className="refill-radio-list--price"><sup>$</sup>45.00</div>
                                <br></br>
                                <Modal
                                    ctaText={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    buttonClasses='button--link'
                                    title={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfo}
                                    body={refillContent?.refillFlow?.orderConfirmation?.promoModal?.pricingOtherInfoContent}
                                />
                                </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </CSSTransition>
                </div>
        </div>
    )
}

export default withDashboard(Tv200Accordion)
