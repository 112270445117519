declare global {
  namespace JSX {
    interface IntrinsicElements {
      "jump-web-component": any;
    }
  }
}

const PaymentComponent = (props) => {
  const { cpcPageType } = props;

  const hostedAppEnv = process.env.REACT_APP_CPC_ENV;
  const pageType = cpcPageType;
  const pageCssUrl =
  process.env.REACT_APP_CA_API_BASE_URL + "site-assets/css/payment.css";
  const pageWidth = "100%";
  const pageHeight = "880px";
  const pageBorder = "none";

  return (
    <div>
      <jump-web-component
        cpc-page-type={pageType}
        cpc-env={hostedAppEnv}
        cpc-page-height={pageHeight}
        cpc-page-width={pageWidth}
        cpc-page-css-url={pageCssUrl}
        cpc-page-border={pageBorder}
      ></jump-web-component>
    </div>
  );
};

export default PaymentComponent;
