import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import withDashboard from '../redux/connectors/dashboard'
import ProgressSpinner from '../components/ProgressSpinner'
import { digitalDataLinkClick } from '../utils/adobe-analytics';
import TrackPageComplete from '../utils/TrackPageComplete';
import TrackUserData from '../utils/TrackUserData';

const Error = (props) => {
    const {
        pageClass,
        dashboardContent,
        reconnectContent,
        dashboard,
        auxAccount,

        fetchEnglishDashboardContent,
        fetchedEnglishDashboardContent,
        fetchingEnglishDashboardContent,
        fetchSpanishDashboardContent,
        fetchedSpanishDashboardContent,
        fetchingSpanishDashboardContent,

        fetchEnglishReconnectContent,
        fetchedEnglishReconnectContent,
        fetchingEnglishReconnectContent,
        fetchSpanishReconnectContent,
        fetchedSpanishReconnectContent,
        fetchingSpanishReconnectContent
    } = props;

    useEffect(() => {
        props.updatePageClass('dashboard')
        // console.log(props)
        if (!dashboardContent.loaded) {
            if (!(fetchedEnglishDashboardContent || fetchingEnglishDashboardContent)) {
                fetchEnglishDashboardContent()
            }
            if (!(fetchedSpanishDashboardContent || fetchingSpanishDashboardContent)) {
                fetchSpanishDashboardContent()
            }
        }
        if (!reconnectContent.loaded) {
            if (!(fetchedEnglishReconnectContent || fetchingEnglishReconnectContent)) {
                fetchEnglishReconnectContent()
            }
            if (!(fetchedSpanishReconnectContent || fetchingSpanishReconnectContent)) {
                fetchSpanishReconnectContent()
            }
        }

    }, []) // eslint-disable-line

    if (dashboardContent.loaded && reconnectContent.loaded) {
        return (
            <div className={`prepaid-wrapper ${pageClass}`}>
                <TrackUserData data={dashboard} />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Error - Xfinity Prepaid</title>
                    <link rel="icon" href="/favicon.ico" />
                </Helmet>

                {dashboard && dashboard.customerInfo.prepaidAccountStatus === 'Closed' && !auxAccount?.reconnectEligibility?.reconnectEligibleAcct
                ? <main>
                    <div id="main" role="main" className="main">
                        <div className='error-content'>
                            <img alt="" src="/site-assets/img/icon-fail.svg"></img>
                            <h1 className="page__title">
                                {reconnectContent?.errorMessages?.accountNotAvailable?.title}
                            </h1>
                            <p dangerouslySetInnerHTML={{__html: reconnectContent?.errorMessages?.accountNotAvailable?.message}} />
                        </div>
                    </div>
                </main>
                : <main>
                    <div id="main" role="main" className="main">
                        <div className='error-content'>
                            <img alt="" src="/site-assets/img/icon-fail.svg"></img>
                            <h1 className="page__title">
                                {dashboardContent?.accountInfoArea?.genericErrorScreen?.title}
                            </h1>
                            <p dangerouslySetInnerHTML={{__html: dashboardContent?.accountInfoArea?.genericErrorScreen?.bodyText}} />
                            <a href={dashboardContent?.accountInfoArea?.genericErrorScreen?.clickToCallButtonText?.link?.url}
                                className="link-button" data-tracking={digitalDataLinkClick('account-info-error', dashboardContent?.accountInfoArea?.genericErrorScreen?.clickToCallButtonText?.link?.url, dashboardContent?.accountInfoArea?.genericErrorScreen?.clickToCallButtonText?.text)}>{dashboardContent?.accountInfoArea?.genericErrorScreen?.clickToCallButtonText?.text}</a>
                            <br/>
                            <a href={dashboardContent?.accountInfoArea?.genericErrorScreen?.secondaryLink?.link?.url} data-tracking={digitalDataLinkClick('account-info-error-secondary', dashboardContent?.accountInfoArea?.genericErrorScreen?.secondaryLink?.link?.url, dashboardContent?.accountInfoArea?.genericErrorScreen?.secondaryLink?.text)}>
                                {dashboardContent?.accountInfoArea?.genericErrorScreen?.secondaryLink?.text}</a>
                        </div>
                    </div>
                </main>
                }
                <TrackPageComplete />
            </div>
        )
    }
    else return (
        <div id="main" className="main" role="main">
            <div className="main__width-constraint">
                <ProgressSpinner loadingText="Loading..." />
            </div>
        </div>
    )
}

export default withDashboard(Error)