import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import withLitepay from "../../redux/connectors/litepay";
import { useForm } from "react-hook-form";
import NotificationBanner from "../NotificationBanner";
import Cart from "./Cart";
import PaymentForm from "./PaymentForm";
import PurchaseAgreementForm from "./PurchaseAgreementForm";
import ProgressSpinner from "../../components/ProgressSpinner";
import { errorForCode } from "../../lib/errors";
import { trackEvent } from "../../utils/utils";
import { digitalDataButtonClick, digitalDataAccordionClick, digitalDataValidationError, digitalDataAddToCart } from "../../utils/adobe-analytics";
import TrackPurchaseData from '../../utils/TrackPurchaseData';

const RefillAccordions = (props) => {
  const {
    nextClicked,
    litepayPages,
    dictionaryContent,
    billingPhoneNumber,
    billingZipLookup,
    billingAddressLine1,
    billingAddressLine2,
    billingCity,
    billingZip,
    billingState,
    updateField,
    locatingService,
    locatedService,
    fetchCustomerDashboard,
    showLocateServicesNotification,
    showMultipleAccountsNotification,
    toggleField,
    fetchTaxData,
    checkoutContent,
    cartContent,
    handleFormStatus,
    handleNext,
    refillPrepaidAccount,
    dashboard,
    taxData,
    haveValidACP,
    isTribalACP,
    refillingPrepaidAccount,
    refilledPrepaidAccount,
    refillPrepaidAccountFailed,
    refillErrorCode,
    currentLocale,
    cpcResponse,
    updatePageStep,
    fetchedDashboard
  } = props;

  const [items, setItems] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [firstTime, setFirstTime] = useState(true);
  const [disabledStep1, updateDisabledStep1] = useState(true);
  const [collapsedPayment, updateCollapsedPayment] = useState(false);
  const [refillError, setRefillError] = useState("");
  const [processNext, setProcessNext] = useState(false);
  const [startCPCEvents, setStartCPCEvents] = useState(false);
  const [accordianStep, setAccordianStep] = useState(0)

  const { handleSubmit, register, trigger, errors, formState } = useForm({
    mode: "onChange",
  });
  const { isValid } = formState;
  const history = useHistory();

  useEffect(() => {
    if (firstTime && litepayPages?.litepay) {
      setItems([
        {
          title: litepayPages?.litepay?.locateServiceStep?.tabLabelActive?.text,
          content: {
            stepLabel: {
              text: litepayPages?.litepay?.locateServiceStep?.tabLabelComplete
                ?.text,
              cssClass: "",
            },
          },
          id: 1,
          type: "account-id",
          index: 0,
          status: "incomplete",
        },
        {
          title: litepayPages?.litepay?.paymentStep?.tabLabelActive?.text,
          content: {
            stepLabel: {
              text: litepayPages?.litepay?.paymentStep?.tabLabelActive?.text,
              cssClass: "",
            },
          },
          id: 2,
          readyForActivation: true,
          type: "refill",
          index: 1,
          status: "incomplete",
        },
        {
          title: litepayPages?.litepay?.confirmStep?.tabLabelActive?.text,
          content: {
            stepLabel: {
              text: litepayPages?.litepay?.confirmStep?.tabLabelActive?.text,
              cssClass: "",
            },
          },
          id: 3,
          type: "activation",
          index: 2,
          status: "incomplete",
        },
      ]);
    }
  }, [litepayPages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (locatedService) {
      setStartCPCEvents(true);
      setActiveIndex(1);
      let newItems = [...items];
      newItems[0].status = "complete";
      setItems(newItems);
      toggleField({ field: "showStickyCart" });
      fetchTaxData();

      trackEvent({
        event: "litepay flow",
        litepayStep: "step 2",
        litepayStepName: "payment",
      });

      const products: any = [];
      const refillProduct: any = {};
      refillProduct.name = "30 days plan for customer";
      refillProduct.id = "HSD_030";
      refillProduct.price = 45;
      refillProduct.dimension1 = "litepay refill";
      refillProduct.dimension4 = "website";
      refillProduct.quantity = 1;
      products.push(refillProduct);

      trackEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            products,
          },
        },
      });
    }
  }, [locatedService]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (nextClicked) {
      if (Object.keys(cpcResponse).length === 0) {
        setProcessNext(true);
      }
    }
  }, [nextClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstTime && litepayPages?.litepay) {
      let oldItems = [...items];
      oldItems[0].title =
        litepayPages?.litepay?.locateServiceStep?.tabLabelActive?.text;
      oldItems[0].content.stepLabel.text =
        litepayPages?.litepay?.locateServiceStep?.tabLabelComplete?.text;
      oldItems[1].title =
        litepayPages?.litepay?.paymentStep?.tabLabelActive?.text;
      oldItems[1].content.stepLabel.text =
        litepayPages?.litepay?.paymentStep?.tabLabelComplete?.text;
      oldItems[2].title =
        litepayPages?.litepay?.confirmStep?.tabLabelActive?.text;
      oldItems[2].content.stepLabel.text =
        litepayPages?.litepay?.confirmStep?.tabLabelComplete?.text;
      setItems(oldItems);
    }
  }, [litepayPages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (items.length && firstTime) {
      setFirstTime(false);
      setTimeout(() => {
        setActiveIndex(0);
      }, 1000);
    }
  }, [items]); // eslint-disable-line

  useEffect(() => {
    if (showLocateServicesNotification) {
      trackEvent({
        event: "litepay info",
        litepayStep: "step 1",
        litepayStepName: "find your account",
        litepayEvent: "error",
        litepayDetail: "trouble locating account",
      });
    }
    if (showMultipleAccountsNotification) {
      trackEvent({
        event: "litepay info",
        litepayStep: "step 1",
        litepayStepName: "find your account",
        litepayEvent: "error",
        litepayDetail: "multiple accounts associated",
      });
    }
  }, [showLocateServicesNotification, showMultipleAccountsNotification]);

  useEffect(() => {
    if (refilledPrepaidAccount) {
      history.push("/" + currentLocale + "/litepay/order-confirmation");
    } else if (refillPrepaidAccountFailed) {
      console.warn("Uh-oh, something went wrong with refill", refillErrorCode);
      const error = errorForCode(refillErrorCode || "", currentLocale);
      setRefillError(error);
      setActiveIndex(1);
      updateCollapsedPayment(false);
      const tab3yPosition =
        (document.getElementById("02-tab") as HTMLDivElement).scrollHeight + 60;
      window.scrollTo({ top: tab3yPosition + 350 , behavior: 'smooth' });

      trackEvent({
        event: "litepay info",
        litepayStep: "step 4",
        litepayStepName: "order review",
        litepayEvent: "error",
        litepayDetail: refillErrorCode,
      });
    }
  }, [refilledPrepaidAccount, refillPrepaidAccountFailed]); // eslint-disable-line

  const AccordianStepNumber = () => {
    console.log("accordianStep: ", accordianStep)
    // if(accordianStep === 0){
    //   window.digitalData.transaction.attributes.buyflowStep = "find-your-account";

    // }else if(accordianStep === 1){
    //   window.digitalData.transaction.attributes.buyflowStep = "payment";
    // }else{
    //   window.digitalData.transaction.attributes.buyflowStep = "order-review";
    // }
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onError = (errors, e) => {
    e.preventDefault();
    console.log("onError", errors);
    if (errors?.billingPhoneNumber || errors?.billingZipLookup) {
      digitalDataValidationError('refill accordions', [errors?.billingPhoneNumber ? 'billingPhoneNumber' : '', errors?.billingZipLookup ? 'billingZipLookup' : ''], 'biling information invalid', '.accordion__panel-content');
    }
  };
  const goToOrderReview = (e) => {
    setActiveIndex(2);
    let newItems = [...items];
    newItems[1].status = "complete";
    setItems(newItems);
    toggleField({ field: "showStickyCart" });
    updateCollapsedPayment(true);
    handleNext(true);
  };

  const onUpdateField = (e) => {
    //Determine if this event is returned from our intitial address verification API or the Common Payment Component data
    if (e.target !== undefined) {
      if (errors[e.target.name]) {
        trigger(e.target.name.toString());
      }
      if (e.target.name === "billingPhoneNumber") {
        const target = e.target;
        const input = target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) {
          target.value = `(${zip}) ${middle} - ${last}`;
        } else if (input.length > 3) {
          target.value = `(${zip}) ${middle}`;
        } else if (input.length > 0) {
          target.value = `(${zip}`;
        } else if (input.length === 0) {
          target.value = "";
        }
        const tn = target.value.replace(/\D/g, "").substring(0, 10);
        updateField({ field: "tn", value: tn });
        updateField({ field: e.target.name, value: e.target.value });
      }
      // check if fields are filled and valid
      const invalid =
        errors?.billingPhoneNumber || errors?.billingZipLookup || !isValid;
      if (!invalid) {
        updateDisabledStep1(false);
      }
      updateField({ field: e.target.name, value: e.target.value });
      console.log(
        "onUpdateField Refill Acoordion",
        e.target.name,
        " : ",
        e.target.value
      );
    } else if (e.action === "JUMP-RESPONSE-RECEIVED") {
      //We have determined that this is not a input event & is a CPC Response, populate the fields needed for the transaction
      //Payment Info
      updateField({
        field: "billingAddressLine1",
        value: e.data.paymentInfo.address,
      });
      updateField({
        field: "billingAddressLine2",
        value: e.data.paymentInfo.addressLine2,
      });
      updateField({ field: "billingCity", value: e.data.paymentInfo.city });
      updateField({ field: "billingState", value: e.data.paymentInfo.state });
      updateField({ field: "billingZip", value: e.data.paymentInfo.zipCode });
      updateField({
        field: "billingFirstName",
        value: e.data.paymentInfo.firstName,
      });
      updateField({
        field: "billingLastName",
        value: e.data.paymentInfo.lastName,
      });
      updateField({
        field: "prepaidPaymentID",
        value: dashboard?.customerInfo?.prepaidPaymentID,
      });

      //CPC Data returned from iFrame
      updateField({ field: "cpcResponse", value: e?.data });
    }
  };
  const onBlurField = (e) => {
    trigger(e.target.name.toString());
    updateField({ field: e.target.name, value: e.target.value });
  };

  const getAccount = () => {
    fetchCustomerDashboard();
    trackEvent({
      event: "litepay info",
      litepayStep: "step 1",
      litepayStepName: "find your account",
      litepayEvent: "next step cta",
      litepayDetail: "next step",
    });
    digitalDataButtonClick('get-account', locatingService ? dictionaryContent?.global?.locating : dictionaryContent?.global?.nextStep, false, "",true, ".get-account-button")
    digitalDataAddToCart('litepay refill flow', 'next-step', '.litepay-page',false,"HSD_030");
    const productNode ={
      product:[
        {
          productInfo:{
            offerIncluded: false,
            price:'45',
            productID: 'HSD_030',
            productType: 'prepaid-refill',
            quantity:1
          }
        }]
    }
    // Object.assign(window.digitalData, productNode);
    // return( <TrackPurchaseData offerIncluded={false} price={'45'} productID={'HSD_030'} productType={'prepaid-refill'} quantity={1} transactionID={""} screenName="payment"/>)
  };

  const setFormValid = (status) => {
    handleFormStatus(status);
  };

  const normalizePhoneNumber = (number) => {
    if (number) {
      return number.replace(/[^0-9]/g, "");
    }
    return number;
  };

  const processRefill = (e) => {
    //All data returned from the Common Payment Component must be ready before running this function
    e.preventDefault();
    e.stopPropagation();
    trackEvent({
      event: "litepay info",
      litepayStep: "step 3",
      litepayStepName: "order review",
      litepayEvent: "next step cta",
      litepayDetail: "next step",
    });

    setRefillError("");
    const plan = dashboard.availablePlans[0];

    let prodTaxDetails = [];
    if (taxData.products && taxData.products[0]) {
      const product = taxData.products[0];
      if (product.productType && product.productType[0]) {
        prodTaxDetails = product.productType[0].vertexCodes.map((item) => {
          return { taxCode: item.vertexCode, taxAmount: item.amt };
        });
      }
    }

    let totalAmount = 0;
    if (haveValidACP && !isTribalACP) {
      totalAmount =
        plan.planPrice - dashboard.acpDetail.acpBenefits[0].benefitAmount;
      if (taxData.totalTaxAmt) {
        totalAmount += taxData.totalTaxAmt;
      }
    } else if (taxData.totalTaxAmt) {
      totalAmount = plan.planPrice + taxData.totalTaxAmt;
    } else {
      totalAmount = plan.planPrice;
    }
    const acpBenefits: any = [];
    if (haveValidACP && !isTribalACP) {
      acpBenefits.push({
        benefitAmount: dashboard.acpDetail.acpBenefits[0].benefitAmount,
        benefitPlan: dashboard.acpDetail.acpBenefits[0].benefitPlan,
        benefitCode: dashboard.acpDetail.acpBenefits[0].benefitCode,
      });
    }

    const data = {
      channel: "ONLINE",
      customerInfo: {
        accountID: dashboard.customerInfo.accountId,
        phone: normalizePhoneNumber(billingPhoneNumber),
        zip: billingZipLookup,
      },
      flow: "EZREFILL",
      paymentInfo: {
        amount: totalAmount,
        billingAddress: {
          addressLine1: billingAddressLine1,
          addressLine2: billingAddressLine2,
          city: billingCity,
          country: "US",
          state: billingState,
          zip: billingZip,
        },
        cardNumber: cpcResponse?.cpcData?.cardDetails?.cardLast4Digits,
        cardType: cpcResponse?.cpcData?.cardDetails?.cardType,
        expirationDate: cpcResponse?.cpcData?.cardDetails?.expirationDate,
        firstName: cpcResponse?.cpcData?.customerDetails?.firstName,
        lastName: cpcResponse?.cpcData?.customerDetails?.lastName,
        processingNetwork: "CREDIT",
        isServiceAddress: true,
        //duplicateOverride will force the user to enter their address, for the non-logged-in flow 'Litepay'
        isCPC: true,
        paymentInstToken: cpcResponse?.cpcData?.cardDetails?.token,
        prepaidPaymentID: dashboard?.customerInfo?.prepaidPaymentID,
      },
      prepaidProducts: [
        {
          chargeCode: [
            {
              amount: plan.planPrice,
              chargeCode: plan.productCode,
              prodTaxDetails,
            },
          ],
          lob: plan.planLob,
          planCode: plan.productCode,
          planQty: 1,
          rate: plan.planPrice,
        },
      ],
      acpBenefits,
      referenceID: dashboard.referenceID,
      termsVersion: "1.0",
    };
    refillPrepaidAccount(data);
  };

  const renderAccordionIcon = (item, index) => {
    const itemNumber = index + 1;
    if (item.status && item.status === "complete") {
      return (
        <img
          src="/site-assets/img/icon-check-circle.svg"
          className="icon check"
          alt="complete"
        ></img>
      );
    } else {
      return <span className="number">{itemNumber}</span>;
    }
  };

  const renderAccordionContent = (item, index) => {
    switch (item.id) {
      case 1:
        return (
          <div className="accordion__panel-content full-bleed" id="01-tab">
            {showLocateServicesNotification ? (
              <NotificationBanner
                text={
                  litepayPages?.litepay?.locateServiceStep?.troubleLocating
                    ?.message
                }
                displayStyle="warning litepay"
                modal="litepayAccountError"
                modalCtaText={
                  litepayPages?.litepay?.locateServiceStep?.warningMessageModal
                    ?.primaryButtonText
                }
                modalTitle={
                  litepayPages?.litepay?.locateServiceStep?.warningMessageModal
                    ?.title
                }
                modalText={
                  litepayPages?.litepay?.locateServiceStep?.warningMessageModal
                    ?.text
                }
              />
            ) : null}
            {showMultipleAccountsNotification ? (
              <NotificationBanner
                text={litepayPages?.litepay?.locateServiceStep?.unfortunately}
                displayStyle="warning litepay"
                modal="false"
              />
            ) : null}
            <p>{litepayPages?.litepay?.locateServiceStep?.bodyText}</p>
            <form
              id="address-lookup"
              action=""
              method="get"
              data-testid="addressLookup"
              onSubmit={() => handleSubmit(onSubmit, onError)}
            >
              <fieldset>
                <div className="flex flex--50-50" data-flex-start="medium">
                  <div className="flex-item form-field">
                    <div>
                      <div className="form-field">
                        <label
                          className="form-field__title"
                          htmlFor="billingPhoneNumber"
                        >
                          {dictionaryContent?.formLabels?.phoneNumber}
                        </label>
                        <input
                          id="billingPhoneNumber"
                          defaultValue={billingPhoneNumber}
                          className={`${
                            errors.billingPhoneNumber ? "validation-error" : ""
                            }`}
                          name="billingPhoneNumber"
                          type="tel"
                          maxLength={16}
                          onInput={onUpdateField}
                          onBlur={onBlurField}
                          data-testid="billing-ph-input"
                          ref={register({ required: true })}
                        />
                        {errors?.billingPhoneNumber && (
                          <label
                            id="phone-number-error"
                            htmlFor="billingPhoneNumber"
                            className="validation-error"
                          >
                            {
                              litepayPages?.litepay?.locateServiceStep
                                ?.phoneNumberError?.message
                            }
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div>
                      <div className="form-field">
                        <label
                          className="form-field__title"
                          htmlFor="billingZipLookup"
                        >
                          {dictionaryContent?.formLabels?.ziPCode}
                        </label>
                        <input
                          id="billingZipLookup"
                          defaultValue={billingZipLookup}
                          className={`${
                            errors.billingZipLookup ? "validation-error" : ""
                            }`}
                          name="billingZipLookup"
                          type="tel"
                          maxLength={5}
                          onInput={onUpdateField}
                          onBlur={onBlurField}
                          ref={register({ required: true })}
                        />
                        {errors?.billingZipLookup && (
                          <label
                            id="zip-error"
                            htmlFor="billingZipLookup"
                            className="validation-error"
                          >
                            {
                              litepayPages?.litepay?.locateServiceStep
                                ?.zipCodeError?.message
                            }
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  disabled={locatingService || disabledStep1}
                  defaultValue={
                    locatingService
                      ? dictionaryContent?.global?.locating
                      : dictionaryContent?.global?.nextStep
                  }
                  type="button"
                  className="button button--inline get-account-button"
                  data-testid="get-account-button"
                  onClick={() => getAccount()}
                // data-tracking={digitalDataButtonClick('get-account', locatingService ? dictionaryContent?.global?.locating : dictionaryContent?.global?.nextStep)}
                />
              </fieldset>
            </form>
          </div>
        );

      case 2:
        return (
          <div className="accordion__panel-content" id="02-tab">
            <div className="man__width-constraint">
              {collapsedPayment ? (
                <div className="card">
                  <div className="cc-form-recap">
                    <div className="card__header">
                      <legend className="card__header-title">
                        {
                          checkoutContent?.paymentAndDelivery
                            ?.cardInformationSectionTitle
                        }
                      </legend>
                      <button
                        type="button"
                        data-testid="update-page-stepbtn"
                        className="totals__edit link-callout"
                        aria-labelledby="edit-xitv"
                        onClick={() =>
                          updatePageStep() &&
                          updateCollapsedPayment(!collapsedPayment)
                        }
                        data-tracking={digitalDataButtonClick('edit-cart', cartContent?.cartContent?.cartModule?.editLinkText)}
                      >
                        {cartContent?.cartContent?.cartModule?.editLinkText}
                      </button>
                    </div>
                    <div className="cc-form-recap-card">
                      <div className="cc-form-recap-header">
                        {dictionaryContent?.formLabels?.paymentMethod}
                      </div>
                      <img
                        className="payment-input-icon icon"
                        alt={cpcResponse?.cpcData?.cardDetails?.cardType}
                        src={`/site-assets/img/${cpcResponse?.cpcData?.cardDetails?.cardType.toLocaleLowerCase()}.svg`}
                      />
                      **** **** ****{" "}
                      {cpcResponse?.cpcData?.cardDetails?.cardLast4Digits}
                    </div>
                    <div className="cc-form-recap-address">
                      <div className="cc-form-recap-header">
                        {dictionaryContent?.formLabels?.billingAddress}
                      </div>
                      {cpcResponse?.paymentInfo?.address}
                      <br />
                      {cpcResponse?.paymentInfo?.addressLine2 &&
                        cpcResponse?.paymentInfo?.addressLine2}
                      {cpcResponse?.paymentInfo?.addressLine2 && <br />}
                      {cpcResponse?.paymentInfo?.city} ,{" "}
                      {cpcResponse?.paymentInfo?.state}{" "}
                      {cpcResponse?.paymentInfo?.zipCode}
                    </div>
                    <div className="cc-form-recap-telephone">
                      <div className="cc-form-recap-header">
                        {
                          dictionaryContent?.formLabels
                            ?.mobileNumberUsedForAccountLogin
                        }
                      </div>
                      {billingPhoneNumber}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card">
                  {locatedService && <Cart showDetails={false} />}
                  <PaymentForm
                    onUpdateField={onUpdateField}
                    goToOrderReview={goToOrderReview}
                    setFormValid={setFormValid}
                    nextClicked={processNext}
                    startCPCEvents={startCPCEvents}
                  />
                </div>
              )}
            </div>
          </div>
        );

      case 3:
        return (
          <div className="card accordion__panel-content" id="03-tab">
            <div>
              {litepayPages?.litepay?.confirmStep?.title && (
                <span className="page__title page__title--large">
                  {litepayPages?.litepay?.confirmStep?.title}
                </span>
              )}
              <Cart showDetails={true} />
              <PurchaseAgreementForm
                billingIsValid={true}
                litepayPages={litepayPages}
                processRefill={processRefill}
              />
            </div>
          </div>
        );
    }
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const onTitleClick = (index) => {
        if (activeIndex === index) {
          setActiveIndex(0);
          setAccordianStep(index);
        } else if (index === 2 || items[0].status !== "complete") {
          if (index === 2 && items[1].status === "complete") {
            setActiveIndex(index);
          }
          setAccordianStep(index);
          // do nothing if step 3 clicks and step 2 isn't completed
        } else {

          setActiveIndex(index);
          setAccordianStep(index);
        }
        AccordianStepNumber();
      };

      const captureEnterKey = (event, index) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
          onTitleClick(index);
        }
      };
      return (
        <React.Fragment key={item.id}>
          <div className="accordion__item accordion-refill">
            <div
              id="01-tab"
              tabIndex={0}
              role="tab"
              data-testid="title"
              aria-expanded={activeIndex === index}
              aria-controls="01-panel"
              className={`accordion__tab ${
                activeIndex === index ? "expanded" : ""
                } ${index === 0 ? "accordion__tab-first" : ""} ${
                index === items.length - 1 ? "accordion__tab-last" : ""
                } ${
                item.type === "activation" && items[1].status === "complete"
                  ? "active"
                  : ""
                } ${item.status === "complete" ? "complete" : ""} ${
                index === 2 && items[1].status !== "complete" ? "disabled" : ""
                }`}
              onClick={() => {
                onTitleClick(index);
                digitalDataAccordionClick('refill',
                  item.status === "complete"
                    ? item?.content?.stepLabel?.text
                    : item?.title,
                  '.accordion-tab'
                )
              }}
              onKeyDown={(e) => captureEnterKey(e, index)}
            >
              {renderAccordionIcon(item, index)}
              {item.status === "complete"
                ? item?.content?.stepLabel?.text
                : item?.title}
            </div>
            <CSSTransition
              in={activeIndex === index}
              timeout={{ enter: 500, exit: 0 }}
              classNames="expand"
            >
              <div
                className={`accordion__panel ${
                  activeIndex === index ? "" : "expand-exit-done"
                  }`}
                id="01-panel"
                aria-hidden={activeIndex !== index}
                aria-labelledby="01-tab"
                tabIndex={0}
                role="tabpanel"
                data-testid="tabpanel-div"
              >
                {refillError ? (
                  <NotificationBanner
                    text={refillError}
                    displayStyle="warning litepay"
                    modal="false"
                    data-testid="litepay-refill-error"
                  />
                ) : null}
                {renderAccordionContent(item, index)}
              </div>
            </CSSTransition>
          </div>
        </React.Fragment>
      );
    });
  };

  return !items.length ? (
    <div />
  ) : (
    <div>
      {refillingPrepaidAccount ? (
        <ProgressSpinner
          loadingText={dictionaryContent?.global?.updating + "…"}
        />
      ) : (
        <div
          className="accordion accordion__faq-group"
          role="tablist"
          multiselectable="true"
        >
          {renderItems()}
        </div>
      )}
    </div>
  );
};

export default withLitepay(RefillAccordions);
