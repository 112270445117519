import {createSlice} from '@reduxjs/toolkit';

interface ActivationState {
    lastActionAt: number
    ready: boolean

    macAddress: string | null

    accountId: string | null
    custGuid: string | null
    behindTheModem: boolean

    firstName: string | null
    lastName: string | null
    isVanAccount: boolean

    trackingId: string | null

    locale: string
    inApp: boolean

    xsrfToken: string | null
    fetchedToken: boolean
    fetchingToken: boolean
    fetchingTokenFailed: boolean

    content: any
    englishContent: any
    spanishContent: any
    fetchedContent: boolean
    fetchingEnglishContent: boolean
    fetchingEnglishContentFailed: boolean
    fetchedEnglishContent: boolean
    fetchingSpanishContent: boolean
    fetchingSpanishContentFailed: boolean
    fetchedSpanishContent: boolean

    identity: any | null
    identityAccounts: any | null
    fetchedIdentity: boolean
    fetchingIdentity: boolean
    fetchingIdentityFailed: boolean
    identityErrorCode: string | null
    identityErrorMessage: string | null

    dashboard: any | null
    fetchedDashboard: boolean
    fetchingDashboard: boolean
    fetchingDashboardFailed: boolean
    dashboardErrorCode: string | null
    dashboardErrorMessage: string | null

    account: any | null
    fetchedAccount: boolean
    fetchingAccount: boolean
    fetchingAccountFailed: boolean

    deviceStatus: string | null
    fetchedDeviceStatus: boolean
    fetchingDeviceStatus: boolean
    fetchingDeviceStatusFailed: boolean

    macStatus: any | null
    macStatusValid: boolean
    macStatusFailure: boolean
    fetchedMacStatus: boolean
    fetchingMacStatus: boolean
    fetchingMacStatusFailed: boolean

    activationStatus: any | null
    activatedPrepaidAccount: boolean
    activatingPrepaidAccount: boolean
    activatingPrepaidAccountFailed: boolean

    restartDeviceStatus: any | null
    restartedDevice: boolean
    restartingDevice: boolean
    restartingDeviceFailed: boolean
}

const updateReadyStatus = (state) => {
    let ready = true
    ready = ready && state.fetchedEnglishContent
    ready = ready && state.fetchedSpanishContent
    ready = ready && state.fetchedIdentity
    ready = ready && state.fetchedDashboard
    // ready = ready && state.fetchedAccount
    ready = ready && state.fetchedDeviceStatus
    state.ready = ready
    state.fetchedContent = state.fetchedEnglishContent && state.fetchedSpanishContent
}

const initialState = {
    lastActionAt: Date.now(),
    ready: false,

    macAddress: null,

    accountId: null,
    custGuid: null,
    behindTheModem: false,

    firstName: null,
    lastName: null,
    isVanAccount: false,

    trackingId: null,

    locale: 'en',
    inApp: false,

    xsrfToken: null, // XXX not populated, not needed?
    fetchedToken: false,
    fetchingToken: false,
    fetchingTokenFailed: false,

    content: {},
    englishContent: {},
    spanishContent: {},
    fetchedContent: false,
    fetchingEnglishContent: false,
    fetchingEnglishContentFailed: false,
    fetchedEnglishContent: false,
    fetchingSpanishContent: false,
    fetchingSpanishContentFailed: false,
    fetchedSpanishContent: false,

    identity: null,
    identityAccounts: null,
    fetchedIdentity: false,
    fetchingIdentity: false,
    fetchingIdentityFailed: false,
    identityErrorCode: '',
    identityErrorMessage: '',

    dashboard: null,
    fetchedDashboard: false,
    fetchingDashboard: false,
    fetchingDashboardFailed: false,
    dashboardErrorCode: '',
    dashboardErrorMessage: '',

    account: null,
    fetchedAccount: false,
    fetchingAccount: false,
    fetchingAccountFailed: false,

    deviceStatus: null,
    fetchedDeviceStatus: false,
    fetchingDeviceStatus: false,
    fetchingDeviceStatusFailed: false,

    macStatus: null,
    macStatusValid: false,
    macStatusFailure: false,
    fetchedMacStatus: false,
    fetchingMacStatus: false,
    fetchingMacStatusFailed: false,

    activationStatus: null,
    activatedPrepaidAccount: false,
    activatingPrepaidAccount: false,
    activatingPrepaidAccountFailed: false,

    restartDeviceStatus: null,
    restartedDevice: false,
    restartingDevice: false,
    restartingDeviceFailed: false
} as ActivationState

const activation = createSlice({
    name: 'activation',
    initialState,
    reducers: {
        generateTrackingId: (state, {payload}) => {
            if (!state.trackingId) {
                state.trackingId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    var r = Math.random() * 16 | 0
                    var v = c === 'x' ? r : (r & 0x3 | 0x8) // eslint-disable-line no-mixed-operators
                    return v.toString(16)
                  })
            }
        },

        updateLocale: (state, {payload}) => {
            state.locale = payload
            if (state.locale === 'es') {
                state.content = {...state.spanishContent}
            } else {
                state.content = {...state.englishContent}
            }
        },

        updateInAppStatus: (state, {payload}) => {
            state.inApp = payload
        },

        updateBehindTheModemStatus: (state, {payload}) => {
            state.behindTheModem = payload
        },

        updateAccountId: (state, {payload}) => {
            state.accountId = payload
        },

        updateCustGuid: (state, {payload}) => {
            state.custGuid = payload
        },

        updateFirstName: (state, {payload}) => {
            state.firstName = payload
        },

        updateLastName: (state, {payload}) => {
            state.lastName = payload
        },

        fetchToken: (state) => {
            state.fetchingToken = true;
            state.lastActionAt = Date.now()
        },
        fetchTokenSuccess: (state, {payload}) => {
            // console.log('fetchTokenSuccess payload', payload)
            state.xsrfToken = payload.xsrfToken
            state.fetchingToken = false;
            state.fetchingTokenFailed = false;
            state.fetchedToken = true;
            state.lastActionAt = Date.now()
        },
        fetchTokenFailure: (state, {payload}) => {
            console.log('fetchTokenFailure payload', payload)
            state.fetchedToken = false;
            state.fetchingToken = false;
            state.fetchingTokenFailed = true;
            state.lastActionAt = Date.now()
        },

        fetchEnglishActivationContent: (state) => {
            state.fetchingEnglishContent = true;
            state.lastActionAt = Date.now()
        },
        fetchEnglishActivationContentSuccess: (state, {payload}) => {
            // console.log('🇺🇸 fetchEnglishActivationContentSuccess payload', payload)

            let content:any = {}
            content.activation = {}

            payload.activation?.forEach(flow => {
                let flowKey = Object.keys(flow)[0]
                let flowContentArray = flow[flowKey]
                content.activation[flowKey] = {}
                flowContentArray.forEach(page => {
                    let pageKey = Object.keys(page)[0]
                    let pageContent = page[pageKey]
                    content.activation[flowKey][pageKey] = pageContent
                })

            })

            console.log('🇺🇸 Updating English content...', content)
            state.englishContent = content
            if (state.locale === 'en') {
                state.content = {...content}
            }
            state.fetchingEnglishContent = false;
            state.fetchingEnglishContentFailed = false;
            state.fetchedEnglishContent = true;
            updateReadyStatus(state)
            state.lastActionAt = Date.now()
        },
        fetchEnglishActivationContentFailure: (state, {payload}) => {
            console.log('fetchEnglishActivationContentFailure payload', payload)
            state.fetchingEnglishContent = false;
            state.fetchingEnglishContentFailed = true
            state.lastActionAt = Date.now()
        },

        fetchSpanishActivationContent: (state) => {
            state.fetchingSpanishContent = true;
            state.lastActionAt = Date.now()
        },
        fetchSpanishActivationContentSuccess: (state, {payload}) => {
            // console.log('fetchSpanishActivationContentSuccess payload', payload)

            let content:any = {}
            content.activation = {}

            payload.activation?.forEach(flow => {
                let flowKey = Object.keys(flow)[0]
                let flowContentArray = flow[flowKey]
                content.activation[flowKey] = {}
                flowContentArray.forEach(page => {
                    let pageKey = Object.keys(page)[0]
                    let pageContent = page[pageKey]
                    content.activation[flowKey][pageKey] = pageContent
                })

            })

            console.log('🇲🇽 Updating Spanish content...', content)
            state.spanishContent = content
            if (state.locale === 'es') {
                state.content = {...content}
            }
            state.fetchingSpanishContent = false;
            state.fetchingSpanishContentFailed = false;
            state.fetchedSpanishContent = true;
            updateReadyStatus(state)
            state.lastActionAt = Date.now()
        },
        fetchSpanishActivationContentFailure: (state, {payload}) => {
            console.log('fetchSpanishActivationContentFailure payload', payload)
            state.fetchingSpanishContent = false;
            state.fetchingSpanishContentFailed = true
            state.lastActionAt = Date.now()
        },

        fetchIdentityInfo: (state) => {
            state.fetchingIdentity = true;
            state.lastActionAt = Date.now()
        },
        fetchIdentityInfoSuccess: (state, {payload}) => {
            console.log('fetchIdentityInfoSuccess payload', payload)
            state.identity = payload.identity
            state.identityAccounts = payload.identityAccounts
            state.fetchingIdentity = false;
            state.fetchingIdentityFailed = false
            state.fetchedIdentity = true;
            updateReadyStatus(state)
            state.lastActionAt = Date.now()
        },
        fetchIdentityInfoFailure: (state, {payload}) => {
            console.log('fetchIdentityInfoFailure payload', payload)
            state.fetchingIdentity = false;
            state.fetchingIdentityFailed = true
            state.identityErrorCode = payload.errorCode;
            state.identityErrorMessage = payload.errorMessage;
            state.lastActionAt = Date.now()
        },

        fetchCustomerDashboard: (state) => {
            state.fetchingDashboard = true;
            state.lastActionAt = Date.now()
        },
        fetchCustomerDashboardSuccess: (state, {payload}) => {
            console.log('fetchCustomerDashboardSuccess payload', payload)
            state.dashboard = payload
            state.isVanAccount =  state.dashboard?.customerInfo?.accountType === 'VAN'
            state.fetchingDashboard = false;
            state.fetchingDashboardFailed = false
            state.fetchedDashboard = true;
            updateReadyStatus(state)
            state.lastActionAt = Date.now()
        },
        fetchCustomerDashboardFailure: (state, {payload}) => {
            console.log('fetchCustomerDashboardFailure payload', payload)
            state.fetchingDashboard = false;
            state.fetchingDashboardFailed = true
            state.dashboardErrorCode = payload.errorCode;
            state.dashboardErrorMessage = payload.errorMessage;
            state.lastActionAt = Date.now()
        },

        loadPrepaidAccount: (state) => {
            state.fetchingAccount = true;
            state.lastActionAt = Date.now()
        },
        loadPrepaidAccountSuccess: (state, {payload}) => {
            console.log('loadPrepaidAccountSuccess payload', payload)
            state.account = payload
            state.fetchingAccount = false;
            state.fetchingAccountFailed = false
            state.fetchedAccount = true;
            updateReadyStatus(state)
            state.lastActionAt = Date.now()
        },
        loadPrepaidAccountFailure: (state, {payload}) => {
            console.log('loadPrepaidAccountFailure payload', payload)
            state.fetchingAccount = false;
            state.fetchingAccountFailed = true
            state.lastActionAt = Date.now()
        },

        fetchDeviceStatus: (state) => {
            state.fetchingDeviceStatus = true;
            state.lastActionAt = Date.now()
        },
        fetchDeviceStatusSuccess: (state, {payload}) => {
            console.log('fetchDeviceStatusSuccess payload', payload)
            state.deviceStatus = payload

            if (payload.devices) {
                const hsdDevice = payload.devices.find(d => d.lob === 'HSD')
                if (hsdDevice && hsdDevice.deviceStatus === 'FOUND') {
                    state.macAddress = hsdDevice.macId
                }
            }

            state.fetchingDeviceStatus = false;
            state.fetchingDeviceStatusFailed = false
            state.fetchedDeviceStatus = true;
            updateReadyStatus(state)
            state.lastActionAt = Date.now()
        },
        fetchDeviceStatusFailure: (state, {payload}) => {
            console.log('fetchDeviceStatusFailure payload', payload)
            state.fetchingDeviceStatus = false;
            state.fetchingDeviceStatusFailed = true
            state.lastActionAt = Date.now()
        },

        fetchMacStatus: (state) => {
            state.fetchingMacStatus = true;
            state.lastActionAt = Date.now()
        },
        fetchMacStatusSuccess: (state, {payload}) => {
            console.log('fetchMacStatusSuccess payload', payload)
            state.macStatus = payload
            if (payload.status === 'SUCCESS') {
                state.macStatusValid = true
                state.macAddress = payload.mac
            }
            state.macStatusFailure = payload.status === 'FAILURE'
            state.fetchingMacStatus = false;
            state.fetchingMacStatusFailed = false
            state.fetchedMacStatus = true;
            state.lastActionAt = Date.now()
        },
        fetchMacStatusFailure: (state, {payload}) => {
            console.log('fetchMacStatusFailure payload', payload)
            state.fetchingMacStatus = false;
            state.fetchingMacStatusFailed = true
            state.lastActionAt = Date.now()
        },

        activatePrepaidAccount: (state) => {
            state.activatingPrepaidAccount = true;
            state.lastActionAt = Date.now()
        },
        activatePrepaidAccountSuccess: (state, {payload}) => {
            console.log('activatePrepaidAccountSuccess payload', payload)
            state.activationStatus = payload
            state.activatingPrepaidAccount = false
            if (payload.errorCode || payload.errorMessage) {
                state.activatingPrepaidAccountFailed = true
                state.activatedPrepaidAccount = false
            } else {
                state.activatingPrepaidAccountFailed = false
                state.activatedPrepaidAccount = true
            }
            state.activatingPrepaidAccount = false
            state.lastActionAt = Date.now()
        },
        activatePrepaidAccountFailure: (state, {payload}) => {
            console.log('activatePrepaidAccountFailure payload', payload)
            state.activatedPrepaidAccount = false
            state.activatingPrepaidAccount = false;
            state.activatingPrepaidAccountFailed = true
            state.lastActionAt = Date.now()
        },


        restartDevice: (state) => {
            state.restartingDevice = true;
            state.lastActionAt = Date.now()
        },
        restartDeviceSuccess: (state, {payload}) => {
            console.log('restartDeviceSuccess payload', payload)
            state.restartDeviceStatus = payload
            state.restartingDevice = false
            state.restartingDeviceFailed = false
            state.restartedDevice = true
            state.lastActionAt = Date.now()
        },
        restartDeviceFailure: (state, {payload}) => {
            console.log('restartDeviceFailure payload', payload)
            state.restartingDevice = false
            state.restartingDeviceFailed = true
            state.restartedDevice = false
            state.lastActionAt = Date.now()
        },
    },
});

export {activation};

export const {
    generateTrackingId,
    updateLocale,
    updateInAppStatus,
    updateBehindTheModemStatus,
    updateAccountId,
    updateCustGuid,
    updateFirstName,
    updateLastName,
    fetchToken,
    fetchTokenSuccess,
    fetchTokenFailure,
    fetchEnglishActivationContent,
    fetchEnglishActivationContentSuccess,
    fetchEnglishActivationContentFailure,
    fetchSpanishActivationContent,
    fetchSpanishActivationContentSuccess,
    fetchSpanishActivationContentFailure,
    fetchIdentityInfo,
    fetchIdentityInfoSuccess,
    fetchIdentityInfoFailure,
    fetchCustomerDashboard,
    fetchCustomerDashboardSuccess,
    fetchCustomerDashboardFailure,
    loadPrepaidAccount,
    loadPrepaidAccountSuccess,
    loadPrepaidAccountFailure,
    fetchDeviceStatus,
    fetchDeviceStatusSuccess,
    fetchDeviceStatusFailure,
    fetchMacStatus,
    fetchMacStatusSuccess,
    fetchMacStatusFailure,
    activatePrepaidAccount,
    activatePrepaidAccountSuccess,
    activatePrepaidAccountFailure,
    restartDevice,
    restartDeviceSuccess,
    restartDeviceFailure,

  } = activation.actions;
  export default activation.reducer;